import React, { useEffect } from "react";
import MuiBackdrop from "../../../components/Loading/MuiBackdrop";
import { apiNodeService } from "../../../provider/apiNodeService";
import moment from "moment";
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { maillingService } from "../../../provider/maillingService";

const Offre_Detail = () => {

    const [loading, setLoading] = React.useState(false);
    const [offre, setOffre] = React.useState(null);
    const [email, setEmail] = React.useState("");
    const [step, setStep] = React.useState(1);

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const id = searchParams.get('id');
        const mail = searchParams.get('email');
        setEmail(mail)
        console.log(id)
        getOffreById(id)
    }, []);

    const getOffreById = async (id) => {
        setLoading(true)
        return new Promise(resolve => {
            apiNodeService.filterTable({ table: "offre", filter: { id: id }, db: "FREELANCE_MAD" }).then(userRes => {
                console.log(userRes)
                if (userRes && userRes !== "false" && Array.isArray(userRes.data)) {
                    console.log(userRes.data[0])
                    setOffre(userRes.data[0])
                    setLoading(false)
                    resolve(userRes.data[0])
                } else {
                    setLoading(false)
                    resolve("false")
                }
            }).catch(err => resolve("false"))
        })
    }

    const updateOffre = () => {
        setLoading(true)
        let data = {}
        data.freelancers = [...offre.freelancers, email]
        console.log(data)
        apiNodeService.updateTableData({ db: "FREELANCE_MAD", table: "offre", id: offre.id, data: data }).then(resAdd => {
            console.log(resAdd)
            if (resAdd && resAdd.status === 200) {
                setStep(2)
                sendEmail()
                setLoading(false)
            } else {
                setLoading(false)
            }
        })
    }

    const sendEmail = () => {
        const mailData = {
            emailFrom: "dev.rocketbonds@gmail.com",
            nameFrom: "FreelanceMada",
            to: [
                {
                    Email: offre.email,
                    Name: ""
                }
            ],
            subject: "Bon de Commande et Facture d'Acompte pour Votre Mission",
            htmlText: 'Bonjour ' +offre.name+ ', <br/> <br/> ' +
                "Nous avons le plaisir de vous informer qu'un freelanceur a accepté votre mission."+
                '<br/>' +
                "Nous vous avons également joint une facture d'acompte correspondant à 30% du coût total de la mission. Vous pouvez effectuer le paiement en cliquant sur le lien suivant : "+
                '<br/>' +
                "<a href=" + "https://www.freelancemada.ovh/offre-payment?id=" + offre.id + "&email=" + email + ">Lien de Paiement.</b></a>" +
                '<br/>' +
                "Votre prompt paiement nous permettra de commencer le travail sur votre projet dans les meilleurs délais."+
                '<br/><br/>' +
                "Cordialement."+
                '<br/><br/>' +
                '<br/><br/>FreelanceMada'
        };

        maillingService.sendMailByHtmlText(mailData).then(res => {
            console.log(res)
        }).catch(err => {
            console.log(err)
        })
    }

    return (
        <div className="client-home">
            <MuiBackdrop open={loading} text={"Chargement..."} />
            <div className="header-client">
                <div className="d-flex justify-content-between align-items-center" style={{ height: "100%" }}>
                    <div className="d-flex px-2">
                        <span style={{ fontSize: 34, fontWeight: 700 }}>Build.</span><span style={{ fontSize: 34, color: "#214dcb", fontWeight: 700 }}>Mada</span>
                    </div>
                </div>
            </div>

            <div className="body-offre p-4" style={{ marginTop: 90 }}>
                <div className="row">
                    { step === 1 ?
                    <div className="col-md-6 px-3">
                        {offre &&
                            <div className="d-flex flex-column" style={{ gap: 10 }}>
                                <div className="d-flex align-items-end" style={{ gap: 5 }}>
                                    <span style={{ fontSize: 18, fontWeight: 700, fontFamily: "Montserrat" }}>Rôle: </span>
                                    <span style={{ fontSize: 16, fontWeight: 500, fontFamily: "Montserrat" }}> {offre.role} </span>
                                </div>
                                <div className="d-flex align-items-end" style={{ gap: 5 }}>
                                    <span style={{ fontSize: 18, fontWeight: 700, fontFamily: "Montserrat" }}>Date de création: </span>
                                    <span style={{ fontSize: 16, fontWeight: 500, fontFamily: "Montserrat" }}> {moment(offre.created_at).format("YYYY/MM/DD")} </span>
                                </div>
                                <div className="d-flex align-items-end" style={{ gap: 5 }}>
                                    <span style={{ fontSize: 18, fontWeight: 700, fontFamily: "Montserrat" }}>Société: </span>
                                    <span style={{ fontSize: 16, fontWeight: 500, fontFamily: "Montserrat" }}> {offre.soc_name} </span>
                                </div>
                                <div className="d-flex align-items-end" style={{ gap: 5 }}>
                                    <span style={{ fontSize: 18, fontWeight: 700, fontFamily: "Montserrat" }}>Tarif: </span>
                                    <span style={{ fontSize: 16, fontWeight: 500, fontFamily: "Montserrat" }}> {offre.tarif} </span>
                                </div>
                                <div className="d-flex align-items-end" style={{ gap: 5 }}>
                                    <span style={{ fontSize: 18, fontWeight: 700, fontFamily: "Montserrat" }}>Description: </span>
                                    <span style={{ fontSize: 16, fontWeight: 500, fontFamily: "Montserrat" }}> {offre.description} </span>
                                </div>

                                <button className="create-client-button" onClick={(e) => { updateOffre() }}>
                                    Accepter l'offre
                                </button>
                            </div>
                        }
                    </div>
                     :
                     <div className="col-md-6 px-3">
                        <div className="d-flex align-items-center" style={{gap:10}}>
                            <TaskAltIcon color="success" fontSize="large"/>
                            <span className="span-title-freemada">Offre accepté, un email est envoyé pour informer la société.</span>
                        </div>
                     </div>
                    }
                    <div className="col-md-6 px-3">
                        <img className="image-landing" src="lan.png" />
                    </div>
                </div>

            </div>
        </div>
    )
}
export default Offre_Detail;