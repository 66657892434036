
export const menu_items = [
    {
        id:1,
        uid:"oa_team",
        title: "Equipe",
        icon: "bx bx-group",
        route: "skills",
        menu:[]
    },
    {
        id:2,
        uid:"clients",
        title: "Clients",
        route: "product",
        icon: "bx bx-briefcase-alt"
    },
    {
        id:3,
        uid:"timesheets",
        title: "Emploi du temps",
        icon: "bx bx-time-five",
        menu:[]
    },
    {
        id:4,
        uid:"settings",
        title: "Recrutement freelance",
        icon: "bx bx-group",
        menu:[],
        route: "employee",
    }
]




