import React, { useEffect, useRef, useState } from 'react';
import "./Complete_Profil.css"
import { Chip } from '@material-ui/core'
import MuiBackdrop from "../../components/Loading/MuiBackdrop";
import { apiNodeService } from '../../provider/apiNodeService';
import Alert from '@mui/material/Alert';
import Avatar from '@mui/material/Avatar';
import utilFunctions from '../../tools/functions';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import moment from "moment";
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import rethink from '../../controller/rethink';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import 'moment/locale/fr'; // Importer la localisation française de Moment.js
import TextComponent from '../../components/Profil/TextComponent';
import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';


const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 13,
        fontWeight: 600,
        maxWidth: 450,
    },
}));

const Profil_Details_Porteur = () => {

    const navigate = useNavigate();

    const [loading, setLoading] = React.useState(false);
    const [mobility, setMobility] = React.useState('');
    const [prenom, setPrenom] = React.useState('');
    const [nom, setNom] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [mobile, setMobile] = React.useState('');
    const [ville, setVille] = React.useState('');
    const [description, setDescription] = React.useState('');
    const [pays, setPays] = React.useState('Madagascar');
    const [facebook, setFacebook] = React.useState('');
    const [linkedin, setLinkedin] = React.useState('');
    const [gmail, setGmail] = React.useState('');
    const [twitter, setTwitter] = React.useState('');
    const [whatsapp, setWhatsapp] = React.useState('');
    const [alertSuccess, setAlertSuccess] = React.useState(false)
    const [alertError, setAlertError] = React.useState(false)
    const [alertText, setAlertText] = useState("");
    const [photo, setPhoto] = React.useState(null)
    const [montant, setMontant] = React.useState("")
    const [user, setUser] = React.useState(null)
    const [employe, setEmploye] = React.useState(null)
    const [naissance, setNaissance] = React.useState(dayjs(moment()))
    const [codep, setCodep] = React.useState("");

    const [checked, setChecked] = React.useState(false);
    const [openPortfolioModal, setOpenPortfolioModal] = useState(false);
    const [titleModal, setTitleModal] = React.useState("");
    const [valueModal, setValueModal] = React.useState("");
    const [comments, setComments] = React.useState([]);
    const [etoileRemp, setEtoileRemp] = useState(0);
    const [langues, setLangues] = React.useState([]);
    const [missions, setMissions] = React.useState([]);
    const [missionsPorteur, setMissionsPorteur] = React.useState([]);
    const [posteActuel, setPosteActuel] = useState('');
    const [siret, setSiret] = React.useState("");
    const [userTypePorteur, setUserTypePorteur] = React.useState('');
    const [denomination, setDenomination] = React.useState("");

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const email = searchParams.get('user');
        getUserById(email)
        getMissionPorteur()
    }, []);

    const getMission = (email) => {
        rethink.getItemByLabel("FREELANCE_MAD", "mission", { email: email }).then((res) => {
            console.log(res)
            if (res) {
                // let filteredData = res.filter(item => item.state === "finished")
                setMissions(res)

            }
        })
    }

    const getMissionPorteur = () => {
        rethink.getItemByLabel("FREELANCE_MAD", "mission", { email: localStorage.getItem('email') }).then((res) => {
            console.log(res)
            if (res) {
                let filteredData = res.filter(item => item.state !== "finished")
                const sortedData = filteredData.sort((a, b) => {
                    return moment(b.created_at).diff(moment(a.created_at));
                });
                setMissionsPorteur(sortedData)
            }
        })
    }

    const getUserById = async (email) => {
        setLoading(true)
        return new Promise(resolve => {
            apiNodeService.filterTable({ table: "user_build", filter: { email: email }, db: "FREELANCE_MAD" }).then(userRes => {
                console.log(userRes)
                if (userRes && userRes !== "false" && Array.isArray(userRes.data)) {
                    console.log(userRes.data[0])
                    getUserByEmail(userRes.data[0].email, userRes.data[0].last_name, userRes.data[0].first_name)
                    setUser(userRes.data[0])
                    setNom(userRes.data[0].last_name)
                    setPrenom(userRes.data[0].first_name)
                    setNaissance(dayjs(userRes.data[0].naissance))
                    setMobile(userRes.data[0].mobile)
                    setPays(userRes.data[0].pays)
                    setEmail(userRes.data[0].email)
                    getMission(userRes.data[0].email)
                    setVille(userRes.data[0].ville)
                    setCodep(userRes.data[0].codep)
                    setPosteActuel(userRes.data[0].poste_actuel ? userRes.data[0].poste_actuel : '')
                    setDescription(userRes.data[0].description ? userRes.data[0].description : "")
                    setUserTypePorteur(userRes.data[0].user_type_porteur ? userRes.data[0].user_type_porteur : "Une personne physique")
                    resolve(userRes.data[0])
                } else {
                    setLoading(false)
                    resolve("false")
                }
            }).catch(err => resolve("false"))
        })
    }

    const getUserByEmail = async (email, last_name, first_name) => {
        return new Promise(resolve => {
            apiNodeService.filterTable({ table: "employe_odoo", filter: { email: email }, db: "FREELANCE_MAD" }).then(userRes => {
                console.log(userRes)
                if (userRes && userRes !== "false" && Array.isArray(userRes.data)) {
                    console.log(userRes.data[0])
                    if (!userRes.data[0]) {
                        let data = { email: email, last_name: last_name, first_name: first_name }
                        apiNodeService.insertTableData({ db: "FREELANCE_MAD", table: "employe_odoo", data: data }).then(resAdd => {
                            console.log(resAdd)
                            if (resAdd && resAdd.status === 200) {
                                console.log(resAdd)
                                let data = {}
                                data.id = resAdd.data.id
                                setEmploye(data)
                                console.log('true')
                            } else {
                                console.log('false')
                            }
                        })
                        setLoading(false)
                    } else {
                        setEmploye(userRes.data[0])
                        setMontant(userRes.data[0].montant ? userRes.data[0].montant : '')
                        setChecked(userRes.data[0].permis ? userRes.data[0].permis : '')
                        setMobility(userRes.data[0].mobility ? userRes.data[0].mobility : '')
                        setPhoto(userRes.data[0].photo ? userRes.data[0].photo : null)
                        setFacebook(userRes.data[0].facebook ? userRes.data[0].facebook : '')
                        setLinkedin(userRes.data[0].linkedin ? userRes.data[0].linkedin : '')
                        setWhatsapp(userRes.data[0].whatsapp ? userRes.data[0].whatsapp : '')
                        setTwitter(userRes.data[0].twitter ? userRes.data[0].twitter : '')
                        setPosteActuel(userRes.data[0].poste_actuel ? userRes.data[0].poste_actuel : '')
                        setGmail(userRes.data[0].gmail ? userRes.data[0].gmail : '')
                        setComments(userRes.data[0].comment ? userRes.data[0].comment : [])
                        getEtoileRemp(userRes.data[0].comment)
                        setSiret(userRes.data[0].siret ? userRes.data[0].siret : '')
                        setDenomination(userRes.data[0].denomination ? userRes.data[0].denomination : '')
                        setLoading(false)
                    }
                } else {
                    resolve("false")
                }
            }).catch(err => resolve("false"))
        })
    }

    const getEtoileRemp = (items) => {
        if (items && items.length > 0) {
            let i = 0;
            items.forEach((item) => {
                i += (item.satisfaction + item.communication) / 2
            })
            console.log(i)
            setEtoileRemp(i)
        }
    }

    const getUserByEmail1 = async (email) => {
        return new Promise(resolve => {
            apiNodeService.filterTable({ table: "user_build", filter: { email: email }, db: "FREELANCE_MAD" }).then(userRes => {
                console.log(userRes)
                if (userRes && userRes !== "false" && Array.isArray(userRes.data)) {
                    console.log(userRes.data[0])
                    resolve(userRes.data[0])
                } else {
                    resolve("false")
                }
            }).catch(err => resolve("false"))
        })
    }

    return (
        <>
            <MuiBackdrop open={loading} text={"Chargement..."} />
            {alertSuccess &&
                <Alert style={{ marginTop: 35 }} variant="filled" severity="success">
                    {alertText}
                </Alert>}
            {alertError &&
                <Alert style={{ marginTop: 35 }} variant="filled" severity="error">
                    Erreur, Veuillez réessayer plus tard !
                </Alert>}
            <div className="complete-profil-mada p-2">
                {user &&
                    <>
                        <div className='header-profile' style={{ marginTop: 40 }}>
                            <div className='d-flex align-items-center p-2' style={{ gap: 20 }}>
                                <ArrowBackIosIcon style={{ cursor: 'pointer' }} onClick={(e) => { navigate("/build-mada-missions") }} />
                                <div onClick={(e) => { navigate("/build-mada-missions") }} style={{ fontSize: 24, cursor: 'pointer' }} className='span-avatar'>profil de {user.first_name}</div>
                            </div>
                        </div>
                        <div className="body-complete">
                            <div className="row">
                                <div className="col-md-4 details-section">
                                    <div className='d-flex flex-column mt-3 justify-content-center'>
                                        <div className="d-flex flex-column align-items-center">
                                            {photo ?
                                                <Avatar
                                                    alt="Remy Sharp"
                                                    className="avatar-profil"
                                                    src={photo.prefix + photo.data}
                                                />
                                                : <Avatar className="avatar-profil">
                                                    {user.first_name[0]}
                                                </Avatar>
                                            }
                                            <span className='span-avatar mt-3'>{user.first_name} {user.last_name}</span>
                                            {user.poste_actuel && <span style={{ color: "#616161" }} className=' mt-1'>{user.poste_actuel}</span>}
                                            <div className='d-flex align-items-center mt-2' style={{ gap: 5 }}>
                                                <img
                                                    loading="lazy"
                                                    className='card-pays'
                                                    width="30"
                                                    srcSet={`https://flagcdn.com/w40/${pays.code.toLowerCase()}.png 2x`}
                                                    src={`https://flagcdn.com/w20/${pays.code.toLowerCase()}.png`}
                                                    alt=""
                                                />
                                            </div>
                                            {userTypePorteur === "Une personne morale" &&
                                                <div className='d-flex align-items-center mt-3' style={{ gap: 5 }}>
                                                    <Button onClick={(e) => {
                                                        window.open('https://www.pappers.fr/entreprise/' + denomination + siret.slice(0, 9), '_blank');
                                                    }} className='btn-suivre' variant="outlined" endIcon={<OpenInNewIcon />}>
                                                        En savoir plus
                                                    </Button>
                                                    <LightTooltip style={{ backgroundColor: '#fff' }} title="Si ce bouton ne fonctionne pas, c’est qu’il y a peut être une erreur dans la dénomination sociale ou le SIRET de votre société. Si tout vous semble correct, n’hésitez pas à contacter le support.">
                                                        <InfoOutlinedIcon className="mt-1" style={{ cursor: 'pointer' }} color="primary" />
                                                    </LightTooltip>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className='d-flex mt-4 mb-4 flex-column'>
                                        <div className='d-flex justify-content-start'>
                                            <span className='span-avatar mt-4'>À propos</span>
                                        </div>
                                        <span style={{ fontFamily: 'Montserrat' }} className='mt-1'>{user.description ? user.description : ""}</span>
                                    </div>

                                    <div className='d-flex mt-4 flex-column'>
                                        <div className='d-flex justify-content-start'>
                                            <span className='span-avatar mt-4'>Contacts</span>
                                        </div>
                                        {linkedin !== '' &&
                                            <div onClick={(e) => {
                                                setTitleModal("Linkedin")
                                                setValueModal(linkedin)
                                                setOpenPortfolioModal(true)
                                            }}
                                                className='d-flex justify-content-between mt-3' style={{ cursor: 'pointer' }}>
                                                <div className='d-flex align-items-center' style={{ gap: 10 }}>
                                                    <img height={24} src="/linkedin1.png" />
                                                    <span style={{ fontFamily: 'Montserrat' }}>Linkedin</span>
                                                </div>
                                                <KeyboardArrowRightIcon
                                                    style={{ color: "#616161", cursor: 'pointer' }} size="small" />
                                            </div>
                                        }
                                        {facebook !== '' &&
                                            <div
                                                onClick={(e) => {
                                                    setTitleModal("Facebook")
                                                    setValueModal(facebook)
                                                    setOpenPortfolioModal(true)
                                                }}
                                                className='d-flex justify-content-between mt-2' style={{ cursor: 'pointer' }}>
                                                <div className='d-flex align-items-center' style={{ gap: 10 }}>
                                                    <img height={24} src="/facb.png" />
                                                    <span style={{ fontFamily: 'Montserrat' }}>Facebook</span>
                                                </div>
                                                <KeyboardArrowRightIcon
                                                    style={{ color: "#616161", cursor: 'pointer' }} size="small" />
                                            </div>
                                        }
                                        {twitter !== '' &&
                                            <div
                                                onClick={(e) => {
                                                    setTitleModal("Twitter")
                                                    setValueModal(twitter)
                                                    setOpenPortfolioModal(true)
                                                }}
                                                className='d-flex justify-content-between mt-2' style={{ cursor: 'pointer' }}>
                                                <div className='d-flex align-items-center' style={{ gap: 10 }}>
                                                    <img height={24} width={24} src="/twitter.png" />
                                                    <span style={{ fontFamily: 'Montserrat' }}>Twitter</span>
                                                </div>
                                                <KeyboardArrowRightIcon
                                                    style={{ color: "#616161", cursor: 'pointer' }} size="small" />
                                            </div>
                                        }
                                        {whatsapp !== '' &&
                                            <div
                                                onClick={(e) => {
                                                    setTitleModal("Whatsapp")
                                                    setValueModal(whatsapp)
                                                    setOpenPortfolioModal(true)
                                                }}
                                                className='d-flex justify-content-between mt-2' style={{ cursor: 'pointer' }}>
                                                <div className='d-flex align-items-center' style={{ gap: 10 }}>
                                                    <img height={24} src="/whatsapp.png" />
                                                    <span style={{ fontFamily: 'Montserrat' }}>Whatsapp</span>
                                                </div>
                                                <KeyboardArrowRightIcon
                                                    style={{ color: "#616161", cursor: 'pointer' }} size="small" />
                                            </div>
                                        }
                                        {email !== '' &&
                                            <div
                                                onClick={(e) => {
                                                    setTitleModal("Mail")
                                                    setValueModal(email)
                                                    setOpenPortfolioModal(true)
                                                }}
                                                className='d-flex justify-content-between mt-2' style={{ cursor: 'pointer' }}>
                                                <div className='d-flex align-items-center' style={{ gap: 10 }}>
                                                    <img height={24} src="/gmail.png" />
                                                    <span style={{ fontFamily: 'Montserrat' }}>Mail</span>
                                                </div>
                                                <KeyboardArrowRightIcon
                                                    style={{ color: "#616161", cursor: 'pointer' }} size="small" />
                                            </div>
                                        }
                                    </div>
                                </div>

                                <div className="col-md-8 mt-2">
                                    <span className='span-info-perso'>ACTIVITÉ</span>
                                    <div className='row mb-2'>
                                        <div className='col-md-6 p-3'>
                                            <div className='card-detail-profil p-2'>
                                                <div className='d-flex flex-column'>
                                                    <span className='span-detail-profil'>{missions.length}</span>
                                                    <span>Missions postées</span>
                                                    <div className='d-flex' style={{ gap: 10 }}>
                                                        <img width={12} src='/flechgreen.svg' />
                                                        <span style={{ fontSize: 10 }}>dont {missions.length} le dernier mois</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-6 p-3'>
                                            <div className='card-detail-profil p-2'>
                                                <div className='d-flex flex-column'>
                                                    <span className='span-detail-profil'>{etoileRemp}</span>
                                                    <span>Étoiles remportées</span>
                                                    <div className='d-flex' style={{ gap: 10 }}>
                                                        <img width={12} src='/flechgreen.svg' />
                                                        <span style={{ fontSize: 10 }}>dont {etoileRemp} le dernier mois</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='mt-4'>
                                        <span className='span-info-perso mt-4 uppercase-text'>DERNIÈRES Missions postées</span>
                                    </div>

                                    {
                                        missions.length > 0 ?
                                            <div className="d-flex flex-column px-2" style={{ gap: 20 }}>
                                                {missions.map((item, key) => (
                                                    <div key={key}>
                                                        <div className="p-3 card-profil">
                                                            <div className="d-flex justify-content-between">
                                                                <span className="span-avatar">{item.title}</span>
                                                                {/* <div className="d-flex flex-column justify-content-end">
                                                                <span className="span-avatar">{item.montant} MGA</span>
                                                                {parseInt(item.montant) > 0 && <span className="span-avatar" style={{ fontSize: 16, textAlign: "right" }}>{(parseInt(item.montant) / 4870).toFixed(2)} EUROS</span>}
                                                            </div> */}
                                                            </div>
                                                            <div className="mt-2">
                                                                <span style={{ fontFamily: 'Montserrat', fontSize: 14, fontWeight: 700 }}>{item.description}</span>
                                                            </div>
                                                            {item.competence &&
                                                                <div className="d-flex flex-wrap mt-2" style={{ gap: 4 }}>
                                                                    {(item.competence || []).map((logiciel, key1) => (
                                                                        <div key={key1}>
                                                                            <Chip size="small" style={{ color: "#1565C0", borderColor: "#1565C0" }}

                                                                                variant="outlined" label={logiciel} />
                                                                        </div>
                                                                    ))
                                                                    }
                                                                </div>
                                                            }
                                                            <div className="d-flex justify-content-between mt-2">
                                                                <div>
                                                                    <span style={{ fontFamily: 'Montserrat', fontSize: 13, fontWeight: 700 }}>Niveau d'expérience: </span>
                                                                    <span style={{ fontSize: 13, fontWeight: 700 }}>{item.niveau}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* <div style={{ fontSize: 12, color: 'grey' }}>
                                                        {moment(item.created_at).fromNow()}
                                                    </div> */}
                                                    </div>
                                                ))
                                                }
                                            </div>
                                            :
                                            <span style={{ fontFamily: 'Montserrat', fontSize: 14, fontWeight: 600 }}>Aucune mission postée pour le moment.</span>
                                    }

                                    <span className='span-info-perso'>RETOURS</span>
                                    {comments.length > 0 ?
                                        <div className='row'>
                                            {comments.map((item, key) => (
                                                <div key={key} className='col-md-6 p-3'>
                                                    <div className='card-detail-profil p-2'>
                                                        <div className='d-flex' style={{ gap: 10 }}>
                                                            <Avatar className="avatar-comment">
                                                                {item.user_first_name[0] + item.user_last_name[0]}
                                                            </Avatar>
                                                            <div className='d-flex flex-column'>
                                                                <span className='span-user-comment'>{item.user_first_name + " " + item.user_last_name}</span>
                                                                <div className='d-flex align-items-center' style={{ gap: 5 }}>
                                                                    <img alt="star" width={17} height={17} src='/star.png' />
                                                                    <span style={{ fontFamily: 'Montserrat', fontSize: 12 }}>{(parseInt(item.communication) + parseInt(item.satisfaction)) / 2} / 5</span>
                                                                </div>
                                                                <p className='m-0' style={{ fontFamily: 'Montserrat', fontSize: 12 }}>{item.description}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                            }
                                        </div>
                                        :
                                        <p className='m-0' style={{ fontFamily: 'Montserrat', fontSize: 12 }}>Aucun commentaire jusqu'à présent</p>
                                    }
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>

            <Dialog
                open={openPortfolioModal}
                aria-labelledby="form-dialog-title"
                fullWidth={true}
                maxWidth="sm"
                onClose={(e) => { setOpenPortfolioModal(false) }}
            >
                <DialogTitle id="form-dialog-title" style={{ paddingBottom: 1 }}>
                    <div className='d-flex' style={{ gap: 10 }}>
                        <Typography style={{ color: '#1565C0', fontWeight: 600 }}>{titleModal} </Typography>
                    </div>
                    <IconButton
                        aria-label="close"
                        style={{
                            position: 'absolute',
                            right: 5,
                            top: 5,
                            color: '#c0c0c0'
                        }}
                        onClick={() => {
                            setOpenPortfolioModal(false)
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <hr style={{ color: "#1565C0" }} />
                </DialogTitle>
                <DialogContent style={{ overflowY: "inherit" }}>
                    <>
                        <div className='p-4'>
                            <span style={{ color: "#616161", fontSize: 16, fontWeight: 600 }}>{valueModal}</span>
                        </div>

                    </>
                </DialogContent>

            </Dialog>

        </>
    )
}
export default Profil_Details_Porteur;