import rethink from "../controller/rethink";

const db_name = process.env.REACT_APP_RETHINK_DB_NAME

let rethinkFunctions = {

    addB64File(table_name,created_at,created_by,b64,name,type,uid,extra,extra_value){
        return new Promise( resolve => {

            let newFile = {
                created_at: created_at,
                created_by: created_by,
                b64: b64,
                name: name,
                type:type,
                uid: uid,
                [extra]:extra_value
            }
            console.log(newFile)
            rethink.insert("test", 'table("'+ table_name +'").insert(' + JSON.stringify(newFile) + ')', db_name, false).then( resAdd => {
                console.log(resAdd)
                if (resAdd && resAdd === true) {
                    resolve(newFile.uid)
                }else resolve("false")
            }).catch( err => {
                console.log(err)
                resolve("false")
            })

        })
    },

    getB64File(uid){
        return new Promise( resolve => {
            rethink.getItemByLabel(db_name,"documents","uid",uid,"test").then( res => {
                if (res && res !== "false" && Array.isArray(res) && res.length > 0) {
                    resolve(res[0])
                } else {
                    resolve("false")
                }
            }).catch( err => {
                resolve("false")
            })
        })
    },

    getUserProfileByEmail(email){
        return new Promise( resolve => {
            if(email){
                rethink.getItemByLabel(db_name,"users","email",email,"test").then( res => {
                    if(res && res !== "false" && Array.isArray(res) && res.length > 0){
                        let user = res[0]
                        resolve({
                            id:user.id,
                            fname:user.firstname + " " + user.lastname,
                            photo:user.photo
                        })
                    }else{
                        resolve("false")
                    }
                }).catch( err => {
                    resolve("false")
                })
            }else{
                resolve("false")
            }

        })
    },

    searchItemByIdLabel(table,label,id){
        return new Promise( resolve => {
            rethink.getItemByLabel(db_name,table,label,id,"test").then( res => {
                if(res && res !== "false" && Array.isArray(res) && res.length > 0){
                    let item = res[0]
                    resolve(item)
                }else{
                    resolve("false")
                }
            }).catch( err => {
                resolve("false")
            })
        })
    }
}

export default rethinkFunctions