import React, { useEffect, useState } from "react";
import StripePaymentComponent from "./StripePaymentComponent";
import MuiBackdrop from "../Loading/MuiBackdrop";
import "./style.css";


export default function StripePayment(props) {

    const [loading, setLoading] = useState(false);
    const [process, setProcess] = useState("wait");

    const confirmPayment = (token_data, method_data, email) => {
        // this.setState({ loading: true })
        setTimeout(() => {
            this.setState({ loading: false, process: "done" })
        }, 3000)
    }

    useEffect(() => {
        console.log("im in child11")
    }, [props.payToChild]);

    return (
        <div>
            <MuiBackdrop open={loading === true} />
            <div align="center">
                <div>
                    
                    <div>
                        <StripePaymentComponent
                            payToChild={props.payToChild}
                            amount={props.amount}
                            email={props.email}
                            desc={props.desc}
                            image={props.logo}
                            onPayment={(data, method_data, email) => {
                                props.onConfirmPayment(data, method_data, email)
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    )

}