exports.categoriesFreelance = [
    { label: "Revit" },
    { label: 'AutoCAD' },
    { label: "SketchUp" },
    { label: "Photoshop" },
    { label: 'Rhino 3D' },
    { label: 'ArchiCAD' },
    { label: "Blender" },
    { label: 'React js' },
    { label: 'Architect' },
    { label: 'CATIA' },
    { label: 'Angular' },
    { label: "V-Ray" },
    { label: "Trello" },
    { label: "Devops" },
    { label: "Node js" },
    { label: "Perspectiviste 3" }
]