import React, { useEffect } from "react"
import { apiNodeService } from "../../provider/apiNodeService"
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import MuiBackdrop from "../../components/Loading/MuiBackdrop";

const Employee_Hr = () => {

    const [loading, setLoading] = React.useState(true);
    const [employee, setEmployee] = React.useState([])

    useEffect(() => {
        getOdooEmployee()
    }, [])

    const getOdooEmployee = () => {
        setLoading(true)
        apiNodeService.getEmployeeOdoo().then(res => {
            console.log(res)
            if (res && res.status === 200) {
                setEmployee(res.data.records)
                setLoading(false)
            } else {
                console.log("erreur survenu")
                setLoading(false)
            }
        })
    }

    return (
        <div style={{ marginTop: 60,overflowY:'auto', maxHeight: '85vh' }}>
            <MuiBackdrop open={loading} text={"Chargement..."} />
            <div className="title-equipe d-flex justify-content-between px-3 mb-4">
                        <span>
                            Employés RH Odoo
                        </span>
                    </div>
                    <hr style={{ color: "#7293b3" }} className="mb-4" />

            {   employee.length > 0 ?
                <div className="p-3 d-flex flex-wrap mb-5" style={{gap:30}}>
                {
                    employee.map((item, key) => (
                        <Card sx={{ minWidth: 360, maxWidth: 360 }}>
                            <CardContent>
                                <div className="d-flex flex-column">
                                    <div className="d-flex flex-wrap align-items-center" style={{gap:10}}>
                                        <span style={{fontWeight:600,fontSize:14,color:"#1565C0"}}>Nom d'utilisateur :</span>
                                        <span style={{fontWeight:600,fontSize:12,color:"#495057"}}>{item.name}</span>
                                    </div>
                                    <div className="d-flex flex-wrap" style={{gap:10}}>
                                        <span style={{fontWeight:600,fontSize:14,color:"#1565C0"}}>Email :</span>
                                        <span style={{fontWeight:600,fontSize:12,color:"#495057"}}>{item.work_email}</span>
                                    </div>
                                    <div className="d-flex flex-wrap" style={{gap:10}}>
                                        <span style={{fontWeight:600,fontSize:14,color:"#1565C0"}}>Dernière modification :</span>
                                        <span style={{fontWeight:600,fontSize:12,color:"#495057"}}>{item.__last_update}</span>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                    ))
                }

            </div> :
            <div className="p-3">
                <span>Aucun employé disponible !</span>
            </div>
            }
        </div>
    )

}
export default Employee_Hr;