import React, { useEffect, useRef, useState } from "react";
import "./Client_Home.css"
import { useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import MuiBackdrop from "../../../components/Loading/MuiBackdrop";
import { apiNodeService } from "../../../provider/apiNodeService";
import Avatar from '@mui/material/Avatar';
import HomeRepairServiceOutlinedIcon from '@mui/icons-material/HomeRepairServiceOutlined';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import InputAdornment from '@mui/material/InputAdornment';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const Repertoire = () => {

    const navigate = useNavigate();
    const [loading, setLoading] = React.useState(false);
    const [employes, setEmployes] = React.useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [filteredDataMontant, setFilteredDataMontant] = useState([]);
    const [filteredDataNiveau, setFilteredDataNiveau] = useState([]);
    const [startIndex, setStartIndex] = useState(0);
    const [checkedNiveau, setCheckedNiveau] = React.useState(0);
    const [checkedPrice, setCheckedPrice] = React.useState('0,100000000');
    const [logicielFilter, setLogicielFilter] = useState('');
    const [openSelect1, setOpenSelect1] = useState(false);
    const [openSelect2, setOpenSelect2] = useState(false);
    const pageTopRef = useRef(null);

    const itemsPerPage = 18;

    const totalTranches = Math.ceil(filteredDataNiveau.length / itemsPerPage);

    useEffect(() => {
        getUsers()
    }, []);

    const getUsers = () => {
        setLoading(true)
        apiNodeService.getTableData({ db: "FREELANCE_MAD", table: "employe_odoo" }).then((res) => {
            console.log(res)
            if (res && res.status === 200) {
                const freelanceUsers = res.data.filter(user => user.role === 'freelance');
                let sortedData = freelanceUsers.sort((a, b) => {
                    if (a.logiciel && b.logiciel) {
                        return b.logiciel.length - a.logiciel.length;
                    } else if (a.logiciel) {
                        return -1; // Place les objets sans logiciel après ceux avec logiciel
                    } else if (b.logiciel) {
                        return 1; // Place les objets avec logiciel avant ceux sans logiciel
                    } else {
                        return 0; // Garde l'ordre si aucun des deux objets n'a de logiciel
                    }
                });
                setEmployes([...sortedData])
                setFilteredData([...sortedData])
                setFilteredDataMontant([...sortedData])
                setFilteredDataNiveau([...sortedData])
            }
            setLoading(false)
        })
    }

    const handleOpenSelect1 = () => {
        console.log(openSelect1)
        setOpenSelect1(true);
    };

    const handleCloseSelect1 = () => {
        console.log(openSelect1)
        setOpenSelect1(false);
    };

    const handleOpenSelect2 = () => {
        setOpenSelect2(true);
    };

    const handleCloseSelect2 = () => {
        setOpenSelect2(false);
    };

    const handleClickNext = () => {
        if ((startIndex + itemsPerPage >= filteredDataNiveau.length) === false) {
        const nextIndex = Math.min(startIndex + itemsPerPage, filteredDataNiveau.length);
        setStartIndex(nextIndex);
        console.log(nextIndex)
        scrollToTop()
    }
    };

    const handleClickPrev = () => {
        if (startIndex > 0) {
        const prevIndex = Math.max(startIndex - itemsPerPage, 0);
        setStartIndex(prevIndex);
        scrollToTop()
        }
    };

    const resetFilter = () => {
        setCheckedPrice('0,100000000')
        setCheckedNiveau(0)
        setFilteredDataNiveau(employes)
    }

    const scrollToTop = () => {
        pageTopRef.current.scrollTo(0, 170)
    };

    const handleFilterChange = (event) => {
        const { value } = event.target;
        setLogicielFilter(value);
        filterByLogiciel(value);
    };

    const filterByLogiciel = (filterValue) => {
        console.log(filterValue)
        const filteredArray = employes.filter(item =>
            !filterValue || // Added check for empty filter value
            (item.logiciel &&
                item.logiciel.some(logiciel =>
                    logiciel.toLowerCase().includes(filterValue.toLowerCase())
                ))
            || filterValue.toLowerCase().replace(/é/g, 'e').split(/\s+/).every(word =>
                (item.first_name.toLowerCase().replace(/é/g, 'e')).includes(word) ||
                (item.last_name.toLowerCase().replace(/é/g, 'e')).includes(word)
            )
        )
        console.log(filteredArray)
        setFilteredData(filteredArray);
        setFilteredDataMontant(filteredArray)
        filterByMontant(checkedPrice, filteredArray)
    };


    const filterByMontant = (value, data) => {
        console.log(filteredData);
        let defaultValue = '0,100000000'
        let filterValue = value ? value.split(',').map(Number) : defaultValue.split(',').map(Number);
        console.log(filterValue)
        const filteredArray = data.filter(item => {
            if (!filterValue) {
                return true; // Retourne true si aucun filtre n'est défini
            }

            if (filterValue[0] === 0) {
                // Retourne true si le filtre est défini à 0 (inclut les objets sans montant)
                return !item.montant || (item.montant >= filterValue[0] && item.montant <= filterValue[1]);
            }

            // Retourne true si le montant de l'élément est dans la plage spécifiée
            return item.montant && (item.montant >= filterValue[0] && item.montant <= filterValue[1]);
        });

        console.log(filteredArray);
        setFilteredDataMontant(filteredArray);
        filterByNiveau(checkedNiveau, filteredArray)
    };

    const filterByNiveau = (filterValue, data) => {
        console.log(filterValue);

        const filteredArray = data.filter(item => {
            if (!filterValue) {
                return true;
            }

            if (filterValue === '' || 0) {
                // Retourne true si le filtre est défini à 0 (inclut les objets sans montant)
                return !item.niveau || item.niveau;
            }

            // Retourne true si le montant de l'élément est dans la plage spécifiée
            return item.niveau && (item.niveau === filterValue);
        });

        console.log(filteredArray);
        setFilteredDataNiveau(filteredArray);
    };



    const handleChangeMontant = (event) => {
        console.log(event.target.value)
        setCheckedPrice(event.target.value ? event.target.value : '0,100000000');
        filterByMontant(event.target.value, filteredData)
    };


    const filterFreelanceFooter = (value) => {
        console.log(value)
        navigate("/freelance-mada?cat='" + value + "'")
    }

    return (
        <div className="client-home" ref={pageTopRef}>
            <MuiBackdrop open={loading} text={"Chargement..."} />
            <div className="header-client">
                    <div className="d-flex justify-content-between align-items-center px-2" style={{ height: "100%" }}>
                        <div className="d-flex px-2">
                            <span style={{ fontSize: 34, fontWeight: 700 }}>Build.</span><span style={{ fontSize: 34, color: "#214dcb", fontWeight: 700 }}>Mada</span>
                        </div>
                        <div className="d-flex align-items-center px-2" style={{ gap: 20 }}>
                            <button className="create-client-button" onClick={(e) => { navigate('/build-mada-signup') }}>
                                Créer un compte
                            </button>
                            <div style={{ borderBottom: "2px solid #214dcb", padding: "2px 8px 4px 8px" }}>
                                <span className="span-link1" style={{ fontWeight: 700, color: "#214dcb" }} onClick={(e) => { navigate('/build-mada') }}>Me connecter</span>
                            </div>
                        </div>
                    </div>
                </div>
            <div className="body-client p-4" style={{marginTop:60,minHeight:500}}>
            <div className="row justify-content-center mt-3">
                        <div className="col-md-6">
                            <div className="d-flex flex-column">
                                <span className="span-title-freemada p-2" style={{ textAlign: "center" }}>Répertoire de nos <span style={{color:"#1565C0"}}>freelances</span></span>
                                <div className="mb-2 align-self-center" style={{ borderBottom: " 2px solid #1565C0", width: "70%" }} />
                                <button style={{ width: "50%", letterSpacing: 1, minHeight: 40, gap: 10 }} className="create-client-button d-flex justify-content-center align-items-center  align-self-center mt-2 mb-2" onClick={(e) => { navigate('/build-mada-signup') }}>
                                    Je deviens un.e freelance !
                                    <ArrowForwardIosIcon style={{ width: 18 }} />
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="d-flex justify-content-center mt-4 px-3 mb-4" style={{ gap: 10 }}>
                        <TextField
                            value={logicielFilter}
                            onChange={handleFilterChange}
                            id="input-with-icon-textfield"
                            placeholder="Rechercher par mot clés"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon style={{ color: "#1565C0" }} />
                                    </InputAdornment>
                                ),
                            }}
                            style={{ width: 250 }}
                            variant="outlined"
                            size="small"
                        />
                        <FormControl sx={{ minWidth: 200 }} size="small">

                            <Select
                                autoWidth
                                labelId="demo-select-small-label"
                                id="demo-select-small"
                                value={checkedPrice}
                                onChange={handleChangeMontant}
                                IconComponent={openSelect1 === true ? KeyboardArrowUpIcon : KeyboardArrowDownIcon}
                                onClose={handleCloseSelect1}
                                onOpen={handleOpenSelect1}
                                sx={{
                                    '& .MuiSvgIcon-root': {
                                        color: 'blue', // Change the color of the icon
                                        fontSize: 20, // Change the font size of the icon
                                    },
                                }}
                            >
                                <MenuItem disabled value={'0,100000000'}>
                                    Prix
                                </MenuItem>
                                <MenuItem value={'0,1000'}>0 - 1000 MGA/h</MenuItem>
                                <MenuItem value={'1000,10000'}>1000 - 10000 MGA/h</MenuItem>
                                <MenuItem value={'10000,100000000'}>10000+ MGA/h</MenuItem>
                                <MenuItem onClick={(e) => { setCheckedPrice('0,100000000') }}>
                                    Tous
                                </MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl sx={{ minWidth: 250 }} size="small">
                            <Select
                                labelId="demo-select-small-label"
                                id="demo-select-small"
                                value={checkedNiveau}
                                onChange={(e) => {
                                    let selected = e.target.value ? e.target.value : 0
                                    setCheckedNiveau(selected)
                                    console.log(e.target.value)
                                    filterByNiveau(selected, filteredData)
                                }}
                                IconComponent={openSelect2 === false ? KeyboardArrowDownIcon : KeyboardArrowUpIcon}
                                onClose={handleCloseSelect2}
                                onOpen={handleOpenSelect2}
                                sx={{
                                    '& .MuiSvgIcon-root': {
                                        color: 'blue', // Change the color of the icon
                                        fontSize: 20, // Change the font size of the icon
                                    },
                                }}
                            >
                                <MenuItem disabled value={0}>Catégories</MenuItem>
                                <MenuItem value={"Junior (0-2ans)"}>Junior (0-2ans)</MenuItem>
                                <MenuItem value={"Confirmé (3-7ans)"}>Confirmé (3-7ans)</MenuItem>
                                <MenuItem value={"Senior (8-12ans)"}>Senior (8-12ans)</MenuItem>
                                <MenuItem value={"Expert (13ans et +)"}>Expert (13ans et +)</MenuItem>
                                <MenuItem onClick={(e) => { setCheckedNiveau(0) }}>Tous</MenuItem>
                            </Select>
                        </FormControl>
                        {(checkedPrice !== "0,100000000" || checkedNiveau !== 0) &&
                            <div className="d-flex align-items-center" style={{ gap: 5, cursor: 'pointer', marginLeft: 10 }} onClick={resetFilter}>
                                <RestartAltIcon style={{ color: "#1565C0", width: 24, height: 24 }} />
                                <span style={{ color: "#1565C0", fontSize: 15, fontWeight: 500 }}>Réinitialiser les filtres</span>
                            </div>}
                    </div>
                    <div className="d-flex px-4 justify-content-center">
                        <div style={{ borderBottom: " 2px solid #e1e1e1", width: "100%" }} />
                    </div>

                    {employes.length > 0 &&
                        <>
                            {filteredDataNiveau.length > 0 ?
                                <div className="row px-4 mt-4 mb-3">
                                    {filteredDataNiveau.slice(startIndex, startIndex + itemsPerPage).map((item, key) => (
                                        <div className="col-md-4 mt-2 mb-4" key={key}>
                                            <div className="p-2 card-profil">
                                                <div className="row px-2">
                                                    <div className="col-lg-4 py-2">
                                                        <div className="d-flex flex-column">
                                                            <Avatar
                                                                variant="square"
                                                                sx={{ width: "100%", height: 115, cursor: 'pointer' }}
                                                                alt={item.first_name}
                                                                src={item.photo ? item.photo.prefix + item.photo.data : ''}
                                                            />

                                                        </div>
                                                    </div>
                                                    <div className="col-lg-8">
                                                        <div className="d-flex flex-column" style={{ height: 200 }}>
                                                            <span className='span-avatar mt-2' style={{ textAlign: 'left' }}>{item.first_name} {item.last_name}</span>
                                                            <span className='span-job mt-1' style={{ textAlign: 'left' }}>{item.poste_actuel ? item.poste_actuel : 'Freelance'}</span>
                                                            {/* <span className='span-job mt-1' style={{ textAlign: 'left' }}>Architecte solaire</span> */}
                                                            {/* <span className='span-description mt-1' style={{ textAlign: 'left' }}>{item.description}</span> */}
                                                            <div className='d-flex align-items-center mt-2' style={{ gap: 2 }}>
                                                                <img width={15} alt="star" src='/star.png' />
                                                                <span style={{ fontFamily: 'Montserrat', fontSize: 12 }}>{item.comment ? (item.comment[0].satisfaction + item.comment[0].communication) / 2 + "/5" : "0/5"} {item.comment ? "(" + item.comment.length + " avis)" : "(0 avis)"}</span>
                                                            </div>
                                                            <div className="d-flex flex-column mt-auto" style={{ width: "100%" }}>
                                                                {/* {item.montant &&
                                                                    <div className="d-flex align-items-center" style={{ gap: 10 }}>
                                                                        <HomeRepairServiceOutlinedIcon style={{ color: "#1565C0" }} />
                                                                        <div className="d-flex flex-column">
                                                                            <span style={{ fontWeight: 600, color: "#a5a5a5" }}>A PARTIR DE</span>
                                                                            <span style={{ fontWeight: 600 }}>{item.montant ? (parseInt(item.montant) / 4870).toFixed(2) : 0}€ / jour</span>
                                                                        </div>
                                                                    </div>
                                                                } */}
                                                                <button
                                                                    onClick={(e) => { navigate("/build-mada-profil-detail-home?user=" + item.uid, { replace: true }) }}
                                                                    style={{ letterSpacing: 1, gap: 10 }} className="contact-freelance-button  w-100 d-flex justify-content-center align-items-center  align-self-center mt-2 mb-2">
                                                                    Voir le profil
                                                                    <ArrowForwardIosIcon fontSize="small" style={{ marginLeft: 8 }} />
                                                                </button>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))

                                    }
                                </div>

                                :
                                <span style={{ fontFamily: "Montserrat", color: "black", marginLeft: 16 }}>Aucun résultat correspondant à votre recherche</span>
                            }
                        </>
                    }

                    {filteredDataNiveau.length > 0 &&
                        <div className="d-flex justify-content-center align-items-center mt-5" style={{ gap: 20 }}>
                            <ArrowBackIosNewOutlinedIcon style={{ cursor: 'pointer' }} onClick={handleClickPrev} disabled={startIndex === 0} />
                            <div className="d-flex" style={{ gap: 4 }}>
                                {Array.from({ length: totalTranches }).map((_, i) => (
                                    <button className="button-paginator-num" style={{ backgroundColor: (i === startIndex / itemsPerPage) ? '#1565C0' : '', color: (i === startIndex / itemsPerPage) ? '#fff' : '#000', fontWeight: 500, borderRadius: 2 }} key={i}
                                        onClick={() => {
                                            setStartIndex(i * itemsPerPage)
                                            scrollToTop()
                                        }}
                                        disabled={startIndex === i * itemsPerPage}
                                    >
                                        {i + 1}
                                    </button>
                                ))}
                            </div>
                            <ArrowForwardIosIcon style={{ cursor: 'pointer' }} onClick={handleClickNext} disabled={startIndex + itemsPerPage >= filteredDataNiveau.length} />
                        </div>
                    }
                    </div>
                    <footer className="footer mt-4">
                    <div className="row px-4">
                        <p style={{ fontSize: 26, fontWeight: 700 }} className="investisseurs">Freelance Mada</p>
                        <p style={{ fontSize: 18 }}>Synchronisez vos besoins avec les compétences idéales</p>
                    </div>
                    <div className="row align-items-start p-4" style={{ width: "100%" }}>
                        <div className="col-md-12">
                            <div className="d-flex flex-column">
                                <p style={{ fontSize: 26, fontWeight: 700, marginTop: 40 }} className="investisseurs mb-3">Catégories</p>
                                <p onClick={(e) => { filterFreelanceFooter('Création artistique') }} style={{ fontSize: 18, cursor: 'pointer' }}>Création artistique</p>
                                <p onClick={(e) => { filterFreelanceFooter('Vidéo et animation') }} style={{ fontSize: 18, cursor: 'pointer' }}>Vidéo et animation</p>
                                <p onClick={(e) => { filterFreelanceFooter('Data') }} style={{ fontSize: 18, cursor: 'pointer' }}>Data</p>
                                <p onClick={(e) => { filterFreelanceFooter('Programmation') }} style={{ fontSize: 18, cursor: 'pointer' }}>Programmation</p>
                                <p onClick={(e) => { filterFreelanceFooter('Marketing') }} style={{ fontSize: 18, cursor: 'pointer' }}>Marketing</p>
                                <p onClick={(e) => { filterFreelanceFooter('Énergies') }} style={{ fontSize: 18, cursor: 'pointer' }}>Énergies</p>
                                <p onClick={(e) => { filterFreelanceFooter('Comptabilité') }} style={{ fontSize: 18, cursor: 'pointer' }}>Comptabilité</p>
                                <p onClick={(e) => { filterFreelanceFooter('Musique et audio') }} style={{ fontSize: 18, cursor: 'pointer' }}>Musique et audio</p>
                                <p onClick={(e) => { filterFreelanceFooter('Business') }} style={{ fontSize: 18, cursor: 'pointer' }}>Business</p>
                                <p onClick={(e) => { filterFreelanceFooter('Luxe') }} style={{ fontSize: 18, cursor: 'pointer' }}>Luxe</p>
                                <p onClick={(e) => { filterFreelanceFooter('Artisanat') }} style={{ fontSize: 18, cursor: 'pointer' }}>Artisanat</p>

                                <div className="d-flex mt-4" style={{ gap: 50 }}>
                                    <img src="/facebookfooter.png" />
                                    <img src="/twitterfooter.png" />
                                    <img src="/instagramfooter.png" />
                                    <img src="/linkedinfooter.png" />
                                    <img src="/youtubefooter.png" />
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-md-3">
                            <p style={{ fontSize: 26, fontWeight: 700, marginTop: 40 }} className="investisseurs">Découvrir Freelance Mada</p>
                            <p style={{ fontSize: 18 }}>Soutiens & partenaires</p>
                            <p style={{ fontSize: 18 }}>Nous rejoindre</p>
                            <p style={{ fontSize: 18 }}>Notre approche</p>
                            <p style={{ fontSize: 18 }}>Nous contacter</p>
                        </div>
                        <div className="col-md-3">
                            <p style={{ fontSize: 26, fontWeight: 700, marginTop: 40 }} className="investisseurs">Mentions légales</p>
                            <p style={{ fontSize: 18 }}>Politique de confidentialité</p>
                            <p style={{ fontSize: 18 }}>Mentions légales</p>
                            <p style={{ fontSize: 18 }}>CGV</p>
                        </div> */}
                    </div>
                    <p style={{ fontSize: 18, marginTop: 30 }}>©2023-2024  Freelance Mada | Tout droits reservés</p>
                </footer>
        </div>
    )
}
export default Repertoire;