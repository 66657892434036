import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
  Navigate,
} from "react-router-dom";
import './App.css';
import Annuaire_List from "./pages/Annuaire/Annuaire_List";
import ExcelToJsonConverter from "./pages/ExcelToJsonConverter";
import ExcelToJsonConverter5c from "./pages/ExcelToJsonConverter5c";
import Main from "./pages/Main/Main";
import Freelance_List from "./pages/Freelance/Freelance_List";
import ExcelToJson2c from "./pages/ExcelToJson2c";
import Login from "./pages/auth/Login";
import Signup from "./pages/auth/signup";
import Product from "./pages/Products/Products";
import Employee_Hr from "./pages/Employee_Hr/Employee_Hr";
import Complete_Profil from "./pages/Build_Mada/Complete_Profil";
import Login_Build from "./pages/Build_Mada/auth/login_build";
import Signup_Build from "./pages/Build_Mada/auth/signup_build";
import { useEffect } from "react";
import { gapi } from "gapi-script";
import Main1 from "./pages/Main1/Main1";
import Profile_List from "./pages/Build_Mada/Profile_List";
import Mission_List from "./pages/Build_Mada/Mission_List";
import Porteur from "./pages/Build_Mada/Porteur";
import Profil_Details from "./pages/Build_Mada/Profil_Details";
import Discuss_Main from "./pages/Discuss/Discuss_Main";
import Chat from "./pages/Discuss/Chat";
import Main2 from "./pages/Main1/Main2";
import Client_Home from "./pages/Build_Mada/client/Client_Home";
import Client_Form from "./pages/Build_Mada/client/Client_Form";
import Offre_Detail from "./pages/Build_Mada/offre/Offre_Detail";
import Offre_Payment from "./pages/Build_Mada/offre/Offre_Payment";
import StripePaymentPage from "./pages/stripe/StripePaymentPage";
import Payment_Success from "./pages/stripe/Payment_Success";
import Profile_List_View from "./pages/Build_Mada/Profile_List_View";
import Profil_Details_View from "./pages/Build_Mada/Profil_Details_View";
import Profil_Details_Porteur from "./pages/Build_Mada/Profil_Details_Porteur";
import Profil_Details_Home from "./pages/Build_Mada/Profil_Details_Home";
import Repertoire from "./pages/Build_Mada/client/Repertoire";
import Forget_Password from "./pages/Build_Mada/auth/forget_password";
import Update_Password from "./pages/Build_Mada/auth/update_password";
import UploadDocToOdoo from "./pages/UploadDocToOdoo";

function App() {

  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    console.log(process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID)
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
    gapi.load("client:auth2", () => {
      gapi.client.init({
        clientId: process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID,
        plugin_name: "chat",
      });
    });
  }, [action, pathname]);

  return (
    <Routes>
      <Route path="/" element={<Navigate to="/home-freelance" />} />
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/forgot-password" element={<Forget_Password />} />
      <Route path="/update_password/:table/:token" element={<Update_Password />} />
      <Route path="/upload-odoo" element={<UploadDocToOdoo />} />
      <Route path="home" element={<Main />}>
        <Route path="skills" element={<Freelance_List />} />
        <Route path="product" element={<Product />} />
        <Route path="employee" element={<Employee_Hr />} />
      </Route>
      <Route path="/annuaire" element={<Annuaire_List />} />
      <Route path="/converter" element={<ExcelToJsonConverter />} />
      <Route path="/converter5c" element={<ExcelToJsonConverter5c />} />
      <Route path="/converter2c" element={<ExcelToJson2c />} />
      <Route path="/build-mada" element={<Login_Build />} />
      <Route path="/home-freelance" element={<Client_Home />} />
      <Route path="/freelance-mada" element={<Profile_List_View />} />
      <Route path="/offre-view" element={<Offre_Detail />} />
      {/* <Route path="/offre-payment" element={<Offre_Payment />} /> */}
      {/* <Route path="/stripe/payment/:amount" element={<StripePaymentPage />} /> */}
      <Route path="/payment-success" element={<Payment_Success />} />
      <Route path="/home-freelance-form" element={<Client_Form />} />
      <Route path="/build-mada-signup" element={<Signup_Build />} />
      <Route path="" element={<Main2 />}>
        <Route path="/profil-build" element={<Complete_Profil />} />
      </Route>
      <Route path="" element={<Main1 />}>
        <Route path="/rh-employe" element={<Complete_Profil />} />
        <Route path="/build-mada-list" element={<Profile_List />} />
        <Route path="/build-mada-profil-detail" element={<Profil_Details />} />
        <Route path="/build-mada-profil-detail-porteur" element={<Profil_Details_Porteur />} />
        <Route path="/build-mada-missions" element={<Mission_List />} />
        <Route path="/build-mada-porteur" element={<Porteur />} />
        <Route path={"discuss"} element={<Discuss_Main />}>
          <Route path={"groups"} element={<Chat />} />
        </Route>
        <Route path="/stripe/payment/:amount" element={<StripePaymentPage />} />
        <Route path="/offre-payment" element={<Offre_Payment />} />
        <Route path="/payment-success" element={<Payment_Success />} />
      </Route>
      <Route path="/build-mada-profil-detail-view" element={<Profil_Details_View />} />
      <Route path="/build-mada-profil-detail-home" element={<Profil_Details_Home />} />
      <Route path="/repertoire-mada" element={<Repertoire />} />
    </Routes>
  );
}

export default App;
