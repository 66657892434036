const endpoint = process.env.REACT_APP_API;

export const stripeService = {

    retrieve_stripe_payment_method(id){
        return fetch(endpoint + '/api/stripe/get-payment-method/' + id, {
            method: 'GET',
            headers: {'Accept': 'application/json', 'Content-Type': 'application/json'}
        }).then(response => response.json()).catch(error => {
            console.log(error);
        });
    },

    create_payment_intent(data){
        return fetch(endpoint+'/api/stripe/create-payment-intent', {
            method: 'POST',
            headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
            body: JSON.stringify(data)
        }).then(response => response.json()).catch(error => {
            console.log(error);
        });
    }

};