import React, { useEffect, useRef, useState } from "react";
import MuiBackdrop from "../../components/Loading/MuiBackdrop";
import { apiNodeService } from "../../provider/apiNodeService";
import Avatar from '@mui/material/Avatar';
import "./Profile_List.css"
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { Button as MuiButton, Chip } from '@material-ui/core'
import Button from '@mui/material/Button';
import DoneIcon from '@mui/icons-material/Done';
import HomeRepairServiceOutlinedIcon from '@mui/icons-material/HomeRepairServiceOutlined';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import IconButton from "@material-ui/core/IconButton";
import TextField from '@mui/material/TextField';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import Checkbox from '@mui/material/Checkbox';
import StarIcon from '@mui/icons-material/Star';
import Alert from '@mui/material/Alert';
import { useNavigate } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import rethink from "../../controller/rethink";
import moment from "moment";
import utilFunctions from "../../tools/functions";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import Slider from "react-slick";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import Skeleton from '@mui/material/Skeleton';
import categoriesFreelance from '../../data/categoriesFreelance';

const CustomNextArrow = ({ onClick }) => (
    <div className="custom-arrow next" >
        <ArrowForwardIosIcon fontSize="small" color="primary" onClick={onClick} />
    </div>
);

const CustomPrevArrow = ({ onClick }) => (
    <div className="custom-arrow prev" >
        <ArrowBackIosIcon fontSize="small" color="primary" onClick={onClick} />
    </div>
);

const CustomNextArrow1 = ({ onClick }) => (
    <div className="custom-arrow1 next" >
        <ArrowForwardIosIcon fontSize="small" color="primary" onClick={onClick} />
    </div>
);

const CustomPrevArrow1 = ({ onClick }) => (
    <div className="custom-arrow1 prev" >
        <ArrowBackIosIcon fontSize="small" color="primary" onClick={onClick} />
    </div>
);

const settings1 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <CustomNextArrow1 />,
    prevArrow: <CustomPrevArrow1 />,
};

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
};

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 13,
        fontWeight: 600,
        maxWidth: 450,
    },
}));

const Profile_List = () => {

    const navigate = useNavigate();

    const [employes, setEmployes] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [openCommentModal, setOpenCommentModal] = React.useState(false);
    const [selectedUser, setSelectedUser] = React.useState({});
    const [requiredValues, setRequiredValues] = React.useState(false)
    const [description, setDescription] = React.useState("")
    const [checked, setChecked] = React.useState(false);
    const [communication, setCommunication] = React.useState("")
    const [satisfaction, setSatisfaction] = React.useState("")
    const [alertSuccess, setAlertSuccess] = React.useState(false)
    const [alertError, setAlertError] = React.useState(false)
    const [alertText, setAlertText] = React.useState("");
    const [logicielFilter, setLogicielFilter] = useState('');
    const [filteredData, setFilteredData] = useState(null);
    const [filteredDataMontant, setFilteredDataMontant] = useState(null);
    const [filteredDataNiveau, setFilteredDataNiveau] = useState(null);
    const [checkedNiveau, setCheckedNiveau] = React.useState(0);
    const [checkedPrice, setCheckedPrice] = React.useState('0,100000000');
    const pageTopRef = useRef(null);
    const [slider, setSlider] = useState(null);
    const [slider1, setSlider1] = useState(null);

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    const resetValues = () => {
        setChecked(false)
        setSatisfaction("")
        setCommunication("")
        setDescription("")
    }

    useEffect(() => {
        getUsers()
    }, []);

    const AddComment = () => {
        setLoading(true)
        let data = {}
        let newComment = {
            description: description,
            satisfaction: satisfaction,
            communication: communication,
            checked: checked,
            user: localStorage.getItem("email"),
            user_first_name: localStorage.getItem("first_name"),
            user_last_name: localStorage.getItem("last_name")
        }
        if (selectedUser.comment) {
            console.log(selectedUser)
            data.comment = [...selectedUser.comment, newComment]
            apiNodeService.updateTableData({ db: "FREELANCE_MAD", table: "employe_odoo", id: selectedUser.id, data: data }).then(resAdd => {
                console.log(resAdd)
                if (resAdd && resAdd.status === 200) {
                    setOpenCommentModal(false)
                    setLoading(false)
                    getUsers()
                    resetValues()
                    setAlertText("Votre commentaire a bien été enregistré et sera publié après vérification par notre équipe")
                    setAlertSuccess(true)
                    setTimeout(() => {
                        setAlertSuccess(false)
                    }, 5000)
                } else {
                    setLoading(false)
                }
            })
        } else {
            data.comment = [newComment]
            apiNodeService.updateTableData({ db: "FREELANCE_MAD", table: "employe_odoo", id: selectedUser.id, data: data }).then(resAdd => {
                console.log(resAdd)
                if (resAdd && resAdd.status === 200) {
                    setOpenCommentModal(false)
                    setLoading(false)
                    getUsers()
                    resetValues()
                    setAlertText("Votre commentaire a bien été enregistré et sera publié après vérification par notre équipe")
                    setAlertSuccess(true)
                    setTimeout(() => {
                        setAlertSuccess(false)
                    }, 5000)
                } else {
                    setLoading(false)
                }
            })
        }
    }

    const getUsers = () => {
        setLoading(true)
        apiNodeService.getTableData({ db: "FREELANCE_MAD", table: "employe_odoo" }).then((res) => {
            console.log(res)
            if (res && res.status === 200) {
                const freelanceUsers = res.data.filter(user => user.role === 'freelance');
                let sortedData = freelanceUsers.sort((a, b) => {
                    if (a.logiciel && b.logiciel) {
                        return b.logiciel.length - a.logiciel.length;
                    } else if (a.logiciel) {
                        return -1; // Place les objets sans logiciel après ceux avec logiciel
                    } else if (b.logiciel) {
                        return 1; // Place les objets avec logiciel avant ceux sans logiciel
                    } else {
                        return 0; // Garde l'ordre si aucun des deux objets n'a de logiciel
                    }
                });
                setEmployes([...sortedData])
                setFilteredData([...sortedData])
                setFilteredDataMontant([...sortedData])
                setFilteredDataNiveau([...sortedData])
            }
            setLoading(false)
        })
    }

    const getNumberFreelance = (filterItem) => {
        const filteredData = employes.filter(obj => obj.logiciel && obj.logiciel.includes(filterItem));
        return filteredData.length + (filteredData.length > 1 ? " Freelances": " Freelance");
    }

    const handleFilterChange = (event) => {
        const { value } = event.target;
        setLogicielFilter(value);
        filterByLogiciel(value);
    };

    const filterByLogiciel = (filterValue) => {
        console.log(filterValue)
        const filteredArray = employes.filter(item =>
            !filterValue || // Added check for empty filter value
            (item.logiciel &&
                item.logiciel.some(logiciel =>
                    logiciel.toLowerCase().includes(filterValue.toLowerCase())
                ))
            || filterValue.toLowerCase().replace(/é/g, 'e').split(/\s+/).every(word =>
                (item.first_name.toLowerCase().replace(/é/g, 'e')).includes(word) ||
                (item.last_name.toLowerCase().replace(/é/g, 'e')).includes(word)
            )
        )
        console.log(filteredArray)
        setFilteredData(filteredArray);
        setFilteredDataMontant(filteredArray)
        filterByMontant(checkedPrice, filteredArray)
    };


    const filterByMontant = (value, data) => {
        console.log(filteredData);
        let defaultValue = '0,100000000'
        let filterValue = value ? value.split(',').map(Number) : defaultValue.split(',').map(Number);
        console.log(filterValue)
        const filteredArray = data.filter(item => {
            if (!filterValue) {
                return true; // Retourne true si aucun filtre n'est défini
            }

            if (filterValue[0] === 0) {
                // Retourne true si le filtre est défini à 0 (inclut les objets sans montant)
                return !item.montant || (item.montant >= filterValue[0] && item.montant <= filterValue[1]);
            }

            // Retourne true si le montant de l'élément est dans la plage spécifiée
            return item.montant && (item.montant >= filterValue[0] && item.montant <= filterValue[1]);
        });

        console.log(filteredArray);
        setFilteredDataMontant(filteredArray);
        filterByNiveau(checkedNiveau, filteredArray)
    };

    const filterByNiveau = (filterValue, data) => {
        console.log(filterValue);

        const filteredArray = data.filter(item => {
            if (!filterValue) {
                return true;
            }

            if (filterValue === '' || 0) {
                // Retourne true si le filtre est défini à 0 (inclut les objets sans montant)
                return !item.niveau || item.niveau;
            }

            // Retourne true si le montant de l'élément est dans la plage spécifiée
            return item.niveau && (item.niveau === filterValue);
        });

        console.log(filteredArray);
        setFilteredDataNiveau(filteredArray);
    };



    const handleChangeMontant = (event) => {
        console.log(event.target.value)
        setCheckedPrice(event.target.value ? event.target.value : '0,100000000');
        filterByMontant(event.target.value, filteredData)
    };

    const scrollToTop = () => {
        console.log('hi to scroll')
        pageTopRef.current.scrollTo(0, 0)
    };

    const resetFilter = () => {
        setCheckedPrice('0,100000000')
        setCheckedNiveau(0)
        setFilteredDataNiveau(employes)
    }

    return (
        <>
            <MuiBackdrop open={loading} text={"Chargement..."} />
            {alertSuccess &&
                <Alert style={{ marginTop: 35 }} severity="success">
                    {alertText}
                </Alert>}
            {alertError &&
                <Alert severity="error">
                    Erreur, Veuillez réessayer plus tard !
                </Alert>}
            <div ref={pageTopRef} className="p-2" style={{ marginTop: 60, overflowY: 'auto', overflowX: 'hidden', maxHeight: '85vh' }}>

                {employes.length > 0 &&
                    <div>
                        <div className="d-flex title-equipe mt-4 px-3 align-items-center" style={{ gap: 10 }}>
                            <span>
                                {"Top 10 freelances du mois"}
                            </span>
                            <LightTooltip style={{ backgroundColor: '#fff' }} title="Le classement du Top 10 des freelances du mois est basé sur le nombre de commentaires (selon la catégorie de métier) et la note moyenne des commentaires attribuée dans le mois ! 
                         Chaque 1er matin du mois à 00h00, le classement est rénitialisé à zéro afin de laisser à chaque freelance une chance d’être mis en avant.">
                                <InfoOutlinedIcon className="mt-1" style={{ cursor: 'pointer' }} color="primary" />
                            </LightTooltip>
                        </div>
                    </div>
                }
                {employes.length > 0 &&
                    <Slider ref={(slider)} className="mb-5 slider-freelance" slidesPerRow={2} {...settings}>
                        {employes.slice(0, 10).map((item, key) => (
                            <div key={key} className="col-md-6 mt-3 px-2">
                                <div className="p-2 card-profil">
                                    <div className="d-flex align-items-start justify-content-between mt-2" style={{ gap: 20, width: "100%" }}>
                                        <div className="d-flex" style={{ gap: 20, width: "100%" }}>
                                            <div className="d-flex flex-column">
                                                <Avatar
                                                    onClick={(e) => { navigate("/build-mada-profil-detail?user=" + item.uid, { replace: true }) }}
                                                    sx={{ width: 100, height: 100, cursor: 'pointer' }}
                                                    alt={item.first_name}
                                                    src={item.photo ? item.photo.prefix + item.photo.data : ''}
                                                />
                                                <div className='d-flex align-items-center mt-2' style={{ gap: 2 }}>
                                                    <img width={15} alt="star" src='/star.png' />
                                                    <span style={{ fontFamily: 'Montserrat', fontSize: 12 }}>{item.comment ? (item.comment[0].satisfaction + item.comment[0].communication) / 2 + "/5" : "Aucun avis"} {item.comment ? "(" + item.comment.length + " avis)" : ""}</span>
                                                </div>
                                            </div>
                                            <div className="d-flex flex-column">
                                                <span onClick={(e) => { navigate("/build-mada-profil-detail?user=" + item.uid, { replace: true }) }} className='span-avatar go-details' style={{ textAlign: 'left' }}>{item.first_name} {item.last_name}</span>
                                                <span className='span-job mt-1' style={{ textAlign: 'left' }}>{item.poste_actuel ? item.poste_actuel : 'Freelanceur'}</span>
                                                <span className='span-description mt-1' style={{ textAlign: 'left' }}>{item.description}</span>
                                            </div>
                                        </div>
                                        {/* <FavoriteIcon color="primary"/> */}
                                        {/* <FavoriteBorderIcon color="primary" style={{ cursor: 'pointer' }}
                                            onClick={() => {
                                                setSelectedUser(item)
                                                setOpenCommentModal(true)
                                            }} /> */}
                                    </div>
                                    {item.logiciel &&
                                        <div className="d-flex flex-wrap mt-2 text-truncate" style={{ gap: 4, height: 58 }}>
                                            {(item.logiciel || []).slice(0, 7).map((competence, key1) => (
                                                <div key={key1} onClick={(e) => {
                                                    setLogicielFilter(competence)
                                                    filterByLogiciel(competence)
                                                    scrollToTop()
                                                }}>
                                                    <Chip size="small"
                                                        style={{ color: "#1565C0", borderColor: "#1565C0", cursor: "pointer" }}
                                                        variant="outlined"
                                                        label={competence}
                                                    />
                                                </div>
                                            ))
                                            }
                                        </div>
                                    }
                                    <div className="d-flex justify-content-between mt-3 mb-2" style={{ gap: 5 }}>
                                        <Button color="success" variant="outlined" className="btn-profile" startIcon={<DoneIcon />}>
                                            Disponible
                                        </Button>
                                        <Button style={{ color: "grey", borderColor: "grey" }} variant="outlined" className="btn-profile" startIcon={<HomeRepairServiceOutlinedIcon />}>
                                            {item.montant ? item.montant : 0} MGA/h
                                        </Button>
                                        {/* <Button className="btn-profile" onClick={(e)=>{createRoom(item.email,item.first_name + " " +item.last_name)}} variant="contained" endIcon={<SendOutlinedIcon />}>
                                            Inviter sur une mission
                                        </Button> */}
                                    </div>
                                </div>
                            </div>
                        ))

                        }
                    </Slider>
                }

                <div>
                    <div className="title-equipe mb-3 px-3">
                        <span>
                            {"Recruter un.e freelance"}
                        </span>
                    </div>
                </div>
                <div className="d-flex justify-content-start px-3">
                    <div className="d-flex flex-column" style={{ gap: 5 }}>
                        <TextField
                            value={logicielFilter}
                            onChange={handleFilterChange}
                            id="input-with-icon-textfield"
                            placeholder="Rechercher par compétences, logiciels, freelance ou métiers"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon style={{ color: "#1565C0" }} />
                                    </InputAdornment>
                                ),
                            }}
                            style={{ width: 520 }}
                            variant="outlined"
                            size="small"
                        />
                        {/* <span className="span-title mb-2" style={{ fontSize: 12, color: "#b1b1b1" }}>Chercher par compétences logiciels</span> */}
                    </div>
                </div>

                {employes.length > 0 &&
                    <Slider ref={(slider1)} className="mb-5 px-2 mt-2 slider-categorie" slidesPerRow={6} {...settings1}>
                        {categoriesFreelance.categoriesFreelance.map((item, key) => (
                        <div className="col-md-3 p-1" key={key}>
                            <div className="p-2 card-profil">
                                <div className="d-flex flex-column" style={{ gap: 10 }}>
                                    <span style={{ textAlign: 'left', fontWeight: 700, color: "#1565C0", fontSize: 18 }}>{item.label}</span>
                                    <div>
                                        <Chip size="small"
                                            className="px-2"
                                            style={{ color: "grey", borderColor: "grey", cursor: "pointer" }}
                                            variant="outlined"
                                            label={getNumberFreelance(item.label)}
                                        />
                                    </div>
                                    <div className="d-flex align-items-center go-details" style={{ gap: 10 }}
                                        onClick={(e) => {
                                            setLogicielFilter(item.label);
                                            filterByLogiciel(item.label);
                                        }}>
                                        <span style={{ fontWeight: 600, color: "#1565C0" }}>Voir</span>
                                        <ArrowForwardIosIcon style={{ width: 15, marginTop: 2 }} color="primary" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        ))
                        }
                    </Slider>
                }

                <div>
                    <div className="title-equipe mb-3 px-3">
                        <span>
                            {"Toutes les freelances"}
                        </span>
                    </div>
                </div>
                <div className="d-flex px-3 mb-4" style={{ gap: 10 }}>
                    <FormControl sx={{ minWidth: 120 }} size="small">

                        <Select
                            autoWidth
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            value={checkedPrice}
                            onChange={handleChangeMontant}
                        >
                            <MenuItem disabled value={'0,100000000'}>
                                Prix
                            </MenuItem>
                            <MenuItem value={'0,1000'}>0 - 1000 MGA/h</MenuItem>
                            <MenuItem value={'1000,10000'}>1000 - 10000 MGA/h</MenuItem>
                            <MenuItem value={'10000,100000000'}>10000+ MGA/h</MenuItem>
                            <MenuItem onClick={(e) => { setCheckedPrice('0,100000000') }}>
                                Tous
                            </MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl sx={{ minWidth: 120 }} size="small">
                        <Select
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            value={checkedNiveau}
                            onChange={(e) => {
                                let selected = e.target.value ? e.target.value : 0
                                setCheckedNiveau(selected)
                                console.log(e.target.value)
                                filterByNiveau(selected, filteredData)
                            }}
                        >
                            <MenuItem disabled value={0}>Catégories</MenuItem>
                            <MenuItem value={"Junior (0-2ans)"}>Junior (0-2ans)</MenuItem>
                            <MenuItem value={"Confirmé (3-7ans)"}>Confirmé (3-7ans)</MenuItem>
                            <MenuItem value={"Senior (8-12ans)"}>Senior (8-12ans)</MenuItem>
                            <MenuItem value={"Expert (13ans et +)"}>Expert (13ans et +)</MenuItem>
                            <MenuItem onClick={(e) => { setCheckedNiveau(0) }}>Tous</MenuItem>
                        </Select>
                    </FormControl>
                    {(checkedPrice !== "0,100000000" || checkedNiveau !== 0) &&
                        <div className="d-flex align-items-center" style={{ gap: 5, cursor: 'pointer', marginLeft: 10 }} onClick={resetFilter}>
                            <RestartAltIcon style={{ color: "#1565C0", width: 24, height: 24 }} />
                            <span style={{ color: "#1565C0", fontSize: 15, fontWeight: 500 }}>Réinitialiser les filtres</span>
                        </div>}
                </div>

                {loading === true &&
                    <div className="row px-3">
                        <div className="col-md-6">
                            <Skeleton className="mt-3" variant="rectangular" width={"100%"} height={160} />
                        </div>
                        <div className="col-md-6">
                            <Skeleton className="mt-3" variant="rectangular" width={"100%"} height={160} />
                        </div>
                        <div className="col-md-6">
                            <Skeleton className="mt-3" variant="rectangular" width={"100%"} height={160} />
                        </div>
                        <div className="col-md-6">
                            <Skeleton className="mt-3" variant="rectangular" width={"100%"} height={160} />
                        </div>
                        <div className="col-md-6">
                            <Skeleton className="mt-3" variant="rectangular" width={"100%"} height={160} />
                        </div>
                        <div className="col-md-6">
                            <Skeleton className="mt-3" variant="rectangular" width={"100%"} height={160} />
                        </div>
                    </div>
                }

                {(filteredDataNiveau && filteredDataNiveau.length > 0) ?
                    <div className="row px-3 mb-3">
                        {filteredDataNiveau.map((item, key) => (
                            <div key={key} className="col-md-6 mt-2">
                                <div className="p-2 card-profil">
                                    <div className="d-flex align-items-start justify-content-between mt-2" style={{ gap: 20, width: "100%" }}>
                                        <div className="d-flex" style={{ gap: 20, width: "100%" }}>
                                            <div className="d-flex flex-column">
                                                <Avatar
                                                    onClick={(e) => { navigate("/build-mada-profil-detail?user=" + item.uid, { replace: true }) }}
                                                    sx={{ width: 100, height: 100, cursor: 'pointer' }}
                                                    alt={item.first_name}
                                                    src={item.photo ? item.photo.prefix + item.photo.data : ''}
                                                />
                                                <div className='d-flex align-items-center mt-2' style={{ gap: 2 }}>
                                                    <img width={15} alt="star" src='/star.png' />
                                                    <span style={{ fontFamily: 'Montserrat', fontSize: 12 }}>{item.comment ? (item.comment[0].satisfaction + item.comment[0].communication) / 2 + "/5" : "Aucun avis"} {item.comment ? "(" + item.comment.length + " avis)" : ""}</span>
                                                </div>
                                            </div>
                                            <div className="d-flex flex-column">
                                                <span onClick={(e) => { navigate("/build-mada-profil-detail?user=" + item.uid, { replace: true }) }} className='span-avatar go-details' style={{ textAlign: 'left' }}>{item.first_name} {item.last_name}</span>
                                                <span className='span-job mt-1' style={{ textAlign: 'left' }}>{item.poste_actuel ? item.poste_actuel : 'Freelanceur'}</span>
                                                <span className='span-description mt-1' style={{ textAlign: 'left' }}>{item.description}</span>
                                            </div>
                                        </div>
                                        {/* <FavoriteIcon color="primary"/> */}
                                        {/* <FavoriteBorderIcon color="primary" style={{ cursor: 'pointer' }}
                                            onClick={() => {
                                                setSelectedUser(item)
                                                setOpenCommentModal(true)
                                            }} /> */}
                                    </div>
                                    {item.logiciel &&
                                        <div className="d-flex flex-wrap mt-2 text-truncate" style={{ gap: 4, height: 58 }}>
                                            {(item.logiciel || []).map((competence, key1) => (
                                                <div key={key1} onClick={(e) => {
                                                    setLogicielFilter(competence)
                                                    filterByLogiciel(competence)
                                                    scrollToTop()
                                                }}>
                                                    <Chip size="small"
                                                        style={{ color: "#1565C0", borderColor: "#1565C0", cursor: "pointer" }}
                                                        variant="outlined"
                                                        label={competence}
                                                    />
                                                </div>
                                            ))
                                            }
                                        </div>
                                    }
                                    <div className="d-flex justify-content-between mt-3 mb-2" style={{ gap: 5 }}>
                                        <Button color="success" variant="outlined" className="btn-profile" startIcon={<DoneIcon />}>
                                            Disponible
                                        </Button>
                                        <Button style={{ color: "grey", borderColor: "grey" }} variant="outlined" className="btn-profile" startIcon={<HomeRepairServiceOutlinedIcon />}>
                                            {item.montant ? item.montant : 0} MGA/h
                                        </Button>
                                        {/* <Button className="btn-profile" onClick={(e)=>{createRoom(item.email,item.first_name + " " +item.last_name)}} variant="contained" endIcon={<SendOutlinedIcon />}>
                                            Inviter sur une mission
                                        </Button> */}
                                    </div>
                                </div>
                            </div>
                        ))

                        }
                    </div>
                    :
                    <div className="p-3">
                        <span style={{ color: "#616161", fontSize: 18 }}>0 Résultat</span>
                    </div>
                }
            </div>

            {selectedUser.first_name &&
                <Dialog
                    open={openCommentModal}
                    aria-labelledby="form-dialog-title"
                    fullWidth={true}
                    maxWidth="sm"

                >
                    <DialogTitle id="form-dialog-title" style={{ paddingBottom: 1 }}>
                        <div className="d-flex" style={{ gap: 10 }}>
                            <StarBorderIcon style={{ color: "#1565c0" }} />
                            <Typography style={{ color: '#1565C0', fontWeight: 600 }}>Commenter le service de {selectedUser.first_name}</Typography>
                        </div>

                        <IconButton
                            aria-label="close"
                            style={{
                                position: 'absolute',
                                right: 5,
                                top: 5,
                                color: '#c0c0c0'
                            }}
                            onClick={() => {
                                setOpenCommentModal(false)
                                resetValues()
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                        <hr style={{ color: "#1565C0" }} />
                    </DialogTitle>
                    <DialogContent style={{ overflowY: "inherit" }}>
                        <div className="d-flex flex-column">
                            <span style={{ color: 'grey', fontSize: 14 }}>* = Champs obligatoires</span>
                            <span className="span-title mt-3 mb-2">Communication (politesse, respect, etc...)*</span>
                        </div>

                        <div className="d-flex mb-3" style={{ gap: 25 }}>
                            <div className="d-flex flex-column justify-content-center">
                                <div className="d-flex justify-content-center">
                                    {communication === 1 ?
                                        <StarIcon style={{ color: "#1565c0" }} />
                                        :
                                        <StarBorderIcon onClick={() => { setCommunication(1) }} style={{ color: "#1565c0" }} />
                                    }
                                </div>

                                <span style={{ color: "#949494", fontSize: 12 }}>Terrible</span>
                            </div>

                            <div className="d-flex flex-column">
                                <div className="d-flex justify-content-center">
                                    {communication === 2 ?
                                        <StarIcon style={{ color: "#1565c0" }} />
                                        :
                                        <StarBorderIcon onClick={() => { setCommunication(2) }} style={{ color: "#1565c0" }} />
                                    }
                                </div>

                                <span style={{ color: "#949494", fontSize: 12 }}>Mauvais</span>
                            </div>

                            <div className="d-flex flex-column">
                                <div className="d-flex justify-content-center">
                                    {communication === 3 ?
                                        <StarIcon style={{ color: "#1565c0" }} />
                                        :
                                        <StarBorderIcon onClick={() => { setCommunication(3) }} style={{ color: "#1565c0" }} />
                                    }
                                </div>
                                <span style={{ color: "#949494", fontSize: 12 }}>Moyen</span>
                            </div>

                            <div className="d-flex flex-column">
                                <div className="d-flex justify-content-center">
                                    {communication === 4 ?
                                        <StarIcon style={{ color: "#1565c0" }} />
                                        :
                                        <StarBorderIcon onClick={() => { setCommunication(4) }} style={{ color: "#1565c0" }} />
                                    }
                                </div>
                                <span style={{ color: "#949494", fontSize: 12 }}>Bien</span>
                            </div>

                            <div className="d-flex flex-column">
                                <div className="d-flex justify-content-center">
                                    {communication === 5 ?
                                        <StarIcon style={{ color: "#1565c0" }} />
                                        :
                                        <StarBorderIcon onClick={() => { setCommunication(5) }} style={{ color: "#1565c0" }} />
                                    }
                                </div>
                                <span style={{ color: "#949494", fontSize: 12 }}>Excellent</span>
                            </div>
                        </div>

                        <span className="span-title mb-2">Satisfaction du résultat (objectif initial atteint, problème rencontré, etc...)*</span>
                        <div className="d-flex mb-3" style={{ gap: 25 }}>
                            <div className="d-flex flex-column">
                                <div className="d-flex justify-content-center">
                                    {satisfaction === 1 ?
                                        <StarIcon style={{ color: "#1565c0" }} />
                                        :
                                        <StarBorderIcon onClick={() => { setSatisfaction(1) }} style={{ color: "#1565c0" }} />
                                    }
                                </div>
                                <span style={{ color: "#949494", fontSize: 12 }}>Terrible</span>
                            </div>

                            <div className="d-flex flex-column">
                                <div className="d-flex justify-content-center">
                                    {satisfaction === 2 ?
                                        <StarIcon style={{ color: "#1565c0" }} />
                                        :
                                        <StarBorderIcon onClick={() => { setSatisfaction(2) }} style={{ color: "#1565c0" }} />
                                    }
                                </div>
                                <span style={{ color: "#949494", fontSize: 12 }}>Mauvais</span>
                            </div>

                            <div className="d-flex flex-column">
                                <div className="d-flex justify-content-center">
                                    {satisfaction === 3 ?
                                        <StarIcon style={{ color: "#1565c0" }} />
                                        :
                                        <StarBorderIcon onClick={() => { setSatisfaction(3) }} style={{ color: "#1565c0" }} />
                                    }
                                </div>
                                <span style={{ color: "#949494", fontSize: 12 }}>Moyen</span>
                            </div>

                            <div className="d-flex flex-column">
                                <div className="d-flex justify-content-center">
                                    {satisfaction === 4 ?
                                        <StarIcon style={{ color: "#1565c0" }} />
                                        :
                                        <StarBorderIcon onClick={() => { setSatisfaction(4) }} style={{ color: "#1565c0" }} />
                                    }
                                </div>
                                <span style={{ color: "#949494", fontSize: 12 }}>Bien</span>
                            </div>

                            <div className="d-flex flex-column">
                                <div className="d-flex justify-content-center">
                                    {satisfaction === 5 ?
                                        <StarIcon style={{ color: "#1565c0" }} />
                                        :
                                        <StarBorderIcon onClick={() => { setSatisfaction(5) }} style={{ color: "#1565c0" }} />
                                    }
                                </div>
                                <span style={{ color: "#949494", fontSize: 12 }}>Excellent</span>
                            </div>
                        </div>
                        <span className="span-title mb-2 mt-2">Description (remerciement, spécification, etc...)</span>
                        <TextField fullWidth size="small" id="outlined-basic" variant="outlined"
                            value={description}
                            onChange={(e) => {
                                setDescription(e.target.value)
                            }} />
                        <div className="d-flex justify-content-start mt-1">
                            <span className="span-title mb-2" style={{ fontSize: 12, color: "#b1b1b1" }}>Cette description pourra figurer sur le profil du prestataire.</span>
                        </div>
                        <div className="d-flex align-items-center" style={{ gap: 8 }}>
                            <Checkbox
                                checked={checked}
                                onChange={handleChange}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                            <span className="span-footer mb-1">En cochant cette case, je confirme que les détails fournis ci-dessus sont véridiques et exacts. Je comprends que cette information est cruciale pour aider les autres utilisateurs à faire leurs choix et de mettre en juste valeur le travail effectué par ce prestataire</span>
                        </div>
                    </DialogContent>
                    <DialogActions style={{ padding: 20, justifyContent: 'start' }}>
                        <MuiButton

                            color="primary"
                            variant="outlined"
                            style={{ textTransform: 'capitalize' }}
                            onClick={() => {
                                setOpenCommentModal(false)
                                resetValues()
                            }}
                        >
                            Cancel
                        </MuiButton>
                        <MuiButton
                            onClick={() => {
                                AddComment()
                            }}
                            variant="contained"
                            disabled={(checked === false || satisfaction === "" || communication === "" || description === "") ? true : false}
                            style={{ textTransform: 'capitalize', color: "#fff", backgroundColor: (checked === false || satisfaction === "" || communication === "" || description === "") ? "grey" : "#1565C0" }}
                        >
                            Envoyer le commentaire
                        </MuiButton>
                        {requiredValues === true && <span style={{ color: 'red' }}>Veuillez remplir les champs obligatoire *</span>}
                    </DialogActions>
                </Dialog>
            }
        </>
    )
}
export default Profile_List;