import React, { useEffect, useRef, useState } from 'react';
import "./Complete_Profil.css"
import { Chip } from '@material-ui/core'
import MuiBackdrop from "../../components/Loading/MuiBackdrop";
import { apiNodeService } from '../../provider/apiNodeService';
import Alert from '@mui/material/Alert';
import Avatar from '@mui/material/Avatar';
import utilFunctions from '../../tools/functions';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import moment from "moment";
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import rethink from '../../controller/rethink';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import 'moment/locale/fr'; // Importer la localisation française de Moment.js
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import TextComponent from '../../components/Profil/TextComponent';
import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Cv_Profil_Home from '../../components/Profil/Cv_Profil_Home';

const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 25,
    height: 25,
    border: `2px solid ${theme.palette.background.paper}`,
}));

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 13,
        fontWeight: 600,
        maxWidth: 450,
    },
}));
const Profil_Details_Home = () => {

    const navigate = useNavigate();

    const [loading, setLoading] = React.useState(false);
    const [niveauExperience, setNiveauExperience] = React.useState('');
    const [disponibility, setDisponibility] = React.useState('');
    const [mobility, setMobility] = React.useState('');
    const [prenom, setPrenom] = React.useState('');
    const [nom, setNom] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [mobile, setMobile] = React.useState('');
    const [ville, setVille] = React.useState('');
    const [description, setDescription] = React.useState('');
    const [pays, setPays] = React.useState('Madagascar');
    const [facebook, setFacebook] = React.useState('');
    const [linkedin, setLinkedin] = React.useState('');
    const [gmail, setGmail] = React.useState('');
    const [twitter, setTwitter] = React.useState('');
    const [whatsapp, setWhatsapp] = React.useState('');
    const [selectedValues, setSelectedValues] = React.useState([]);
    const [selectedValues1, setSelectedValues1] = React.useState([]);
    const [photo, setPhoto] = React.useState(null)
    const [montant, setMontant] = React.useState("")
    const [user, setUser] = React.useState(null)
    const [employe, setEmploye] = React.useState(null)
    const [naissance, setNaissance] = React.useState(dayjs(moment()))
    const [codep, setCodep] = React.useState("");
    const [checked, setChecked] = React.useState(false);
    const [portfolio, setPortfolio] = useState([]);
    const [postes, setPostes] = React.useState([]);
    const [projets, setProjets] = React.useState([]);
    const [formations, setFormations] = React.useState([]);
    const [comments, setComments] = React.useState([]);
    const [etoileRemp, setEtoileRemp] = useState(0);
    const [langues, setLangues] = React.useState([]);
    const [missions, setMissions] = React.useState([]);
    const [posteActuel, setPosteActuel] = useState('');
    const [isDownwload, setIsDownwload] = useState(false);
    const [isIncompletData, setIsIncompletData] = useState(false);

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const id = searchParams.get('user');
        getUserById(id)
    }, []);

    const getMission = (email) => {
        rethink.getItemByLabel("FREELANCE_MAD", "mission", { freelancer: email }).then((res) => {
            console.log(res)
            if (res) {
                let filteredData = res.filter(item => item.state === "finished")
                setMissions(filteredData)
            }
        })
    }

    const getUserById = async (id) => {
        setLoading(true)
        return new Promise(resolve => {
            apiNodeService.filterTable({ table: "user_build", filter: { id: id }, db: "FREELANCE_MAD" }).then(userRes => {
                console.log(userRes)
                if (userRes && userRes !== "false" && Array.isArray(userRes.data)) {
                    console.log(userRes.data[0])
                    getUserByEmail(userRes.data[0].email, userRes.data[0].last_name, userRes.data[0].first_name)
                    setUser(userRes.data[0])
                    setNom(userRes.data[0].last_name)
                    setPrenom(userRes.data[0].first_name)
                    setNaissance(dayjs(userRes.data[0].naissance))
                    setMobile(userRes.data[0].mobile)
                    setPays(userRes.data[0].pays)
                    setEmail(userRes.data[0].email)
                    getMission(userRes.data[0].email)
                    setVille(userRes.data[0].ville)
                    setCodep(userRes.data[0].codep)
                    setPosteActuel(userRes.data[0].poste_actuel ? userRes.data[0].poste_actuel : '')
                    setDescription(userRes.data[0].description ? userRes.data[0].description : "")
                    resolve(userRes.data[0])
                } else {
                    setLoading(false)
                    resolve("false")
                }
            }).catch(err => resolve("false"))
        })
    }

    const getUserByEmail = async (email, last_name, first_name) => {
        return new Promise(resolve => {
            apiNodeService.filterTable({ table: "employe_odoo", filter: { email: email }, db: "FREELANCE_MAD" }).then(userRes => {
                console.log(userRes)
                if (userRes && userRes !== "false" && Array.isArray(userRes.data)) {
                    console.log(userRes.data[0])
                    if (!userRes.data[0]) {
                        let data = { email: email, last_name: last_name, first_name: first_name }
                        apiNodeService.insertTableData({ db: "FREELANCE_MAD", table: "employe_odoo", data: data }).then(resAdd => {
                            console.log(resAdd)
                            if (resAdd && resAdd.status === 200) {
                                console.log(resAdd)
                                let data = {}
                                data.id = resAdd.data.id
                                setEmploye(data)
                                console.log('true')
                            } else {
                                console.log('false')
                            }
                        })
                        setLoading(false)
                    } else {
                        setEmploye(userRes.data[0])
                        setNiveauExperience(userRes.data[0].niveau ? userRes.data[0].niveau : '')
                        setMontant(userRes.data[0].montant ? userRes.data[0].montant : '')
                        setSelectedValues(userRes.data[0].competence ? userRes.data[0].competence : [])
                        setSelectedValues1(userRes.data[0].logiciel ? userRes.data[0].logiciel : [])
                        setDisponibility(userRes.data[0].disponibility ? userRes.data[0].disponibility : '')
                        setChecked(userRes.data[0].permis ? userRes.data[0].permis : '')
                        setMobility(userRes.data[0].mobility ? userRes.data[0].mobility : '')
                        setPhoto(userRes.data[0].photo ? userRes.data[0].photo : null)
                        setFacebook(userRes.data[0].facebook ? userRes.data[0].facebook : '')
                        setLinkedin(userRes.data[0].linkedin ? userRes.data[0].linkedin : '')
                        setWhatsapp(userRes.data[0].whatsapp ? userRes.data[0].whatsapp : '')
                        setTwitter(userRes.data[0].twitter ? userRes.data[0].twitter : '')
                        setGmail(userRes.data[0].gmail ? userRes.data[0].gmail : '')
                        setPosteActuel(userRes.data[0].poste_actuel ? userRes.data[0].poste_actuel : '')
                        setPortfolio(userRes.data[0].portfolio ? userRes.data[0].portfolio : [])
                        setPostes(userRes.data[0].postes ? userRes.data[0].postes : [])
                        setProjets(userRes.data[0].projets ? userRes.data[0].projets : [])
                        setFormations(userRes.data[0].formations ? userRes.data[0].formations : [])
                        setComments(userRes.data[0].comment ? userRes.data[0].comment : [])
                        getEtoileRemp(userRes.data[0].comment)
                        setLangues(userRes.data[0].langues ? userRes.data[0].langues : [])
                        setLoading(false)
                    }
                } else {
                    resolve("false")
                }
            }).catch(err => resolve("false"))
        })
    }

    const getEtoileRemp = (items) => {
        if (items && items.length > 0) {
            let i = 0;
            items.forEach((item) => {
                i += (item.satisfaction + item.communication) / 2
            })
            console.log(i)
            setEtoileRemp(i)
        }
    }


    const filterFreelanceFooter = (value) => {
        console.log(value)
        navigate("/freelance-mada?cat='" + value + "'")
    }

    return (
        <>
            <MuiBackdrop open={loading} text={"Chargement..."} />
            <div className="header-client" style={{ top: 0, backgroundColor: "#fff" }} >
                <div className="d-flex justify-content-between align-items-center px-2" style={{ height: "100%" }}>
                    <div className="d-flex px-2" style={{ cursor: "pointer" }} onClick={(e) => { navigate('/') }}>
                        <span style={{ fontSize: 34, fontWeight: 700 }}>Build.</span><span style={{ fontSize: 34, color: "#214dcb", fontWeight: 700 }}>Mada</span>
                    </div>
                    <div className="d-flex align-items-center px-2" style={{ gap: 20 }}>
                        <button className="create-client-button" onClick={(e) => { navigate('/build-mada-signup') }}>
                            Créer un compte
                        </button>
                        <div style={{ borderBottom: "2px solid #214dcb", padding: "2px 8px 4px 8px" }}>
                            <span className="span-link1" style={{ fontWeight: 700, color: "#214dcb" }} onClick={(e) => { navigate('/build-mada') }}>Me connecter</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="body-client p-4" style={{ minHeight: '90vh' }}>
                {(user && employe) &&
                    <>
                        <div style={{ marginTop: 60 }}>
                            <div className='d-flex align-items-center px-4 p-2' style={{ gap: 20 }}>
                                <ArrowBackIcon fontSize='small' style={{ cursor: 'pointer' }} onClick={(e) => { navigate('/repertoire-mada') }} />
                                <div onClick={(e) => { navigate('/repertoire-mada') }} style={{ fontSize: 16, cursor: 'pointer', fontWeight: 600, letterSpacing: 1 }}>Revenir à la page d’accueil</div>
                            </div>
                        </div>
                        <div className='row p-4'>
                            <div className="col-lg-4 py-2">
                                <div className="d-flex flex-column">
                                    <Avatar
                                        variant="square"
                                        sx={{ width: "95%", height: 300, cursor: 'pointer', borderRadius: 2 }}
                                        alt={employe.first_name}
                                        src={employe.photo ? employe.photo.prefix + employe.photo.data : ''}
                                    />

                                </div>
                            </div>
                            <div className='col-lg-8'>
                                <div className="d-flex flex-column" style={{ height: "100%" }}>
                                    <span className='span-avatar-home-detail'>{user.first_name} {user.last_name}</span>
                                    <span className='span-job-home-detail mt-1'>{user.poste_actuel ? user.poste_actuel : 'Freelance'}</span>
                                    {/* <span className='span-job mt-1' style={{ textAlign: 'left' }}>Architecte solaire</span> */}
                                    {/* <span className='span-description mt-1' style={{ textAlign: 'left' }}>{item.description}</span> */}
                                    <div className='d-flex align-items-center mt-2' style={{ gap: 4 }}>
                                        <img width={22} alt="star" src='/star.png' />
                                        <span style={{ fontFamily: 'Montserrat', fontSize: 15,fontWeight:600 }}>{employe.comment ? (employe.comment[0].satisfaction + employe.comment[0].communication) / 2 + "/5" : "0/5"} {employe.comment ? "(" + employe.comment.length + " avis)" : "(0 avis)"}</span>
                                    </div>
                                    <div className="d-flex flex-column mt-auto">
                                        <div className="d-flex mb-2" style={{ gap: 31 }}>
                                            {employe.linkedin && <LightTooltip style={{ backgroundColor: '#fff' }} title={employe.linkedin ? employe.linkedin : "Le freelance n'a pas renseigné de valeur"} >
                                                <img width={35} style={{ cursor: 'pointer' }} src="/linkedindetail.png" />
                                            </LightTooltip>}
                                            {employe.instagram && <LightTooltip style={{ backgroundColor: '#fff' }} title={employe.instagram ? employe.instagram : "Le freelance n'a pas renseigné de valeur"} >
                                                <img width={35} style={{ cursor: 'pointer' }} src="/instagramdetail.png" />
                                            </LightTooltip>}
                                            {employe.whatsapp && <LightTooltip style={{ backgroundColor: '#fff' }} title={employe.whatsapp ? employe.whatsapp : "Le freelance n'a pas renseigné de valeur"} >
                                                <img width={35} style={{ cursor: 'pointer' }} src="/whatappdetail.png" />
                                            </LightTooltip>}
                                            {employe.facebook && <LightTooltip style={{ backgroundColor: '#fff' }} title={employe.facebook ? employe.facebook : "Le freelance n'a pas renseigné de valeur"} >
                                                <img width={35} style={{ cursor: 'pointer' }} src="/facebookdetail.png" />
                                            </LightTooltip>}
                                            {employe.twitter && <LightTooltip style={{ backgroundColor: '#fff' }} title={employe.twitter ? employe.twitter : "Le freelance n'a pas renseigné de valeur"} >
                                                <img width={35} style={{ cursor: 'pointer' }} src="/twitterdetail.png" />
                                            </LightTooltip>}
                                        </div>
                                        {isIncompletData === true && <span style={{color:'red'}}>Le freelance n'a pas renseigné les données nécessaires</span>}
                                        <button
                                            onClick={(e) => { 
                                                setIsDownwload(true)
                                                setIsIncompletData(false) 
                                                setTimeout(() => {
                                                    setIsDownwload(false)
                                                }, 3000)
                                            }}
                                            style={{ letterSpacing: 1, gap: 10, width: 300 }} className="contact-freelance-button d-flex justify-content-center align-items-center mt-2 mb-2">
                                            Télécharer le CV
                                            <FileDownloadOutlinedIcon fontSize="small" style={{ marginLeft: 8 }} />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='p-3 px-4'>
                            <div className='separator-detail-home'/>
                        </div>

                        <div className='row p-4'>
                            {/* <div className='col-md-4'>
                                <div className='d-flex flex-column'>
                                    <span className='span-detail-home-1'>À PARTIR DE</span>
                                    <span className='span-detail-home-2'>{employe.montant ? (parseInt(employe.montant) / 4870).toFixed(2) + '€ / jour' : "Le freelance n'a pas renseigné de valeur"}</span>
                                </div>
                            </div> */}
                            <div className='col-md-4'>
                                <div className='d-flex flex-column'>
                                    <span className='span-detail-home-1'>NOUS A REJOINT LE</span>
                                    <span className='span-detail-home-2'>{user.created_at ? moment(user.created_at).format("DD/MM/YYYY") : "12/02/2024"}</span>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='d-flex flex-column'>
                                    <span className='span-detail-home-1'>MISSIONS RÉALISÉES</span>
                                    <span className='span-detail-home-2'>{missions.length}</span>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='d-flex flex-column'>
                                    <span className='span-detail-home-1'>À PROPOS</span>
                                    <span className='span-detail-home-2'>{employe.description ? employe.description : "Le freelance n'a pas renseigné de valeur"}</span>
                                </div>
                            </div>
                        </div>
                        {/* <div className='row p-4'>
                            <div className='col-md-4'>
                                <div className='d-flex flex-column'>
                                    <span className='span-detail-home-1'>NOUS A REJOINT LE</span>
                                    <span className='span-detail-home-2'>{user.created_at ? moment(user.created_at).format("DD/MM/YYYY") : "12/02/2024"}</span>
                                </div>
                            </div>
                        </div> */}
                    </>
                }
            </div>

            <footer className="footer mt-4">
                <div className="row px-4">
                    <p style={{ fontSize: 26, fontWeight: 700 }} className="investisseurs">Freelance Mada</p>
                    <p style={{ fontSize: 18 }}>Synchronisez vos besoins avec les compétences idéales</p>
                </div>
                <div className="row align-items-start p-4" style={{ width: "100%" }}>
                    <div className="col-md-12">
                        <div className="d-flex flex-column">
                            <p style={{ fontSize: 26, fontWeight: 700, marginTop: 40 }} className="investisseurs mb-3">Catégories</p>
                            <p onClick={(e) => { filterFreelanceFooter('Création artistique') }} style={{ fontSize: 18, cursor: 'pointer' }}>Création artistique</p>
                            <p onClick={(e) => { filterFreelanceFooter('Vidéo et animation') }} style={{ fontSize: 18, cursor: 'pointer' }}>Vidéo et animation</p>
                            <p onClick={(e) => { filterFreelanceFooter('Data') }} style={{ fontSize: 18, cursor: 'pointer' }}>Data</p>
                            <p onClick={(e) => { filterFreelanceFooter('Programmation') }} style={{ fontSize: 18, cursor: 'pointer' }}>Programmation</p>
                            <p onClick={(e) => { filterFreelanceFooter('Marketing') }} style={{ fontSize: 18, cursor: 'pointer' }}>Marketing</p>
                            <p onClick={(e) => { filterFreelanceFooter('Énergies') }} style={{ fontSize: 18, cursor: 'pointer' }}>Énergies</p>
                            <p onClick={(e) => { filterFreelanceFooter('Comptabilité') }} style={{ fontSize: 18, cursor: 'pointer' }}>Comptabilité</p>
                            <p onClick={(e) => { filterFreelanceFooter('Musique et audio') }} style={{ fontSize: 18, cursor: 'pointer' }}>Musique et audio</p>
                            <p onClick={(e) => { filterFreelanceFooter('Business') }} style={{ fontSize: 18, cursor: 'pointer' }}>Business</p>
                            <p onClick={(e) => { filterFreelanceFooter('Luxe') }} style={{ fontSize: 18, cursor: 'pointer' }}>Luxe</p>
                            <p onClick={(e) => { filterFreelanceFooter('Artisanat') }} style={{ fontSize: 18, cursor: 'pointer' }}>Artisanat</p>

                            <div className="d-flex mt-4" style={{ gap: 50 }}>
                                <img src="/facebookfooter.png" />
                                <img src="/twitterfooter.png" />
                                <img src="/instagramfooter.png" />
                                <img src="/linkedinfooter.png" />
                                <img src="/youtubefooter.png" />
                            </div>
                        </div>
                    </div>
                </div>
                <p style={{ fontSize: 18, marginTop: 30 }}>©2023-2024  Freelance Mada | Tout droits reservés</p>
            </footer>
            <Cv_Profil_Home email={email} isDownwload={isDownwload}/>
        </>
    )
}
export default Profil_Details_Home;