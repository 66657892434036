import React, { useEffect, useState } from "react";
import Menu from "../Menu/Menu1";
import {
    Outlet, useLocation, useNavigate
} from 'react-router-dom';
import { useMediaQuery } from 'react-responsive'
import { menu_items } from "../Menu/DataMenu1";
import { menu_items2 } from "../Menu/DataMenu2";
import defaultAvatar from "../../assets/images/default_avatar.png"
import { Popup } from 'semantic-ui-react'
import { ButtonItem, MenuGroup, Section } from '@atlaskit/menu';
import SignOutIcon from '@atlaskit/icon/glyph/sign-out'
import PersonIcon from '@atlaskit/icon/glyph/person'
import SettingsIcon from '@atlaskit/icon/glyph/settings'
import logo_2 from "../../assets/logo/logo2.png"
import logo_3 from "../../assets/logo/logo3.png"
import Avatar from '@mui/material/Avatar';
import Typography from "@mui/material/Typography";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import MainMenuContext from './MainMenuContext';

export default function Main1(props) {

    const location = useLocation()
    const navigate = useNavigate()
    const [isLocked,setIsLocked] = React.useState(false)
    const [menuOpen, setMenuOpen] = useState(false);
    const [photo, setPhoto] = React.useState(localStorage.getItem('photo'))
    const [toggle_menu, setToggle_menu] = React.useState(false);
    const [openDrawerMenu, setOpenDrawerMenu] = React.useState(false);
    const [openDetailMenu, setOpenDetailMenu] = React.useState(true);
    const [currentPage, setCurrentPage] = React.useState("");
    const [currentPageTitle, setCurrentPageTitle] = React.useState(
        location.pathname.includes("team") ? "Equipe OA" :
            location.pathname.includes("clients") ? "Liste clients" :
                location.pathname.includes("timesheets") ? "TimeSheets" : "Equipe OA"
    );
    const [activeMenuItem, setActiveMenuItem] = React.useState(
        location.pathname.includes("build-mada-missions") ? 1 :
            location.pathname.includes("build-mada-list") ? 2 :
                location.pathname.includes("discuss/groups") ? 3 : 0
    );
    const [isProfilePath, setIsProfilePath] = React.useState(
        location.pathname.includes("rh-employe") ? true :false
    );

    const isMobile = useMediaQuery({ query: '(max-width: 925px)' })
    const isBigScreen = useMediaQuery({ query: '(min-width: 925px)' })
    let left_distance = isBigScreen ? (openDetailMenu === true ? 270 : toggle_menu === false ? 64 : 0) : 0

    useEffect(() => {
        let sidebar = document.querySelector(".sidebar");
        sidebar.classList.toggle("close");
        if (!localStorage.getItem("email") || !localStorage.getItem("first_name")) {
            navigate("/login")
        }
    }, [])

    useEffect(() => {
        console.log('hello from menu')
        if (location.pathname.includes("discuss/groups")) {
            setActiveMenuItem(3)
        }
        }
    , [location.pathname])

    const executeFunctionFromNestedComponent = (arg) => {
        console.log('hello im in menu yes')
        setIsLocked(arg)
      };

    return (
        <>

            <Menu background_color={"#fff"} ilogo={openDetailMenu || openDrawerMenu ? logo_3 : logo_3} top_title="Freelance Mad" top_title_color="#fff"
                size={openDetailMenu ? "big" : "small"}
                show_logo={true}
                isLocked={isLocked}
                show_active_user={false}
                active_user_name={localStorage.getItem("username")} active_user_details="admin"
                active_user_img={defaultAvatar}
                icons_color={"#3F434A"} titles_color={"#3F434A"}
                items={menu_items}
                items1={menu_items2}
                isProfilePath={isProfilePath}
                on_logout={() => {
                }}
                active_item={activeMenuItem}
                setActiveMenuItem={(item) => {
                    setActiveMenuItem(item)
                }}
                setIsProfilePath={(item) => {
                    setIsProfilePath(item)
                }}
                openDrawerMenu={openDrawerMenu}
                setOpenDrawerMenu={() => {
                    setOpenDrawerMenu(!openDrawerMenu)
                }}
                isMobile={isMobile}
                openDetailMenu={openDetailMenu}
                setOpenDetailMenu={(item) => {
                    setCurrentPage(item.uid)
                    setCurrentPageTitle(item.title)
                    openDetailMenu && setOpenDetailMenu(true)
                }}
                currentItem={currentPage}
                history={props.history}

            />

            <section className="home-section"
                style={{
                    backgroundColor: "#fff",
                    left: left_distance,
                    width: "calc(100% - " + (left_distance).toString() + "px)"
                }}
            >
                <div className="home-content">

                    <i className={openDetailMenu === true ? "bx bx-menu-alt-left" : "bx bx-menu"}
                        style={{ color: "#004671" }}
                        onClick={(event => {
                            if (isBigScreen) {
                                let sidebar = document.querySelector(".sidebar");
                                sidebar.classList.toggle("close");
                                setOpenDetailMenu(!openDetailMenu)
                            } else if (isMobile) {
                                setOpenDrawerMenu(!openDrawerMenu)
                            }
                        })}
                    />

                    <div style={{ position: "fixed", right: 20 }}>

                        <Popup
                            style={{ backgroundColor: "#fff", borderRadius: 4, border: "1px solid #c6d7f4", boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
                            content={
                                <>
                                    <MenuGroup
                                        maxWidth={180}
                                        minWidth={180}
                                    >
                                        <Section hasSeparator={false}>
                                            <ButtonItem
                                                onClick={(e) => {
                                                    setActiveMenuItem(-1)
                                                    setIsProfilePath(true)
                                                    console.log(activeMenuItem)
                                                    setMenuOpen(false)
                                                    navigate("/rh-employe?user=" + localStorage.getItem('id'))
                                                }}
                                                iconBefore={<AccountCircleOutlinedIcon color="primary" label="" />}
                                            >
                                               Mon profil
                                            </ButtonItem>
                                            <ButtonItem
                                                onClick={() => {
                                                    localStorage.clear()
                                                    navigate("/build-mada", { replace: true })
                                                }}
                                                iconBefore={<SignOutIcon primaryColor="red" label="" />}
                                            >
                                                Me déconnecter
                                            </ButtonItem>
                                        </Section>
                                    </MenuGroup>
                                </>
                            }
                            on='click'
                            position='bottom right'
                            popper={{ id: 'popper-container', style: { zIndex: 2000 } }}
                            trigger={
                                <div style={{ cursor: "pointer" }} onClick={(e) => { e.stopPropagation(); setMenuOpen(!menuOpen); }}>
                                    <div style={{ display: "flex" }}>
                                        <div style={{ alignSelf: "center" }}>
                                          <Avatar
                                                sx={{ width: 34, height: 34 }}
                                                alt={localStorage.getItem("email")}
                                                src={photo ? "data:image/png;base64," +photo :''}
                                            />
                                        </div>
                                        <div style={{ alignSelf: "center", marginLeft: 5 }}>
                                        </div>
                                        <div style={{ alignSelf: "center" }}>
                                            <ArrowDropDownIcon style={{ color: "black", marginLeft: 3 }} />
                                        </div>
                                    </div>
                                </div>
                            }
                            open={menuOpen}
                            onClose={() => setMenuOpen(false)}
                        />

                    </div>
                </div>

                <div style={{
                    paddingTop: 30
                }}>
            <MainMenuContext.Provider value={executeFunctionFromNestedComponent}>
                    <Outlet />
                    </MainMenuContext.Provider>
                </div>
            </section>

        </>
    )


}
