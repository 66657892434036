exports.ecole = [
    {
        name: "Lycée Français de Tananarive (LFT)",
        section: "Lycée Capital"
    },
    {
        name: "Lycée Jules Ferry",
        section: "Lycée Capital"
    },
    {
        name: "Lycée Privé Analamahitsy",
        section: "Lycée Capital"
    },
    {
        name: "Lycée Andohalo",
        section: "Lycée Capital"
    },
    {
        name: "Lycée Technique et Commercial d'Antananarivo (LTCA)",
        section: "Lycée Capital"
    },
    {
        name: "Lycée Sainte Thérèse",
        section: "Lycée Capital"
    },
    {
        name: "Lycée Nanisana",
        section: "Lycée Capital"
    },
    {
        name: "IST Instituts Supérieurs de Technologie IST d’Antananarivo, ",
        section: "IST (bac +2)"
    },
    {
        name: "IST Instituts Supérieurs de Technologie ’Antsiranana",
        section: "IST (bac +2)"
    },
    {
        name: "IST Instituts Supérieurs de Technologie d’Ambositra ",
        section: "IST (bac +2)"
    },
    {
        name: "Écoles de Formation de Technicien Agricole (EFTA)",
        section: "Agro Bac +3"
    },
    {
        name: "Centre National d’Étude et d’Application du Génie Rural (CNEAGR)",
        section: "Agro Bac +3"
    },
    {
        name: "Centre de Fabrication, de Formation, et d’Application du Machinisme et de la Mécanisation Agricole (CFFAMMA)​1​.",
        section: "Agro , meca Bac +3"
    },
    {
        name: "CFTP ONJA",
        section: "Electricité Bac +3"
    },
    {
        name: "TECH Madagascar",
        section: "Electricité Bac +3"
    },
    {
        name: "EDM Academy (Electricité de Madagascar Academy)",
        section: "Electricité Bac +3"
    },
    {
        name: "Licence en Science du tourisme- Université d’Antananarivo ",
        section: "Tourisme Bac +3"
    },
    {
        name: "Licence en Histoire, Sciences des savoirs et des sociétés en développement (HSSSD) - Université d’Antananarivo ",
        section: "Dev Bac +3"
    },
    {
        name: "Licence en Géographie - Université d’Antananarivo ",
        section: "Géographie Bac +3"
    },
    {
        name: "Licence en Etudes Malgaches (EM) - Université d’Antananarivo ",
        section: "Langue Bac +3"
    },
    {
        name: "Licence en Etudes Hispaniques et Etudes Russes (EHER) - Université d’Antananarivo ",
        section: "Langue Bac +3"
    },
    {
        name: "Licence en Etudes Germaniques (EG) - Université d’Antananarivo ",
        section: "Langue Bac +3"
    },
    {
        name: "Licence en Etudes Françaises et Francophones (EFF) - Université d’Antananarivo ",
        section: "Langue Bac +3"
    },
    {
        name: "Licence en Etudes Anglophones - Université d’Antananarivo ",
        section: "Langue Bac +3"
    },
    {
        name: "Licence en Culture, Société, Individus - Université d’Antananarivo ",
        section: "Langue Bac +3"
    },
    {
        name: "Ecole 42",
        section: "Etablissement Master informatique Bac +5"
    },
    {
        name: "Ecole Nationale d'Informatique (ENI) Fianarantsoa- GB : Génie logiciel et Base de Données univ. de Fianarantsoa",
        section: "Etablissement Master informatique Bac +5"
    },
    {
        name: "ENI SR : Administration des Systèmes et des Réseaux (univ. de Fianarantsoa)",
        section: "Etablissement Master informatique Bac +5"
    },
    {
        name: "ENI Hybride : Informatique Générale (univ. de Fianarantsoa ou Tuléar)",
        section: "Etablissement Master informatique Bac +5"
    },
    {
        name: "ESMIA : Ecole Supérieure de Management et d’Informatique Appliquée (",
        section: "Etablissement Master informatique Bac +5"
    },
    {
        name: "ENEAM  École Nationale de l'Éducation Appliquée à la Météorologie et à l'Aéronautique ",
        section: "Etablissement Master informatique Bac +5"
    },
    {
        name: "EPSI ( Ecole Professionnelle Spécialisée en Informatique  )",
        section: "Etablissement Master informatique Bac +5"
    },
    {
        name: "ESMIA (Informatique Appliquée)",
        section: "Etablissement Master informatique Bac +5"
    },
    {
        name: "INSI ",
        section: "Etablissement Master informatique Bac +5"
    },
    {
        name: "ISERP ( Institut Supérieur d’Études, de Recherches et de Pratiques  ) ",
        section: "Etablissement Master informatique Bac +5"
    },
    {
        name: "IS-INFO  Institut Supérieur en Informatique",
        section: "Etablissement Master informatique Bac +5"
    },
    {
        name: "ISPM Institut Supérieur Polytechnique de Madagascar ",
        section: "Etablissement Master informatique Bac +5"
    },
    {
        name: "IST Institut Supérieur de Technologie d'Antananarivo (IST)",
        section: "Etablissement Master informatique Bac +5"
    },
    {
        name: "IT University",
        section: "Etablissement Master informatique Bac +5"
    },
    {
        name: "Université d'Antananarivo",
        section: "Etablissement Master informatique Bac +5"
    },
    {
        name: "Université INSI",
        section: "Etablissement Master informatique Bac +5"
    },
    {
        name: "ESSA École Supérieure des Sciences Agronomiques ",
        section: "Etablissement Master Agronomique Bac +5"
    },
    {
        name: "ENEAM  École Nationale de l'Éducation Appliquée à la Météorologie et à l'Aéronautique ",
        section: "Etablissement Master Agronomique Bac +5"
    },
    {
        name: "Université d'Antananarivo",
        section: "Etablissement Master Agronomique Bac +5"
    },
    {
        name: "Université d'Antsiranana (Université de Diego-Suarez)",
        section: "Etablissement Master Agronomique Bac +5"
    },
    {
        name: "Université de Toamasina",
        section: "Etablissement Master Agronomique Bac +5"
    },
    {
        name: "École Supérieure des Sciences Agronomiques (ESSA)",
        section: "Etablissement Master Agronomique Bac +5"
    },
    {
        name: "Institut National des Sciences et Techniques Nucléaires (INSTN)",
        section: "Etablissement Master Agronomique Bac +5"
    },
    {
        name: "Institut Supérieur de Technologie (IST)",
        section: "Etablissement Master Agronomique Bac +5"
    },
    {
        name: "Université de Fianarantsoa",
        section: "Etablissement Master Agronomique Bac +5"
    },
    {
        name: "Université d'Antananarivo",
        section: "Etablissement Master electricité Bac +5"
    },
    {
        name: "École Supérieure Polytechnique d'Antananarivo (ESPA)",
        section: "Etablissement Master electricité Bac +5"
    },
    {
        name: "Institut Supérieur de Technologie (IST)",
        section: "Etablissement Master electricité Bac +5"
    },
    {
        name: "École Supérieure Privée Polytechnique (ESPA)",
        section: "Etablissement Master electricité Bac +5"
    },
    {
        name: "Université de Toamasina",
        section: "Etablissement Master electricité Bac +5"
    },
    {
        name: "Université d'Antsiranana (Université de Diego-Suarez)",
        section: "Etablissement Master electricité Bac +5"
    },
    {
        name: "Institut National des Sciences et Techniques Nucléaires (INSTN)",
        section: "Etablissement Master electricité Bac +5"
    },
    {
        name: "École Supérieure Polytechnique (ESP)",
        section: "Etablissement Master electricité Bac +5"
    },
    {
        name: "Université d'Antananarivo",
        section: "Etablissement Master mécanique Bac +5"
    },
    {
        name: "École Supérieure Polytechnique d'Antananarivo (ESPA)",
        section: "Etablissement Master mécanique Bac +5"
    },
    {
        name: "Institut Supérieur de Technologie (IST)",
        section: "Etablissement Master mécanique Bac +5"
    },
    {
        name: "École Supérieure Privée Polytechnique (ESPA)",
        section: "Etablissement Master mécanique Bac +5"
    },
    {
        name: "Université de Toamasina",
        section: "Etablissement Master mécanique Bac +5"
    },
    {
        name: "Université d'Antsiranana (Université de Diego-Suarez)",
        section: "Etablissement Master mécanique Bac +5"
    },
    {
        name: "Institut National des Sciences et Techniques Nucléaires (INSTN)",
        section: "Etablissement Master mécanique Bac +5"
    },
    {
        name: "École Supérieure Polytechnique (ESP)",
        section: "Etablissement Master mécanique Bac +5"
    },
    {
        name: "Centre National de télé enseignement de Madagascar",
        section: "Etablissement Master Divers Bac +5"
    },
    {
        name: "INSCAE Institut National des Sciences Comptables et de l'Administration d'Entreprises",
        section: "Etablissement Master Divers Bac +5"
    },
    {
        name: "INSPC  Institut National de la Santé Publique et Communautaire ",
        section: "Etablissement Master Divers Bac +5"
    },
    {
        name: "ENAM Ecole nationale d’administration de Madagascar",
        section: "Etablissement Master Divers Bac +5"
    },
    {
        name: "INTH Institut National du Tourisme et de l'Hôtellerie ",
        section: "Etablissement Master Divers Bac +5"
    },
    {
        name: "École supérieure polytechnique (ESP) - Diego Suarez",
        section: "Etablissement Master Divers Bac +5"
    },
    {
        name: "École normale supérieure pour l'enseignement technique (ENSET) - Diego Suarez",
        section: "Etablissement Master Divers Bac +5"
    },
    {
        name: "École doctorale thématique énergie nouvelle/renouvelable et environnement (EDT ENRE) - Diego Suarez",
        section: "Etablissement Master Divers Bac +5"
    },
    {
        name: "École Supérieur en Agronomie et Environnement de Diego(ESAED) - Diego Suarez",
        section: "Etablissement Master Divers Bac +5"
    },
    {
        name: "Université d’Antananarivo GESTION DES RESSOURCES NATURELLES ET DEVELOPPEMENT",
        section: "PhD doctorat Sciences de l'Ingénieur"
    },
    {
        name: "Université d’Antananarivo VALORISATION DES RESSOURCES NATURELLES",
        section: "PhD doctorat SCIENCES ET TECHNOLO"
    },
    {
        name: "Université d’Antsiranana ENERGIES RENOUVELABLES et ENVIRONNEMENT",
        section: "PhD doctorat ENERGIES RENOUVELABLES et ENVIRONNEMENT"
    }

]