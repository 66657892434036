import React, { useEffect } from "react";
import { apiNodeService } from "../../../provider/apiNodeService";
import { useToast } from "@chakra-ui/react";
import "./login_build.css"
import TextField from '@mui/material/TextField';
import { Button as MuiButton } from "@mui/material";

const Forget_Password = () => {

    const toast = useToast();

    const [loading, setLoading] = React.useState(false);
    const [email, setEmail] = React.useState('');
    const [isSended, setIsSended] = React.useState(false);

    useEffect(() => {
    }, [])

    const resetPassword = () => {
        setLoading(true)
        let data = { email: email, table: 'user_build' }
        apiNodeService.resetPasswordFreelance(data).then(res => {
            if (res.status === 200) {
                console.log(res)
                setIsSended(true)
                setLoading(false)
                toast({
                    title: 'Succès !',
                    description: "Veuillez consulter votre boite email !",
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });
            } else if (res.status === 400) {
                toast({
                    title: 'Erreur !',
                    description: "Utilisateur n'existe pas! Veuillez vérifier'",
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
                setLoading(false)
            } else {
                toast({
                    title: 'Erreur !',
                    description: "Une erreur est survenue, Veuillez réessayer ultérieurement",
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            }

        })
    }

    return (
        <div>
            <div className="header-reset d-flex">
                <span style={{ fontSize: 36, color: '#000', fontWeight: 700 }}>Build.</span>
                <span style={{ fontSize: 36, color: '#214dcb', fontWeight: 700 }}>Mada</span>
            </div>
            <div className="d-flex flex-column align-items-center" style={{ marginTop: 70, width: "100%", gap: 20 }}>
                <span style={{ fontSize: 22, color: '#000', fontWeight: 600 }}>Entrez votre email</span>
                <TextField style={{ width: '22rem' }} size="small"
                    value={email}
                    placeholder="xxxxx@xxx.xx"
                    onChange={(e) => {
                        setEmail(e.target.value)
                    }}
                    id="outlined-basic" variant="outlined" />
                <MuiButton variant="contained" color="primary" size="large"
                    style={{ textTransform: "none", fontWeight: 600, height: 46, fontSize: "1.1rem", width: '22rem' }}
                    onClick={() => {
                        resetPassword()
                    }}
                    fullWidth={true}
                >
                    Valider
                </MuiButton>
            </div>
        </div>
    )
}
export default Forget_Password;