import React from 'react';
import * as XLSX from 'xlsx';

class ExcelToJson2c extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          jsonData: [],
        };
      }
    
      handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
          const reader = new FileReader();
          reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
            const formattedData = jsonData
              .slice(1) // Ignore header row
              .map((row) => ({
                nom: row[0], // Assuming first column is 'nom'
                prenom: row[1], // Assuming second column is 'prenom'
              }));
            this.setState({ jsonData: formattedData });
          };
          reader.readAsArrayBuffer(file);
        }
      };
    
      render() {
        return (
          <div>
            <input type="file" onChange={this.handleFileChange} />
            <div>
              <h2>JSON Data:</h2>
              <pre>{JSON.stringify(this.state.jsonData, null, 2)}</pre>
            </div>
          </div>
        );
      }
    }
export default ExcelToJson2c;
