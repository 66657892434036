const endpoint = process.env.REACT_APP_API

export const maillingService = {

    sendMailByTemplate(data){
        return fetch(endpoint+'/api/mailjet/send_mail_by_template', {
            method: 'POST',
            headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
            body: JSON.stringify(data)
        }).then(response => response.json()).catch(error => {
            console.log(error);
        });
    },

    sendMailByHtmlText(data){
        return fetch(endpoint+'/api/mailjet/send_mail_by_html_text', {
            method: 'POST',
            headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
            body: JSON.stringify(data)
        }).then(response => response.json()).catch(error => {
            console.log(error);
        });
    },

    sendFlyerPdf(data){
        return fetch(endpoint+'/api/mailjet/send_flyer_pdf', {
            method: 'POST',
            headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
            body: JSON.stringify(data)
        }).then(response => response.json()).catch(error => {
            console.log(error);
        });
    }
};
