import React from "react";
import useWindowSize from "../../components/WindowSize/useWindowSize";
import { useNavigate } from "react-router-dom";
import oalogo from "../../assets/logo/logo3.png"
import "./login.css"
import "./backgroundAnim.css"
import { Button as MuiButton, TextField } from "@mui/material";
import LoginIcon from '@mui/icons-material/Login';
import { apiNodeService } from "../../provider/apiNodeService";
import MuiBackdrop from "../../components/Loading/MuiBackdrop";
import { GoogleLogin } from 'react-google-login';

const popup_w = 900;
const popup_h = 700;

var dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screen.left;
var dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screen.top;

var width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : window.screen.width;
var height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : window.screen.height;

var left = ((width / 2) - (popup_w / 2)) + dualScreenLeft;
var top = ((height / 2) - (popup_h / 2)) + dualScreenTop;


export default function Login(props) {

    const screenSize = useWindowSize()
    const navigate = useNavigate()

    const [loading, setLoading] = React.useState(false);
    const [email, setEmail] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [errorMsg, setErrorMsg] = React.useState("");

    const getUserByEmail = async () => {
        setLoading(true)
        return new Promise(resolve => {
            apiNodeService.filterTable({ table: "user", filter: { email: email }, db: "FREELANCE_MAD" }).then(userRes => {
                if (userRes && userRes !== "false" && Array.isArray(userRes.data)) {
                    if (userRes.data[0]) {
                        if (userRes.data[0].password === password) {
                            localStorage.setItem("email", userRes.data[0].email)
                            localStorage.setItem("first_name", userRes.data[0].first_name)
                            setErrorMsg("")
                            navigate("/home/skills")
                        } else {
                            setErrorMsg("Vérifiez votre mot de passe !")
                        }

                        setLoading(false)
                    } else {
                        setErrorMsg("Vérifiez votre email !")
                        setLoading(false)
                    }

                    resolve(userRes.data[0])
                } else {
                    resolve("false")
                    setErrorMsg("Vérifiez votre email !")
                }
            }).catch(err => resolve("false"))
        })
    }

    const googleLogin = async (response) => {
        setLoading(true)
        if (response && response.Ca) {
          const user = await getUserByEmail1(response.profileObj.email)
          if (user) {
            localStorage.setItem("email", response.profileObj.email)
            localStorage.setItem("first_name", response.profileObj.givenName)
            navigate("/home/skills")
          } else {
            setLoading(false)
          }
        }
      }

      const getUserByEmail1 = async (email) => {
        return new Promise(resolve => {
          apiNodeService.filterTable({table:"user",filter:{email:email},db:"FREELANCE_MAD"}).then(userRes => {
            if (userRes && userRes !== "false" && Array.isArray(userRes.data)) {
              resolve(userRes.data[0])
            } else {
              resolve("false")
            }
          }).catch(err => resolve("false"))
        })
      }


    return (
        <div>
            <MuiBackdrop open={loading} text={"Chargement..."} />
            <div className="account-container">

                <div className="right-container right-loaded" style={{ top: 0, marginTop: 120, borderRadius: 10, zIndex: 100 }}>
                    <div className="sign-list" id="sign-in" style={{ display: "block" }}>
                        <a className="left-logo">
                            <img src={oalogo} alt="" />
                        </a>
                        <h5 className="mt-4">Connectez-vous à Freelance-Mada</h5>
                        <div className="input-list" style={{ marginTop: 30 }}>
                            <div className="input-list-con first-init" style={{ display: "block" }}>
                                <div className="row align-items-center justify-content-around">
                                    <div className="col-md-6">
                                        <TextField className="inupt-login" fullWidth value={email} onChange={(e) => {
                                            setEmail(e.target.value)
                                        }} size="small" id="outlined-basic" label="Email" variant="outlined" />
                                    </div>
                                    <div className="col-md-6">
                                        <TextField className="inupt-login" fullWidth type="password" value={password} onChange={(e) => {
                                            setPassword(e.target.value)
                                        }} size="small" id="outlined-basic" label="Mot de passe" variant="outlined" />

                                    </div>
                                </div>
                                <div align="center" style={{ marginTop: 30 }}>
                                    <MuiButton variant="contained" color="primary" size="large"
                                        style={{ textTransform: "none", fontWeight: 800, height: 46, fontSize: "1.1rem", width: 220 }}
                                        endIcon={<LoginIcon style={{ color: "#fff" }} />}
                                        onClick={() => {
                                            getUserByEmail()
                                        }}
                                        fullWidth={true}
                                    >
                                        Se connecter
                                    </MuiButton>
                                </div>
                                <div align="center" style={{ marginTop: 15 }}>
                                <GoogleLogin
                                    clientId={process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID}
                                    render={renderProps => (
                                    <img height={45} style={{cursor:'pointer'}} width={75} alt="login" src="/google.png" onClick={renderProps.onClick}/>
                                    )}
                                    buttonText="Login"
                                    onSuccess={googleLogin}
                                    onFailure={googleLogin}
                                    cookiePolicy={'single_host_origin'}
                                />
                                </div>
                                <div className="mt-2" align="center">
                                    <span style={{ fontSize: 12, fontWeight: 500, color: "#1976d2", cursor: 'pointer' }} onClick={(e) => { navigate('/signup') }}>Créer un compte</span>
                                </div>
                                {errorMsg !== "" && <span className="mt-1" style={{ color: "red", marginLeft: 2 }}>{errorMsg}</span>}

                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div className="area">
                <ul className="circles">
                    {
                        ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"].map((item, key) => (
                            <li key={key} />
                        ))
                    }
                </ul>
            </div>

        </div>
    )

}