import React, { useState } from 'react';
import formations from "../../data/formation"


function TreeNode({ data, onCheckboxChange, selectedItems }) {
    const hasChildren = data.children.length > 0;
    const isChecked = selectedItems.includes(data.name);

    const handleCheckboxChange = (event) => {
        console.log(event.target.checked)
        onCheckboxChange(data.name, isChecked);
    };

    return (
        <div>
            {hasChildren ? (
                <span>{data.name}</span>
            ) : (
                <div className='d-flex justify-content-between'>
                    <label>

                        {data.name}
                    </label>
                    <input type="checkbox" checked={isChecked} onChange={handleCheckboxChange} />
                </div>

            )}
            {hasChildren && (
                <ul>
                    {data.children.map((child) => (
                        <li key={child.name}>
                            <TreeNode
                                data={child}
                                onCheckboxChange={onCheckboxChange}
                                selectedItems={selectedItems}
                            />
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
}

function TreeList({ onCheckboxChange }) {
    const [selectedItems, setSelectedItems] = useState([]);

    const handleCheckboxChange = (itemName, isChecked) => {
        if (isChecked) {
            setSelectedItems(selectedItems.filter((item) => item !== itemName));
        } else {
            setSelectedItems([...selectedItems, itemName]);
        }
        onCheckboxChange(itemName)
    };

    return (
        <div>
            <ul>
                {formations.formation.map((item) => (
                    <li key={item.name}>
                        <TreeNode data={item} onCheckboxChange={handleCheckboxChange} selectedItems={selectedItems} />
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default TreeList;
