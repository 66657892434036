export const workspaces = [
    {
        "id":1,
        "name":"Intern"
    },
    {
        "id":2,
        "name":"Finance"
    },
    {
        "id":3,
        "name":"Marketing"
    }
]