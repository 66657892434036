let dataAnnuaire ={
    "Designer de mode :": {
        "Prêt-à-porter :": {
            "description": "Les designers de prêt-à-porter créent des vêtements de mode prêts à être portés pour les collections de mode saisonnières. Ils peuvent travailler pour des marques de mode de luxe ou pour des marques grand public."
        },
        "Haute couture :": {
            "description": "Les designers de haute couture créent des vêtements de luxe sur mesure pour des clients individuels. Ils travaillent souvent pour des maisons de couture établies ou ont leur propre marque de haute couture."
        },
        "Accessoires :": {
            "description": "Les designers d'accessoires créent des accessoires de mode tels que des chaussures, des sacs à main, des bijoux et des lunettes. Ils peuvent travailler pour des marques de mode ou avoir leur propre marque d'accessoires."
        },
        "Designers de costumes :": {
            "description": "Les designers de costumes créent des costumes pour le cinéma, le théâtre, la télévision et d'autres productions. Ils peuvent travailler pour des studios de cinéma ou de télévision, des théâtres ou des compagnies de production."
        },
        "Designers de sportswear :": {
            "description": "Les designers de sportswear créent des vêtements et des équipements de sport pour les activités physiques. Ils peuvent travailler pour des marques de sportswear ou pour des marques de mode qui ont une ligne de sportswear."
        },
        "Designers de lingerie :": {
            "description": "Les designers de lingerie créent des sous-vêtements et des vêtements de nuit pour les femmes et les hommes. Ils peuvent travailler pour des marques de lingerie ou avoir leur propre marque de lingerie."
        },
        "Designers de maillots de bain :": {
            "description": "Les designers de maillots de bain créent des maillots de bain pour les femmes et les hommes. Ils peuvent travailler pour des marques de maillots de bain ou pour des marques de mode qui ont une ligne de maillots de bain."
        }
    },
    "Designer de produit": {
        "Designer industriel": {
            "Designer de produits de consommation": {
                "description": "Les designers de produits de consommation conçoivent des produits tels que des appareils électroménagers, des produits électroniques grand public, des articles de sport et de loisirs, des jouets, des outils et des accessoires. Ils se concentrent sur la fonctionnalité, la forme, l'esthétique et l'ergonomie."
            },
            "Designer d'équipements industriels :": {
                "description": "Les designers d'équipements industriels conçoivent des machines et des équipements utilisés dans la production industrielle, tels que des machines-outils, des équipements de fabrication de semi-conducteurs, des équipements de production d'énergie et des équipements de traitement des matériaux. Ils se concentrent sur la fonctionnalité, la sécurité, la fiabilité et l'efficacité."
            },
            "Designer de systèmes de transport :": {
                "Designer de véhicules": {
                    "Designer automobile :": {
                        "description": "Les designers automobiles travaillent sur la conception de voitures particulières, de véhicules utilitaires, de camions, de motos et d'autres types de véhicules. Ils créent des designs pour l'extérieur et l'intérieur des véhicules, en veillant à ce que les designs soient esthétiquement agréables et fonctionnels."
                    },
                    "Designer de motos": {
                        "description": "Les designers de motos travaillent spécifiquement sur la conception de motos, en créant des designs pour les modèles de production en série ainsi que pour les prototypes de motos personnalisées."
                    },
                    "Designer de camions": {
                        "description": "Les designers de camions travaillent sur la conception de camions commerciaux, tels que les camions de livraison, les camions à benne basculante et les camions de remorquage. Ils travaillent à la fois sur l'extérieur et l'intérieur des véhicules, en veillant à ce que les designs soient fonctionnels et esthétiquement agréables."
                    },
                    "Designer de bateaux": {
                        "description": "Les designers de bateaux travaillent sur la conception de bateaux de plaisance, de bateaux de pêche, de bateaux de croisière et d'autres types de bateaux. Ils créent des designs pour l'extérieur et l'intérieur des bateaux, en veillant à ce que les designs soient fonctionnels et esthétiquement agréables."
                    },
                    "Designer d'avions": {
                        "description": "Les designers d'avions travaillent sur la conception d'avions commerciaux, de jets privés, d'avions militaires et d'autres types d'aéronefs. Ils travaillent à la fois sur l'extérieur et l'intérieur des avions, en veillant à ce que les designs soient esthétiquement agréables et fonctionnels."
                    }
                },
                "Designer d'intérieurs de transports publics": {
                    "description": "Les designers d'intérieurs de transports publics travaillent sur la conception d'espaces intérieurs pour les trains, les métros, les bus et les avions. Ils créent des designs qui répondent aux besoins des passagers, tels que des sièges confortables, des espaces de rangement pour les bagages et des systèmes de divertissement à bord."
                },
                "Designer d'interfaces utilisateur pour les systèmes de transport :": {
                    "description": "Les designers d'interfaces utilisateur travaillent sur la conception des interfaces utilisateur pour les systèmes de transport, tels que les systèmes de navigation, les systèmes d'information sur les horaires, les systèmes de billetterie et les applications mobiles."
                },
                "Designer d'infrastructures de transport : L": {
                    "description": "Les designers d'infrastructures de transport travaillent sur la conception de routes, de ponts, de tunnels, de gares, de ports et d'aéroports. Ils s'assurent que ces infrastructures sont fonctionnelles, durables et esthétiquement agréables."
                },
                "Designer de systèmes de transport intelligents :": {
                    "description": "Les designers de systèmes de transport intelligents travaillent sur la conception de systèmes de transport qui utilisent les technologies de l'information pour améliorer l'efficacité et la sécurité du transport, tels que les systèmes de contrôle de la circulation, les systèmes de signalisation intelligents et les systèmes de gestion de flotte."
                }
            },
            "Designer de meubles industriels :": {
                "description": "Les designers de meubles industriels conçoivent des meubles pour une utilisation dans des environnements industriels tels que des bureaux, des usines et des entrepôts. Ils se concentrent sur la fonctionnalité, la durabilité et l'esthétique."
            },
            "Designer d'emballages écologique": {
                "Designer d'emballage de produits de consommation :": {
                    "Les designers d'emballage  pour Aliments et boissons": {
                        "description": "Les designers d'emballage de produits alimentaires et de boissons travaillent sur la conception d'emballages pour des produits tels que les aliments pour animaux de compagnie, les produits laitiers, les snacks, les jus, les boissons gazeuses, les bières et les vins."
                    },
                    "Les designers d'emballage  pour  Produits de beauté et de soins personnels :": {
                        "description": "Les designers d'emballage de produits de beauté et de soins personnels travaillent sur la conception d'emballages pour des produits tels que les cosmétiques, les produits de soin de la peau, les parfums, les produits de coiffure, les produits d'hygiène bucco-dentaire, les produits de rasage et les produits pour bébés."
                    },
                    "Les designers d'emballage  pour  Produits ménagers :": {
                        "description": "Les designers d'emballage de produits ménagers travaillent sur la conception d'emballages pour des produits tels que les produits de nettoyage, les produits de lessive, les produits de vaisselle, les sacs poubelles et les insecticides."
                    },
                    "Les designers d'emballage de jouets et de jeux t": {
                        "description": "Les designers d'emballage de jouets et de jeux travaillent sur la conception d'emballages pour des produits tels que les jeux de société, les jeux vidéo, les poupées, les figurines d'action, les jeux de construction et les peluches."
                    },
                    "Les designers d'emballage  pour  Produits électroniques grand public :": {
                        "description": "Les designers d'emballage de produits électroniques grand public travaillent sur la conception d'emballages pour des produits tels que les ordinateurs portables, les tablettes, les smartphones, les écouteurs et les haut-parleurs."
                    },
                    "Les designers d'emballage  pour  Produits de bureau et de papeterie :": {
                        "description": "Les designers d'emballage de produits de bureau et de papeterie travaillent sur la conception d'emballages pour des produits tels que les stylos, les crayons, les cahiers, les agendas, les enveloppes et les étiquettes."
                    }
                },
                "Designer d'emballage de luxe :": {
                    "description": "Les designers d'emballage de luxe travaillent sur la conception d'emballages haut de gamme pour des produits tels que les parfums, les cosmétiques, les bijoux, les vins et les spiritueux. Ils se concentrent sur la création d'un packaging prestigieux et élégant qui reflète la valeur et la qualité du produit."
                },
                "Designer d'emballage industriel :": {
                    "description": "Les designers d'emballage industriel conçoivent des emballages pour les produits industriels tels que les pièces automobiles, les produits chimiques et les matériaux de construction. Ils se concentrent sur la création"
                },
                "Designer d'emballage écologique :": {
                    "description": "Les designers d'emballage écologique se concentrent sur la création d'emballages durables et respectueux de l'environnement. Ils travaillent sur des emballages biodégradables, compostables et recyclables, ainsi que sur des matériaux d'emballage renouvelables."
                },
                "Designer d'emballage de produits électroniques :": {
                    "description": "Les designers d'emballage de produits électroniques travaillent sur la conception d'emballages pour des produits tels que les ordinateurs, les téléphones portables, les tablettes et les accessoires électroniques. Ils se concentrent sur la protection des produits pendant le transport et sur la création d'un packaging attrayant pour les consommateurs."
                },
                "Designer d'emballage de produits médicaux :": {
                    "description": "Les designers d'emballage de produits médicaux travaillent sur la conception d'emballages pour des produits tels que les médicaments, les dispositifs médicaux et les équipements médicaux. Ils se concentrent sur la sécurité et la facilité d'utilisation des emballages pour les professionnels de la santé et les patients."
                }
            },
            "Designer de systèmes d'éclairage": {
                "description": "Les designers de systèmes d'éclairage conçoivent des luminaires pour une utilisation dans des espaces intérieurs et extérieurs, tels que des lampes de table, des lampes de plafond, des lampadaires et des éclairages de paysage. Ils se concentrent sur la fonctionnalité, l'efficacité énergétique, l'esthétique et la sécurité."
            }
        },
        "Designer d'emballage :": {
            "description": "Les designers d'emballage conçoivent des emballages pour divers produits de consommation tels que des aliments, des boissons, des produits de beauté, des produits électroniques et des jouets."
        },
        "Designer d'expérience utilisateur (UX) :": {
            "Designer d'interface utilisateur (UI) :": {
                "description": "Les designers UI se concentrent sur la création de l'apparence visuelle et de l'interaction des éléments d'interface utilisateur, tels que les boutons, les icônes et les menus."
            },
            "Designer de navigation": {
                "description": "Les designers de navigation se concentrent sur la création de systèmes de navigation efficaces et intuitifs pour les sites web, les applications mobiles et les produits numériques."
            },
            "Designer de contenu": {
                "description": "Les designers de contenu se concentrent sur la création de contenus numériques, tels que des images, des vidéos, des textes et des sons, qui renforcent l'expérience utilisateur et communiquent efficacement avec l'utilisateur."
            },
            "Designer de recherche utilisateur :": {
                "description": "Les designers de recherche utilisateur se concentrent sur la recherche et l'analyse des comportements et des besoins des utilisateurs afin de créer des produits numériques qui répondent à leurs attentes."
            },
            "Designer de service :": {
                "description": "Les designers de service se concentrent sur la création d'expériences utilisateur globales pour des services en ligne et hors ligne. Ils travaillent souvent en étroite collaboration avec les équipes marketing, les équipes de vente et les équipes de support client."
            },
            "Designer de jeux :": {
                "description": "Les designers de jeux se concentrent sur la création de jeux interactifs et ludiques pour des applications mobiles, des consoles de jeux et des ordinateurs. Ils se concentrent sur la création d'expériences utilisateur engageantes et amusantes."
            },
            "Designer de réalité virtuelle (VR) et de réalité augmentée (AR) :": {
                "description": "Les designers de VR et AR se concentrent sur la création d'expériences utilisateur immersives en utilisant la réalité virtuelle et la réalité augmentée."
            }
        },
        "Designer de mobilier :": {
            "Designer de meubles d'intérieur": {
                "description": "Les designers de meubles d'intérieur conçoivent des meubles pour une utilisation dans des espaces de vie intérieurs tels que des maisons, des appartements et des bureaux. Ils se concentrent sur la création de pièces confortables, fonctionnelles et esthétiquement agréables."
            },
            "Designer de meubles d'extérieur :": {
                "description": "Les designers de meubles d'extérieur conçoivent des meubles pour une utilisation en plein air tels que des chaises, des tables, des bancs et des parasols. Ils se concentrent sur la résistance aux intempéries et la durabilité, tout en gardant à l'esprit l'esthétique et le confort."
            },
            "Designer de meubles de cuisine :": {
                "description": "Les designers de meubles de cuisine conçoivent des meubles pour une utilisation dans des cuisines domestiques et professionnelles, tels que des armoires, des étagères, des îlots et des plans de travail. Ils se concentrent sur la fonctionnalité et l'ergonomie, tout en gardant à l'esprit l'esthétique."
            },
            "Designer de meubles de salle de bain": {
                "description": "Les designers de meubles de salle de bain conçoivent des meubles pour une utilisation dans des salles de bains domestiques et professionnelles, tels que des armoires, des lavabos et des miroirs. Ils se concentrent sur la fonctionnalité, la résistance à l'eau et l'esthétique."
            },
            "Designer de meubles pour enfants :": {
                "description": "Les designers de meubles pour enfants conçoivent des meubles pour une utilisation par les enfants tels que des lits, des tables de jeu, des chaises et des bibliothèques. Ils se concentrent sur la sécurité, la fonctionnalité et l'esthétique ludique."
            },
            "Designer de meubles sur mesure": {
                "description": "Les designers de meubles sur mesure conçoivent des meubles uniques et personnalisés pour répondre aux besoins et aux spécifications spécifiques des clients. Ils se concentrent sur la personnalisation, la qualité et l'esthétique."
            }
        },
        "Designer de jouets :": {
            "description": "Les designers de jouets créent des jouets pour enfants de tout âge, en prenant en compte les aspects de la sécurité, la stimulation et l'amusement."
        },
        "Designer de bijoux :": {
            "description": "Les designers de bijoux créent des bijoux pour hommes et femmes, en utilisant des matériaux tels que des métaux précieux, des pierres précieuses et des matériaux synthétiques."
        },
        "Designer de produits durables :": {
            "description": "Les designers de produits durables se concentrent sur la création de produits écologiques et respectueux de l'environnement en utilisant des matériaux recyclés et en minimisant leur impact sur l'environnement."
        }
    },
    "Responsable des relations publiques :": {
        "Responsable des relations publiques produit :": {
            "description": "le responsable des relations publiques produit est en charge de promouvoir les produits de luxe d'une entreprise auprès des médias et des influenceurs. Il organise des événements de presse, des présentations de produits, des défilés de mode, des shootings de photos, etc. afin de générer une couverture médiatique positive pour les produits de luxe de l'entreprise."
        },
        "Responsable des relations publiques de la marque :": {
            "description": "le responsable des relations publiques de la marque est en charge de promouvoir l'image de marque d'une entreprise de luxe auprès des médias et du grand public. Il organise des événements de relations publiques, des lancements de produits, des défilés de mode, des interviews de personnalités, etc. afin de renforcer la notoriété et la réputation de l'entreprise de luxe."
        },
        "Responsable des relations publiques événementielles :": {
            "description": "le responsable des relations publiques événementielles est en charge d'organiser des événements pour promouvoir les produits ou l'image de marque d'une entreprise de luxe. Il peut organiser des événements tels que des soirées de lancement, des défilés de mode, des expositions d'art, des festivals de cinéma, etc. afin de créer un buzz et d'attirer l'attention sur l'entreprise de luxe."
        },
        "Responsable des relations publiques institutionnelles :": {
            "description": "le responsable des relations publiques institutionnelles est en charge de promouvoir les relations de l'entreprise de luxe avec les parties prenantes telles que les investisseurs, les organisations professionnelles, les gouvernements, etc. Il organise des événements et des communications pour renforcer la réputation de l'entreprise de luxe auprès de ces parties prenantes."
        },
        "Responsable des relations publiques digitales :": {
            "description": "le responsable des relations publiques digitales est en charge de promouvoir les produits ou l'image de marque d'une entreprise de luxe sur les médias sociaux, les blogs, les sites web et autres canaux numériques. Il travaille avec des influenceurs, des blogueurs, des journalistes en ligne, etc. pour créer une présence en ligne pour l'entreprise de luxe et engager la communauté en ligne."
        }
    },
    "Directeur artistique :": {
        "Directeur artistique en mode :": {
            "description": "le directeur artistique en mode est en charge de la direction artistique d'une maison de mode de luxe. Il travaille en étroite collaboration avec le directeur général, les stylistes et les designers pour concevoir des collections de mode haut de gamme. Il veille à l'identité visuelle de la marque et supervise également des équipes créatives pour s'assurer que le travail est réalisé selon les normes de qualité attendues."
        },
        "Directeur artistique en joaillerie :": {
            "description": "le directeur artistique en joaillerie est en charge de la direction artistique d'une maison de joaillerie de luxe. Il travaille en étroite collaboration avec les joailliers et les designers pour concevoir des bijoux de haute qualité, des modèles uniques et des collections de bijoux exclusifs pour la marque."
        },
        "Directeur artistique en parfumerie :": {
            "description": "le directeur artistique en parfumerie est en charge de la direction artistique d'une maison de parfums de luxe. Il travaille en étroite collaboration avec les nez et les designers pour concevoir des parfums de haute qualité, des flacons de parfum uniques et des campagnes publicitaires pour la marque."
        },
        "Directeur artistique en hôtellerie :": {
            "description": "le directeur artistique en hôtellerie est en charge de la direction artistique d'un hôtel de luxe. Il travaille en étroite collaboration avec les architectes d'intérieur, les designers et les artisans pour concevoir l'aménagement intérieur, les chambres, les espaces publics, les restaurants et les spas de l'hôtel."
        }
    },
    "Responsable marketing :": {
        "Responsable marketing produit :": {
            "description": "le responsable marketing produit est en charge du développement et de la mise en place de la stratégie marketing pour un produit spécifique de la marque de luxe. Il travaille en étroite collaboration avec les équipes de création, de production, de vente et de communication pour définir les objectifs de vente, les messages clés, les canaux de distribution et les campagnes publicitaires associées à ce produit."
        },
        "Responsable marketing digital :": {
            "Responsable marketing digital de marque": {
                "description": ":le responsable marketing digital de marque est en charge de la stratégie digitale de la marque de luxe dans son ensemble. Il travaille sur l'élaboration de la stratégie de contenu, la gestion de l'image de marque sur les réseaux sociaux, la mise en place de campagnes publicitaires en ligne et la mesure de l'efficacité des actions menées."
            },
            "Responsable marketing digital de produit :": {
                "description": "le responsable marketing digital de produit est en charge de la stratégie digitale pour un produit spécifique de la marque de luxe. Il travaille en étroite collaboration avec les équipes de création, de production, de vente et de communication pour définir les objectifs de vente, les messages clés, les canaux de distribution et les campagnes publicitaires associées à ce produit."
            },
            "Responsable marketing digital de la clientèle :": {
                "description": "le responsable marketing digital de la clientèle est en charge de la stratégie digitale pour l'acquisition et la fidélisation des clients de la marque de luxe. Il travaille sur l'optimisation du parcours client en ligne, la personnalisation de l'expérience client, la mise en place de programmes de fidélité en ligne et la mesure de l'efficacité des actions menées."
            },
            "Responsable marketing digital événementiel :": {
                "description": "le responsable marketing digital événementiel est en charge de la stratégie digitale pour les événements de la marque de luxe. Il travaille en étroite collaboration avec les équipes de communication, de création et de production pour assurer la réussite de ces événements, y compris la promotion en ligne, la gestion des inscriptions et la mesure de l'engagement des participants."
            }
        },
        "Responsable marketing événementiel :": {
            "description": "le responsable marketing événementiel est en charge de la conception, de la planification et de la mise en place des événements de la marque de luxe. Il peut s'agir de lancements de produits, d'événements exclusifs pour les clients VIP, d'expositions, de soirées de gala, etc. Il travaille en étroite collaboration avec les équipes de communication, de création et de production pour assurer la réussite de ces événements."
        },
        "Responsable marketing retail :": {
            "description": "le responsable marketing retail est en charge de la stratégie marketing pour les points de vente de la marque de luxe. Il travaille sur l'amélioration de l'expérience client en magasin, la mise en place de campagnes de promotion pour inciter à l'achat, la gestion des stocks et la mesure des performances des points de vente."
        }
    },
    "Styliste :": {
        "Styliste de mode :": {
            "description": "le styliste de mode est en charge de la création de la collection de vêtements et d'accessoires d'une marque de luxe. Il travaille en étroite collaboration avec les directeurs artistiques pour créer des pièces uniques et des looks qui répondent aux exigences de la marque et des clients."
        },
        "Styliste d'accessoires :": {
            "description": "le styliste de mode est en charge de la création de la collection de vêtements et d'accessoires d'une marque de luxe. Il travaille en étroite collaboration avec les directeurs artistiques pour créer des pièces uniques et des looks qui répondent aux exigences de la marque et des clients."
        },
        "Styliste de coiffure et maquillage :": {
            "description": "le styliste de coiffure et maquillage est en charge de la création des looks capillaires et maquillage pour les défilés de mode et les campagnes publicitaires. Il travaille en étroite collaboration avec les directeurs artistiques et les photographes pour créer des looks qui mettent en valeur la beauté et l'élégance de la marque de luxe."
        },
        "Styliste d'intérieur :": {
            "description": "le styliste d'intérieur est en charge de la création de l'ambiance et du décor des magasins, des showrooms et des événements de la marque de luxe. Il travaille en étroite collaboration avec les architectes d'intérieur et les responsables de la vente pour créer des espaces qui reflètent l'essence de la marque et attirent les clients."
        }
    },
    "Horloger :": {
        "Designer horloger :": {
            "description": "le designer horloger est en charge de la conception esthétique de la montre de luxe. Il travaille en étroite collaboration avec les ingénieurs horlogers pour créer des montres innovantes, élégantes et sophistiquées."
        },
        "Ingénieur horloger :": {
            "description": "l'ingénieur horloger est en charge de la conception technique de la montre de luxe. Il travaille en étroite collaboration avec le designer horloger pour créer des montres qui allient esthétique et fonctionnalité."
        },
        "Horloger assembleur :": {
            "description": "l'horloger assembleur est en charge de l'assemblage des différentes pièces de la montre de luxe. Il doit être extrêmement minutieux et avoir une grande expertise technique pour s'assurer que la montre fonctionne correctement."
        },
        "Horloger réparateur :": {
            "description": "l'horloger réparateur est en charge de la réparation et de l'entretien des montres de luxe. Il doit être capable de diagnostiquer et de réparer toutes sortes de problèmes techniques pour que la montre continue de fonctionner de manière optimale."
        },
        "Responsable marketing horlogerie :": {
            "description": "le responsable marketing horlogerie est en charge de la stratégie marketing et de la promotion des montres de luxe. Il travaille en étroite collaboration avec les designers et les ingénieurs horlogers pour développer des campagnes publicitaires créatives qui mettent en valeur les caractéristiques et la qualité des montres."
        },
        "Responsable des ventes horlogerie :": {
            "description": "le responsable des ventes horlogerie est en charge de la commercialisation des montres de luxe. Il travaille en étroite collaboration avec le responsable marketing pour atteindre les objectifs de vente et développer la clientèle."
        },
        "Expert horloger :": {
            "description": "l'expert horloger est un professionnel spécialisé dans l'expertise et l'authentification des montres de luxe. Il doit avoir une connaissance approfondie des marques, des modèles et des mouvements pour identifier les contrefaçons et les montres falsifiées."
        }
    },
    "Joaillier :": {
        "Joaillier haute joaillerie :": {
            "description": "ce type de joaillier se spécialise dans la fabrication de pièces de joaillerie haut de gamme, souvent des pièces uniques et personnalisées. Ils utilisent souvent des pierres précieuses et des métaux rares, et leurs pièces sont souvent vendues à des prix très élevés."
        },
        "Joaillier bijoutier :": {
            "description": "un joaillier bijoutier crée des bijoux plus abordables, utilisant des matériaux tels que l'argent, l'or et les pierres semi-précieuses. Ils peuvent également créer des bijoux fantaisie pour des occasions spéciales."
        },
        "Joaillier artisan :": {
            "description": "un joaillier artisan crée des pièces de joaillerie à la main, souvent en utilisant des techniques traditionnelles et des matériaux de qualité supérieure. Ils peuvent travailler en indépendant ou pour une entreprise de joaillerie."
        },
        "Joaillier sertisseur :": {
            "description": "ce type de joaillier se spécialise dans la technique de sertissage, qui consiste à fixer les pierres précieuses dans les montures en métal. Ils doivent avoir une grande attention aux détails et une grande habileté manuelle."
        },
        "Joaillier lapidaire :": {
            "description": "un lapidaire est un artisan qui travaille les pierres précieuses pour les tailler et les polir en formes spécifiques. Les lapidaires peuvent travailler pour des joailliers ou des bijoutiers, ou en tant qu'indépendants."
        },
        "Gemmologue :": {
            "description": "le gemmologue est un expert en gemmes et en pierres précieuses. Il est en charge de l'identification, de l'évaluation et de la certification des pierres utilisées dans la fabrication de bijoux de luxe."
        },
        "Responsable marketing joaillerie :": {
            "description": "le responsable marketing joaillerie est en charge de la stratégie marketing et de la promotion des bijoux de luxe. Il travaille en étroite collaboration avec les designers, les gemmologues et les artisans pour développer des campagnes publicitaires créatives qui mettent en valeur les caractéristiques et la qualité des bijoux."
        },
        "Responsable des ventes joaillerie :": {
            "description": "le responsable des ventes joaillerie est en charge de la commercialisation des bijoux de luxe. Il travaille en étroite collaboration avec le responsable marketing pour atteindre les objectifs de vente et développer la clientèle."
        },
        "Expert joaillier :": {
            "description": "l'expert joaillier est un professionnel spécialisé dans l'expertise et l'authentification des bijoux de luxe. Il doit avoir une connaissance approfondie des marques, des styles et des matériaux pour identifier les contrefaçons et les bijoux falsifiés."
        }
    },
    "Artisan d'art :": {
        "Calligraphiste": {
            "Calligraphe traditionnel :": {
                "description": "un calligraphe traditionnel pratique les styles de calligraphie historiques, tels que l'écriture cursive chinoise, la calligraphie arabe, ou la calligraphie occidentale ancienne comme l'écriture gothique."
            },
            "Calligraphe contemporain :": {
                "description": "un calligraphe contemporain utilise des techniques traditionnelles de calligraphie, mais les applique dans un contexte plus moderne. Ils peuvent créer des œuvres d'art originales, des designs de tatouages, des logos ou des polices de caractères."
            },
            "Enlumineur :": {
                "description": "un enlumineur est un calligraphe qui ajoute des ornements et des décorations à une page calligraphiée. Les ornements peuvent inclure des motifs floraux, des illustrations, des bordures décoratives, etc."
            },
            "Graphiste calligraphe :": {
                "description": "un graphiste calligraphe utilise des compétences en calligraphie pour créer des designs modernes, des logos, des affiches, des cartes de vœux et des produits imprimés."
            },
            "Scribe :": {
                "description": "un scribe est un calligraphe qui copie des textes anciens ou des documents importants tels que des diplômes, des certificats, des invitations, des menus de restaurants, etc."
            },
            "Lettering artist :": {
                "description": "un lettering artist est un calligraphe qui utilise des techniques de dessin et de peinture pour créer des lettres décoratives sur des panneaux, des enseignes, des bannières, etc."
            },
            "Graveur :": {
                "description": "un graveur est un calligraphe qui utilise des outils de gravure pour créer des lettres calligraphiées sur des plaques de métal, des pierres tombales, des trophées, etc."
            }
        },
        "Cadre ( fabricant de cadre ) / Frame Maker )": {
            "Fabricant de cadres en métal :": {
                "description": "un fabricant de cadres en métal crée des cadres en utilisant des métaux précieux tels que l'or, l'argent, le platine ou le palladium. Les cadres peuvent être utilisés pour encadrer des miroirs, des photos, des œuvres d'art et d'autres objets de valeur."
            },
            "Fabricant de cadres en bois :": {
                "description": "un fabricant de cadres en bois crée des cadres en utilisant des bois de haute qualité tels que l'acajou, l'ébène ou le palissandre. Les cadres peuvent être utilisés pour encadrer des miroirs, des photos, des œuvres d'art et d'autres objets de valeur."
            },
            "Fabricant de cadres en cuir :": {
                "description": "un fabricant de cadres en cuir crée des cadres en utilisant du cuir de haute qualité. Les cadres peuvent être utilisés pour encadrer des miroirs, des photos, des œuvres d'art et d'autres objets de valeur."
            },
            "Fabricant de cadres en cristal :": {
                "description": "un fabricant de cadres en cristal crée des cadres en utilisant du cristal de haute qualité. Les cadres peuvent être utilisés pour encadrer des miroirs, des photos, des œuvres d'art et d'autres objets de valeur."
            },
            "Fabricant de cadres personnalisés :": {
                "description": "un fabricant de cadres personnalisés crée des cadres sur mesure pour répondre aux exigences spécifiques des clients. Les cadres peuvent être fabriqués à partir de matériaux tels que le métal, le bois, le cuir ou le cristal, selon les préférences du client."
            },
            "Fabricant de cadres d'œuvres d'art :": {
                "description": "un fabricant de cadres d'œuvres d'art crée des cadres spécifiquement pour encadrer des œuvres d'art de haute qualité. Les cadres sont souvent fabriqués sur mesure pour répondre aux exigences des collectionneurs d'art et des galeries d'art."
            }
        },
        "Céramiste :": {
            "Céramiste d'art :": {
                "description": "ce type de céramiste se concentre sur la création d'œuvres d'art uniques et originales, souvent sculpturales ou abstraites. Les céramistes d'art peuvent utiliser une variété de techniques pour créer leurs pièces, notamment le modelage à la main, le tournage, le moulage, l'émaillage et la cuisson."
            },
            "Céramiste utilitaire :": {
                "description": "ce type de céramiste se concentre sur la création d'objets fonctionnels tels que des tasses, des bols, des plats et des vases. Les céramistes utilitaires travaillent souvent avec des formes simples et des designs élégants qui mettent en valeur la beauté naturelle de la céramique. Ils peuvent également utiliser des techniques de décoration et d'émaillage pour ajouter de la couleur et de la texture à leurs pièces."
            },
            "Céramiste de production :": {
                "description": "Ce type de céramiste crée des objets en série pour des entreprises ou des marques. Les céramistes de production travaillent souvent dans des usines ou des ateliers où ils utilisent des machines pour produire des pièces en grande quantité. Ils doivent souvent travailler rapidement et efficacement pour répondre aux demandes de leurs clients."
            },
            "Céramiste traditionnel :": {
                "description": "ce type de céramiste se concentre sur la préservation des techniques et des styles de céramique traditionnels. Les céramistes traditionnels peuvent travailler dans des communautés rurales où la céramique est une activité importante, et ils peuvent utiliser des méthodes de production qui ont été transmises de génération en génération."
            },
            "Céramiste contemporain :": {
                "description": "ce type de céramiste utilise des techniques modernes et des designs innovants pour créer des pièces uniques et originales. Les céramistes contemporains peuvent incorporer des matériaux non traditionnels tels que le verre ou le métal dans leurs œuvres, ou utiliser des techniques de décoration et de cuisson avancées pour créer des effets visuels intéressants."
            }
        },
        "Ebéniste :": {
            "Ébéniste de meubles d'art :": {
                "description": "les ébénistes d'art créent des meubles uniques et haut de gamme qui sont considérés comme des œuvres d'art. Ils travaillent souvent sur commande pour des clients individuels ou des institutions."
            },
            "Ébéniste de meubles en série :": {
                "description": "Les ébénistes de meubles en série créent des meubles en grandes quantités pour des entreprises de production de meubles. Ils utilisent des techniques de production pour créer des meubles rapidement et à moindre coût."
            },
            "Ébéniste de restauration de meubles :": {
                "description": "Les ébénistes de restauration de meubles réparent et restaurent des meubles anciens pour leur redonner leur aspect d'origine. Ils peuvent travailler pour des musées, des maisons de vente aux enchères, des antiquaires ou des clients individuels."
            },
            "Ébéniste de mobilier contemporain :": {
                "description": "les ébénistes de mobilier contemporain créent des meubles modernes et fonctionnels en utilisant des matériaux tels que le bois, le métal et le verre. Ils peuvent travailler pour des entreprises de design ou avoir leur propre entreprise."
            },
            "Ébéniste de cuisine :": {
                "description": "les ébénistes de cuisine créent des armoires, des îlots et des autres éléments de cuisine sur mesure en utilisant des matériaux tels que le bois, le stratifié et le granit. Ils peuvent travailler pour des entrepreneurs en construction ou avoir leur propre entreprise."
            },
            "Ébéniste d'escaliers :": {
                "description": "les ébénistes d'escaliers créent des escaliers en bois sur mesure pour les maisons, les bâtiments commerciaux et les lieux publics. Ils peuvent travailler pour des entrepreneurs en construction ou avoir leur propre entreprise."
            },
            "Ébéniste de décoration intérieure :": {
                "description": "les ébénistes de décoration intérieure créent des objets de décoration en bois tels que des cadres, des miroirs, des sculptures et des boîtes à bijoux. Ils peuvent travailler pour des entreprises de décoration intérieure ou avoir leur propre entreprise."
            }
        },
        "Emailleurs": {
            "Émailleur sur métaux précieux :": {
                "description": "un émailleur sur métaux précieux travaille avec des métaux de haute qualité tels que l'or, l'argent, le platine ou le palladium. Ils créent des designs et appliquent de l'émail sur des bijoux et d'autres objets de luxe."
            },
            "Émailleur sur porcelaine : .": {
                "description": "un émailleur sur porcelaine applique de l'émail sur des objets en porcelaine pour créer des designs et des motifs. Les objets peuvent être des tasses, des assiettes, des vases, des figurines et d'autres objets décoratifs"
            },
            "Émailleur sur verre :": {
                "description": "un émailleur sur verre applique de l'émail sur des objets en verre, tels que des flacons de parfum, des vases, des coupes et des bijoux."
            },
            "Émailleur sur montres :": {
                "description": "un émailleur sur montres crée des designs d'émail pour les cadrans de montres de luxe. Les designs peuvent inclure des motifs floraux, des animaux, des paysages et d'autres designs personnalisés."
            },
            "Émailleur sur métaux communs :": {
                "description": "un émailleur sur métaux communs travaille avec des métaux tels que le cuivre, le laiton, l'aluminium et le zinc. Ils créent des designs et appliquent de l'émail sur des bijoux et d'autres objets décoratifs."
            },
            "Émailleur artistique :": {
                "description": "un émailleur artistique crée des pièces uniques et originales en utilisant différentes techniques d'émaillage. Leur travail peut être présenté dans des expositions d'art et de design."
            }
        },
        "Maroquinier :": {
            "Maroquinier sacs à main :": {
                "Maroquinier designer :": {
                    "description": "les maroquiniers designers travaillent souvent pour des marques de mode haut de gamme et sont responsables de la conception de sacs à main. Ils peuvent travailler sur la conception de sacs de toutes tailles, formes et styles, allant des sacs à main classiques aux sacs plus modernes et originaux."
                },
                "Maroquinier prototypiste :": {
                    "description": "les maroquiniers prototypistes sont chargés de créer des prototypes de sacs à main. Ils travaillent souvent en collaboration avec les designers pour s'assurer que les prototypes répondent aux spécifications et aux normes de qualité requises."
                },
                "Maroquinier industriel :": {
                    "description": "les maroquiniers industriels travaillent dans des usines de fabrication de sacs à main et sont responsables de la production de masse des sacs à main. Ils utilisent souvent des machines de production pour couper, assembler et coudre les différentes parties du sac à main."
                },
                "Maroquinier artisanal :": {
                    "description": "les maroquiniers artisans travaillent souvent dans leur propre atelier ou pour de petites entreprises. Ils créent des sacs à main en utilisant des techniques traditionnelles de maroquinerie, telles que la coupe à la main, la couture à la main et le laçage."
                }
            },
            "Maroquinier chaussures :": {
                "Maroquinier chaussures de luxe :": {
                    "description": "ces maroquiniers travaillent avec des matériaux de haute qualité tels que le cuir exotique, les peaux de crocodile ou d'alligator, pour créer des chaussures de luxe haut de gamme. Ils peuvent également utiliser des techniques de fabrication artisanales pour ajouter des détails tels que des coutures à la main ou des gravures personnalisées."
                },
                "Maroquinier chaussures pour hommes :": {
                    "description": "ces maroquiniers créent des chaussures pour hommes de différents styles, allant des chaussures classiques en cuir noir ou marron aux chaussures plus élaborées avec des détails tels que des perforations ou des surpiqûres décoratives. Ils peuvent également travailler sur la conception de chaussures pour hommes avec des détails supplémentaires tels que des motifs gravés ou des boucles en métal."
                },
                "Maroquinier chaussures pour femmes :": {
                    "description": "ces maroquiniers créent des chaussures pour femmes dans une variété de styles, allant des chaussures à talons hauts et élégantes aux chaussures plates et confortables. Ils peuvent également utiliser des matériaux tels que le cuir verni ou le cuir métallisé pour créer des chaussures plus décoratives et ajouter des détails tels que des boucles en cristal ou en métal."
                },
                "Maroquinier chaussures sur mesure :": {
                    "description": "ces maroquiniers créent des chaussures sur mesure pour répondre aux besoins spécifiques des clients. Ils travaillent avec les clients pour choisir les matériaux, les couleurs et les styles pour créer des chaussures qui s'adaptent parfaitement à leur morphologie et à leur style personnel."
                }
            },
            "Maroquinier ceintures :": {
                "Maroquinier ceintures de luxe :": {
                    "description": "ces maroquiniers travaillent avec des matériaux de haute qualité tels que le cuir exotique, les peaux de crocodile ou d'alligator, pour créer des ceintures de luxe haut de gamme. Ils peuvent également utiliser des techniques de fabrication artisanales pour ajouter des détails tels que des coutures à la main ou des gravures personnalisées."
                },
                "Maroquinier ceintures pour hommes :": {
                    "description": "ces maroquiniers créent des ceintures pour hommes de différents styles, allant des modèles classiques en cuir noir ou brun aux modèles plus élaborés avec des boucles en métal décoratives. Ils peuvent également travailler sur la conception de ceintures pour hommes avec des détails supplémentaires tels que des motifs gravés ou des surpiqûres contrastées."
                },
                "Maroquinier ceintures pour femmes :": {
                    "description": "ces maroquiniers créent des ceintures pour femmes dans une variété de styles, allant des ceintures fines et élégantes pour les robes aux ceintures plus larges pour les pantalons ou les jupes. Ils peuvent également utiliser des matériaux tels que le cuir verni ou le cuir métallisé pour créer des ceintures plus décoratives et ajouter des détails tels que des boucles décoratives en cristal ou en métal."
                },
                "Maroquinier ceintures sur mesure :": {
                    "description": "ces maroquiniers créent des ceintures sur mesure pour répondre aux besoins spécifiques des clients. Ils travaillent avec les clients pour choisir les matériaux, les couleurs et les styles pour créer des ceintures qui s'adaptent parfaitement à leur morphologie et à leur style personnel."
                }
            },
            "Maroquinier articles de voyage :": {
                "Maroquinier valises :": {
                    "description": "les maroquiniers spécialisés dans la création de valises travaillent avec des matériaux résistants et durables pour créer des valises de qualité supérieure. Ils peuvent travailler sur la conception de valises rigides ou souples, de différentes tailles et formes, et utiliser des techniques de fabrication de bagages pour assurer la durabilité et la résistance des produits."
                },
                "Maroquinier sacs de voyage :": {
                    "description": "les maroquiniers spécialisés dans la création de sacs de voyage travaillent souvent sur la conception de sacs de différentes tailles et styles, allant des sacs de voyage week-end aux sacs de voyage plus grands pour les longs séjours. Ils utilisent des techniques de fabrication de sacs pour créer des produits fonctionnels et pratiques, avec des poches et des compartiments pour ranger les affaires de voyage."
                },
                "Maroquinier porte-documents :": {
                    "description": "les maroquiniers spécialisés dans la création de porte-documents en cuir créent des produits fonctionnels et élégants pour les professionnels. Ils peuvent travailler sur la conception de porte-documents pour ordinateurs portables, de porte-documents pour documents de travail, ou de porte-documents pour voyageurs."
                },
                "Maroquinier accessoires de voyage :": {
                    "description": "les maroquiniers spécialisés dans la création d'accessoires de voyage en cuir créent des produits tels que des trousses de toilette, des porte-passeports, des porte-cartes de crédit, des étiquettes de bagage et des sangles de bagage. Ils travaillent souvent avec des techniques de fabrication de petite maroquinerie pour créer des produits pratiques et élégants pour les voyageurs."
                }
            },
            "Maroquinier accessoires :": {
                "Maroquinier ceintures :": {
                    "description": "les maroquiniers spécialisés dans la création de ceintures travaillent avec des matériaux de qualité supérieure pour créer des ceintures élégantes et fonctionnelles. Ils peuvent travailler sur la conception de ceintures pour hommes et femmes, et utiliser des techniques de fabrication de petite maroquinerie pour créer des produits de haute qualité."
                },
                "Maroquinier porte-monnaie :": {
                    "description": "les maroquiniers spécialisés dans la création de porte-monnaie en cuir créent des produits pratiques et élégants pour ranger les espèces, les cartes de crédit et les autres petits objets de valeur. Ils peuvent travailler sur la conception de porte-monnaie pour hommes et femmes, avec des styles allant des modèles plats et minimalistes aux modèles plus volumineux avec des compartiments supplémentaires."
                },
                "Maroquinier étuis à cigarettes :": {
                    "description": "les maroquiniers spécialisés dans la création d'étuis à cigarettes en cuir créent des produits fonctionnels et élégants pour les fumeurs. Ils peuvent travailler sur la conception d'étuis à cigarettes pour hommes et femmes, et utiliser des techniques de fabrication de petite maroquinerie pour créer des produits de qualité supérieure."
                },
                "Maroquinier porte-clés :": {
                    "description": "les maroquiniers spécialisés dans la création de porte-clés en cuir créent des produits pratiques et élégants pour ranger les clés et les autres petits objets. Ils peuvent travailler sur la conception de porte-clés pour hommes et femmes, avec des styles allant des modèles simples et minimalistes aux modèles plus décoratifs avec des détails en métal et des pierres précieuses."
                }
            }
        },
        "Bijoutier-joaillier :": {
            "Joailliers :": {
                "Joailliers créateurs :": {
                    "description": "Ce sont des professionnels de la joaillerie qui conçoivent et créent des bijoux exclusifs et originaux en utilisant des pierres précieuses et des métaux précieux haut de gamme. Ils peuvent travailler avec des clients individuels pour créer des pièces sur mesure ou concevoir des collections pour des maisons de haute joaillerie."
                },
                "Joailliers artisans :": {
                    "description": "Les joailliers artisans sont des professionnels qui travaillent avec des techniques de joaillerie traditionnelles pour créer des bijoux de haute qualité, souvent à la main. Ils peuvent travailler pour des ateliers de joaillerie ou en tant qu'artisans indépendants, et peuvent se spécialiser dans des techniques telles que le sertissage de pierres précieuses, la sculpture de métaux, la gravure ou la ciselure."
                },
                "Joailliers gemmologues :": {
                    "description": "Les joailliers gemmologues sont des professionnels de la joaillerie qui sont formés pour évaluer et expertiser les pierres précieuses et les diamants. Ils utilisent des outils tels que des loupes, des microscopes et des spectromètres pour examiner les pierres et déterminer leur qualité, leur couleur et leur valeur."
                },
                "Joailliers bijoutiers :": {
                    "description": "Les joailliers bijoutiers sont des professionnels qui créent des bijoux en utilisant une variété de matériaux, y compris des pierres précieuses, des métaux précieux et des matériaux moins précieux. Ils peuvent travailler pour des entreprises de bijoux de luxe ou en tant qu'artisans indépendants, et peuvent concevoir des bijoux pour une variété de styles et de budgets."
                }
            },
            "Horlogers :": {
                "description": "Les horlogers sont des bijoutiers-joailliers spécialisés dans la création et la réparation de montres. Ils travaillent avec des mouvements de montre mécaniques ou à quartz et utilisent des matériaux tels que l'or, l'argent, le platine et les diamants pour créer des montres de haute qualité et de luxe."
            },
            "Gravure sur bijoux :": {
                "description": "Les graveurs sur bijoux sont des bijoutiers-joailliers spécialisés dans l'ajout de gravures sur des bijoux tels que des alliances, des bagues de fiançailles et des colliers. Les gravures peuvent être des noms, des dates, des initiales, des messages personnels et d'autres types de personnalisations."
            },
            "Fabricants de bijoux fantaisie :": {
                "description": "Les fabricants de bijoux fantaisie créent des bijoux de mode à des prix abordables pour les clients qui cherchent des bijoux pour des occasions spéciales ou pour les porter tous les jours. Les bijoux fantaisie peuvent être fabriqués à partir de métaux moins chers, tels que l'acier inoxydable ou le laiton, et de pierres de fantaisie, telles que des pierres synthétiques ou des perles en plastique."
            }
        },
        "Tapissier": {
            "Tapissier d'ameublement :": {
                "description": "le tapissier d'ameublement se concentre sur la rénovation et la restauration de meubles tapissés tels que des chaises, des canapés, des fauteuils, des banquettes, etc. Le tapissier d'ameublement peut remplacer la mousse, le rembourrage et le tissu d'ameublement pour donner une nouvelle vie aux meubles anciens ou usés. Le tapissier d'ameublement peut également réaliser des travaux de décoration et de finition tels que la pose de galons, de franges, de boutons, etc."
            },
            "Tapissier décorateur :": {
                "description": "Le tapissier décorateur se concentre sur la réalisation de projets de décoration intérieure impliquant des textiles et des revêtements muraux tels que des rideaux, des coussins, des tapisseries, des tentures murales, etc. Le tapissier décorateur peut travailler avec des tissus, des cuirs, des peaux, des velours, des soies, des linens, des laines, des cotons, etc. Le tapissier décorateur peut également réaliser des travaux de design d'intérieur en proposant des solutions de décoration adaptées aux besoins et aux goûts de ses clients."
            },
            "Tapissier garnisseur": {
                "description": "Le tapissier garnisseur se concentre sur la réalisation de sièges et de matelas pour des projets de décoration intérieure, tels que des banquettes, des coussins de sol, des lits, des canapés, etc. Le tapissier garnisseur peut travailler avec une variété de matériaux tels que la mousse, les ressorts, les fibres synthétiques, les plumes, etc. Le tapissier garnisseur peut également réaliser des travaux de décoration et de finition en utilisant des tissus et des cuirs pour habiller les sièges et les matelas."
            },
            "Tapissier sellier :": {
                "description": "le tapissier sellier se concentre sur la réalisation de travaux de tapisserie pour les voitures, les motos, les bateaux et les avions. Le tapissier sellier peut travailler avec des matériaux spécifiques tels que le cuir, le vinyle, le tissu résistant à l'eau, etc. Le tapissier sellier doit avoir des compétences techniques avancées pour réaliser des travaux de tapisserie de haute qualité adaptés aux besoins et aux contraintes des véhicules."
            }
        },
        "Modiste :": {
            "Modiste haute couture :": {
                "description": "le modiste haute couture se concentre sur la création de chapeaux de haute qualité et de style pour les défilés de mode, les événements de haute couture, les mariages, etc. Le modiste haute couture travaille souvent avec des matériaux luxueux tels que la soie, le satin, le tulle, la dentelle, le feutre, le cuir, la plume, etc. Le modiste haute couture peut également concevoir des accessoires de mode tels que des bandeaux, des barrettes, des sacs à main, etc."
            },
            "Modiste prêt-à-porter :": {
                "description": "le modiste prêt-à-porter se concentre sur la création de chapeaux de mode à porter au quotidien ou pour des événements moins formels. Le modiste prêt-à-porter peut travailler avec des matériaux plus simples tels que le coton, le lin, le tweed, la laine, etc. Le modiste prêt-à-porter peut également adapter ses créations aux tendances actuelles de la mode pour répondre aux besoins de ses clients."
            },
            "Modiste événementiel :": {
                "description": "le modiste événementiel se concentre sur la création de chapeaux et d'accessoires pour des événements spéciaux tels que les mariages, les courses hippiques, les cérémonies religieuses, etc. Le modiste événementiel peut travailler avec une variété de matériaux et de styles en fonction de l'événement et des préférences de ses clients."
            },
            "Modiste chapelier :": {
                "description": "le modiste chapelier se concentre sur la création de chapeaux et de coiffes pour les productions théâtrales, les films, les séances photo, etc. Le modiste chapelier doit être capable de créer des pièces uniques et originales pour répondre aux exigences des productions artistiques. Le modiste chapelier peut travailler avec des matériaux inhabituels tels que le métal, le plastique, le papier, etc."
            }
        },
        "Verrier :": {
            "Verrier d'art :": {
                "description": "le verrier d'art se concentre sur la création d'objets en verre décoratifs et originaux, tels que des sculptures, des bijoux, des luminaires, des vases, des miroirs, etc. Le verrier d'art utilise souvent des techniques artisanales pour travailler le verre, telles que le soufflage, le façonnage à chaud, la gravure, la peinture, l'émaillage, etc. Le but du verrier d'art est de produire des pièces uniques et esthétiquement plaisantes."
            },
            "Verrier utilitaire :": {
                "description": "le verrier utilitaire se concentre sur la création d'objets en verre fonctionnels, tels que des verres, des carafes, des plats, des assiettes, etc. Le verrier utilitaire utilise souvent des techniques de production en série pour créer des objets identiques et répondre aux demandes de clients ou d'entreprises. Le but du verrier utilitaire est de produire des objets pratiques, résistants et esthétiquement plaisants."
            },
            "Verrier scientifique :": {
                "description": "le verrier scientifique se concentre sur la fabrication d'instruments en verre pour la recherche scientifique, tels que des tubes à essai, des pipettes, des réacteurs, des cuves, etc. Le verrier scientifique doit avoir des compétences techniques avancées pour travailler le verre avec précision, résistance et uniformité, afin de garantir la fiabilité des instruments utilisés en laboratoire."
            },
            "Verrier industriel :": {
                "description": "le verrier industriel se concentre sur la production de verre à grande échelle pour des applications industrielles, telles que les fenêtres, les écrans plats, les panneaux solaires, les récipients en verre, etc. Le verrier industriel utilise des techniques de production automatisées pour créer des produits standardisés en grande quantité, en optimisant les coûts de production et les performances des produits. Le but du verrier industriel est de répondre aux besoins de la production industrielle en utilisant des matériaux en verre adaptés."
            }
        }
    },
    "Responsable des ventes :": {
        "Responsable de boutique": {
            "description": "le responsable de boutique gère une boutique de luxe et est responsable de la réalisation des objectifs de vente, de la gestion de l'inventaire, de la formation et de la gestion de l'équipe de vente, ainsi que de la satisfaction de la clientèle. Le responsable de boutique doit avoir une connaissance approfondie des produits de luxe et être en mesure de fournir un service clientèle de qualité supérieure."
        },
        "Responsable régional de vente": {
            "description": "le responsable régional de vente gère plusieurs boutiques de luxe dans une région donnée. Il est responsable de la réalisation des objectifs de vente, de la gestion de l'inventaire, de la formation et de la gestion des équipes de vente dans chaque boutique, ainsi que de la satisfaction de la clientèle dans la région. Le responsable régional de vente doit avoir une connaissance approfondie des produits de luxe, être capable de motiver et de gérer efficacement des équipes de vente, et être en mesure de fournir un service clientèle exceptionnel."
        },
        "Responsable des ventes en ligne": {
            "description": "Le responsable des ventes en ligne est responsable de la vente des produits de luxe sur les plateformes de vente en ligne. Il doit être en mesure de développer des stratégies de vente efficaces pour atteindre les objectifs de vente en ligne, de gérer l'inventaire en ligne, de gérer les relations avec les fournisseurs de plateformes de vente en ligne, et de fournir un service clientèle de qualité supérieure aux acheteurs en ligne."
        },
        "Responsable des ventes internationales": {
            "description": "le responsable des ventes internationales gère les ventes de produits de luxe dans plusieurs pays. Il est responsable de la réalisation des objectifs de vente, de la gestion de l'inventaire, de la formation et de la gestion des équipes de vente dans chaque pays, ainsi que de la satisfaction de la clientèle internationale. Le responsable des ventes internationales doit avoir une connaissance approfondie des différentes cultures et marchés internationaux, être capable de gérer des équipes de vente multilingues, et être en mesure de fournir un service clientèle exceptionnel aux clients internationaux."
        }
    },
    "Expert en maroquinerie :": {
        "Designer maroquinerie :": {
            "description": "le designer maroquinerie est responsable de la création de nouveaux produits en cuir de luxe. Il doit être créatif et innovant tout en respectant les codes esthétiques de la marque. Il travaille en étroite collaboration avec les équipes de développement produit, les maroquiniers et les stylistes."
        },
        "Maroquinier :": {
            "description": "le maroquinier est un artisan spécialisé dans la fabrication de produits en cuir. Il doit avoir une grande expertise technique dans la découpe, l'assemblage, la couture et la finition des produits. Il travaille généralement en atelier et peut être amené à créer des produits sur mesure."
        },
        "Responsable de production :": {
            "description": "le responsable de production est en charge de la gestion de la production de la maroquinerie de luxe. Il doit coordonner les équipes de production, planifier les opérations, contrôler la qualité et respecter les délais de livraison."
        },
        "Responsable marketing :": {
            "description": "le responsable marketing est responsable de la stratégie de communication et de promotion des produits de maroquinerie de luxe. Il travaille en étroite collaboration avec les designers, les maroquiniers et les responsables de production pour développer des campagnes publicitaires créatives et innovantes qui mettent en valeur les caractéristiques et la qualité des produits."
        },
        "Responsable des ventes :": {
            "description": "le responsable des ventes est en charge de la commercialisation des produits de maroquinerie de luxe. Il doit être en mesure de développer des relations avec les clients, d'identifier les opportunités de vente et de maximiser les revenus. Il travaille en étroite collaboration avec le responsable marketing pour atteindre les objectifs de vente."
        },
        "Expert en cuir :": {
            "description": "l'expert en cuir est un professionnel qui a une connaissance approfondie des différentes qualités de cuir utilisées dans la maroquinerie de luxe. Il est capable de distinguer les différents types de cuir, d'évaluer leur qualité et de conseiller les clients sur l'entretien et la préservation des produits."
        }
    },
    "Parfumeur :": {
        "Parfumeur-créateur :": {
            "description": "le parfumeur-créateur est un artiste qui imagine et crée des fragrances pour une marque de parfum. Il travaille en étroite collaboration avec le directeur artistique et le responsable marketing pour développer des parfums qui correspondent à l'identité et à l'image de la marque. Il doit avoir une grande expertise dans la composition de fragrances et utiliser des matières premières de qualité pour créer des parfums uniques."
        },
        "Nez :": {
            "description": "le nez est un parfumeur expert qui travaille en laboratoire pour créer des fragrances. Il est capable de distinguer les différents composants d'une fragrance et de les assembler pour créer une fragrance harmonieuse et équilibrée. Il doit avoir une connaissance approfondie des matières premières utilisées dans la parfumerie et être capable d'identifier les odeurs de manière précise."
        },
        "Responsable de la création :": {
            "description": "le responsable de la création est en charge de la stratégie de développement de nouveaux parfums pour une marque de parfum. Il travaille en étroite collaboration avec le parfumeur-créateur et le responsable marketing pour développer des parfums qui correspondent aux attentes des clients et aux tendances du marché."
        },
        "Responsable marketing :": {
            "description": "le responsable marketing est en charge de la stratégie de communication et de promotion des parfums de luxe. Il travaille en étroite collaboration avec le parfumeur-créateur et le responsable de la création pour développer des campagnes publicitaires créatives et innovantes qui mettent en valeur les caractéristiques et la qualité des parfums."
        },
        "Responsable des ventes :": {
            "description": "le responsable des ventes est en charge de la commercialisation des parfums de luxe. Il doit être en mesure de développer des relations avec les clients, d'identifier les opportunités de vente et de maximiser les revenus. Il travaille en étroite collaboration avec le responsable marketing pour atteindre les objectifs de vente."
        },
        "Responsable de la qualité :": {
            "description": "le responsable de la qualité est en charge de la conformité des parfums de luxe aux normes de qualité. Il travaille en étroite collaboration avec le parfumeur-créateur pour s'assurer que les parfums répondent aux normes de qualité et de sécurité et sont conformes aux réglementations en vigueur."
        }
    },
    "Responsable du développement de produit :": {
        "Chef de produit :": {
            "description": "le chef de produit est en charge de la conception et de la gestion d'une ligne de produits spécifique pour une marque de luxe. Il travaille en étroite collaboration avec les équipes de conception, de développement, de production et de marketing pour s'assurer que les produits répondent aux normes de qualité et aux exigences de la marque."
        },
        "Ingénieur produit :": {
            "description": "l'ingénieur produit est chargé de la conception technique des produits de luxe. Il travaille en étroite collaboration avec les équipes de conception et de développement pour s'assurer que les produits sont conçus de manière à optimiser leur fonctionnalité et leur durabilité."
        },
        "Responsable de la stratégie produit :": {
            "description": "le responsable de la stratégie produit est en charge de la définition de la stratégie de développement de produits pour une marque de luxe. Il travaille en étroite collaboration avec les équipes de marketing, de conception et de développement pour identifier les opportunités de croissance du marché et définir les objectifs de développement de produit."
        },
        "Responsable du développement de produit durable :": {
            "description": "le responsable du développement de produit durable est chargé de la conception et du développement de produits écologiques et durables pour une marque de luxe. Il travaille en étroite collaboration avec les équipes de conception et de développement pour s'assurer que les produits sont conçus de manière à minimiser leur impact environnemental et à répondre aux exigences des consommateurs en matière de développement durable."
        },
        "Responsable du développement de produit numérique :": {
            "description": "le responsable du développement de produit numérique est chargé de la conception et du développement de produits numériques pour une marque de luxe. Il travaille en étroite collaboration avec les équipes de conception, de développement et de marketing pour créer des produits numériques innovants qui répondent aux attentes des consommateurs et aux tendances du marché."
        }
    },
    "Analyste de marché :": {
        "Analyste de marché des produits de luxe :": {
            "description": "l'analyste de marché des produits de luxe analyse les tendances et les mouvements du marché des produits de luxe pour aider les entreprises à développer des produits et des stratégies de marketing efficaces. Il collecte des données sur les tendances de la mode, les innovations, les préférences des consommateurs et les tendances du marché, et fournit des rapports et des recommandations aux entreprises de luxe."
        },
        "Analyste de marché de la clientèle de luxe :": {
            "description": "l'analyste de marché de la clientèle de luxe analyse les tendances et les mouvements de la clientèle de luxe pour aider les entreprises à développer des stratégies de marketing efficaces. Il collecte des données sur les habitudes d'achat des consommateurs de luxe, les préférences et les tendances du marché, et fournit des rapports et des recommandations aux entreprises de luxe."
        },
        "Analyste de marché de la concurrence dans le luxe :": {
            "description": "l'analyste de marché de la concurrence dans le luxe analyse les tendances et les mouvements de la concurrence dans le secteur du luxe pour aider les entreprises à développer des stratégies de marketing et de positionnement efficaces. Il collecte des données sur les produits, les prix, les canaux de distribution et les communications marketing des concurrents, et fournit des rapports et des recommandations aux entreprises de luxe."
        },
        "Analyste de marché de la distribution de luxe :": {
            "description": "l'analyste de marché de la distribution de luxe analyse les tendances et les mouvements du marché de la distribution de produits de luxe pour aider les entreprises à développer des stratégies de distribution efficaces. Il collecte des données sur les canaux de distribution, les partenaires de distribution et les tendances du marché de la distribution de produits de luxe, et fournit des rapports et des recommandations aux entreprises de luxe."
        },
        "Analyste de marché des événements de luxe :": {
            "description": "l'analyste de marché des événements de luxe analyse les tendances et les mouvements des événements de luxe pour aider les entreprises à développer des stratégies de marketing et de promotion efficaces. Il collecte des données sur les événements de mode, les salons professionnels, les foires et les événements de luxe, et fournit des rapports et des recommandations aux entreprises de luxe."
        }
    },
    "Gestionnaire de la chaîne d'approvisionnement :": {
        "Responsable logistique :": {
            "description": "le responsable logistique est chargé de la planification, de l'organisation et de la mise en œuvre de la logistique de la chaîne d'approvisionnement. Il travaille en étroite collaboration avec les fournisseurs, les transporteurs et les distributeurs pour garantir une livraison efficace et ponctuelle des produits."
        },
        "Responsable des achats :": {
            "description": "le responsable des achats est chargé de l'achat des matières premières et des produits finis nécessaires à la production des produits de luxe. Il travaille en étroite collaboration avec les fournisseurs pour s'assurer que les matières premières et les produits répondent aux normes de qualité et aux exigences de la marque."
        },
        "Responsable de la planification de la production :": {
            "description": "le responsable de la planification de la production est chargé de la planification de la production de la marque de luxe. Il travaille en étroite collaboration avec les équipes de conception et de développement pour s'assurer que la production est planifiée de manière efficace pour répondre aux demandes du marché et aux exigences de la marque."
        },
        "Responsable de la qualité :": {
            "description": "le responsable de la qualité est chargé de s'assurer que les produits de luxe répondent aux normes de qualité et de sécurité les plus élevées. Il travaille en étroite collaboration avec les équipes de production pour mettre en place des processus de contrôle qualité rigoureux tout au long de la chaîne d'approvisionnement."
        },
        "Responsable de la gestion de stock :": {
            "description": "le responsable de la gestion de stock est chargé de la gestion des stocks de produits finis et de matières premières pour la marque de luxe. Il travaille en étroite collaboration avec les équipes de production et de planification pour s'assurer que les stocks sont maintenus à un niveau optimal pour répondre aux demandes du marché et aux exigences de la marque."
        }
    },
    "Responsable des ressources humaines :": {
        "Responsable du recrutement :": {
            "description": "le responsable du recrutement est chargé de recruter les talents nécessaires pour la marque de luxe. Il travaille en étroite collaboration avec les managers pour identifier les besoins en personnel et sélectionner les candidats les plus qualifiés pour les postes vacants."
        },
        "Responsable de la formation et du développement :": {
            "description": "le responsable de la formation et du développement est chargé de la formation et du développement des employés de la marque de luxe. Il travaille en étroite collaboration avec les managers pour identifier les besoins en formation et développer des programmes de formation pour améliorer les compétences des employés."
        },
        "Responsable de la gestion de la performance :": {
            "description": "le responsable de la gestion de la performance est chargé de la gestion de la performance des employés de la marque de luxe. Il travaille en étroite collaboration avec les managers pour s'assurer que les employés sont évalués de manière juste et équitable, et que les performances sont mesurées de manière efficace."
        },
        "Responsable de la gestion des avantages sociaux :": {
            "description": "le responsable de la gestion des avantages sociaux est chargé de la gestion des avantages sociaux pour les employés de la marque de luxe. Il travaille en étroite collaboration avec les managers pour identifier les avantages sociaux les plus adaptés aux besoins des employés et de l'entreprise."
        },
        "Responsable de la gestion des relations de travail :": {
            "description": "le responsable de la gestion des relations de travail est chargé de la gestion des relations entre la direction et les employés de la marque de luxe. Il travaille en étroite collaboration avec les managers pour s'assurer que les employés sont satisfaits de leur travail et que les relations de travail sont harmonieuses."
        }
    }
}

export default dataAnnuaire
