let endpoint = process.env.REACT_APP_GLOBAL_API + '/api/rethink'


let rethinkService = {

    loadHeaders() {
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append("Accept", '*/*');
        return headers;
    },


    getTableData(data) {
        return fetch(endpoint + '/getTableData', {
            method: 'POST',
            headers: this.loadHeaders(),
            body: JSON.stringify(data)
        }).then(response => response.json()).catch(error => {
            console.log(error);
        });
    },

    filterTableData(data) {
        console.log(endpoint)
        return fetch(endpoint + '/filterTableData', {
            method: 'POST',
            headers: this.loadHeaders(),
            body: JSON.stringify(data)
        }).then(response => response.json()).catch(error => {
            console.log(error);
        });
    },

    getTableRow(data) {
        return fetch(endpoint + '/getTableRow', {
            method: 'POST',
            headers: this.loadHeaders(),
            body: JSON.stringify(data)
        }).then(response => response.json()).catch(error => {
            console.log(error);
        });
    },

    insert(data){
        return fetch(endpoint + '/insertTableRow', {
            method: 'POST',
            headers: this.loadHeaders(),
            body: JSON.stringify(data)
        }).then(response => response.json()).catch(error => {
            console.log(error);
        });
    },

    update(data){
        return fetch(endpoint + '/updateTableRow', {
            method: 'POST',
            headers: this.loadHeaders(),
            body: JSON.stringify(data)
        }).then(response => response.json()).catch(error => {
            console.log(error);
        });
    }
}


export default rethinkService;