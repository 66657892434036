import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import { Chip } from '@material-ui/core'
import categoriesMetier from "../../data/categoriesMetier";
import Autocomplete from '@mui/material/Autocomplete';
import { apiNodeService } from "../../provider/apiNodeService";
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Typography from "@mui/material/Typography";
import categoriesLogiciel from "../../data/categoriesLogiciel";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import moment from "moment";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import utilFunctions from "../../tools/functions";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

const Projet_Profil = (props) => {

    const [freelancer, setFreelancer] = React.useState(null)
    const [title, setTitle] = React.useState('')
    const [description, setDescription] = React.useState('')
    const [selectedValuesMetiers, setSelectedMetiers] = React.useState([]);
    const [selectedValuesLogiciel, setSelectedLogiciel] = React.useState([]);
    const [prix, setPrix] = React.useState('')
    const [lieuProjet, setLieuProjet] = React.useState('')
    const [lienProjet, setLienProjet] = React.useState('')
    const [dateDeb, setDateDeb] = React.useState(dayjs(''))
    const [dateFin, setDateFin] = React.useState(dayjs(''))
    const [checkedProjet, setCheckedProjet] = React.useState(false)
    const [isValidDate, setIsValidDate] = React.useState(false)
    const [options, setOptions] = useState(categoriesLogiciel.categoriesLogiciel.map((option) => option.label));
    const [options1, setOptions1] = useState(categoriesMetier.categoriesMetier.map((option) => option.label));

    const handleInputChange = (event) => {
        const newValue = event.target.value;
        if (newValue.trim() !== '' && !options.includes(newValue)) {
            // Si la nouvelle valeur n'existe pas dans la liste, l'ajouter aux options
            setOptions([...options, newValue]);
        }
    };

    const handleInputChange1 = (event) => {
        const newValue = event.target.value;
        if (newValue.trim() !== '' && !options1.includes(newValue)) {
            // Si la nouvelle valeur n'existe pas dans la liste, l'ajouter aux options
            setOptions1([...options1, newValue]);
        }
    };

    useEffect(() => {
        getUserByEmail()
    }, [props.projetUid, props.handleUpdateUid]);

    const handleChange2 = (event) => {
        setCheckedProjet(event.target.checked);
    };

    const resetValues = () => {
        setCheckedProjet(false)
        setDateDeb('')
        setDateFin('')
        setDescription('')
        setLieuProjet('')
        setLienProjet('')
        setSelectedLogiciel([])
        setSelectedMetiers([])
        setPrix('')
        setTitle('')
        setIsValidDate(false)
    }

    const getUserByEmail = async () => {
        resetValues()
        return new Promise(resolve => {
            apiNodeService.filterTable({ table: "employe_odoo", filter: { email: localStorage.getItem('email') }, db: "FREELANCE_MAD" }).then(userRes => {
                if (userRes && userRes !== "false" && Array.isArray(userRes.data)) {
                    setFreelancer(userRes.data[0])
                    if (props.projetUid !== '') {
                        let data = userRes.data[0].projets.filter(item => item.uid === props.projetUid)
                        if (data[0]) {
                            setCheckedProjet(data[0].checked_projet || false)
                            setDateDeb(data[0].date_debut || '')
                            setDateFin(data[0].date_fin || '')
                            setDescription(data[0].description || '')
                            setLieuProjet(data[0].lieu_projet || '')
                            setLienProjet(data[0].lien_projet || '')
                            setSelectedLogiciel(data[0].logiciels || [])
                            setSelectedMetiers(data[0].metiers || [])
                            setPrix(data[0].prix || '')
                            setTitle(data[0].title || '')
                        }
                    } else {
                        resetValues()
                    }
                } else {
                    resolve("false")
                }
            }).catch(err => resolve("false"))
        })
    }

    const addToCompetences = () => {
        let oldProjets = freelancer.projets;
        let projet = {
            title: title,
            description: description,
            prix: prix,
            lieu_projet: lieuProjet,
            lien_projet: lienProjet,
            date_debut: dateDeb,
            date_fin: dateFin,
            checked_projet: checkedProjet,
            logiciels: selectedValuesLogiciel,
            metiers: selectedValuesMetiers,
            uid: utilFunctions.getUID()
        }
        let testDate = dateFin ?  moment(dateFin).diff(moment(dateDeb)) : 1;
        let newProjets = []
        if (testDate < 1) {
            setIsValidDate(true)
        } else {
            setIsValidDate(false)
        if (oldProjets) {
            newProjets = oldProjets
            newProjets.push(projet)
            updateProfile1(newProjets)
        } else {
            newProjets.push(projet)
            updateProfile1(newProjets)
        }
    }
    };

    const updateToCompetences = () => {
        let oldProjets = freelancer.projets;
        let projet = {
            title: title,
            description: description,
            prix: prix,
            lieu_projet: lieuProjet,
            lien_projet: lienProjet,
            date_debut: dateDeb,
            date_fin: dateFin,
            checked_projet: checkedProjet,
            logiciels: selectedValuesLogiciel,
            metiers: selectedValuesMetiers,
            uid: props.projetUid
        }
        const updatedData = oldProjets.map(item => {
            if (item.uid === props.projetUid) {
                return projet;
            }
            return item;
        });
        let testDate = dateFin ?  moment(dateFin).diff(moment(dateDeb)) : 1;
        if (testDate < 1) {
            setIsValidDate(true)
        } else {
            setIsValidDate(false)
        updateProfile1(updatedData)
        }
    }

    const updateProfile1 = (value) => {
        props.setLoading(true)
        let data = {}
        data.projets = value
        props.onCloseDialogProjet()
        apiNodeService.updateTableData({ db: "FREELANCE_MAD", table: "employe_odoo", id: freelancer.id, data: data }).then(resAdd => {
            if (resAdd && resAdd.status === 200) {
                props.setLoading(false)
                props.handleUpdateUser()
            } else {
                console.log("error")
                props.setLoading(false)
                props.onCloseDialogProjet()
            }
        })
    }

    return (
        <>
            <Dialog
                open={props.openProjetDialog}
                aria-labelledby="form-dialog-title"
                fullWidth={true}
                maxWidth="md"
                onClose={(e)=>{
                    props.onCloseDialogProjet()
                }}
            >
                <DialogTitle id="form-dialog-title" style={{ paddingBottom: 1 }}>
                    <div className='d-flex align-items-center' style={{ gap: 10 }}>
                        {props.isEditProjet === true ? <EditOutlinedIcon style={{ color: "#1565C0" }} /> : <AddIcon style={{ color: "#1565C0" }} />}
                        <span style={{ color: '#1565C0', fontWeight: 700 }}>{props.isEditProjet === true ? "Modification du projet" : "Informations sur le projet"} </span>
                    </div>
                    <IconButton
                        aria-label="close"
                        style={{
                            position: 'absolute',
                            right: 5,
                            top: 5,
                            color: '#c0c0c0'
                        }}
                        onClick={() => {
                            props.onCloseDialogProjet()
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <hr style={{ color: "#1565C0" }} />
                </DialogTitle>
                <DialogContent style={{ overflowY: "inherit" }}>
                    <>
                        <span style={{ fontSize: 14, fontWeight: 700, color: 'grey', marginLeft: 24 }}>* = Champs obligatoires</span>
                        <div className='row py-1 px-4'>
                            <div className='col-md-12'>
                                <span className="span-title">Nom du projet*</span>
                                <TextField fullWidth className='mt-1' size="small" id="outlined-basic3" variant="outlined"
                                    value={title}
                                    onChange={(e) => {
                                        setTitle(e.target.value)
                                    }} />
                                <div className="d-flex justify-content-end">
                                    <span style={{ fontSize: 12, color: title.length > 100 ? 'red' : "#b1b1b1" }} className="span-title mt-1">{title.length} / 100 caractères</span>
                                </div>
                            </div>
                        </div>
                        <div className='row py-1 px-4'>
                            <div className='col-md-12'>
                                <span className="span-title">Courte description du projet*</span>
                                <TextField fullWidth className='mt-1' size="small" id="outlined-basic3" variant="outlined"
                                    value={description}
                                    multiline
                                    rows={3}
                                    onChange={(e) => {
                                        setDescription(e.target.value)
                                    }} />
                                <div className="d-flex justify-content-end">
                                    <span style={{ fontSize: 12, color: description.length > 250 ? 'red' : "#b1b1b1" }} className="span-title mt-1">{description.length} / 250 caractères</span>
                                </div>
                            </div>
                            <span className="span-title mt-1" style={{ fontSize: 12, color: "#4b4b4b", fontWeight: 500 }}>Pour mettre toutes les chances de votre coté, détaillez un maximum tous ce que vous avez effectué dans ce projet.</span>
                        </div>
                        <div className='row py-1 px-4'>
                            <div className='col-md-6'>
                                <span className="span-title">Lieu du projet</span>
                                <TextField fullWidth className='mt-1' size="small" id="outlined-basic3" variant="outlined"
                                    value={lieuProjet}
                                    onChange={(e) => {
                                        setLieuProjet(e.target.value)
                                    }} />
                                <div className="d-flex justify-content-start mt-1">
                                    {/* <span className="span-title mb-2" style={{ fontSize: 12, color: "#b1b1b1", fontWeight: 500 }}>Si le projet est en télétravail, mettre le nom et le lieu de l’entreprise.</span> */}
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <span className="span-title">URL du projet</span>
                                <TextField fullWidth className='mt-1' size="small" id="outlined-basic3" variant="outlined"
                                    value={lienProjet}
                                    onChange={(e) => {
                                        setLienProjet(e.target.value)
                                    }} />
                                <div className="d-flex justify-content-start mt-1">
                                </div>
                            </div>
                        </div>
                        <div className="px-4 d-flex align-items-center">
                            <Checkbox
                                checked={checkedProjet}
                                onChange={handleChange2}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                            <span style={{ fontSize: 14, fontWeight: 600, color: '#616161' }}>Je travail encore sur ce projet</span>
                        </div>
                        <div className="row px-4">
                            <div className='col-md-6 date-section1'>
                                <span className="span-title">Date de commencement*</span>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['DatePicker']}>
                                        <DatePicker format="DD/MM/YYYY" value={dayjs(dateDeb)} maxDate={dayjs(moment())} onChange={(e) => {
                                            if (e) {
                                                setDateDeb(moment(e.$d).format('YYYY-MM-DD'))
                                            }
                                        }} />
                                    </DemoContainer>
                                </LocalizationProvider>
                            </div>
                            {checkedProjet === false &&
                                <div className='col-md-6 date-section1'>
                                    <span className="span-title">Date de fin</span>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer components={['DatePicker']}>
                                            <DatePicker format="DD/MM/YYYY" minDate={dayjs(dateDeb)} maxDate={dayjs(moment())} value={dayjs(dateFin)} onChange={(e) => {
                                                if (e) {
                                                    setDateFin(moment(e.$d).format('YYYY-MM-DD'))
                                                }
                                            }} />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                    {isValidDate === true && <span style={{ color: "red", fontWeight: 500,fontSize:13 }}>Date de fin doit être superieur à la date de commencement </span>}
                                </div>
                            }
                        </div>
                        <div className='d-flex flex-column p-4'>
                            <span style={{ color: "#616161", fontWeight: 600 }}>Compétences informatiques requises</span>
                            <Autocomplete
                                multiple
                                className='mb-4 mt-2'
                                id="tags-outlined"
                                variant="outlined"
                                value={selectedValuesLogiciel}
                                options={options}
                                defaultValue={selectedValuesLogiciel}
                                onChange={(event, newValue) => {
                                    setSelectedLogiciel(newValue)
                                }}
                                renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                        <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                                    ))
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        size="small"
                                        label="Ajouter des compétences informatiques"
                                        placeholder="Compétences informatiques"
                                        onChange={handleInputChange}
                                    />
                                )}
                            />
                            <span style={{ color: "#616161", fontWeight: 600 }}>Compétences métier requises</span>
                            <Autocomplete
                                className='mt-2'
                                multiple
                                id="tags-outlined"
                                value={selectedValuesMetiers}
                                options={options1}
                                defaultValue={selectedValuesMetiers}
                                onChange={(event, newValue) => {
                                    setSelectedMetiers(newValue)
                                }}
                                renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                        <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                                    ))
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        size="small"
                                        label="Ajouter des compétences métier"
                                        placeholder="Compétences métier"
                                        onChange={handleInputChange1}
                                    />
                                )}
                            />


                        </div>

                        <div className='row py-1 px-4'>
                            <div className='col-md-12'>
                                <span className="span-title">Récompense(s) / prix obtenu(s)</span>
                                <TextField fullWidth className='mt-1' size="small" id="outlined-basic3" variant="outlined"
                                    value={prix}
                                    placeholder="Ex. : Prix du jury"
                                    onChange={(e) => {
                                        setPrix(e.target.value)
                                    }} />
                                <div className="d-flex justify-content-start mt-1">
                                    {/* <span className="span-title mb-2" style={{ fontSize: 12, color: "#b1b1b1", fontWeight: 500 }}>Si le projet est en télétravail, mettre le nom et le lieu de l’entreprise.</span> */}
                                </div>
                            </div>
                        </div>

                    </>
                </DialogContent>
                <DialogActions style={{ padding: "10px 55px 20px 35px", justifyContent: 'end' }}>
                    <Button
                        variant="outlined"
                        onClick={(e) => {
                            props.onCloseDialogProjet()
                        }}
                        style={{ textTransform: 'none', fontWeight: 600 }}
                    >
                        Annuler
                    </Button>
                    <Button
                        variant="contained"
                        disabled={dateDeb === '' || title === '' || description === '' || (selectedValuesLogiciel.length === 0 && selectedValuesMetiers.length === 0) ? true : false}
                        onClick={(e) => {
                            props.isEditProjet === false ? addToCompetences() : updateToCompetences()
                        }}
                        style={{ textTransform: 'none', color: "#fff", backgroundColor: (dateDeb === '' || title === '' || description === '' || (selectedValuesLogiciel.length === 0 && selectedValuesMetiers.length === 0)) ? "grey" : "#1565C0", minWidth: 180, fontWeight: 600 }}
                    >
                        {props.isEditProjet === false ? "Ajouter projet" : "Valider et mettre à jour"}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
export default Projet_Profil;