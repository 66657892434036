import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Radio from '@mui/material/Radio';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Button from '@mui/material/Button';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import { Chip } from '@material-ui/core'
import Competence from "../../Freelance/Competence";
import { makeStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import moment from "moment";
import { apiNodeService } from "../../../provider/apiNodeService";
import MuiBackdrop from "../../../components/Loading/MuiBackdrop";
import { maillingService } from "../../../provider/maillingService";

const useStyles = makeStyles((theme) => ({
    menuItem: {
        '&:hover, &:active': {
            backgroundColor: 'transparent', // Set the background color to transparent on hover
        },
    },
}));

const Client_Form = () => {

    const navigate = useNavigate();
    const classes = useStyles();

    const [loading, setLoading] = React.useState(false);
    const [step, setStep] = React.useState(1)
    const [role, setRole] = React.useState('');
    const [nbPersonne, setNbPersonne] = React.useState('');
    const [typeProjet, setTypeProjet] = React.useState('');
    const [tempsBesoin, setTempsBesoin] = React.useState('');
    const [engagement, setEngagement] = React.useState('');
    const [selectedValues, setSelectedValues] = React.useState([]);
    const [selectedNodes, setSelectedNodes] = React.useState({});
    const [dateDebut, setDateDebut] = React.useState('');
    const [tarif, setTarif] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [mobile, setMobile] = React.useState('');
    const [socName, setSocName] = React.useState('');
    const [name, setName] = React.useState('');
    const [description, setDescription] = React.useState('');

    const topRef = useRef(null);

    const scrollToTop = () => {
        if (topRef.current) {
            topRef.current.scrollIntoView({
                behavior: 'smooth'
            });
        }
    };

    useEffect(() => {
        // const searchParams = new URLSearchParams(window.location.search);
        // const id = searchParams.get('user');
    }, []);

    const handleChange = (event) => {
        console.log(event.target.value)
        const updatedObject = event.target.value.reduce((acc, currentValue) => {
            acc[currentValue] = true;
            return acc;
        }, {});
        console.log(updatedObject)
        setSelectedNodes(updatedObject)
        const arr1 = selectedValues.filter(element => element !== undefined);
        setSelectedValues(arr1)
        console.log(selectedValues)
    };

    const handleChangeRole = (event) => {
        setRole(event.target.value);
    };

    const handleChangeNbPersonne = (event) => {
        setNbPersonne(event.target.value);
    };

    const handleChangeTypeProjet = (event) => {
        setTypeProjet(event.target.value);
    };

    const handleChangeTempsBesoin = (event) => {
        setTempsBesoin(event.target.value);
    };

    const handleChangeEngagement = (event) => {
        setEngagement(event.target.value);
    };

    const handleChangedateDebut = (event) => {
        setDateDebut(event.target.value);
    };

    const handleChangeTarif = (event) => {
        setTarif(event.target.value);
    };

    const nextStep = () => {
        setStep(step + 1)
        scrollToTop()
    }

    const backStep = () => {
        setStep(step - 1)
        scrollToTop()
    }

    const addOffre = () => {
        setLoading(true)
        let data = {
            role: role,
            soc_name: socName,
            email: email,
            name: name,
            mobile: mobile,
            nb_personne: nbPersonne,
            created_at: moment(),
            type_projet: typeProjet,
            temps_besoin: tempsBesoin,
            engagement: engagement,
            competence: selectedValues,
            date_debut: dateDebut,
            tarif: tarif,
            choosen: false,
            freelancer_choosen: "",
            freelancers: [],
            description:description,
            client_payed:0
        }
        console.log(data)
        apiNodeService.insertTableData({ db: "FREELANCE_MAD", table: "offre", data: data }).then(res => {
            console.log(res)
            if (res && res.status === 200) {
                setLoading(false)
                // sendEmail("k.kaabi1994@gmail.com", res.data.id, "khaled")
                getFreelancers(res.data.id)
                sendEmail1()
                navigate("/home-freelance")
            } else {
                setLoading(false)
                console.log("erreur")
            }
        })
    }

    const getFreelancers = (id) => {
        apiNodeService.getTableData({ db: "FREELANCE_MAD", table: "employe_odoo" }).then((res) => {
            console.log(res)
            if (res && res.status === 200) {
                res.data.map((item,key)=>{
                    sendEmail(item.email,id,item.first_name)
                })
                console.log(res)
            }
            setLoading(false)
        })
    }

    const sendEmail = (mail, id, name) => {
        console.log(id)
        const mailData = {
            emailFrom: "dev.rocketbonds@gmail.com",
            nameFrom: "FreelanceMada",
            to: [
                {
                    Email: mail,
                    Name: ""
                }
            ],
            subject: "Nouvelle Mission Disponible : "+role,
            htmlText: 'Bonjour ' +name+ ', <br/> <br/> ' +
                "Une nouvelle mission qui pourrait correspondre à vos compétences est disponible : "+role+". Le client propose un budget de "+tarif+ "."+
                '<br/>' +
                "Si vous êtes intéressé par cette mission et acceptez le prix proposé, veuillez cliquer sur le lien ci-dessous pour confirmer votre acceptation."+
                '<br/>' +
                "<a href=" + "https://www.freelancemada.ovh/offre-view?id=" + id + "&email=" + mail + ">lien d'acceptation.</b></a>" +
                '<br/><br/>' +
                '<br/><br/>FreelanceMada'
        };

        maillingService.sendMailByHtmlText(mailData).then(res => {
            console.log(res)
            resetValues()
        }).catch(err => {
            console.log(err)
        })
    }

    const sendEmail1 = () => {
        const mailData = {
            emailFrom: "dev.rocketbonds@gmail.com",
            nameFrom: "FreelanceMada",
            to: [
                {
                    Email: email,
                    Name: ""
                }
            ],
            subject: "Offre freelance Mada",
            htmlText: 'Bonjour ' + name + ', <br/> <br/> ' +
                "Nous avons bien reçu votre demande pour " + role + ". Merci de nous avoir choisis pour trouver le professionnel parfait pour votre projet. Nous sommes actuellement en train de la présenter à nos freelancers qualifiés." +
                '<br/>' +
                "Vous recevrez une notification dès qu'un freelanceur acceptera votre mission."+
                '<br/><br/>' +
                "Cordialement,"+
                '<br/><br/>FreelanceMada'
        };

        maillingService.sendMailByHtmlText(mailData).then(res => {
            console.log(res)
            resetValues()
        }).catch(err => {
            console.log(err)
        })
    }

    const resetValues = () => {
        setStep(1)
        setRole("")
        setNbPersonne("")
        setSelectedValues([])
        setTypeProjet("")
        setTempsBesoin("")
        setEngagement("")
        setDateDebut("")
        setTarif("")
        setEmail("")
        setMobile("")
        setSocName("")
        setName("")
    }

    return (
        <div className="client-home">
            <MuiBackdrop open={loading} text={"Chargement..."} />
            <div className="header-client">
                <div className="d-flex justify-content-between align-items-center" style={{ height: "100%" }}>
                    <div className="d-flex px-2">
                        <span style={{ fontSize: 34, fontWeight: 700 }}>Build.</span><span style={{ fontSize: 34, color: "#214dcb", fontWeight: 700 }}>Mada</span>
                    </div>
                    <div className="d-flex align-items-center px-2" style={{ gap: 10 }}>
                        <span className="span-link" onClick={(e) => { navigate('/build-mada') }}>Postuler à un offre</span>
                        <button className="create-client-button" onClick={(e) => { navigate('/home-freelance-form') }}>
                            Contacter un Talent
                        </button>
                    </div>
                </div>
            </div>
            <div ref={topRef}></div>
            <div className="d-flex justify-content-center align-items-center" style={{ marginTop: 90 }}>
                <div className="p-4 mb-4" style={{ backgroundColor: "#fff", borderRadius: 8, maxWidth: 500 }}>
                    {step === 1 &&
                        <div className="d-flex flex-column justify-content-center">
                            <span className="span-title-freemada1" style={{ textAlign: "center" }}>
                                Quel rôle souhaitez-vous pourvoir ?
                            </span>
                            <div className="d-flex align-items-center mt-3">
                                <Radio
                                    checked={role === 'Développeurs'}
                                    onChange={handleChangeRole}
                                    value="Développeurs"
                                    name="radio-buttons"
                                    inputProps={{ 'aria-label': 'Développeurs' }}
                                />
                                <div className="d-flex flex-column">
                                    <span style={{ fontWeight: "700" }}>Développeurs</span>
                                    <span style={{ fontSize: 13 }}>Développeurs logiciels, Data Scientists, DevOps et QA</span>
                                </div>
                            </div>
                            <div className="d-flex align-items-center mt-3">
                                <Radio
                                    checked={role === 'Designers'}
                                    onChange={handleChangeRole}
                                    value="Designers"
                                    name="radio-buttons"
                                    inputProps={{ 'aria-label': 'Designers' }}
                                />
                                <div className="d-flex flex-column">
                                    <span style={{ fontWeight: "700" }}>Designers</span>
                                    <span style={{ fontSize: 13 }}>Designers Designers Web, Mobile, UI/UX, Branding et Design Visuel</span>
                                </div>
                            </div>
                            <div className="d-flex align-items-center mt-3">
                                <Radio
                                    checked={role === 'Architecte, génie Civil'}
                                    onChange={handleChangeRole}
                                    value="Architecte, génie Civil"
                                    name="radio-buttons"
                                    inputProps={{ 'aria-label': 'Architecte, génie Civil' }}
                                />
                                <div className="d-flex flex-column">
                                    <span style={{ fontWeight: "700" }}>Architecte, génie Civil</span>
                                    <span style={{ fontSize: 13 }}>Archueft, BIM manager, modeleur, Ingé Génie Civil, ...</span>
                                </div>
                            </div>
                            <div className="d-flex align-items-center mt-3">
                                <Radio
                                    checked={role === 'Experts en Marketing'}
                                    onChange={handleChangeRole}
                                    value="Experts en Marketing"
                                    name="radio-buttons"
                                    inputProps={{ 'aria-label': 'Experts en Marketing' }}
                                />
                                <div className="d-flex flex-column">
                                    <span style={{ fontWeight: "700" }}>Experts en Marketing</span>
                                    <span style={{ fontSize: 13 }}>Expert en Marketing de Croissance, Stratège en Marketing de Contenu, Marketeurs Numériques, et Spécialiste SEO</span>
                                </div>
                            </div>
                            <div className="d-flex align-items-center mt-3">
                                <Radio
                                    checked={role === 'Chefs de Projet'}
                                    onChange={handleChangeRole}
                                    value="Chefs de Projet"
                                    name="radio-buttons"
                                    inputProps={{ 'aria-label': 'Chefs de Projet' }}
                                />
                                <div className="d-flex flex-column">
                                    <span style={{ fontWeight: "700" }}>Chefs de Projet</span>
                                    <span style={{ fontSize: 13 }}>Chefs de Projet Numériques, Chefs de Projet IT, Scrum Masters et Coachs Agile</span>
                                </div>
                            </div>

                            <div className="d-flex align-items-center mt-3">
                                <Radio
                                    checked={role === 'Gestionnaires de Produit'}
                                    onChange={handleChangeRole}
                                    value="Gestionnaires de Produit"
                                    name="radio-buttons"
                                    inputProps={{ 'aria-label': 'Gestionnaires de Produit' }}
                                />
                                <div className="d-flex flex-column">
                                    <span style={{ fontWeight: "700" }}>Gestionnaires de Produit</span>
                                    <span style={{ fontSize: 13 }}>Gestionnaires de Produit Numériques, Propriétaires de Produit, et Analystes d'Affaires</span>
                                </div>
                            </div>
                            <div className="d-flex align-items-center mt-3">
                                <Radio
                                    checked={role === 'Experts en Finance'}
                                    onChange={handleChangeRole}
                                    value="Experts en Finance"
                                    name="radio-buttons"
                                    inputProps={{ 'aria-label': 'Experts en Finance' }}
                                />
                                <div className="d-flex flex-column">
                                    <span style={{ fontWeight: "700" }}>Experts en Finance</span>
                                    <span style={{ fontSize: 13 }}>Modélisateurs Financiers, Conseillers en Levée de Fonds, Experts en Fusions & Acquisitions et en FP&A", comptable</span>
                                </div>
                            </div>
                        </div>
                    }

                    {step === 2 &&
                        <div className="d-flex flex-column justify-content-center">
                            <span className="span-title-freemada1" style={{ textAlign: "center" }}>
                                Combien de personnes sont employées dans votre entreprise ?
                            </span>
                            <div className="d-flex align-items-center mt-3">
                                <Radio
                                    checked={nbPersonne === 'Moins de 10'}
                                    onChange={handleChangeNbPersonne}
                                    value="Moins de 10"
                                    name="radio-buttons"
                                    inputProps={{ 'aria-label': 'Moins de 10' }}
                                />
                                <div className="d-flex flex-column">
                                    <span style={{ fontSize: 14 }}>Moins de 10</span>
                                </div>
                            </div>
                            <div className="d-flex align-items-center mt-1">
                                <Radio
                                    checked={nbPersonne === '11 - 50'}
                                    onChange={handleChangeNbPersonne}
                                    value="11 - 50"
                                    name="radio-buttons"
                                    inputProps={{ 'aria-label': '11 - 50' }}
                                />
                                <div className="d-flex flex-column">
                                    <span style={{ fontSize: 14 }}>11 - 50</span>
                                </div>
                            </div>
                            <div className="d-flex align-items-center mt-1">
                                <Radio
                                    checked={nbPersonne === '51 - 200'}
                                    onChange={handleChangeNbPersonne}
                                    value="51 - 200"
                                    name="radio-buttons"
                                    inputProps={{ 'aria-label': '51 - 200' }}
                                />
                                <div className="d-flex flex-column">
                                    <span style={{ fontSize: 14 }}>51 - 200</span>
                                </div>
                            </div>
                            <div className="d-flex align-items-center mt-1">
                                <Radio
                                    checked={nbPersonne === '201 - 1000'}
                                    onChange={handleChangeNbPersonne}
                                    value="201 - 1000"
                                    name="radio-buttons"
                                    inputProps={{ 'aria-label': '201 - 1000' }}
                                />
                                <div className="d-flex flex-column">
                                    <span style={{ fontSize: 14 }}>201 - 1000</span>
                                </div>
                            </div>
                            <div className="d-flex align-items-center mt-1">
                                <Radio
                                    checked={nbPersonne === '1001 - 5000'}
                                    onChange={handleChangeNbPersonne}
                                    value="1001 - 5000"
                                    name="radio-buttons"
                                    inputProps={{ 'aria-label': '1001 - 5000' }}
                                />
                                <div className="d-flex flex-column">
                                    <span style={{ fontSize: 14 }}>1001 - 5000</span>
                                </div>
                            </div>

                            <div className="d-flex align-items-center mt-1">
                                <Radio
                                    checked={nbPersonne === 'Plus de 5000'}
                                    onChange={handleChangeNbPersonne}
                                    value="Plus de 5000"
                                    name="radio-buttons"
                                    inputProps={{ 'aria-label': 'Plus de 5000' }}
                                />
                                <div className="d-flex flex-column">
                                    <span style={{ fontSize: 14 }}>Plus de 5000</span>
                                </div>
                            </div>
                        </div>
                    }

                    {step === 3 &&
                        <div className="d-flex flex-column justify-content-center">
                            <span className="span-title-freemada1" style={{ textAlign: "center" }}>
                                Pour quel type de projet recrutez-vous ?
                            </span>
                            <div className="d-flex align-items-center mt-3">
                                <Radio
                                    checked={typeProjet === 'Nouvelle idée ou projet'}
                                    onChange={handleChangeTypeProjet}
                                    value="Nouvelle idée ou projet"
                                    name="radio-buttons"
                                    inputProps={{ 'aria-label': 'Nouvelle idée ou projet' }}
                                />
                                <div className="d-flex flex-column">
                                    <span style={{ fontSize: 14 }}>Nouvelle idée ou projet</span>
                                </div>
                            </div>
                            <div className="d-flex align-items-center mt-1">
                                <Radio
                                    checked={typeProjet === 'Projet existant qui nécessite plus de ressources'}
                                    onChange={handleChangeTypeProjet}
                                    value="Projet existant qui nécessite plus de ressources"
                                    name="radio-buttons"
                                    inputProps={{ 'aria-label': 'Projet existant qui nécessite plus de ressources' }}
                                />
                                <div className="d-flex flex-column">
                                    <span style={{ fontSize: 14 }}>Projet existant qui nécessite plus de ressources</span>
                                </div>
                            </div>
                            <div className="d-flex align-items-center mt-1">
                                <Radio
                                    checked={typeProjet === 'Aucune des réponses ci-dessus, je cherche juste à en savoir plus sur Freelance Mada'}
                                    onChange={handleChangeTypeProjet}
                                    value="Aucune des réponses ci-dessus, je cherche juste à en savoir plus sur Freelance Mada"
                                    name="radio-buttons"
                                    inputProps={{ 'aria-label': 'Aucune des réponses ci-dessus, je cherche juste à en savoir plus sur Freelance Mada' }}
                                />
                                <div className="d-flex flex-column">
                                    <span style={{ fontSize: 14 }}>Aucune des réponses ci-dessus, je cherche juste à en savoir plus sur Freelance Mada</span>
                                </div>
                            </div>
                        </div>
                    }

                    {step === 4 &&
                        <div className="d-flex flex-column justify-content-center">
                            <span className="span-title-freemada1" style={{ textAlign: "center" }}>
                                Combien de temps avez-vous besoin du {role} ?
                            </span>
                            <div className="d-flex align-items-center mt-3">
                                <Radio
                                    checked={tempsBesoin === "Moins d'une semaine"}
                                    onChange={handleChangeTempsBesoin}
                                    value="Moins d'une semaine"
                                    name="radio-buttons"
                                    inputProps={{ 'aria-label': "Moins d'une semaine" }}
                                />
                                <div className="d-flex flex-column">
                                    <span style={{ fontSize: 14 }}>Moins d'une semaine</span>
                                </div>
                            </div>
                            <div className="d-flex align-items-center mt-1">
                                <Radio
                                    checked={tempsBesoin === '1 à 4 semaines'}
                                    onChange={handleChangeTempsBesoin}
                                    value="1 à 4 semaines"
                                    name="radio-buttons"
                                    inputProps={{ 'aria-label': '1 à 4 semaines' }}
                                />
                                <div className="d-flex flex-column">
                                    <span style={{ fontSize: 14 }}>1 à 4 semaines</span>
                                </div>
                            </div>
                            <div className="d-flex align-items-center mt-1">
                                <Radio
                                    checked={tempsBesoin === '1 à 3 mois'}
                                    onChange={handleChangeTempsBesoin}
                                    value="1 à 3 mois"
                                    name="radio-buttons"
                                    inputProps={{ 'aria-label': '1 à 3 mois' }}
                                />
                                <div className="d-flex flex-column">
                                    <span style={{ fontSize: 14 }}>1 à 3 mois</span>
                                </div>
                            </div>
                            <div className="d-flex align-items-center mt-1">
                                <Radio
                                    checked={tempsBesoin === '3 à 6 mois'}
                                    onChange={handleChangeTempsBesoin}
                                    value="3 à 6 mois"
                                    name="radio-buttons"
                                    inputProps={{ 'aria-label': '3 à 6 mois' }}
                                />
                                <div className="d-flex flex-column">
                                    <span style={{ fontSize: 14 }}>3 à 6 mois</span>
                                </div>
                            </div>
                            <div className="d-flex align-items-center mt-1">
                                <Radio
                                    checked={tempsBesoin === 'Plus de 6 mois'}
                                    onChange={handleChangeTempsBesoin}
                                    value="Plus de 6 mois"
                                    name="radio-buttons"
                                    inputProps={{ 'aria-label': 'Plus de 6 mois' }}
                                />
                                <div className="d-flex flex-column">
                                    <span style={{ fontSize: 14 }}>Plus de 6 mois</span>
                                </div>
                            </div>

                            <div className="d-flex align-items-center mt-1">
                                <Radio
                                    checked={tempsBesoin === 'Je déciderai plus tard'}
                                    onChange={handleChangeTempsBesoin}
                                    value="Je déciderai plus tard"
                                    name="radio-buttons"
                                    inputProps={{ 'aria-label': 'Je déciderai plus tard' }}
                                />
                                <div className="d-flex flex-column">
                                    <span style={{ fontSize: 14 }}>Je déciderai plus tard</span>
                                </div>
                            </div>
                        </div>
                    }

                    {step === 5 &&
                        <div className="d-flex flex-column justify-content-center">
                            <span className="span-title-freemada1" style={{ textAlign: "center" }}>
                                Quel niveau d'engagement en temps exigerez-vous du {role} ?
                            </span>
                            <div className="d-flex mt-2" style={{ gap: 10 }}>
                                <InfoOutlinedIcon color="primary" />
                                <span style={{ fontSize: 12 }}>Besoin de flexibilité ? Toptal offre un engagement flexible si vous avez besoin d'ajuster le nombre d'heures sur votre projet.</span>
                            </div>
                            <div className="d-flex align-items-center mt-3">
                                <Radio
                                    checked={engagement === "Temps plein (40 heures ou plus par semaine)"}
                                    onChange={handleChangeEngagement}
                                    value="Temps plein (40 heures ou plus par semaine)"
                                    name="radio-buttons"
                                    inputProps={{ 'aria-label': "Temps plein (40 heures ou plus par semaine)" }}
                                />
                                <div className="d-flex flex-column">
                                    <span style={{ fontSize: 14 }}>Temps plein (40 heures ou plus par semaine)</span>
                                </div>
                            </div>
                            <div className="d-flex align-items-center mt-1">
                                <Radio
                                    checked={engagement === 'Temps partiel (Moins de 40 heures par semaine)'}
                                    onChange={handleChangeEngagement}
                                    value="Temps partiel (Moins de 40 heures par semaine)"
                                    name="radio-buttons"
                                    inputProps={{ 'aria-label': 'Temps partiel (Moins de 40 heures par semaine)' }}
                                />
                                <div className="d-flex flex-column">
                                    <span style={{ fontSize: 14 }}>Temps partiel (Moins de 40 heures par semaine)</span>
                                </div>
                            </div>
                            <div className="d-flex align-items-center mt-1">
                                <Radio
                                    checked={engagement === "À l'heure"}
                                    onChange={handleChangeEngagement}
                                    value="À l'heure"
                                    name="radio-buttons"
                                    inputProps={{ 'aria-label': "À l'heure" }}
                                />
                                <div className="d-flex flex-column">
                                    <span style={{ fontSize: 14 }}>À l'heure</span>
                                </div>
                            </div>
                            <div className="d-flex align-items-center mt-1">
                                <Radio
                                    checked={engagement === 'Je déciderai plus tard'}
                                    onChange={handleChangeEngagement}
                                    value="Je déciderai plus tard"
                                    name="radio-buttons"
                                    inputProps={{ 'aria-label': 'Je déciderai plus tard' }}
                                />
                                <div className="d-flex flex-column">
                                    <span style={{ fontSize: 14 }}>Je déciderai plus tard</span>
                                </div>
                            </div>
                        </div>
                    }

                    {step === 6 &&
                        <div className="d-flex flex-column justify-content-center">
                            <span className="span-title-freemada1" style={{ textAlign: "center" }}>
                                Quelles compétences aimeriez-vous voir chez votre {role} ?
                            </span>
                            <div className="d-flex mt-2" style={{ gap: 10 }}>
                                <InfoOutlinedIcon color="primary" />
                                <span style={{ fontSize: 12 }}>Vous n'êtes pas sûr des compétences à inclure ? Passez à la suite pour obtenir de l'aide de la part des experts de Freelance Mada. Domaines d'expertise souhaités.</span>
                            </div>

                            <FormControl fullWidth className='mt-1'>
                                <Select
                                    size='small'
                                    multiple
                                    value={selectedValues}
                                    onChange={handleChange}
                                    renderValue={(selected) => (
                                        <Box display="flex" style={{ gap: 5 }} flexWrap="wrap">
                                            {selected.map((value, key) => (
                                                value ?
                                                    <Chip style={{ color: "#1565C0", borderColor: "#1565C0" }} variant="outlined" key={key} label={value} /> : ''
                                            ))}
                                        </Box>
                                    )}
                                >
                                    <MenuItem className={classes.menuItem}>
                                        <Competence selectedNodes={selectedNodes} onCheckboxChange={(selectedValue) => {
                                            if (selectedValue) {
                                                if (selectedValues.includes(selectedValue)) {
                                                    const newArray = selectedValues.filter(item => item !== selectedValue);
                                                    setSelectedValues(newArray);
                                                } else {
                                                    setSelectedValues([...selectedValues, selectedValue]);
                                                }
                                            }
                                        }} />
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    }

                    {step === 7 &&
                        <div className="d-flex flex-column justify-content-center">
                            <span className="span-title-freemada1" style={{ textAlign: "center" }}>
                                Quand avez-vous besoin que le {role} commence ?
                            </span>
                            <div className="d-flex align-items-center mt-3">
                                <Radio
                                    checked={dateDebut === "Immédiatement"}
                                    onChange={handleChangedateDebut}
                                    value="Immédiatement"
                                    name="radio-buttons"
                                    inputProps={{ 'aria-label': "Immédiatement" }}
                                />
                                <div className="d-flex flex-column">
                                    <span style={{ fontSize: 14 }}>Immédiatement</span>
                                </div>
                            </div>
                            <div className="d-flex align-items-center mt-1">
                                <Radio
                                    checked={dateDebut === 'Dans 1 à 2 semaines'}
                                    onChange={handleChangedateDebut}
                                    value="Dans 1 à 2 semaines"
                                    name="radio-buttons"
                                    inputProps={{ 'aria-label': 'Dans 1 à 2 semaines' }}
                                />
                                <div className="d-flex flex-column">
                                    <span style={{ fontSize: 14 }}>Dans 1 à 2 semaines</span>
                                </div>
                            </div>
                            <div className="d-flex align-items-center mt-1">
                                <Radio
                                    checked={dateDebut === "Dans plus de 2 semaines"}
                                    onChange={handleChangedateDebut}
                                    value="Dans plus de 2 semaines"
                                    name="radio-buttons"
                                    inputProps={{ 'aria-label': "Dans plus de 2 semaines" }}
                                />
                                <div className="d-flex flex-column">
                                    <span style={{ fontSize: 14 }}>Dans plus de 2 semaines</span>
                                </div>
                            </div>
                            <div className="d-flex align-items-center mt-1">
                                <Radio
                                    checked={dateDebut === 'Je déciderai plus tard'}
                                    onChange={handleChangedateDebut}
                                    value="Je déciderai plus tard"
                                    name="radio-buttons"
                                    inputProps={{ 'aria-label': 'Je déciderai plus tard' }}
                                />
                                <div className="d-flex flex-column">
                                    <span style={{ fontSize: 14 }}>Je déciderai plus tard</span>
                                </div>
                            </div>
                        </div>
                    }

                    {step === 8 &&
                        <div className="d-flex flex-column justify-content-center">
                            <span className="span-title-freemada1" style={{ textAlign: "center" }}>
                                Tarif horaire ?
                            </span>
                            <div className="d-flex mt-2" style={{ gap: 10 }}>
                                <InfoOutlinedIcon color="primary" />
                                <span style={{ fontSize: 12 }}>Ce n'est pas grave si vous n'êtes pas sûr. Vous pourrez modifier ces informations plus tard.</span>
                            </div>
                            <div className="d-flex align-items-center mt-3">
                                <Radio
                                    checked={tarif === "Moins de 5000 ariary /h"}
                                    onChange={handleChangeTarif}
                                    value="Moins de 5000 ariary /h"
                                    name="radio-buttons"
                                    inputProps={{ 'aria-label': "Moins de 5000 ariary /h" }}
                                />
                                <div className="d-flex flex-column">
                                    <span style={{ fontSize: 14 }}>Moins de 5000 ariary /h</span>
                                </div>
                            </div>
                            <div className="d-flex align-items-center mt-1">
                                <Radio
                                    checked={tarif === '5000 ariary- 10 000 Ariary  /h'}
                                    onChange={handleChangeTarif}
                                    value="5000 ariary- 10 000 Ariary  /h"
                                    name="radio-buttons"
                                    inputProps={{ 'aria-label': '5000 ariary- 10 000 Ariary  /h' }}
                                />
                                <div className="d-flex flex-column">
                                    <span style={{ fontSize: 14 }}>5000 ariary- 10 000 Ariary  /h</span>
                                </div>
                            </div>
                            <div className="d-flex align-items-center mt-1">
                                <Radio
                                    checked={tarif === "10000 ariary- 25000 Ariary /h"}
                                    onChange={handleChangeTarif}
                                    value="10000 ariary- 25000 Ariary /h"
                                    name="radio-buttons"
                                    inputProps={{ 'aria-label': "10000 ariary- 25000 Ariary /h" }}
                                />
                                <div className="d-flex flex-column">
                                    <span style={{ fontSize: 14 }}>10000 ariary- 25000 Ariary /h</span>
                                </div>
                            </div>
                            <div className="d-flex align-items-center mt-1">
                                <Radio
                                    checked={tarif === '>50 000 Ariary /h'}
                                    onChange={handleChangeTarif}
                                    value=">50 000 Ariary /h"
                                    name="radio-buttons"
                                    inputProps={{ 'aria-label': '>50 000 Ariary /h' }}
                                />
                                <div className="d-flex flex-column">
                                    <span style={{ fontSize: 14 }}> {'>'}50 000 Ariary /h</span>
                                </div>
                            </div>
                            <div className="d-flex align-items-center mt-1">
                                <Radio
                                    checked={tarif === 'Pas encore sûr du budget'}
                                    onChange={handleChangeTarif}
                                    value="Pas encore sûr du budget"
                                    name="radio-buttons"
                                    inputProps={{ 'aria-label': 'Pas encore sûr du budget' }}
                                />
                                <div className="d-flex flex-column">
                                    <span style={{ fontSize: 14 }}>Pas encore sûr du budget</span>
                                </div>
                            </div>
                        </div>
                    }

                    {step === 9 &&
                        <div className="d-flex flex-column justify-content-center p-4">
                            <span className="span-title-freemada1" style={{ textAlign: "center" }}>
                                Veuillez décrire votre offre
                            </span>
                            <div className="d-flex mt-2" style={{ gap: 10 }}>
                                <InfoOutlinedIcon color="primary" />
                                <span style={{ fontSize: 12 }}>La description de l'offre va aider les freelanceurs à comprendre vos besoins.</span>
                            </div>
                            <div className="mt-4">
                                <TextField multiline
                                    rows={4}
                                    fullWidth
                                    onChange={(e) => { setDescription(e.target.value) }}
                                    value={description}
                                    size="small"
                                    id="outlined-basic99"
                                    label="Description"
                                    variant="outlined" 
                                />
                            </div>
                        </div>
                    }

                    {step === 10 &&
                        <div className="d-flex flex-column justify-content-center">
                            <span className="span-title-freemada1" style={{ textAlign: "center" }}>
                                Pour continuer avec l'inscription et vous connecter avec des talents sur FreelanceMada
                            </span>
                            <div className="d-flex flex-column mt-3" style={{ gap: 10 }}>
                                <TextField onChange={(e) => { setEmail(e.target.value) }} value={email} size="small" id="outlined-basic" label="Email" variant="outlined" />
                                <TextField onChange={(e) => { setSocName(e.target.value) }} value={socName} size="small" id="outlined-basic1" label="Société" variant="outlined" />
                                <TextField onChange={(e) => { setName(e.target.value) }} value={name} size="small" id="outlined-basic2" label="Contact prénom" variant="outlined" />
                                <TextField onChange={(e) => { setMobile(e.target.value) }} value={mobile} size="small" id="outlined-basic3" label="Téléphone" variant="outlined" />
                            </div>
                        </div>
                    }

                    {step === 1 ?
                        <div className="d-flex justify-content-center mt-3">
                            <div className="d-flex flex-column" style={{ gap: 15 }}>
                                <Button disabled={role === ""} onClick={nextStep} className="button-form-client" variant="contained" endIcon={<ArrowForwardIosIcon style={{ fontSize: 16 }} />}>
                                    Commencer
                                </Button>
                                <span className="span-link" onClick={(e) => { navigate('/build-mada') }} style={{ fontSize: 12, fontWeight: 700, color: "#1565C0", textAlign: "center" }}>Vous êtes un freelancer ?</span>
                            </div>
                        </div>
                        : step < 10 ?
                            <div>
                                <hr />
                                <div className="d-flex justify-content-between mt-3">
                                    <Button style={{ color: '#4f4949', textTransform: 'none', border: 'none' }} onClick={backStep} variant="outlined" startIcon={<ArrowBackIosIcon style={{ fontSize: 14 }} />}>
                                        Retour
                                    </Button>
                                    <div className="d-flex" style={{ gap: 10 }}>
                                        {(step > 2 && step < 8) &&
                                            <Button style={{ color: '#4f4949', textTransform: 'none', border: 'none' }} onClick={nextStep} variant="outlined" >
                                                Skip
                                            </Button>
                                        }
                                        <Button disabled={(nbPersonne !== "" && step === 2) || (typeProjet !== "" && step === 3) || (tempsBesoin !== "" && step === 4) || (engagement !== "" && step === 5) || (selectedValues.length !== 0 && step === 6) || (dateDebut !== "" && step === 7) || (tarif !== "" && step === 8) || (description !== "" && step === 9) ? false : true} disableElevation style={{ textTransform: 'none' }} onClick={nextStep} variant="contained" endIcon={<ArrowForwardIosIcon style={{ fontSize: 14 }} />}>
                                            Suivant
                                        </Button>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="d-flex flex-column justify-content-center mt-3" style={{ gap: 10 }}>
                                <Button disabled={name === "" || email === "" || socName === ""} onClick={addOffre} className="button-form-client" variant="contained" endIcon={<ArrowForwardIosIcon style={{ fontSize: 16 }} />}>
                                    Connectez moi à FreelanceMada
                                </Button>
                                <Button style={{ color: '#4f4949', textTransform: 'none', border: 'none' }} onClick={backStep} variant="outlined" startIcon={<ArrowBackIosIcon style={{ fontSize: 14 }} />}>
                                    Retour
                                </Button>
                            </div>
                    }
                </div>
            </div>
        </div>
    )
}
export default Client_Form;