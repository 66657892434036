import React, { useEffect } from "react";
import {useNavigate, useParams} from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import MuiBackdrop from "../../components/Loading/MuiBackdrop";
import StripePayment from "../../components/stripePayment/StripePayment";
import {apiNodeService} from "../../provider/apiNodeService";
import { maillingService } from "../../provider/maillingService";

const StripePaymentPage = (props) => {

    const toast = useToast();

    const [loading, setLoading] = React.useState(false);
    const navigate = useNavigate();
    const {amount} = useParams()
    const [offre, setOffre] = React.useState(null);
    const [user, setUser] = React.useState(null);
    const [room, setRoom] = React.useState(null);

    useEffect(() => {
        console.log(props.amount)
        // const searchParams = new URLSearchParams(window.location.search);
        // const id = searchParams.get('id');
        // getOffreById(id)
        // getRoomByMissionId(id)
    }, []);

    useEffect(() => {
        console.log("im in child")
    }, [props.payToChild]);

    
    const onConfirmPayment = (data,method_data) => {
        setLoading(true)
        if( data?.paymentIntent?.status === "succeeded" &&
            method_data?.status === 200 && !method_data?.error){
            setLoading(false);
            sendEmail()
            props.closeModal()
            console.log(amount)
            toast({
                title: 'Félicitation !',
                description: "Votre paiement est bien effectué avec succès",
                status: 'success',
                duration: 5000,
                isClosable: true,
            });

        }
    }
    

    const sendEmail = () => {
        const mailData = {
            emailFrom: "dev.rocketbonds@gmail.com",
            nameFrom: "FreelanceMada",
            to: [
                {
                    Email: props.email,
                    Name: ""
                }
            ],
            subject: "Confirmation de Paiement et Démarrage de la Mission",
            htmlText: 'Bonjour ' + props.name + ', <br/> <br/> ' +
                "Nous sommes heureux de vous informer que le donneur d'ordre a effectué le paiement d'acompte pour la mission "+props.title+". Vous pouvez maintenant commencer à travailler sur ce projet." +
                '<br/>' +
                "Nous vous enverrons [30% du montant de la mission moins les 15% de commission] sur votre compte IBAN sous peu." +
                '<br/>' +
                "Merci de votre collaboration et nous vous souhaitons un excellent travail sur ce projet." +
                '<br/><br/>' +
                "Cordialement." +
                '<br/><br/>' +
                '<br/><br/>FreelanceMada'
        };

        maillingService.sendMailByHtmlText(mailData).then(res => {
            console.log(res)
        }).catch(err => {
            console.log(err)
        })
    }

    // const getUserByEmail = async (email) => {
    //     return new Promise(resolve => {
    //         apiNodeService.filterTable({ table: "user_build", filter: { email: email }, db: "FREELANCE_MAD" }).then(userRes => {
    //             console.log(userRes)
    //             if (userRes && userRes !== "false" && Array.isArray(userRes.data)) {
    //                 setUser(userRes.data[0])
    //             } else {
    //                 resolve("false")
    //             }
    //         }).catch(err => resolve("false"))
    //     })
    // }

    return (
        <div>
            <MuiBackdrop
                open={loading}
            />
            {/* <div className="header-client">
                        <div className="d-flex justify-content-between align-items-center" style={{ height: "100%" }}>
                            <div className="d-flex px-2">
                                <span style={{ fontSize: 34, fontWeight: 700 }}>Build.</span><span style={{ fontSize: 34, color: "#214dcb", fontWeight: 700 }}>Mada</span>
                            </div>
                        </div>
                    </div> */}
            <div>
                {/* <button className="moreiconbutton1">
                    <img className="bg-icon4" alt="" src="/bg2.svg" />
                    <img className="vector-icon12" alt="" src="/vector3.svg" />
                </button>
                <button className="arrow15">
                    <div className="arrow-child4" />
                    <img
                        className="angle-leftoutline-icon6"
                        alt=""
                        src="/angleleftoutline.svg"
                    />
                </button> */}
                <div>
                    <StripePayment
                        amount={parseFloat(props.amount).toFixed(2)}
                        email={localStorage.getItem("email")}
                        desc={"Achat UCS"}
                        payToChild={props.payToChild}
                        logo={"/label-EC.png"}
                        onConfirmPayment={(data,method_data,email) => {
                            onConfirmPayment(data,method_data)
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default StripePaymentPage;
