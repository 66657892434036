import React, { useEffect, useRef, useState } from "react";
import MuiBackdrop from "../../components/Loading/MuiBackdrop";
import { apiNodeService } from "../../provider/apiNodeService";
import Avatar from '@mui/material/Avatar';
import "./Profile_List.css"
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { Button as MuiButton, Chip } from '@material-ui/core'
import Button from '@mui/material/Button';
import DoneIcon from '@mui/icons-material/Done';
import HomeRepairServiceOutlinedIcon from '@mui/icons-material/HomeRepairServiceOutlined';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import IconButton from "@material-ui/core/IconButton";
import TextField from '@mui/material/TextField';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import Checkbox from '@mui/material/Checkbox';
import StarIcon from '@mui/icons-material/Star';
import Alert from '@mui/material/Alert';
import { useNavigate } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import Slider from '@mui/material/Slider';
import Slider1 from "react-slick";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Skeleton from '@mui/material/Skeleton';
import  categoriesFreelance  from "../../data/categoriesFreelance";

const CustomNextArrow = ({ onClick }) => (
    <div className="custom-arrow next" >
        <ArrowForwardIosIcon fontSize="small" color="primary" onClick={onClick} />
    </div>
);

const CustomPrevArrow = ({ onClick }) => (
    <div className="custom-arrow prev" >
        <ArrowBackIosIcon fontSize="small" color="primary" onClick={onClick} />
    </div>
);

const CustomNextArrow1 = ({ onClick }) => (
    <div className="custom-arrow1 next" >
        <ArrowForwardIosIcon fontSize="small" color="primary" onClick={onClick} />
    </div>
);

const CustomPrevArrow1 = ({ onClick }) => (
    <div className="custom-arrow1 prev" >
        <ArrowBackIosIcon fontSize="small" color="primary" onClick={onClick} />
    </div>
);

const settings1 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <CustomNextArrow1 />,
    prevArrow: <CustomPrevArrow1 />,
};

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
};

function valuetext(value) {
    return `${value}°C`;
}

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 13,
        fontWeight: 600,
        maxWidth: 450,
    },
}));

const Profile_List_View = () => {

    const navigate = useNavigate();

    const [employes, setEmployes] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [openCommentModal, setOpenCommentModal] = React.useState(false);
    const [selectedUser, setSelectedUser] = React.useState({});
    const [requiredValues, setRequiredValues] = React.useState(false)
    const [description, setDescription] = React.useState("")
    const [checked, setChecked] = React.useState(false);
    const [communication, setCommunication] = React.useState("")
    const [satisfaction, setSatisfaction] = React.useState("")
    const [alertSuccess, setAlertSuccess] = React.useState(false)
    const [alertError, setAlertError] = React.useState(false)
    const [alertText, setAlertText] = React.useState("");
    const [logicielFilter, setLogicielFilter] = useState('');
    const [filteredData, setFilteredData] = useState(null);
    const [filteredDataMontant, setFilteredDataMontant] = useState(null);
    const [filteredDataNiveau, setFilteredDataNiveau] = useState(null);
    const [value, setValue] = React.useState([0, 1000000]);
    const [checkedNiveau1, setCheckedNiveau1] = React.useState(false);
    const [checkedNiveau2, setCheckedNiveau2] = React.useState(false);
    const [checkedNiveau3, setCheckedNiveau3] = React.useState(false);
    const [checkedNiveau, setCheckedNiveau] = React.useState('');
    const pageTopRef = useRef(null);
    const [slider, setSlider] = useState(null);
    const [slider1, setSlider1] = useState(null);

    const getNumberFreelance = (filterItem) => {
        const filteredData = employes.filter(obj => obj.logiciel && obj.logiciel.includes(filterItem));
        return filteredData.length + (filteredData.length > 1 ? " Freelances": " Freelance");
    }

    const scrollToTop = () => {
        pageTopRef.current.scrollTo(0, 0)
    };

    const handleChangeNiveau1 = (event) => {
        setCheckedNiveau1(event.target.checked);
        if (event.target.checked) {
            setCheckedNiveau('Junior (0-2ans)')
            setCheckedNiveau2(false)
            setCheckedNiveau3(false)
            filterByNiveau('Junior (0-2ans)', filteredDataMontant)
        } else {
            setCheckedNiveau('')
            filterByNiveau('', filteredDataMontant)
        }

    };
    const handleChangeNiveau2 = (event) => {
        setCheckedNiveau2(event.target.checked);
        if (event.target.checked) {
            setCheckedNiveau('Confirmer (3-7ans)')
            setCheckedNiveau1(false)
            setCheckedNiveau3(false)
            filterByNiveau('Confirmer (3-7ans)', filteredDataMontant)
        } else {
            setCheckedNiveau('')
            filterByNiveau('', filteredDataMontant)
        }
    };

    const handleChangeNiveau3 = (event) => {
        setCheckedNiveau3(event.target.checked);
        if (event.target.checked) {
            setCheckedNiveau('Senior (8-12ans)')
            setCheckedNiveau1(false)
            setCheckedNiveau2(false)
            filterByNiveau('Senior (8-12ans)', filteredDataMontant)
        } else {
            setCheckedNiveau('')
            filterByNiveau('', filteredDataMontant)
        }
    };

    const handleChange = (event, newValue) => {
        console.log(newValue)
        setValue(newValue);
        filterByMontant(newValue, filteredData)
    };

    const resetValues = () => {
        setChecked(false)
        setSatisfaction("")
        setCommunication("")
        setDescription("")
    }

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const categorie = searchParams.get('cat');
        console.log(categorie)
        getUsers(categorie)
    }, []);

    const getUsers = (categorie) => {
        setLoading(true)
        apiNodeService.getTableData({ db: "FREELANCE_MAD", table: "employe_odoo" }).then((res) => {
            console.log(res)
            if (res && res.status === 200) {
                const freelanceUsers = res.data.filter(user => user.role === 'freelance');
                let sortedData = freelanceUsers.sort((a, b) => {
                    if (a.logiciel && b.logiciel) {
                        return b.logiciel.length - a.logiciel.length;
                    } else if (a.logiciel) {
                        return -1; // Place les objets sans logiciel après ceux avec logiciel
                    } else if (b.logiciel) {
                        return 1; // Place les objets avec logiciel avant ceux sans logiciel
                    } else {
                        return 0; // Garde l'ordre si aucun des deux objets n'a de logiciel
                    }
                });
                setEmployes([...sortedData])
                setFilteredData([...sortedData])
                setFilteredDataMontant([...sortedData])
                setFilteredDataNiveau([...sortedData])
                const cat = categorie.replace(/'/g, "")
                setLogicielFilter(cat)
                filterByLogiciel(cat, sortedData)
            }
            setLoading(false)
        })
    }

    const handleFilterChange = (event) => {
        const { value } = event.target;
        setLogicielFilter(value);
        filterByLogiciel(value);
    };

    const filterByLogiciel = (filterValue,arr1) => {
        console.log(filterValue)
        const filteredArray = (employes.length > 0 ? employes : arr1).filter(item =>
            !filterValue || // Added check for empty filter value
            filterValue === "" ||
            (item.logiciel &&
                item.logiciel.some(logiciel =>
                    logiciel.toLowerCase().includes(filterValue.toLowerCase())
                ))
            || filterValue.toLowerCase().replace(/é/g, 'e').split(/\s+/).every(word =>
                (item.first_name.toLowerCase().replace(/é/g, 'e')).includes(word) ||
                (item.last_name.toLowerCase().replace(/é/g, 'e')).includes(word)
            )
        )
        console.log(filteredArray)
        setFilteredData(filteredArray);
        setFilteredDataMontant(filteredArray)
        filterByMontant(value, filteredArray)
    };

    const filterByMontant = (filterValue, data) => {
        console.log(filteredData);

        const filteredArray = data.filter(item => {
            if (!filterValue) {
                return true; // Retourne true si aucun filtre n'est défini
            }

            if (filterValue[0] === 0) {
                // Retourne true si le filtre est défini à 0 (inclut les objets sans montant)
                return !item.montant || (item.montant >= filterValue[0] && item.montant <= filterValue[1]);
            }

            // Retourne true si le montant de l'élément est dans la plage spécifiée
            return item.montant && (item.montant >= filterValue[0] && item.montant <= filterValue[1]);
        });

        console.log(filteredArray);
        setFilteredDataMontant(filteredArray);
        filterByNiveau(checkedNiveau, filteredArray)
    };

    const filterByNiveau = (filterValue, data) => {
        console.log(filteredData);

        const filteredArray = data.filter(item => {
            if (!filterValue) {
                return true;
            }

            if (filterValue === '') {
                // Retourne true si le filtre est défini à 0 (inclut les objets sans montant)
                return !item.niveau || item.niveau;
            }

            // Retourne true si le montant de l'élément est dans la plage spécifiée
            return item.niveau && (item.niveau === filterValue);
        });

        console.log(filteredArray);
        setFilteredDataNiveau(filteredArray);
    };




    return (
        <div style={{ overflowX: 'hidden' }}>
            <MuiBackdrop open={loading} text={"Chargement..."} />
            <div className="header-client" style={{ top: 0, backgroundColor: "#fff" }} >
                <div className="d-flex justify-content-between align-items-center px-2" style={{ height: "100%" }}>
                    <div className="d-flex px-2" style={{ cursor: "pointer" }} onClick={(e) => { navigate('/') }}>
                        <span style={{ fontSize: 34, fontWeight: 700 }}>Build.</span><span style={{ fontSize: 34, color: "#214dcb", fontWeight: 700 }}>Mada</span>
                    </div>
                    <div className="d-flex align-items-center px-2" style={{ gap: 20 }}>
                        <button className="create-client-button" onClick={(e) => { navigate('/build-mada-signup') }}>
                            Créer un compte
                        </button>
                        <div style={{ borderBottom: "2px solid #214dcb", padding: "2px 8px 4px 8px" }}>
                            <span className="span-link1" style={{ fontWeight: 700, color: "#214dcb" }} onClick={(e) => { navigate('/build-mada') }}>Me connecter</span>
                        </div>
                    </div>
                </div>
            </div>
            {alertSuccess &&
                <Alert style={{ marginTop: 35 }} severity="success">
                    {alertText}
                </Alert>}
            {alertError &&
                <Alert severity="error">
                    Erreur, Veuillez réessayer plus tard !
                </Alert>}
            <div ref={pageTopRef} className="row" style={{ marginTop: 30 }}>

                <div className="col-md-3">
                    <div className="p-4 d-flex flex-column" style={{ marginTop: 70 }}>
                        <span style={{ fontSize: 32, fontWeight: 700, fontFamily: "Montserrat" }}>Filtres</span>
                        <hr style={{ color: "#7293b3" }} className="mb-3 mt-0" />
                        <span style={{ fontSize: 20, fontWeight: 700, fontFamily: "Montserrat" }}>Tarif / jour (MGA)</span>
                        <Slider
                            min={0}
                            max={100000}
                            getAriaLabel={() => 'Temperature range'}
                            value={value}
                            className="mt-3"
                            onChange={handleChange}
                            valueLabelDisplay="auto"
                            getAriaValueText={valuetext}
                        />
                        <div className="d-flex justify-content-between">
                            <span style={{ fontSize: 14, fontWeight: 600 }}>Min: {value[0]}</span>
                            <span style={{ fontSize: 14, fontWeight: 600 }}>Max: {value[1]}</span>
                        </div>

                        <hr style={{ color: "#7293b3" }} className="mb-4 mt-4" />

                        <span style={{ fontSize: 20, fontWeight: 700, fontFamily: "Montserrat" }}>Niveau d'expérience</span>
                        <div className="d-flex align-items-center" style={{ gap: 10 }}>
                            <Checkbox
                                checked={checkedNiveau1}
                                onChange={handleChangeNiveau1}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                            <span style={{ fontSize: 18, fontWeight: 600 }}>0 - 2 ans</span>
                        </div>
                        <div className="d-flex align-items-center" style={{ gap: 10 }}>
                            <Checkbox
                                checked={checkedNiveau2}
                                onChange={handleChangeNiveau2}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                            <span style={{ fontSize: 18, fontWeight: 600 }}>3 - 7 ans</span>
                        </div>
                        <div className="d-flex align-items-center" style={{ gap: 10 }}>
                            <Checkbox
                                checked={checkedNiveau3}
                                onChange={handleChangeNiveau3}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                            <span style={{ fontSize: 18, fontWeight: 600 }}>8 ans et +</span>
                        </div>

                    </div>
                </div>
                <div className="col-md-9">

                    <div className="p-2" style={{ overflowY: 'auto', overflowX: 'hidden', maxHeight: '85vh', marginTop: 30 }}>
                        {employes.length > 0 &&
                            <div>
                                <div className="d-flex title-equipe mt-4 px-3 align-items-center" style={{ gap: 10 }}>
                                    <span>
                                        {"Top 10 freelances du mois"}
                                    </span>
                                    <LightTooltip style={{ backgroundColor: '#fff' }} title="Le classement du Top 10 des freelances du mois est basé sur le nombre de commentaires (selon la catégorie de métier) et la note moyenne des commentaires attribuée dans le mois ! 
                              Chaque 1er matin du mois à 00h00, le classement est rénitialisé à zéro afin de laisser à chaque freelance une chance d’être mis en avant.">
                                        <InfoOutlinedIcon className="mt-1" style={{ cursor: 'pointer' }} color="primary" />
                                    </LightTooltip>
                                </div>
                            </div>
                        }
                        {employes.length > 0 &&
                            <Slider1 ref={(slider)} className="mb-5 slider-freelance" slidesPerRow={2} {...settings}>
                                {employes.slice(0, 10).map((item, key) => (
                                    <div key={key} className="col-md-6 mt-3 px-2">
                                        <div className="p-2 card-profil">
                                            <div className="d-flex align-items-start justify-content-between mt-2" style={{ gap: 20, width: "100%" }}>
                                                <div className="d-flex" style={{ gap: 20, width: "100%" }}>
                                                    <div className="d-flex flex-column">
                                                        <Avatar
                                                            onClick={(e) => { navigate("/build-mada-profil-detail-view?user=" + item.uid, { replace: true }) }}
                                                            sx={{ width: 100, height: 100, cursor: 'pointer' }}
                                                            alt={item.first_name}
                                                            src={item.photo ? item.photo.prefix + item.photo.data : ''}
                                                        />
                                                        <div className='d-flex align-items-center mt-2' style={{ gap: 2 }}>
                                                            <img width={15} alt="star" src='/star.png' />
                                                            <span style={{ fontFamily: 'Montserrat', fontSize: 12 }}>{item.comment ? (item.comment[0].satisfaction + item.comment[0].communication) / 2 + "/5" : "Aucun avis"} {item.comment ? "(" + item.comment.length + " avis)" : ""}</span>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex flex-column">
                                                        <span onClick={(e) => { navigate("/build-mada-profil-detail-view?user=" + item.uid, { replace: true }) }} className='span-avatar go-details' style={{ textAlign: 'left' }}>{item.first_name} {item.last_name}</span>
                                                        <span className='span-job mt-1' style={{ textAlign: 'left' }}>{item.poste_actuel ? item.poste_actuel : 'Freelanceur'}</span>
                                                        <span className='span-description mt-1' style={{ textAlign: 'left' }}>{item.description}</span>
                                                    </div>
                                                </div>
                                                {/* <FavoriteIcon color="primary"/> */}
                                                {/* <FavoriteBorderIcon color="primary" style={{ cursor: 'pointer' }}
                                            onClick={() => {
                                                setSelectedUser(item)
                                                setOpenCommentModal(true)
                                            }} /> */}
                                            </div>
                                            {item.logiciel &&
                                                <div className="d-flex flex-wrap mt-2 text-truncate" style={{ gap: 4, height: 58 }}>
                                                    {(item.logiciel || []).slice(0, 7).map((competence, key1) => (
                                                        <div key={key1} onClick={(e) => {
                                                            setLogicielFilter(competence)
                                                            filterByLogiciel(competence)
                                                            scrollToTop()
                                                        }}>
                                                            <Chip size="small"
                                                                style={{ color: "#1565C0", borderColor: "#1565C0", cursor: "pointer" }}
                                                                variant="outlined"
                                                                label={competence}
                                                            />
                                                        </div>
                                                    ))
                                                    }
                                                </div>
                                            }
                                            <div className="d-flex justify-content-between mt-3 mb-2" style={{ gap: 5 }}>
                                                <Button color="success" variant="outlined" className="btn-profile" startIcon={<DoneIcon />}>
                                                    Disponible
                                                </Button>
                                                <Button style={{ color: "grey", borderColor: "grey" }} variant="outlined" className="btn-profile" startIcon={<HomeRepairServiceOutlinedIcon />}>
                                                    {item.montant ? item.montant : 0} MGA/h
                                                </Button>
                                                {/* <Button className="btn-profile" onClick={(e)=>{createRoom(item.email,item.first_name + " " +item.last_name)}} variant="contained" endIcon={<SendOutlinedIcon />}>
                                            Inviter sur une mission
                                        </Button> */}
                                            </div>
                                        </div>
                                    </div>
                                ))

                                }
                            </Slider1>
                        }

                        <div >
                            <div className="title-equipe mb-1 px-3">
                                <span>
                                    {"Recruter un.e freelance"}
                                </span>
                            </div>
                        </div>
                        <div className="d-flex justify-content-start">
                            <div className="d-flex flex-column px-3" style={{ gap: 5 }}>
                                <TextField
                                    value={logicielFilter}
                                    onChange={handleFilterChange}
                                    id="input-with-icon-textfield"
                                    placeholder="Rechercher par compétences, logiciels, freelance ou métiers"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon style={{ color: "#1565C0" }} />
                                            </InputAdornment>
                                        ),
                                    }}
                                    style={{ width: 520 }}
                                    variant="outlined"
                                    size="small"
                                />
                                {/* <span className="span-title mb-2" style={{ fontSize: 12, color: "#b1b1b1" }}>Chercher par compétences logiciels</span> */}
                            </div>
                        </div>
                        {employes.length > 0 &&
                    <Slider1 ref={(slider1)} className="mb-5 px-2 mt-2 slider-categorie" slidesPerRow={5} {...settings1}>
                        {categoriesFreelance.categoriesFreelance.map((item, key) => (<div className="col-md-3 p-2">
                            <div className="p-2 px-3 card-profil">
                                <div className="d-flex flex-column" style={{ gap: 10 }}>
                                    <span style={{ textAlign: 'left', fontWeight: 700, color: "#1565C0", fontSize: 18 }}>{item.label}</span>
                                    <div>
                                        <Chip size="small"
                                            className="px-2"
                                            style={{ color: "grey", borderColor: "grey", cursor: "pointer" }}
                                            variant="outlined"
                                            label={getNumberFreelance(item.label)}
                                        />
                                    </div>
                                    <div className="d-flex align-items-center go-details" style={{ gap: 10 }}
                                        onClick={(e) => {
                                            setLogicielFilter(item.label);
                                            filterByLogiciel(item.label);
                                        }}>
                                        <span style={{ fontWeight: 600, color: "#1565C0" }}>Voir</span>
                                        <ArrowForwardIosIcon style={{ width: 15, marginTop: 2 }} color="primary" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        ))
                        }
                    </Slider1>
                }
                        <div>
                            <div className="title-equipe mb-3 px-3">
                                <span>
                                    {"Toutes les freelances"}
                                </span>
                            </div>
                        </div>
                        {loading === true &&
                            <div className="row px-3">
                                <div className="col-md-6">
                                    <Skeleton className="mt-3" variant="rectangular" width={"100%"} height={160} />
                                </div>
                                <div className="col-md-6">
                                    <Skeleton className="mt-3" variant="rectangular" width={"100%"} height={160} />
                                </div>
                                <div className="col-md-6">
                                    <Skeleton className="mt-3" variant="rectangular" width={"100%"} height={160} />
                                </div>
                                <div className="col-md-6">
                                    <Skeleton className="mt-3" variant="rectangular" width={"100%"} height={160} />
                                </div>
                            </div>
                        }
                        {employes.length > 0 &&
                            <>
                                {filteredDataNiveau.length > 0 ?
                                    <div className="row px-3 mb-3">
                                        {filteredDataNiveau.map((item, key) => (
                                            <div className="col-md-6 mt-2" key={key}>
                                                <div className="p-2 card-profil">
                                                    <div className="d-flex align-items-start justify-content-between mt-2" style={{ gap: 20, width: "100%" }}>
                                                        <div className="d-flex" style={{ gap: 20, width: "100%" }}>
                                                            <div className="d-flex flex-column">
                                                                <Avatar
                                                                    onClick={(e) => { navigate("/build-mada-profil-detail-view?user=" + item.uid, { replace: true }) }}
                                                                    sx={{ width: 100, height: 100, cursor: 'pointer' }}
                                                                    alt={item.first_name}
                                                                    src={item.photo ? item.photo.prefix + item.photo.data : ''}
                                                                />
                                                                <div className='d-flex align-items-center mt-2' style={{ gap: 2 }}>
                                                                    <img width={15} alt="star" src='/star.png' />
                                                                    <span style={{ fontFamily: 'Montserrat', fontSize: 12 }}>{item.comment ? (item.comment[0].satisfaction + item.comment[0].communication) / 2 + "/5" : "0/5"} {item.comment ? "(" + item.comment.length + " avis)" : "(0 avis)"}</span>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex flex-column">
                                                                <span onClick={(e) => { navigate("/build-mada-profil-detail-view?user=" + item.uid, { replace: true }) }} className='span-avatar go-details' style={{ textAlign: 'left' }}>{item.first_name} {item.last_name}</span>
                                                                <span className='span-job mt-1' style={{ textAlign: 'left' }}>{item.job_title ? item.job_title : ''}</span>
                                                                {/* <span className='span-job mt-1' style={{ textAlign: 'left' }}>Architecte solaire</span> */}
                                                                <span className='span-description mt-1' style={{ textAlign: 'left' }}>{item.description}</span>
                                                            </div>
                                                        </div>
                                                        {/* <FavoriteIcon color="primary"/> */}
                                                        {/* <FavoriteBorderIcon color="primary" style={{ cursor: 'pointer' }}
                                                    onClick={() => {
                                                        // setSelectedUser(item)
                                                        // setOpenCommentModal(true)
                                                    }} /> */}
                                                    </div>
                                                    {item.logiciel &&
                                                        <div className="d-flex flex-wrap mt-2" style={{ gap: 4 }}>
                                                            {(item.logiciel || []).map((competence, key1) => (
                                                                <div key={key1} onClick={(e) => {
                                                                    setLogicielFilter(competence)
                                                                    filterByLogiciel(competence)
                                                                    scrollToTop()
                                                                }}>
                                                                    <Chip size="small" style={{ color: "#1565C0", borderColor: "#1565C0", cursor: "pointer" }} variant="outlined" label={competence} />
                                                                </div>
                                                            ))
                                                            }
                                                        </div>
                                                    }
                                                    <div className="d-flex justify-content-between mt-3 mb-2" style={{ gap: 5 }}>
                                                        <Button color="success" variant="outlined" className="btn-profile" startIcon={<DoneIcon />}>
                                                            Disponible
                                                        </Button>
                                                        <Button style={{ color: "grey", borderColor: "grey" }} variant="outlined" className="btn-profile" startIcon={<HomeRepairServiceOutlinedIcon />}>
                                                            {item.montant ? item.montant : 0} MGA/h
                                                        </Button>
                                                        {/* <Button className="btn-profile" variant="contained" endIcon={<SendOutlinedIcon />}>
                                            Inviter sur une mission
                                        </Button> */}
                                                    </div>
                                                </div>
                                            </div>
                                        ))

                                        }
                                    </div>

                                    :
                                    <span style={{ fontFamily: "Montserrat", color: "black", marginLeft: 16 }}>Aucun résultat correspondant à votre recherche</span>
                                }
                            </>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Profile_List_View;