import React, { useEffect } from "react";
import { apiNodeService } from "../../provider/apiNodeService";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import IconButton from "@material-ui/core/IconButton";
import TextField from '@mui/material/TextField';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import Checkbox from '@mui/material/Checkbox';
import StarIcon from '@mui/icons-material/Star';
import { Button as MuiButton } from '@material-ui/core'
import MuiBackdrop from "../Loading/MuiBackdrop";

const Comment_Modal = (props) => {

    const [loading, setLoading] = React.useState(false);
    const [communication, setCommunication] = React.useState("")
    const [satisfaction, setSatisfaction] = React.useState("")
    const [description, setDescription] = React.useState("")
    const [checked, setChecked] = React.useState(false);
    const [selectedUser, setSelectedUser] = React.useState({});
    const [requiredValues, setRequiredValues] = React.useState(false)

    useEffect(() => {
        getUserByEmail(props.email)
    }, []);

    const resetValues = () => {
        setChecked(false)
        setSatisfaction("")
        setCommunication("")
        setDescription("")
    }

    const handleChange = (event) => {
        setChecked(!checked);
    };

    const getUserByEmail = async (email) => {
        return new Promise(resolve => {
            apiNodeService.filterTable({ table: "employe_odoo", filter: { email: email }, db: "FREELANCE_MAD" }).then(userRes => {
                console.log(userRes)
                if (userRes && userRes !== "false" && Array.isArray(userRes.data)) {
                    setSelectedUser(userRes.data[0])
                } else {
                    resolve("false")
                }
            }).catch(err => resolve("false"))
        })
    }

    const AddComment = () => {
        setLoading(true)
        let data = {}
        let newComment = {
            description: description,
            satisfaction: satisfaction,
            communication: communication,
            checked: checked,
            user: localStorage.getItem("email"),
            user_first_name: localStorage.getItem("first_name"),
            user_last_name: localStorage.getItem("last_name")
        }
        if (selectedUser.comment) {
            console.log(selectedUser)
            data.comment = [...selectedUser.comment, newComment]
            apiNodeService.updateTableData({ db: "FREELANCE_MAD", table: "employe_odoo", id: selectedUser.id, data: data }).then(resAdd => {
                console.log(resAdd)
                if (resAdd && resAdd.status === 200) {
                    setLoading(false)
                    props.closeModalComment()
                    props.noteOffre("note_porteur")
                    resetValues()

                } else {
                    setLoading(false)
                }
            })
        } else {
            data.comment = [newComment]
            apiNodeService.updateTableData({ db: "FREELANCE_MAD", table: "employe_odoo", id: selectedUser.id, data: data }).then(resAdd => {
                console.log(resAdd)
                if (resAdd && resAdd.status === 200) {
                    setLoading(false)
                    props.closeModalComment()
                    props.noteOffre("note_porteur")
                    resetValues()

                } else {
                    setLoading(false)
                }
            })
        }
    }

    return (
        <Dialog
            open={props.openCommentModal}
            aria-labelledby="form-dialog-title"
            fullWidth={true}
            maxWidth="sm"

        >
            <DialogTitle id="form-dialog-title" style={{ paddingBottom: 1 }}>
                <div className="d-flex" style={{ gap: 10 }}>
                    <StarBorderIcon style={{ color: "#1565c0" }} />
                    <Typography style={{ color: '#1565C0', fontWeight: 600 }}>Commenter le service de {selectedUser.first_name}</Typography>
                </div>

                <IconButton
                    aria-label="close"
                    style={{
                        position: 'absolute',
                        right: 5,
                        top: 5,
                        color: '#c0c0c0'
                    }}
                    onClick={() => {
                        resetValues()
                        props.closeModalComment()
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <hr style={{ color: "#1565C0" }} />
            </DialogTitle>
            <DialogContent style={{ overflowY: "inherit" }}>
                <MuiBackdrop open={loading} text={"Chargement..."} />
                <div className="d-flex flex-column">
                    <span style={{ color: 'grey', fontSize: 14,fontWeight: 700, }}>* = Champs obligatoires</span>
                    <span className="span-title mt-3 mb-2">Communication (politesse, respect, etc...)*</span>
                </div>

                <div className="d-flex mb-3" style={{ gap: 25 }}>
                    <div className="d-flex flex-column justify-content-center" onClick={() => { setCommunication(1) }} style={{ cursor:'pointer' }}>
                        <div className="d-flex justify-content-center">
                            {communication === 1 ?
                                <StarIcon style={{ color: "#1565c0",cursor:'pointer' }} />
                                :
                                <StarBorderIcon  style={{ color: "#1565c0",cursor:'pointer' }} />
                            }
                        </div>

                        <span style={{ color: "#949494", fontSize: 12 }}>Terrible</span>
                    </div>

                    <div className="d-flex flex-column" onClick={() => { setCommunication(2) }}  style={{ cursor:'pointer' }}>
                        <div className="d-flex justify-content-center">
                            {communication === 2 ?
                                <StarIcon style={{ color: "#1565c0",cursor:'pointer' }} />
                                :
                                <StarBorderIcon style={{ color: "#1565c0",cursor:'pointer' }} />
                            }
                        </div>

                        <span style={{ color: "#949494", fontSize: 12 }}>Mauvais</span>
                    </div>

                    <div className="d-flex flex-column" style={{ cursor:'pointer' }} onClick={() => { setCommunication(3) }}>
                        <div className="d-flex justify-content-center">
                            {communication === 3 ?
                                <StarIcon style={{ color: "#1565c0",cursor:'pointer' }} />
                                :
                                <StarBorderIcon style={{ color: "#1565c0",cursor:'pointer' }} />
                            }
                        </div>
                        <span style={{ color: "#949494", fontSize: 12 }}>Moyen</span>
                    </div>

                    <div className="d-flex flex-column" style={{ cursor:'pointer' }} onClick={() => { setCommunication(4) }}>
                        <div className="d-flex justify-content-center">
                            {communication === 4 ?
                                <StarIcon style={{ color: "#1565c0",cursor:'pointer' }} />
                                :
                                <StarBorderIcon style={{ color: "#1565c0",cursor:'pointer' }} />
                            }
                        </div>
                        <span style={{ color: "#949494", fontSize: 12 }}>Bien</span>
                    </div>

                    <div className="d-flex flex-column" style={{ cursor:'pointer' }} onClick={() => { setCommunication(5) }}>
                        <div className="d-flex justify-content-center">
                            {communication === 5 ?
                                <StarIcon style={{ color: "#1565c0",cursor:'pointer' }} />
                                :
                                <StarBorderIcon  style={{ color: "#1565c0",cursor:'pointer' }} />
                            }
                        </div>
                        <span style={{ color: "#949494", fontSize: 12 }}>Excellent</span>
                    </div>
                </div>

                <span className="span-title mb-2">Satisfaction du résultat (objectif initial atteint, problème rencontré, etc...)*</span>
                <div className="d-flex mb-3" style={{ gap: 25 }}>
                    <div className="d-flex flex-column" style={{ cursor:'pointer' }} onClick={() => { setSatisfaction(1) }}>
                        <div className="d-flex justify-content-center">
                            {satisfaction === 1 ?
                                <StarIcon style={{ color: "#1565c0",cursor:'pointer' }} />
                                :
                                <StarBorderIcon  style={{ color: "#1565c0",cursor:'pointer' }} />
                            }
                        </div>
                        <span style={{ color: "#949494", fontSize: 12 }}>Terrible</span>
                    </div>

                    <div className="d-flex flex-column" style={{ cursor:'pointer' }} onClick={() => { setSatisfaction(2) }}>
                        <div className="d-flex justify-content-center">
                            {satisfaction === 2 ?
                                <StarIcon style={{ color: "#1565c0",cursor:'pointer' }} />
                                :
                                <StarBorderIcon  style={{ color: "#1565c0",cursor:'pointer' }} />
                            }
                        </div>
                        <span style={{ color: "#949494", fontSize: 12 }}>Mauvais</span>
                    </div>

                    <div className="d-flex flex-column" style={{ cursor:'pointer' }} onClick={() => { setSatisfaction(3) }}>
                        <div className="d-flex justify-content-center">
                            {satisfaction === 3 ?
                                <StarIcon style={{ color: "#1565c0",cursor:'pointer' }} />
                                :
                                <StarBorderIcon style={{ color: "#1565c0",cursor:'pointer' }} />
                            }
                        </div>
                        <span style={{ color: "#949494", fontSize: 12 }}>Moyen</span>
                    </div>

                    <div className="d-flex flex-column" style={{ cursor:'pointer' }} onClick={() => { setSatisfaction(4) }}>
                        <div className="d-flex justify-content-center">
                            {satisfaction === 4 ?
                                <StarIcon style={{ color: "#1565c0",cursor:'pointer' }} />
                                :
                                <StarBorderIcon style={{ color: "#1565c0",cursor:'pointer' }} />
                            }
                        </div>
                        <span style={{ color: "#949494", fontSize: 12 }}>Bien</span>
                    </div>

                    <div className="d-flex flex-column" style={{ cursor:'pointer' }} onClick={() => { setSatisfaction(5) }}>
                        <div className="d-flex justify-content-center">
                            {satisfaction === 5 ?
                                <StarIcon style={{ color: "#1565c0",cursor:'pointer' }} />
                                :
                                <StarBorderIcon style={{ color: "#1565c0",cursor:'pointer' }} />
                            }
                        </div>
                        <span style={{ color: "#949494", fontSize: 12 }}>Excellent</span>
                    </div>
                </div>
                <span className="span-title mb-2 mt-2">Description (remerciement, spécification, etc...)</span>
                <TextField fullWidth size="small" id="outlined-basic" variant="outlined"
                    value={description}
                    onChange={(e) => {
                        setDescription(e.target.value)
                    }} />
                <div className="d-flex justify-content-start mt-1">
                    <span className="span-title mb-2" style={{ fontSize: 12, color: "#616161" }}>Cette description pourra figurer sur le profil du freelance.</span>
                </div>
                <div className="d-flex align-items-center mt-3" style={{ gap: 8 }}>
                    <Checkbox
                        checked={checked}
                        onChange={handleChange}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                    <span onClick={handleChange} className="span-footer mb-1">En cochant cette case, je confirme que les détails fournis ci-dessus sont véridiques et exacts. Je comprends que cette information est cruciale pour aider les autres utilisateurs à faire leurs choix et de mettre en juste valeur le travail effectué par ce prestataire</span>
                </div>
            </DialogContent>
            <DialogActions style={{ padding: 20, justifyContent: 'start' }}>
                <MuiButton

                    color="primary"
                    variant="outlined"
                    style={{ textTransform: 'capitalize' }}
                    onClick={() => {
                        resetValues()
                        props.closeModalComment()
                    }}
                >
                    Cancel
                </MuiButton>
                <MuiButton
                    onClick={() => {
                        AddComment()
                    }}
                    variant="contained"
                    disabled={(satisfaction === "" || communication === "" || description === "") ? true : false}
                    style={{ textTransform: 'capitalize', color: "#fff", backgroundColor: (satisfaction === "" || communication === "" || description === "") ? "grey" : "#1565C0" }}
                >
                    Envoyer le commentaire
                </MuiButton>
                {requiredValues === true && <span style={{ color: 'red' }}>Veuillez remplir les champs obligatoire *</span>}
            </DialogActions>
        </Dialog>
    )
}
export default Comment_Modal;