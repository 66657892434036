import React, { useEffect } from "react";
import useWindowSize from "../../components/WindowSize/useWindowSize";
import { useNavigate } from "react-router-dom";
import oalogo from "../../assets/logo/logo3.png"
import "./login.css"
import "./backgroundAnim.css"
import { Button as MuiButton, TextField } from "@mui/material";
import LoginIcon from '@mui/icons-material/Login';
import { apiNodeService } from "../../provider/apiNodeService";
import MuiBackdrop from "../../components/Loading/MuiBackdrop";

const popup_w = 900;
const popup_h = 700;

var dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screen.left;
var dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screen.top;

var width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : window.screen.width;
var height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : window.screen.height;

var left = ((width / 2) - (popup_w / 2)) + dualScreenLeft;
var top = ((height / 2) - (popup_h / 2)) + dualScreenTop;


export default function Signup(props) {

    const navigate = useNavigate();

    const [email, setEmail] = React.useState('')
    const [nom, setNom] = React.useState('')
    const [prenom, setPrenom] = React.useState('')
    const [password, setPassword] = React.useState('')
    const [passwordVerif, setPasswordVerif] = React.useState('')
    const [validate, setValidate] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const [isEmptyValue, setIsEmptyValue] = React.useState(false)
    const [isMatch, setIsMatch] = React.useState(false)
    const [inscritError, setInscritError] = React.useState(false)

    useEffect(() => {

    }, []);

    const getUserByEmail = async () => {
        return new Promise(resolve => {
            apiNodeService.filterTable({ table: "user", filter: { email: email }, db: "FREELANCE_MAD" }).then(userRes => {
                if (userRes && userRes !== "false" && Array.isArray(userRes.data)) {
                    resolve(userRes.data[0])
                } else {
                    resolve("false")
                }
            }).catch(err => resolve("false"))
        })
    }

    const inscription = async () => {
        setLoading(true)
        setIsEmptyValue(false)
        setValidate(false)
        setIsMatch(false)
        setInscritError(false)
        const exist = await getUserByEmail()
        if (exist) {
            setValidate(true)
            setLoading(false)
        } else {
            if (nom === "" || prenom === "" || email === "" || password === "" || passwordVerif === "") {
                setIsEmptyValue(true)
                setLoading(false)
            } else {
                if (password !== passwordVerif) {
                    setIsMatch(true)
                    setLoading(false)
                } else {
                    let data = {
                        first_name: prenom,
                        last_name: nom,
                        password: password,
                        email: email,
                        mobile: "123"
                    }
                    apiNodeService.insertTableData({ db: "FREELANCE_MAD", table: "user", data: data }).then(res => {
                        if (res && res.status === 200) {
                            navigate("/login")
                            setLoading(false)
                        } else {
                            console.log("erreur")
                            setValidate(true)
                            setInscritError(true)
                            setLoading(false)
                        }
                    })
                }
            }
        }
    }

    return (
        <div>
            <MuiBackdrop open={loading} text={"Chargement..."} />
            <div className="account-container">

                <div className="right-container right-loaded" style={{ top: 0, marginTop: 120, borderRadius: 10, zIndex: 100 }}>
                    <div className="sign-list" id="sign-in" style={{ display: "block" }}>
                        <a className="left-logo">
                            <img src={oalogo} alt="" />
                        </a>
                        <h5 className="mt-4">Création compte Freelance-Mada</h5>
                        <div className="input-list" style={{ marginTop: 30 }}>
                            <div className="input-list-con first-init" style={{ display: "block" }}>
                                <div className="row align-items-center justify-content-around">
                                    <div className="col-md-6">
                                        <TextField className="inupt-login" fullWidth value={prenom} onChange={(e) => {
                                            setPrenom(e.target.value)
                                        }} size="small" id="outlined-basic" label="Prénom" variant="outlined" />
                                    </div>
                                    <div className="col-md-6">
                                        <TextField className="inupt-login" fullWidth value={nom} onChange={(e) => {
                                            setNom(e.target.value)
                                        }} size="small" id="outlined-basic" label="Nom" variant="outlined" />

                                    </div>
                                </div>
                                <div className="row mt-4 align-items-center justify-content-around">
                                    <div className="col-md-12">
                                        <TextField className="inupt-login" fullWidth value={email} onChange={(e) => {
                                            setEmail(e.target.value)
                                        }} size="small" id="outlined-basic" label="Email" variant="outlined" />
                                    </div>
                                </div>
                                <div className="row mt-4 align-items-center justify-content-around">

                                    <div className="col-md-6">
                                        <TextField className="inupt-login" fullWidth type="password" value={password} onChange={(e) => {
                                            setPassword(e.target.value)
                                        }} size="small" id="outlined-basic" label="Mot de passe" variant="outlined" />

                                    </div>
                                    <div className="col-md-6">
                                        <TextField className="inupt-login" fullWidth type="password" value={passwordVerif} onChange={(e) => {
                                            setPasswordVerif(e.target.value)
                                        }} size="small" id="outlined-basic" label="Confirme mot de passe" variant="outlined" />
                                    </div>
                                </div>
                                <div align="center" style={{ marginTop: 30 }}>
                                    <MuiButton variant="contained" color="primary" size="large"
                                        style={{ textTransform: "none", fontWeight: 800, height: 46, width: 180, fontSize: "1.1rem" }}
                                        endIcon={<LoginIcon style={{ color: "#fff" }} />}
                                        onClick={() => {
                                            inscription()
                                        }}
                                        fullWidth={true}
                                    >
                                        S'inscrir
                                    </MuiButton>
                                </div>

                                {validate === true && <span className="mt-1" style={{ color: "red", marginLeft: 2 }}> Email existe !</span>}
                                {isEmptyValue === true && <span className="mt-1" style={{ color: "red", marginLeft: 2 }}> Veuillez remplir les champs *</span>}
                                {isMatch === true && <span className="mt-1" style={{ color: "red", marginLeft: 2 }}>Veuillez vérifier le mot de passe !</span>}
                                {inscritError === true && <span className="mt-1" style={{ color: "red", marginLeft: 2 }}>Erreur survenue !</span>}

                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div className="area">
                <ul className="circles">
                    {
                        ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"].map((item, key) => (
                            <li key={key} />
                        ))
                    }
                </ul>
            </div>

        </div>
    )

}