exports.categories = [ 
    { label: 'AutoCAD' },
    { label: 'Rhino 3D' },
    { label: 'ArchiCAD' },
    { label: 'MicroStation' },
    { label: 'Vectorworks Architect' },
    { label: 'CATIA' },
    { label: 'BricsCAD' },
    { label: 'Chief Architect' },
    { label: 'Fusion 360 autodesk' },
    { label: '3ds Max' },
    { label: 'Lumion' },
    { label: 'V-Ray' },
    { label: 'Blender' },
    { label: 'Twinmotion,Epic Games' },
    { label: 'Homestyler (online)' },
    { label: 'Adobe Illustrator' },
    { label: 'CorelDRAW' },
    { label: 'Photoshop' },
    { label: 'Microsoft Teams' },
    { label: 'Slack' },
    { label: 'Asana' },
    { label: 'Trello' },
    { label: 'Primavera P6' },
    { label: 'Adobe InDesign' },
    { label: 'Sketch' },
    { label: 'Power BI' },
    { label: 'Gestion de Projets BIM' },
    { label: 'Autodesk BIM 360' },
    { label: 'Procore' },
    { label: 'ONFLY de BIM&Co' },
    { label: 'Trimble Connect' },
    { label: 'Bentley Systems ProjectWise' },
    { label: 'Graphisoft BIMcloud' },
    { label: 'Newforma Project Center' },
    { label: 'Revit' },
    { label: 'Model Checker' },
    { label: 'BIMcollab' },
    { label: 'Navisworks' },
    { label: 'Tekla BIMsight' },
    { label: 'Assemble Systems' },
    { label: 'dRofus' },
    { label: 'FME (Feature Manipulation Engine)' },
    { label: 'Open BIM ToolBox' },
    { label: 'Dynamo' },
    { label: 'Unity Reflec' },
    { label: 'Autodesk CFD' },
    { label: 'IES VE' },
    { label: 'Bluebeam Revu' },
    { label: 'Acone' },
    { label: 'ARCHIBUS' },
    { label: 'IBM Maximo' },
    { label: 'Chef de projet BIM' },
    { label: 'Coordinateur BIM' },
    { label: 'Contrôle de Qualité et Vérification' },
    { label: 'Dessinateur BIM' },
    { label: 'Perspectiviste 3' },
    { label: 'Projeteur BIM' },
    { label: 'Pluralsight' },
    { label: 'OpenStudio' },
    { label: 'DesignBuilder' },
    { label: 'Relux' },
    { label: 'Dialux' },
    { label: 'AGi32' },
    { label: 'LumenRT' },
    { label: 'Luxicon' },
    { label: 'Photopia' },
    { label: 'OxyTech LiTGHT' },
    { label: 'AutoCAD Electrical' },
    { label: 'EPLAN Electric P8' },
    { label: 'SolidWorks Electrical' },
    { label: 'SKM Power Tools' },
    { label: 'Cinema 4D' },
    { label: 'Rhinoceros 3D (Rhino)' },
    { label: 'ZBrush' },
    { label: 'AutoCAD Civil 3D' },
    { label: 'SketchUp' },
    { label: 'PowerPoint' },
    { label: 'Excel' },
    { label: 'Microsoft word' },
    {"label": "Développeur web"},
    {"label": "Graphiste freelance"},
    {"label": "Consultant en marketing digital"},
    {"label": "Traducteur en ligne"},
    {"label": "Rédacteur web"},
    {"label": "Assistant virtuel"},
    {"label": "Designer UX/UI"},
    {"label": "Analyste de données"},
    {"label": "Expert en référencement SEO"},
    {"label": "Administrateur système à distance"},
    {"label": "Spécialiste en e-commerce"},
    {"label": "Comptable"},
    {"label": "Expert comptable"},
    {"label": "Développeur"},
    {"label": "Ingénieur"},
    {"label": "Finance"},
    {"label": "Architecte"},
    {"label": "Designer"},
    {"label": "Médecin"},
    {"label": "Avocat"},
    {"label": "Enseignant"},
    {"label": "Journaliste"},
    {"label": "Artiste"},
    {"label": "Développeur d'applications Web"},
    {"label": "Designer de sites Web"},
    {"label": "Gestionnaire de médias sociaux"},
    {"label": "Rédacteur de contenu en ligne"},
    {"label": "Traducteur freelance"},
    {"label": "Consultant en marketing numérique"},
    {"label": "Spécialiste en référencement (SEO)"},
    {"label": "Développeur d'applications mobiles"},
    {"label": "Testeur de logiciels"},
    {"label": "Graphiste indépendant"},
    {"label": "Professeur/tuteur en ligne"},
    {"label": "Gestionnaire de communauté en ligne"},
    {"label": "Expert en sécurité informatique"},
    {"label": "Développeur de jeux vidéo indépendant"},
    {"label": "Créateur de contenu YouTube"},
    {"label": "Blogueur professionnel"},
    {"label": "Community manager"},
    {"label": "Consultant en commerce électronique"},
    {"label": "Gestionnaire de projet en ligne"},
    {"label": "Spécialiste en publicité en ligne"},
    {"label": "Développeur de logiciels freelance"},
    {"label": "Graphiste numérique"},
    {"label": "Assistant administratif à distance"},
    {"label": "Écrivain indépendant"},
    {"label": "Correcteur/relecteur freelance"},
    {"label": "Spécialiste en relations publiques en ligne"},
    {"label": "Conseiller financier virtuel"},
    {"label": "Formateur en ligne"},
    {"label": "Odoo"},
    {"label": "Wordpress"},
    {"label": "Conception de plans architecturaux"},
    {"label": "Modélisation 3D de bâtiments"},
    {"label": "Consultant en structure de bâtiment"},
    {"label": "Estimateur de coûts de construction en ligne"},
    {"label": "Ingénieur en simulation de bâtiments"},
    {"label": "Expert en conception de structures"},
    {"label": "Dessinateur technique en ligne"},
    {"label": "Spécialiste en gestion de projet de construction"},
    {"label": "Expert-comptable freelance"},
    {"label": "Auditeur financier en ligne"},
    {"label": "Consultant en fiscalité virtuelle"},
    {"label": "Analyste financier indépendant"},
    {"label": "Réviseur comptable à distance"},
    {"label": "Spécialiste en comptabilité des petites entreprises"},
    {"label": "Conseiller en gestion financière en ligne"},
    {"label": "Formateur en comptabilité et finance"},
    {label:"Directeur(trice) Logistique"},
    {label:"Responsable de la Stratégie Logistique"},
    {label:"Chef de Projet Logistique"},
    {label:"Coordinateur(trice) Logistique"},
    {label:"Coordinateur(trice) de Transport International"},
    {label:"Coordinateur(trice) des Opérations de Douane"},
    {label:"Responsable des Transports"},
    {label:"Gestionnaire de Flotte"},
    {label:"Responsable des Affrètements"},
    {label:"Opérateur de Transport"},
    {label:"Conducteur(trice) Routier(ère) International(e)"},
    {label:"Agent de Transit"},
    {label:"Responsable de la Planification des Transports"},
    {label:"Responsable d'Entrepôt"},
    {label:"Chef de Quai"},
    {label:"Gestionnaire de Stock"},
    {label:"Magasinier(ère)"},
    {label:"Opérateur(trice) Logistique"},
    {label:"Préparateur(trice) de Commandes"},
    {label:"Cariste"},
    {label:"Responsable des Affaires Douanières"},
    {label:"Déclarant(e) en Douane"},
    {label:"Responsable de la Conformité"},
    {label:"Agent de Douane"},
    {label:"Inspecteur(trice) des Douanes"},
    {label:"Conseiller(ère) en Commerce International"},
    {label:"Responsable des Systèmes d'Information Logistiques"},
    {label:"Analyste Logistique"},
    {label:"Chef de Projet Systèmes d'Information"},
    {label:"Technicien(ne) en Logistique Informatique"},
    {label:"Gestionnaire de Bases de Données Logistiques"},
    {label:"Responsable du Service Client"},
    {label:"Responsable des Relations Clientèle"},
    {label:"Responsable de la Satisfaction Client"},
    {label:"Agent de Support Logistique"},
    {label:"Agent de Service Client"},
    {label:"Support Technique Logistique"}
]