import TaskAltIcon from '@mui/icons-material/TaskAlt';

const Payment_Success = () => {

    return (
        <div className="d-flex align-items-center p-4" style={{ gap: 10,marginTop:60 }}>
            <TaskAltIcon color="success" fontSize="large" />
            <span className="span-title-freemada">Votre paiement est bien effectué avec succès.</span>
        </div>
    )
}
export default Payment_Success;