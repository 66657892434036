import React from "react";
import { useNavigate } from "react-router-dom";
import { apiNodeService } from "../../../provider/apiNodeService";
import "./login_build.css"
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import { Button as MuiButton } from "@mui/material";
import Divider from '@mui/material/Divider';
import { GoogleLogin } from 'react-google-login';

const Login_Build = () => {

    const navigate = useNavigate()

    const [loading, setLoading] = React.useState(false);
    const [email, setEmail] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [errorMsg, setErrorMsg] = React.useState("");
    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const googleLogin = async (response) => {
        console.log(response)
        setLoading(true)
        if (response && response.Ca) {
          const user = await getUserByEmail1(response.profileObj.email)
          console.log(user)
          if (user) {
            localStorage.setItem("token", response.tokenId)
            localStorage.setItem("id", user.id)
            localStorage.setItem("role", user.role)
            localStorage.setItem("email", response.profileObj.email)
            localStorage.setItem("first_name", response.profileObj.givenName)
            localStorage.setItem("last_name", response.profileObj.familyName)
            localStorage.setItem("photo",user.photo ? user.photo.data : response.profileObj.imageUrl)
            // if (response.profileObj.email === "jhlauret@gmail.com" || response.profileObj.email === "smaniotto.gabriel@gmail.com" || response.profileObj.email === "k.kaabi1994@gmail.com") {
            //     navigate("/rh-employe?user="+user.id)
            // } else {
            //     navigate("/profil-build?user="+user.id)
            // }
            navigate("/rh-employe?user="+user.id)

          } else {
            navigate("/build-mada-signup?email="+response.profileObj.email+"&prenom="+response.profileObj.givenName+"&nom="+response.profileObj.familyName)
          }
        }
      }

      const getUserByEmail1 = async (email) => {
        let emailLower = email.toLowerCase()
        return new Promise(resolve => {
          apiNodeService.filterTable({table:"user_build",filter:{email:emailLower},db:"FREELANCE_MAD"}).then(userRes => {
            console.log(userRes)
            if (userRes && userRes !== "false" && Array.isArray(userRes.data)) {
              console.log(userRes.data[0])
              resolve(userRes.data[0])
            } else {
              resolve("false")
            }
          }).catch(err => resolve("false"))
        })
      }

    const getUserByEmail = async () => {
        setLoading(true)
        return new Promise(resolve => {
            // apiNodeService.filterTable({ table: "user_build", filter: { email: email }, db: "FREELANCE_MAD" }).then(userRes => {
            //     console.log(userRes)
            //     if (userRes && userRes !== "false" && Array.isArray(userRes.data)) {
            //         console.log(userRes.data[0])
            //         if (userRes.data[0]) {
            //             if (userRes.data[0].password === password) {
            //                 localStorage.setItem("email", userRes.data[0].email)
            //                 localStorage.setItem("first_name", userRes.data[0].first_name)
            //                 localStorage.setItem("last_name", userRes.data[0].last_name)
            //                 localStorage.setItem("id", userRes.data[0].id)
            //                 localStorage.setItem("role", userRes.data[0].role)
            //                 localStorage.setItem("photo", userRes.data[0].photo ? userRes.data[0].photo.data : null)
            //                 setErrorMsg("")
            //                 // if (userRes.data[0].email === "jhlauret@gmail.com" || userRes.data[0].email === "smaniotto.gabriel@gmail.com" || userRes.data[0].email === "k.kaabi1994@gmail.com") {
            //                 //     navigate("/rh-employe?user="+userRes.data[0].id)
            //                 // } else {
            //                 //     navigate("/profil-build?user="+userRes.data[0].id)
            //                 // }
            //                 navigate("/rh-employe?user="+userRes.data[0].id)
            //             } else {
            //                 setErrorMsg("Vérifiez votre mot de passe !")
            //             }

            //             setLoading(false)
            //         } else {
            //             setErrorMsg("Vérifiez votre email !")
            //             setLoading(false)
            //         }

            //         resolve(userRes.data[0])
            //     } else {
            //         resolve("false")
            //         setErrorMsg("Vérifiez votre email !")
            //     }
            // }).catch(err => resolve("false"))
            let data = { email: email.toLowerCase(), password: password ,table:'user_build' }
            apiNodeService.loginFreelance(data).then((result) => {
                console.log(result)
                setLoading(false)
                if (result.data && result.data.token) {
                  console.log(result.data)
                  localStorage.setItem("token", result.data.token)
                  localStorage.setItem("email", result.data.email)
                    localStorage.setItem("first_name", result.data.first_name)
                    localStorage.setItem("last_name", result.data.last_name)
                    localStorage.setItem("id", result.data.id)
                    localStorage.setItem("role", result.data.role)
                    setErrorMsg("")
                    localStorage.setItem("photo", result.data.photo ? result.data.photo.data : null)
                    navigate("/rh-employe?user="+result.data.id)
                    setLoading(false)
                }
                else {
                    setErrorMsg("Vérifiez votre email et mot de passe !")
                }
              }).catch(err => {
                setLoading(false)
              })
        })
    }

    return (
        <div style={{ overflowX: 'hidden' }}>
            <div className="row align-items-center">
                <div className="col-md-6">
                <span className="title-build" style={{ fontSize: 36, color: '#000', fontWeight: 700 }}>Build.</span>
                <span className="title-build1" style={{ fontSize: 36, color: '#214dcb', fontWeight: 700 }}>Mada</span>
                    <div className="d-flex flex-column section1-login">
                        <div className="d-flex align-items-center justify-content-center">
                            <div className="d-flex flex-column">
                                <span className="conect-span mb-2">Se connecter</span>
                            </div>
                        </div>
                        <div style={{ gap: 60 }} className="d-flex align-items-center justify-content-center mb-4">
                            <img height={45} width={75} alt="login" src="/linkedin.png" />
                            <GoogleLogin
                            clientId={process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID}
                            render={renderProps => (
                            <img height={45} style={{cursor:'pointer'}} width={75} alt="login" src="/google.png" onClick={renderProps.onClick}/>

                            )}
                            buttonText="Login"
                            onSuccess={googleLogin}
                            onFailure={googleLogin}
                            cookiePolicy={'single_host_origin'}
                        />
                            <img height={45} width={75} alt="login" src="/facebook.png" />
                        </div>
                        <div className="d-flex align-items-center justify-content-center">
                            <Divider style={{ width: '22rem', color: '#1976d2', fontWeight: 700 }}>OU</Divider>
                        </div>
                        <div className="d-flex align-items-center justify-content-center">
                            <div className="d-flex flex-column">
                                <span className="span-title mb-1">Email*</span>
                                <TextField style={{ width: '22rem' }} size="small"
                                    value={email} 
                                    onChange={(e) => {
                                        setEmail(e.target.value)
                                    }} 
                                    id="outlined-basic" variant="outlined" />
                            </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-center mt-3">
                            <div className="d-flex flex-column">
                                <span className="span-title mb-1">Mot de passe*</span>
                                <FormControl style={{ width: '22rem' }} variant="outlined">
                                    <OutlinedInput
                                        id="outlined-adornment-password"
                                        type={showPassword ? 'text' : 'password'}
                                        size="small"
                                        variant="filled"
                                        value={password} 
                                        onChange={(e) => {
                                            setPassword(e.target.value)
                                        }}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                            </div>
                        </div>
                        <div className="mt-4 mb-1 d-flex align-items-center justify-content-center">
                            <MuiButton variant="contained" color="primary" size="large"
                                style={{ textTransform: "none", fontWeight: 600, height: 46, fontSize: "1.1rem", width: '22rem' }}
                                onClick={() => {
                                    getUserByEmail()
                                }}
                                fullWidth={true}
                            >
                                Valider
                            </MuiButton>
                        </div>
                        
                        <div className="mt-2 d-flex align-items-center justify-content-center">
                            <div style={{ width: '22rem' }} className="d-flex justify-content-between">
                                <span onClick={(e)=>{navigate('/build-mada-signup')}} className="span-footer mb-1">Pas encore de compte ?</span>
                                <span onClick={(e)=>{navigate('/forgot-password')}} className="span-footer mb-1">Mot de passe oublié ?</span>

                            </div>
                        </div>
                        <div className="mb-2 d-flex align-items-center justify-content-center">
                            <div style={{ width: '22rem' }}>
                                <span className="mt-3 mb-3" style={{color:'red'}}>{errorMsg}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 login-section2 p-0">
                </div>
            </div>
        </div>
    )
}
export default Login_Build;
