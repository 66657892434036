import React, { useEffect, useRef, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import { Chip } from '@material-ui/core'
import categoriesMetier from "../../data/categoriesMetier";
import Autocomplete from '@mui/material/Autocomplete';
import { apiNodeService } from "../../provider/apiNodeService";
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Typography from "@mui/material/Typography";
import categoriesLogiciel from "../../data/categoriesLogiciel";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import moment from "moment";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import utilFunctions from "../../tools/functions";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import "./Experience.css"
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';

const Formation_Profil = (props) => {

    const [freelancer, setFreelancer] = React.useState(null)
    const [title, setTitle] = React.useState('')
    const [description, setDescription] = React.useState('')
    const [ecole, setEcole] = React.useState('')
    const [selectedValuesMetiers, setSelectedMetiers] = React.useState([]);
    const [selectedValuesLogiciel, setSelectedLogiciel] = React.useState([]);
    const [domaine, setDomaine] = React.useState('')
    const [lieuFormation, setLieuFormation] = React.useState('')
    const [dateDeb, setDateDeb] = React.useState(dayjs(''))
    const [dateFin, setDateFin] = React.useState(dayjs(''))
    const [checkedFormation, setCheckedFormation] = React.useState(false)
    const [isValidDate, setIsValidDate] = React.useState(false)
    const fileInputRef = useRef(null);
    const [selectedFile, setSelectedFile] = useState("");
    const [fileUid, setFileUid] = useState("");
    const [fileName, setFileName] = useState("");
    const [options, setOptions] = useState(categoriesLogiciel.categoriesLogiciel.map((option) => option.label));
    const [options1, setOptions1] = useState(categoriesMetier.categoriesMetier.map((option) => option.label));

    const handleInputChange = (event) => {
        const newValue = event.target.value;
        if (newValue.trim() !== '' && !options.includes(newValue)) {
            // Si la nouvelle valeur n'existe pas dans la liste, l'ajouter aux options
            setOptions([...options, newValue]);
        }
    };

    const handleInputChange1 = (event) => {
        const newValue = event.target.value;
        if (newValue.trim() !== '' && !options1.includes(newValue)) {
            // Si la nouvelle valeur n'existe pas dans la liste, l'ajouter aux options
            setOptions1([...options1, newValue]);
        }
    };

    const handleChange2 = (event) => {
        setCheckedFormation(event.target.checked);
    };

    useEffect(() => {
        getUserByEmail()
    }, [props.formationUid, props.handleUpdateUid]);

    const resetValues = () => {
        setDateDeb('')
        setDateFin('')
        setDescription('')
        setLieuFormation('')
        setSelectedLogiciel([])
        setSelectedMetiers([])
        setDomaine('')
        setTitle('')
        setEcole('')
        setIsValidDate(false)
    }

    const getUserByEmail = async () => {
        resetValues()
        return new Promise(resolve => {
            apiNodeService.filterTable({ table: "employe_odoo", filter: { email: localStorage.getItem('email') }, db: "FREELANCE_MAD" }).then(userRes => {
                if (userRes && userRes !== "false" && Array.isArray(userRes.data)) {
                    setFreelancer(userRes.data[0])
                    if (props.formationUid !== '') {
                        let data = userRes.data[0].formations.filter(item => item.uid === props.formationUid)
                        if (data[0]) {
                            setDateDeb(data[0].date_debut || '')
                            setDateFin(data[0].date_fin || '')
                            setDescription(data[0].description || '')
                            setLieuFormation(data[0].lieu_formation || '')
                            setSelectedLogiciel(data[0].logiciels || [])
                            setSelectedMetiers(data[0].metiers || [])
                            setDomaine(data[0].domaine || '')
                            setTitle(data[0].title || '')
                            setEcole(data[0].ecole || '')
                            setCheckedFormation(data[0].checked_formation || false)
                            setFileUid(data[0].docUid || '')
                            setFileName(data[0].doc_name || '')
                        }
                    } else {
                        resetValues()
                    }
                } else {
                    resolve("false")
                }
            }).catch(err => resolve("false"))
        })
    }

    const addToCompetences = () => {
        let oldFormation = freelancer.formations;
        let formation = {
            title: title,
            ecole: ecole,
            description: description,
            checked_formation: checkedFormation,
            domaine: domaine,
            lieu_formation: lieuFormation,
            date_debut: dateDeb,
            date_fin: dateFin ? dateFin : '',
            logiciels: selectedValuesLogiciel,
            metiers: selectedValuesMetiers,
            uid: utilFunctions.getUID(),
            docUid: (selectedFile !== "") ? utilFunctions.getUID() : null,
            doc_name:fileName
        }

        let testDate = dateFin ? moment(dateFin).diff(moment(dateDeb)) : 1;
        console.log(testDate)
        if (testDate < 1) {
            setIsValidDate(true)
        } else {
            setIsValidDate(false)
            let newFormation = []
            if (formation.docUid) {
                addCertif(formation.docUid)
            }
            if (oldFormation) {
                newFormation = oldFormation
                newFormation.push(formation)
                updateProfile1(newFormation)
            } else {
                newFormation.push(formation)
                updateProfile1(newFormation)
            }
        }
    };

    const addCertif = (uid) => {
        let data = {
            file_type: "certif",
            name: selectedFile.alt,
            uid: uid,
            type: selectedFile.ext,
            b64: selectedFile,
            created_by: localStorage.getItem('email')
        }
        apiNodeService.insertTableData({ db: "FREELANCE_MAD", table: "documents", data: data }).then(res => {
            console.log(res)
            if (res && res.status === 200) {
                console.log(true)
            } else {
                console.log(false)
            }
        })
    }

    const updateToCompetences = () => {
        let oldFormation = freelancer.formations;
        let formation = {
            title: title,
            ecole: ecole,
            checked_formation: checkedFormation,
            description: description,
            domaine: domaine,
            lieu_formation: lieuFormation,
            date_debut: dateDeb,
            date_fin: dateFin ? dateFin : '',
            logiciels: selectedValuesLogiciel,
            metiers: selectedValuesMetiers,
            uid: props.formationUid,
            docUid: (selectedFile !== "") ? utilFunctions.getUID() : fileUid,
            doc_name:fileName
        }
        const updatedData = oldFormation.map(item => {
            if (item.uid === props.formationUid) {
                return formation;
            }
            return item;
        });
        let testDate = dateFin ? moment(dateFin).diff(moment(dateDeb)) : 1;
        if (testDate < 1) {
            setIsValidDate(true)
        } else {
            setIsValidDate(false)
            if (formation.docUid) {
                addCertif(formation.docUid)
            }
            updateProfile1(updatedData)
        }
    }

    const updateProfile1 = (value) => {
        props.setLoading(true)
        let data = {}
        data.formations = value
        props.onCloseDialogFormation()
        apiNodeService.updateTableData({ db: "FREELANCE_MAD", table: "employe_odoo", id: freelancer.id, data: data }).then(resAdd => {
            if (resAdd && resAdd.status === 200) {
                props.setLoading(false)
                props.handleUpdateUser()
            } else {
                console.log("error")
                props.setLoading(false)
                props.onCloseDialogFormation()
            }
        })
    }

    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        console.log(file)
        if (file) {
            let thumbnail = await utilFunctions.toBase64(file)
            setFileName(file.name)
            setSelectedFile(thumbnail);
            console.log("Selected file:", thumbnail);
        } else {
            console.log("No file selected!");
        }
    };


    return (
        <>
            <Dialog
                open={props.openFormationDialog}
                aria-labelledby="form-dialog-title"
                fullWidth={true}
                maxWidth="md"
                onClose={(e) => {
                    props.onCloseDialogFormation()
                }}
            >
                <DialogTitle id="form-dialog-title" style={{ paddingBottom: 1 }}>
                    <div className='d-flex align-items-center' style={{ gap: 10 }}>
                        {props.isEditFormation === true ? <EditOutlinedIcon style={{ color: "#1565C0" }} /> : <AddIcon style={{ color: "#1565C0" }} />}
                        <span style={{ color: '#1565C0', fontWeight: 700 }}>{props.isEditFormation === true ? "Modification de la formation" : "Informations sur la formation"} </span>
                    </div>
                    <IconButton
                        aria-label="close"
                        style={{
                            position: 'absolute',
                            right: 5,
                            top: 5,
                            color: '#c0c0c0'
                        }}
                        onClick={() => {
                            props.onCloseDialogFormation()
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <hr style={{ color: "#1565C0" }} />
                </DialogTitle>
                <DialogContent style={{ overflowY: "inherit" }}>
                    <>
                        <span style={{ fontSize: 14, fontWeight: 700, color: 'grey', marginLeft: 24 }}>* = Champs obligatoires</span>
                        <div className='row py-1 px-4'>
                            <div className='col-md-6'>
                                <span className="span-title">Nom de la formation / Diplôme*</span>
                                <TextField fullWidth className='mt-1' size="small" id="outlined-basic3" variant="outlined"
                                    value={title}
                                    placeholder="Ex: Licence"
                                    onChange={(e) => {
                                        setTitle(e.target.value)
                                    }} />
                                <div className="d-flex justify-content-end">
                                    <span style={{ fontSize: 12, color: title.length > 100 ? 'red' : "#b1b1b1" }} className="span-title mt-1">{title.length} / 100 caractères</span>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <span className="span-title">École / Organisme de formation*</span>
                                <TextField fullWidth className='mt-1' size="small" id="outlined-basic3" variant="outlined"
                                    value={ecole}
                                    placeholder="Ex: Université paris V"
                                    onChange={(e) => {
                                        setEcole(e.target.value)
                                    }} />
                                <div className="d-flex justify-content-end">
                                    <span style={{ fontSize: 12, color: ecole.length > 100 ? 'red' : "#b1b1b1" }} className="span-title mt-1">{ecole.length} / 100 caractères</span>
                                </div>
                            </div>
                        </div>

                        <div className='row py-1 px-4'>
                            <div className='col-md-12'>
                                <span className="span-title">Courte description de la formation*</span>
                                <TextField fullWidth className='mt-1' size="small" id="outlined-basic3" variant="outlined"
                                    value={description}
                                    multiline
                                    rows={3}
                                    onChange={(e) => {
                                        setDescription(e.target.value)
                                    }} />
                                <div className="d-flex justify-content-end">
                                    <span style={{ fontSize: 12, color: description.length > 250 ? 'red' : "#b1b1b1" }} className="span-title mt-1">{description.length} / 250 caractères</span>
                                </div>
                            </div>
                            <span className="span-title mt-1" style={{ fontSize: 12, color: "#4b4b4b", fontWeight: 500 }}>Pour mettre toutes les chances de votre coté, détaillez un maximum tous ce que vous avez effectué lors de cette formation.</span>
                        </div>
                        <div className='row py-1 px-4'>
                            <div className='col-md-6'>
                                <span className="span-title">Domaine d’études</span>
                                <TextField fullWidth className='mt-1' size="small" id="outlined-basic3" variant="outlined"
                                    value={domaine}
                                    placeholder="Ex: Économie"
                                    onChange={(e) => {
                                        setDomaine(e.target.value)
                                    }} />
                            </div>
                            <div className='col-md-6'>
                                <span className="span-title">Lieu</span>
                                <TextField fullWidth className='mt-1' size="small" id="outlined-basic3" variant="outlined"
                                    value={lieuFormation}
                                    onChange={(e) => {
                                        setLieuFormation(e.target.value)
                                    }} />
                                <div className="d-flex justify-content-start mt-1">
                                    <span className="span-title mb-2" style={{ fontSize: 12, color: "#b1b1b1", fontWeight: 500 }}>Si la formation est en télétravail, mettre le lieu de l’entreprise qui à mise en place la formation.</span>
                                </div>
                            </div>
                        </div>
                        <div className="px-4 d-flex align-items-center">
                            <Checkbox
                                checked={checkedFormation}
                                onChange={handleChange2}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                            <span style={{ fontSize: 14, fontWeight: 600, color: '#616161' }}>Je poursuis encore cette formation</span>
                        </div>
                        <div className="row px-4">
                            <div className='col-md-6 date-section1'>
                                <span className="span-title">Date de commencement*</span>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['DatePicker']}>
                                        <DatePicker format="DD/MM/YYYY" value={dayjs(dateDeb)} maxDate={dayjs(moment())} onChange={(e) => {
                                            if (e) {
                                                setDateDeb(moment(e.$d).format('YYYY-MM-DD'))
                                            }

                                        }} />
                                    </DemoContainer>
                                </LocalizationProvider>
                            </div>
                            {checkedFormation === false &&
                                <div className='col-md-6 date-section1'>
                                    <span className="span-title">Date de fin</span>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer components={['DatePicker']}>
                                            <DatePicker format="DD/MM/YYYY" minDate={dayjs(dateDeb)} maxDate={dayjs(moment())} value={dayjs(dateFin)} onChange={(e) => {
                                                if (e) {
                                                    setDateFin(moment(e.$d).format('YYYY-MM-DD'))
                                                }
                                            }} />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                    {isValidDate === true && <span style={{ color: "red", fontWeight: 500, fontSize: 13 }}>Date de fin doit être superieur à la date de commencement </span>}
                                </div>
                            }
                        </div>
                        <div className='d-flex flex-column p-4'>
                            <span style={{ color: "#616161", fontWeight: 600 }}>Compétences informatiques requises</span>
                            <Autocomplete
                                multiple
                                className='mb-4 mt-2'
                                id="tags-outlined"
                                variant="outlined"
                                value={selectedValuesLogiciel}
                                options={options}
                                defaultValue={selectedValuesLogiciel}
                                onChange={(event, newValue) => {
                                    setSelectedLogiciel(newValue)
                                }}
                                renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                        <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                                    ))
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        size="small"
                                        label="Ajouter des compétences informatiques"
                                        placeholder="Compétences informatiques"
                                        onChange={handleInputChange}
                                    />
                                )}
                            />
                            <span style={{ color: "#616161", fontWeight: 600 }}>Compétences métier requises</span>
                            <Autocomplete
                                className='mt-2'
                                multiple
                                id="tags-outlined"
                                value={selectedValuesMetiers}
                                options={options1}
                                defaultValue={selectedValuesMetiers}
                                onChange={(event, newValue) => {
                                    setSelectedMetiers(newValue)
                                }}
                                renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                        <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                                    ))
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        size="small"
                                        label="Ajouter des compétences métier"
                                        placeholder="Compétences métier"
                                        onChange={handleInputChange1}
                                    />
                                )}
                            />


                        </div>
                        <div className="d-flex align-items-center justify-content-between">
                            <div className="d-flex flex-column px-4" style={{ gap: 10 }}>
                                <div className="d-flex align-items-center" style={{ gap: 10 }}>
                                    <Checkbox
                                        className="p-0"
                                        checked={checkedFormation}
                                        onChange={handleChange2}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                    <span style={{ fontSize: 14, fontWeight: 600, color: '#616161' }}>Mention(s) / Certification(s) obtenue(s)</span>
                                </div>
                              { checkedFormation === true &&
                              <>
                                {fileUid === "" ?
                                    <div className="upload-formation align-items-center p-2" onClick={handleButtonClick}>
                                        <div className="d-flex flex-column justify-content-center">

                                            {selectedFile !== "" ?
                                                <>
                                                    <UploadFileOutlinedIcon className="align-self-center" style={{ width: 80, height: 80 }} />
                                                    <span style={{ fontWeight: 600, textAlign: 'center', fontSize: 14 }}>{fileName}</span>
                                                </>
                                                :
                                                <>
                                                    <FileUploadOutlinedIcon className="align-self-center" style={{ width: 70, height: 70 }} />
                                                    <span style={{ fontWeight: 600, textAlign: 'center', fontSize: 14 }}>Cliquez ici pour ajouter un certificat, une mention ou tout document pouvant prouver ou mettre en valeur cette formation</span>
                                                </>
                                            }
                                        </div>
                                    </div>
                                    :
                                    <div className="upload-formation align-items-center p-2" onClick={handleButtonClick}>
                                        <div className="d-flex flex-column justify-content-center">
                                                    <UploadFileOutlinedIcon className="align-self-center" style={{ width: 80, height: 80 }} />
                                                    <span style={{ fontWeight: 600, textAlign: 'center', fontSize: 14 }}>{"Cliquez ici pour charger un certificat ou preuve de mention"}</span>
                                        </div>
                                    </div>
                                }
                                </>
                                }
                                <input
                                    type="file"
                                    accept='.jpg, .png, .jpeg'
                                    ref={fileInputRef}
                                    style={{ display: 'none' }}
                                    onChange={handleFileChange}
                                />
                            </div>
                        </div>
                    </>
                </DialogContent>
                <DialogActions style={{ padding: "10px 55px 20px 35px", justifyContent: 'end' }}>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={(e) => {
                            props.onCloseDialogFormation()
                        }}
                        style={{ textTransform: 'none', fontWeight: 600 }}
                    >
                        Annuler
                    </Button>
                    <Button
                        variant="contained"
                        disabled={dateDeb === '' || title === '' || description === '' || ecole === '' ? true : false}
                        onClick={(e) => {
                            props.isEditFormation === false ? addToCompetences() : updateToCompetences()
                        }}
                        style={{ textTransform: 'none', color: "#fff", backgroundColor: (dateDeb === '' || title === '' || description === '' || ecole === '') ? "grey" : "#1565C0", minWidth: 180, fontWeight: 600 }}
                    >
                        {props.isEditFormation === false ? "Ajouter formation" : "Valider et mettre à jour"}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
export default Formation_Profil;