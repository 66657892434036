import React from "react";
import { useEffect, useState } from "react";
import CheckoutForm from "./CheckoutForm";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import {stripeService} from "../../provider/stripeService";

const endpoint = process.env.REACT_APP_API

export default function StripePaymentComponent(props){

    const [stripePromise, setStripePromise] = useState(null);
    const [clientSecret, setClientSecret] = useState("");

    useEffect(() => {
        fetch(endpoint + "/api/stripe/config").then(async (r) => {
            const { publishableKey } = await r.json();
            setStripePromise(loadStripe(publishableKey,{locale:"fr"}));
        });
    }, []);

    useEffect(() => {

        const intentData = {
            currency:"EUR",
            amount: props.amount
        }
        stripeService.create_payment_intent(intentData).then( async result => {
            setClientSecret(result.clientSecret);
        }).catch( err => {
            console.log(err)
        });
    }, []);

    useEffect(() => {
        console.log("im in child1222")
    }, [props.payToChild]);


    return(
        <div align="center">
            <div style={{display:"flex",justifyContent:"center"}}>
                {clientSecret && stripePromise && (
                    <Elements stripe={stripePromise} options={{ clientSecret }}>
                        <CheckoutForm amount={props.amount}
                                      desc={props.desc}
                                      payToChild={props.payToChild}
                                      image={props.image}
                                      email={props.email}
                                      onPayment={(data,method_data,email) => {
                                          props.onPayment(data,method_data,email)
                                      }}
                        />
                    </Elements>
                )}
            </div>

        </div>
    )
}