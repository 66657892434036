import "../Freelance/Freelance_List.css"
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import React, { useEffect, useRef, useState } from "react";
import { apiNodeService } from "../../provider/apiNodeService";
import VisibilityIcon from '@mui/icons-material/Visibility';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { Button as MuiButton, Chip } from '@material-ui/core'
import IconButton from "@material-ui/core/IconButton";
import MuiBackdrop from "../../components/Loading/MuiBackdrop";
import formations from "../../data/formation"
import ecoles from "../../data/ecole"
import niveauEtude from "../../data/niveauEtude"
import countries from "../../data/countries"
import Alert from '@mui/material/Alert';
import Avatar from '@mui/material/Avatar';
import moment from 'moment';
import Autocomplete from '@mui/material/Autocomplete';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Menu from '@mui/material/Menu';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


const Porteur = () => {

    const [openNewMembreModal, setOpenMembreModal] = React.useState(false)

    const [selectedProducts, setSelectedProducts] = React.useState(null);
    const [loading, setLoading] = React.useState(true);
    const [porteurs, setPorteurs] = React.useState([]);
    const [alertSuccess, setAlertSuccess] = React.useState(false)
    const [alertError, setAlertError] = React.useState(false)
    const [requiredValues, setRequiredValues] = React.useState(false)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openMenu = Boolean(anchorEl);
    const [alertText, setAlertText] = useState("");
    const [prenom, setPrenom] = useState("");
    const [nom, setNom] = useState("");
    const [pays, setPays] = useState("");
    const [type, setType] = useState("");
    const [email, setEmail] = useState("");
    const [mobile, setMobile] = useState("");

    const handleClickMenu = (event, row) => {
        console.log(row)
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };


    useEffect(() => {
        getPorteur()
    }, [])

    const getPorteur = () => {
        setLoading(true)
        apiNodeService.getTableData({ db: "FREELANCE_MAD", table: "porteur" }).then((res) => {
            console.log(res)
            if (res && res.status === 200) {
                setPorteurs([...res.data])
                console.log(res)
                setLoading(false)
            }
            setLoading(false)
        })
    }

    const addPorteur = () => {
        let data = {
            prenom: prenom,
            nom: nom,
            type: type,
            email: email,
            mobile: mobile,
            pays: pays,
            created_at: moment().format("DD/MM/YYYY")
        }
        console.log(data)
        apiNodeService.insertTableData({ db: "FREELANCE_MAD", table: "porteur", data: data }).then(res => {
            console.log(res)
            if (res && res.status === 200) {
                setLoading(false)
                getPorteur()
                setAlertText("Le porteur a bien été enregistré")
                setOpenMembreModal(false)
                setAlertSuccess(true)
                resetValues()
                setTimeout(() => {
                    setAlertSuccess(false)
                }, 5000)
            } else {
                console.log("erreur")
                setAlertText("Le porteur n'a pas pu être enregistré, Veuillez ressayer ou contacter le support technique")
                setOpenMembreModal(false)
                setLoading(false)
                setAlertError(true)
                setTimeout(() => {
                    setAlertError(false)
                }, 5000)
            }
        })
    }

    const resetValues = () => {
        setPrenom("")
        setNom("")
        setPays("")
        setType("")
        setEmail("")
        setMobile("")
    }

    const iconsTemplate = (row) => {
        return (
            <div className="d-flex" style={{ gap: 10 }}>
                <VisibilityIcon onClick={(e => {

                })}
                    style={{ color: "#757575", cursor: 'pointer' }} fontSize="small" />

                <MoreVertIcon fontSize="small"
                    style={{ cursor: 'pointer' }}
                    aria-controls={openMenu ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={openMenu ? 'true' : undefined}
                    onClick={(e) => {
                        handleClickMenu(e, row)
                    }} />
                <Menu
                    key={row.id}
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={openMenu}
                    onClose={handleCloseMenu}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                    PaperProps={{
                        elevation: 0,
                        sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.02))',
                            mt: 1.5,
                            '& .MuiAvatar-root': {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                            },
                            '&:before': {
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                top: 0,
                                right: 10,
                                width: 10,
                                height: 10,
                                bgcolor: 'background.paper',
                                transform: 'translateY(-50%) rotate(45deg)',
                                zIndex: 0,
                            },
                        },
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                    <MenuItem style={{ paddingLeft: 0, gap: 10 }} onClick={handleCloseMenu}>
                        <DeleteOutlineIcon style={{ color: "#EB5757" }} /> <span style={{ color: "#EB5757", fontSize: 12, fontWeight: 500 }}>Supprimer</span> </MenuItem>
                    <hr className="m-0" />
                    <MenuItem style={{ paddingLeft: 0, gap: 10 }} onClick={(e) => {
                        handleCloseMenu()
                    }}><EditIcon style={{ color: "#1565C0" }} /> <span style={{ color: "#1565C0", fontSize: 12, fontWeight: 500 }}>Modifier</span> </MenuItem>

                </Menu>

            </div>
        )
    }

    return (
        <>
            <MuiBackdrop open={loading} text={"Chargement..."} />
            {alertSuccess &&
                <Alert style={{ marginTop: 35 }} variant="filled" severity="success">
                    {alertText}
                </Alert>}
            {alertError &&
                <Alert variant="filled" severity="error">
                    Erreur, Veuillez réessayer plus tard !
                </Alert>}
            <div style={{ marginTop: 30, overflowY: 'auto', maxHeight: '85vh' }} className="p-3">
                <div className="equipe-list p-2">
                    <div className="title-equipe d-flex justify-content-between mb-4">
                        <span>
                        Porteurs de projet
                        </span>
                        <button className="btn-add-member" onClick={(e) => { setOpenMembreModal(true) }}>
                            <strong>+ </strong>Ajouter un porteur
                        </button>
                    </div>
                    <hr style={{ color: "#7293b3" }} className="mb-4" />
                    <DataTable value={porteurs} paginator paginatorLeft rows={10} dataKey="id" tableStyle={{ minWidth: '50rem' }}>
                        <Column field="type" header="Type d’entité"></Column>
                        <Column field="nom" header="Nom"></Column>
                        <Column field="prenom" header="Prénom"></Column>
                        <Column field="mobile" header="Téléphone	"></Column>
                        <Column field="email" header="Email"></Column>
                        <Column field="created_at" header="Date de création"></Column>
                        <Column body={iconsTemplate} headerStyle={{ width: '4rem' }}></Column>
                    </DataTable>
                </div>
            </div>
            <Dialog
                open={openNewMembreModal}
                aria-labelledby="form-dialog-title"
                fullWidth={true}
                maxWidth="sm"

            >
                <DialogTitle disableTypography id="form-dialog-title" style={{ paddingBottom: 1 }}>
                    <Typography style={{ color: '#1565C0', fontWeight: 600 }}>{"Informations générales du porteur"} </Typography>
                    <IconButton
                        aria-label="close"
                        style={{
                            position: 'absolute',
                            right: 5,
                            top: 5,
                            color: '#c0c0c0'
                        }}
                        onClick={() => {
                            setOpenMembreModal(false)
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <hr style={{ color: "#1565C0" }} />
                </DialogTitle>
                <DialogContent style={{ overflowY: "inherit" }}>
                    <>
                        <div className="p-2">


                            <div className="row align-items-center justify-content-around">
                                <div className="col-md-6">
                                    <span className="span-title">Prénom*</span>
                                    <TextField fullWidth value={prenom} onChange={(e) => { setPrenom(e.target.value) }} size="small" id="outlined-basic" variant="outlined" />
                                </div>
                                <div className="col-md-6">
                                    <span className="span-title">Nom*</span>
                                    <TextField fullWidth value={nom} onChange={(e) => { setNom(e.target.value) }} size="small" id="outlined-basic" variant="outlined" />

                                </div>
                            </div>


                            <div className="row mt-1 align-items-center justify-content-around">
                                <div className="col-md-12">
                                    <span className="span-title">Email*</span>
                                    <TextField fullWidth value={email} onChange={(e) => { setEmail(e.target.value) }} size="small" id="outlined-basic" variant="outlined" />

                                </div>

                            </div>

                            <div className="row mt-1 align-items-center justify-content-around">
                                <div className="col-md-12">
                                    <span className="span-title">Téléphone*</span>
                                    <TextField fullWidth value={mobile} onChange={(e) => { setMobile(e.target.value) }} size="small" id="outlined-basic" variant="outlined" />

                                </div>

                            </div>

                            <div className="row mt-1 align-items-center justify-content-around">
                                <div className="col-md-12">
                                    <span className="span-title">Type*</span>
                                    <Select
                                        fullWidth
                                        className="mt-1"
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={type}
                                        size="small"
                                        onChange={(e) => { setType(e.target.value) }}
                                    >
                                        <MenuItem value={"Société"}>Société</MenuItem>
                                        <MenuItem value={"Particulier"}>Particulier</MenuItem>
                                    </Select>
                                </div>

                            </div>

                            <div className="row auto-icon mt-2 align-items-center justify-content-around">
                                <span className="span-title">Pays</span>
                                <Autocomplete
                                    id="country-select-demo"
                                    options={countries.countries}
                                    className="countries-autocomplete mt-1"
                                    onChange={(e, selected) => {
                                        setPays(selected)
                                    }}
                                    autoHighlight
                                    getOptionLabel={(option) => option.label}
                                    renderOption={(props, option) => (
                                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                            <img
                                                loading="lazy"
                                                width="20"
                                                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                                alt=""
                                            />
                                            {option.label}
                                        </Box>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'new-password', // disable autocomplete and autofill
                                            }}
                                        />
                                    )}
                                />
                            </div>
                        </div>

                    </>
                </DialogContent>
                <DialogActions style={{ padding: "10px 35px 20px 35px", justifyContent: 'start' }}>
                    <MuiButton

                        color="primary"
                        variant="outlined"
                        style={{ textTransform: 'capitalize' }}
                        onClick={() => {
                            setOpenMembreModal(false)
                            resetValues()
                        }}
                    >
                        Cancel
                    </MuiButton>
                    <MuiButton
                        disabled={(nom ==="" || prenom ==="" || type ==="" || email ==="" || mobile ==="") ? true : false}
                        onClick={() => { addPorteur() }}
                        variant="contained"
                        style={{ textTransform: 'capitalize', color: "#fff", backgroundColor: (nom ==="" || prenom ==="" || type ==="" || email ==="" || mobile ==="") ? "grey": "#1565C0" }}
                    >
                        Valider
                    </MuiButton>
                    {requiredValues === true && <span style={{ color: 'red' }}>Veuillez remplir les champs obligatoire *</span>}
                </DialogActions>
            </Dialog>



        </>
    )
}
export default Porteur;