import { PaymentElement } from "@stripe/react-stripe-js";
import React, { useEffect, useState } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { stripeService } from "../../provider/stripeService";

export default function CheckoutForm(props) {
  const stripe = useStripe();
  const elements = useElements();

  const [email, setEmail] = useState(props.email);
  const [message, setMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    handleSubmit()
}, [props.payToChild]);

  const handleSubmit = async () => {
    // e.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsProcessing(true);

    stripe.confirmPayment({
      elements,
      redirect: "if_required",
      confirmParams: {
        payment_method_data: {
          billing_details: {
            email: email
          }
        }
      },
    }).then(async data => {
      console.log(data)
      if (data.error && (data.error.type === "card_error" || data.error.type === "validation_error" || data.error.type === "invalid_request_error")) {
        setMessage(data.error.message);
      } else {
        if (data.paymentIntent && data.paymentIntent.status === "succeeded") {
          let payment_method_data = await stripeService.retrieve_stripe_payment_method(data.paymentIntent.payment_method)
          props.onPayment(data, payment_method_data, email)
        } else {
          setMessage("Une erreur inattendue s'est produite.");
        }
      }
      setIsProcessing(false);
    }).catch(err => {
      console.log(err)
      setMessage("Une erreur inattendue s'est produite.");
      setIsProcessing(false);
    })
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit} style={{ width: 480 }}>
      <div className="form-group mb-2">
        <input className="custom-stripe-input" type="email" value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          required={true}
        />
      </div>
      <PaymentElement id="payment-element" options={{ fields: { billingDetails: { email: "auto" } } }} />
      {/* <button className="btn-stripe" disabled={isProcessing || !stripe || !elements} id="submit">
        <span id="button-text" style={{ fontWeight: 700 }}>
          {isProcessing ? "Traitement ... " : "Payer maintenant"}
        </span>
      </button> */}
      {/* Show any error or success messages */}
      {message && <div id="payment-message" style={{ color: "red", marginTop: 5, fontWeight: 600 }}>{message}</div>}
    </form>
  );
}
