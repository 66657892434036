exports.formation = [
    {
        "name": "Lycée",
        "children": [
            {
                "name": "Seconde (classe de seconde)",
                "children": []
            },
            {
                "name": "Première (classe de première)",
                "children": []
            },
            {
                "name": "Terminale (classe de terminale)",
                "children": []
            },

        ]
    },
    {
        "name": "Enseignement Supérieur (après le lycée)",
        "children": [
            {
                "name": "Premier Cycle (Licence/Bachelor)",
                "children": [
                    {
                        "name":" L1 (Première année de Licence/Bachelor)",
                        "children": []
                    },
                    {
                        "name":"L2 (Deuxième année de Licence/Bachelor)",
                        "children": []
                    },
                    {
                        "name":"L3 (Troisième année de Licence/Bachelor)",
                        "children": []
                    }
                ]
            },
            {
                "name": " Deuxième Cycle (Master)",
                "children": [
                    {
                        "name":"M1 (Première année de Master)",
                        "children": []
                    },
                    {
                        "name":"M2 (Deuxième année de Master)",
                        "children": []
                    }
                ]
            },
            {
                "name": "Troisième Cycle (Doctorat)",
                "children": [
                    {
                        "name":"Doctorat (ou Doctorat en recherche)",
                        "children": []
                    }
                ]
            }

        ]
    },
    {
        "name": "Études Supérieures Supplémentaires",
        "children": [
            {
                "name": "Diplôme d'Études Supérieures Spécialisées (DESS)",
                "children": []
            },
            {
                "name": "Diplôme d'Études Approfondies (DEA)",
                "children": []
            },
            {
                "name": "Mastère Spécialisé (MS)",
                "children": []
            },

        ]
    },
    {
        "name": "Écoles Supérieures",
        "children": [
            {
                "name": "Grandes Écoles",
                "children": []
            },
            {
                "name": "Instituts Universitaires de Technologie (IUT)",
                "children": []
            },
            {
                "name": "Écoles d'Ingénieurs",
                "children": []
            },
            {
                "name": "Écoles de Commerce",
                "children": []
            },
            {
                "name": "Écoles d'Art",
                "children": []
            },
            {
                "name": "Écoles de Communication, etc.",
                "children": []
            },

        ]
    },
    {
        "name": "Formations Professionnelles Supérieures",
        "children": [
            {
                "name": "Diplômes de Technicien Supérieur (BTS)",
                "children": []
            },
            {
                "name": "Diplômes Universitaires de Technologie (DUT)",
                "children": []
            },
            {
                "name": "Formations courtes certifiées (par exemple, CAP, BEP)",
                "children": []
            },
            {
                "name": "Formation Continue",
                "children": []
            },
            {
                "name": "Formation continue universitaire",
                "children": []
            },
            {
                "name": "Cours et certifications professionnelles",
                "children": []
            },

        ]
    }
]