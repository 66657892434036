import React, { useState } from 'react';
import * as XLSX from 'xlsx';

const ExcelToJsonConverter = () => {
  const [json, setJson] = useState(null);

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const data = event.target.result;
      const workbook = XLSX.read(data, { type: 'binary' });
      const result = [];

      workbook.SheetNames.forEach((sheetName) => {
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        const categories = [];
        let parentCategory = null;
        let subcategoryObj = null;

        jsonData.forEach((row) => {
          const [parent, subcategory, subsubcategory] = row;

          if (parent) {
            parentCategory = {
              name: parent,
              selectable: false,
              children: [],
            };
            categories.push(parentCategory);
            subcategoryObj = null;
          }

          if (subcategory && parentCategory) {
            subcategoryObj = {
              name: subcategory,
              selectable: false,
              children: [],
            };
            parentCategory.children.push(subcategoryObj);
          }

          if (subsubcategory && subcategoryObj) {
            subcategoryObj.children.push({
              name: subsubcategory,
              selectable: true,
              children: [],
            });
          }
        });

        categories.forEach((category) => {
          if (category.children.length === 0) {
            category.selectable = true;
          } else {
            category.selectable = false;
          }
        });

        const sheetObject = {
          categorie: categories,
          nam_sectionl: sheetName,
        };

        result.push(sheetObject);
      });

      setJson(result);
    };

    reader.readAsBinaryString(file);
  };

  return (
    <div>
      <input type="file" onChange={handleFileUpload} />
      {json && <pre>{JSON.stringify(json, null, 2)}</pre>}
    </div>
  );
};

export default ExcelToJsonConverter;
