import React, { useEffect, useRef, useState } from "react";
import "./Client_Home.css"
import { useNavigate } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import categories from "../../../data/categories"
import SearchIcon from '@mui/icons-material/Search';
import MuiBackdrop from "../../../components/Loading/MuiBackdrop";

const Client_Home = () => {

    const navigate = useNavigate();

    const [loading, setLoading] = React.useState(false);
    const [value, setValue] = React.useState('');
    const [inputValue, setInputValue] = React.useState('');
    const [options, setOptions] = useState(categories.categories.map((option) => option.label));
    const pageTopRef = useRef(null);


    const handleInputChange = (event) => {
        const newValue = event.target.value;
        if (newValue.trim() !== '' && !options.includes(newValue)) {
            // Si la nouvelle valeur n'existe pas dans la liste, l'ajouter aux options
            setOptions([...options, newValue]);
        }
    };

    const filterFreelance = () => {
        console.log(inputValue)
        navigate("/freelance-mada?cat='" + inputValue + "'")
    }

    const filterFreelanceFooter = (value) => {
        console.log(value)
        navigate("/freelance-mada?cat='" + value + "'")
    }

    return (
        <>
            <MuiBackdrop open={loading} text={"Chargement..."} />
            <div className="client-home" ref={pageTopRef}>
                <div className="header-client">
                    <div className="d-flex justify-content-between align-items-center px-2" style={{ height: "100%" }}>
                        <div className="d-flex px-2">
                            <span style={{ fontSize: 34, fontWeight: 700 }}>Build.</span><span style={{ fontSize: 34, color: "#214dcb", fontWeight: 700 }}>Mada</span>
                        </div>
                        <div className="d-flex align-items-center px-2" style={{ gap: 20 }}>
                            <button className="create-client-button" onClick={(e) => { navigate('/build-mada-signup') }}>
                                Créer un compte
                            </button>
                            <div style={{ borderBottom: "2px solid #214dcb", padding: "2px 8px 4px 8px" }}>
                                <span className="span-link1" style={{ fontWeight: 700, color: "#214dcb" }} onClick={(e) => { navigate('/build-mada') }}>Me connecter</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="body-client pb-4" style={{ marginTop: 100 }}>
                    <div className="row align-items-center">
                        <div className="col-md-6 px-3">
                            <div className="d-flex mt-3 flex-column px-3" style={{ gap: 10 }}>
                                <span className="span-title-freemada p-2">Relevez vos défis avec l'expérience <br /> de nos <span style={{ color: "#1565C0" }}> freelances</span></span>
                                {/* <span className="span-description-freemada">Trouver le talent dont vous avez besoin n'a jamais été aussi simple. Notre plateforme vous permet de parcourir un vivier de freelancers qualifiés dans divers domaines, du développement web à la conception graphique, en passant par la rédaction, la traduction, le marketing et bien plus encore. Postez votre projet, décrivez vos besoins et recevez des propositions de freelancers expérimentés en quelques heures. Choisissez le candidat idéal en fonction de ses compétences, de son expérience et de ses évaluations précédentes. FreelanceMada facilite la collaboration et garantit des résultats exceptionnels.</span> */}

                                <div className="d-flex align-items-center px-2" style={{ gap: 20 }}>

                                    <button style={{ width: "52%", letterSpacing: 2 }} className="create-client-button mt-2 mb-2" onClick={(e) => { filterFreelance() }}>
                                        Trouver un(e) freelance
                                    </button>
                                    <span style={{ fontWeight: 600, color: "#636161", fontSize: 16, letterSpacing: 2 }}>ou</span>
                                    <div style={{ borderBottom: "2px solid #214dcb", padding: "2px 8px 4px 8px", letterSpacing: 2 }}>
                                        <span className="span-link1" style={{ fontWeight: 700, color: "#214dcb" }} onClick={(e) => { navigate('/build-mada') }}>Déposer une mission</span>
                                    </div>
                                </div>
                                <div className="px-2 mt-2 mb-3">
                                    <Autocomplete
                                        disablePortal
                                        placeholder="Rechercher par compétences ou métiers"
                                        value={value}
                                        onChange={(event, newValue) => {
                                            setValue(newValue);
                                        }}
                                        inputValue={inputValue}
                                        onInputChange={(event, newInputValue) => {
                                            setInputValue(newInputValue);
                                        }}
                                        id="combo-box-demo"
                                        options={options}
                                        sx={{
                                            width: "100%",
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    // border: '2px solid #1565C0',
                                                    borderRadius: 2,// Changer la couleur de la bordure de l'Autocomplete
                                                },
                                            },
                                        }}
                                        size="small"
                                        renderInput={(params) =>
                                            <TextField
                                                {...params}
                                                placeholder="Rechercher par compétences, logiciels, freelance ou métiers"
                                                InputProps={{
                                                    ...params.InputProps,
                                                    startAdornment: (
                                                        <SearchIcon
                                                            style={{ color: "#1565C0" }}
                                                            sx={{ color: 'action.active', cursor: 'pointer' }}
                                                            onClick={() => {
                                                                // Mettre ici la logique de recherche
                                                            }}
                                                        />
                                                    ),
                                                }}
                                                onChange={handleInputChange}
                                            />
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 px-3">
                            <img className="image-landing" src="home1.png" />
                        </div>

                    </div>

                    <div style={{ marginTop: 50 }} className="row justify-content-md-center px-4">
                        <div className="col-md-3">
                            <Card>
                                <CardActionArea>
                                    <CardMedia
                                        sx={{ height: 167 }}
                                        component="img"
                                        image="/lan1.jpg"
                                        alt="green iguana"
                                    />
                                    <CardContent>
                                        <Typography style={{ fontSize: 18, fontWeight: 600 }} component="div">
                                            Hanitra Rasoanaivo
                                        </Typography>
                                        <Typography gutterBottom style={{ fontSize: 16, fontWeight: 700, fontFamily: 'Montserrat', color: 'grey' }}>Développeuse Web</Typography>
                                        <Typography style={{ fontSize: 16, fontWeight: 600, fontFamily: 'Montserrat' }}>CodeInnovate Solutions</Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </div>
                        <div className="col-md-3">
                            <Card>
                                <CardActionArea>
                                    <CardMedia
                                        component="img"
                                        sx={{ height: 167 }}
                                        image="/lan2.jpg"
                                        alt="green iguana"
                                    />
                                    <CardContent>
                                        <Typography style={{ fontSize: 18, fontWeight: 600 }} component="div">
                                            Tojo Andrianarivelo
                                        </Typography>
                                        <Typography gutterBottom style={{ fontSize: 16, fontWeight: 700, fontFamily: 'Montserrat', color: 'grey' }}>Chef de Projet</Typography>
                                        <Typography style={{ fontSize: 16, fontWeight: 600, fontFamily: 'Montserrat' }}>ProjectMinds Consulting</Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </div>
                        <div className="col-md-3">
                            <Card>
                                <CardActionArea>
                                    <CardMedia
                                        component="img"
                                        sx={{ height: 167 }}
                                        image="/lan3.jpg"
                                        alt="green iguana"
                                    />
                                    <CardContent>
                                        <Typography style={{ fontSize: 18, fontWeight: 600 }} component="div">
                                            Soa Andriamanalina
                                        </Typography>
                                        <Typography gutterBottom style={{ fontSize: 16, fontWeight: 700, fontFamily: 'Montserrat', color: 'grey' }}>Expert Comptable</Typography>
                                        <Typography style={{ fontSize: 16, fontWeight: 600, fontFamily: 'Montserrat' }}>N-Insight Consulting</Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </div>
                    </div>
                </div>
                <footer className="footer mt-4">
                    <div className="row px-4">
                        <p style={{ fontSize: 26, fontWeight: 700 }} className="investisseurs">Freelance Mada</p>
                        <p style={{ fontSize: 18 }}>Synchronisez vos besoins avec les compétences idéales</p>
                    </div>
                    <div className="row align-items-start p-4" style={{ width: "100%" }}>
                        <div className="col-md-12">
                            <div className="d-flex flex-column">
                                <p style={{ fontSize: 26, fontWeight: 700, marginTop: 40 }} className="investisseurs mb-3">Catégories</p>
                                <p onClick={(e) => { filterFreelanceFooter('Création artistique') }} style={{ fontSize: 18, cursor: 'pointer' }}>Création artistique</p>
                                <p onClick={(e) => { filterFreelanceFooter('Vidéo et animation') }} style={{ fontSize: 18, cursor: 'pointer' }}>Vidéo et animation</p>
                                <p onClick={(e) => { filterFreelanceFooter('Data') }} style={{ fontSize: 18, cursor: 'pointer' }}>Data</p>
                                <p onClick={(e) => { filterFreelanceFooter('Programmation') }} style={{ fontSize: 18, cursor: 'pointer' }}>Programmation</p>
                                <p onClick={(e) => { filterFreelanceFooter('Marketing') }} style={{ fontSize: 18, cursor: 'pointer' }}>Marketing</p>
                                <p onClick={(e) => { filterFreelanceFooter('Énergies') }} style={{ fontSize: 18, cursor: 'pointer' }}>Énergies</p>
                                <p onClick={(e) => { filterFreelanceFooter('Comptabilité') }} style={{ fontSize: 18, cursor: 'pointer' }}>Comptabilité</p>
                                <p onClick={(e) => { filterFreelanceFooter('Musique et audio') }} style={{ fontSize: 18, cursor: 'pointer' }}>Musique et audio</p>
                                <p onClick={(e) => { filterFreelanceFooter('Business') }} style={{ fontSize: 18, cursor: 'pointer' }}>Business</p>
                                <p onClick={(e) => { filterFreelanceFooter('Luxe') }} style={{ fontSize: 18, cursor: 'pointer' }}>Luxe</p>
                                <p onClick={(e) => { filterFreelanceFooter('Artisanat') }} style={{ fontSize: 18, cursor: 'pointer' }}>Artisanat</p>

                                <div className="d-flex mt-4" style={{ gap: 50 }}>
                                    <img src="/facebookfooter.png" />
                                    <img src="/twitterfooter.png" />
                                    <img src="/instagramfooter.png" />
                                    <img src="/linkedinfooter.png" />
                                    <img src="/youtubefooter.png" />
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-md-3">
                            <p style={{ fontSize: 26, fontWeight: 700, marginTop: 40 }} className="investisseurs">Découvrir Freelance Mada</p>
                            <p style={{ fontSize: 18 }}>Soutiens & partenaires</p>
                            <p style={{ fontSize: 18 }}>Nous rejoindre</p>
                            <p style={{ fontSize: 18 }}>Notre approche</p>
                            <p style={{ fontSize: 18 }}>Nous contacter</p>
                        </div>
                        <div className="col-md-3">
                            <p style={{ fontSize: 26, fontWeight: 700, marginTop: 40 }} className="investisseurs">Mentions légales</p>
                            <p style={{ fontSize: 18 }}>Politique de confidentialité</p>
                            <p style={{ fontSize: 18 }}>Mentions légales</p>
                            <p style={{ fontSize: 18 }}>CGV</p>
                        </div> */}
                    </div>
                    <p style={{ fontSize: 18, marginTop: 30 }}>©2023-2024  Freelance Mada | Tout droits reservés</p>
                </footer>
            </div>
        </>
    )
}
export default Client_Home;