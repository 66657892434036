import React, { useEffect, useRef, useState } from 'react';
import "./Complete_Profil.css"
import { Chip } from '@material-ui/core'
import MuiBackdrop from "../../components/Loading/MuiBackdrop";
import { apiNodeService } from '../../provider/apiNodeService';
import Alert from '@mui/material/Alert';
import Avatar from '@mui/material/Avatar';
import utilFunctions from '../../tools/functions';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import moment from "moment";
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import rethink from '../../controller/rethink';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import 'moment/locale/fr'; // Importer la localisation française de Moment.js
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import TextComponent from '../../components/Profil/TextComponent';
import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined';

const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 25,
    height: 25,
    border: `2px solid ${theme.palette.background.paper}`,
}));

const Profil_Details_View = () => {

    const navigate = useNavigate();

    const [loading, setLoading] = React.useState(false);
    const [niveauExperience, setNiveauExperience] = React.useState('');
    const [disponibility, setDisponibility] = React.useState('');
    const [mobility, setMobility] = React.useState('');
    const [prenom, setPrenom] = React.useState('');
    const [nom, setNom] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [mobile, setMobile] = React.useState('');
    const [ville, setVille] = React.useState('');
    const [poste, setPoste] = React.useState('');
    const [ecole, setEcole] = React.useState('');
    const [description, setDescription] = React.useState('');
    const [pays, setPays] = React.useState('Madagascar');
    const [facebook, setFacebook] = React.useState('');
    const [linkedin, setLinkedin] = React.useState('');
    const [gmail, setGmail] = React.useState('');
    const [twitter, setTwitter] = React.useState('');
    const [whatsapp, setWhatsapp] = React.useState('');
    const [selectedValues, setSelectedValues] = React.useState([]);
    const [selectedNodes, setSelectedNodes] = React.useState({});
    const [selectedValues1, setSelectedValues1] = React.useState([]);
    const [selectedNodes1, setSelectedNodes1] = React.useState({});
    const [alertSuccess, setAlertSuccess] = React.useState(false)
    const [alertError, setAlertError] = React.useState(false)
    const [alertText, setAlertText] = useState("");
    const [photo, setPhoto] = React.useState(null)
    const [montant, setMontant] = React.useState("")
    const [montantClient, setMontantClient] = React.useState("")
    const [user, setUser] = React.useState(null)
    const [employe, setEmploye] = React.useState(null)
    const [naissance, setNaissance] = React.useState(dayjs(moment()))
    const [codep, setCodep] = React.useState("");

    const [checked, setChecked] = React.useState(false);
    const [portfolio, setPortfolio] = useState([]);
    const [openPortfolioModal, setOpenPortfolioModal] = useState(false);
    const [titleModal, setTitleModal] = React.useState("");
    const [valueModal, setValueModal] = React.useState("");
    const [postes, setPostes] = React.useState([]);
    const [projets, setProjets] = React.useState([]);
    const [formations, setFormations] = React.useState([]);
    const [posteNumber, setPosteNumber] = useState(2);
    const [projetNumber, setProjetNumber] = useState(2);
    const [formationNumber, setFormationNumber] = useState(2);
    const [comments, setComments] = React.useState([]);
    const [etoileRemp, setEtoileRemp] = useState(0);
    const [langues, setLangues] = React.useState([]);
    const [missions, setMissions] = React.useState([]);
    const [showAll, setShowAll] = useState(false);
    const [posteActuel, setPosteActuel] = useState('');
    const [openDocumentModal, setOpenDocumentModal] = useState(false);
    const [document, setDocument] = useState(null);
    const [loadingModal, setLoadingModal] = React.useState(false);

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const id = searchParams.get('user');
        getUserById(id)
    }, []);

    const formatDate = (date) => {
        return moment(date).format('MMMM YYYY');
    }

    const getDocument = (uid) => {
        setLoadingModal(true)
        setOpenDocumentModal(true)
        rethink.getItemByLabel("FREELANCE_MAD", "documents", { uid: uid }).then((res) => {
            console.log(res)
            if (res) {
                setDocument(res[0])
                setLoadingModal(false)
            }
        })
    }

    const getMission = (email) => {
        rethink.getItemByLabel("FREELANCE_MAD", "mission", { freelancer: email }).then((res) => {
            console.log(res)
            if (res) {
                let filteredData = res.filter(item => item.state === "finished")
                setMissions(filteredData)

            }
        })
    }

    const getDateRange = (startDate, endDate, checked) => {
        let endDateFormatted;

        if (checked) {
            endDateFormatted = "aujourd'hui";
        } else {
            endDateFormatted = moment(endDate).format('MMMM YYYY');
        }

        const startDateFormatted = formatDate(startDate);

        return `${startDateFormatted} - ${endDateFormatted}`;
    }

    const getDateRangeFormation = (startDate, endDate) => {
        let endDateFormatted;

        if (endDate === '') {
            endDateFormatted = "aujourd'hui";
        } else {
            endDateFormatted = moment(endDate).format('MMMM YYYY');
        }

        const startDateFormatted = formatDate(startDate);

        return `${startDateFormatted} - ${endDateFormatted}`;
    }


    const getUserById = async (id) => {
        setLoading(true)
        return new Promise(resolve => {
            apiNodeService.filterTable({ table: "user_build", filter: { id: id }, db: "FREELANCE_MAD" }).then(userRes => {
                console.log(userRes)
                if (userRes && userRes !== "false" && Array.isArray(userRes.data)) {
                    console.log(userRes.data[0])
                    getUserByEmail(userRes.data[0].email, userRes.data[0].last_name, userRes.data[0].first_name)
                    setUser(userRes.data[0])
                    setNom(userRes.data[0].last_name)
                    setPrenom(userRes.data[0].first_name)
                    setNaissance(dayjs(userRes.data[0].naissance))
                    setMobile(userRes.data[0].mobile)
                    setPays(userRes.data[0].pays)
                    setEmail(userRes.data[0].email)
                    getMission(userRes.data[0].email)
                    setVille(userRes.data[0].ville)
                    setCodep(userRes.data[0].codep)
                    setPosteActuel(userRes.data[0].poste_actuel ? userRes.data[0].poste_actuel : '')
                    setDescription(userRes.data[0].description ? userRes.data[0].description : "")
                    resolve(userRes.data[0])
                } else {
                    setLoading(false)
                    resolve("false")
                }
            }).catch(err => resolve("false"))
        })
    }

    const getUserByEmail = async (email, last_name, first_name) => {
        return new Promise(resolve => {
            apiNodeService.filterTable({ table: "employe_odoo", filter: { email: email }, db: "FREELANCE_MAD" }).then(userRes => {
                console.log(userRes)
                if (userRes && userRes !== "false" && Array.isArray(userRes.data)) {
                    console.log(userRes.data[0])
                    if (!userRes.data[0]) {
                        let data = { email: email, last_name: last_name, first_name: first_name }
                        apiNodeService.insertTableData({ db: "FREELANCE_MAD", table: "employe_odoo", data: data }).then(resAdd => {
                            console.log(resAdd)
                            if (resAdd && resAdd.status === 200) {
                                console.log(resAdd)
                                let data = {}
                                data.id = resAdd.data.id
                                setEmploye(data)
                                console.log('true')
                            } else {
                                console.log('false')
                            }
                        })
                        setLoading(false)
                    } else {
                        setEmploye(userRes.data[0])
                        setNiveauExperience(userRes.data[0].niveau ? userRes.data[0].niveau : '')
                        setMontant(userRes.data[0].montant ? userRes.data[0].montant : '')
                        setSelectedValues(userRes.data[0].competence ? userRes.data[0].competence : [])
                        setSelectedValues1(userRes.data[0].logiciel ? userRes.data[0].logiciel : [])
                        setDisponibility(userRes.data[0].disponibility ? userRes.data[0].disponibility : '')
                        setChecked(userRes.data[0].permis ? userRes.data[0].permis : '')
                        setMobility(userRes.data[0].mobility ? userRes.data[0].mobility : '')
                        setPhoto(userRes.data[0].photo ? userRes.data[0].photo : null)
                        setFacebook(userRes.data[0].facebook ? userRes.data[0].facebook : '')
                        setLinkedin(userRes.data[0].linkedin ? userRes.data[0].linkedin : '')
                        setWhatsapp(userRes.data[0].whatsapp ? userRes.data[0].whatsapp : '')
                        setTwitter(userRes.data[0].twitter ? userRes.data[0].twitter : '')
                        setGmail(userRes.data[0].gmail ? userRes.data[0].gmail : '')
                        setPosteActuel(userRes.data[0].poste_actuel ? userRes.data[0].poste_actuel : '')
                        setPortfolio(userRes.data[0].portfolio ? userRes.data[0].portfolio : [])
                        setPostes(userRes.data[0].postes ? userRes.data[0].postes : [])
                        setProjets(userRes.data[0].projets ? userRes.data[0].projets : [])
                        setFormations(userRes.data[0].formations ? userRes.data[0].formations : [])
                        setComments(userRes.data[0].comment ? userRes.data[0].comment : [])
                        getEtoileRemp(userRes.data[0].comment)
                        setLangues(userRes.data[0].langues ? userRes.data[0].langues : [])
                        setLoading(false)
                    }
                } else {
                    resolve("false")
                }
            }).catch(err => resolve("false"))
        })
    }

    const getEtoileRemp = (items) => {
        if (items && items.length > 0) {
            let i = 0;
            items.forEach((item) => {
                i += (item.satisfaction + item.communication) / 2
            })
            console.log(i)
            setEtoileRemp(i)
        }
    }

    const getUserByEmail1 = async (email) => {
        return new Promise(resolve => {
            apiNodeService.filterTable({ table: "user_build", filter: { email: email }, db: "FREELANCE_MAD" }).then(userRes => {
                console.log(userRes)
                if (userRes && userRes !== "false" && Array.isArray(userRes.data)) {
                    console.log(userRes.data[0])
                    resolve(userRes.data[0])
                } else {
                    resolve("false")
                }
            }).catch(err => resolve("false"))
        })
    }

    const createRoom = async (email, nameM) => {
        console.log(email)
        let porteur = await getUserByEmail1(localStorage.getItem("email"))
        let freelancer = await getUserByEmail1(email)
        setLoading(true)
        let newRoom = {
            uid: utilFunctions.getUID(),
            title: nameM,
            desc: "",
            color: "",
            porteur_first_name: porteur.first_name,
            porteur_last_name: porteur.last_name,
            freelancer_first_name: freelancer.first_name,
            freelancer_last_name: freelancer.last_name,
            created_by: localStorage.getItem('email'),
            created_at: moment().toDate(),
            members: [localStorage.getItem("email"), email],
            offre_state: "en cours"
        }
        rethink.insert("FREELANCE_MAD", 'rooms', newRoom).then(resAdd => {
            console.log(resAdd)
            if (resAdd && resAdd === true) {
                console.log(resAdd)
                navigate('/discuss/groups?room=' + newRoom.uid)
                setLoading(false)
            } else {
                console.log('erreur')
                setLoading(false)
            }
        })
    }

    // const calculateDuration = (dateDebut, dateFin, checked) => {
    //     const startDate = moment(dateDebut);
    //     const endDate = checked ? moment() : moment(dateFin);

    //     const years = endDate.diff(startDate, 'years');
    //     const months = endDate.diff(startDate, 'months');

    //     return years > 1 ? years + " ans" : years > 0 ? years + " an" : months + " mois";
    // }

    const calculateDuration = (dateDebut, dateFin, checked) => {
        const startDate = moment(dateDebut);
        const endDate = checked ? moment() : moment(dateFin);

        const years = endDate.diff(startDate, 'years');
        const months = endDate.diff(startDate, 'months');
        const days = endDate.diff(startDate, 'days');

        if (years > 0) {
            return years > 1 ? `${years} ans` : `${years} an`;
        } else if (months > 0) {
            return months > 1 ? `${months} mois` : `${months} mois`;
        } else {
            return days > 1 ? `${days} jours` : `${days} jour`;
        }
    }

    return (
        <>
            <MuiBackdrop open={loading} text={"Chargement..."} />
            <div className="header-client" style={{ top: 0, backgroundColor: "#fff" }} >
                <div className="d-flex justify-content-between align-items-center px-2" style={{ height: "100%" }}>
                    <div className="d-flex px-2" style={{ cursor: "pointer" }} onClick={(e) => { navigate('/') }}>
                        <span style={{ fontSize: 34, fontWeight: 700 }}>Build.</span><span style={{ fontSize: 34, color: "#214dcb", fontWeight: 700 }}>Mada</span>
                    </div>
                    <div className="d-flex align-items-center px-2" style={{ gap: 20 }}>
                        <button className="create-client-button" onClick={(e) => { navigate('/build-mada-signup') }}>
                            Créer un compte
                        </button>
                        <div style={{ borderBottom: "2px solid #214dcb", padding: "2px 8px 4px 8px" }}>
                            <span className="span-link1" style={{ fontWeight: 700, color: "#214dcb" }} onClick={(e) => { navigate('/build-mada') }}>Me connecter</span>
                        </div>
                    </div>
                </div>
            </div>
            {alertSuccess &&
                <Alert style={{ marginTop: 35 }} variant="filled" severity="success">
                    {alertText}
                </Alert>}
            {alertError &&
                <Alert style={{ marginTop: 35 }} variant="filled" severity="error">
                    Erreur, Veuillez réessayer plus tard !
                </Alert>}
            <div className="complete-profil-mada p-4">
                {user &&
                    <>
                        <div className='header-profile' style={{ marginTop: 60 }}>
                            <div className='d-flex align-items-center p-2' style={{ gap: 20 }}>
                                <ArrowBackIosIcon style={{ cursor: 'pointer' }} onClick={(e) => { navigate("/freelance-mada?cat=''") }} />
                                <div onClick={(e) => { navigate("/freelance-mada?cat=''") }} style={{ fontSize: 24, cursor: 'pointer' }} className='span-avatar'>profil de {user.first_name}</div>
                            </div>
                        </div>
                        <div className="body-complete">
                            <div className="row">
                                <div className="col-md-4 details-section">
                                    <div className='d-flex flex-column mt-3 justify-content-center'>
                                        <div className="d-flex flex-column align-items-center">
                                            {photo ?
                                                <Avatar
                                                    alt="Remy Sharp"
                                                    className="avatar-profil"
                                                    src={photo.prefix + photo.data}
                                                />
                                                : <Avatar className="avatar-profil">
                                                    {user.first_name[0]}
                                                </Avatar>
                                            }
                                            <span className='span-avatar mt-3'>{user.first_name} {user.last_name}</span>
                                            {user.poste_actuel && <span style={{ color: "#616161" }} className=' mt-1'>{user.poste_actuel}</span>}
                                            <div className='d-flex align-items-center mt-2' style={{ gap: 5 }}>
                                                <img
                                                    loading="lazy"
                                                    className='card-pays'
                                                    width="30"
                                                    srcSet={`https://flagcdn.com/w40/${pays.code.toLowerCase()}.png 2x`}
                                                    src={`https://flagcdn.com/w20/${pays.code.toLowerCase()}.png`}
                                                    alt=""
                                                />
                                            </div>

                                        </div>
                                    </div>
                                    <div className='d-flex mt-4 mb-4 flex-column'>
                                        <div className='d-flex justify-content-start'>
                                            <span className='span-avatar mt-4'>À propos</span>
                                        </div>
                                        <span style={{ fontFamily: 'Montserrat' }} className='mt-1'>{user.description ? user.description : ""}</span>
                                    </div>

                                    <span className='span-avatar'>Compétences</span>
                                    <div className='d-flex flex-wrap mt-2 mb-4' style={{ gap: 5 }}>
                                        {selectedValues1.map((item, key) => (
                                            <Chip size='small' style={{ color: "#1565C0", borderColor: "#1565C0", maxWidth: 200, overflow: 'hidden', textOverflow: 'ellipsis' }} variant="outlined" key={key} label={item} />
                                        ))}
                                    </div>

                                    {portfolio.length > 0 &&
                                        <>
                                            <div className='d-flex justify-content-between align-items-center mt-4 mb-3'>
                                                <span className='span-avatar'>Portfolio</span>
                                            </div>
                                            <div className='d-flex flex-wrap mb-3' style={{ gap: 5 }}>
                                                {portfolio.map((item, key) => (
                                                    <div onClick={(e) => {
                                                        const newTab = window.open(item.link, '_blank');
                                                        newTab.focus();
                                                    }} className='d-flex justify-content-between align-items-center card-portfolio p-3' style={{ width: "90%", cursor: 'pointer' }} key={key}>
                                                        <div className='d-flex align-items-center' style={{ gap: 20 }}>
                                                            <span style={{ maxWidth: 200, overflow: 'hidden', textOverflow: 'ellipsis' }} className='span-portfolio'>{item.title}</span>
                                                        </div>
                                                        <KeyboardArrowRightIcon
                                                            style={{ color: "#1976D2", cursor: 'pointer' }} size="small" />
                                                    </div>
                                                ))}

                                            </div>
                                        </>
                                    }

                                    <div className='d-flex mt-4 flex-column'>
                                        <div className='d-flex justify-content-start'>
                                            <span className='span-avatar mt-4'>Contacts</span>
                                        </div>
                                        {linkedin !== '' &&
                                            <div onClick={(e) => {
                                                setTitleModal("Linkedin")
                                                setValueModal(linkedin)
                                                setOpenPortfolioModal(true)
                                            }}
                                                className='d-flex justify-content-between mt-3' style={{ cursor: 'pointer' }}>
                                                <div className='d-flex align-items-center' style={{ gap: 10 }}>
                                                    <img height={24} src="/linkedin1.png" />
                                                    <span style={{ fontFamily: 'Montserrat' }}>Linkedin</span>
                                                </div>
                                                <KeyboardArrowRightIcon
                                                    style={{ color: "#616161", cursor: 'pointer' }} size="small" />
                                            </div>
                                        }
                                        {facebook !== '' &&
                                            <div
                                                onClick={(e) => {
                                                    setTitleModal("Facebook")
                                                    setValueModal(facebook)
                                                    setOpenPortfolioModal(true)
                                                }}
                                                className='d-flex justify-content-between mt-2' style={{ cursor: 'pointer' }}>
                                                <div className='d-flex align-items-center' style={{ gap: 10 }}>
                                                    <img height={24} src="/facb.png" />
                                                    <span style={{ fontFamily: 'Montserrat' }}>Facebook</span>
                                                </div>
                                                <KeyboardArrowRightIcon
                                                    style={{ color: "#616161", cursor: 'pointer' }} size="small" />
                                            </div>
                                        }
                                        {twitter !== '' &&
                                            <div
                                                onClick={(e) => {
                                                    setTitleModal("Twitter")
                                                    setValueModal(twitter)
                                                    setOpenPortfolioModal(true)
                                                }}
                                                className='d-flex justify-content-between mt-2' style={{ cursor: 'pointer' }}>
                                                <div className='d-flex align-items-center' style={{ gap: 10 }}>
                                                    <img height={24} width={24} src="/twitter.png" />
                                                    <span style={{ fontFamily: 'Montserrat' }}>Twitter</span>
                                                </div>
                                                <KeyboardArrowRightIcon
                                                    style={{ color: "#616161", cursor: 'pointer' }} size="small" />
                                            </div>
                                        }
                                        {whatsapp !== '' &&
                                            <div
                                                onClick={(e) => {
                                                    setTitleModal("Whatsapp")
                                                    setValueModal(whatsapp)
                                                    setOpenPortfolioModal(true)
                                                }}
                                                className='d-flex justify-content-between mt-2' style={{ cursor: 'pointer' }}>
                                                <div className='d-flex align-items-center' style={{ gap: 10 }}>
                                                    <img height={24} src="/whatsapp.png" />
                                                    <span style={{ fontFamily: 'Montserrat' }}>Whatsapp</span>
                                                </div>
                                                <KeyboardArrowRightIcon
                                                    style={{ color: "#616161", cursor: 'pointer' }} size="small" />
                                            </div>
                                        }
                                        {email !== '' &&
                                            <div
                                                onClick={(e) => {
                                                    setTitleModal("Mail")
                                                    setValueModal(email)
                                                    setOpenPortfolioModal(true)
                                                }}
                                                className='d-flex justify-content-between mt-2' style={{ cursor: 'pointer' }}>
                                                <div className='d-flex align-items-center' style={{ gap: 10 }}>
                                                    <img height={24} src="/gmail.png" />
                                                    <span style={{ fontFamily: 'Montserrat' }}>Mail</span>
                                                </div>
                                                <KeyboardArrowRightIcon
                                                    style={{ color: "#616161", cursor: 'pointer' }} size="small" />
                                            </div>
                                        }
                                    </div>
                                </div>

                                <div className="col-md-8 mt-2">
                                    <span className='span-info-perso'>ACTIVITÉ</span>
                                    <div className='row mb-2'>
                                        <div className='col-md-6 p-3'>
                                            <div className='card-detail-profil p-2'>
                                                <div className='d-flex flex-column'>
                                                    <span className='span-detail-profil'>{missions.length}</span>
                                                    <span>Missions réalisées</span>
                                                    <div className='d-flex' style={{ gap: 10 }}>
                                                        <img width={12} src='/flechgreen.svg' />
                                                        <span style={{ fontSize: 10 }}>dont {missions.length} le dernier mois</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-6 p-3'>
                                            <div className='card-detail-profil p-2'>
                                                <div className='d-flex flex-column'>
                                                    <span className='span-detail-profil'>{etoileRemp}</span>
                                                    <span>Étoiles remportées</span>
                                                    <div className='d-flex' style={{ gap: 10 }}>
                                                        <img width={12} src='/flechgreen.svg' />
                                                        <span style={{ fontSize: 10 }}>dont {etoileRemp} le dernier mois</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <span className='span-info-perso'>PROJETS RÉALISÉS</span>
                                    <div className='row'>
                                        <div className='col-md-6 p-3'>
                                            <div className='card-detail-profil p-2'>
                                                <div className='d-flex flex-column'>
                                                    <span className='span-detail-profil'>Designer 3D tiny house</span>
                                                    <span style={{ fontSize: 14 }}>Niveau d’expérience : <b> INTERMÉDIAIRE</b> </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}

                                    <div className='mt-2'>
                                        <span className='span-info-perso mt-4 uppercase-text'>DERNIÈRES MISSIONS RÉALISÉES</span>
                                    </div>

                                    {
                                        missions.length > 0 ?
                                            <div className="d-flex flex-column px-2" style={{ gap: 20 }}>
                                                {missions.map((item, key) => (
                                                    <div key={key}>
                                                        <div className="p-3 card-profil">
                                                            <div className="d-flex justify-content-between">
                                                                <span className="span-avatar">{item.title}</span>
                                                                {/* <div className="d-flex flex-column justify-content-end">
                                                                <span className="span-avatar">{item.montant} MGA</span>
                                                                {parseInt(item.montant) > 0 && <span className="span-avatar" style={{ fontSize: 16, textAlign: "right" }}>{(parseInt(item.montant) / 4870).toFixed(2)} EUROS</span>}
                                                            </div> */}
                                                            </div>
                                                            <div className="mt-2">
                                                                <span style={{ fontFamily: 'Montserrat', fontSize: 14, fontWeight: 700 }}>{item.description}</span>
                                                            </div>
                                                            {item.competence &&
                                                                <div className="d-flex flex-wrap mt-2" style={{ gap: 4 }}>
                                                                    {(item.competence || []).map((logiciel, key1) => (
                                                                        <div key={key1}>
                                                                            <Chip size="small" style={{ color: "#1565C0", borderColor: "#1565C0" }}

                                                                                variant="outlined" label={logiciel} />
                                                                        </div>
                                                                    ))
                                                                    }
                                                                </div>
                                                            }
                                                            <div className="d-flex justify-content-between mt-2">
                                                                <div>
                                                                    <span style={{ fontFamily: 'Montserrat', fontSize: 13, fontWeight: 700 }}>Niveau d'expérience: </span>
                                                                    <span style={{ fontSize: 13, fontWeight: 700 }}>{item.niveau}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* <div style={{ fontSize: 12, color: 'grey' }}>
                                                        {moment(item.created_at).fromNow()}
                                                    </div> */}
                                                    </div>
                                                ))
                                                }
                                            </div>
                                            :
                                            <span style={{ fontFamily: 'Montserrat', fontSize: 14, fontWeight: 600 }}>Aucune mission réalisée pour le moment, soyez le premier en contactant {user.first_name} {user.last_name}</span>
                                    }

                                    <div className='mt-4'>

                                        <span className='span-info-perso mt-4 uppercase-text'>Informations professionnelles</span>
                                    </div>

                                    <div className='row'>
                                        {montant !== "" &&
                                            <div className='col-md-6 p-2 px-4'>
                                                <div className='d-flex flex-column' style={{ gap: 5 }}>
                                                    <span className="span-title">Prix de la prestation par jour (HT)</span>
                                                    <div className='info-perso-section'>
                                                        <div className='d-flex justify-content-between p-0'>
                                                            <span style={{ fontWeight: 500, fontSize: 14, color: "#616161" }}>{montant} MGA</span>
                                                            <span>{parseInt(montant) > 0 ? "( " + (parseInt(montant) / 4870).toFixed(2) + " € )" : ""}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {disponibility !== "" &&
                                            <div className='col-md-6 p-2 px-4'>
                                                <div className='d-flex flex-column' style={{ gap: 5 }}>
                                                    <span className="span-title">Disponibilité</span>
                                                    <div className='info-perso-section'>
                                                        <span style={{ fontWeight: 500, fontSize: 14, color: "#616161" }}>{disponibility}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {checked === true &&
                                            <div className='col-md-6 p-2 px-4'>
                                                <div className='d-flex flex-column' style={{ gap: 5 }}>
                                                    <span className="span-title">Permis de conduire</span>
                                                    <div className='info-perso-section'>
                                                        <span style={{ fontWeight: 500, fontSize: 14, color: "#616161" }}>Posséder</span>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {mobility !== "" &&
                                            <div className='col-md-6 p-2 px-4'>
                                                <div className='d-flex flex-column' style={{ gap: 5 }}>
                                                    <span className="span-title">Moyen de transport</span>
                                                    <div className='info-perso-section'>
                                                        <span style={{ fontWeight: 500, fontSize: 14, color: "#616161" }}>{mobility}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>

                                    {postes.length > 0 &&
                                        <span style={{ fontWeight: 700, fontSize: 20, color: "grey", fontFamily: "montserrat" }}>Expériences</span>
                                    }

                                    {postes.length > 0 &&
                                        <div className='p-4 mt-2 mb-2' style={{ border: "1px solid #dcdcdc" }}>
                                            {postes.slice(0, posteNumber).map((item, key) => (
                                                <div className='d-flex mb-3' key={key}>
                                                    <div className='d-flex flex-column px-3' style={{ width: "30%" }}>
                                                        <span style={{ color: "#1565C0", fontSize: 22, fontFamily: "Montserrat", fontWeight: 700, textAlign: 'center' }}>{item.type_poste}</span>
                                                        <span style={{ fontWeight: 600, textAlign: 'center', color: '#616161' }}>{calculateDuration(item.date_debut, item.date_fin, item.checked_poste)}</span>
                                                    </div>
                                                    <Divider style={{ backgroundColor: '#1565C0' }} orientation="vertical" flexItem />
                                                    <div className='d-flex flex-column px-3' style={{ width: "70%" }}>
                                                        <span style={{ color: "#1565C0", fontSize: 20, fontFamily: "Montserrat", fontWeight: 700 }}>{item.title}</span>
                                                        <span style={{ fontWeight: 600, color: '#000' }}>{item.nom_soc + ", " + item.lieu_poste}</span>
                                                        <span style={{ fontSize: 14 }}>{getDateRange(item.date_debut, item.date_fin, item.checked_poste)}</span>
                                                        <TextComponent key={item.uid} text={item.description} />
                                                    </div>
                                                </div>
                                            ))}
                                            {postes.length > 2 &&
                                                <div className='d-flex justify-content-center align-items-center mt-2'
                                                    onClick={(e) => {
                                                        if (posteNumber === 2) {
                                                            setPosteNumber(postes.length)
                                                        } else {
                                                            setPosteNumber(2)
                                                        }
                                                    }}>
                                                    <span style={{ color: "#1565C0", fontSize: 16, fontFamily: "Montserrat", fontWeight: 700, cursor: 'pointer' }}>{posteNumber === 2 ? "Voir toutes les expériences" : "Voir moins"}</span>
                                                    {posteNumber === 2 ?
                                                        <KeyboardArrowDownOutlinedIcon style={{ cursor: 'pointer' }} fontSize='small' color='primary' /> :
                                                        <KeyboardArrowUpOutlinedIcon style={{ cursor: 'pointer' }} fontSize='small' color='primary' />
                                                    }
                                                </div>
                                            }
                                        </div>
                                    }

                                    {formations.length > 0 &&
                                        <span style={{ fontWeight: 700, fontSize: 20, color: "grey", fontFamily: "montserrat" }}>Formations</span>
                                    }

                                    {formations.length > 0 &&
                                        <div className='p-4 mb-2' style={{ border: "1px solid #dcdcdc" }}>
                                            {formations.slice(0, formationNumber).map((item, key) => (
                                                <div className='d-flex mb-3' key={key}>
                                                    <div className='d-flex flex-column px-3' style={{ width: "30%" }}>
                                                        <span style={{ color: "#1565C0", fontSize: 22, fontFamily: "Montserrat", fontWeight: 700, textAlign: 'center' }}>{item.title}</span>
                                                        <span style={{ fontWeight: 600, textAlign: 'center', color: '#616161' }}>{item.docUid ? 'Certifié' : 'Non certifié'}</span>
                                                    </div>
                                                    <Divider style={{ backgroundColor: '#1565C0' }} orientation="vertical" flexItem />
                                                    <div className='d-flex flex-column px-3' style={{ width: "70%" }}>
                                                        <div className='d-flex justify-content-between align-items-center'>
                                                            <span style={{ color: "#1565C0", fontSize: 20, fontFamily: "Montserrat", fontWeight: 700 }}>{item.domaine}</span>
                                                            {item.docUid && <LaunchOutlinedIcon style={{ cursor: 'pointer' }}
                                                            onClick={(e)=>{
                                                                setDocument(null)
                                                                getDocument(item.docUid)
                                                            }}
                                                            color='primary' fontSize='small' />}
                                                        </div>
                                                        <span style={{ fontWeight: 600, color: '#000' }}>{item.ecole + ", " + item.lieu_formation}</span>
                                                        <span style={{ fontSize: 14 }}>{getDateRangeFormation(item.date_debut, item.date_fin, item.checked_poste)}</span>
                                                        <TextComponent key={item.uid} text={item.description} />
                                                    </div>
                                                </div>
                                            ))}
                                            {formations.length > 2 &&
                                                <div className='d-flex justify-content-center align-items-center mt-2'
                                                    onClick={(e) => {
                                                        if (formationNumber === 2) {
                                                            setFormationNumber(formations.length)
                                                        } else {
                                                            setFormationNumber(2)
                                                        }
                                                    }}>
                                                    <span style={{ color: "#1565C0", fontSize: 16, fontFamily: "Montserrat", fontWeight: 700, cursor: 'pointer' }}>{formationNumber === 2 ? "Voir toutes les formations" : "Voir moins"}</span>
                                                    {formationNumber === 2 ?
                                                        <KeyboardArrowDownOutlinedIcon style={{ cursor: 'pointer' }} fontSize='small' color='primary' /> :
                                                        <KeyboardArrowUpOutlinedIcon style={{ cursor: 'pointer' }} fontSize='small' color='primary' />
                                                    }
                                                </div>
                                            }
                                        </div>
                                    }

                                    {projets.length > 0 &&
                                        <span style={{ fontWeight: 700, fontSize: 20, color: "grey", fontFamily: "montserrat" }}>Projets</span>
                                    }

                                    {projets.length > 0 &&
                                        <div className='p-4 mt-2 mb-2' style={{ border: "1px solid #dcdcdc" }}>
                                            {projets.slice(0, projetNumber).map((item, key) => (
                                                <div className='d-flex mb-3' key={key}>
                                                    <div className='d-flex flex-column px-3' style={{ width: "30%" }}>
                                                        <span style={{ color: "#1565C0", fontSize: 22, fontFamily: "Montserrat", fontWeight: 700, textAlign: 'center' }}>Projet {key + 1}</span>
                                                        <span style={{ fontWeight: 600, textAlign: 'center', color: '#616161' }}>{calculateDuration(item.date_debut, item.date_fin, item.checked_projet)}</span>
                                                    </div>
                                                    <Divider style={{ backgroundColor: '#1565C0' }} orientation="vertical" flexItem />
                                                    <div className='d-flex flex-column px-3' style={{ width: "70%" }}>
                                                        <span style={{ color: "#1565C0", fontSize: 20, fontFamily: "Montserrat", fontWeight: 700 }}>{item.title}</span>
                                                        <span style={{ fontWeight: 600, color: '#000' }}>{item.lieu_projet}</span>
                                                        <span style={{ fontSize: 14 }}>{getDateRange(item.date_debut, item.date_fin, item.checked_projet)}</span>
                                                        <TextComponent key={item.uid} text={item.description} />
                                                    </div>
                                                    {item.lien_projet &&
                                                        <OpenInNewIcon
                                                            onClick={(e) => {
                                                                window.open(item.lien_projet, '_blank');
                                                            }} style={{ cursor: 'pointer' }} fontSize='small' color='primary' />
                                                    }
                                                </div>
                                            ))}
                                            {projets.length > 2 &&
                                                <div className='d-flex justify-content-center align-items-center mt-2'
                                                    onClick={(e) => {
                                                        if (projetNumber === 2) {
                                                            setProjetNumber(projets.length)
                                                        } else {
                                                            setProjetNumber(2)
                                                        }
                                                    }}>
                                                    <span style={{ color: "#1565C0", fontSize: 16, fontFamily: "Montserrat", fontWeight: 700, cursor: 'pointer' }}>{projetNumber === 2 ? "Voir toutes les projets" : "Voir moins"}</span>
                                                    {projetNumber === 2 ?
                                                        <KeyboardArrowDownOutlinedIcon style={{ cursor: 'pointer' }} fontSize='small' color='primary' /> :
                                                        <KeyboardArrowUpOutlinedIcon style={{ cursor: 'pointer' }} fontSize='small' color='primary' />
                                                    }
                                                </div>
                                            }
                                        </div>
                                    }

                                    {langues.length > 0 &&
                                        <div className='d-flex justify-content-between mt-3 p-1'>
                                            <span style={{ fontWeight: 700, fontSize: 20, color: "grey", fontFamily: "montserrat" }}>Langues</span>

                                        </div>
                                    }

                                    {langues.length > 0 &&
                                        <div className='row px-2 mb-3'>
                                            {langues.map((item, key) => (
                                                <div className='col-md-4 p-2' key={key}>
                                                    <div className='card-portfolio p-3' >
                                                        <div className='d-flex align-items-center' style={{ gap: 10 }}>
                                                            <img
                                                                loading="lazy"
                                                                width="40"
                                                                style={{ height: 25 }}
                                                                srcSet={`https://flagcdn.com/w40/${
                                                                    item.langue === 'Anglais' ? 'gb' :
                                                                    item.langue === 'Français' ? 'fr' :
                                                                    item.langue === 'Espagnol' ? 'es' :
                                                                    item.langue === 'Chinois' ? 'cn' :
                                                                    item.langue === 'Arabe' ? 'sa' :
                                                                    item.langue === 'Allemand' ? 'de' :
                                                                    item.langue === 'Italien' ? 'it' :
                                                                    item.langue === 'Japonais' ? 'jp' :
                                                                    item.langue === 'Russe' ? 'ru' :
                                                                    item.langue === 'Portugais' ? 'pt' :
                                                                    item.langue === 'Hindi' ? 'in' :
                                                                    item.langue === 'Coréen' ? 'kr' :
                                                                    item.langue === 'Turc' ? 'tr' :
                                                                    item.langue === 'Néerlandais' ? 'nl' :
                                                                    item.langue === 'Suédois' ? 'se' :
                                                                    item.langue === 'Polonais' ? 'pl' : 
                                                                    item.langue === 'Grec' ? 'gr' :
                                                                    ''
                                                                  }.png`}
                                                                    src={`https://flagcdn.com/w40/${
                                                                    item.langue === 'Anglais' ? 'gb' :
                                                                    item.langue === 'Français' ? 'fr' :
                                                                    item.langue === 'Espagnol' ? 'es' :
                                                                    item.langue === 'Chinois' ? 'cn' :
                                                                    item.langue === 'Arabe' ? 'sa' :
                                                                    item.langue === 'Allemand' ? 'de' :
                                                                    item.langue === 'Italien' ? 'it' :
                                                                    item.langue === 'Japonais' ? 'jp' :
                                                                    item.langue === 'Russe' ? 'ru' :
                                                                    item.langue === 'Portugais' ? 'pt' :
                                                                    item.langue === 'Hindi' ? 'in' :
                                                                    item.langue === 'Coréen' ? 'kr' :
                                                                    item.langue === 'Turc' ? 'tr' :
                                                                    item.langue === 'Néerlandais' ? 'nl' :
                                                                    item.langue === 'Suédois' ? 'se' :
                                                                    item.langue === 'Polonais' ? 'pl' :
                                                                    item.langue === 'Grec' ? 'gr' :
                                                                    ''
                                                                  }.png`}   
                                                                   alt=""
                                                            />
                                                            <div className='d-flex flex-column'>
                                                                <span style={{ maxWidth: 200, overflow: 'hidden', textOverflow: 'ellipsis', color: "#1976D2", fontWeight: 600 }} className='span-langue'>{item.langue}</span>
                                                                <span style={{ maxWidth: 200, overflow: 'hidden', textOverflow: 'ellipsis', color: '#000' }} className='span-langue'>Niveau: <b>{item.level}</b> </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}

                                        </div>
                                    }

                                    <span className='span-info-perso'>RETOURS</span>
                                    {comments.length > 0 ?
                                        <div className='row'>
                                            {comments.map((item, key) => (
                                                <div key={key} className='col-md-6 p-3'>
                                                    <div className='card-detail-profil p-2'>
                                                        <div className='d-flex' style={{ gap: 10 }}>
                                                            <Avatar className="avatar-comment">
                                                                {item.user_first_name[0] + item.user_last_name[0]}
                                                            </Avatar>
                                                            <div className='d-flex flex-column'>
                                                                <span className='span-user-comment'>{item.user_first_name + " " + item.user_last_name}</span>
                                                                <div className='d-flex align-items-center' style={{ gap: 5 }}>
                                                                    <img alt="star" width={17} height={17} src='/star.png' />
                                                                    <span style={{ fontFamily: 'Montserrat', fontSize: 12 }}>{(parseInt(item.communication) + parseInt(item.satisfaction)) / 2} / 5</span>
                                                                </div>
                                                                <p className='m-0' style={{ fontFamily: 'Montserrat', fontSize: 12 }}>{item.description}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                            }
                                        </div>
                                        :
                                        <p className='m-0' style={{ fontFamily: 'Montserrat', fontSize: 12 }}>Aucun commentaire jusqu'à présent</p>
                                    }
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>

            <Dialog
                open={openPortfolioModal}
                aria-labelledby="form-dialog-title"
                fullWidth={true}
                maxWidth="sm"

            >
                <DialogTitle id="form-dialog-title" style={{ paddingBottom: 1 }}>
                    <div className='d-flex' style={{ gap: 10 }}>
                        <Typography style={{ color: '#1565C0', fontWeight: 600 }}>{titleModal} </Typography>
                    </div>
                    <IconButton
                        aria-label="close"
                        style={{
                            position: 'absolute',
                            right: 5,
                            top: 5,
                            color: '#c0c0c0'
                        }}
                        onClick={() => {
                            setOpenPortfolioModal(false)
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <hr style={{ color: "#1565C0" }} />
                </DialogTitle>
                <DialogContent style={{ overflowY: "inherit" }}>
                    <>
                        <div className='p-4'>
                            <span style={{ color: "#616161", fontSize: 16, fontWeight: 600 }}>{valueModal}</span>
                        </div>

                    </>
                </DialogContent>

            </Dialog>

            <Dialog
                open={openDocumentModal}
                aria-labelledby="form-dialog-title"
                fullWidth={true}
                maxWidth="lg"
                style={{zIndex:99999}}
                onClose={(e)=>{setOpenDocumentModal(false)}}
            >
                <DialogTitle id="form-dialog-title" style={{ paddingBottom: 1 }}>
                    <div className='d-flex' style={{ gap: 10 }}>
                        <Typography style={{ color: '#1565C0', fontWeight: 600 }}>{titleModal} </Typography>
                    </div>
                    <IconButton
                        aria-label="close"
                        style={{
                            position: 'absolute',
                            right: 5,
                            top: 5,
                            color: '#c0c0c0'
                        }}
                        onClick={() => {
                            setOpenDocumentModal(false)
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent style={{ overflowY: "inherit" }}>
                <div className='p-5'>
                        <MuiBackdrop open={loadingModal} text="Chargement..." />
                        {document && document.b64 && <img width={"100%"} src={document.b64}/>}

                    </div>
                </DialogContent>

            </Dialog>
        </>
    )
}
export default Profil_Details_View;