import React from "react";
import "./index.css"
import rethink from "../../controller/rethink";
import moment from "moment";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Lightbox } from "react-modal-image";
import Popover from '@material-ui/core/Popover';
import * as ReactDOM from 'react-dom';
import { IconButton } from "@material-ui/core";
import { Anchorme } from 'react-anchorme'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Drawer from '@material-ui/core/Drawer';
import { Drawer as RsDrawer, IconButton as RsIconButton, SelectPicker, Tag, TagGroup } from 'rsuite';
import utilFunctions from "../../tools/functions";
import AtlButton from '@atlaskit/button';
import SignatureCanvas from 'react-signature-canvas';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { AudioRecorder } from "./AudioRecorder";
import ReactPlayer from "react-player";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Paper from "@material-ui/core/Paper";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import PeoplesMapIcon from '@rsuite/icons/PeoplesMap';
import ImageIcon from '@rsuite/icons/Image';
import { DatePicker } from 'react-rainbow-components';
import loading_gif from "../../assets/gifs/loading3.gif"
import withNavigate from "../navigation/withNavigate";
import MuiBackdrop from "../../components/Loading/MuiBackdrop";
import ImageView from "./ImageView";
import { toast } from 'react-toastify';
import DialogFlowService from "../../provider/DialogFlowService";
import VoiceRecognitionService from "../../provider/VoiceRecognitionService";
import { ShimmerText } from "react-shimmer-effects";
import { Carousel } from "react-responsive-carousel";
import RenderUserAvatar from "../../components/Avatars/UserAvatar";
import { SpeechRecognitionComp } from "./SpeechRecognition";
import Checkbox from '@mui/material/Checkbox';
import { Rnd } from "react-rnd";
import { MapInteractionCSS } from "react-map-interaction";
import rethink_functions from "../../tools/rethink_functions";
import ResponsiveModal from "react-responsive-modal";
import * as htmlToImage from "html-to-image"
import { apiNodeService } from '../../provider/apiNodeService';
import projectIcon from "../../assets/images/project-icon-96.png"
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import SettingsIcon from '@mui/icons-material/Settings';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import { Button as MuiButton } from '@material-ui/core';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { maillingService } from "../../provider/maillingService";
import Chip from '@mui/material/Chip';
import DoneIcon from '@mui/icons-material/Done';
import Alert from '@mui/material/Alert';
import CloseIcon from "@mui/icons-material/Close";
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import html2pdf from 'html2pdf.js';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import PermMediaOutlinedIcon from '@mui/icons-material/PermMediaOutlined';
import FilePresentOutlinedIcon from '@mui/icons-material/FilePresentOutlined';
import ListOutlinedIcon from '@mui/icons-material/ListOutlined';
import DrawOutlinedIcon from '@mui/icons-material/DrawOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import Button from '@mui/material/Button';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import StripePaymentPage from "../stripe/StripePaymentPage";
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import { Add } from "@material-ui/icons";
import Comment_Modal from "../../components/Chat/Comment_Modal";
import Comment_Modal_Porteur from "../../components/Chat/Comment_Modal_Porteur";
import Box from '@mui/material/Box';
import jsPDF from "jspdf";

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 13,
        fontWeight: 600,
        maxWidth: 450,
    },
}));

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        backgroundColor: '#44b700',
        color: '#44b700',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: 'ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
        },
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1,
        },
        '100%': {
            transform: 'scale(2.4)',
            opacity: 0,
        },
    },
}));

const StyledBadgeOff = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        backgroundColor: 'grey',
        color: 'grey',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: 'ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
        },
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1,
        },
        '100%': {
            transform: 'scale(2.4)',
            opacity: 0,
        },
    },
}));

const planMapModalStyles = {
    modal: {
        backgroundColor: "transparent",
        boxShadow: "none",
        //display: "flex",
        overflow: "none",
        width: "100%",
        padding: "0",
        margin: "0",
        height: "100%",
        minWidth: "100%",
        justifyContent: "center"
    },
    overlay: {
        backgroundColor: "#1cccc",
        padding: 0,
        marginTop: 5,
        marginLeft: 0,
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
    },
    closeButton: {
        right: 0,
        left: 10
    },
    closeIcon: {
        fill: "#fff",
        fontWeight: 700
    }
};

class Chat extends React.Component {

    imageUpload = {}
    fileUpload = {}
    bot_image_upload = {}
    messageList = React.createRef()
    datePickerRef = React.createRef()
    mapImageRef = React.createRef()
    containerRef = React.createRef()
    containerNdaRef = React.createRef()
    containerPartageRef = React.createRef()
    popupPlusRef = React.createRef()
    plusBtnRef = React.createRef()
    sigCanvas = {}
    interval = null

    state = {
        projects_plan: [],
        choixSolar: [],
        objects_selected: [],
        updateScreen: false,
        text: "",
        messages: [],
        loading: true,
        loadingCall: false,
        showImageModal: false,
        imageModal: "",
        hasMore: true,
        limit: 50,
        skipCount: 0,
        loadingScroll: false,
        room_id: "",
        room_title: "ChatBot",
        contacts: null,
        openBottomSignModal: false,
        anchorElMsgMenu: null,
        showTaggedMsgForm: false,
        selectedMsg: "",
        taggedMsg: "",

        initial_bot: {
            name: "",
            type: "",
            questions: [
                {
                    data_title: null,
                    question: localStorage.getItem("role") === "porteur" ? "J'espère que ce message vous trouve en pleine forme. Je me permets de vous contacter au sujet de notre plateforme, Freelance Mada, qui se distingue par sa capacité à fournir des services de qualité grâce à une communauté de freelancers hautement qualifiés. Notre plateforme regroupe une diversité de talents dans différents domaines, allant de la conception graphique au développement web en passant par la rédaction et bien d'autres. Nous sommes convaincus que vous trouverez chez nous le professionnel idéal pour répondre à vos besoins spécifiques." : "Nous sommes ravis de vous accueillir sur notre plateforme. Pour commencer votre aventure, nous vous invitons à parcourir nos missions disponibles. Une fois que vous avez trouvé une mission qui correspond à vos compétences et à vos intérêts, n'hésitez pas à contacter le porteur du projet. Cela déclenchera automatiquement l'ouverture d'une nouvelle discussion avec le porteur du projet. Posez vos questions, partagez vos idées et collaborez pour atteindre des résultats exceptionnels. Si vous avez besoin d'aide ou avez des questions, notre équipe est là pour vous soutenir à chaque étape du processus. Profitez de cette opportunité pour explorer, créer et développer votre carrière ! Bonne chance dans votre recherche de mission, et que l'aventure commence !",
                    suggestions: {
                        type: "text"
                    },
                    action_before: async () => { this.setState({ is_bot_active: true }) },
                    id: 0
                },
            ],
            currentQuest: 0,
            timeout: 2000,
            end: false
        },
        is_bot_active: false,
        active_bot: "",
        bot_recovered_data: {},
        openModalDatePicker: false,
        showPlusFormMenu: false,
        showImageView: false,
        showDetailGroup: false,
        showDetailBimObject: false,
        showPlanMap: false,

        //plan map
        mapValue: { translation: { x: 0, y: 0 }, scale: 1 },
        roomsList: [],
        openEmailModal: false,
        email: '',
        roomTitle: '',
        openModalInfo: false,
        openModalDevis: false,
        openModalDevisSign: false,
        openModalDocumentSign: false,
        openModalDocumentPartage: false,
        prenomPorteur: "",
        nomPorteur: "",
        prenomFreelancer: "",
        nomFreelancer: "",
        paysPorteur: "",
        adressePorteur: "",
        codepPorteur: "",
        emailPorteur: "",
        villePorteur: "",
        mobilePorteur: "",
        complAdressPorteur: "",
        paysFreelancer: "",
        userType: "",
        adresseFreelancer: "",
        codepFreelancer: "",
        emailFreelancer: "",
        villeFreelancer: "",
        mobileFreelancer: "",
        complAdressFreelancer: "",
        montant: 0,
        modalite: "",
        mission_description: "",
        missionRoom: null,
        checked: false,
        docDevis64: null,
        openModalRaison: false,
        refusRaison: "",
        docDevisNum: 0,
        docDevisDate: "",
        montantMga: 0,
        isEmptyRooms: false,
        jobFreelancer: "",
        jobPorteur: "",
        numSecSocial: "",
        isUpdateChat: false,
        isPartageDocGenerated: false,
        openModalPayment: false,
        openModalVersion: false,
        openModalVersionValidate: false,
        isEditing: false,
        payToChild: false,
        modalLoading: false,
        versionTitle: "",
        versionDescription: "",
        versionFinale: false,
        versionLink: "",
        versionComment: "",
        isValidComment: false,
        openCommentModal: false,
        openCommentModalPorteur: false,
        isStartSign: false
    }

    generatePdf = () => {
        const contentElement = document.getElementById('pdf-content');
        this.setState({ isUpdateChat: false, loading: true })
        if (!contentElement) {
            return;
        }

        html2pdf()
            .from(contentElement)
            .set({
                margin: 10,
                filename: 'devis.pdf',
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 4 },
                jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
            })
            .outputPdf('blob') // Get PDF as Blob
            .then((pdfBlob) => {
                // Convert Blob to base64
                const reader = new FileReader();
                console.log(reader)
                reader.readAsDataURL(pdfBlob);
                console.log(pdfBlob)
                reader.onloadend = () => {
                    const base64String = reader.result;
                    this.downloadPdf(base64String, "devis")
                    this.addB64FileDevis(base64String, "devis")
                    //     const pdf = new jsPDF();
                    // pdf.loadFile(pdfBlob);
                    // const numPages = pdf.internal.pages.length;
                    // console.log("Number of pages:", numPages);
                    // You can now use the base64String as needed
                    if (parseInt(this.state.montant) > 0) {
                        let data1 = this.state.room
                        data1.offre_state = "waiting"
                        this.setState({ room: data1 })
                        let data = {}
                        data.montant = this.state.montant
                        data.mission_description = this.state.mission_description
                        data.offre_state = "waiting"
                        data.num_devis = "D2024-" + this.state.docDevisNum.toString().padStart(4, '0')
                        console.log(data)
                        apiNodeService.updateTableData({ db: "FREELANCE_MAD", table: "rooms", id: this.state.room.id, data: data }).then(resAdd => {
                            console.log(resAdd)
                            if (resAdd && resAdd.status === 200) {
                                this.setState({ loading: false })
                                console.log(resAdd)
                            } else {
                                this.setState({ loading: false })
                            }
                        })

                    }
                };
            })
            .catch((error) => {
                console.error('Error generating PDF:', error);
            });
    };

    generateDocumentNda = () => {
        const contentElement = document.getElementById('pdf-content-nda');
        this.setState({ isUpdateChat: false })
        if (!contentElement) {
            return;
        }
        html2pdf()
            .from(contentElement)
            .set({
                margin: 10,
                filename: 'NDA.pdf',
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 4 },
                jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
            })
            .outputPdf('blob') // Get PDF as Blob
            .then((pdfBlob) => {
                // Convert Blob to base64
                const reader = new FileReader();
                reader.readAsDataURL(pdfBlob);
                reader.onloadend = () => {
                    const base64String = reader.result;
                    console.log('Base64 PDF:', base64String);

                    let signature = this.sigCanvas.getTrimmedCanvas().toDataURL('image/png');
                    const cleanBase64 = signature.replace(/^data:image\/png;base64,/, '');
                    let data = {
                        date: moment().format("DD/MM/YYYY"),
                        b64: cleanBase64,
                        fileName: "nda",
                        w: 170,
                        h: 80,
                        page: 0
                    }
                    console.log(data)
                    let data1 = {
                        file64: "data:application/pdf;base64," + base64String,
                        signature: data,
                        x: 50,
                        y: 420
                    }
                    this.saveSignatureFreelance(signature)
                    this.setState({ loading: true, openModalDocumentSign: false })
                    apiNodeService.signNda(data1).then(res => {
                        console.log(res)
                        this.downloadPdf(res.pdf, "NDA")
                        this.addB64FileNda(res.pdf, "nda-signe")
                        this.signNdaFreelance()
                        // this.generateDocumentNda()
                    })
                };
            })
            .catch((error) => {
                console.error('Error generating PDF:', error);
            });
    };

    saveSignatureFreelance = async (data) => {
        // let newB64 = await utilFunctions.compressB64Image(data)
        let newDoc = {
            b64: data,
            mission_id: this.state.room.mission_id,
            freelance_sign: localStorage.getItem('email')
        }
        apiNodeService.insertTableData({ db: "FREELANCE_MAD", table: "documents", data: newDoc }).then(resAdd => {
            if (resAdd && resAdd.status === 200) {
                console.log(resAdd)
            } else {
                console.log("error insert signature")
            }
        })
    }

    getB64ByEmail = async (email) => {
        return new Promise(resolve => {
            apiNodeService.filterTable({ table: "documents", filter: { freelance_sign: email }, db: "FREELANCE_MAD" }).then(userRes => {
                if (userRes && userRes !== "false" && Array.isArray(userRes.data)) {
                    console.log(userRes.data)
                    resolve(userRes.data[0].b64)
                } else {
                    resolve("false")
                }
            }).catch(err => resolve("false"))
        })
    }

    getB64SignFreeMada = async () => {
        return new Promise(resolve => {
            apiNodeService.filterTable({ table: "documents", filter: { id: "1ddb9556-c7dd-4a42-bf5c-bda27646cdee" }, db: "FREELANCE_MAD" }).then(userRes => {
                if (userRes && userRes !== "false" && Array.isArray(userRes.data)) {
                    resolve(userRes.data[0].b64)
                } else {
                    resolve("false")
                }
            }).catch(err => resolve("false"))
        })
    }
    generateDocumentPartage = () => {
        const contentElement = document.getElementById('pdf-content-partage');
        if (!contentElement) {
            return;
        }
        html2pdf()
            .from(contentElement)
            .set({
                margin: 10,
                filename: 'NDA.pdf',
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 4 },
                jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
            })
            .outputPdf('blob') // Get PDF as Blob
            .then((pdfBlob) => {
                // Convert Blob to base64
                const reader = new FileReader();
                reader.readAsDataURL(pdfBlob);
                reader.onloadend = () => {
                    const base64String = reader.result;
                    console.log('Base64 PDF:', base64String);

                    let signature = this.sigCanvas.getTrimmedCanvas().toDataURL('image/png');
                    const cleanBase64 = signature.replace(/^data:image\/png;base64,/, '');
                    let data = {
                        date: moment().format("DD/MM/YYYY"),
                        b64: cleanBase64,
                        fileName: "nda",
                        w: 170,
                        h: 80,
                        page: 0
                    }
                    console.log(data)
                    let data1 = {
                        file64: "data:application/pdf;base64," + base64String,
                        signature: data,
                        x: 350,
                        y: 420
                    }
                    this.setState({ openModalDocumentPartage: false, loading: true })
                    apiNodeService.signNda(data1).then(res => {
                        this.setState({ loading: false, isPartageDocGenerated: true })
                        console.log(res)
                        this.downloadPdf(res.pdf, "Partage Revenus")
                        this.saveDocument(res.pdf)
                        setTimeout(() => {
                            this.setState({ isPartageDocGenerated: false })
                        }, 6400)
                    })
                };
            })
            .catch((error) => {
                console.error('Error generating PDF:', error);
            });
    };

    handleValueChange = (newValue) => {
        this.setState({ payToChild: !this.state.payToChild })
    };

    signDocumentNda = async () => {
        this.setState({ isUpdateChat: false, modalLoading: true })
        let base64String = await this.getB64ByUid(this.state.docDevis64)
        this.setState({ loading: true, openModalDocumentSign: false })
        let signature = this.sigCanvas.getTrimmedCanvas().toDataURL('image/png');
        const cleanBase64 = signature.replace(/^data:image\/png;base64,/, '');
        let data = {
            date: moment().format("DD/MM/YYYY"),
            b64: cleanBase64,
            fileName: "nda",
            w: 170,
            h: 80,
            page: 0
        }
        console.log(data)
        let data1 = {
            file64: "data:application/pdf;base64," + base64String,
            signature: data,
            x: 350,
            y: 420
        }
        apiNodeService.signNda(data1).then(res => {
            console.log(res)
            this.setState({ modalLoading: false })
            this.downloadPdf(res.pdf, "NDA")
            this.addB64FileNda(res.pdf, "nda-signe")
            this.signNdaPorteur()
        })
    }

    downloadPdf = (base64String, fileName) => {
        // Check if the Base64 string is valid
        if (!base64String || typeof base64String !== 'string') {
            console.error('Invalid Base64 string');
            return;
        }
        // Remove data URI scheme if present
        const base64WithoutPrefix = base64String.replace(/data:application\/pdf;base64,/, '');
        // Convert Base64 to Blob
        try {
            const byteCharacters = atob(base64WithoutPrefix);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: 'application/pdf' });

            // Create a download link
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = fileName || 'downloaded.pdf';

            // Append the link to the body
            document.body.appendChild(link);

            // Trigger the click event
            link.click();

            // Remove the link from the body
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error decoding Base64 string:', error);
        }
    };

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
        this.setState({ isUpdateChat: false })
        clearInterval(this.interval);
    }

    componentDidMount() {
        const searchParams = new URLSearchParams(window.location.search);
        const room_id = searchParams.get('room');
        document.addEventListener('mousedown', this.handleClickOutside);
        console.log(room_id)
        this.getRooms()
        if (room_id) {
            rethink.getItemByLabel("FREELANCE_MAD", "rooms", { uid: room_id }).then((roomRes) => {
                console.log(roomRes)
                if (roomRes && Array.isArray(roomRes) && roomRes.length > 0) {
                    console.log(roomRes)
                    let room = roomRes[0]
                    console.log(room)
                    this.getPorteur(roomRes[0].members[0])
                    this.getFreelancer(roomRes[0].members[1])
                    this.getFreelancerEmploye(roomRes[0].members[1])
                    if (roomRes[0].mission_id) this.getMission(roomRes[0].mission_id)
                    this.setState({ room: room, room_id: room_id })
                    rethink.getItemByLabel("FREELANCE_MAD", "chat", { "room_id": room_id }).then(res => {
                        if (res.length < this.state.limit) {
                            console.log(res)
                            this.setState({ hasMore: false, loading: false, messages: res })
                        } else {
                            this.setState({
                                loading: false,
                                messages: res,
                                hasMore: true
                            })
                        }
                        setTimeout(() => {
                            this.scrollToBottom();
                        }, 250)
                    }).catch(err => {
                        console.log(err)
                    })
                }
            }).catch(err => {
            })
        } else {
            apiNodeService.getTableData({ db: "FREELANCE_MAD", table: "rooms" }).then(res => {
                console.log(res)
                this.setState({ loading: true })
                if (res && res.status === 200) {
                    let data1 = res.data.filter(item => item.uid !== "xys97s7rzx-54ns7x95ajg-maqqnfgelm-95zajdpev");
                    console.log(data1.length)
                    let data = res.data.filter(item => item.uid !== "xys97s7rzx-54ns7x95ajg-maqqnfgelm-95zajdpev" && item.members.includes(localStorage.getItem("email")));
                    const sortedElements = data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
                    if (data.length > 0) {
                        this.setState({ roomsList: sortedElements, docDevisNum: data1.length + 1, room: data[0], room_id: data[0].uid })
                        this.getPorteur(data[0].members[0])
                        this.getFreelancer(data[0].members[1])
                        this.getFreelancerEmploye(data[0].members[1])
                        if (data[0].mission_id) this.getMission(data[0].mission_id)
                        rethink.getItemByLabel("FREELANCE_MAD", "chat", { "room_id": data[0].uid }).then(resM => {
                            if (resM.length < this.state.limit) {
                                console.log(resM)
                                this.setState({ hasMore: false, loading: false, messages: resM })
                            } else {
                                this.setState({
                                    loading: false,
                                    messages: resM,
                                    hasMore: true
                                })
                            }
                            setTimeout(() => {
                                this.scrollToBottom();
                            }, 250)
                        }).catch(err => {
                            console.log(err)
                        })
                    } else {
                        console.log("im empty")
                        this.setState({ loading: false, isEmptyRooms: true })
                    }
                }
            })
        }
        this.setState({ isUpdateChat: true })
        this.interval = setInterval(() => {
            this.updateChat(this.state.room_id)
            this.updateChat1(this.state.room_id)
        }, 6500);
        return () => clearInterval(this.interval);
    }

    insertDoc = (doc) => {
        this.setState({ loading: true })
        let data = {
            b64: doc,
            mission_id: this.state.room.mission_id
        }
        apiNodeService.insertTableData({ db: "FREELANCE_MAD", table: "documents", data: data }).then(resAdd => {
            console.log(resAdd)
            if (resAdd && resAdd.status === 200) {
                this.setState({ loading: false })
            } else {
                this.setState({ loading: false })
            }
        })
    }

    getRooms = () => {
        apiNodeService.getTableData({ db: "FREELANCE_MAD", table: "rooms" }).then(res => {
            console.log(res)
            if (res && res.status === 200) {
                let data1 = res.data.filter(item => item.uid !== "xys97s7rzx-54ns7x95ajg-maqqnfgelm-95zajdpev");
                console.log(data1.length)
                let data = res.data.filter(item => item.uid !== "xys97s7rzx-54ns7x95ajg-maqqnfgelm-95zajdpev" && item.members.includes(localStorage.getItem("email")));
                const sortedElements = data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
                this.setState({ roomsList: sortedElements, docDevisNum: data1.length + 1 })
            }
        })
    }

    addNewRoom = () => {
        let newRoom = {
            uid: utilFunctions.getUID(),
            title: this.state.roomTitle,
            desc: "",
            color: "",
            created_by: localStorage.getItem('email'),
            created_at: moment().toDate(),
            members: []
        }
        rethink.insert("FREELANCE_MAD", 'rooms', newRoom).then(resAdd => {
            console.log(resAdd)
            if (resAdd && resAdd === true) {
                console.log(resAdd)
                this.getRooms()
                this.setState({ openEmailModal: false })
            } else {
                console.log('erreur')
            }
        })
    }

    getMission = (id) => {
        console.log("hellooo")
        rethink.getItemByLabel("FREELANCE_MAD", "mission", { id: id }).then((res) => {
            console.log(res)
            if (res) {
                this.setState({
                    missionRoom: res[0],
                    mission_description: res[0].description
                })

            }
        })
    }

    getPorteur = (email) => {
        rethink.getItemByLabel("FREELANCE_MAD", "user_build", { email: email }).then((res) => {
            console.log(res)
            if (res) {
                this.setState({
                    userPorteur: res[0],
                    nomPorteur: res[0].first_name + " " + res[0].last_name,
                    paysPorteur: res[0].pays.label,
                    adressePorteur: res[0].adress,
                    complAdressPorteur: res[0].adress_complet,
                    codepPorteur: res[0].codep,
                    villePorteur: res[0].ville,
                    emailPorteur: res[0].email,
                    mobilePorteur: res[0].mobile,
                    jobPorteur: res[0].job_title,
                    numSecSocial: res[0].num_sec_social
                })

            }
        })

    }

    getFreelancer = (email) => {
        rethink.getItemByLabel("FREELANCE_MAD", "user_build", { email: email }).then((res) => {
            console.log(res)
            if (res) {
                this.setState({
                    userFreelancer: res[0],
                    nomFreelancer: res[0].first_name + " " + res[0].last_name,
                    paysFreelancer: res[0].pays.label,
                    adresseFreelancer: res[0].adress,
                    complAdressFreelancer: res[0].adress_complet,
                    codepFreelancer: res[0].codep,
                    villeFreelancer: res[0].ville,
                    emailFreelancer: res[0].email,
                    mobileFreelancer: res[0].mobile,
                    jobFreelancer: res[0].job_title,
                    userType: res[0].user_type ? res[0].user_type : ''
                })

            }
        })

    }

    getFreelancerEmploye = (email) => {
        rethink.getItemByLabel("FREELANCE_MAD", "employe_odoo", { email: email }).then((res) => {
            console.log(res)
            if (res) {
                this.setState({
                    userFreelancerEmploye: res[0]
                })

            }
        })

    }

    updateChat = (roomid) => {
        if (this.state.is_bot_active !== true && this.state.room_id !== roomid) {
            this.setState({ isUpdateChat: false })
            this.setState({ messages: [] })
            this.setState({ room_id: roomid })
            rethink.getItemByLabel("FREELANCE_MAD", "rooms", { uid: roomid }).then((roomRes) => {
                if (roomRes && Array.isArray(roomRes) && roomRes.length > 0) {
                    let room = roomRes[0]
                    this.getPorteur(roomRes[0].members[0])
                    this.getFreelancer(roomRes[0].members[1])
                    this.getFreelancerEmploye(roomRes[0].members[1])
                    if (roomRes[0].mission_id) this.getMission(roomRes[0].mission_id)
                    if (roomRes[0].montant) this.setState({ montant: roomRes[0].montant })
                    this.setState({ room: room })
                    rethink.getItemByLabel("FREELANCE_MAD", "chat", { "room_id": roomid }).then(res => {
                        console.log(res)
                        if (res.length !== 0) {
                            if (res.length < this.state.limit) {
                                this.setState({ hasMore: false, messages: res })
                                this.scrollToBottom();
                                this.setState({ loading: false })
                                this.setState({ isUpdateChat: true })
                            } else {
                                this.setState({
                                    messages: res,
                                    hasMore: true
                                })
                            }
                            setTimeout(() => {
                                this.scrollToBottom();
                                this.setState({ loading: false })
                            }, 250)
                        } else {
                            this.scrollToBottom();
                            this.setState({ loading: false })
                        }
                    }).catch(err => {
                        console.log(err)
                    })
                }
            }).catch(err => {
            })
        }
    }

    getRoomByUid = (roomid) => {
        rethink.getItemByLabel("FREELANCE_MAD", "rooms", { uid: roomid }).then((roomRes) => {
            if (roomRes && Array.isArray(roomRes) && roomRes.length > 0) {
                let room = roomRes[0]
                console.log(room)
                if (roomRes[0].montant) this.setState({ montant: roomRes[0].montant })
                this.setState({ room: room })

            }
        }).catch(err => {
        })
    }

    updateChat1 = (roomid) => {
        if (this.state.room_id === roomid && this.state.is_bot_active !== true && this.state.isUpdateChat === true) {
            console.log("hello im in update")
            this.getRoomByUid(roomid)
            rethink.getItemByLabel("FREELANCE_MAD", "chat", { "room_id": roomid }).then(res => {

                if (res.length !== this.state.messages.length) {
                    setTimeout(() => {
                        this.scrollToBottom();
                    }, 250)
                    console.log(res)
                    this.getRooms()
                    if (res.length < this.state.limit) {
                        setTimeout(() => {
                            this.setState({ hasMore: false, messages: res })
                            setTimeout(() => {
                                this.scrollToBottom();
                            }, 250)
                        }, 2500)

                    } else {
                        this.setState({
                            messages: res,
                            hasMore: true
                        })
                    }
                    setTimeout(() => {
                        this.scrollToBottom();
                    }, 250)
                }
            }).catch(err => {
                console.log(err)
            })
        }
    }

    handleChange = (event) => {
        this.setState({ checked: event.target.checked })
    };

    handleChangeVersion = (event) => {
        this.setState({ versionFinale: event.target.checked })
    };

    resetValueVersion = () => {
        this.setState(
            {
                versionComment: "",
                versionDescription: "",
                versionTitle: "",
                versionLink: "",
                versionFinale: false
            }
        )
    }

    acceptOffre = () => {
        this.setState({ loading: true })
        let data = {}
        data.offre_state = "accepted"
        console.log(data)
        apiNodeService.updateTableData({ db: "FREELANCE_MAD", table: "rooms", id: this.state.room.id, data: data }).then(resAdd => {
            console.log(resAdd)
            if (resAdd && resAdd.status === 200) {
                let newRoom = this.state.room
                newRoom.offre_state = "accepted"
                this.setState({ room: newRoom })
                this.sendEmail()
                this.setState({ loading: false })
            } else {
                this.setState({ loading: false })
            }
        })
    }

    confirmOffre = () => {
        this.setState({ loading: true })
        let data = {}
        data.offre_state = "confirmed"
        console.log(data)
        apiNodeService.updateTableData({ db: "FREELANCE_MAD", table: "rooms", id: this.state.room.id, data: data }).then(resAdd => {
            console.log(resAdd)
            if (resAdd && resAdd.status === 200) {
                let newRoom = this.state.room
                newRoom.offre_state = "confirmed"
                this.setState({ room: newRoom })
                this.sendEmailConfirm()
                this.setState({ loading: false })
                this.updateMission()
                this.getRooms()
                setTimeout(() => {
                    this.addPaymentMsg()
                    this.scrollToBottom();
                }, 10000)
            } else {
                this.setState({ loading: false })
            }
        })
    }

    updateMission = () => {
        let data = {}
        data.accepted = true
        data.freelancer = this.state.room.members[1]
        console.log(data)
        apiNodeService.updateTableData({ db: "FREELANCE_MAD", table: "mission", id: this.state.room.mission_id, data: data }).then(resAdd => {
            if (resAdd && resAdd.status === 200) {
                console.log(resAdd)
            } else {
                this.setState({ loading: false })
            }
        })
    }

    updateMissionContrat = () => {
        let data = {}
        data.signed = true
        console.log(data)
        apiNodeService.updateTableData({ db: "FREELANCE_MAD", table: "mission", id: this.state.room.mission_id, data: data }).then(resAdd => {
            if (resAdd && resAdd.status === 200) {
                console.log(resAdd)
            } else {
                this.setState({ loading: false })
            }
        })
    }

    payedOffre = () => {
        this.setState({ loading: true })
        let data = {}
        data.offre_state = "payed"
        apiNodeService.updateTableData({ db: "FREELANCE_MAD", table: "rooms", id: this.state.room.id, data: data }).then(resAdd => {
            console.log(resAdd)
            if (resAdd && resAdd.status === 200) {
                let newRoom = this.state.room
                newRoom.offre_state = "payed"
                this.setState({ room: newRoom })
                this.setState({ loading: false })
                this.getRooms()
            } else {
                this.setState({ loading: false })
            }
        })
    }

    endOffre = () => {
        this.setState({ loading: true })
        let data = {}
        data.offre_state = "finished"
        apiNodeService.updateTableData({ db: "FREELANCE_MAD", table: "rooms", id: this.state.room.id, data: data }).then(resAdd => {
            console.log(resAdd)
            if (resAdd && resAdd.status === 200) {
                let newRoom = this.state.room
                newRoom.offre_state = "finished"
                this.endMission()
                let textPorteur = "Merci pour avoir fait appel à l’un.e de nos freelance !"
                let textFreelancer = "Merci d’avoir terminé cette mission !"
                this.addNoteMsg(this.state.emailPorteur, "porteur", textPorteur)
                this.addNoteMsg(this.state.emailFreelancer, "freelance", textFreelancer)
                this.setState({ room: newRoom })
                this.setState({ loading: false })
                this.getRooms()
            } else {
                this.setState({ loading: false })
            }
        })
    }

    noteOffre = (element) => {
        console.log(element)
        let data = {}
        data[element] = true
        apiNodeService.updateTableData({ db: "FREELANCE_MAD", table: "rooms", id: this.state.room.id, data: data }).then(resAdd => {
            console.log(resAdd)
            if (resAdd && resAdd.status === 200) {
                let newRoom = this.state.room
                newRoom[element] = true
                this.setState({ room: newRoom })
                this.setState({ loading: false })
            } else {
                this.setState({ loading: false })
            }
        })
    }

    endMission = () => {
        let data = {}
        data.state = "finished"
        console.log(data)
        apiNodeService.updateTableData({ db: "FREELANCE_MAD", table: "mission", id: this.state.room.mission_id, data: data }).then(resAdd => {
            if (resAdd && resAdd.status === 200) {
                console.log(resAdd)
            } else {
                this.setState({ loading: false })
            }
        })
    }

    signNdaFreelance = () => {
        this.setState({ loading: true })
        let data = {}
        data.nda_freelance = "signed"
        console.log(data)
        apiNodeService.updateTableData({ db: "FREELANCE_MAD", table: "rooms", id: this.state.room.id, data: data }).then(resAdd => {
            console.log(resAdd)
            if (resAdd && resAdd.status === 200) {
                let newRoom = this.state.room
                newRoom.nda_freelance = "signed"
                this.setState({ room: newRoom })
                this.setState({ loading: false })
            } else {
                this.setState({ loading: false })
            }
        })
    }

    signNdaPorteur = () => {
        this.setState({ loading: true })
        let data = {}
        data.nda_porteur = "signed"
        console.log(data)
        apiNodeService.updateTableData({ db: "FREELANCE_MAD", table: "rooms", id: this.state.room.id, data: data }).then(resAdd => {
            console.log(resAdd)
            if (resAdd && resAdd.status === 200) {
                let newRoom = this.state.room
                newRoom.nda_porteur = "signed"
                this.updateMissionContrat()
                this.setState({ room: newRoom })
                // this.sendEmailConfirm()
                this.setState({ loading: false })
            } else {
                this.setState({ loading: false })
            }
        })
    }

    refusOffre = () => {
        this.setState({ loading: true })
        let data = {}
        data.offre_state = "refused"
        console.log(data)
        apiNodeService.updateTableData({ db: "FREELANCE_MAD", table: "rooms", id: this.state.room.id, data: data }).then(resAdd => {
            console.log(resAdd)
            if (resAdd && resAdd.status === 200) {
                let newRoom = this.state.room
                newRoom.offre_state = "refused"
                this.setState({ room: newRoom })
                this.sendEmailRefus()
                this.setState({ loading: false })
            } else {
                this.setState({ loading: false })
            }
        })
    }

    sendEmail = () => {
        const mailData = {
            emailFrom: "dev.rocketbonds@gmail.com",
            nameFrom: "FreelanceMada",
            to: [
                {
                    Email: this.state.room.members[0],
                    Name: ""
                }
            ],
            subject: "Mission accepté",
            htmlText: 'Bonjour ' + this.state.room.porteur_first_name + ', <br/> <br/> ' +
                "Nous avons le plaisir de vous informer qu'un freelanceur a accepté votre mission." +
                '<br/>' +
                "Nous vous invitons de joindre la disscussion " + this.state.room.title + " avec le freelanceur " + this.state.room.freelancer_first_name + " " + this.state.room.freelancer_last_name + " sur notre plateforme pour disscuter les détails du projet." +
                '<br/>' +
                "<a href=" + "https://www.freelancemada.ovh" + ">Lien.</b></a>" +
                '<br/>' +
                '<br/><br/>' +
                "Cordialement." +
                '<br/><br/>' +
                '<br/><br/>FreelanceMada'
        };

        maillingService.sendMailByHtmlText(mailData).then(res => {
            console.log(res)
        }).catch(err => {
            console.log(err)
        })
    }

    sendEmailConfirm = () => {
        const mailData = {
            emailFrom: "dev.rocketbonds@gmail.com",
            nameFrom: "FreelanceMada",
            to: [
                {
                    Email: this.state.room.members[1],
                    Name: ""
                }
            ],
            subject: "Mission confirmé",
            htmlText: 'Bonjour ' + this.state.room.freelancer_first_name + ', <br/> <br/> ' +
                "Nous sommes ravis de vous informer que nous avons le plaisir de vous offrir le poste de " + this.state.room.title + ". Après avoir examiné votre profil, nous sommes convaincus que votre expérience et vos compétences seront des atouts précieux pour notre équipe." +
                '<br/>' +
                'Félicitations.' +
                '<br/><br/>' +
                "Cordialement." +
                '<br/><br/>' +
                '<br/><br/>FreelanceMada'
        };

        maillingService.sendMailByHtmlText(mailData).then(res => {
            console.log(res)
        }).catch(err => {
            console.log(err)
        })
    }

    sendEmailRefus = () => {
        const mailData = {
            emailFrom: "dev.rocketbonds@gmail.com",
            nameFrom: "FreelanceMada",
            to: [
                {
                    Email: this.state.room.members[1],
                    Name: ""
                }
            ],
            subject: "Mission refusé",
            htmlText: 'Bonjour ' + this.state.room.freelancer_first_name + ', <br/> <br/> ' +
                "Nous tenons à vous remercier sincèrement d'avoir pris le temps de postuler pour le poste de " + this.state.room.title + ". Nous avons apprécié la qualité de votre candidature et l'intérêt que vous avez manifesté pour notre entreprise." +
                '<br/>' +
                "Après une analyse approfondie de toutes les candidatures reçues, nous regrettons de vous informer que votre candidature n'a pas été retenue pour ce poste particulier. La décision a été difficile à prendre étant donné le nombre élevé de candidatures talentueuses." +
                '<br/>' +
                "Nous encourageons vivement à continuer à rechercher des opportunités qui correspondent à vos compétences et à votre expérience. Nous vous souhaitons beaucoup de succès dans vos futures démarches professionnelles." +
                '<br/><br/>' +
                "Cordialement." +
                '<br/><br/>' +
                '<br/><br/>FreelanceMada'
        };

        maillingService.sendMailByHtmlText(mailData).then(res => {
            console.log(res)
        }).catch(err => {
            console.log(err)
        })
    }


    getChatbotDisscus() {
        this.setState({ messages: [] })
        this.setState({ room_id: "xys97s7rzx-54ns7x95ajg-maqqnfgelm-95zajdpev" })
        rethink.getItemByLabel("FREELANCE_MAD", "rooms", { uid: "xys97s7rzx-54ns7x95ajg-maqqnfgelm-95zajdpev" }).then(roomRes => {
            console.log(roomRes)
            if (roomRes && Array.isArray(roomRes) && roomRes.length > 0) {
                console.log(roomRes)
                let room = roomRes[0]
                this.setState({ room: room })
                rethink.getItemByLabel("FREELANCE_MAD", "chat", { "room_id": "xys97s7rzx-54ns7x95ajg-maqqnfgelm-95zajdpev" }).then(res => {
                    if (res.length < this.state.limit) {
                        this.setState({ hasMore: false, loading: false, messages: res })
                        this.executeBot("initial_bot", this.state.initial_bot, 0)
                    } else {
                        this.setState({
                            loading: false,
                            messages: res,
                            hasMore: true
                        })
                    }
                    setTimeout(() => {
                        this.scrollToBottom();
                    }, 250)
                }).catch(err => {
                    console.log(err)
                })
            }
        }).catch(err => {
        })
    }

    fetchMoreData = () => {
        if (this.state.hasMore === true) {
            this.setState({ loadingScroll: true })
            let skipcount = this.state.skipCount + this.state.limit;
            let limit = this.state.limit
            rethink.getItemByLabel("FREELANCE_MAD", "chat", { "room_id": this.state.room_id }).then(res => {
                console.log(res.length)
                if (res.length < this.state.limit) {
                    this.setState({
                        skipCount: skipcount, limit: limit,
                        loading: false,
                        messages: this.state.messages.concat(res),
                        hasMore: false, loadingScroll: false
                    })
                } else {
                    this.setState({
                        skipCount: skipcount, limit: limit,
                        loading: false,
                        messages: this.state.messages.concat(res),
                        hasMore: true, loadingScroll: false
                    })
                }
                setTimeout(() => {
                    this.scrollPlus(20)
                }, 250)

            }).catch(err => {
                console.log(err)
            })
        } else {
            /*toast.info("Plus de messages !", {
                position: toast.POSITION.BOTTOM_CENTER,
                hideProgressBar: true,
                closeOnClick: true,
            });*/
        }
    };

    scrollPlus = (x) => {
        ReactDOM.findDOMNode(this.messageList).scrollTop = x;
    }

    scrollToBottom = () => {
        if (this.messageList) {
            console.log(this.messageList)
            const scrollHeight = this.messageList.scrollHeight;
            const height = this.messageList.clientHeight;
            const maxScrollTop = scrollHeight - (height - 30);
            ReactDOM.findDOMNode(this.messageList).scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
        }
    }



    getTableChanges(ust_token, db, table, table_name) {

        let socket = new WebSocket("wss://api.smartdom.ch/ws/" + ust_token);

        socket.onopen = (e) => {
            console.log("Connection established");
            let payload;
            payload = { "cmd": table, "db": db, "read_change": true }
            socket.send(JSON.stringify(payload));
        };


        socket.onmessage = (event) => {

            let data = this.state[table_name];
            let recieve = JSON.parse(event.data);
            //update
            if (recieve.new_val && recieve.old_val) {
                let index_to_updated = data.findIndex(x => x.id === recieve.old_val.id)
                data[index_to_updated] = recieve.new_val;
                this.setState({ [table_name]: data })
            }
            //insert
            else if (recieve.new_val) {
                data.push(recieve.new_val)
                this.setState({ [table_name]: data })
            }
            //remove
            else if (recieve.old_val) {
                data.splice(data.findIndex(x => x.id === recieve.old_val.id), 1);
                this.setState({ [table_name]: data })
            }
        }
        socket.error = function (event) {
            console.log("ERROR INITIALISIATION TABLE");
        };
        socket.onclose = (event => {
            this.getTableChanges(ust_token, db, table, table_name)
            console.log("CLOSED READ CHANGES");
        })
    }

    // function to get the user
    getUserFname(contacts, email) {
        let find = contacts.find(x => x.email === email);
        if (find) {
            return (find.nom || "") + " " + (find.prenom || "")
        } else {
            return email
        }
    }

    async addNewMessage(text, bot_name) {
        this.setState({ isUpdateChat: false })
        if (text.trim() !== "") {
            console.log(this.interval)
            let newItem = {
                uid: utilFunctions.getUID(),
                text: text,
                created_at: moment().toDate(),
                room_id: this.state.room_id,
                sender: {
                    email: localStorage.getItem("email")
                },
                type: "text",
                tagged_to: this.state.taggedMsg !== "" ? (this.state.taggedMsg.uid || this.state.taggedMsg.id) : "false"
            }
            let msgs = this.state.messages || [];
            msgs.push(newItem)
            this.setState({ messages: msgs, text: "", taggedMsg: "", showTaggedMsgForm: false })
            setTimeout(() => {
                this.scrollToBottom()
            }, 250)
            rethink.insert("FREELANCE_MAD", 'chat', newItem).then(resAdd => {
                console.log(resAdd)
                if (resAdd && resAdd === true) {
                    this.setState({ isUpdateChat: true })
                } else {
                    console.log("Erreur add msg chat !")
                }
            }).catch(err => {
                console.log(err)
            })
        }
    }

    addTmpNewMessage(text, type) {
        let newItem = {
            uid: utilFunctions.getUID(),
            text: text,
            created_at: moment().format("YYYY-MM-DD HH:mm:ss"),
            room_id: this.state.room_id,
            sender: {
                email: (type && type === "bot") ? "ChatBot" : localStorage.getItem("email")
            },
            type: "text",
            tagged_to: this.state.taggedMsg !== "" ? (this.state.taggedMsg.uid || this.state.taggedMsg.id) : "false"
        }
        let msgs = this.state.messages || [];
        msgs.push(newItem)
        this.setState({ messages: msgs, text: "", taggedMsg: "", showTaggedMsgForm: false })
        setTimeout(() => {
            this.scrollToBottom()
        }, 250)
    }

    addAudio(b64, duration) {

        let newItem = {
            uid: utilFunctions.getUID(),
            created_at: moment().toDate(),
            room_id: this.state.room_id,
            sender: {
                email: localStorage.getItem("email")
            },
            type: "audio",
            b64: b64,
            duration: duration / 1000,
            tagged_to: this.state.taggedMsg !== "" ? (this.state.taggedMsg.uid || this.state.taggedMsg.id) : "false"
        }
        let msgs = this.state.messages || [];
        msgs.push(newItem)
        this.setState({ messages: msgs, text: "", taggedMsg: "", showTaggedMsgForm: false })
        setTimeout(() => {
            this.scrollToBottom()
        }, 250)

        if (this.state.is_bot_active === true) {
            let msgItem = {
                queryInput: {
                    text: {
                        text: "", languageCode: "fr"
                    },
                }
            }
            VoiceRecognitionService.b64VoiceToText({ b64: b64 }).then(res => {
                if (res.status === 200 && res.succes === true) {
                    msgItem.queryInput.text = res.data.text
                    DialogFlowService.beginChat(msgItem, "8e622031-4ec4-4b28-838a-3d845df555e4", "webdemo-27a2d311-e1bb-7745-0126-c552ccc921b1", "webdemo").then(resData => {
                        let data = JSON.parse(resData.replace(")]}'", ""))
                        let newItem = {
                            uid: utilFunctions.getUID(),
                            text: data.queryResult.fulfillmentText,
                            created_at: moment().toDate(),
                            room_id: this.state.room_id,
                            sender: {
                                email: "ChatBot"
                            },
                            type: "text",
                            tagged_to: this.state.taggedMsg !== "" ? (this.state.taggedMsg.uid || this.state.taggedMsg.id) : "false"
                        }
                        let msgs = this.state.messages || [];
                        msgs.push(newItem)
                        this.setState({ messages: msgs, text: "", taggedMsg: "", showTaggedMsgForm: false })
                        setTimeout(() => {
                            this.scrollToBottom()
                        }, 250)
                    }).catch(err => {
                        console.log(err)
                    })
                } else {
                    let newItem = {
                        uid: utilFunctions.getUID(),
                        text: "J'ai pas pu bien vous comprendre, essayer encore une fois",
                        created_at: moment().toDate(),
                        room_id: this.state.room_id,
                        sender: {
                            email: "ChatBot"
                        },
                        type: "text",
                        tagged_to: this.state.taggedMsg !== "" ? (this.state.taggedMsg.uid || this.state.taggedMsg.id) : "false"
                    }
                    let msgs = this.state.messages || [];
                    msgs.push(newItem)
                    this.setState({ messages: msgs, text: "", taggedMsg: "", showTaggedMsgForm: false })
                    setTimeout(() => {
                        this.scrollToBottom()
                    }, 250)
                }
            }).catch(err => {
                console.log(err)
                let newItem = {
                    uid: utilFunctions.getUID(),
                    text: "J'ai pas pu bien vous comprendre, essayer encore une fois",
                    created_at: moment().toDate(),
                    room_id: this.state.room_id,
                    sender: {
                        email: "ChatBot"
                    },
                    type: "text",
                    tagged_to: this.state.taggedMsg !== "" ? (this.state.taggedMsg.uid || this.state.taggedMsg.id) : "false"
                }
                let msgs = this.state.messages || [];
                msgs.push(newItem)
                this.setState({ messages: msgs, text: "", taggedMsg: "", showTaggedMsgForm: false })
                setTimeout(() => {
                    this.scrollToBottom()
                }, 250)
            })


        } else {
            rethink.insert("FREELANCE_MAD", "chat", newItem).then(resAdd => {
                if (resAdd && resAdd === true) {
                    console.log("audio added")
                } else {
                    console.log("Erreur add audio in chat !")
                }
            }).catch(err => {
                console.log(err)
            })
        }


    }

    async addB64File(event, b64, fileName) {
        this.setState({ loading: true, isUpdateChat: false })
        let file = event.target.files[0]
        let newItem = {
            uid: utilFunctions.getUID(),
            // b64: b64 ? b64 : await utilFunctions.toBase64(event.target.files[0]),
            created_at: moment().toDate(),
            room_id: this.state.room_id,
            sender: {
                email: localStorage.getItem("email")
            },
            type: "ged_file",
            name: file ? file.name : fileName,
            file_type: b64 ? "devis" : "file"
        }
        let msgs = this.state.messages || [];
        msgs.push(newItem)
        this.setState({ messages: msgs })
        setTimeout(() => {
            this.scrollToBottom()
        }, 250)

        rethink.insert("FREELANCE_MAD", 'chat', newItem).then(resAdd => {
            if (resAdd && resAdd === true) {
                this.setState({ loading: false })
                var reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onloadend = (e) => {
                    let result = reader.result
                    let newDoc = {
                        created_at: moment().toDate(),
                        created_by: localStorage.getItem("email"),
                        b64: result,
                        name: file.name,
                        type: file.type,
                        uid: newItem.uid,
                        file_type: b64 ? "devis" : "file"
                    }
                    rethink.insert("FREELANCE_MAD", 'documents', newDoc).then(resAdd => {
                        if (resAdd && resAdd === true) {
                            console.log("INSERT FILE OK")
                            this.setState({ isUpdateChat: true })
                        } else console.log("ERROR INSERT FILE")
                    }).catch(err => {
                        console.log(err)
                        console.log("ERROR INSERT FILE")
                    })
                }
            } else {
                console.log("ERROR ADD MSG !")
            }
        }).catch(err => {
            console.log(err)
        })


    }

    async addB64FileDevis(b64, fileName) {
        this.setState({ isUpdateChat: false })
        let newItem = {
            uid: utilFunctions.getUID(),
            created_at: moment().toDate(),
            room_id: this.state.room_id,
            sender: {
                email: localStorage.getItem("email")
            },
            type: "ged_file",
            sender_role: localStorage.getItem("role"),
            name: "Devis D2024-" + this.state.docDevisNum.toString().padStart(4, '0'),
            file_type: "devis"
        }
        let msgs = this.state.messages || [];
        msgs.push(newItem)
        this.setState({ messages: msgs })
        setTimeout(() => {
            this.scrollToBottom()
        }, 250)
        rethink.insert("FREELANCE_MAD", 'chat', newItem).then(resAdd => {

            if (resAdd && resAdd === true) {
                this.setState({ loading: false })
                let newDoc = {
                    created_at: moment().toDate(),
                    created_by: localStorage.getItem("email"),
                    b64: b64,
                    name: "Devis D2024-" + this.state.docDevisNum.toString().padStart(4, '0'),
                    type: "application/pdf",
                    uid: newItem.uid,
                    file_type: "devis",
                }
                rethink.insert("FREELANCE_MAD", 'documents', newDoc).then(resAdd => {
                    if (resAdd && resAdd === true) {
                        console.log("INSERT FILE OK")
                        this.setState({ isUpdateChat: true })
                    } else console.log("ERROR INSERT FILE")
                }).catch(err => {
                    console.log(err)
                    console.log("ERROR INSERT FILE")
                })
            } else {
                console.log("ERROR ADD MSG !")
            }
        }).catch(err => {
            console.log(err)
        })
    }

    async addB64FileNda(b64, fileName) {
        this.setState({ isUpdateChat: false })
        let newItem = {
            uid: utilFunctions.getUID(),
            created_at: moment().toDate(),
            room_id: this.state.room_id,
            sender: {
                email: localStorage.getItem("email")
            },
            sender_role: localStorage.getItem("role"),
            type: "ged_file",
            name: "NDA D2024-" + this.state.docDevisNum.toString().padStart(4, '0'),
            file_type: "nda"
        }
        let msgs = this.state.messages || [];
        msgs.push(newItem)
        this.setState({ messages: msgs })
        setTimeout(() => {
            this.scrollToBottom()
        }, 250)
        rethink.insert("FREELANCE_MAD", 'chat', newItem).then(resAdd => {
            if (resAdd && resAdd === true) {
                let newDoc = {
                    created_at: moment().toDate(),
                    created_by: localStorage.getItem("email"),
                    b64: b64,
                    name: "NDA D2024-" + this.state.docDevisNum.toString().padStart(4, '0'),
                    type: "application/pdf",
                    uid: newItem.uid,
                    file_type: "nda",
                }
                rethink.insert("FREELANCE_MAD", 'documents', newDoc).then(resAdd => {
                    if (resAdd && resAdd === true) {
                        console.log("INSERT FILE OK")
                        this.setState({ loading: false, isUpdateChat: true })
                    } else console.log("ERROR INSERT FILE")
                }).catch(err => {
                    console.log(err)
                    console.log("ERROR INSERT FILE")
                })
            } else {
                console.log("ERROR ADD MSG !")
            }
        }).catch(err => {
            console.log(err)
        })
    }

    async addPaymentMsg() {
        this.setState({ isUpdateChat: false })
        let newItem = {
            uid: utilFunctions.getUID(),
            created_at: moment().toDate(),
            room_id: this.state.room_id,
            sender: {
                email: localStorage.getItem("email")
            },
            sender_role: localStorage.getItem("role"),
            type: "ged_file",
            name: "Prestation à payer",
            file_type: "payment"
        }
        let msgs = this.state.messages || [];
        msgs.push(newItem)
        this.setState({ messages: msgs })
        setTimeout(() => {
            this.scrollToBottom()
        }, 250)
        rethink.insert("FREELANCE_MAD", 'chat', newItem).then(resAdd => {
            if (resAdd && resAdd === true) {
                this.setState({ isUpdateChat: true })
            } else {
                console.log("ERROR ADD MSG !")
            }
        }).catch(err => {
            console.log(err)
        })
    }

    async addNoteMsg(email, role, text) {
        this.setState({ isUpdateChat: false })
        let newItem = {
            uid: utilFunctions.getUID(),
            created_at: moment().toDate(),
            room_id: this.state.room_id,
            sender: {
                email: email
            },
            sender_role: role,
            type: "ged_file",
            name: text,
            file_type: "note"
        }
        let msgs = this.state.messages || [];
        msgs.push(newItem)
        this.setState({ messages: msgs })
        setTimeout(() => {
            this.scrollToBottom()
        }, 250)
        rethink.insert("FREELANCE_MAD", 'chat', newItem).then(resAdd => {
            if (resAdd && resAdd === true) {
                this.setState({ isUpdateChat: true })
            } else {
                console.log("ERROR ADD MSG !")
            }
        }).catch(err => {
            console.log(err)
        })
    }

    async addVersionMsg() {
        this.setState({ isUpdateChat: false, loading: true })
        let newItem = {
            uid: utilFunctions.getUID(),
            created_at: moment().toDate(),
            room_id: this.state.room_id,
            sender: {
                email: localStorage.getItem("email")
            },
            sender_role: localStorage.getItem("role"),
            type: "ged_file",
            name: this.state.versionTitle,
            file_type: "version",
            version_link: this.state.versionLink,
            version_description: this.state.versionDescription,
            version_finale: this.state.versionFinale
        }
        let msgs = this.state.messages || [];
        msgs.push(newItem)
        this.setState({ messages: msgs })
        setTimeout(() => {
            this.scrollToBottom()
        }, 250)
        rethink.insert("FREELANCE_MAD", 'chat', newItem).then(resAdd => {
            if (resAdd && resAdd === true) {
                this.setState({ isUpdateChat: true, openModalVersion: false, loading: false })
                this.resetValueVersion()
            } else {
                console.log("ERROR ADD MSG !")
            }
        }).catch(err => {
            console.log(err)
        })
    }

    saveDocument = (b64) => {
        let newDoc = {
            created_at: moment().toDate(),
            created_by: localStorage.getItem("email"),
            b64: b64,
            name: "partage " + this.state.nomFreelancer,
            type: "application/pdf",
            file_type: "partage",
        }
        rethink.insert("FREELANCE_MAD", 'documents', newDoc).then(resAdd => {
            if (resAdd && resAdd === true) {
                console.log("INSERT FILE OK")
                this.setState({ loading: false, isUpdateChat: true })
            } else console.log("ERROR INSERT FILE")
        }).catch(err => {
            console.log(err)
            console.log("ERROR INSERT FILE")
        })
    }

    uploadImage = (evt) => {

        if (evt.target.files[0]) {
            let imgToUpload = evt.target.files[0];
            let imgToUploadArray = [];
            imgToUploadArray.push(imgToUpload)

            if (imgToUpload.type === "image/png" || imgToUpload.type === "image/jpeg" || imgToUpload.type === "image/jpg") {
                const Compress = require('compress.js');
                const compress = new Compress()
                compress.compress(imgToUploadArray, {
                    size: 2, // the max size in MB, defaults to 2MB
                    quality: .75, // the quality of the image, max is 1,
                    maxWidth: 1920, // the max width of the output image, defaults to 1920px
                    maxHeight: 1920, // the max height of the output image, defaults to 1920px
                    resize: true, // defaults to true, set false if you do not want to resize the image width and height
                }).then((data) => {
                    console.log(imgToUpload)
                    console.log(data)
                    //this.setState({uploadedImage:data[0].prefix + data[0].data,showImageView:true})
                    this.setState({ uploadedImage: data[0], showImageView: true })

                }).catch((err) => {
                    console.log(err)
                })
            } else {
                toast.error("Type de fichier erroné ! ", {
                    position: toast.POSITION.TOP_CENTER
                });
            }
        }
    };

    upload_bot_image = async (evt) => {
        let img = evt.target.files[0];
        let bot_data = this.state.bot_recovered_data
        bot_data.poi_image_file = img
        this.state.bot_recovered_data.uploaded_plan_img = img
        let b64_image = await utilFunctions.toBase64(img)
        let imageItem = {
            prefix: "",
            data: b64_image
        }
        this.setState({ bot_recovered_data: bot_data, uploadedImage: imageItem, showImageView: true })
        console.log(imageItem)
    }

    async sendAnnotatedImage(b64) {
        this.setState({ isUpdateChat: false })
        let newB64 = await utilFunctions.compressB64Image(b64)
        console.log(newB64)
        let newItem = {
            uid: utilFunctions.getUID(),
            text: newB64.prefix + newB64.data,
            name: "annotated-image",
            created_at: moment().toDate(),
            room_id: this.state.room_id,
            sender: {
                email: localStorage.getItem("email")
            },
            type: "image",
            tagged_to: this.state.taggedMsg !== "" ? (this.state.taggedMsg.uid || this.state.taggedMsg.id) : "false"
        }
        let msgs = this.state.messages || [];
        msgs.push(newItem)
        this.setState({ messages: msgs, text: "", taggedMsg: "", showTaggedMsgForm: false })
        setTimeout(() => {
            this.scrollToBottom()
        }, 250)
        rethink.insert("FREELANCE_MAD", 'chat', newItem).then(resAdd => {
            if (resAdd && resAdd === true) {
                console.log("Image added")
                this.setState({ isUpdateChat: true })
            } else {
                console.log("Erreur add msg chat !")
            }
        }).catch(err => {
            console.log(err)
        })
    }


    downloadB64File(uid) {
        this.setState({ loading: true })
        rethink.getItemByUID("FREELANCE_MAD", "documents", uid, "test").then(res => {
            console.log(res)
            if (res && res !== false) {
                this.setState({ loading: false })
                let a = document.createElement('a');
                a.href = res[0].b64
                a.download = (res[0].name) || "Document";
                a.click();
            } else {
                this.setState({ loading: false })
                toast.error("Une erreur est survenue, veuillez réessayer ultérieurement", {
                    position: toast.POSITION.TOP_CENTER
                });
            }
        }).catch(err => {
            console.log(err)
            this.setState({ loading: false })
            toast.error("Une erreur est survenue, veuillez réessayer ultérieurement", {
                position: toast.POSITION.TOP_CENTER
            });
        })
    }

    getB64ByUid = async (uid) => {
        return new Promise(resolve => {
            apiNodeService.filterTable({ table: "documents", filter: { uid: uid }, db: "FREELANCE_MAD" }).then(userRes => {
                console.log(userRes)
                if (userRes && userRes !== "false" && Array.isArray(userRes.data)) {
                    resolve(userRes.data[0].b64)
                } else {
                    resolve("false")
                }
            }).catch(err => resolve("false"))
        })
    }
    async executeBot(bot_name, bot, question_id) {
        this.setState({ anchorElMsgMenu: null })
        let s_bot = bot
        s_bot.currentQuest = question_id;
        this.setState({ [bot_name]: s_bot })
        let msgs = this.state.messages || [];
        let find_question = s_bot.questions.find(x => x.id === question_id)
        console.log(find_question)
        if (find_question) {
            this.start_loading_bot()
            setTimeout(() => {
                this.scrollToBottom();
            }, 200)
            await find_question.action_before()
            this.stop_loading_bot()
            msgs.push({
                created_at: moment().toDate(),
                id: utilFunctions.getUID(),
                room_id: this.state.room_id,
                text: find_question.question,
                type: "text",
                sender: {
                    email: "ChatBot"
                }
            })
            this.setState({ messages: msgs })

            setTimeout(async () => {

                this.scrollToBottom();

                if (find_question.suggestions.type !== "text") {

                    if (find_question.suggestions.type === "date") {
                        setTimeout(() => {
                            this.datePickerRef.click()
                        }, 700)
                    }
                    else if (find_question.suggestions.type === "bim_objects") {
                        setTimeout(async () => {
                            msgs.push({
                                created_at: moment().toDate(),
                                id: utilFunctions.getUID(),
                                room_id: this.state.room_id,
                                text: "",
                                type: find_question.suggestions.type,
                                sender: {
                                    email: "ChatBot"
                                },
                                extra: {
                                    bim_objects: this.state.bot_recovered_data.finded_bim_objects
                                }
                            })
                            this.setState({ messages: msgs })
                            setTimeout(() => {
                                this.scrollToBottom()
                            }, 250)
                            if (find_question.action_after) {
                                console.log("action after")
                                this.start_loading_bot()
                                setTimeout(() => {
                                    this.scrollToBottom();
                                }, 200)
                                await find_question.action_after();
                                this.stop_loading_bot()
                            }
                        }, 500)
                    } else if (find_question.suggestions.type === "plan_projects") {
                        setTimeout(async () => {
                            msgs.push({
                                created_at: moment().toDate(),
                                id: utilFunctions.getUID(),
                                room_id: this.state.room_id,
                                text: "",
                                type: find_question.suggestions.type,
                                sender: {
                                    email: "ChatBot"
                                },
                                extra: {
                                    plan_projects: this.state.projects_plan
                                }
                            })
                            this.setState({ messages: msgs })
                            setTimeout(() => {
                                this.scrollToBottom()
                            }, 250)
                            if (find_question.action_after) {
                                console.log("action after")
                                this.start_loading_bot()
                                setTimeout(() => {
                                    this.scrollToBottom();
                                }, 200)
                                await find_question.action_after();
                                this.stop_loading_bot()
                            }
                        }, 500)
                    }
                    else {
                        setTimeout(() => {
                            msgs.push({
                                created_at: moment().toDate(),
                                id: utilFunctions.getUID(),
                                room_id: this.state.room_id,
                                text: "",
                                type: find_question.suggestions.type,
                                sender: {
                                    email: "ChatBot"
                                },
                                extra: find_question
                            })
                            this.setState({ messages: msgs })
                            setTimeout(() => {
                                this.scrollToBottom()
                            }, 250)

                        }, 200)
                    }
                }
                else {
                    if (find_question.action_after) {
                        console.log("action after")
                        this.start_loading_bot()
                        setTimeout(() => {
                            this.scrollToBottom();
                        }, 200)
                        await find_question.action_after();
                        this.stop_loading_bot()
                    }
                }
            }, 250)

        } else {

        }
    }

    start_loading_bot(timeout) {
        let msgs = this.state.messages;
        msgs.push({
            id: "loading",
            type: "loading",
            sender: {
                email: "ChatBot"
            }
        })
        this.setState({ messages: msgs })
        setTimeout(() => {
            this.scrollToBottom();
        }, 200)

        if (timeout && timeout > 100) {
            setTimeout(() => {
                let find = msgs.findIndex(x => x.id === "loading");
                msgs.splice(find, 1)
                this.setState({ messages: msgs })
            }, timeout)
        }
    }

    stop_loading_bot() {
        let msgs = this.state.messages;
        let find = msgs.findIndex(x => x.id === "loading");
        msgs.splice(find, 1)
        this.setState({ messages: msgs })
    }

    setProjectPlan(item) {
        let bot_recovered_data = this.state.bot_recovered_data
        bot_recovered_data.plan_project_fdma = item
        this.setState({ bot_recovered_data: bot_recovered_data })
        this.executeBot("bot_treeporteur", this.state.bot_treeporteur, 3)
        console.log(this.state.bot_recovered_data)
    }

    renderMsgResponseForm() {
        let type = this.state.taggedMsg.type;

        return (
            <div className="response_container">
                <div className="response_container_display">
                    <div className="response_container_left_comp" style={{ position: "relative" }}>
                        <div className="response_container_left_comp_container">
                            <span className="left_comp_container_bar" />
                            <div className="left_comp_container_text_div">
                                <div style={{ maxWidth: 500 }}>
                                    <div className="left_comp_container_text_title">
                                        <span>
                                            {
                                                localStorage.getItem("email") === this.state.taggedMsg.sender.email ? "Vous" : this.getUserFname(this.state.contacts || [], this.state.taggedMsg.sender.email)
                                            }
                                        </span>
                                    </div>
                                    <div className="left_comp_container_text_desc">
                                        <span>
                                            {
                                                type === "audio" ?
                                                    <span style={{ marginRight: 4, color: "#4fc3f7" }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width={12} height={20}
                                                            viewBox="0 0 12 20">
                                                            <path
                                                                d="M6 11.745a2 2 0 0 0 2-2V4.941a2 2 0 0 0-4 0v4.803a2 2 0 0 0 2 2.001zm3.495-2.001c0 1.927-1.568 3.495-3.495 3.495s-3.495-1.568-3.495-3.495H1.11c0 2.458 1.828 4.477 4.192 4.819v2.495h1.395v-2.495c2.364-.342 4.193-2.362 4.193-4.82H9.495v.001z"
                                                                fill="currentColor"
                                                            />
                                                        </svg>
                                                    </span> : type === "image" ?
                                                        <span style={{ marginRight: 4, color: "#4fc3f7" }}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width={12} height={20}
                                                                viewBox="0 0 12 20">
                                                                <path
                                                                    d="M13.822 4.668H7.14l-1.068-1.09a1.068 1.068 0 0 0-.663-.278H3.531c-.214 0-.51.128-.656.285L1.276 5.296c-.146.157-.266.46-.266.675v1.06l-.001.003v6.983c0 .646.524 1.17 1.17 1.17h11.643a1.17 1.17 0 0 0 1.17-1.17v-8.18a1.17 1.17 0 0 0-1.17-1.169zm-5.982 8.63a3.395 3.395 0 1 1 0-6.79 3.395 3.395 0 0 1 0 6.79zm0-5.787a2.392 2.392 0 1 0 0 4.784 2.392 2.392 0 0 0 0-4.784z"
                                                                    fill="currentColor"
                                                                />
                                                            </svg>
                                                        </span> : type === "ged_file" ?
                                                            <span style={{ marginRight: 4, color: "#4fc3f7" }}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width={12} height={20}
                                                                    viewBox="0 0 12 20">
                                                                    <path
                                                                        d="M10.2 3H2.5C1.7 3 1 3.7 1 4.5v10.1c0 .7.7 1.4 1.5 1.4h7.7c.8 0 1.5-.7 1.5-1.5v-10C11.6 3.7 11 3 10.2 3zm-2.6 9.7H3.5v-1.3h4.1v1.3zM9.3 10H3.5V8.7h5.8V10zm0-2.7H3.5V6h5.8v1.3z"
                                                                        fill="currentColor"
                                                                    />
                                                                </svg>
                                                            </span> : null


                                            }
                                            {type === "text" ? this.state.taggedMsg.text : type === "audio" ? utilFunctions.toMMSS(this.state.taggedMsg.duration.toString()) : type === "image" ? "Photo" : type === "ged_file" ? this.state.taggedMsg.name_in_ged : ""}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            type === "image" &&
                            <div style={{ position: "absolute", bottom: 0, right: 0 }}>
                                <img alt="" src={this.state.taggedMsg.text} style={{ width: 83, height: 83 }} />
                            </div>
                        }
                    </div>
                    <div className="response_container_right_comp">
                        <div role="button"
                            onClick={() => {
                                this.setState({ showTaggedMsgForm: false, taggedMsg: "" })
                            }}
                        >
                            <span>
                                <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24}
                                    viewBox="0 0 24 24">
                                    <path
                                        d="M 19.1 17.2 l -5.3 -5.3 l 5.3 -5.3 l -1.8 -1.8 l -5.3 5.4 l -5.3 -5.3 l -1.8 1.7 l 5.3 5.3 l -5.3 5.3 L 6.7 19 l 5.3 -5.3 l 5.3 5.3 l 1.8 -1.8 Z"
                                        fill="currentColor"
                                    />
                                </svg>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    handleClickOutside = (event) => {
        if (this.popupPlusRef.current && this.popupPlusRef.current.contains(event.target)) {
            this.setState({ showPlusFormMenu: true });
        } else {
            if (this.plusBtnRef.current && this.plusBtnRef.current.contains(event.target)) {
                return;
            }
            this.setState({ showPlusFormMenu: false });
        }
    }

    renderBtnPlusForm() {
        return (
            <div className="plus_form_container">
                <div className="plus-form-container-display p-4" ref={this.popupPlusRef} style={{ gap: 8 }}>
                    <div className="ml-2 mr-2"
                        onClick={async (event) => {
                            this.setState({ showPlusFormMenu: false })
                            this.imageUpload.click()
                        }}
                    >
                        <div className="d-flex align-items-center" style={{ gap: 20, cursor: 'pointer' }}>
                            <PermMediaOutlinedIcon fontSize="meduim" color="primary" />
                            <span style={{ fontSize: 14, fontWeight: 600 }}>Photos et vidéos</span>
                        </div>
                    </div>
                    <div className="ml-2 mr-2 mt-2"
                        onClick={() => {
                            this.setState({ showPlusFormMenu: false })
                            this.fileUpload.click()
                        }}
                    >
                        <div className="d-flex align-items-center" style={{ gap: 20, cursor: 'pointer' }}>
                            <FilePresentOutlinedIcon fontSize="meduim" color="primary" />
                            <span style={{ fontSize: 14, fontWeight: 600 }}>Documents</span>
                        </div>
                    </div>
                    {localStorage.getItem("role") === "freelance" &&
                        <div className="ml-2 mr-2 mt-2"
                            onClick={() => {
                                this.setState({ showPlusFormMenu: false, openModalDocumentSign: true })
                            }}
                        >
                            <div className="d-flex align-items-center" style={{ gap: 20, cursor: 'pointer' }}>
                                <FilePresentOutlinedIcon fontSize="meduim" color="primary" />
                                <span style={{ fontSize: 14, fontWeight: 600 }}>Générer un contrat de confidentialité (NDA)</span>
                            </div>
                        </div>
                    }
                    {localStorage.getItem("role") === "freelance" &&
                        <div className="ml-2 mr-2 mt-2"
                            onClick={async () => {
                                let signatureB64Freelance = await this.getB64SignFreeMada()
                                this.setState({ signatureB64Freelance: signatureB64Freelance })
                                this.setState({ showPlusFormMenu: false, openModalDocumentPartage: true })
                            }}
                        >
                            <div className="d-flex align-items-center" style={{ gap: 20, cursor: 'pointer' }}>
                                <FilePresentOutlinedIcon fontSize="meduim" color="primary" />
                                <span style={{ fontSize: 14, fontWeight: 600 }}>Générer un contrat de partage de revenu (ISA)</span>
                            </div>
                        </div>
                    }
                    {/* this.setState({ openModalInfo: true }) */}
                    {localStorage.getItem("role") === "freelance" &&
                        <div className="ml-2 mr-2 mt-2"
                            onClick={() => {
                                this.setState({ showPlusFormMenu: false, openModalInfo: true, isUpdateChat: false })
                            }}
                        >
                            <div className="d-flex align-items-center" style={{ gap: 20, cursor: 'pointer' }}>
                                <ListOutlinedIcon fontSize="meduim" color="primary" />
                                <span style={{ fontSize: 14, fontWeight: 600 }}>Créer un devis</span>
                            </div>
                        </div>
                    }
                    {(localStorage.getItem("role") === "freelance" && this.state.room.nda_porteur === "signed") &&
                        <div className="ml-2 mr-2 mt-2"
                            onClick={() => {
                                this.setState({ showPlusFormMenu: false, openModalVersion: true })
                            }}
                        >
                            <div className="d-flex align-items-center" style={{ gap: 20, cursor: 'pointer' }}>
                                <WorkHistoryIcon fontSize="meduim" color="primary" />
                                <span style={{ fontSize: 14, fontWeight: 600 }}>Envoyer une version</span>
                            </div>
                        </div>
                    }
                </div>
            </div>
        )
    }

    renderTaggedMsg(msg, taggedMsgId) {
        let findTaggedMsg = this.state.messages.find(x => x.uid === taggedMsgId || x.id === taggedMsgId);
        if (findTaggedMsg) {
            return (
                <div className="response_container_left_comp"
                    style={{ position: "relative", marginLeft: 0, marginTop: 12, marginBottom: 5, cursor: "pointer" }}
                    onClick={() => {
                        const element = document.getElementById(taggedMsgId)
                        element.scrollIntoView({ behavior: 'smooth', block: 'start' })
                    }}
                >
                    <div className="response_container_left_comp_container">
                        <span className="left_comp_container_bar" />
                        <div className="left_comp_container_text_div">
                            <div style={{ maxWidth: 500 }}>
                                <div className="left_comp_container_text_title">
                                    <span>
                                        {
                                            localStorage.getItem("email") === findTaggedMsg.sender.email ? "Vous" : this.getUserFname(this.state.contacts || [], findTaggedMsg.sender.email)
                                        }
                                    </span>
                                </div>
                                <div className="left_comp_container_text_desc">
                                    <span>
                                        {
                                            findTaggedMsg.type === "audio" ?
                                                <span style={{ marginRight: 4, color: "#4fc3f7" }}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width={12} height={20}
                                                        viewBox="0 0 12 20">
                                                        <path
                                                            d="M6 11.745a2 2 0 0 0 2-2V4.941a2 2 0 0 0-4 0v4.803a2 2 0 0 0 2 2.001zm3.495-2.001c0 1.927-1.568 3.495-3.495 3.495s-3.495-1.568-3.495-3.495H1.11c0 2.458 1.828 4.477 4.192 4.819v2.495h1.395v-2.495c2.364-.342 4.193-2.362 4.193-4.82H9.495v.001z"
                                                            fill="currentColor"
                                                        />
                                                    </svg>
                                                </span> :
                                                findTaggedMsg.type === "image" ?
                                                    <span style={{ marginRight: 4, color: "#4fc3f7" }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width={12} height={20}
                                                            viewBox="0 0 12 20">
                                                            <path
                                                                d="M13.822 4.668H7.14l-1.068-1.09a1.068 1.068 0 0 0-.663-.278H3.531c-.214 0-.51.128-.656.285L1.276 5.296c-.146.157-.266.46-.266.675v1.06l-.001.003v6.983c0 .646.524 1.17 1.17 1.17h11.643a1.17 1.17 0 0 0 1.17-1.17v-8.18a1.17 1.17 0 0 0-1.17-1.169zm-5.982 8.63a3.395 3.395 0 1 1 0-6.79 3.395 3.395 0 0 1 0 6.79zm0-5.787a2.392 2.392 0 1 0 0 4.784 2.392 2.392 0 0 0 0-4.784z"
                                                                fill="currentColor"
                                                            />
                                                        </svg>
                                                    </span> : findTaggedMsg.type === "ged_file" ?
                                                        <span style={{ marginRight: 4, color: "#4fc3f7" }}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width={12} height={20}
                                                                viewBox="0 0 12 20">
                                                                <path
                                                                    d="M10.2 3H2.5C1.7 3 1 3.7 1 4.5v10.1c0 .7.7 1.4 1.5 1.4h7.7c.8 0 1.5-.7 1.5-1.5v-10C11.6 3.7 11 3 10.2 3zm-2.6 9.7H3.5v-1.3h4.1v1.3zM9.3 10H3.5V8.7h5.8V10zm0-2.7H3.5V6h5.8v1.3z"
                                                                    fill="currentColor"
                                                                />
                                                            </svg>
                                                        </span> : null
                                        }
                                        {findTaggedMsg.type === "text" ? findTaggedMsg.text : findTaggedMsg.type === "audio" ? utilFunctions.toMMSS(findTaggedMsg.duration.toString()) : findTaggedMsg.type === "image" ? "Photo" : findTaggedMsg.type === "ged_file" ? findTaggedMsg.name_in_ged : ""}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        findTaggedMsg.type === "image" &&
                        <div style={{ position: "absolute", bottom: 0, right: 0 }}>
                            <img alt="" src={findTaggedMsg.text}
                                style={{ width: 60, height: 83, objectFit: "unset", borderRadius: "inherit" }} />
                        </div>
                    }
                </div>
            )
        }
    }

    getProjectPlans() {
        return new Promise(resolve => {
            rethink.getTableData("FREELANCE_MAD", "test", "plans").then(res => {
                if (res && res !== "false" && Array.isArray(res)) {
                    let user_plans = [];
                    res.map((project, key) => {
                        if (project.users && project.users.find(x => x.email === localStorage.getItem("email"))) {
                            user_plans.push(project)
                        }
                    })
                    user_plans.sort((a, b) => {
                        var c = new Date(a.created_at);
                        var d = new Date(b.created_at);
                        return d - c;
                    })
                    console.log(user_plans)
                    resolve(user_plans.map(item => { return { value: item.uid, label: item.name, image: item.thumbnail ? (item.thumbnail.prefix + item.thumbnail.data) : "", image_uid: item.image_uid, project: item.project } }))
                } else {
                    resolve("false")
                }

            }).catch(err => {
                console.log(err)
                resolve("false")
            })
        })
    }

    render() {

        const msgs = this.state.messages.sort((a, b) => {
            var c = new Date(a.created_at);
            var d = new Date(b.created_at);
            return c - d;
        })

        const openMsgMenuPopup = Boolean(this.state.anchorElMsgMenu);
        const id3 = openMsgMenuPopup ? 'msg-menu-popover' : undefined;

        return (
            <div>
                <MuiBackdrop open={this.state.loading} text={"Chargement..."} />
                {
                    (this.state.room && this.state.room.id) ?
                        <div className="d-flex">
                            <div style={{ width: 230, marginTop: 45, maxHeight: "85vh", overflowY: 'auto' }}>
                                {/* <div onClick={(e) => {
                                if (this.state.room_id !== "xys97s7rzx-54ns7x95ajg-maqqnfgelm-95zajdpev") {
                                    this.setState({ loading: true, room_title: "ChatBot" })
                                    this.getChatbotDisscus()
                                }
                              }}
                                className="d-flex align-items-center p-2 group-item" style={{ gap: 5, width: "100%", cursor: "pointer" }}>
                                <div className="chatbot-avatar">
                                    <Avatar alt="Remy Sharp" src="/chatbot.svg" sx={{ bgcolor: "#0A3936", width: 40, height: 40 }} />
                                </div>
                                <span className="group-title text-truncate">Freelance Mada</span>
                            </div> */}
                                <div className="d-flex flex-column">
                                    {this.state.roomsList.length > 0 &&
                                        this.state.roomsList.map((item, key) => (
                                            <div key={key} onClick={(e) => {
                                                if (this.state.room_id !== item.uid) {
                                                    this.setState({ is_bot_active: false, active_bot: "", room_title: item.title })
                                                    this.setState({ loading: true })
                                                    this.updateChat(item.uid)
                                                }
                                            }}
                                                className="d-flex align-items-center p-2 group-item" style={{ gap: 5, width: "100%", cursor: "pointer", backgroundColor: this.state.room_id === item.uid ? "#e7f2ff" : "" }}>
                                                <StyledBadge
                                                    key={key}
                                                    overlap="circular"
                                                    className="group-avatar"
                                                    anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                                                    variant="dot"
                                                >
                                                    <Avatar sx={{ width: 40, height: 40, border: "2px solid #0A3936", backgroundColor: "#d1e9e7b0" }}
                                                        alt="Remy Sharp"
                                                        src="/avatar12.png"
                                                    />
                                                </StyledBadge>
                                                <div className="d-flex flex-column" style={{ lineHeight: 1.3 }}>
                                                    <Tooltip title={item.title} arrow>
                                                        <span className="group-title text-truncate">{item.title}</span>
                                                    </Tooltip>
                                                    <span className="group-title text-truncate" style={{ fontSize: 14, color: "#787a7c", fontWeight: 600, marginTop: 2 }}> {localStorage.getItem('role') === "porteur" ? item.freelancer_first_name + " " + item.freelancer_last_name : item.porteur_first_name + " " + item.porteur_last_name}</span>
                                                    <div className="p-0" style={{ width: 135 }}>
                                                        {(item.offre_state === 'en cours') ?
                                                            <span style={{ fontSize: 10, color: "#787a7c" }}>En cours de négociation</span> :
                                                            item.offre_state === 'waiting' ?
                                                                <span style={{ fontSize: 10, color: "#787a7c" }}>En cours de négociation</span> :
                                                                item.offre_state === 'confirmed' ?
                                                                    <span style={{ fontSize: 10, color: "#787a7c" }}>En attente de paiement</span> :
                                                                    item.offre_state === 'payed' ?
                                                                        <span style={{ fontSize: 10, color: "#787a7c" }}>En cours de réalisation</span> :
                                                                        <span style={{ fontSize: 10, color: "#787a7c" }}>Mission terminée</span>
                                                        }
                                                    </div>
                                                </div>
                                                <div style={{ width: "100%" }} className="d-flex justify-content-end">
                                                    {(item.offre_state === 'en cours') ?
                                                        <img width={20} height={20} src="/encours.png" /> :
                                                        item.offre_state === 'waiting' ?
                                                            <img width={20} height={20} src="/confirmed.png" /> :
                                                            item.offre_state === 'confirmed' ?
                                                                <img width={20} height={20} src="/payment.png" /> :
                                                                item.offre_state === 'payed' ?
                                                                    <img width={20} height={20} src="/versiontime.png" /> :
                                                                    <img width={20} height={20} src="/versiondone.png" />
                                                    }
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                            {this.state.room &&
                                <>
                                    {(localStorage.getItem("role") === "freelance" && this.state.is_bot_active !== true && this.state.room.offre_state === "en cours") &&
                                        <div className="alert-info-style" style={{ marginTop: 90, position: 'absolute', zIndex: 99, paddingRight: 11 }}>
                                            <Alert
                                                iconMapping={{
                                                    info: <InfoOutlinedIcon style={{ color: "#18328F" }} />,
                                                }}
                                                style={{ border: "1px solid #18328F", backgroundColor: "#E9F4FF", alignItems: 'center' }} severity="info">Après avoir discuté et convenu des détails et du montant de la mission avec le porteur ou la porteuse de projet, n'oubliez pas de générer un devis en cliquant sur le bouton (+) plus bas.</Alert>

                                        </div>
                                    }
                                    {(localStorage.getItem("role") === "porteur" && this.state.room.offre_state === "en cours") &&
                                        <div className="alert-info-style" style={{ marginTop: 90, position: 'absolute', zIndex: 99, paddingRight: 11 }}>
                                            <Alert
                                                iconMapping={{
                                                    info: <InfoOutlinedIcon style={{ color: "#18328F" }} />,
                                                }}
                                                style={{ border: "1px solid #18328F", backgroundColor: "#E9F4FF", alignItems: 'center' }} severity="info">Une fois les négociations avec le freelance terminées, ce dernier vous enverra un devis, vérifiez le bien avant de le signer !</Alert>

                                        </div>
                                    }
                                    {(localStorage.getItem("role") === "freelance" && this.state.is_bot_active !== true && this.state.room.offre_state === "confirmed") &&
                                        <div className="alert-info-style" style={{ marginTop: 90, position: 'absolute', zIndex: 99, paddingRight: 11 }}>
                                            <Alert
                                                iconMapping={{
                                                    info: <InfoOutlinedIcon style={{ color: "#18328F" }} />,
                                                }}
                                                style={{ border: "1px solid #18328F", backgroundColor: "#E9F4FF", alignItems: 'center' }} severity="info">En attente du porteur pour le paiement </Alert>

                                        </div>
                                    }
                                    {(localStorage.getItem("role") === "freelance" && this.state.is_bot_active !== true && this.state.room.offre_state === "payed") &&
                                        <div className="alert-info-style" style={{ marginTop: 90, position: 'absolute', zIndex: 99, paddingRight: 11 }}>
                                            <Alert
                                                iconMapping={{
                                                    info: <InfoOutlinedIcon style={{ color: "#18328F" }} />,
                                                }}
                                                style={{ border: "1px solid #18328F", backgroundColor: "#E9F4FF", alignItems: 'center' }} severity="info">Le porteur de projet a payé le montant convenu, vous pouvez commencer la mission après avoir généré et signé un contrat de confidentialité NDA avec le porteur  !
                                                Une fois une version finale envoyée et validée par le porteur de projet, nous vous enverrons le montant convenu.</Alert>

                                        </div>
                                    }
                                    {(localStorage.getItem("role") === "porteur" && this.state.room.offre_state === "payed" && this.state.room.nda_porteur !== "signed") &&
                                        <div className="alert-info-style" style={{ marginTop: 90, position: 'absolute', zIndex: 99, paddingRight: 11 }}>
                                            <Alert
                                                iconMapping={{
                                                    info: <InfoOutlinedIcon style={{ color: "#18328F" }} />,
                                                }}
                                                style={{ border: "1px solid #18328F", backgroundColor: "#E9F4FF", alignItems: 'center' }} severity="info">Le freelance va maintenant vous envoyer un Contrat de Confidentialité, également, lisez le bien avant de le signer.</Alert>

                                        </div>
                                    }
                                    {(localStorage.getItem("role") === "porteur" && this.state.room.offre_state === "payed" && this.state.room.nda_porteur === "signed") &&
                                        <div className="alert-info-style" style={{ marginTop: 90, position: 'absolute', zIndex: 99, paddingRight: 11 }}>
                                            <Alert
                                                iconMapping={{
                                                    info: <InfoOutlinedIcon style={{ color: "#18328F" }} />,
                                                }}
                                                style={{ border: "1px solid #18328F", backgroundColor: "#E9F4FF", alignItems: 'center' }} severity="info">Le freelance est en train de travailler sur votre mission ! Une fois une ébauche terminée, il vous enverra une version contenant le lien des livrables, vous recevrez un mail une fois la version reçue.</Alert>

                                        </div>
                                    }
                                    {(localStorage.getItem("role") === "freelance" && this.state.is_bot_active !== true && this.state.room.offre_state === "finished") &&
                                        <div className="alert-info-style" style={{ marginTop: 90, position: 'absolute', zIndex: 99, paddingRight: 11 }}>
                                            <Alert
                                                iconMapping={{
                                                    info: <InfoOutlinedIcon style={{ color: "#18328F" }} />,
                                                }}
                                                style={{ border: "1px solid #18328F", backgroundColor: "#E9F4FF", alignItems: 'center' }} severity="info">Félicitation ! La mission est terminée ! Vous recevrez le paiement correspondant sous 7 jours ouvrés.</Alert>

                                        </div>
                                    }
                                </>
                            }
                            {this.state.room &&
                                <>
                                    {(localStorage.getItem("role") === "freelance" && this.state.isPartageDocGenerated === true) &&
                                        <div className="alert-info-style" style={{ marginTop: 90, position: 'absolute', zIndex: 99, paddingRight: 11 }}>
                                            <Alert severity="success" style={{ fontWeight: 700 }}>Le Contrat de Partage de Revenus entre vous et la plateforme est validé et sauvegardé avec succés.</Alert>

                                        </div>
                                    }
                                </>
                            }
                            <div id="frame"
                                onDragOver={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                }}
                                onDragLeave={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                }}
                                onDrop={(e) => {
                                    //this.onDrop_container(e)
                                }}
                            >
                                <div className="content">
                                    <div className="contact-profile">

                                        <div className="d-flex user-list">
                                            {this.state.room &&
                                                <div className="d-flex align-items-center justify-content-between" style={{ width: "100%", gap: 5 }}>
                                                    <div className="d-flex align-items-center" style={{ gap: 5 }}>
                                                        <StyledBadge
                                                            overlap="circular"
                                                            className="group-avatar"
                                                            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                                                            variant="dot"
                                                        >
                                                            {this.state.is_bot_active === false ?
                                                                <Avatar sx={{ width: 40, height: 40, border: "2px solid #0A3936", backgroundColor: "#d1e9e7b0" }}
                                                                    alt="Remy Sharp"
                                                                    src="/avatar12.png"
                                                                /> :
                                                                <Avatar alt="Remy Sharp" src="/chatbot.svg" sx={{ bgcolor: "#0A3936", width: 40, height: 40 }} />
                                                            }
                                                        </StyledBadge>
                                                        <div className="d-flex flex-column" style={{ lineHeight: 1.3 }}>
                                                            <Tooltip title={this.state.room.title} arrow>
                                                                <span style={{ fontSize: 14, fontWeight: 700 }} className="group-title1 text-truncate">{this.state.room.title}</span>
                                                            </Tooltip>
                                                            <span style={{ fontSize: 14, color: "#787a7c", fontWeight: 600 }}> {localStorage.getItem("role") === "porteur" ? this.state.room.freelancer_first_name : this.state.room.porteur_first_name}</span>
                                                        </div>

                                                    </div>
                                                    <div style={{ width: "100%" }} className="d-flex justify-content-end">
                                                        {(this.state.room.offre_state === 'en cours') ?
                                                            <LightTooltip style={{ backgroundColor: '#fff' }} title="Statut actuel de cette mission : En cours de négociation">
                                                                <img width={30} style={{ cursor: 'pointer' }} height={30} src="/encours.png" />
                                                            </LightTooltip>
                                                            :
                                                            this.state.room.offre_state === 'waiting' ?
                                                                <LightTooltip style={{ backgroundColor: '#fff' }} title="Statut actuel de cette mission : En cours de négociation">
                                                                    <img width={30} style={{ cursor: 'pointer' }} height={30} src="/confirmed.png" />
                                                                </LightTooltip>
                                                                :
                                                                this.state.room.offre_state === 'confirmed' ?
                                                                    <LightTooltip style={{ backgroundColor: '#fff' }} title="Statut actuel de cette mission : En attente de paiement">
                                                                        <img width={30} style={{ cursor: 'pointer' }} height={30} src="/payment.png" />
                                                                    </LightTooltip> :
                                                                    this.state.room.offre_state === 'payed' ?
                                                                        <LightTooltip style={{ backgroundColor: '#fff' }} title="Statut actuel de cette mission : En cours de réalisation">
                                                                            <img width={30} style={{ cursor: 'pointer' }} height={30} src="/versiontime.png" />
                                                                        </LightTooltip> :
                                                                        <LightTooltip style={{ backgroundColor: '#fff' }} title="Statut actuel de cette mission : Mission terminée">
                                                                            <img width={30} style={{ cursor: 'pointer' }} height={30} src="/versiondone.png" />
                                                                        </LightTooltip>
                                                        }
                                                    </div>
                                                    {this.state.is_bot_active !== true &&
                                                        <div className="d-flex" style={{ gap: 10 }}>
                                                            {/* {(localStorage.getItem("role") === "porteur" && this.state.room.offre_state === "confirmed") && <button className="button_accept" onClick={(e) => { console.log(this.props.navigate("/offre-payment?id=" + this.state.room.mission_id + "&email=" + this.state.room.members[1])) }}>Payer le montant</button>} */}
                                                            {/* {(localStorage.getItem("role") === "freelance" && this.state.room.offre_state === "en cours") && <img alt="negociation" width={40} height={40} style={{ cursor: 'pointer' }} onClick={this.acceptOffre} src="/negociation.png" />} */}
                                                            {(localStorage.getItem("role") === "freelance" && this.state.room.offre_state === "accepted") &&
                                                                <Chip
                                                                    label="Mission accepté"
                                                                    icon={<DoneIcon fontSize="small" />}
                                                                    fontSize="small"
                                                                    variant="outlined"
                                                                    color="primary"
                                                                />
                                                            }
                                                            {/* {(localStorage.getItem("role") === "porteur" && this.state.room.offre_state === "en cours") &&
                                                            <Chip
                                                                variant="outlined"
                                                                label="En cours de négociation"
                                                                fontSize="small"
                                                                color="warning"

                                                            />
                                                        } */}
                                                            {/* {(this.state.room.offre_state === "confirmed") &&
                                                            <Chip
                                                                variant="outlined"
                                                                icon={<DoneIcon fontSize="small" />}
                                                                label="Mission confirmé"
                                                                fontSize="small"
                                                                color="success"

                                                            />
                                                        } */}
                                                            {(this.state.room.offre_state === "refused") &&
                                                                <Chip
                                                                    variant="outlined"
                                                                    label="Mission refusée"
                                                                    fontSize="small"
                                                                    color="error"

                                                                />
                                                            }
                                                            {/* {(localStorage.getItem("role") === "porteur" && this.state.room.offre_state === "Confirmed") &&
                                                            <Chip
                                                                variant="outlined"
                                                                label="En cours de négociation"
                                                                fontSize="small"
                                                                color="warning"

                                                            />
                                                        } */}
                                                            {/* {(this.state.room.offre_state === "payed") &&
                                                            <Chip
                                                                variant="outlined"
                                                                icon={<DoneIcon fontSize="small" />}
                                                                label="Mission payée"
                                                                fontSize="small"
                                                                color="success"

                                                            />
                                                        } */}
                                                        </div>
                                                    }
                                                </div>
                                            }
                                            {/* <div className="chatbot-avatar">
                                        <Avatar onClick={(e)=>{this.setState({openEmailModal: true});}} alt="Remy Sharp" src="/plus.svg" sx={{ bgcolor: "#0A3936", width: 50, height: 50 }} />
                                    </div> */}

                                        </div>
                                    </div>
                                    {
                                        this.state.loadingScroll === true &&
                                        <div align="center" style={{ marginTop: 5 }}>
                                            <CircularProgress color="secondary" size={25} />
                                        </div>
                                    }
                                    <div className="messages" style={{ paddingTop: (this.state.room.offre_state === "en cours") ? 100 : (this.state.room.offre_state === "payed") ? 120 : (this.state.room.offre_state === "confirmed") ? 60 : (localStorage.getItem('role') === "freelance" && this.state.room.offre_state === "finished") ? 65 : 20 }} ref={(ref) => this.messageList = ref} onScroll={() => {
                                        const scrollTop = this.messageList.scrollTop;
                                        if (scrollTop === 0) {
                                            // this.fetchMoreData()
                                        }
                                    }}>
                                        <ul>
                                            {
                                                msgs.map((msg, key) => (
                                                    msg.sender.email === localStorage.getItem("email") ?
                                                        <div key={key}>
                                                            <li className="replies" id={msg.uid || msg.id}>
                                                                {
                                                                    msg.type === "image" ?
                                                                        <div style={{
                                                                            backgroundColor: "#1565C0",
                                                                            color: "#fff",
                                                                            padding: "3px",
                                                                            borderRadius: 7.5,
                                                                            position: "relative",
                                                                            paddingTop: 10,
                                                                            maxWidth: "calc(100% - 80px)",
                                                                            display: "inline-block",
                                                                            marginBottom: 15,
                                                                            float: "right",
                                                                            boxShadow: "0 1px 0.5px rgba(0,0,0,.13)"
                                                                        }}
                                                                        >
                                                                            <ExpandMoreIcon style={{
                                                                                position: "absolute",
                                                                                top: -2,
                                                                                right: 5,
                                                                                color: "rgba(0,0,0,0.3)"
                                                                            }}
                                                                                onClick={(e) => {
                                                                                    this.setState({
                                                                                        anchorElMsgMenu: e.currentTarget,
                                                                                        selectedMsg: msg
                                                                                    })
                                                                                }}
                                                                            />
                                                                            {
                                                                                msg.tagged_to && msg.tagged_to !== "false" ? this.renderTaggedMsg(msg, msg.tagged_to) : null
                                                                            }
                                                                            <img alt="" src={msg.text}
                                                                                style={{
                                                                                    width: "100%",
                                                                                    height: "100%",
                                                                                    objectFit: "cover",
                                                                                    borderRadius: 10,
                                                                                    marginBottom: 7,
                                                                                    maxHeight: 150,
                                                                                    maxWidth: 300,
                                                                                    cursor: "pointer"
                                                                                }}
                                                                                onClick={() => {
                                                                                    console.log(msg)
                                                                                    this.setState({
                                                                                        showImageModal: true,
                                                                                        imageModal: msg.text
                                                                                    })
                                                                                }}
                                                                            />
                                                                            <h6 style={{
                                                                                color: "#fff",
                                                                                fontSize: "0.6rem",
                                                                                textAlign: "end",
                                                                                marginRight: 5
                                                                            }}>{moment(msg.created_at).fromNow(false)}</h6>
                                                                        </div> :
                                                                        msg.type === "ged_file" ?
                                                                            <div style={{
                                                                                backgroundColor: "#1565C0",
                                                                                color: "#fff",
                                                                                padding: "3px",
                                                                                borderRadius: 7.5,
                                                                                position: "relative",
                                                                                maxWidth: "calc(100% - 60px)",
                                                                                display: "inline-block",
                                                                                marginBottom: 15,
                                                                                float: "right",
                                                                                boxShadow: "0 1px 0.5px rgba(0,0,0,.13)"
                                                                            }}
                                                                            >
                                                                                <ExpandMoreIcon style={{
                                                                                    position: "absolute",
                                                                                    top: -2,
                                                                                    right: 5,
                                                                                    color: "rgba(0,0,0,0.3)"
                                                                                }}
                                                                                    onClick={(e) => {
                                                                                        this.setState({
                                                                                            anchorElMsgMenu: e.currentTarget,
                                                                                            selectedMsg: msg
                                                                                        })
                                                                                    }}
                                                                                />
                                                                                {
                                                                                    msg.tagged_to && msg.tagged_to !== "false" ? this.renderTaggedMsg(msg, msg.tagged_to) : null
                                                                                }
                                                                                <div style={{ backgroundColor: "#1565C0" }}>
                                                                                    <div>
                                                                                        <div className="d-flex align-items-center p-2" style={{ gap: 10 }}>
                                                                                            {(msg.file_type !== "payment" && msg.file_type === "note" && this.state.room.note_porteur === true && msg.sender_role === "porteur") ? <div className="msg_file_icon5" /> : (msg.file_type !== "payment" && msg.file_type === "note" && this.state.room.note_freelance === true && msg.sender_role === "freelance") ? <div className="msg_file_icon5" /> : (msg.file_type !== "payment" && msg.file_type === "note") ? <div className="msg_file_icon4" /> : (msg.sender_role === "porteur" && msg.file_type !== "payment") ? <div className="msg_file_icon1" /> : (msg.file_type !== "payment" && msg.file_type === 'version') ? <div className="msg_file_icon3" /> : msg.file_type !== "payment" ? <div className="msg_file_icon" /> : <div className="msg_file_icon2" />}
                                                                                            <div className={msg.file_type === "note" ? "msg_file_text2" : "msg_file_text1"}
                                                                                            >
                                                                                                {(localStorage.getItem("role") === "freelance" && msg.file_type === "payment") ?
                                                                                                    <div className="d-flex flex-column">
                                                                                                        <span style={{ fontSize: 14 }}>En attente du porteur pour le paiement</span>
                                                                                                        <span style={{ fontSize: 12, fontWeight: 400 }}>{parseFloat(this.state.room.montant).toFixed(2) + ' €'}</span>
                                                                                                    </div>
                                                                                                    :
                                                                                                    <span style={{ fontSize: msg.file_type === "note" ? 12 : '' }}>{(msg.sender_role === "porteur" && this.state.room.note_porteur === true) ? 'Génial ! Merci pour votre retour !' : (msg.sender_role === "freelance" && this.state.room.note_freelance === true) ? 'Génial ! Merci pour votre retour !' : msg.name}</span>
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                        <div
                                                                                            className="msg_file_download_icon">
                                                                                            <div style={{ color: "rgba(51,51,51,.5" }}>
                                                                                                {/* <span>
                                                                                                <svg
                                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                                    width={34}
                                                                                                    height={34}
                                                                                                    viewBox="0 0 34 34">
                                                                                                    <path
                                                                                                        d="M17 2c8.3 0 15 6.7 15 15s-6.7 15-15 15S2 25.3 2 17 8.7 2 17 2m0-1C8.2 1 1 8.2 1 17s7.2 16 16 16 16-7.2 16-16S25.8 1 17 1z"
                                                                                                        fill="currentColor"
                                                                                                    />
                                                                                                    <path
                                                                                                        d="M22.4 17.5h-3.2v-6.8c0-.4-.3-.7-.7-.7h-3.2c-.4 0-.7.3-.7.7v6.8h-3.2c-.6 0-.8.4-.4.8l5 5.3c.5.7 1 .5 1.5 0l5-5.3c.7-.5.5-.8-.1-.8z"
                                                                                                        fill="currentColor"
                                                                                                    />
                                                                                                </svg>
                                                                                            </span> */}
                                                                                                <div className="p-2" style={{ width: "100%" }}>
                                                                                                    {(msg.file_type !== "payment" && msg.file_type !== "version" && msg.file_type !== "note") &&
                                                                                                        <Button onClick={async (e) => {
                                                                                                            this.setState({ loading: true })
                                                                                                            let a = document.createElement('a');
                                                                                                            let b64 = await this.getB64ByUid(msg.uid)
                                                                                                            console.log(b64)
                                                                                                            if (b64.includes('data:application/pdf;base64,')) {
                                                                                                                a.href = b64
                                                                                                            } else {
                                                                                                                a.href = 'data:application/pdf;base64,' + b64
                                                                                                            }
                                                                                                            a.download = "Freemada-file";
                                                                                                            a.click();
                                                                                                            this.setState({ loading: false })
                                                                                                        }}
                                                                                                            className="px-5" style={{ backgroundColor: "#6DB2FF", width: "100%", textTransform: 'none' }} variant="contained">Enregistrer sous...</Button>
                                                                                                    }
                                                                                                    {(msg.file_type === "devis" && msg.name !== "devis-signe" && (this.state.room.offre_state === "en cours" || this.state.room.offre_state === "waiting")) && <Button className="mt-2 px-5" onClick={(e) => {
                                                                                                        this.setState({ openModalDevisSign: true, docDevis64: msg.uid, docDevisDate: msg.created_at })
                                                                                                        console.log(msg)
                                                                                                    }} style={{ backgroundColor: "#6DB2FF", width: "100%", textTransform: 'none' }} variant="contained">Voir le  devis</Button>}
                                                                                                    {(msg.file_type === "nda" && this.state.room.nda_porteur !== "signed" && localStorage.getItem('role') === 'porteur') && <Button className="mt-2 px-5"
                                                                                                        onClick={async (e) => {
                                                                                                            this.setState({ loading: true })
                                                                                                            let signatureB64 = await this.getB64ByEmail(this.state.room.members[1])
                                                                                                            this.setState({ signatureB64: signatureB64 })
                                                                                                            this.setState({ openModalDocumentSign: true, docDevis64: msg.uid, docDevisDate: msg.created_at, loading: false })
                                                                                                        }}
                                                                                                        style={{ backgroundColor: "#6DB2FF", width: "100%", textTransform: 'none' }}
                                                                                                        variant="contained">Voir le document</Button>}

                                                                                                    {(msg.file_type === "payment" && localStorage.getItem('role') === 'porteur' && this.state.room.offre_state !== "payed") && <Button className="mt-2 px-5" onClick={(e) => {
                                                                                                        this.setState({ openModalPayment: true, modalLoading: true })
                                                                                                        setTimeout(() => {
                                                                                                            this.setState({ modalLoading: false })
                                                                                                        }, 3000)
                                                                                                        console.log(msg)
                                                                                                    }} style={{ backgroundColor: "#6DB2FF", width: "100%", textTransform: 'none' }} variant="contained">Payer</Button>}

                                                                                                    {(msg.file_type === "version" && localStorage.getItem('role') === 'porteur') && <Button className="mt-2 px-5" onClick={(e) => {
                                                                                                        this.setState({ versionTitle: msg.name, versionLink: msg.version_link, versionDescription: msg.version_description, versionFinale: msg.version_finale })
                                                                                                        setTimeout(() => {
                                                                                                            this.setState({ openModalVersionValidate: true })
                                                                                                        }, 500)
                                                                                                        console.log(msg)
                                                                                                    }} style={{ backgroundColor: "#6DB2FF", width: "100%", textTransform: 'none' }} variant="contained">Voir la version</Button>}

                                                                                                    {(msg.file_type === "note" && msg.sender_role === "porteur" && this.state.room.note_porteur !== true) &&
                                                                                                        <Button className="mt-2 px-5" onClick={(e) => {
                                                                                                            if (localStorage.getItem('role') === 'porteur') {
                                                                                                                this.setState({ openCommentModal: true })
                                                                                                            } else {
                                                                                                                this.setState({ openCommentModalPorteur: true })
                                                                                                            }
                                                                                                            console.log(msg)
                                                                                                        }} style={{ backgroundColor: "#6DB2FF", width: "100%", textTransform: 'none' }} variant="contained">{"Noter la prestation"}</Button>}
                                                                                                    {(msg.file_type === "note" && msg.sender_role === "freelance" && this.state.room.note_freelance !== true) &&
                                                                                                        <Button className="mt-2 px-5" onClick={(e) => {
                                                                                                            if (localStorage.getItem('role') === 'porteur') {
                                                                                                                this.setState({ openCommentModal: true })
                                                                                                            } else {
                                                                                                                this.setState({ openCommentModalPorteur: true })
                                                                                                            }
                                                                                                            console.log(msg)
                                                                                                        }} style={{ backgroundColor: "#6DB2FF", width: "100%", textTransform: 'none' }} variant="contained">{"Noter le porteur de projet"}</Button>}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <h6 style={{
                                                                                    color: "#fff",
                                                                                    marginTop: 4,
                                                                                    marginBottom: 2,
                                                                                    float: "right",
                                                                                    fontSize: "0.6rem"
                                                                                }}>{moment(msg.created_at).fromNow(false)}</h6>

                                                                            </div>
                                                                            :
                                                                            msg.type === "audio" ?
                                                                                <div style={{
                                                                                    backgroundColor: "#1565C0",
                                                                                    padding: "3px",
                                                                                    borderRadius: 7.5,
                                                                                    position: "relative",
                                                                                    maxWidth: "calc(100% - 40px)",
                                                                                    display: "inline-block",
                                                                                    marginBottom: 15,
                                                                                    float: "right",
                                                                                    boxShadow: "0 1px 0.5px rgba(0,0,0,.13)"
                                                                                }}
                                                                                >
                                                                                    <ExpandMoreIcon style={{
                                                                                        position: "absolute",
                                                                                        top: 3,
                                                                                        right: 5,
                                                                                        color: "rgba(0,0,0,0.3)"
                                                                                    }}
                                                                                        onClick={(e) => {
                                                                                            this.setState({
                                                                                                anchorElMsgMenu: e.currentTarget,
                                                                                                selectedMsg: msg
                                                                                            })
                                                                                        }}
                                                                                    />
                                                                                    {
                                                                                        msg.tagged_to && msg.tagged_to !== "false" ? this.renderTaggedMsg(msg, msg.tagged_to) : null
                                                                                    }
                                                                                    <div
                                                                                        style={{ backgroundColor: "#1565C0" }}>
                                                                                        <div style={{
                                                                                            padding: "13px 19px",
                                                                                            alignItems: "center",
                                                                                            display: "flex",
                                                                                            paddingBottom: 4
                                                                                        }}>
                                                                                            <ReactPlayer
                                                                                                url={"data:audio/webm;codecs=opus;base64," + msg.b64}
                                                                                                width="260px"
                                                                                                height="40px"
                                                                                                controls={true}
                                                                                                style={{ marginTop: 5 }}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    <h6 style={{
                                                                                        color: "black",
                                                                                        marginTop: 4,
                                                                                        marginBottom: 2,
                                                                                        float: "right",
                                                                                        fontSize: "0.6rem"
                                                                                    }}>{moment(msg.created_at).fromNow(false)}</h6>
                                                                                    <h6 style={{
                                                                                        color: "black",
                                                                                        marginTop: 4,
                                                                                        marginBottom: 2,
                                                                                        float: "left",
                                                                                        fontSize: "0.6rem",
                                                                                        marginLeft: 25
                                                                                    }}>{utilFunctions.toMMSS(msg.duration.toString())}</h6>

                                                                                </div> :

                                                                                <div className="text-msg pt-3">
                                                                                    <ExpandMoreIcon style={{
                                                                                        position: "absolute",
                                                                                        top: -1,
                                                                                        right: 5,
                                                                                        color: "rgba(0,0,0,0.3)",
                                                                                        cursor: "pointer"
                                                                                    }}
                                                                                        onClick={(e) => {
                                                                                            this.setState({
                                                                                                anchorElMsgMenu: e.currentTarget,
                                                                                                selectedMsg: msg
                                                                                            })
                                                                                        }}
                                                                                    />
                                                                                    {
                                                                                        msg.tagged_to && msg.tagged_to !== "false" ? this.renderTaggedMsg(msg, msg.tagged_to) : null
                                                                                    }
                                                                                    <Anchorme target="_blank"
                                                                                        style={{ color: "#039be5" }}>
                                                                                        {msg.text}
                                                                                    </Anchorme>
                                                                                    <h6 style={{
                                                                                        color: "#fff",
                                                                                        marginBottom: -2,
                                                                                        fontSize: "0.6rem",
                                                                                        float: "right",
                                                                                        marginTop: 20
                                                                                    }}>{moment(msg.created_at).fromNow(false)}</h6>
                                                                                </div>
                                                                }
                                                            </li>
                                                        </div>
                                                        :
                                                        <>
                                                            {msg.file_type !== "note" &&
                                                                <div key={key}>
                                                                    <li className="sent" id={msg.uid || msg.id}>
                                                                        {
                                                                            msg.type === "loading" ?
                                                                                <div style={{
                                                                                    backgroundColor: "#D8E9FD", borderRadius: 7.5,
                                                                                    display: "inline-block", maxWidth: 45
                                                                                }}
                                                                                >
                                                                                    <img alt=""
                                                                                        src={loading_gif}
                                                                                        style={{
                                                                                            width: 45,
                                                                                            height: 40,
                                                                                            marginTop: -3
                                                                                        }} />
                                                                                </div> :

                                                                                msg.type === "image" ?
                                                                                    <div style={{
                                                                                        backgroundColor: "#D8E9FD",
                                                                                        padding: "3px",
                                                                                        borderRadius: 7.5,
                                                                                        display: "inline-block",
                                                                                        marginBottom: 15, position: "relative",
                                                                                        maxWidth: "calc(100% - 80px)"
                                                                                    }}
                                                                                    >
                                                                                        <ExpandMoreIcon style={{
                                                                                            position: "absolute",
                                                                                            top: -2,
                                                                                            right: 5,
                                                                                            color: "rgba(0,0,0,0.3)"
                                                                                        }}
                                                                                            onClick={(e) => {
                                                                                                this.setState({
                                                                                                    anchorElMsgMenu: e.currentTarget,
                                                                                                    selectedMsg: msg
                                                                                                })
                                                                                            }}
                                                                                        />
                                                                                        {
                                                                                            msg.tagged_to && msg.tagged_to !== "false" ? this.renderTaggedMsg(msg, msg.tagged_to) : null
                                                                                        }
                                                                                        <h6 style={{
                                                                                            color: "#35cd96",
                                                                                            fontSize: "0.6rem",
                                                                                            marginTop: -1,
                                                                                            marginLeft: 5
                                                                                        }}>{this.getUserFname(this.state.contacts || [], msg.sender.email)}</h6>
                                                                                        {this.state.active_bot === "bot_treeporteur_rep" &&
                                                                                            <Checkbox
                                                                                                checked={msg.text === this.state.imageDalleSelected}
                                                                                                onChange={(e) => {
                                                                                                    console.log(msg.text)
                                                                                                    this.setState({ imageDalleSelected: msg.text })
                                                                                                    this.setState({ active_bot: "bot_treeporteur_rep", isArtistique: undefined, isAutre: undefined })
                                                                                                    this.executeBot("bot_treeporteur_rep", this.state.bot_treeporteur_rep, 10)
                                                                                                }}
                                                                                            />}
                                                                                        <img alt="" src={msg.text} style={{
                                                                                            width: "100%",
                                                                                            height: "100%",
                                                                                            objectFit: "cover",
                                                                                            borderRadius: 10,
                                                                                            marginBottom: 10,
                                                                                            maxHeight: 150,
                                                                                            maxWidth: 300,
                                                                                            cursor: "pointer"
                                                                                        }}
                                                                                            onClick={() => {
                                                                                                console.log(msg.text)
                                                                                                this.setState({
                                                                                                    showImageModal: true,
                                                                                                    imageModal: msg.text
                                                                                                })
                                                                                            }}
                                                                                        />
                                                                                        <h6 style={{
                                                                                            color: "black",
                                                                                            fontSize: "0.6rem",
                                                                                            textAlign: "end",
                                                                                            marginRight: 5
                                                                                        }}>
                                                                                            {moment(msg.created_at).fromNow(false)}</h6>
                                                                                    </div> :

                                                                                    msg.type === "ged_file" ?
                                                                                        <div style={{
                                                                                            backgroundColor: "#1565C0",
                                                                                            padding: "3px",
                                                                                            borderRadius: 7.5,
                                                                                            position: "relative",
                                                                                            maxWidth: "calc(100% - 60px)",
                                                                                            display: "inline-block",
                                                                                            marginBottom: 15,
                                                                                            boxShadow: "0 1px 0.5px rgba(0,0,0,.13)"
                                                                                        }}
                                                                                        >
                                                                                            <ExpandMoreIcon style={{
                                                                                                position: "absolute",
                                                                                                top: -2,
                                                                                                right: 5,
                                                                                                color: "rgba(0,0,0,0.3)"
                                                                                            }}
                                                                                                onClick={(e) => {
                                                                                                    this.setState({
                                                                                                        anchorElMsgMenu: e.currentTarget,
                                                                                                        selectedMsg: msg
                                                                                                    })
                                                                                                }}
                                                                                            />
                                                                                            {
                                                                                                msg.tagged_to && msg.tagged_to !== "false" ? this.renderTaggedMsg(msg, msg.tagged_to) : null
                                                                                            }
                                                                                            <h6 style={{
                                                                                                color: "#fff",
                                                                                                fontSize: "0.6rem",
                                                                                                marginTop: 2,
                                                                                                marginBottom: 4,
                                                                                                marginLeft: 5
                                                                                            }}>{this.getUserFname(this.state.contacts || [], msg.sender.email)}</h6>
                                                                                            <div
                                                                                                style={{ backgroundColor: "#1565C0" }}>
                                                                                                <div style={{
                                                                                                    padding: "13px 19px",
                                                                                                    alignItems: "center",
                                                                                                    display: "flex",
                                                                                                    paddingTop: 10,
                                                                                                    paddingBottom: 10,
                                                                                                    gap: 10
                                                                                                }}>
                                                                                                    {(msg.sender_role === "porteur" && msg.file_type !== "payment" && msg.file_type === "note") ? <div className="msg_file_icon4" /> : (msg.sender_role === "porteur" && msg.file_type !== "payment") ? <div className="msg_file_icon1" /> : (msg.file_type !== "payment" && msg.file_type === 'version') ? <div className="msg_file_icon3" /> : msg.file_type !== "payment" ? <div className="msg_file_icon" /> : <div className="msg_file_icon2" />}
                                                                                                    <div className="msg_file_text"
                                                                                                    >
                                                                                                        {(localStorage.getItem("role") === "freelance" && msg.file_type === "payment") ?
                                                                                                            <div className="d-flex flex-column">
                                                                                                                <span style={{ fontSize: 12 }}>En attente du porteur pour le paiement</span>
                                                                                                                {this.state.room.montant && <span style={{ fontSize: 12, fontWeight: 400 }}>{(parseFloat(this.state.room.montant)).toFixed(2) + ' €'}</span>}
                                                                                                            </div>
                                                                                                            :
                                                                                                            <span>{msg.name}</span>
                                                                                                        }
                                                                                                    </div>

                                                                                                </div>

                                                                                                <div className="p-2" style={{ width: "100%" }}>
                                                                                                    {(msg.file_type !== "payment" && msg.file_type !== "version" && msg.file_type !== "note") &&
                                                                                                        <Button onClick={async (e) => {
                                                                                                            this.setState({ loading: true })
                                                                                                            let a = document.createElement('a');
                                                                                                            let b64 = await this.getB64ByUid(msg.uid)
                                                                                                            if (b64.includes('data:application/pdf;base64,')) {
                                                                                                                a.href = b64
                                                                                                            } else {
                                                                                                                a.href = 'data:application/pdf;base64,' + b64
                                                                                                            }
                                                                                                            a.download = "Freemada-file";
                                                                                                            a.click();
                                                                                                            this.setState({ loading: false })
                                                                                                        }}
                                                                                                            className="px-5" style={{ backgroundColor: "#6DB2FF", width: "100%", textTransform: 'none' }} variant="contained">Enregistrer sous...</Button>}
                                                                                                    {(msg.file_type === "devis" && msg.name !== "devis-signe" && (this.state.room.offre_state === "en cours" || this.state.room.offre_state === "waiting")) && <Button className="mt-2 px-5" onClick={(e) => { this.setState({ openModalDevisSign: true, docDevis64: msg.uid, docDevisDate: msg.created_at }) }} style={{ backgroundColor: "#6DB2FF", width: "100%", textTransform: 'none' }} variant="contained">Voir le devis</Button>}
                                                                                                    {(msg.file_type === "nda" && this.state.room.nda_porteur !== "signed" && localStorage.getItem('role') === 'porteur') && <Button className="mt-2 px-5"
                                                                                                        onClick={async (e) => {
                                                                                                            this.setState({ loading: true })
                                                                                                            let signatureB64 = await this.getB64ByEmail(this.state.room.members[1])
                                                                                                            this.setState({ signatureB64: signatureB64 })
                                                                                                            this.setState({ openModalDocumentSign: true, docDevis64: msg.uid, docDevisDate: msg.created_at, loading: false })
                                                                                                        }}
                                                                                                        style={{ backgroundColor: "#6DB2FF", width: "100%", textTransform: 'none' }}
                                                                                                        variant="contained">Voir le document</Button>}
                                                                                                    {(msg.file_type === "payment" && localStorage.getItem('role') === 'porteur' && this.state.room.offre_state !== "payed") && <Button className="mt-2 px-5" onClick={(e) => {
                                                                                                        this.setState({ openModalPayment: true })
                                                                                                        console.log(msg)
                                                                                                    }} style={{ backgroundColor: "#6DB2FF", width: "100%", textTransform: 'none' }} variant="contained">Payer</Button>}
                                                                                                    {(msg.file_type === "version" && localStorage.getItem('role') === 'porteur') && <Button className="mt-2 px-5" onClick={(e) => {
                                                                                                        this.setState({ versionTitle: msg.name, versionLink: msg.version_link, versionDescription: msg.version_description, versionFinale: msg.version_finale })
                                                                                                        setTimeout(() => {
                                                                                                            this.setState({ openModalVersionValidate: true })
                                                                                                        }, 500)

                                                                                                        console.log(msg)
                                                                                                    }} style={{ backgroundColor: "#6DB2FF", width: "100%", textTransform: 'none' }} variant="contained">Voir la version</Button>}
                                                                                                </div>

                                                                                            </div>
                                                                                            <h6 style={{
                                                                                                color: "#fff",
                                                                                                marginTop: 4,
                                                                                                marginBottom: 2,
                                                                                                float: "right",
                                                                                                fontSize: "0.6rem"
                                                                                            }}>{moment(msg.created_at).fromNow(false)}</h6>
                                                                                        </div>
                                                                                        :

                                                                                        msg.type === "audio" ?
                                                                                            <div style={{
                                                                                                backgroundColor: "#D8E9FD",
                                                                                                padding: "3px",
                                                                                                borderRadius: 7.5,
                                                                                                position: "relative",
                                                                                                maxWidth: "calc(100% - 40px)",
                                                                                                display: "inline-block",
                                                                                                marginBottom: 15,
                                                                                                boxShadow: "0 1px 0.5px rgba(0,0,0,.13)"
                                                                                            }}
                                                                                            >
                                                                                                <ExpandMoreIcon style={{
                                                                                                    position: "absolute",
                                                                                                    top: 3,
                                                                                                    right: 5,
                                                                                                    color: "rgba(0,0,0,0.3)"
                                                                                                }}
                                                                                                    onClick={(e) => {
                                                                                                        this.setState({
                                                                                                            anchorElMsgMenu: e.currentTarget,
                                                                                                            selectedMsg: msg
                                                                                                        })
                                                                                                    }}
                                                                                                />
                                                                                                {
                                                                                                    msg.tagged_to && msg.tagged_to !== "false" ? this.renderTaggedMsg(msg, msg.tagged_to) : null
                                                                                                }
                                                                                                <h6 style={{
                                                                                                    color: "#35cd96",
                                                                                                    fontSize: "0.6rem",
                                                                                                    marginTop: 2,
                                                                                                    marginBottom: 4,
                                                                                                    marginLeft: 5
                                                                                                }}>{this.getUserFname(this.state.contacts || [], msg.sender.email)}</h6>
                                                                                                <div>
                                                                                                    <div style={{
                                                                                                        padding: "13px 19px",
                                                                                                        alignItems: "center",
                                                                                                        display: "flex",
                                                                                                        paddingBottom: 4
                                                                                                    }}>
                                                                                                        <ReactPlayer
                                                                                                            url={"data:audio/webm;codecs=opus;base64," + msg.b64}
                                                                                                            width="260px"
                                                                                                            height="40px"
                                                                                                            controls={true}
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <h6 style={{
                                                                                                    color: "black",
                                                                                                    marginTop: 4,
                                                                                                    marginBottom: 2,
                                                                                                    float: "right",
                                                                                                    fontSize: "0.6rem"
                                                                                                }}>{moment(msg.created_at).fromNow(false)}</h6>
                                                                                                <h6 style={{
                                                                                                    color: "black",
                                                                                                    marginTop: 4,
                                                                                                    marginBottom: 2,
                                                                                                    float: "left",
                                                                                                    fontSize: "0.6rem",
                                                                                                    marginLeft: 25
                                                                                                }}>{utilFunctions.toMMSS(msg.duration.toString())}</h6>

                                                                                            </div> :
                                                                                            msg.type === "buttons" ?
                                                                                                <div style={{
                                                                                                    backgroundColor: "#D8E9FD",
                                                                                                    padding: "3px",
                                                                                                    borderRadius: 7.5,
                                                                                                    display: "inline-block",
                                                                                                    marginBottom: 15,
                                                                                                    maxWidth: "100%"
                                                                                                }}
                                                                                                >
                                                                                                    {
                                                                                                        (msg.extra.suggestions.responses || []).map((resp, key) => (
                                                                                                            <div
                                                                                                                style={{ margin: 10 }}>
                                                                                                                <AtlButton
                                                                                                                    onClick={() => {
                                                                                                                        resp.selected = true;
                                                                                                                        let updX = this.state.updateScreen
                                                                                                                        this.setState({ updateScreen: !updX })
                                                                                                                        setTimeout(() => {
                                                                                                                            resp.onClick()
                                                                                                                        }, 400)
                                                                                                                    }}
                                                                                                                    appearance="default"
                                                                                                                //isSelected={resp.selected && resp.selected === true }
                                                                                                                //iconAfter={resp.selected && resp.selected === true ? <CheckIcon fontSize="small" color="secondary"/> : null}

                                                                                                                >
                                                                                                                    {resp.text}
                                                                                                                </AtlButton>
                                                                                                            </div>
                                                                                                        ))
                                                                                                    }
                                                                                                </div> :

                                                                                                msg.type === "bim_objects" ?
                                                                                                    <div style={{
                                                                                                        backgroundColor: "#f5f5f5",
                                                                                                        padding: "3px",
                                                                                                        borderRadius: 7.5,
                                                                                                        display: "inline-block",
                                                                                                        marginBottom: 15,
                                                                                                        maxWidth: "100%"
                                                                                                    }}
                                                                                                    >
                                                                                                        {
                                                                                                            (msg.extra.bim_objects || []).map((item, key) => (
                                                                                                                <div key={key} style={{ margin: 10, backgroundColor: "white", borderRadius: 7.5, paddingLeft: 10, paddingRight: 15 }}>
                                                                                                                    <div style={{ display: "flex" }}>
                                                                                                                        <div>
                                                                                                                            <img alt="" src={item.Photo}
                                                                                                                                style={{ height: 50, width: 50, cursor: "pointer" }}
                                                                                                                                onClick={(e) => {
                                                                                                                                    e.preventDefault()
                                                                                                                                    e.stopPropagation()
                                                                                                                                    this.setState({
                                                                                                                                        showImageModal: true,
                                                                                                                                        imageModal: item.Photo
                                                                                                                                    })
                                                                                                                                }}
                                                                                                                            />
                                                                                                                        </div>
                                                                                                                        <div style={{ alignSelf: "center", cursor: "pointer" }}>
                                                                                                                            <h5 style={{ marginLeft: 5, textDecoration: "underline" }}
                                                                                                                                onClick={() => {
                                                                                                                                    console.log(item)
                                                                                                                                    this.setState({ selectedBimObject: item, showDetailBimObject: true })
                                                                                                                                }}
                                                                                                                            >{item.Name}</h5>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            ))
                                                                                                        }
                                                                                                    </div>
                                                                                                    : msg.type === "plan_projects" ?
                                                                                                        <div style={{
                                                                                                            backgroundColor: "#f5f5f5",
                                                                                                            padding: "3px",
                                                                                                            borderRadius: 7.5,
                                                                                                            display: "inline-block",
                                                                                                            marginBottom: 15,
                                                                                                            maxWidth: "100%"
                                                                                                        }}
                                                                                                        >
                                                                                                            {
                                                                                                                (msg.extra.plan_projects || []).map((item, key) => (
                                                                                                                    <div key={key} style={{ margin: 10, backgroundColor: "white", borderRadius: 7.5, paddingLeft: 10, paddingRight: 15 }}>
                                                                                                                        <div style={{ display: "flex" }}>
                                                                                                                            <div>
                                                                                                                                <img alt="" src={projectIcon}
                                                                                                                                    style={{ height: 50, width: 50, cursor: "pointer" }}
                                                                                                                                    onClick={(e) => {
                                                                                                                                        this.setProjectPlan(item)
                                                                                                                                        e.preventDefault()
                                                                                                                                        e.stopPropagation()
                                                                                                                                    }}
                                                                                                                                />
                                                                                                                            </div>
                                                                                                                            <div style={{ alignSelf: "center", cursor: "pointer" }}>
                                                                                                                                <h5 style={{ marginLeft: 5, textDecoration: "underline" }}
                                                                                                                                    onClick={() => {
                                                                                                                                        this.setProjectPlan(item)
                                                                                                                                    }}
                                                                                                                                >{item.titreProjet}</h5>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                ))
                                                                                                            }
                                                                                                        </div> :

                                                                                                        msg.type === "input_img_upload" ?
                                                                                                            <div style={{
                                                                                                                backgroundColor: "#f5f5f5",
                                                                                                                padding: "10px",
                                                                                                                borderRadius: 7.5,
                                                                                                                display: "inline-block",
                                                                                                                marginBottom: 15,
                                                                                                                maxWidth: "100%"
                                                                                                            }}
                                                                                                            >
                                                                                                                <AtlButton
                                                                                                                    onClick={(e) => {
                                                                                                                        this.bot_image_upload.click()
                                                                                                                    }}
                                                                                                                    appearance="default"
                                                                                                                    //isSelected={resp.selected && resp.selected === true }
                                                                                                                    iconBefore={<ImageIcon />}

                                                                                                                >
                                                                                                                    Séléctionner votre image...
                                                                                                                </AtlButton>
                                                                                                            </div> :

                                                                                                            msg.type === "select" ?  // c'est fait seulemnt pour le bot POI
                                                                                                                <div style={{
                                                                                                                    //backgroundColor: "#f5f5f5",
                                                                                                                    padding: "5px",
                                                                                                                    borderRadius: 7.5,
                                                                                                                    marginBottom: 15,
                                                                                                                    display: "inline-block",
                                                                                                                    maxWidth: "100%"
                                                                                                                }}
                                                                                                                >
                                                                                                                    <SelectPicker data={this.state.projectPlans || []}
                                                                                                                        size={"md"}
                                                                                                                        placement={"topStart"} placeholder={"Chercher un plan..."}
                                                                                                                        renderMenuItem={(label, item) => {
                                                                                                                            return (
                                                                                                                                <div>
                                                                                                                                    <img alt="" src={item.image} style={{ width: 30, height: 30, marginRight: 5 }} /> {label}
                                                                                                                                </div>
                                                                                                                            );
                                                                                                                        }}
                                                                                                                        value={this.state.bot_recovered_data.poi_plan ? this.state.bot_recovered_data.poi_plan.uid : ""}
                                                                                                                        onChange={async value => {
                                                                                                                            this.start_loading_bot()
                                                                                                                            let selected_plan = (this.state.projectPlans || []).find(x => x.value === value)
                                                                                                                            let bot_data = this.state.bot_recovered_data
                                                                                                                            bot_data.poi_plan = selected_plan
                                                                                                                            let plan_image = await rethink_functions.getB64File(selected_plan.image_uid)
                                                                                                                            this.stop_loading_bot()
                                                                                                                            this.setState({ bot_recovered_data: bot_data, selected_bot_plan_image: plan_image.b64, showPlanMap: true })
                                                                                                                        }}
                                                                                                                        style={{ minWidth: 250 }}
                                                                                                                        cleanable={false}
                                                                                                                    />
                                                                                                                </div>
                                                                                                                :

                                                                                                                <div className="text-msg pt-3">
                                                                                                                    <h6 style={{
                                                                                                                        color: "#35cd96",
                                                                                                                        fontSize: "0.6rem",
                                                                                                                        marginTop: -1
                                                                                                                    }}
                                                                                                                    >
                                                                                                                        {this.getUserFname(this.state.contacts || [], msg.sender.email)}</h6>
                                                                                                                    <ExpandMoreIcon style={{
                                                                                                                        position: "absolute",
                                                                                                                        top: -1,
                                                                                                                        right: 5,
                                                                                                                        color: "rgba(0,0,0,0.3)",
                                                                                                                        cursor: "pointer"
                                                                                                                    }}
                                                                                                                        onClick={(e) => {
                                                                                                                            this.setState({
                                                                                                                                anchorElMsgMenu: e.currentTarget,
                                                                                                                                selectedMsg: msg
                                                                                                                            })
                                                                                                                        }}
                                                                                                                    />
                                                                                                                    {
                                                                                                                        msg.tagged_to && msg.tagged_to !== "false" ? this.renderTaggedMsg(msg, msg.tagged_to) : null
                                                                                                                    }
                                                                                                                    <Anchorme
                                                                                                                        target="_blank"
                                                                                                                        style={{ color: "#039be5" }}>
                                                                                                                        {msg.text}
                                                                                                                    </Anchorme>
                                                                                                                    <h6 style={{
                                                                                                                        color: "black",
                                                                                                                        marginBottom: -2,
                                                                                                                        fontSize: "0.6rem",
                                                                                                                        float: "right",
                                                                                                                        marginTop: 20
                                                                                                                    }}>{moment(msg.created_at).fromNow(false)}</h6>
                                                                                                                </div>

                                                                        }

                                                                    </li>
                                                                </div>}
                                                        </>

                                                ))
                                            }
                                        </ul>
                                    </div>


                                    {
                                        this.state.showTaggedMsgForm === true && this.renderMsgResponseForm()

                                    }

                                    {
                                        this.state.showPlusFormMenu === true && this.renderBtnPlusForm()
                                    }


                                    <div className="chat-input-msg">
                                        <div className="message-input d-flex px-2 align-items-center flex-grow-1">
                                            <i ref={this.plusBtnRef} className={this.state.showPlusFormMenu === true ? "bx bx-x font-22" : "bx bx-plus font-22"}
                                                style={{ cursor: "pointer", color: "#1565C0", marginLeft: 8 }}
                                                onClick={(event) => {
                                                    this.setState({ showPlusFormMenu: !this.state.showPlusFormMenu })
                                                }}
                                            />
                                            <div className="wrap">
                                                {/* <textarea placeholder="Entrez votre message ici..."
                                                    value={this.state.text}
                                                    onChange={(e => {
                                                        this.setState({ text: e.target.value })
                                                    })}
                                                    onKeyPress={(event => {
                                                        if (event.key === 'Enter' && !event.shiftKey) {
                                                            event.preventDefault()
                                                            this.addNewMessage(this.state.text, "bot_saisie_fact")
                                                        }
                                                    })}
                                                /> */}
                                                <Box
                                                    component="form"
                                                    sx={{
                                                        '& .MuiTextField-root': { borderBottom: 'none!important' },
                                                    }}
                                                    noValidate
                                                    autoComplete="off"
                                                >
                                                    <TextField
                                                        placeholder="Entrez votre message ici..."
                                                        value={this.state.text}
                                                        onChange={(e => {
                                                            this.setState({ text: e.target.value })
                                                        })}
                                                        onKeyPress={(event => {
                                                            if (event.key === 'Enter' && !event.shiftKey) {
                                                                event.preventDefault()
                                                                this.addNewMessage(this.state.text, "bot_saisie_fact")
                                                            }
                                                        })}
                                                        id="outlined-multiline-flexible"
                                                        multiline
                                                        fullWidth
                                                        maxRows={4}
                                                    />
                                                </Box>

                                            </div>
                                            <i className="bx bxs-send font-18" style={{ right: 6, color: "#1565C0", cursor: "pointer" }}
                                                onClick={(event) => {
                                                    event.preventDefault()
                                                    this.addNewMessage(this.state.text, "")
                                                }}
                                            />
                                        </div>

                                    </div>

                                    <input style={{ visibility: 'hidden', width: 0, height: 0 }}
                                        type='file' accept='.jpg, .png, .jpeg' capture="environment"
                                        onChange={(files) => this.uploadImage(files)}
                                        ref={(ref) => this.imageUpload = ref}
                                    />
                                    <input style={{ visibility: 'hidden', width: 0, height: 0 }}
                                        type='file' accept='.pdf'
                                        onChange={(event) => this.addB64File(event)}
                                        ref={(ref) => this.fileUpload = ref}
                                    />
                                    <input style={{ visibility: 'hidden', width: 0, height: 0 }}
                                        type='file' accept='image/*' capture="environment"
                                        onChange={(files) => this.upload_bot_image(files)}
                                        ref={(ref) => this.bot_image_upload = ref}
                                    />
                                </div>
                            </div>
                        </div>
                        : this.state.isEmptyRooms === true ?
                            <div className="p-4" style={{ marginTop: 80 }}>
                                <span style={{ fontFamily: "montserrat", fontWeight: 700, fontSize: 24, color: "grey" }}>{localStorage.getItem("role") === "freelance" ? "Contactez un porteur de projet pour commencer une conversation." : "Contactez un.e freelanceur pour commencer une conversation."}</span>
                            </div> :
                            <div></div>
                }

                {
                    this.state.uploadedImage &&
                    <ImageView
                        open={this.state.showImageView}
                        setOpen={() => this.setState({ showImageView: false })}
                        image={this.state.uploadedImage}
                        sendImage={(b64) => {
                            if (this.state.is_bot_active === true && this.state.active_bot === "bot_treeporteur_rep") {
                                console.log(b64)
                                let bot_data = this.state.bot_recovered_data
                                bot_data.imageToPrint = b64
                                this.setState({ bot_recovered_data: bot_data, uploadedImage: null, showImageView: false })
                                this.executeBot("bot_treeporteur_rep", this.state.bot_treeporteur_rep, 9)
                            } else {
                                this.sendAnnotatedImage(b64)
                                this.setState({ uploadedImage: null, showImageView: false })
                            }

                        }}
                        reprendre={() => {
                            if (this.state.is_bot_active === true && this.state.active_bot === "POI") {
                                this.setState({ uploadedImage: null, showImageView: false })
                            } else {
                                this.setState({ uploadedImage: null, showImageView: false })
                                this.imageUpload.click()
                            }
                        }}
                    />
                }

                <DatePicker
                    id="datePicker-1"
                    ref={(ref) => this.datePickerRef = ref}
                    value={new Date()}
                    onChange={value => {
                        console.log(value)
                        setTimeout(() => {
                            this.addNewMessage(moment(value).format("YYYY-MM-DD"), "bot_saisie_fact")
                        }, 400)
                    }}
                    label=""
                    formatStyle="small"
                    locale="fr-Fr"
                    style={{ display: "none" }}
                    isCentered={true}
                />


                <Drawer anchor="bottom" open={this.state.openBottomSignModal}
                    onClose={() => this.setState({ openBottomSignModal: false })}
                    style={{ borderRadius: 20 }}
                >
                    <div style={{ padding: 15 }}>
                        <h5 style={{ color: "green", marginTop: 5 }}>Dessinez votre signature</h5>
                        <div style={{ marginTop: 20, textAlign: "center" }}>
                            <div style={{ height: 300, border: '3px solid #f0f0f0' }}>
                                <SignatureCanvas ref={(ref) => {
                                    this.sigCanvas = ref
                                }} penColor={"#000"}

                                    canvasProps={{
                                        width: 320,
                                        height: 300,
                                        className: 'sigCanvas'
                                    }}

                                    style={{ backgroundColor: "grey" }}
                                />
                                <div style={{ position: "absolute", bottom: 65, right: 30 }}>
                                    <HighlightOffIcon onClick={() => {
                                        this.sigCanvas.clear()
                                    }} />
                                </div>
                            </div>
                        </div>
                        <div align="right" style={{ marginTop: 15 }}>
                            <AtlButton
                                onClick={() => {
                                    let msgs = this.state.messages || [];

                                    msgs.push({
                                        created_at: moment().toDate(),
                                        id: utilFunctions.getUID(),
                                        room_id: this.state.room_id,
                                        text: "Votre document est en cours de traitement...",
                                        type: "text",
                                        sender: {
                                            email: "ChatBot"
                                        }
                                    })
                                    this.setState({ messages: msgs })
                                    setTimeout(() => {
                                        this.scrollToBottom();
                                        let signature = this.sigCanvas.getTrimmedCanvas().toDataURL('image/png');
                                        let data = {
                                            date: moment().format("DD/MM/YYYY"),
                                            signature: signature
                                        }
                                        console.log(data)
                                        this.sendAnnotatedImage(data.signature)
                                        this.setState({ openBottomSignModal: false })
                                    }, 250)
                                }}
                                appearance="primary"
                            >
                                Valider
                            </AtlButton>
                        </div>
                    </div>
                </Drawer>

                <Popover
                    id={id3}
                    open={openMsgMenuPopup}
                    anchorEl={this.state.anchorElMsgMenu}
                    onClose={() => {
                        this.setState({ anchorElMsgMenu: null })
                    }}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <Paper style={{ width: 260 }}>
                        <MenuList id="menu-list-grow">
                            <MenuItem style={{ minHeight: 40 }}
                                onClick={() => {
                                    this.setState({
                                        showTaggedMsgForm: true,
                                        anchorElMsgMenu: null,
                                        taggedMsg: this.state.selectedMsg
                                    })
                                }}
                            >
                                Répondre
                            </MenuItem>
                            <MenuItem style={{ minHeight: 40 }} onClick={() => {
                            }}>Marquer comme important</MenuItem>
                            <MenuItem style={{ minHeight: 40 }} onClick={() => {
                            }}>Copier</MenuItem>
                            <MenuItem style={{ minHeight: 40 }} onClick={() => {
                            }}>Infos</MenuItem>
                            <MenuItem style={{ minHeight: 40 }} onClick={() => {
                            }}>Supprimer</MenuItem>
                        </MenuList>
                    </Paper>
                </Popover>

                {
                    this.state.showImageModal === true &&
                    <Lightbox
                        imageBackgroundColor="#fff"
                        medium={this.state.imageModal}
                        large={this.state.imageModal}
                        alt=""
                        onClose={() => {
                            this.setState({ showImageModal: false, imageModal: "" })
                        }}
                    />
                }

                <ResponsiveModal
                    open={this.state.showPlanMap}
                    onClose={() => {
                        this.setState({ showPlanMap: false })
                    }}
                    styles={planMapModalStyles}
                    animationDuration={1000}
                    focusTrapped={true}
                    closeIconSize={20}
                    showCloseIcon={true}
                >

                    <div style={{ marginTop: 30, position: "relative" }}>
                        <div id="map-plan-intercation">
                            <MapInteractionCSS
                                value={this.state.mapValue}
                                onChange={(data) => {
                                    this.setState({ mapValue: data })
                                }}
                                showControls={false}
                            >
                                <div style={{ paddingTop: 85, paddingLeft: 45 }}>
                                    <img ref={this.state.mapImageRef}
                                        alt="" src={this.state.selected_bot_plan_image || ""} style={{ pointerEvents: 'none' }}
                                    />
                                </div>

                                <Rnd key={`rnd-0`}
                                    default={{
                                        x: 258.893866,
                                        y: 85.3585715
                                    }}
                                    scale={this.state.mapValue.scale}
                                    bounds="parent"
                                    style={{ zIndex: 1050 }}
                                    size={{ width: 60, height: 60 }}
                                    onDragStart={(e, d) => {
                                        e.preventDefault()
                                        e.stopPropagation()
                                    }}
                                    onDrag={(e, d) => {
                                        e.preventDefault()
                                        e.stopPropagation()
                                    }}
                                    onDragStop={(e, d) => {
                                        let bot_data = this.state.bot_recovered_data
                                        bot_data.poi_image_position = { x: d.x, y: d.y }
                                        this.setState({ bot_recovered_data: bot_data })
                                        //setFinalMarkerPosition({x:d.x,y:d.y})
                                    }}
                                    enableResizing={false}
                                >

                                    {/*<img alt={""} src={redmMarker} style={{width:100,height:100}}/>*/}
                                    <div className="speech-bubble">
                                        <img alt={""} src={this.state.bot_recovered_data.poi_image || ""}
                                            style={{ width: "100%", height: "100%", borderRadius: "50%" }} />
                                    </div>

                                </Rnd>
                            </MapInteractionCSS>
                        </div>
                    </div>


                </ResponsiveModal>


                <RsDrawer open={this.state.showDetailGroup} onClose={() => {
                    this.setState({ showDetailGroup: false })
                }}
                    size={"xs"}
                >
                    <RsDrawer.Body>
                        {
                            this.state.room ?
                                <div>
                                    <div align="center" style={{ marginTop: 20 }}>
                                        <RsIconButton icon={<PeoplesMapIcon size="10em" />} circle size="lg" />
                                        <h4 style={{ fontWeight: 700, fontSize: "1.7em" }}>{this.state.room.title}</h4>
                                    </div>
                                    <div style={{ marginTop: 20 }}>
                                        <h5>{this.state.room.members.length + " participants"}</h5>
                                        <div style={{ marginTop: 15 }}>
                                            {
                                                this.state.room.members.map((item, key) => (
                                                    <div key={key}>
                                                        <RenderUserAvatar email={item.email} />
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div> :
                                <ShimmerText line={5} />
                        }


                    </RsDrawer.Body>
                </RsDrawer>


                <RsDrawer open={this.state.showDetailBimObject} onClose={() => {
                    this.setState({ showDetailBimObject: false })
                }}
                    size={"xs"}
                >
                    <RsDrawer.Body>
                        {
                            this.state.selectedBimObject ?
                                <div>
                                    <div align="center" style={{ marginTop: 20 }}>
                                        <Carousel autoPlay={true}
                                            showThumbs={false}
                                            emulateTouch={true}
                                            swipeable={true}
                                            dynamicHeight={false}
                                            infiniteLoop={true}
                                        >
                                            {
                                                [this.state.selectedBimObject.Photo].map((img, k) => (
                                                    <div key={k}>
                                                        <img alt="" src={img} style={{ maxHeight: 180, objectFit: "contain" }} />
                                                    </div>
                                                ))
                                            }

                                        </Carousel>
                                    </div>
                                    <div align="center" className="mt-2">
                                        <h4>{this.state.selectedBimObject.Name}</h4>
                                        <p>{this.state.selectedBimObject.Description || ""}</p>
                                    </div>
                                    <div style={{ marginTop: 20 }}>
                                        <h5>Classifications:</h5>
                                        <TagGroup>
                                            {
                                                (this.state.selectedBimObject.Classifications || []).map((classification, index) => (
                                                    <Tag key={index} closable={false}
                                                    >{classification.LeafName || ""}</Tag>
                                                ))
                                            }
                                        </TagGroup>
                                    </div>
                                </div> :
                                <ShimmerText line={5} />
                        }


                    </RsDrawer.Body>
                </RsDrawer>
                <Dialog
                    open={this.state.openEmailModal}
                    onClose={() => {
                        this.setState({ openEmailModal: !this.state.openEmailModal });
                    }}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle disableTypography id="form-dialog-title">
                        <Typography variant="h6"
                            style={{ color: "#0A3936" }}>Nom de groupe</Typography>

                    </DialogTitle>
                    <DialogContent style={{ overflowY: "inherit" }}>
                        <div className="row">
                            <div className="col-lg-12">
                                <TextField
                                    id="room-name"
                                    placeholder="Name"
                                    variant="outlined"
                                    value={this.state.roomTitle}
                                    onChange={(event) =>
                                        this.setState({ roomTitle: event.target.value })
                                    }
                                    style={{ width: "100%", borderColor: "#1565C0" }}
                                    size="small"
                                />
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions className="justify-content-center" style={{ padding: 20, textAlign: "center" }}>
                        <MuiButton
                            onClick={(e) => {
                                this.addNewRoom()
                            }}
                            disabled={this.state.roomTitle === ""}
                            color="secondary"
                            variant="contained"
                            style={{ textTransform: 'none', color: "#fff", backgroundColor: "#5C2DA8", borderRadius: 20 }}
                        >
                            Créer le groupe
                        </MuiButton>
                    </DialogActions>
                </Dialog>
                <Dialog
                    disableEnforceFocus
                    open={this.state.openModalInfo}
                    aria-labelledby="form-dialog-title"
                    fullWidth={true}
                    maxWidth="lg"
                    onClose={(e) => {
                        this.setState({ openModalInfo: false })
                    }}
                >
                    <DialogTitle id="form-dialog-title" style={{ paddingBottom: 1 }}>
                        <div className="d-flex align-items-center" style={{ gap: 10 }}>
                            <EditIcon style={{ color: '#1565c0' }} fontSize="small" />
                            <Typography style={{ color: '#1565C0', fontWeight: 600 }}>{"Informations du devis"} </Typography>
                        </div>
                        <IconButton
                            aria-label="close"
                            style={{
                                position: 'absolute',
                                right: 5,
                                top: 5,
                                color: '#c0c0c0'
                            }}
                            onClick={() => {
                                this.setState({ openModalInfo: false })
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                        <hr style={{ color: "#1565C0" }} />
                    </DialogTitle>
                    <DialogContent style={{ overflowY: "inherit" }}>
                        <>
                            <div className="p-2">
                                <div className="mb-2">
                                    <span style={{ fontWeight: 600 }}>* = Champs obligatoires</span>

                                </div>

                                <span style={{ color: "#616161", fontFamily: "Montserrat", fontWeight: 700, fontSize: 18 }}>PORTEUR/PORTEUSE DU PROJET</span>

                                <div className="row mt-3 align-items-center justify-content-around">
                                    <div className="col-md-6">
                                        <div className="d-flex flex-column" style={{ gap: 10 }}>
                                            <span style={{ color: "#616161", fontFamily: "Montserrat", fontWeight: 700 }}>Nom, prénom ou dénomination sociale*</span>
                                            <TextField disabled={true} fullWidth value={this.state.nomPorteur} onChange={(e) => { this.setState({ nomPorteur: e.target.value }) }} size="small" id="outlined-basic" variant="outlined" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="d-flex flex-column" style={{ gap: 10 }}>
                                            <span style={{ color: "#616161", fontFamily: "Montserrat", fontWeight: 700 }}>Pays*</span>
                                            <TextField disabled={true} fullWidth value={this.state.paysPorteur} onChange={(e) => { this.setState({ paysPorteur: e.target.value }) }} size="small" id="outlined-basic" variant="outlined" />
                                        </div>
                                    </div>

                                </div>

                                <div className="row mt-3 align-items-center justify-content-around">
                                    <div className="col-md-6">
                                        <div className="d-flex flex-column" style={{ gap: 10 }}>
                                            <span style={{ color: "#616161", fontFamily: "Montserrat", fontWeight: 700 }}>Adresse*</span>
                                            <TextField disabled={true} fullWidth value={this.state.adressePorteur} onChange={(e) => { this.setState({ adressePorteur: e.target.value }) }} size="small" id="outlined-basic" variant="outlined" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="d-flex flex-column" style={{ gap: 10 }}>
                                            <span style={{ color: "#616161", fontFamily: "Montserrat", fontWeight: 700 }}>Complément d’adresse</span>
                                            <TextField disabled={true} fullWidth value={this.state.complAdressPorteur} onChange={(e) => { this.setState({ complAdressPorteur: e.target.value }) }} size="small" id="outlined-basic" variant="outlined" />
                                        </div>
                                    </div>

                                </div>

                                <div className="row mt-3 align-items-center justify-content-around">
                                    <div className="col-md-6">
                                        <div className="d-flex flex-column" style={{ gap: 10 }}>
                                            <span style={{ color: "#616161", fontFamily: "Montserrat", fontWeight: 700 }}>Code postal*</span>
                                            <TextField disabled={true} fullWidth value={this.state.codepPorteur} onChange={(e) => { this.setState({ codepPorteur: e.target.value }) }} size="small" id="outlined-basic" variant="outlined" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="d-flex flex-column" style={{ gap: 10 }}>
                                            <span style={{ color: "#616161", fontFamily: "Montserrat", fontWeight: 700 }}>Ville*</span>
                                            <TextField disabled={true} fullWidth value={this.state.villePorteur} onChange={(e) => { this.setState({ villePorteur: e.target.value }) }} size="small" id="outlined-basic" variant="outlined" />
                                        </div>
                                    </div>

                                </div>

                                <div className="row mt-3 mb-4 align-items-center justify-content-around">
                                    <div className="col-md-6">
                                        <div className="d-flex flex-column" style={{ gap: 10 }}>
                                            <span style={{ color: "#616161", fontFamily: "Montserrat", fontWeight: 700 }}>Email*</span>
                                            <TextField disabled={true} fullWidth value={this.state.emailPorteur} onChange={(e) => { this.setState({ emailPorteur: e.target.value }) }} size="small" id="outlined-basic" variant="outlined" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="d-flex flex-column" style={{ gap: 10 }}>
                                            <span style={{ color: "#616161", fontFamily: "Montserrat", fontWeight: 700 }}>Numéro de téléphone*</span>
                                            <TextField disabled={true} fullWidth value={this.state.mobilePorteur} onChange={(e) => { this.setState({ mobilePorteur: e.target.value }) }} size="small" id="outlined-basic" variant="outlined" />
                                        </div>
                                    </div>

                                </div>

                                <span style={{ color: "#616161", fontFamily: "Montserrat", fontWeight: 700, fontSize: 18 }}>VOUS (FREELANCE)</span>

                                <div className="row mt-3 align-items-center justify-content-around">
                                    <div className="col-md-6">
                                        <div className="d-flex flex-column" style={{ gap: 10 }}>
                                            <span style={{ color: "#616161", fontFamily: "Montserrat", fontWeight: 700 }}>{this.state.userType === "Une personne morale" ? "Nom, prénom ou dénomination sociale*" : "Nom, prénom"}</span>
                                            <TextField fullWidth
                                                error={this.state.nomFreelancer === "" ? true : false}
                                                value={this.state.nomFreelancer} onChange={(e) => { this.setState({ nomFreelancer: e.target.value }) }} size="small" id="outlined-basic" variant="outlined" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="d-flex flex-column" style={{ gap: 10 }}>
                                            <span style={{ color: "#616161", fontFamily: "Montserrat", fontWeight: 700 }}>Pays*</span>
                                            <TextField fullWidth
                                                error={this.state.paysFreelancer === "" ? true : false}
                                                value={this.state.paysFreelancer} onChange={(e) => { this.setState({ paysFreelancer: e.target.value }) }} size="small" id="outlined-basic" variant="outlined" />
                                        </div>
                                    </div>

                                </div>

                                <div className="row mt-3 align-items-center justify-content-around">
                                    <div className="col-md-6">
                                        <div className="d-flex flex-column" style={{ gap: 10 }}>
                                            <span style={{ color: "#616161", fontFamily: "Montserrat", fontWeight: 700 }}>Adresse*</span>
                                            <TextField fullWidth
                                                error={this.state.adresseFreelancer === "" ? true : false}
                                                value={this.state.adresseFreelancer} onChange={(e) => { this.setState({ adresseFreelancer: e.target.value }) }} size="small" id="outlined-basic" variant="outlined" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="d-flex flex-column" style={{ gap: 10 }}>
                                            <span style={{ color: "#616161", fontFamily: "Montserrat", fontWeight: 700 }}>Complément d’adresse</span>
                                            <TextField fullWidth value={this.state.complAdressFreelancer} onChange={(e) => { this.setState({ complAdressFreelancer: e.target.value }) }} size="small" id="outlined-basic" variant="outlined" />
                                        </div>
                                    </div>

                                </div>

                                <div className="row mt-3 align-items-center justify-content-around">
                                    <div className="col-md-6">
                                        <div className="d-flex flex-column" style={{ gap: 10 }}>
                                            <span style={{ color: "#616161", fontFamily: "Montserrat", fontWeight: 700 }}>Code postal*</span>
                                            <TextField fullWidth
                                                error={this.state.codepFreelancer === "" ? true : false}
                                                value={this.state.codepFreelancer} onChange={(e) => { this.setState({ codepFreelancer: e.target.value }) }} size="small" id="outlined-basic" variant="outlined" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="d-flex flex-column" style={{ gap: 10 }}>
                                            <span style={{ color: "#616161", fontFamily: "Montserrat", fontWeight: 700 }}>Ville*</span>
                                            <TextField fullWidth
                                                error={this.state.villeFreelancer === "" ? true : false}
                                                value={this.state.villeFreelancer} onChange={(e) => { this.setState({ villeFreelancer: e.target.value }) }} size="small" id="outlined-basic" variant="outlined" />
                                        </div>
                                    </div>

                                </div>

                                <div className="row mb-4 mt-3 align-items-center justify-content-around">
                                    <div className="col-md-6">
                                        <div className="d-flex flex-column" style={{ gap: 10 }}>
                                            <span style={{ color: "#616161", fontFamily: "Montserrat", fontWeight: 700 }}>Email*</span>
                                            <TextField fullWidth
                                                error={this.state.emailFreelancer === "" ? true : false}
                                                value={this.state.emailFreelancer} onChange={(e) => { this.setState({ emailFreelancer: e.target.value }) }} size="small" id="outlined-basic" variant="outlined" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="d-flex flex-column" style={{ gap: 10 }}>
                                            <span style={{ color: "#616161", fontFamily: "Montserrat", fontWeight: 700 }}>Numéro de téléphone*</span>
                                            <TextField fullWidth
                                                value={this.state.mobileFreelancer}
                                                onChange={(e) => { this.setState({ mobileFreelancer: e.target.value }) }} size="small" id="outlined-basic" variant="outlined" />
                                        </div>
                                    </div>

                                </div>

                                <span style={{ color: "#616161", fontFamily: "Montserrat", fontWeight: 700, fontSize: 18 }}>DÉTAILS DU DEVIS</span>

                                <div className="row mt-3 align-items-center justify-content-around">
                                    <div className="col-md-12">
                                        <div className="d-flex flex-column" style={{ gap: 10 }}>
                                            <span style={{ color: "#616161", fontFamily: "Montserrat", fontWeight: 700 }}>Résumé détaillé de la mission à effectuer*</span>
                                            <TextField fullWidth
                                                multiline
                                                error={this.state.mission_description === "" ? true : false}
                                                value={this.state.mission_description}
                                                onChange={(e) => { this.setState({ mission_description: e.target.value }) }}
                                                size="small" id="outlined-basic" variant="outlined" />
                                                <div className="d-flex justify-content-end">
                            <span style={{ fontSize: 12, color: this.state.mission_description.length > 500 ? 'red' : "#b1b1b1" }} className="span-title mt-1">{this.state.mission_description.length} / 500 caractères</span>
                        </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row mt-3 mb-4 align-items-center justify-content-around">
                                    <div className="col-md-6">
                                        <div className="d-flex flex-column" style={{ gap: 5 }}>
                                            <span style={{ color: "#616161", fontFamily: "Montserrat", fontWeight: 700 }}>Montant du devis*</span>
                                            <FormControl variant="outlined">
                                                <OutlinedInput
                                                    error={parseInt(this.state.montant) > 0 ? false : true}
                                                    fullWidth
                                                    style={{ height: 45 }}
                                                    size="small"
                                                    value={this.state.montant}
                                                    onChange={(e) => { this.setState({ montant: e.target.value, montantMga: parseInt(e.target.value) * 4870 }) }}
                                                    id="outlined-adornment-weight"
                                                    endAdornment={<InputAdornment position="end">{parseInt(this.state.montant) > 0 ? "Euros (" + (parseInt(this.state.montant) * 4870).toFixed(2) + " MGA)" : "€"}</InputAdornment>}
                                                    aria-describedby="outlined-weight-helper-text"
                                                    inputProps={{
                                                        'aria-label': 'weight',
                                                    }}
                                                />
                                            </FormControl>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="d-flex flex-column" style={{ gap: 5 }}>
                                            <span style={{ color: "#616161", fontFamily: "Montserrat", fontWeight: 700 }}>Modalité de paiement*</span>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={this.state.modalite}
                                                size="small"
                                                error={this.state.modalite === "" ? true : false}
                                                style={{ height: 45 }}
                                                fullWidth
                                                onChange={(e) => { this.setState({ modalite: e.target.value }) }}
                                            >
                                                <MenuItem value={"Virement bancaire"}>Virement bancaire</MenuItem>
                                                <MenuItem value={"Chèque"}>Chèque</MenuItem>
                                                <MenuItem value={"PayPal"}>PayPal</MenuItem>
                                            </Select>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </>

                    </DialogContent>
                    <DialogActions style={{ padding: 20, justifyContent: 'end' }}>
                        <MuiButton
                            color="primary"
                            variant="outlined"
                            style={{ textTransform: 'none' }}
                            onClick={() => {

                            }}
                        >
                            Annuler
                        </MuiButton>
                        <MuiButton
                            onClick={() => { this.setState({ openModalDevis: true, openModalInfo: false }) }}
                            variant="contained"
                            disabled={this.state.montant === "" || this.state.montant === "0" || this.state.montant === 0 || this.state.modalite === "" || this.state.mobileFreelancer === "" || this.state.villeFreelancer === "" || this.state.codepFreelancer === "" || this.state.mission_description === "" || this.state.mission_description.length > 500}
                            style={{ textTransform: 'none', color: "#fff", backgroundColor: this.state.montant === "" || this.state.montant === "0" || this.state.montant === 0 || this.state.modalite === "" || this.state.villeFreelancer === "" || this.state.mobileFreelancer === "" || this.state.codepFreelancer === "" || this.state.mission_description === "" || this.state.mission_description.length > 500 ? 'grey' : "#1565C0" }}
                        >
                            Créer et vérifier le devis
                        </MuiButton>
                    </DialogActions>
                </Dialog>

                <Dialog
                    disableEnforceFocus
                    open={this.state.openModalDevis}
                    aria-labelledby="form-dialog-title"
                    fullWidth={true}
                    maxWidth="lg"
                    onClose={(e) => {
                        this.setState({ openModalDevis: false })
                    }}
                >
                    <DialogTitle id="form-dialog-title" style={{ paddingBottom: 1 }}>
                        <div className="d-flex align-items-center" style={{ gap: 10 }}>
                            <SearchIcon style={{ color: '#1565c0' }} fontSize="small" />
                            <Typography style={{ color: '#1565C0', fontWeight: 600 }}>{"On récapitule"} </Typography>
                        </div>
                        <IconButton
                            aria-label="close"
                            style={{
                                position: 'absolute',
                                right: 5,
                                top: 5,
                                color: '#c0c0c0'
                            }}
                            onClick={() => {
                                this.setState({ openModalDevis: false })
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                        <hr style={{ color: "#1565C0" }} />
                    </DialogTitle>
                    <DialogContent style={{ overflowY: "inherit" }}>
                        <>
                            <div className="p-2">
                                <div style={{ minHeight: 940 }} id="pdf-content" ref={this.containerRef}>

                                    <div className="d-flex align-items-end justify-content-between px-2">
                                        <div>
                                            <span style={{ fontSize: 36, color: '#000', fontWeight: 700 }}>Build.</span>
                                            <span style={{ fontSize: 36, color: '#214dcb', fontWeight: 700 }}>Mada</span>
                                        </div>
                                        <div className="d-flex align-items-end" style={{ gap: 20 }}>
                                            <span style={{ fontSize: 30, fontWeight: 700 }}>Devis N°</span>
                                            <span style={{ fontSize: 25, fontWeight: 700, color: "grey" }}>D2024-{this.state.docDevisNum.toString().padStart(4, '0')}</span>
                                        </div>

                                    </div>
                                    <div className="d-flex justify-content-end px-2">
                                        <span style={{ fontSize: 25, fontWeight: 600 }}>{moment().format("DD/MM/YYYY")}</span>
                                    </div>
                                    <div className="d-flex justify-content-between px-4">
                                        <div className="d-flex flex-column">
                                            <div className="mt-2">
                                                <span style={{ fontSize: 20, fontWeight: 700 }}>{this.state.nomFreelancer}</span>
                                            </div>
                                            <span style={{ fontSize: 16, fontWeight: 600 }}>{this.state.adresseFreelancer}</span>
                                            <span style={{ fontSize: 16, fontWeight: 600 }}>{this.state.codepFreelancer} - {this.state.villeFreelancer}</span>
                                            <span style={{ fontSize: 16, fontWeight: 600 }}>(Tél): {this.state.mobileFreelancer} / {this.state.emailFreelancer}</span>
                                        </div>

                                        <div className="d-flex flex-column">
                                            <div className="mt-2">
                                                <span style={{ fontSize: 20, fontWeight: 700 }}>{this.state.nomPorteur}</span>
                                            </div>
                                            <span style={{ fontSize: 16, fontWeight: 600 }}>{this.state.adressePorteur}</span>
                                            <span style={{ fontSize: 16, fontWeight: 600 }}>{this.state.codepPorteur} - {this.state.villePorteur}</span>
                                            <span style={{ fontSize: 16, fontWeight: 600 }}>{this.state.paysPorteur}</span>
                                        </div>
                                    </div>
                                    <div style={{height:570}}>
                                    <div className="mb-1">
                                        <table style={{ width: "100%", border: "1px solid grey", marginTop: 30 }}>
                                            <tr style={{ backgroundColor: "#6665dd", color: "#fff" }}>
                                                <th className="px-2 pb-3">Désignation</th>
                                                <th className="px-2 pb-3">Quantité</th>
                                                <th className="px-2 pb-3">TVA %</th>
                                                <th className="px-2 pb-3">Montant TVA</th>
                                                <th className="px-2 pb-3">Total TTC</th>
                                            </tr>
                                            <tr>
                                                {this.state.missionRoom &&
                                                    <td className="p-2">
                                                        <div className="d-flex flex-column" style={{ maxWidth: 600 }}>
                                                            <span style={{ fontWeight: 700 }}>{this.state.missionRoom.title}</span>
                                                            <span>{this.state.mission_description}</span>
                                                        </div>

                                                    </td>}
                                                <td className="p-2" style={{ fontWeight: 600 }}>1</td>
                                                <td className="p-2" style={{ fontWeight: 600 }}>10%</td>
                                                <td className="p-2" style={{ fontWeight: 600 }}>{(parseInt(this.state.montant) / 10).toFixed(2)} €</td>
                                                <td className="p-2" style={{ fontWeight: 600 }}>{parseInt(this.state.montant) + (parseInt(this.state.montant) / 10)} €</td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div className="mt-2 d-flex justify-content-between">
                                        <div>
                                            <div className="d-flex flex-column mt-2">
                                                <span style={{ fontWeight: 600 }}>Conditions de paiement</span>
                                                <div className="d-flex" style={{ gap: 20 }}>
                                                    <span>Acompte de 20% à la signature :</span>
                                                    <span>{((parseInt(this.state.montant) + (parseInt(this.state.montant) / 10)) / 5).toFixed(2)} €</span>
                                                </div>
                                                <span>Solde à la livraison</span>
                                            </div>

                                            <div className="d-flex flex-column mt-2">
                                                <span style={{ fontWeight: 600 }}>Moyens de paiement</span>
                                                <div className="d-flex" style={{ gap: 10 }}>
                                                    <span>{this.state.modalite}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="d-flex justify-content-end align-items-end px-2" style={{ gap: 30 }}>
                                                <span style={{ fontWeight: 700, fontSize: 20 }}>Total HT</span>
                                                <span style={{ fontWeight: 600, fontSize: 20 }}>{parseFloat(this.state.montant).toFixed(2)} €</span>
                                                <span style={{ fontWeight: 500, fontSize: 16 }}>{'(' + ((parseInt(this.state.montant)) * 4870).toFixed(2) + ' MGA)'}</span>
                                            </div>
                                            <div className="d-flex justify-content-end align-items-end px-2" style={{ gap: 30 }}>
                                                <span style={{ fontWeight: 700, fontSize: 20 }}>Total TVA</span>
                                                <span style={{ fontWeight: 600, fontSize: 20 }}>{parseInt(this.state.montant) / 10} €</span>
                                                <span style={{ fontWeight: 500, fontSize: 16 }}>{'(' + (((parseInt(this.state.montant) / 10)) * 4870).toFixed(2) + ' MGA)'}</span>
                                            </div>
                                            <div className="d-flex justify-content-end align-items-center px-2" style={{ gap: 15 }}>
                                                <span style={{ fontWeight: 700, fontSize: 20 }}>Total TTC</span>
                                                <span style={{ fontWeight: 600, fontSize: 20 }}>{parseInt(this.state.montant) + (parseInt(this.state.montant) / 10)} € </span>
                                                <span style={{ fontWeight: 500, fontSize: 16 }}>{'(' + ((parseInt(this.state.montant) + (parseInt(this.state.montant) / 10)) * 4870).toFixed(2) + ' MGA)'}</span>
                                            </div>
                                        </div>

                                    </div>
                                    </div>
                                    <div className="d-flex justify-content-center mt-2">
                                        <div className="d-flex flex-column mt-3">
                                            <span style={{ fontWeight: 600, color: 'grey', textAlign: 'center' }}>Exemple SARL au capital de 0,00 €</span>
                                            <span style={{ fontWeight: 600, color: 'grey', textAlign: 'center' }}>{this.state.adressePorteur} - Numéro de TVA : XXX</span>
                                            <span style={{ fontWeight: 600, color: 'grey', textAlign: 'center' }}>Assurance XXX</span>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-end px-4">
                                        <span style={{ fontWeight: 600, fontSize: 18 }}>Signature porteur du projet</span>
                                    </div>
                                </div>

                                <div className="d-flex align-items-center" style={{ gap: 10, marginTop: 50 }}>
                                    <Checkbox
                                        checked={this.state.checked}
                                        onChange={this.handleChange}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                    <span onClick={(e) => { this.setState({ checked: !this.state.checked }) }} className="span-footer">En cochant cette case, je reconnaît avoir fourni des informations exactes, valides et conformes à toutes les lois et réglementations en vigueur</span>
                                </div>
                            </div>
                        </>
                    </DialogContent>
                    <DialogActions style={{ padding: 20, justifyContent: 'end' }}>
                        <MuiButton
                            color="primary"
                            variant="outlined"
                            style={{ textTransform: 'none' }}
                            onClick={() => {
                                this.setState({ openModalDevis: false, openModalInfo: true })
                            }}
                        >
                            Modifier le devis
                        </MuiButton>
                        <MuiButton
                            onClick={() => {
                                this.generatePdf()
                                this.setState({ openModalDevis: false })
                            }}
                            disabled={!this.state.checked}
                            variant="contained"
                            color="primary"
                            style={{ textTransform: 'none' }}
                        >
                            Valider et envoyer
                        </MuiButton>
                    </DialogActions>
                </Dialog>

                <Dialog
                    disableEnforceFocus
                    open={this.state.openModalDevisSign}
                    aria-labelledby="form-dialog-title"
                    fullWidth={true}
                    maxWidth="lg"
                    onClose={(e) => {
                        this.setState({ openModalDevisSign: false })
                    }}
                >
                    <DialogTitle id="form-dialog-title" style={{ paddingBottom: 1 }}>
                        <div className="d-flex align-items-center" style={{ gap: 10 }}>
                            <SearchIcon style={{ color: '#1565c0' }} fontSize="small" />
                            <Typography style={{ color: '#1565C0', fontWeight: 600 }}>{"On récapitule"} </Typography>
                        </div>
                        <IconButton
                            aria-label="close"
                            style={{
                                position: 'absolute',
                                right: 5,
                                top: 5,
                                color: '#c0c0c0'
                            }}
                            onClick={() => {
                                this.setState({ openModalDevisSign: false })
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                        <hr style={{ color: "#1565C0" }} />
                    </DialogTitle>
                    <DialogContent style={{ overflowY: "inherit" }}>
                        <>
                            <div className="p-2">
                                <div style={{ minHeight: 1000 }} id="pdf-content" ref={this.containerRef}>

                                    <div className="d-flex align-items-end justify-content-between px-2">
                                        <div>
                                            <span style={{ fontSize: 36, color: '#000', fontWeight: 700 }}>Build.</span>
                                            <span style={{ fontSize: 36, color: '#214dcb', fontWeight: 700 }}>Mada</span>
                                        </div>
                                        <div className="d-flex align-items-end" style={{ gap: 20 }}>
                                            <span style={{ fontSize: 30, fontWeight: 700 }}>Devis N°</span>
                                            <span style={{ fontSize: 25, fontWeight: 700, color: "grey" }}>{(this.state.room && this.state.room.num_devis) ? this.state.room.num_devis : "D2024-001"}</span>
                                        </div>

                                    </div>
                                    <div className="d-flex justify-content-end px-2">
                                        <span style={{ fontSize: 25, fontWeight: 600 }}>{moment(this.state.docDevisDate).format("DD/MM/YYYY")}</span>
                                    </div>
                                    <div className="d-flex justify-content-between px-4">
                                        <div className="d-flex flex-column">
                                            <div className="mt-4">
                                                <span style={{ fontSize: 20, fontWeight: 700 }}>{this.state.nomFreelancer}</span>
                                            </div>
                                            <span style={{ fontSize: 16, fontWeight: 600 }}>{this.state.adresseFreelancer}</span>
                                            <span style={{ fontSize: 16, fontWeight: 600 }}>{this.state.codepFreelancer} - {this.state.villeFreelancer}</span>
                                            <span style={{ fontSize: 16, fontWeight: 600 }}>(Tél): {this.state.mobileFreelancer} / {this.state.emailFreelancer}</span>
                                        </div>

                                        <div className="d-flex flex-column">
                                            <div className="mt-4">
                                                <span style={{ fontSize: 20, fontWeight: 700 }}>{this.state.nomPorteur}</span>
                                            </div>
                                            <span style={{ fontSize: 16, fontWeight: 600 }}>{this.state.adressePorteur}</span>
                                            <span style={{ fontSize: 16, fontWeight: 600 }}>{this.state.codepPorteur} - {this.state.villePorteur}</span>
                                            <span style={{ fontSize: 16, fontWeight: 600 }}>{this.state.paysPorteur}</span>
                                        </div>
                                    </div>

                                    <div className="mb-2">
                                        <table style={{ width: "100%", border: "1px solid grey", marginTop: 70 }}>
                                            <tr style={{ backgroundColor: "#6665dd", color: "#fff" }}>
                                                <th className="px-2 pb-3">Désignation</th>
                                                <th className="px-2 pb-3">Quantité</th>
                                                <th className="px-2 pb-3">TVA %</th>
                                                <th className="px-2 pb-3">Montant TVA</th>
                                                <th className="px-2 pb-3">Total TTC</th>
                                            </tr>
                                            <tr>
                                                {this.state.missionRoom &&
                                                    <td className="p-2">
                                                        <div className="d-flex flex-column" style={{ maxWidth: 600 }}>
                                                            <span style={{ fontWeight: 700 }}>{this.state.missionRoom.title}</span>
                                                            <span>{this.state.room.mission_description}</span>
                                                        </div>

                                                    </td>}
                                                <td className="p-2" style={{ fontWeight: 600 }}>1</td>
                                                <td className="p-2" style={{ fontWeight: 600 }}>10%</td>
                                                <td className="p-2" style={{ fontWeight: 600 }}>{parseInt(this.state.montant) === 0 ? 'chargement...' : parseInt(this.state.montant) / 10} €</td>
                                                <td className="p-2" style={{ fontWeight: 600 }}>{parseInt(this.state.montant) === 0 ? 'chargement...' : parseInt(this.state.montant) + (parseInt(this.state.montant) / 10)} €</td>
                                            </tr>
                                        </table>
                                    </div>

                                    <div className="d-flex justify-content-end align-items-end px-2" style={{ gap: 30 }}>
                                        <span style={{ fontWeight: 700, fontSize: 20 }}>Total HT</span>
                                        <span style={{ fontWeight: 600, fontSize: 20 }}>{parseFloat(this.state.montant).toFixed(2)} €</span>
                                        <span style={{ fontWeight: 500, fontSize: 16 }}>{'(' + ((parseInt(this.state.montant)) * 4870).toFixed(2) + ' MGA)'}</span>
                                    </div>
                                    <div className="d-flex justify-content-end align-items-end px-2" style={{ gap: 30 }}>
                                        <span style={{ fontWeight: 700, fontSize: 20 }}>Total TVA</span>
                                        <span style={{ fontWeight: 600, fontSize: 20 }}>{parseInt(this.state.montant) === 0 ? 'chargement...' : parseInt(this.state.montant) / 10} €</span>
                                        <span style={{ fontWeight: 500, fontSize: 16 }}>{'(' + (((parseInt(this.state.montant) / 10)) * 4870).toFixed(2) + ' MGA)'}</span>
                                    </div>
                                    <div className="d-flex justify-content-end align-items-center px-2" style={{ gap: 30 }}>
                                        <span style={{ fontWeight: 700, fontSize: 20 }}>Total TTC</span>
                                        <span style={{ fontWeight: 600, fontSize: 20 }}>{parseInt(this.state.montant) === 0 ? 'chargement...' : parseInt(this.state.montant) + (parseInt(this.state.montant) / 10)} € </span>
                                        <span style={{ fontWeight: 500, fontSize: 16 }}>{parseInt(this.state.montant) === 0 ? 'chargement...' : '(' + ((parseInt(this.state.montant) + (parseInt(this.state.montant) / 10)) * 4870).toFixed(2) + ' MGA)'}</span>
                                    </div>

                                    <div className="d-flex flex-column mt-4">
                                        <span style={{ fontWeight: 600 }}>Conditions de paiement</span>
                                        <div className="d-flex" style={{ gap: 20 }}>
                                            <span>Acompte de 20% à la signature :</span>
                                            <span>{parseInt(this.state.montant) === 0 ? 'chargement...' : ((parseInt(this.state.montant) + (parseInt(this.state.montant) / 10)) / 5).toFixed(2)} €</span>
                                        </div>
                                        <span>Solde à la livraison</span>
                                    </div>

                                    <div className="d-flex flex-column mt-3">
                                        <span style={{ fontWeight: 600 }}>Moyens de paiement</span>
                                        <div className="d-flex" style={{ gap: 10 }}>
                                            <span>{this.state.modalite}</span>
                                            <span>sur le compte xxx</span>
                                        </div>
                                    </div>

                                    <div className="d-flex justify-content-center mt-4">
                                        <div className="d-flex flex-column mt-3">
                                            <span style={{ fontWeight: 600, color: 'grey', textAlign: 'center' }}>Exemple SARL au capital de 0,00 €</span>
                                            <span style={{ fontWeight: 600, color: 'grey', textAlign: 'center' }}>{this.state.adressePorteur} - Numéro de TVA : XXX</span>
                                            <span style={{ fontWeight: 600, color: 'grey', textAlign: 'center' }}>Assurance XXX</span>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-end px-4">
                                        <span style={{ fontWeight: 600, fontSize: 18 }}>Signature porteur du projet</span>
                                    </div>
                                    {localStorage.getItem('role') === "porteur" &&
                                        <div className="d-flex justify-content-end">
                                            <div style={{ marginTop: 20, textAlign: "center", width: 400 }}>
                                                <div style={{ height: 200, border: '3px solid #f0f0f0', backgroundImage: "url(/signaturebackg.png)", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
                                                    {/* <div style={{ marginTop: 70 }}>
                                                        <span style={{ fontSize: 24, color: "grey", fontWeight: 600 }}>Dessinez dans la zone de dessin</span>
                                                    </div> */}
                                                    <SignatureCanvas ref={(ref) => {
                                                        this.sigCanvas = ref
                                                    }} penColor={"#000"} canvasProps={{
                                                        width: 398,
                                                        height: 200,
                                                        className: 'sigCanvas'
                                                    }}
                                                        onBegin={(e) => { this.setState({ isStartSign: true }) }}
                                                        backgroundColor="transparent"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {localStorage.getItem('role') === "porteur" &&
                                        <div className="d-flex justify-content-end mt-2">
                                            <span style={{ fontWeight: 600, fontSize: 14 }}>En signant, je m’engage à régler la somme stipulée dans le devis dans les délais convenus.</span>
                                        </div>
                                    }
                                </div>
                            </div>
                        </>
                    </DialogContent>
                    {localStorage.getItem('role') === "porteur" &&
                        <DialogActions style={{ padding: 20, justifyContent: 'end' }}>
                            <MuiButton
                                color="error"
                                variant="outlined"
                                style={{ textTransform: 'none', borderColor: "red", color: 'red' }}
                                onClick={() => {
                                    this.setState({ openModalDevisSign: false, openModalRaison: true })
                                }}
                            >
                                Refuser le devis
                            </MuiButton>
                            <MuiButton
                                onClick={() => {
                                    setTimeout(async () => {
                                        this.setState({ loading: true })
                                        this.setState({ openModalDevisSign: false, isUpdateChat: false })
                                        let signature = this.sigCanvas.getTrimmedCanvas().toDataURL('image/png');
                                        const cleanBase64 = signature.replace(/^data:image\/png;base64,/, '');
                                        let data = {
                                            date: moment().format("DD/MM/YYYY"),
                                            b64: cleanBase64,
                                            fileName: "devis",
                                            w: 170,
                                            h: 80,
                                            page: 1
                                        }
                                        let b64 = await this.getB64ByUid(this.state.docDevis64)
                                        console.log(data)
                                        console.log(this.state.docDevis64)
                                        let data1 = {
                                            file64: "data:application/pdf;base64," + b64,
                                            signature: data
                                        }
                                        apiNodeService.signDevis(data1).then(res => {
                                            console.log(res)
                                            this.downloadPdf(res.pdf, "devis")
                                            this.confirmOffre()
                                            // this.insertDoc(res.pdf)
                                            this.addB64FileDevis(res.pdf, "devis-signe")
                                            this.setState({ openModalDevisSign: false })
                                        })
                                    }, 250)
                                }}
                                variant="contained"
                                color="primary"
                                style={{ textTransform: 'none' }}
                                disabled={!this.state.isStartSign}
                            >
                                Signer et envoyer
                            </MuiButton>
                        </DialogActions>
                    }
                </Dialog>


                <Dialog
                    disableEnforceFocus
                    open={this.state.openModalRaison}
                    aria-labelledby="form-dialog-title"
                    fullWidth={true}
                    maxWidth="md"
                    onClose={(e) => {
                        this.setState({ openModalRaison: false })
                    }}
                >
                    <DialogTitle id="form-dialog-title" style={{ paddingBottom: 1 }}>
                        <div className="d-flex align-items-center" style={{ gap: 10 }}>
                            <CloseIcon style={{ color: '#1565c0' }} />
                            <Typography style={{ color: '#1565C0', fontWeight: 600 }}>{"Explication du rejet"} </Typography>
                        </div>
                        <IconButton
                            aria-label="close"
                            style={{
                                position: 'absolute',
                                right: 5,
                                top: 5,
                                color: '#c0c0c0'
                            }}
                            onClick={() => {
                                this.setState({ openModalRaison: false })
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                        <hr style={{ color: "#1565C0" }} />
                    </DialogTitle>
                    <DialogContent style={{ overflowY: "inherit" }}>
                        <>
                            <div className="p-2">
                                <div className="mb-4">
                                    <span style={{ color: 'grey', fontWeight: 600 }}>Avant de rejeter la proposition de devis, pourriez-vous expliquer les raisons de ce rejet afin que le freelance puisse ajuster son offre en conséquence ?</span>
                                </div>
                                <TextField fullWidth value={this.state.refusRaison} onChange={(e) => { this.setState({ refusRaison: e.target.value }) }} size="small" id="outlined-basic122" variant="outlined" />
                            </div>
                        </>
                    </DialogContent>
                    {localStorage.getItem('role') === "porteur" &&
                        <DialogActions style={{ padding: 20, justifyContent: 'end' }}>
                            <MuiButton
                                color="primary"
                                variant="outlined"
                                style={{ textTransform: 'none' }}
                                onClick={() => {
                                    this.setState({ openModalDevisSign: true, openModalRaison: false })
                                }}
                            >
                                Revenir au devis
                            </MuiButton>
                            <MuiButton
                                onClick={() => {
                                    this.refusOffre()
                                    this.addNewMessage("Raison de refus: " + this.state.refusRaison)
                                    this.setState({ openModalRaison: false })
                                }}
                                variant="contained"
                                color="primary"
                                style={{ textTransform: 'none' }}
                            >
                                Valider et envoyer
                            </MuiButton>
                        </DialogActions>
                    }
                </Dialog>



                <Dialog
                    disableEnforceFocus
                    open={this.state.openModalDocumentSign}
                    aria-labelledby="form-dialog-title"
                    fullWidth={true}
                    maxWidth="lg"
                    onClose={(e) => {
                        this.setState({ openModalDocumentSign: false })
                    }}
                >
                    <DialogTitle disableTypography id="form-dialog-title" style={{ paddingBottom: 1 }}>
                        <div className="d-flex align-items-center" style={{ gap: 10 }}>
                            <SearchIcon style={{ color: '#1565c0' }} fontSize="small" />
                            <Typography style={{ color: '#1565C0', fontWeight: 600 }}>{"On récapitule"} </Typography>
                        </div>
                        <IconButton
                            aria-label="close"
                            style={{
                                position: 'absolute',
                                right: 5,
                                top: 5,
                                color: '#c0c0c0'
                            }}
                            onClick={() => {
                                this.setState({ openModalDocumentSign: false })
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                        <hr style={{ color: "#1565C0" }} />
                    </DialogTitle>
                    <DialogContent style={{ overflowY: "inherit" }}>
                        <>
                            <MuiBackdrop open={this.state.modalLoading} text={"Chargement..."} />
                            <div className="p-2">
                                <div style={{ minHeight: 1000 }} id="pdf-content-nda" ref={this.containerNdaRef}>

                                    <div className="d-flex justify-content-center">
                                        <span style={{ fontWeight: 700, fontSize: 28, fontFamily: 'Roboto sans-serif' }}>Accord de confidentialité (NDA)</span>
                                    </div>

                                    <div className="d-flex flex-column">
                                        <span style={{ marginTop: 30 }}>Entre :</span>
                                        <span style={{ marginTop: 20 }}>{this.state.prenomFreelancer + " " + this.state.nomFreelancer}, exerçant la profession de "{this.state.jobFreelancer}", résidant à {this.state.villeFreelancer + " " + this.state.codepFreelancer + " " + this.state.adresseFreelancer + ", " + this.state.paysFreelancer}, {this.state.userType === "Une personne morale" ? "immatriculé sous le numéro de sécurité sociale ou numéro d'identification professionnel " + ((this.state.userFreelancerEmploye && this.state.userFreelancerEmploye.siret) ? this.state.userFreelancerEmploye.siret : "num_siret_non-definit") : ""}, ci-après dénommé "Le freelance".</span>
                                        <span style={{ marginTop: 20 }}>Et:</span>
                                        <span style={{ marginTop: 20 }}>{this.state.prenomPorteur + " " + this.state.nomPorteur}, exerçant la profession de "{this.state.jobPorteur}", résidant à {this.state.villePorteur + " " + this.state.codepPorteur + " " + this.state.adressePorteur + ", " + this.state.paysPorteur}, immatriculé sous le numéro de sécurité sociale ou numéro d'identification professionnel {this.state.numSecSocial}, ci-après dénommé "Le porteur de projet",</span>

                                        <span style={{ marginTop: 20 }}>Préambule :</span>
                                        <span style={{ marginTop: 20 }}>Le porteur de projet envisage de divulguer au freelance certaines informations confidentielles dans le cadre de {this.state.mission_description}. Les parties souhaitent protéger la confidentialité de ces informations.</span>
                                        <span style={{ marginTop: 20 }}>Il a été convenu ce qui suit :</span>
                                        <span style={{ marginTop: 20, fontWeight: 700 }}>Article 1 - Définition des Informations Confidentielles</span>
                                        <span style={{ marginTop: 2 }}>Aux termes du présent accord, le terme "Informations Confidentielles" comprend toutes les données, informations, documents, logiciels, prototypes, détails de produits ou services, données financières, ou tout autre matériel ou information divulgués par le porteur de projet au freelance, quelle que soit la forme ou le support.</span>
                                        <span style={{ marginTop: 20, fontWeight: 700 }}>Article 2 - Obligation de Confidentialité</span>
                                        <span style={{ marginTop: 2 }}>Le freelance s'engage à :</span>

                                        <ul>
                                            <li>Ne pas utiliser les Informations Confidentielles autrement que pour les besoins de la mission décrite dans le préambule.</li>
                                            <li>Garder les Informations Confidentielles strictement confidentielles et ne les divulguer à aucun tiers sans l'accord écrit préalable du porteur de projet.</li>
                                            <li>Prendre toutes les mesures nécessaires pour protéger la confidentialité des Informations Confidentielles.</li>

                                        </ul>

                                        <span style={{ marginTop: 20, fontWeight: 700 }}>Article 3 - Durée</span>
                                        <span style={{ marginTop: 2 }}>L'obligation de confidentialité du porteur de projet demeurera en vigueur pendant une durée de MISSION_DURATION à compter de la date de signature du présent accord.</span>

                                        <span style={{ marginTop: 80, fontWeight: 700 }}>Article 4 - Restitution des Informations</span>
                                        <span style={{ marginTop: 2 }}>À la demande du porteur de projet, le freelance s'engage à restituer ou à détruire toutes les Informations Confidentielles qui lui ont été fournies.</span>

                                        <span style={{ marginTop: 20, fontWeight: 700 }}>Article 5 - Dispositions Générales</span>
                                        <span style={{ marginTop: 2 }}>Ce document contient l'intégralité de l'accord entre les parties concernant l'objet de la confidentialité et remplace tous les accords précédents, qu'ils soient écrits ou oraux.</span>

                                        <span style={{ marginTop: 20, fontWeight: 700 }}>Article 6 - Droit Applicable et Juridiction</span>
                                        <span style={{ marginTop: 2 }}>Le présent accord est régi par le droit {this.state.paysFreelancer}. Tout litige relatif à son interprétation et/ou à son exécution relèvera des tribunaux compétents de {this.state.villeFreelancer}.</span>

                                        <span style={{ marginTop: 20 }}>Fait à {this.state.adresseFreelancer} {this.state.villeFreelancer} {this.state.paysFreelancer}, le {moment().format("D MMMM YYYY")}.</span>

                                    </div>


                                    <div className="d-flex justify-content-between p-4">
                                        <span style={{ fontSize: 18 }}>Pour le freelance</span>
                                        <span style={{ fontSize: 18 }}>Pour le porteur de projet</span>
                                    </div>

                                </div>
                            </div>
                            {localStorage.getItem('role') === "freelance" &&
                                <div className="d-flex justify-content-start">
                                    <div style={{ marginTop: 1, textAlign: "center", width: 400 }}>
                                        <div style={{ height: 200, border: '3px solid #f0f0f0', backgroundImage: "url(/signaturebackg.png)", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
                                            {/* <div style={{ marginTop: 70 }}>
                                                        <span style={{ fontSize: 24, color: "grey", fontWeight: 600 }}>Dessinez dans la zone de dessin</span>
                                                    </div> */}
                                            <SignatureCanvas ref={(ref) => {
                                                this.sigCanvas = ref
                                            }} penColor={"#000"} canvasProps={{
                                                width: 398,
                                                height: 200,
                                                className: 'sigCanvas'
                                            }}
                                                backgroundColor="transparent"
                                            />
                                        </div>
                                    </div>
                                </div>
                            }

                            {localStorage.getItem('role') === "freelance" &&
                                <div className="d-flex justify-content-start mt-2">
                                    <span style={{ fontWeight: 600, fontSize: 14 }}>En signant, je m’engage à respecter le réglèment ci-dessus.</span>
                                </div>
                            }

                            {localStorage.getItem('role') === "porteur" &&
                                <div className="d-flex justify-content-between">
                                    {this.state.signatureB64 &&
                                        <div className="d-flex justify-content-start">
                                            <div style={{ marginTop: 1, textAlign: "center" }}>
                                                <img width={260} src={this.state.signatureB64} />
                                            </div>
                                        </div>
                                    }
                                    <div style={{ marginTop: 1, textAlign: "center", width: 400 }}>
                                        <div style={{ height: 200, border: '3px solid #f0f0f0', backgroundImage: "url(/signaturebackg.png)", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
                                            {/* <div style={{ marginTop: 70 }}>
                                                        <span style={{ fontSize: 24, color: "grey", fontWeight: 600 }}>Dessinez dans la zone de dessin</span>
                                                    </div> */}
                                            <SignatureCanvas ref={(ref) => {
                                                this.sigCanvas = ref
                                            }} penColor={"#000"} canvasProps={{
                                                width: 398,
                                                height: 200,
                                                className: 'sigCanvas'
                                            }}
                                                backgroundColor="transparent"
                                            />
                                        </div>
                                    </div>
                                </div>
                            }
                            {localStorage.getItem('role') === "porteur" &&
                                <div className="d-flex justify-content-end mt-2">
                                    <span style={{ fontWeight: 600, fontSize: 14 }}>En signant, je m’engage à respecter le réglèment ci-dessus.</span>
                                </div>
                            }
                        </>
                    </DialogContent>
                    <DialogActions style={{ padding: 20, justifyContent: 'end' }}>
                        <MuiButton
                            color="error"
                            variant="outlined"
                            style={{ textTransform: 'none', borderColor: "red", color: 'red' }}
                            onClick={() => {
                                this.setState({ openModalDocumentSign: false })
                            }}
                        >
                            Annuler
                        </MuiButton>
                        <MuiButton
                            onClick={() => {
                                setTimeout(() => {
                                    localStorage.getItem('role') === "freelance" ? this.generateDocumentNda() : this.signDocumentNda();
                                }, 20)
                            }}
                            variant="contained"
                            color="primary"
                            style={{ textTransform: 'none' }}
                        >
                            Signer et envoyer
                        </MuiButton>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={this.state.openModalDocumentPartage}
                    aria-labelledby="form-dialog-title"
                    fullWidth={true}
                    maxWidth="lg"
                    onClose={(e) => {
                        this.setState({ openModalDocumentPartage: false })
                    }}
                >
                    <DialogTitle id="form-dialog-title" style={{ paddingBottom: 1 }}>
                        <div className="d-flex align-items-center" style={{ gap: 10 }}>
                            <SearchIcon style={{ color: '#1565c0' }} fontSize="small" />
                            <Typography style={{ color: '#1565C0', fontWeight: 600 }}>{"Signature du contrat"} </Typography>
                        </div>
                        <IconButton
                            aria-label="close"
                            style={{
                                position: 'absolute',
                                right: 5,
                                top: 5,
                                color: '#c0c0c0'
                            }}
                            onClick={() => {
                                this.setState({ openModalDocumentPartage: false })
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                        <hr style={{ color: "#1565C0" }} />
                    </DialogTitle>
                    <DialogContent style={{ overflowY: "inherit" }}>
                        <>
                            <div className="p-2">
                                <div style={{ minHeight: 1000 }} id="pdf-content-partage" ref={this.containerPartageRef}>

                                    <div className="d-flex justify-content-center">
                                        <span style={{ fontWeight: 700, fontSize: 28, fontFamily: 'Roboto sans-serif', textAlign: "center" }}>Contrat de partage de revenus entre FreelanceMada et {this.state.prenomFreelancer + " " + this.state.nomFreelancer}</span>
                                    </div>

                                    <div className="d-flex flex-column">
                                        <span style={{ marginTop: 30 }}>Entre :</span>
                                        <span style={{ marginTop: 20 }}>{"FreelanceMada, une société incorporée sous les lois de Madagascar, dont le siège social est situé à Madagascar, ci-après dénomée “la plateforme”."}</span>
                                        <span style={{ marginTop: 20 }}>Et:</span>
                                        <span style={{ marginTop: 20 }}>{this.state.prenomFreelancer + " " + this.state.nomFreelancer}, résidant à {this.state.villeFreelancer + " " + this.state.codepFreelancer + " " + this.state.adresseFreelancer + ", " + this.state.paysFreelancer}, ci-après dénommé "Le freelance".</span>
                                        <span style={{ marginTop: 20 }}>Les parties conviennent de ce qui suit :</span>
                                        <span style={{ marginTop: 20, fontWeight: 700 }}>Article 1 - Objet</span>
                                        <span style={{ marginTop: 2 }}>La plateforme s’engage à financer la formation 'Apprendre Odoo pour les débutants' pour le freelance, en échange d’un pourcentage des revenus futurs générés par le freelance grâce à des missions réalisées via la plateforme.</span>
                                        <span style={{ marginTop: 20, fontWeight: 700 }}>Article 2 - Durée de l’Accord</span>
                                        <span style={{ marginTop: 2 }}>Cet accord est valable pour une période de 5 ans à compter de la date de début de la première mission du freelance post-formation.</span>
                                        <span style={{ marginTop: 20, fontWeight: 700 }}>Article 3 - Partage des revenus</span>
                                        <span style={{ marginTop: 2 }}>1 <b>.</b>Pourcentage des revenus : Le freelance s’engage à verser 20% de ses revenus générés par des missions va la Plateforme pendant la durée de cet acord.</span>
                                        <span style={{ marginTop: 2 }}>2 <b>.</b>Seuil de revenu : Les paiements ne seront dus que si les revenus mensuels du freelance dépassent 488888 MGA (100€).</span>
                                        <span style={{ marginTop: 2 }}>3 <b>.</b>Plafond de paiement : Le montant total à rembourser par le freelance ne dépassera pas 10 fois le prix de la formation.</span>


                                        <span style={{ marginTop: 20, fontWeight: 700 }}>Article 4 - Paiements</span>
                                        <span style={{ marginTop: 2 }}>Les paiements seront effectués sur une base Mensuelle et seront calculés sur les revenus vérifiés du freelance. Le freelance fournira à la plateforme tous les documents nécessaires pour vérifier les revenus.</span>

                                        <span style={{ marginTop: 20, fontWeight: 700 }}>ARTICLE 5 : Obligations du freelance</span>
                                        <span style={{ marginTop: 2 }}>Le freelance s’engage à :</span>
                                        <span style={{ marginTop: 2 }}>1 <b>.</b>Compléter la formation ou le soutien fourni par ou via la plateforme.</span>
                                        <span style={{ marginTop: 2 }}>2 <b>.</b>Rechercher activement et accepter des missions via la plateforme.</span>
                                        <span style={{ marginTop: 2 }}>3 <b>.</b>Fournir des rapports réguliers sur ses revenus et autres informations nécessaires.</span>


                                        <span style={{ marginTop: 70, fontWeight: 700 }}>ARTICLE 6 : Confidentialité</span>
                                        <span style={{ marginTop: 2 }}>Les parties s’engagent à garder confidentielles les informations reçues de l’autre partie pendant la durée de cet accord et après sa conclusion.</span>

                                        <span style={{ marginTop: 20, fontWeight: 700 }}>ARTICLE 7 : Résolution des conflits</span>
                                        <span style={{ marginTop: 2 }}>Tout différend découlant de cet accord sera résolu par médiation ou, à défaut, par arbitrage conformément aux lois de Madagascar.</span>

                                        <span style={{ marginTop: 20, fontWeight: 700 }}>ARTICLE 8 : Dispositions générales</span>
                                        <span style={{ marginTop: 2 }}>1 <b>.</b>Modifications : Toute modification de cet accord doit être écrite et signée par les deux parties.</span>
                                        <span style={{ marginTop: 2 }}>2 <b>.</b>Intégralité de l’Accord : Cet accord constitue l’entente complète entre les parties concernant son objet.</span>

                                    </div>


                                    <div className="d-flex justify-content-between p-4">
                                        <span style={{ fontSize: 18 }}>Signature de la plateforme</span>
                                        <span style={{ fontSize: 18 }}>Signature du freelance</span>
                                    </div>

                                    {this.state.signatureB64Freelance &&
                                        <div style={{ marginTop: 1, textAlign: "center" }}>
                                            <img width={260} src={this.state.signatureB64Freelance} />
                                        </div>
                                    }

                                </div>
                            </div>

                            <div className="d-flex justify-content-end">

                                <div style={{ marginTop: -150, textAlign: "center", width: 400 }}>
                                    <div style={{ height: 200, border: '3px solid #f0f0f0', backgroundImage: "url(/signaturebackg.png)", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
                                        {/* <div style={{ marginTop: 70 }}>
                                                        <span style={{ fontSize: 24, color: "grey", fontWeight: 600 }}>Dessinez dans la zone de dessin</span>
                                                    </div> */}
                                        <SignatureCanvas ref={(ref) => {
                                            this.sigCanvas = ref
                                        }} penColor={"#000"} canvasProps={{
                                            width: 398,
                                            height: 200,
                                            className: 'sigCanvas'
                                        }}
                                            backgroundColor="transparent"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-end mt-2">
                                <span style={{ fontWeight: 600, fontSize: 14 }}>En signant, je m’engage à régler la somme stipulée dans le devis dans les délais convenus.</span>
                            </div>
                        </>
                    </DialogContent>
                    <DialogActions style={{ padding: 20, justifyContent: 'end' }}>
                        <MuiButton
                            color="error"
                            variant="outlined"
                            style={{ textTransform: 'none', borderColor: "red", color: 'red' }}
                            onClick={() => {
                                this.setState({ openModalDocumentPartage: false })
                            }}
                        >
                            Annuler
                        </MuiButton>
                        <MuiButton
                            onClick={() => {
                                this.generateDocumentPartage()
                            }}
                            variant="contained"
                            color="primary"
                            style={{ textTransform: 'none' }}
                        >
                            Signer et envoyer
                        </MuiButton>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={this.state.openModalPayment}
                    aria-labelledby="form-dialog-title"
                    fullWidth={true}
                    maxWidth="lg"
                    onClose={(e) => {
                        this.setState({ openModalPayment: false })
                    }}
                >
                    <DialogTitle id="form-dialog-title" style={{ paddingBottom: 1 }}>
                        <div className="d-flex align-items-center" style={{ gap: 10 }}>
                            <img width={18} src="/moneymodal.png" />
                            <Typography style={{ color: '#1565C0', fontWeight: 600 }}>{"Paiement du premier acompte"} </Typography>
                        </div>
                        <IconButton
                            aria-label="close"
                            style={{
                                position: 'absolute',
                                right: 5,
                                top: 5,
                                color: '#c0c0c0'
                            }}
                            onClick={() => {
                                this.setState({ openModalPayment: false })
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                        <hr style={{ color: "#1565C0" }} />
                    </DialogTitle>
                    <DialogContent style={{ overflowY: "inherit" }}>
                        <>
                            <MuiBackdrop open={this.state.modalLoading} text={"Chargement..."} />
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="d-flex flex-column" style={{ gap: 5 }}>
                                        <span style={{ fontWeight: 600 }}>Adresse de facturation</span>
                                        <div className="p-3" style={{ border: "1px solid #c6c6c6", borderRadius: 8 }}>
                                            {this.state.isEditing === false ?
                                                <div className="d-flex justify-content-between">
                                                    <div className="d-flex flex-column" >
                                                        <span style={{ fontWeight: 700, color: 'grey', fontFamily: 'Montserrat' }}>{this.state.nomPorteur}</span>
                                                        <span style={{ fontWeight: 700, color: 'grey', fontFamily: 'Montserrat' }}>{this.state.adressePorteur}</span>
                                                        <div className="d-flex" style={{ gap: 5 }}>
                                                            <span style={{ fontWeight: 700, color: 'grey', fontFamily: 'Montserrat' }}>{this.state.codepPorteur}</span>
                                                            <span style={{ fontWeight: 700, color: 'grey', fontFamily: 'Montserrat' }}>{this.state.villePorteur}</span>
                                                            <span style={{ fontWeight: 700, color: 'grey', fontFamily: 'Montserrat' }}>{this.state.paysPorteur}</span>
                                                        </div>

                                                    </div>
                                                    <EditIcon onClick={(e) => { this.setState({ isEditing: true }) }} style={{ color: '#1565c0', cursor: 'pointer' }} fontSize="small" />

                                                </div>
                                                :
                                                <div className="d-flex flex-column" style={{ gap: 10 }}>
                                                    <TextField fullWidth value={this.state.nomPorteur} onChange={(e) => { this.setState({ nomPorteur: e.target.value }) }} size="small" id="outlined-basic" variant="outlined" />
                                                    <TextField fullWidth value={this.state.adressePorteur} onChange={(e) => { this.setState({ adressePorteur: e.target.value }) }} size="small" id="outlined-basic" variant="outlined" />
                                                    <div className="d-flex" style={{ gap: 5 }}>
                                                        <TextField fullWidth value={this.state.codepPorteur} onChange={(e) => { this.setState({ codepPorteur: e.target.value }) }} size="small" id="outlined-basic" variant="outlined" />
                                                        <TextField fullWidth value={this.state.villePorteur} onChange={(e) => { this.setState({ villePorteur: e.target.value }) }} size="small" id="outlined-basic" variant="outlined" />
                                                        <TextField fullWidth value={this.state.paysPorteur} onChange={(e) => { this.setState({ paysPorteur: e.target.value }) }} size="small" id="outlined-basic" variant="outlined" />
                                                    </div>
                                                    <button className="btn-valid-info" onClick={(e) => { this.setState({ isEditing: false }) }}>Valider</button>

                                                </div>


                                            }
                                        </div>
                                        <span style={{ fontWeight: 600, marginTop: 10 }}>Information paiement</span>
                                        {this.state.room &&
                                            <StripePaymentPage
                                                closeModal={(e) => {
                                                    console.log('hello payment')
                                                    this.setState({ modalLoading: false, openModalPayment: false })
                                                    this.addNewMessage('paiement effectué avec succès')
                                                    this.payedOffre()
                                                }}
                                                email={this.state.emailFreelancer}
                                                name={this.state.nomFreelancer}
                                                title={this.state.room.title}
                                                payToChild={this.state.payToChild}
                                                amount={(parseInt(this.state.room.montant) + (parseInt(this.state.room.montant) / 10) + (parseInt(this.state.room.montant) / 6.7)).toFixed(2)}
                                            />}
                                    </div>
                                </div>
                                {this.state.room &&
                                    <div className="col-md-6">
                                        <div className="d-flex flex-column" style={{ gap: 5 }}>
                                            <span style={{ fontWeight: 600 }}>Résumé</span>
                                            <div className="p-3 d-flex flex-column" style={{ border: "1px solid #c6c6c6", borderRadius: 8 }}>
                                                <span style={{ fontFamily: 'Montserrat', fontWeight: 700 }}>{this.state.mission_description}</span>
                                                <div className="d-flex align-items-center justify-content-end" style={{ gap: 10 }}>
                                                    <span style={{ fontWeight: 500, fontSize: 14 }}>{(parseInt(this.state.room.montant)).toFixed(2)} € </span>
                                                    <span style={{ fontWeight: 500, fontSize: 14 }}>{'(' + ((parseInt(this.state.room.montant)) * 4870).toFixed(2) + ' MGA)'}</span>
                                                </div>
                                            </div>

                                            <div className="p-3 d-flex flex-column mt-4" style={{ border: "1px solid #c6c6c6", borderRadius: 8 }}>
                                                <div className="d-flex justify-content-between">
                                                    <span style={{ fontWeight: 700, color: 'grey', fontFamily: 'Montserrat', fontSize: 14 }}>Frais de la plateforme (15%)</span>
                                                    <div className="d-flex align-items-center" style={{ gap: 10 }}>
                                                        <span style={{ fontWeight: 700, color: 'grey', fontFamily: 'Montserrat', fontSize: 14 }}>{(parseInt(this.state.room.montant) / 6.7).toFixed(2)} €</span>
                                                        <span style={{ fontWeight: 700, color: 'grey', fontFamily: 'Montserrat', fontSize: 14 }}>{'(' + ((parseInt(this.state.room.montant) / 6.7) * 4870).toFixed(2) + ' MGA)'}</span>
                                                    </div>
                                                </div>
                                                <div className="d-flex justify-content-between">
                                                    <span style={{ fontWeight: 700, color: 'grey', fontFamily: 'Montserrat', fontSize: 14 }}>Taxe TVA (10%)</span>
                                                    <div className="d-flex align-items-center" style={{ gap: 10 }}>
                                                        <span style={{ fontWeight: 700, color: 'grey', fontFamily: 'Montserrat', fontSize: 14 }}>{(parseInt(this.state.room.montant) / 10).toFixed(2)} €</span>
                                                        <span style={{ fontWeight: 700, color: 'grey', fontFamily: 'Montserrat', fontSize: 14 }}>{'(' + ((parseInt(this.state.room.montant) / 10).toFixed(2) * 4870) + ' MGA)'}</span>
                                                    </div>
                                                </div>
                                                <div className="d-flex justify-content-between">
                                                    <span style={{ fontWeight: 700, color: 'grey', fontFamily: 'Montserrat', fontSize: 14 }}>Total</span>
                                                    <div className="d-flex align-items-center" style={{ gap: 10 }}>
                                                        <span style={{ fontWeight: 500, fontSize: 16 }}>{(parseInt(this.state.room.montant) + (parseInt(this.state.room.montant) / 10) + (parseInt(this.state.room.montant) / 6.7)).toFixed(2)} € </span>
                                                        <span style={{ fontWeight: 500, fontSize: 16 }}>{'(' + ((parseInt(this.state.room.montant) + (parseInt(this.state.room.montant) / 10) + (parseInt(this.state.room.montant) / 6.7)) * 4870).toFixed(2) + ' MGA)'}</span>
                                                    </div>

                                                </div>

                                            </div>
                                            <span style={{ fontWeight: 600, fontSize: 13, marginTop: 20 }}>En cliquant sur “Payer”, je reconnais avoir lu et accepté les termes et conditions, ainsi que la politique de confidentialité.</span>
                                        </div>
                                    </div>
                                }

                            </div>
                        </>
                    </DialogContent>
                    <DialogActions style={{ padding: 20, justifyContent: 'end' }}>
                        <MuiButton
                            color="error"
                            variant="outlined"
                            style={{ textTransform: 'none', borderColor: "red", color: 'red' }}
                            onClick={() => {
                                this.setState({ openModalPayment: false })
                            }}
                        >
                            Annuler
                        </MuiButton>
                        <MuiButton
                            onClick={() => {
                                this.handleValueChange()
                                this.setState({ modalLoading: true })
                                setTimeout(() => {
                                    this.setState({ modalLoading: false })
                                }, 4000)
                            }}
                            variant="contained"
                            color="primary"
                            style={{ textTransform: 'none' }}
                        >
                            Payer
                        </MuiButton>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={this.state.openModalVersion}
                    aria-labelledby="form-dialog-title"
                    fullWidth={true}
                    maxWidth="sm"
                    onClose={(e) => {
                        this.setState({ openModalVersion: false })
                    }}
                >
                    <DialogTitle disableTypography id="form-dialog-title" style={{ paddingBottom: 1 }}>
                        <div className="d-flex align-items-center" style={{ gap: 10 }}>
                            <Add style={{ color: '#1565c0' }} fontSize="small" />
                            <Typography style={{ color: '#1565C0', fontWeight: 600 }}>{"Informations générales de la version"} </Typography>
                        </div>
                        <IconButton
                            aria-label="close"
                            style={{
                                position: 'absolute',
                                right: 5,
                                top: 5,
                                color: '#c0c0c0'
                            }}
                            onClick={() => {
                                this.setState({ openModalVersion: false })
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                        <hr style={{ color: "#1565C0" }} />
                    </DialogTitle>
                    <DialogContent style={{ overflowY: "inherit" }}>
                        <>
                            <div className="d-flex flex-column" style={{ gap: 10 }}>
                                <span style={{ fontWeight: 600 }}>* = Champs obligatoires</span>
                                <span style={{ color: "#616161", fontFamily: "Montserrat", fontWeight: 700 }}>Nom de la version*</span>
                                <TextField fullWidth value={this.state.versionTitle} onChange={(e) => { this.setState({ versionTitle: e.target.value }) }} size="small" id="outlined-basic" variant="outlined" />
                                <span style={{ color: "#616161", fontFamily: "Montserrat", fontWeight: 700 }}>Courte description de la version</span>
                                <TextField fullWidth value={this.state.versionDescription} onChange={(e) => { this.setState({ versionDescription: e.target.value }) }} size="small" id="outlined-basic" variant="outlined" />
                                <span style={{ color: "#616161", fontFamily: "Montserrat", fontWeight: 700 }}>Lien(s) des livrables*</span>
                                <TextField fullWidth value={this.state.versionLink} onChange={(e) => { this.setState({ versionLink: e.target.value }) }} size="small" id="outlined-basic" variant="outlined" />
                                <div className="d-flex align-items-center" style={{ gap: 10 }}>
                                    <Checkbox
                                        checked={this.state.versionFinale}
                                        onChange={this.handleChangeVersion}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                    <span className="span-footer">Cette version est une version finale</span>
                                    <LightTooltip style={{ backgroundColor: '#fff' }} title="Une version finale signifie qu’il n’y aura pas de changement majeur sur les livrables (cette option est surtout utilisée lors des envoies finaux des livrables et que les derniers bugs est étaient corrigés)">
                                        <InfoOutlinedIcon color="primary" />
                                    </LightTooltip>
                                </div>
                            </div>
                        </>
                    </DialogContent>
                    <DialogActions style={{ padding: 20, justifyContent: 'end' }}>
                        <MuiButton
                            color="error"
                            variant="outlined"
                            style={{ textTransform: 'none', borderColor: "red", color: 'red' }}
                            onClick={() => {
                                this.setState({ openModalVersion: false })
                                this.resetValueVersion()
                            }}
                        >
                            Annuler
                        </MuiButton>
                        <MuiButton
                            onClick={() => {
                                this.addVersionMsg()
                            }}
                            variant="contained"
                            color="primary"
                            disabled={this.state.versionTitle === "" || this.state.versionLink === "" ? true : false}
                            style={{ textTransform: 'none' }}
                        >
                            Envoyer la version
                        </MuiButton>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={this.state.openModalVersionValidate}
                    aria-labelledby="form-dialog-title"
                    fullWidth={true}
                    maxWidth="sm"
                    onClose={(e) => {
                        this.setState({ openModalVersionValidate: false })
                    }}
                >
                    <DialogTitle id="form-dialog-title" style={{ paddingBottom: 1 }}>
                        <div className="d-flex align-items-center" style={{ gap: 10 }}>
                            <DescriptionOutlinedIcon style={{ color: '#1565c0' }} fontSize="small" />
                            <Typography style={{ color: '#1565C0', fontWeight: 600 }}>{this.state.versionTitle} </Typography>
                        </div>
                        <IconButton
                            aria-label="close"
                            style={{
                                position: 'absolute',
                                right: 5,
                                top: 5,
                                color: '#c0c0c0'
                            }}
                            onClick={() => {
                                this.setState({ openModalVersionValidate: false })
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                        <hr style={{ color: "#1565C0" }} />
                    </DialogTitle>
                    <DialogContent style={{ overflowY: "inherit" }}>
                        <>
                            <div className="d-flex flex-column" style={{ gap: 10 }}>
                                <span style={{ color: "#616161", fontFamily: "Montserrat", fontWeight: 700 }}>{this.state.versionDescription}</span>

                                <span style={{ color: "#616161", fontFamily: "Montserrat", fontWeight: 700, marginTop: 20 }}>Lien(s) des livrables :</span>

                                <span style={{ color: "#616161", fontFamily: "Roboto", fontWeight: 700 }}>{this.state.versionLink}</span>
                                {(this.state.room && this.state.room.offre_state !== "finished") &&
                                    <div className="d-flex flex-column mt-3" style={{ gap: 10 }}>
                                        <span style={{ color: "#616161", fontFamily: "Montserrat", fontWeight: 700 }}>Commentaire</span>
                                        <TextField fullWidth error={this.state.isValidComment === true ? true : false} multiline value={this.state.versionComment} onChange={(e) => { this.setState({ versionComment: e.target.value }) }} size="small" id="outlined-basic" variant="outlined" />
                                        <span style={{ color: "#616161", fontFamily: "Montserrat", fontSize: 11, fontWeight: 600 }}>Si vous refusez la version, veuillez en décrire les raisons pour que le freelance puisse mettre à jour les livrables (à condition que cela fasse partie du devis initial que vous avez signé)</span>
                                    </div>}
                            </div>
                        </>
                    </DialogContent>
                    {(this.state.room && this.state.room.offre_state !== "finished") &&
                        <DialogActions style={{ padding: 20, justifyContent: 'end' }}>
                            <MuiButton
                                color="error"
                                variant="outlined"
                                style={{ textTransform: 'none', borderColor: "red", color: 'red' }}
                                onClick={() => {
                                    if (this.state.versionComment === "") {
                                        this.setState({ isValidComment: true })
                                    } else {
                                        this.setState({ openModalVersionValidate: false, isValidComment: false })
                                        this.addNewMessage("Refus de la version : " + this.state.versionComment)

                                        setTimeout(() => {
                                            this.resetValueVersion()
                                        }, 2400)
                                    }

                                }}
                            >
                                Refuser la version
                            </MuiButton>
                            <MuiButton
                                onClick={() => {
                                    this.addNewMessage("Version validée")
                                    if (this.state.versionComment !== "") {
                                        this.addNewMessage(this.state.versionComment)
                                    }
                                    console.log(this.state.versionFinale)
                                    if (this.state.versionFinale === true) {
                                        this.endOffre()
                                    }
                                    this.setState({ openModalVersionValidate: false })
                                }}
                                variant="contained"
                                color="primary"
                                style={{ textTransform: 'none' }}
                            >
                                Valider la version {this.state.versionTitle}
                            </MuiButton>
                        </DialogActions>
                    }
                </Dialog>

                {this.state.emailFreelancer !== "" &&
                    <Comment_Modal noteOffre={this.noteOffre} closeModalComment={(e) => { this.setState({ openCommentModal: false }) }} openCommentModal={this.state.openCommentModal} email={this.state.emailFreelancer} />
                }
                {this.state.emailPorteur !== "" &&
                    <Comment_Modal_Porteur noteOffre={this.noteOffre} closeModalComment={(e) => { this.setState({ openCommentModalPorteur: false }) }} openCommentModal={this.state.openCommentModalPorteur} email={this.state.emailPorteur} />
                }
            </div>
        )
    }
}

export default withNavigate(Chat)
