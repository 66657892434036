import {Outlet} from "react-router-dom";
import React from "react";



export default class Discuss_Main extends React.Component{


    render() {
        return(
            <Outlet/>
        )
    }


}
