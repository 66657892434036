import React, { useEffect, useRef, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import { apiNodeService } from "../../provider/apiNodeService";
import Avatar from '@mui/material/Avatar';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import moment from "moment";
import html2canvas from "html2canvas";
import pdfMake from "pdfmake";

const Cv_Profil_Home = (props) => {

    const [freelancer, setFreelancer] = React.useState(null)
    const [user, setUser] = React.useState(null)
    const [photo, setPhoto] = React.useState(null)
    const [postes, setPostes] = React.useState([]);
    const [formations, setFormations] = React.useState([]);
    const [langues, setLangues] = useState([]);

    useEffect(() => {
        if (props.email) {
            getUserByEmail()
            getUserByEmail1()
        }
    }, [props.email]);

    useEffect(() => {
        if (props.isDownwload) {
            genPdf()
        }
    }, [props.isDownwload]);

    const getUserByEmail = async () => {
        return new Promise(resolve => {
            apiNodeService.filterTable({ table: "user_build", filter: { email: props.email }, db: "FREELANCE_MAD" }).then(userRes => {
                if (userRes && userRes !== "false" && Array.isArray(userRes.data)) {
                    setUser(userRes.data[0])
                } else {
                    resolve("false")
                }
            }).catch(err => resolve("false"))
        })
    }

    const getUserByEmail1 = async () => {
        return new Promise(resolve => {
            apiNodeService.filterTable({ table: "employe_odoo", filter: { email: props.email }, db: "FREELANCE_MAD" }).then(userRes => {
                if (userRes && userRes !== "false" && Array.isArray(userRes.data)) {
                    setFreelancer(userRes.data[0])
                    setPhoto(userRes.data[0].photo ? userRes.data[0].photo : null)
                    if (userRes.data[0].postes) {
                        const sortedData = userRes.data[0].postes.sort(sortByDate);
                        setPostes(sortedData)
                    }
                    if (userRes.data[0].formations) {
                        const sortedData = userRes.data[0].formations.sort(sortByDate);
                        setFormations(sortedData)
                    }
                    setLangues(userRes.data[0].langues ? userRes.data[0].langues : [])
                } else {
                    resolve("false")
                }
            }).catch(err => resolve("false"))
        })
    }

    const sortByDate = (a, b) => {
        // First, check if dateFin is equal or empty
        if (a.date_fin === b.date_fin || !a.date_fin || !b.date_fin) {
            // If so, sort by dateDeb
            return new Date(b.date_debut) - new Date(a.date_debut);
        } else {
            // Otherwise, sort by dateFin
            return new Date(b.date_fin) - new Date(a.date_fin);
        }
    }

    const getDateRange = (startDate, endDate, checked) => {
        let endDateFormatted;

        if (checked) {
            endDateFormatted = "aujourd'hui";
        } else {
            endDateFormatted = moment(endDate).format('MMMM YYYY');
        }

        const startDateFormatted = formatDate(startDate);

        return `${startDateFormatted} - ${endDateFormatted}`;
    }

    const formatDate = (date) => {
        return moment(date).format('MMMM YYYY');
    }

    const genPdf = () => {
        //get table html
        const pdfTable = document.getElementById("divToPrint");
        html2canvas(pdfTable).then(function (canvas) {
            const imgObj = {
                image: canvas.toDataURL(),
                width: 1000,
            };
            const documentDefinition = {
                content: [imgObj],
                defaultStyle: {
                    font: "NimbusSans"
                },
                pageSize: {
                    width: 990, // A4 width in pixels (595pt at 72 DPI)
                    height: 595.28 + 395,
                },
                pageOrientation: "landscape",
                pageMargins: [0, 0, 20, 0]
            };
            const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
            pdfDocGenerator.download();
        });
    };

    return (
        <div>
            {(user && freelancer) &&
                <div style={{ position: 'absolute', left: '-9999px', top: '-9999px' }}>
                    <div className="d-flex" id="divToPrint" style={{ height: 1200 }}>
                        <div className="py-3 cv-personnel-section" style={{width:'35%'}}>
                            <div className="d-flex flex-column px-3">
                                {photo ?
                                    <Avatar
                                        alt="Remy Sharp"
                                        sx={{ width: 135, height: 135, }}
                                        className="align-self-center mt-2"
                                        src={photo.prefix + photo.data}
                                    />
                                    : <Avatar sx={{ width: 135, height: 135,fontSize:32,fontWeight:700 }} className="align-self-center mt-2">
                                       <span style={{marginTop:-20,textTransform:"uppercase"}} >{user.first_name[0]+user.last_name[0]}</span> 
                                    </Avatar>
                                }
                                <span className="span-cv-name mt-3">{user.first_name + " " + user.last_name}</span>
                                <span className="text-uppercase span-cv-job">{user.poste_actuel ? user.poste_actuel : ""}</span>
                                <div className="separator-cv mt-4 mb-2" />
                                <span className="span-cv-about text-uppercase mt-2">À propos</span>
                                <span className="span-cv-about-data mt-1 mb-2">{user.description ? user.description : ""}</span>
                                <span className="span-cv-about text-uppercase mt-3">Compétences</span>
                                {freelancer.logiciel && <>
                                {freelancer.logiciel.slice(0,5).map((item, key) => (
                                    <span key={key} className="span-cv-about-data mt-1">{"- " + item}</span>
                                ))
                                }
                                </>
                                }
                                <span className="span-cv-about text-uppercase mt-4">Contact</span>
                                <div className="d-flex align-items-center mt-2" style={{ gap: 10 }}>
                                    {/* <LocalPhoneIcon fontSize="small" style={{ color: "#fff" }} /> */}
                                    <span className="span-cv-about-data">{"Tél: "}</span>
                                    <span className="span-cv-about-data">{user.mobile ? user.mobile : ""}</span>
                                </div>
                                <div className="d-flex align-items-center flex-wrap mt-2" style={{ gap: 10 }}>
                                    {/* <MailOutlineIcon fontSize="small" style={{ color: "#fff" }} /> */}
                                    <span className="span-cv-about-data">{"Email: "}</span>
                                    <span className="span-cv-about-data">{user.email}</span>
                                </div>
                                <div className="d-flex align-items-center flex-wrap mt-2" style={{ gap: 10 }}>
                                    {/* <LocationOnIcon fontSize="small" style={{ color: "#fff" }} /> */}
                                    <span className="span-cv-about-data">{"Adresse: "}</span>
                                    <span className="span-cv-about-data">{user.ville + ", " + user.pays.label}</span>
                                </div>
                            </div>
                        </div>
                        <div className="" style={{width:"65%"}}>
                            <div className="d-flex flex-column p-3">
                                <div className="px-3 d-flex flex-column">
                                    <span className="span-cv-poste">EXPÉRIENCES PROFESSIONNELLES</span>
                                    <div className="backg-poste mt-2 mb-2" />
                                </div>
                                {postes.length > 0 &&
                                    <div>
                                        {postes.map((item, key) => (
                                            <div className='d-flex mb-1 mt-1' key={key}>
                                                <div className='d-flex flex-column px-3'>
                                                    <div className="d-flex align-items-center flex-wrap" style={{ gap: 10 }}>
                                                        <span className="title-poste-cv">{item.title}</span>
                                                        <div><strong>|</strong></div>
                                                        <span className="title-poste-cv" style={{ textTransform: "capitalize" }}>{getDateRange(item.date_debut, item.date_fin, item.checked_poste)}</span>
                                                    </div>
                                                    <span className="data-poste-cv">{item.nom_soc + ", " + item.lieu_poste}</span>
                                                    <span className="data1-poste-cv">{item.description}</span>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                }

                                <div className="px-3 d-flex flex-column">
                                    <div className="span-cv-poste" style={{ margin: 0 }}>LANGUES</div>
                                    <div className="backg-poste mt-2 mb-1" />
                                </div>
                                {langues.length > 0 &&
                                    <div>
                                        {langues.map((item, key) => (
                                            <div className='d-flex mb-1 mt-1' key={key}>
                                                <div className='d-flex flex-column px-3'>
                                                    <div className="d-flex align-items-center" style={{ gap: 10 }}>
                                                        <span className="data1-formation-cv" style={{ textTransform: "capitalize" }}>{item.langue}</span>
                                                        :
                                                        <span className="data1-formation-cv">{item.level}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                }

                                <div className="mt-1 px-3 d-flex flex-column">
                                    <label className="span-cv-poste text-uppercase mt-n1">Formations</label>
                                    <div className="backg-poste mt-2 mb-1" />
                                </div>
                                {formations.length > 0 &&
                                    <div>
                                        {formations.map((item, key) => (
                                            <div className='d-flex mb-3 mt-2' key={key}>
                                                <div className='d-flex flex-column px-3'>
                                                    <div className="d-flex align-items-center flex-wrap" style={{ gap: 10 }}>
                                                        <span className="data1-formation-cv" style={{ textTransform: "capitalize" }}>{getDateRange(item.date_debut, item.date_fin, item.checked_poste)}</span>
                                                        <div style={{ marginTop: -4 }}>|</div>
                                                        <span className="data1-formation-cv">{item.title}</span>
                                                    </div>
                                                    <span className="title-poste-cv">{item.description}</span>
                                                    <span className="data-poste-cv">{item.ecole}</span>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                }

                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}
export default Cv_Profil_Home;