let nodes =[
    {
        "value": "Designer de mode : ",
        "label": "Designer de mode : ",
        "children": [
            {
                "value": "Prêt-à-porter : ",
                "label": "Prêt-à-porter : "
            },
            {
                "value": "Haute couture : ",
                "label": "Haute couture : "
            },
            {
                "value": "Accessoires : ",
                "label": "Accessoires : "
            },
            {
                "value": "Designers de costumes : ",
                "label": "Designers de costumes : "
            },
            {
                "value": "Designers de sportswear : ",
                "label": "Designers de sportswear : "
            },
            {
                "value": "Designers de lingerie : ",
                "label": "Designers de lingerie : "
            },
            {
                "value": "Designers de maillots de bain : ",
                "label": "Designers de maillots de bain : "
            }
        ],
        "showCheckbox": false
    },
    {
        "value": "Designer de produit",
        "label": "Designer de produit",
        "children": [
            {
                "value": "Designer industriel",
                "label": "Designer industriel",
                "children": [
                    {
                        "value": "Designer de produits de consommation",
                        "label": "Designer de produits de consommation"
                    },
                    {
                        "value": "Designer d'équipements industriels : ",
                        "label": "Designer d'équipements industriels : "
                    },
                    {
                        "value": "Designer de systèmes de transport : ",
                        "label": "Designer de systèmes de transport : ",
                        "children": [
                            {
                                "value": "Designer de véhicules",
                                "label": "Designer de véhicules",
                                "children": [
                                    {
                                        "value": "Designer automobile : ",
                                        "label": "Designer automobile : "
                                    },
                                    {
                                        "value": "Designer de motos",
                                        "label": "Designer de motos"
                                    },
                                    {
                                        "value": "Designer de systèmes de mobilités urbaines ",
                                        "label": "Designer de systèmes de mobilités urbaines "
                                    },
                                    {
                                        "value": "Designer de camions",
                                        "label": "Designer de camions"
                                    },
                                    {
                                        "value": "Designer de bateaux",
                                        "label": "Designer de bateaux"
                                    },
                                    {
                                        "value": "Designer d'avions",
                                        "label": "Designer d'avions"
                                    }
                                ],
                                "showCheckbox": false
                            },
                            {
                                "value": "Designer d'intérieurs de transports publics",
                                "label": "Designer d'intérieurs de transports publics"
                            },
                            {
                                "value": "Designer d'interfaces utilisateur pour les systèmes de transport : ",
                                "label": "Designer d'interfaces utilisateur pour les systèmes de transport : "
                            },
                            {
                                "value": "Designer d'infrastructures de transport : L",
                                "label": "Designer d'infrastructures de transport : L"
                            },
                            {
                                "value": "Designer de systèmes de transport intelligents : ",
                                "label": "Designer de systèmes de transport intelligents : "
                            }
                        ],
                        "showCheckbox": false
                    },
                    {
                        "value": "Designer de meubles industriels : ",
                        "label": "Designer de meubles industriels : "
                    },
                    {
                        "value": "Designer d'emballages écologique",
                        "label": "Designer d'emballages écologique",
                        "children": [
                            {
                                "value": "Designer d'emballage de produits de consommation : ",
                                "label": "Designer d'emballage de produits de consommation : ",
                                "children": [
                                    {
                                        "value": "Les designers d'emballage  pour Aliments et boissons",
                                        "label": "Les designers d'emballage  pour Aliments et boissons"
                                    },
                                    {
                                        "value": "Les designers d'emballage  pour  Produits de beauté et de soins personnels : ",
                                        "label": "Les designers d'emballage  pour  Produits de beauté et de soins personnels : "
                                    },
                                    {
                                        "value": "Les designers d'emballage  pour  Produits ménagers : ",
                                        "label": "Les designers d'emballage  pour  Produits ménagers : "
                                    },
                                    {
                                        "value": "Les designers d'emballage de jouets et de jeux t",
                                        "label": "Les designers d'emballage de jouets et de jeux t"
                                    },
                                    {
                                        "value": "Les designers d'emballage  pour  Produits électroniques grand public : ",
                                        "label": "Les designers d'emballage  pour  Produits électroniques grand public : "
                                    },
                                    {
                                        "value": "Les designers d'emballage  pour  Produits de bureau et de papeterie : ",
                                        "label": "Les designers d'emballage  pour  Produits de bureau et de papeterie : "
                                    }
                                ],
                                "showCheckbox": false
                            },
                            {
                                "value": "Designer d'emballage de luxe : ",
                                "label": "Designer d'emballage de luxe : "
                            },
                            {
                                "value": "Designer d'emballage industriel : ",
                                "label": "Designer d'emballage industriel : "
                            },
                            {
                                "value": "Designer d'emballage écologique : ",
                                "label": "Designer d'emballage écologique : "
                            },
                            {
                                "value": "Designer d'emballage de produits électroniques : ",
                                "label": "Designer d'emballage de produits électroniques : "
                            },
                            {
                                "value": "Designer d'emballage de produits médicaux : ",
                                "label": "Designer d'emballage de produits médicaux : "
                            }
                        ],
                        "showCheckbox": false
                    },
                    {
                        "value": "Designer de systèmes d'éclairage",
                        "label": "Designer de systèmes d'éclairage"
                    }
                ],
                "showCheckbox": false
            },
            {
                "value": "Designer d'emballage : ",
                "label": "Designer d'emballage : "
            },
            {
                "value": "Designer d'expérience utilisateur (UX) : ",
                "label": "Designer d'expérience utilisateur (UX) : ",
                "children": [
                    {
                        "value": "Designer d'interface utilisateur (UI) : ",
                        "label": "Designer d'interface utilisateur (UI) : "
                    },
                    {
                        "value": "Designer de navigation ",
                        "label": "Designer de navigation "
                    },
                    {
                        "value": "Designer de contenu ",
                        "label": "Designer de contenu "
                    },
                    {
                        "value": "Designer de recherche utilisateur : ",
                        "label": "Designer de recherche utilisateur : "
                    },
                    {
                        "value": "Designer de service : ",
                        "label": "Designer de service : "
                    },
                    {
                        "value": "Designer de jeux : ",
                        "label": "Designer de jeux : "
                    },
                    {
                        "value": "Designer de réalité virtuelle (VR) et de réalité augmentée (AR) : ",
                        "label": "Designer de réalité virtuelle (VR) et de réalité augmentée (AR) : "
                    }
                ],
                "showCheckbox": false
            },
            {
                "value": "Designer de mobilier : ",
                "label": "Designer de mobilier : ",
                "children": [
                    {
                        "value": "Designer de meubles d'intérieur ",
                        "label": "Designer de meubles d'intérieur "
                    },
                    {
                        "value": "Designer de meubles d'extérieur : ",
                        "label": "Designer de meubles d'extérieur : "
                    },
                    {
                        "value": "Designer de meubles de cuisine : ",
                        "label": "Designer de meubles de cuisine : "
                    },
                    {
                        "value": "Designer de meubles de salle de bain ",
                        "label": "Designer de meubles de salle de bain "
                    },
                    {
                        "value": "Designer de meubles pour enfants : ",
                        "label": "Designer de meubles pour enfants : "
                    },
                    {
                        "value": "Designer de meubles sur mesure ",
                        "label": "Designer de meubles sur mesure "
                    }
                ],
                "showCheckbox": false
            },
            {
                "value": "Designer de jouets : ",
                "label": "Designer de jouets : "
            },
            {
                "value": "Designer de bijoux : ",
                "label": "Designer de bijoux : "
            },
            {
                "value": "Designer de produits durables : ",
                "label": "Designer de produits durables : "
            }
        ],
        "showCheckbox": false
    },
    {
        "value": "Responsable des relations publiques : ",
        "label": "Responsable des relations publiques : ",
        "children": [
            {
                "value": "Responsable des relations publiques produit : ",
                "label": "Responsable des relations publiques produit : "
            },
            {
                "value": "Responsable des relations publiques de la marque : ",
                "label": "Responsable des relations publiques de la marque : "
            },
            {
                "value": "Responsable des relations publiques événementielles : ",
                "label": "Responsable des relations publiques événementielles : "
            },
            {
                "value": "Responsable des relations publiques institutionnelles : ",
                "label": "Responsable des relations publiques institutionnelles : "
            },
            {
                "value": "Responsable des relations publiques digitales : ",
                "label": "Responsable des relations publiques digitales : "
            }
        ],
        "showCheckbox": false
    },
    {
        "value": "Directeur artistique : ",
        "label": "Directeur artistique : ",
        "children": [
            {
                "value": "Directeur artistique en mode : ",
                "label": "Directeur artistique en mode : "
            },
            {
                "value": "Directeur artistique en joaillerie : ",
                "label": "Directeur artistique en joaillerie : "
            },
            {
                "value": "Directeur artistique en parfumerie : ",
                "label": "Directeur artistique en parfumerie : "
            },
            {
                "value": "Directeur artistique en hôtellerie : ",
                "label": "Directeur artistique en hôtellerie : "
            }
        ],
        "showCheckbox": false
    },
    {
        "value": "Responsable marketing : ",
        "label": "Responsable marketing : ",
        "children": [
            {
                "value": "Responsable marketing produit : ",
                "label": "Responsable marketing produit : "
            },
            {
                "value": "Responsable marketing digital : ",
                "label": "Responsable marketing digital : ",
                "children": [
                    {
                        "value": "Responsable marketing digital de marque ",
                        "label": "Responsable marketing digital de marque "
                    },
                    {
                        "value": "Responsable marketing digital de produit : ",
                        "label": "Responsable marketing digital de produit : "
                    },
                    {
                        "value": "Responsable marketing digital de la clientèle : ",
                        "label": "Responsable marketing digital de la clientèle : "
                    },
                    {
                        "value": "Responsable marketing digital événementiel : ",
                        "label": "Responsable marketing digital événementiel : "
                    }
                ],
                "showCheckbox": false
            },
            {
                "value": "Responsable marketing événementiel : ",
                "label": "Responsable marketing événementiel : "
            },
            {
                "value": "Responsable marketing retail : ",
                "label": "Responsable marketing retail : "
            }
        ],
        "showCheckbox": false
    },
    {
        "value": "Styliste : ",
        "label": "Styliste : ",
        "children": [
            {
                "value": "Styliste de mode : ",
                "label": "Styliste de mode : "
            },
            {
                "value": "Styliste d'accessoires : ",
                "label": "Styliste d'accessoires : "
            },
            {
                "value": "Styliste de coiffure et maquillage : ",
                "label": "Styliste de coiffure et maquillage : "
            },
            {
                "value": "Styliste d'intérieur : ",
                "label": "Styliste d'intérieur : "
            }
        ],
        "showCheckbox": false
    },
    {
        "value": "Horloger : ",
        "label": "Horloger : ",
        "children": [
            {
                "value": "Designer horloger : ",
                "label": "Designer horloger : "
            },
            {
                "value": "Ingénieur horloger : ",
                "label": "Ingénieur horloger : "
            },
            {
                "value": "Horloger assembleur : ",
                "label": "Horloger assembleur : "
            },
            {
                "value": "Horloger réparateur : ",
                "label": "Horloger réparateur : "
            },
            {
                "value": "Responsable marketing horlogerie : ",
                "label": "Responsable marketing horlogerie : "
            },
            {
                "value": "Responsable des ventes horlogerie : ",
                "label": "Responsable des ventes horlogerie : "
            },
            {
                "value": "Expert horloger : ",
                "label": "Expert horloger : "
            }
        ],
        "showCheckbox": false
    },
    {
        "value": "Joaillier : ",
        "label": "Joaillier : ",
        "children": [
            {
                "value": "Joaillier haute joaillerie : ",
                "label": "Joaillier haute joaillerie : "
            },
            {
                "value": "Joaillier bijoutier : ",
                "label": "Joaillier bijoutier : "
            },
            {
                "value": "Joaillier artisan : ",
                "label": "Joaillier artisan : "
            },
            {
                "value": "Joaillier sertisseur : ",
                "label": "Joaillier sertisseur : "
            },
            {
                "value": "Joaillier lapidaire : ",
                "label": "Joaillier lapidaire : "
            },
            {
                "value": "Gemmologue : ",
                "label": "Gemmologue : "
            },
            {
                "value": "Responsable marketing joaillerie : ",
                "label": "Responsable marketing joaillerie : "
            },
            {
                "value": "Responsable des ventes joaillerie : ",
                "label": "Responsable des ventes joaillerie : "
            },
            {
                "value": "Expert joaillier : ",
                "label": "Expert joaillier : "
            }
        ],
        "showCheckbox": false
    },
    {
        "value": "Artisan d'art : ",
        "label": "Artisan d'art : ",
        "children": [
            {
                "value": "Calligraphiste",
                "label": "Calligraphiste",
                "children": [
                    {
                        "value": "Calligraphe traditionnel : ",
                        "label": "Calligraphe traditionnel : "
                    }
                ],
                "showCheckbox": false
            },
            {
                "value": "Calligraphiste ",
                "label": "Calligraphiste ",
                "children": [
                    {
                        "value": "Calligraphe contemporain : ",
                        "label": "Calligraphe contemporain : "
                    },
                    {
                        "value": "Enlumineur : ",
                        "label": "Enlumineur : "
                    },
                    {
                        "value": "Graphiste calligraphe : ",
                        "label": "Graphiste calligraphe : "
                    },
                    {
                        "value": "Scribe : ",
                        "label": "Scribe : "
                    },
                    {
                        "value": "Lettering artist : ",
                        "label": "Lettering artist : "
                    },
                    {
                        "value": "Graveur : ",
                        "label": "Graveur : "
                    }
                ],
                "showCheckbox": false
            },
            {
                "value": "Cadre ( fabricant de cadre ) / Frame Maker )",
                "label": "Cadre ( fabricant de cadre ) / Frame Maker )",
                "children": [
                    {
                        "value": "Fabricant de cadres en métal : ",
                        "label": "Fabricant de cadres en métal : "
                    },
                    {
                        "value": "Fabricant de cadres en bois : ",
                        "label": "Fabricant de cadres en bois : "
                    },
                    {
                        "value": "Fabricant de cadres en cuir : ",
                        "label": "Fabricant de cadres en cuir : "
                    },
                    {
                        "value": "Fabricant de cadres en cristal : ",
                        "label": "Fabricant de cadres en cristal : "
                    },
                    {
                        "value": "Fabricant de cadres personnalisés : ",
                        "label": "Fabricant de cadres personnalisés : "
                    },
                    {
                        "value": "Fabricant de cadres d'œuvres d'art : ",
                        "label": "Fabricant de cadres d'œuvres d'art : "
                    }
                ],
                "showCheckbox": false
            },
            {
                "value": "Céramiste : ",
                "label": "Céramiste : ",
                "children": [
                    {
                        "value": "Céramiste d'art : ",
                        "label": "Céramiste d'art : "
                    },
                    {
                        "value": "Céramiste utilitaire : ",
                        "label": "Céramiste utilitaire : "
                    },
                    {
                        "value": "Céramiste de production : ",
                        "label": "Céramiste de production : "
                    },
                    {
                        "value": "Céramiste traditionnel : ",
                        "label": "Céramiste traditionnel : "
                    },
                    {
                        "value": "Céramiste contemporain : ",
                        "label": "Céramiste contemporain : "
                    }
                ],
                "showCheckbox": false
            },
            {
                "value": "chaudronnier",
                "label": "chaudronnier"
            },
            {
                "value": "Ebéniste : ",
                "label": "Ebéniste : ",
                "children": [
                    {
                        "value": "Ébéniste de meubles d'art : ",
                        "label": "Ébéniste de meubles d'art : "
                    },
                    {
                        "value": "Ébéniste de meubles en série : ",
                        "label": "Ébéniste de meubles en série : "
                    },
                    {
                        "value": "Ébéniste de restauration de meubles : ",
                        "label": "Ébéniste de restauration de meubles : "
                    },
                    {
                        "value": "Ébéniste de mobilier contemporain : ",
                        "label": "Ébéniste de mobilier contemporain : "
                    },
                    {
                        "value": "Ébéniste de cuisine : ",
                        "label": "Ébéniste de cuisine : "
                    },
                    {
                        "value": "Ébéniste d'escaliers : ",
                        "label": "Ébéniste d'escaliers : "
                    },
                    {
                        "value": "Ébéniste de décoration intérieure : ",
                        "label": "Ébéniste de décoration intérieure : "
                    }
                ],
                "showCheckbox": false
            },
            {
                "value": "Emailleurs",
                "label": "Emailleurs",
                "children": [
                    {
                        "value": "Émailleur sur métaux précieux : ",
                        "label": "Émailleur sur métaux précieux : "
                    },
                    {
                        "value": "Émailleur sur porcelaine : .",
                        "label": "Émailleur sur porcelaine : ."
                    },
                    {
                        "value": "Émailleur sur verre : ",
                        "label": "Émailleur sur verre : "
                    },
                    {
                        "value": "Émailleur sur montres : ",
                        "label": "Émailleur sur montres : "
                    },
                    {
                        "value": "Émailleur sur métaux communs : ",
                        "label": "Émailleur sur métaux communs : "
                    },
                    {
                        "value": "Émailleur artistique : ",
                        "label": "Émailleur artistique : "
                    }
                ],
                "showCheckbox": false
            },
            {
                "value": "Maroquinier : ",
                "label": "Maroquinier : ",
                "children": [
                    {
                        "value": "Maroquinier sacs à main : ",
                        "label": "Maroquinier sacs à main : ",
                        "children": [
                            {
                                "value": "Maroquinier designer : ",
                                "label": "Maroquinier designer : "
                            },
                            {
                                "value": "Maroquinier prototypiste : ",
                                "label": "Maroquinier prototypiste : "
                            },
                            {
                                "value": "Maroquinier industriel : ",
                                "label": "Maroquinier industriel : "
                            },
                            {
                                "value": "Maroquinier artisanal : ",
                                "label": "Maroquinier artisanal : "
                            }
                        ],
                        "showCheckbox": false
                    },
                    {
                        "value": "Maroquinier chaussures : ",
                        "label": "Maroquinier chaussures : ",
                        "children": [
                            {
                                "value": "Maroquinier chaussures de luxe : ",
                                "label": "Maroquinier chaussures de luxe : "
                            },
                            {
                                "value": "Maroquinier chaussures pour hommes : ",
                                "label": "Maroquinier chaussures pour hommes : "
                            },
                            {
                                "value": "Maroquinier chaussures pour femmes : ",
                                "label": "Maroquinier chaussures pour femmes : "
                            },
                            {
                                "value": "Maroquinier chaussures sur mesure : ",
                                "label": "Maroquinier chaussures sur mesure : "
                            }
                        ],
                        "showCheckbox": false
                    },
                    {
                        "value": "Maroquinier ceintures : ",
                        "label": "Maroquinier ceintures : ",
                        "children": [
                            {
                                "value": "Maroquinier ceintures de luxe : ",
                                "label": "Maroquinier ceintures de luxe : "
                            },
                            {
                                "value": "Maroquinier ceintures pour hommes : ",
                                "label": "Maroquinier ceintures pour hommes : "
                            },
                            {
                                "value": "Maroquinier ceintures pour femmes : ",
                                "label": "Maroquinier ceintures pour femmes : "
                            },
                            {
                                "value": "Maroquinier ceintures sur mesure : ",
                                "label": "Maroquinier ceintures sur mesure : "
                            }
                        ],
                        "showCheckbox": false
                    },
                    {
                        "value": "Maroquinier articles de voyage : ",
                        "label": "Maroquinier articles de voyage : ",
                        "children": [
                            {
                                "value": "Maroquinier valises : ",
                                "label": "Maroquinier valises : "
                            },
                            {
                                "value": "Maroquinier sacs de voyage : ",
                                "label": "Maroquinier sacs de voyage : "
                            },
                            {
                                "value": "Maroquinier porte-documents : ",
                                "label": "Maroquinier porte-documents : "
                            },
                            {
                                "value": "Maroquinier accessoires de voyage : ",
                                "label": "Maroquinier accessoires de voyage : "
                            }
                        ],
                        "showCheckbox": false
                    },
                    {
                        "value": "Maroquinier accessoires : ",
                        "label": "Maroquinier accessoires : ",
                        "children": [

                            {
                                "value": "Maroquinier porte-monnaie : ",
                                "label": "Maroquinier porte-monnaie : "
                            },
                            {
                                "value": "Maroquinier étuis à cigarettes : ",
                                "label": "Maroquinier étuis à cigarettes : "
                            },
                            {
                                "value": "Maroquinier porte-clés : ",
                                "label": "Maroquinier porte-clés : "
                            }
                        ],
                        "showCheckbox": false
                    }
                ],
                "showCheckbox": false
            },
            {
                "value": "Bijoutier-joaillier : ",
                "label": "Bijoutier-joaillier : ",
                "children": [
                    {
                        "value": "Joailliers : ",
                        "label": "Joailliers : ",
                        "children": [
                            {
                                "value": "Joailliers créateurs : ",
                                "label": "Joailliers créateurs : "
                            },
                            {
                                "value": "Joailliers artisans : ",
                                "label": "Joailliers artisans : "
                            },
                            {
                                "value": "Joailliers gemmologues : ",
                                "label": "Joailliers gemmologues : "
                            },
                            {
                                "value": "Joailliers bijoutiers : ",
                                "label": "Joailliers bijoutiers : "
                            }
                        ],
                        "showCheckbox": false
                    },
                    {
                        "value": "Horlogers : ",
                        "label": "Horlogers : "
                    },
                    {
                        "value": "Gravure sur bijoux : ",
                        "label": "Gravure sur bijoux : "
                    },
                    {
                        "value": "Fabricants de bijoux fantaisie : ",
                        "label": "Fabricants de bijoux fantaisie : "
                    }
                ],
                "showCheckbox": false
            },
            {
                "value": "Tapissier ",
                "label": "Tapissier ",
                "children": [
                    {
                        "value": "Tapissier d'ameublement : ",
                        "label": "Tapissier d'ameublement : "
                    },
                    {
                        "value": "Tapissier décorateur : ",
                        "label": "Tapissier décorateur : "
                    },
                    {
                        "value": "Tapissier garnisseur ",
                        "label": "Tapissier garnisseur "
                    },
                    {
                        "value": "Tapissier sellier : ",
                        "label": "Tapissier sellier : "
                    }
                ],
                "showCheckbox": false
            },
            {
                "value": "Modiste : ",
                "label": "Modiste : ",
                "children": [
                    {
                        "value": "Modiste haute couture : ",
                        "label": "Modiste haute couture : "
                    },
                    {
                        "value": "Modiste prêt-à-porter : ",
                        "label": "Modiste prêt-à-porter : "
                    },
                    {
                        "value": "Modiste événementiel : ",
                        "label": "Modiste événementiel : "
                    },
                    {
                        "value": "Modiste chapelier : ",
                        "label": "Modiste chapelier : "
                    }
                ],
                "showCheckbox": false
            },
            {
                "value": "Verrier : ",
                "label": "Verrier : ",
                "children": [
                    {
                        "value": "Verrier d'art : ",
                        "label": "Verrier d'art : "
                    },
                    {
                        "value": "Verrier utilitaire : ",
                        "label": "Verrier utilitaire : "
                    },
                    {
                        "value": "Verrier scientifique : ",
                        "label": "Verrier scientifique : "
                    },
                    {
                        "value": "Verrier industriel : ",
                        "label": "Verrier industriel : "
                    }
                ],
                "showCheckbox": false
            }
        ],
        "showCheckbox": false
    },
    {
        "value": "Responsable des ventes : ",
        "label": "Responsable des ventes : ",
        "children": [
            {
                "value": "Responsable de boutique ",
                "label": "Responsable de boutique "
            },
            {
                "value": "Responsable régional de vente ",
                "label": "Responsable régional de vente "
            },
            {
                "value": "Responsable des ventes en ligne ",
                "label": "Responsable des ventes en ligne "
            },
            {
                "value": "Responsable des ventes internationales ",
                "label": "Responsable des ventes internationales "
            }
        ],
        "showCheckbox": false
    },
    {
        "value": "Expert en maroquinerie : ",
        "label": "Expert en maroquinerie : ",
        "children": [
            {
                "value": "Designer maroquinerie : ",
                "label": "Designer maroquinerie : "
            },

            {
                "value": "Responsable de production : ",
                "label": "Responsable de production : "
            },

            {
                "value": "Expert en cuir : ",
                "label": "Expert en cuir : "
            }
        ],
        "showCheckbox": false
    },
    {
        "value": "Parfumeur : ",
        "label": "Parfumeur : ",
        "children": [
            {
                "value": "Parfumeur-créateur : ",
                "label": "Parfumeur-créateur : "
            },
            {
                "value": "Nez : ",
                "label": "Nez : "
            },
            {
                "value": "Responsable de la création : ",
                "label": "Responsable de la création : "
            },


            {
                "value": "Responsable de la qualité : ",
                "label": "Responsable de la qualité : "
            }
        ],
        "showCheckbox": false
    },
    {
        "value": "Responsable du développement de produit : ",
        "label": "Responsable du développement de produit : ",
        "children": [
            {
                "value": "Chef de produit : ",
                "label": "Chef de produit : "
            },
            {
                "value": "Ingénieur produit : ",
                "label": "Ingénieur produit : "
            },
            {
                "value": "Responsable de la stratégie produit : ",
                "label": "Responsable de la stratégie produit : "
            },
            {
                "value": "Responsable du développement de produit durable : ",
                "label": "Responsable du développement de produit durable : "
            },
            {
                "value": "Responsable du développement de produit numérique : ",
                "label": "Responsable du développement de produit numérique : "
            }
        ],
        "showCheckbox": false
    },
    {
        "value": "Analyste de marché : ",
        "label": "Analyste de marché : ",
        "children": [
            {
                "value": "Analyste de marché des produits de luxe : ",
                "label": "Analyste de marché des produits de luxe : "
            },
            {
                "value": "Analyste de marché de la clientèle de luxe : ",
                "label": "Analyste de marché de la clientèle de luxe : "
            },
            {
                "value": "Analyste de marché de la concurrence dans le luxe : ",
                "label": "Analyste de marché de la concurrence dans le luxe : "
            },
            {
                "value": "Analyste de marché de la distribution de luxe : ",
                "label": "Analyste de marché de la distribution de luxe : "
            },
            {
                "value": "Analyste de marché des événements de luxe : ",
                "label": "Analyste de marché des événements de luxe : "
            }
        ],
        "showCheckbox": false
    },
    {
        "value": "Gestionnaire de la chaîne d'approvisionnement : ",
        "label": "Gestionnaire de la chaîne d'approvisionnement : ",
        "children": [
            {
                "value": "Responsable logistique : ",
                "label": "Responsable logistique : "
            },
            {
                "value": "Responsable des achats : ",
                "label": "Responsable des achats : "
            },
            {
                "value": "Responsable de la planification de la production : ",
                "label": "Responsable de la planification de la production : "
            },

            {
                "value": "Responsable de la gestion de stock : ",
                "label": "Responsable de la gestion de stock : "
            }
        ],
        "showCheckbox": false
    },
    {
        "value": "Responsable des ressources humaines : ",
        "label": "Responsable des ressources humaines : ",
        "children": [
            {
                "value": "Responsable du recrutement : ",
                "label": "Responsable du recrutement : "
            },
            {
                "value": "Responsable de la formation et du développement : ",
                "label": "Responsable de la formation et du développement : "
            },
            {
                "value": "Responsable de la gestion de la performance : ",
                "label": "Responsable de la gestion de la performance : "
            },
            {
                "value": "Responsable de la gestion des avantages sociaux : ",
                "label": "Responsable de la gestion des avantages sociaux : "
            },
            {
                "value": "Responsable de la gestion des relations de travail : ",
                "label": "Responsable de la gestion des relations de travail : "
            }
        ],
        "showCheckbox": false
    }
]
export default nodes
