import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 999,
        color: '#fff',
    },
}));

export default function MuiBackdrop(props) {
    const classes = useStyles();
    return (
        <div>
            <Backdrop className={classes.backdrop} open={props.open}>
                <CircularProgress color="inherit" />
                {
                    props.text && props.text !== "" &&
                        <div style={{marginLeft:15}}>
                            {
                                props.percent && props.percent !== "" &&
                                <h5 style={{color:"#fff"}}>{props.percent + " %"}</h5>
                            }
                            <h5 style={{color:"#fff"}}>{props.text}</h5>
                        </div>

                }
            </Backdrop>
        </div>
    );
}
