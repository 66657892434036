
export const menu_items = [
    // {
    //     id:1,
    //     uid:"dashboard",
    //     title: "Dashboard",
    //     icon: "bx bx-grid-alt",
    //     menu:[],
    //     role:["freelance","porteur"]
    // },
    {
        id:2,
        uid:"mission",
        title: "Missions",
        icon: "bx bx-briefcase-alt-2",
        menu:[],
        route: "build-mada-missions",
        role:["freelance","porteur"]
    },
    {
        id:3,
        uid:"recrutement",
        title: "Freelances",
        icon: "bx bx-group",
        menu:[],
        route: "build-mada-list",
        role:["porteur"]
    },
    // {
    //     id:3,
    //     uid:"porteur",
    //     title: "Porteurs de projet",
    //     icon: "bx bx-upload",
    //     menu:[],
    //     route: "build-mada-porteur",
    // },
    {
        id:4,
        uid:"whatsus",
        title: "Discussions",
        icon: "bx bx-message-detail",
        menu:[],
        role:["freelance","porteur"],
        route: "discuss/groups",
    },
    // {
    //     id:5,
    //     uid:"timesheets",
    //     title: "Emploi du temps",
    //     icon: "bx bx-time-five",
    //     menu:[],
    //     role:["freelance"]
    // }
]




