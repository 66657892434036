import React, {useEffect} from "react";
import ResponsiveModal from "react-responsive-modal";
import CanvasDraw from "../../components/Canvas/react-canvas-draw";
import {Popup} from "semantic-ui-react";
import { ReactPainter } from 'react-painter';

const pencil_colors = ["#FFFF00","#FF0000","#2ECC71","#3498DB","#fff","#000",]

const styles = {
    modal: {
        backgroundColor: "transparent",
        boxShadow: "none",
        display: "flex",
        overflow: "none",
        width: "100%",
        padding: "0",
        margin: "0",
        height: "100%",
        minWidth: "100%",
        justifyContent: "center"
    },
    overlay: {
        backgroundColor: "#1cccc",
        padding: 0,
        marginTop: 5,
        borderTopLeftRadius:20,
        borderTopRightRadius:20,
    },
    closeButton:{
        right:0,
        left:10
    },
    closeIcon: {
        fill: "#fff",
        fontWeight:700
    }
};

const ImageView = props => {

    let saveableCanvas = React.useRef()

    const [pencilColor, setPencilColor] = React.useState("#FF0000");

    useEffect( () => {

    },[])

    return (
        <ResponsiveModal
            open={props.open}
            onClose={props.setOpen}
            styles={styles}
            animationDuration={1000}
            focusTrapped={true}
            closeIconSize={25}
            showCloseIcon={true}
        >
            <div align="center" style={{marginTop:60,position:"relative"}}>
                <ReactPainter
                    width={400}
                    height={450}
                    initialColor={'transparent'}
                    initialLineWidth={2}
                    image={props.image.prefix + props.image.data}
                    render={({
                                 canvas,
                                 triggerSave,
                                 imageCanDownload,
                                 imageDownloadUrl,
                                 setColor,
                                 setLineJoin,
                                 setLineWidth,
                                 setLineCap,
                                 getCanvasProps
                             }) => (
                        <div>
                            <canvas id="canvas-annotate-image"
                                    {...getCanvasProps()}
                            />
                            <div style={{marginTop:40}}>
                                {/* <div className="ml-2 mr-2" style={{padding:13,display:"flex",justifyContent:"space-between",borderBottom:"1px solid #fff",marginTop:-10}}>
                                    <div style={{alignSelf:"center"}}>
                                        <div style={{display:"flex"}}>
                                            <div className="home-rounded-circle mr-1" style={{backgroundColor:"white"}}
                                                 onClick={() => {

                                                 }}
                                            >
                                                <div style={{alignSelf:"center",marginTop:3}}>
                                                    <i className="bx bx-eraser font-20" style={{color:"#000"}}/>
                                                </div>
                                            </div>
                                            <div className="home-rounded-circle" style={{backgroundColor:"white"}}
                                                 onClick={() => {

                                                 }}
                                            >
                                                <div style={{alignSelf:"center",marginTop:3}}>
                                                    <i className="bx bx-undo font-20" style={{color:"#000"}}/>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div style={{alignSelf:"center"}}>
                                        <div style={{display:"flex"}}>
                                            <Popup
                                                content={
                                                    <>
                                                        <div style={{display:"flex",justifyContent:"space-evenly"}}>
                                                            {
                                                                pencil_colors.map((color,k) => (
                                                                    <div className="color-rounded-circle m-1" style={{backgroundColor:color}}
                                                                         onClick={() => {
                                                                             setColor(color)
                                                                         }}
                                                                    />
                                                                ))
                                                            }
                                                        </div>
                                                    </>
                                                }
                                                on='click'
                                                position='top left'
                                                popper={{ id: 'popper-container', style: { zIndex: 2000 } }}
                                                trigger={
                                                    <div className="home-rounded-circle mr-1" style={{backgroundColor:"white"}}>
                                                        <div style={{alignSelf:"center",marginTop:3}}>
                                                            <i className="bx bx-pencil font-20" style={{color:"#000"}}/>
                                                        </div>
                                                    </div>
                                                }

                                            />
                                            <Popup
                                                content={
                                                    <>
                                                        <div style={{display:"flex",justifyContent:"space-evenly"}}>
                                                            <div className="color-rounded-circle m-1" style={{backgroundColor:"white"}}
                                                                 onClick={() => {
                                                                     setLineWidth(2)
                                                                 }}
                                                            >
                                                                <div style={{alignSelf:"center",marginTop:3}}>
                                                                    <i className="bx bx-minus" style={{color:"#000",fontSize:15}}/>
                                                                </div>
                                                            </div>
                                                            <div className="color-rounded-circle m-1" style={{backgroundColor:"white"}}
                                                                 onClick={() => {
                                                                     setLineWidth(4)
                                                                 }}
                                                            >
                                                                <div style={{alignSelf:"center",marginTop:3}}>
                                                                    <i className="bx bx-minus" style={{color:"#000",fontSize:30}}/>
                                                                </div>
                                                            </div>
                                                            <div className="color-rounded-circle m-1" style={{backgroundColor:"white"}}
                                                                 onClick={() => {
                                                                     setLineWidth(8)
                                                                 }}
                                                            >
                                                                <div style={{alignSelf:"center",marginTop:3}}>
                                                                    <i className="bx bx-minus " style={{color:"#000",fontSize:35}}/>
                                                                </div>
                                                            </div>
                                                            <div className="color-rounded-circle m-1" style={{backgroundColor:"white"}}
                                                                 onClick={() => {
                                                                     setLineWidth(12)
                                                                 }}
                                                            >
                                                                <div style={{alignSelf:"center",marginTop:3}}>
                                                                    <i className="bx bx-minus " style={{color:"#000",fontSize:35,fontWeight:700}}/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                }
                                                on='click'
                                                position='top left'
                                                popper={{ id: 'popper-container', style: { zIndex: 2000 } }}
                                                trigger={
                                                    <div className="home-rounded-circle" style={{backgroundColor:"white"}}>
                                                        <div style={{alignSelf:"center",marginTop:3}}>
                                                            <i className="bx bx-menu font-20" style={{color:"#000"}}/>
                                                        </div>
                                                    </div>
                                                }
                                            />
                                        </div>

                                    </div>
                                </div> */}
                                <div className="mt-4 ml-3 mr-3">
                                    <div style={{display:"flex",justifyContent:"space-between"}}>
                                        <div>
                                            <button className="custom-rounded-btn" style={{color:"#fff",backgroundColor:"transparent"}}
                                                    onClick={() => {
                                                        props.reprendre()
                                                    }}
                                            >
                                                Choisir un autre fichier
                                            </button>
                                        </div>
                                        <div>
                                            <button className="custom-rounded-btn" style={{color:"#000",backgroundColor:"#fff"}}
                                                    onClick={() => {
                                                        triggerSave()
                                                        setTimeout(() => {
                                                            let canvas = document.getElementById("canvas-annotate-image")
                                                            let dataURL = canvas.toDataURL("image/png");
                                                            props.sendImage(dataURL)
                                                        },500)

                                                    }}
                                            >
                                                Envoyer
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    )}
                />
            </div>




        </ResponsiveModal>
    );
};

export default ImageView;

