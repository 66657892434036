const endpoint = process.env.REACT_APP_DIALOGFLOW_API

let DialogFlowService = {

    loadHeaders() {
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append("Accept", '*/*');
        return headers;
    },



    beginChat(body,webhook_id,session_id,platform){

        return fetch(endpoint + '/integrations/messenger/webhook/'+webhook_id+'/sessions/'+session_id+'?platform=' + platform, {
            method: 'POST',
            body:JSON.stringify(body),
            headers: this.loadHeaders(),
        }).then(response => response.text()).catch( error => {
            console.log(error);
        });
    }
};

export default DialogFlowService;
