import React, { useContext, useEffect, useRef, useState } from 'react';
import TextField from '@mui/material/TextField';
import "./Complete_Profil.css"
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import { Chip } from '@material-ui/core'
import Competence from '../Freelance/Competence';
import Competence_Logiciel from './Competence_Logiciel';
import MuiBackdrop from "../../components/Loading/MuiBackdrop";
import { apiNodeService } from '../../provider/apiNodeService';
import Alert from '@mui/material/Alert';
import Avatar from '@mui/material/Avatar';
import utilFunctions from '../../tools/functions';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from "moment";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import countries from "../../data/countries"
import Autocomplete from '@mui/material/Autocomplete';
import dayjs from 'dayjs';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import { useNavigate } from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import SignatureCanvas from 'react-signature-canvas';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from '@mui/styles';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import categoriesMetier from '../../data/categoriesMetier';
import categoriesLogiciel from '../../data/categoriesLogiciel';
import SearchIcon from '@mui/icons-material/Search';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import Poste_Profil from '../../components/Profil/Poste_Profil';
import Divider from '@mui/material/Divider';
import 'moment/locale/fr'; // Importer la localisation française de Moment.js
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import Formation_Profil from '../../components/Profil/Formation_Profil';
import Projet_Profil from '../../components/Profil/Projet_Profil';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import rethink from '../../controller/rethink';
import MainMenuContext from '../Main1/MainMenuContext';
import TextComponent from '../../components/Profil/TextComponent';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Cv_Profil from '../../components/Profil/Cv_Profil';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 25,
    height: 25,
    border: `2px solid ${theme.palette.background.paper}`,
}));

const useStyles = makeStyles((theme) => ({
    menuItem: {
        '&:hover': {
            backgroundColor: 'inherit', // Disable hover effect
        },
        '&.Mui-selected, &.Mui-focusVisible': {
            backgroundColor: 'transparent', // Disable the grey background when selected
        },
        transition: 'none', // Disable transition effect
        backgroundColor: 'transparent'
    },
}));

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 13,
        fontWeight: 500,
        maxWidth: 450,
    },
}));
// const onedoc = new Onedoc("ec453072-0e3b-4f45-af95-dbd30b001f02"); //replace with you API key

const Complete_Profil = () => {

    const navigate = useNavigate();
    const classes = useStyles();

    const [loading, setLoading] = React.useState(false);
    const [niveauExperience, setNiveauExperience] = React.useState('');
    const [disponibility, setDisponibility] = React.useState('');
    const [mobility, setMobility] = React.useState('');
    const [prenom, setPrenom] = React.useState('');
    const [userType, setUserType] = React.useState('');
    const [nom, setNom] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [mobile, setMobile] = React.useState('');
    const [ville, setVille] = React.useState('');
    const [description, setDescription] = React.useState('');
    const [pays, setPays] = React.useState('Madagascar');
    const [facebook, setFacebook] = React.useState('');
    const [linkedin, setLinkedin] = React.useState('');
    const [gmail, setGmail] = React.useState('');
    const [twitter, setTwitter] = React.useState('');
    const [whatsapp, setWhatsapp] = React.useState('');
    const [selectedValues, setSelectedValues] = React.useState([]);
    const [selectedValues1, setSelectedValues1] = React.useState([]);
    const [selectedValuesMetiers, setSelectedMetiers] = React.useState([]);
    const [selectedValuesLogiciel, setSelectedLogiciel] = React.useState([]);
    const [options, setOptions] = useState(categoriesLogiciel.categoriesLogiciel.map((option) => option.label));
    const [options1, setOptions1] = useState(categoriesMetier.categoriesMetier.map((option) => option.label));
    const [selectedValuesCompetences, setSelectedCompetences] = React.useState([]);
    const [alertSuccess, setAlertSuccess] = React.useState(false)
    const [alertError, setAlertError] = React.useState(false)
    const [alertText, setAlertText] = useState("");
    const [photo, setPhoto] = React.useState(null)
    const fileInputRef = useRef(null);
    const [montant, setMontant] = React.useState("")
    const [user, setUser] = React.useState(null)
    const [employe, setEmploye] = React.useState(null)
    const [naissance, setNaissance] = React.useState('')
    const [codep, setCodep] = React.useState("");
    const [adress, setAdress] = React.useState("");
    const [adressCompl, setAdressCompl] = React.useState("");

    const [checked, setChecked] = React.useState(false);
    const [openBottomSignModal, setOpenBottomSignModal] = React.useState(false);

    const [sigCanvas, setSigCanvas] = React.useState({});
    const [doc64, setDoc64] = React.useState("");
    const [isSigned, setIsSigned] = React.useState(false);
    const [documentSign, setDocumentSign] = React.useState("");
    const [editing, setEditing] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [denomination, setDenomination] = React.useState("");
    const [formJuridique, setFormJuridique] = React.useState("");
    const [siret, setSiret] = React.useState("");
    const [numTva, setNumTva] = React.useState("");
    const [dateCreationJuridique, setDateCreationJuridique] = React.useState('');
    const [adressJuridique, setAdressJuridique] = React.useState("");
    const [complAdressJuridique, setComplAdressJuridique] = React.useState("");
    const [codepJuridique, setCodepJuridique] = React.useState("");
    const [villeJuridique, setVilleJuridique] = React.useState("");
    const [paysJuridique, setPaysJuridique] = React.useState("Madagascar");
    const [paysJuridiqueP, setPaysJuridiqueP] = React.useState("");
    const [isDisabled, setIsDisabled] = useState(true);
    const [openCompetenceModal, setOpenCompetenceModal] = useState(false);
    const [openPortfolioModal, setOpenPortfolioModal] = useState(false);
    const [openPosteDialog, setOpenPosteDialog] = useState(false);
    const [postes, setPostes] = React.useState([]);
    const [projets, setProjets] = React.useState([]);
    const [formations, setFormations] = React.useState([]);
    const [isEditPoste, setIsEditPoste] = useState(false);
    const [posteUid, setPosteUid] = useState('');

    const [openFormationDialog, setOpenFormationDialog] = useState(false);
    const [openCvDialog, setOpenCvDialog] = useState(false);
    const [isEditFormation, setIsEditFormation] = useState(false);
    const [formationUid, setFormationUid] = useState('');

    const [openProjetDialog, setOpenProjetDialog] = useState(false);
    const [isEditProjet, setIsEditProjet] = useState(false);
    const [projetUid, setProjetUid] = useState('');

    const [posteNumber, setPosteNumber] = useState(2);
    const [projetNumber, setProjetNumber] = useState(2);
    const [formationNumber, setFormationNumber] = useState(2);

    const [portfolioName, setPortfolioName] = useState('');
    const [portfolioLink, setPortfolioLink] = useState('');

    const [portfolio, setPortfolio] = useState([]);
    const [comments, setComments] = React.useState([]);
    const [jobTitle, setJobTitle] = useState('');
    const [numSecSocial, setNumSecSocial] = useState('');
    const [etoileRemp, setEtoileRemp] = useState(0);
    const [missionsNumber, setMissionsNumber] = useState(0);

    const [posteActuel, setPosteActuel] = useState('');
    const [langue, setLangue] = useState('Français');
    const [langueLevel, setLangueLevel] = useState('Langue maternelle');
    const [openLangueDialog, setOpenLangueDialog] = useState(false);
    const [langues, setLangues] = useState([]);
    const [isIncomplet, setIsIncomplet] = useState(false);
    const [posteActuelPorteur, setPosteActuelPorteur] = useState('');
    const [missions, setMissions] = React.useState([]);
    const [isValueFilled, setIsValueFilled] = useState(false);

    const executeFunction = useContext(MainMenuContext);
    const [showAll, setShowAll] = useState(false);
    const [openDeletePoste, setOpenDeletePoste] = useState(false);
    const [openDeleteProjet, setOpenDeleteProjet] = useState(false);
    const [openDeleteFormation, setOpenDeleteFormation] = useState(false);
    const [userTypePorteur, setUserTypePorteur] = React.useState('');


    const toggleShow = () => {
        setShowAll(!showAll);
    };

    const handleInputChange = (event) => {
        const newValue = event.target.value;
        if (newValue.trim() !== '' && !options.includes(newValue)) {
            // Si la nouvelle valeur n'existe pas dans la liste, l'ajouter aux options
            setOptions([...options, newValue]);
        }
    };

    const handleInputChange1 = (event) => {
        const newValue = event.target.value;
        if (newValue.trim() !== '' && !options1.includes(newValue)) {
            // Si la nouvelle valeur n'existe pas dans la liste, l'ajouter aux options
            setOptions1([...options1, newValue]);
        }
    };

    const calculateDuration = (dateDebut, dateFin, checked) => {
        const startDate = moment(dateDebut);
        const endDate = checked ? moment() : moment(dateFin);

        const years = endDate.diff(startDate, 'years');
        const months = endDate.diff(startDate, 'months');
        const days = endDate.diff(startDate, 'days');

        if (years > 0) {
            return years > 1 ? `${years} ans` : `${years} an`;
        } else if (months > 0) {
            return months > 1 ? `${months} mois` : `${months} mois`;
        } else {
            return days === 0 ? "moin d'un jour" : days > 1 ? `${days} jours` : `${days} jour`;
        }
    }

    const formatDate = (date) => {
        return moment(date).format('MMMM YYYY');
    }

    const getDateRange = (startDate, endDate, checked) => {
        let endDateFormatted;

        if (checked) {
            endDateFormatted = "aujourd'hui";
        } else {
            endDateFormatted = moment(endDate).format('MMMM YYYY');
        }

        const startDateFormatted = formatDate(startDate);

        return `${startDateFormatted} - ${endDateFormatted}`;
    }

    const getDateRangeFormation = (startDate, endDate) => {
        let endDateFormatted;

        if (endDate === '') {
            endDateFormatted = "aujourd'hui";
        } else {
            endDateFormatted = moment(endDate).format('MMMM YYYY');
        }

        const startDateFormatted = formatDate(startDate);

        return `${startDateFormatted} - ${endDateFormatted}`;
    }

    const handleToggleEdit = () => {
        setEditing(!editing);
    };

    const handleTextChange = (event) => {
        setDescription(event.target.value);
        updateProfile("description", event.target.value, "user_build")
        updateProfile1("description", event.target.value, "employe_odoo")
    };

    const handleBlur = () => {
        setEditing(false);
    };

    const handleChange2 = (event) => {
        setChecked(event.target.checked);
        updateProfile1('permis', event.target.checked, "employe_odoo")

    };

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const id = searchParams.get('user');
        moment.locale('fr');
        getUserById(id)
    }, []);

    const verifEmptyValue = () => {
        if (
            (denomination === "" ||
            paysJuridique === "" ||
            formJuridique === "" ||
            adressJuridique === "" ||
            codepJuridique === "" ||
            villeJuridique === ""  ||
            siret === "" ||
            posteActuel === "" ||
            niveauExperience === "" ||
            selectedValues1.length === 0) && userType === "Une personne morale"
            || jobTitle === ""
        ) {
            setIsIncomplet(true)
            setIsValueFilled(true)
            executeFunction(true);
        } else {
            setIsIncomplet(false)
            setOpenAlert(true)
            setIsDisabled(true)
            setIsValueFilled(false)
            executeFunction(false);
        }
    }

    const verifEmptyValuePorteur = () => {
        if (posteActuelPorteur === "") {
            setIsValueFilled(true)
            executeFunction(true);
        } else {
            setOpenAlert(true)
            setIsDisabled(true)
            setIsIncomplet(false)
            setIsValueFilled(false)
            executeFunction(false);
        }
    }

    const verifEmptyValuePorteurMoral = () => {
        if (
            (denomination === "" ||
            paysJuridique === "" ||
            formJuridique === "" ||
            adressJuridique === "" ||
            codepJuridique === "" ||
            villeJuridique === ""  ||
            siret === "") && userTypePorteur === "Une personne morale"
        ) {
            setIsValueFilled(true)
            setIsIncomplet(true)
            executeFunction(true);
        } else {
            setOpenAlert(true)
            setIsDisabled(true)
            setIsIncomplet(false)
            setIsValueFilled(false)
            executeFunction(false);
        }
    }

    const verifEmptyValueEmploye = (v_denomination, v_formJuridique, v_adressJuridique, v_codepJuridique, v_villeJuridique, v_posteActuel, v_niveauExperience, v_selectedValues1, v_paysJuridique) => {
        if (
            ((v_denomination === "" || !v_denomination ||
            v_formJuridique === "" || !v_formJuridique ||
            v_adressJuridique === "" || !v_adressJuridique ||
            v_codepJuridique === "" || !v_codepJuridique ||
            v_villeJuridique === "" || !v_villeJuridique ||
            v_posteActuel === "" || !v_posteActuel ||
            v_niveauExperience === "" || !v_niveauExperience ||
            v_paysJuridique === "" || !v_paysJuridique ||
            (!v_selectedValues1 && v_selectedValues1.length === 0)) && userType === "Une personne morale")
        ) {
            setIsIncomplet(true)
            executeFunction(true);
        } else {
            setIsIncomplet(false)
            executeFunction(false);
        }
    }

    const verifEmptyValueEmployePorteur = (v_denomination, v_formJuridique, v_adressJuridique, v_codepJuridique, v_villeJuridique, v_paysJuridique) => {
        if (
            ((v_denomination === "" || !v_denomination ||
            v_formJuridique === "" || !v_formJuridique ||
            v_adressJuridique === "" || !v_adressJuridique ||
            v_codepJuridique === "" || !v_codepJuridique ||
            v_villeJuridique === "" || !v_villeJuridique ||
            v_paysJuridique === "" || !v_paysJuridique ) && userTypePorteur === "Une personne morale")
        ) {
            setIsIncomplet(true)
            executeFunction(true);
        } else {
            setIsIncomplet(false)
            executeFunction(false);
        }
    }

    const verifEmptyValueUser = (v_jobTitle) => {
        console.log(v_jobTitle)
        // if (v_jobTitle === "" || !v_jobTitle) {
        //     setIsIncomplet(true)
        //     executeFunction(true);
        // } else {
        //     setIsIncomplet(false)
        //     executeFunction(false);
        // }
    }

    const insertDoc = (doc) => {
        setLoading(true)
        let data = {
            b64: doc,
            user_id: localStorage.getItem('id')
        }
        apiNodeService.insertTableData({ db: "FREELANCE_MAD", table: "documents", data: data }).then(resAdd => {
            if (resAdd && resAdd.status === 200) {
                setLoading(false)
            } else {
                setLoading(false)
            }
        })
    }

    const handleFileChangeUpdated = async (event) => {
        const file = event.target.files[0];
        if (file) {
            let thumbnail = await utilFunctions.compressImage(file, 0.4, 0.4, 300, 300, true)
            setPhoto(thumbnail);
            updateProfile1('photo', thumbnail, "employe_odoo")
            updateProfile('photo', thumbnail, "user_build")
            localStorage.setItem("photo", thumbnail.data);
        } else {
            console.log("No file selected!");
        }
    };

    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    const getUserById = async (id) => {
        setLoading(true)
        return new Promise(resolve => {
            apiNodeService.filterTable({ table: "user_build", filter: { id: id }, db: "FREELANCE_MAD" }).then(userRes => {
                console.log(userRes.data[0])
                console.log(userRes.data[0].naissance)
                if (userRes && userRes !== "false" && Array.isArray(userRes.data)) {
                    getUserByEmail(userRes.data[0].email, userRes.data[0].last_name, userRes.data[0].first_name, userRes.data[0].id, userRes.data[0].role)
                    setUser(userRes.data[0])
                    setNom(userRes.data[0].last_name)
                    setPrenom(userRes.data[0].first_name)
                    setNaissance(dayjs(userRes.data[0].naissance))
                    setMobile(userRes.data[0].mobile)
                    setPays(userRes.data[0].pays)
                    setEmail(userRes.data[0].email)
                    getMission(userRes.data[0].email)
                    setVille(userRes.data[0].ville)
                    setCodep(userRes.data[0].codep)
                    setDescription(userRes.data[0].description ? userRes.data[0].description : "")
                    setAdress(userRes.data[0].adress)
                    setAdressCompl(userRes.data[0].adress_complet)
                    setJobTitle(userRes.data[0].job_title ? userRes.data[0].job_title : '')
                    setNumSecSocial(userRes.data[0].num_sec_social ? userRes.data[0].num_sec_social : '')
                    getMissionsNumberByEmail(userRes.data[0].email)
                    setPosteActuel(userRes.data[0].poste_actuel ? userRes.data[0].poste_actuel : '')
                    setUserType(userRes.data[0].user_type ? userRes.data[0].user_type : "Une personne physique")
                    setUserTypePorteur(userRes.data[0].user_type_porteur ? userRes.data[0].user_type_porteur : "Une personne physique")
                    setLoading(false)
                    if (localStorage.getItem('role') === "porteur") {
                        verifEmptyValueUser(userRes.data[0].poste_actuel_porteur)
                    }
                    setPosteActuelPorteur(userRes.data[0].poste_actuel_porteur ? userRes.data[0].poste_actuel_porteur : '')
                    resolve(userRes.data[0])
                    apiNodeService.filterTable({ table: "documents", filter: { user_id: localStorage.getItem('id') }, db: "FREELANCE_MAD" }).then(res => {
                        if (res && res.data.length > 0) {
                            setIsSigned(true)
                            setDocumentSign(res.data[0].b64)
                        } else {
                            let data1 = {
                                nom: userRes.data[0].first_name + " " + userRes.data[0].last_name,
                                date: moment().format("YYYY-MM-DD"),
                                adress: userRes.data[0].pays.label + ", " + userRes.data[0].ville + ", " + userRes.data[0].codep
                            }
                            let data2 = {
                                data: data1,
                                output: "pdf",
                                doc_name: "Freelance.docx"
                            }
                            // apiNodeService.generateDoc(data2).then(res => {
                            //     console.log(res)
                            //     if (res) {
                            //         setDoc64(res.data)
                            //     } else {
                            //         console.log("error")
                            //     }

                            // })
                            setLoading(false)
                        }
                    })
                } else {
                    setLoading(false)
                    resolve("false")
                }
            }).catch(err => resolve("false"))
        })
    }

    const downloadPdf = (base64String) => {
        const byteCharacters = atob(base64String);
        const byteArrays = [];
        for (let offset = 0; offset < byteCharacters.length; offset += 512) {
            const slice = byteCharacters.slice(offset, offset + 512);
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
        const blob = new Blob(byteArrays, { type: 'application/pdf' });

        // Create a data URI
        const dataUri = URL.createObjectURL(blob);

        // Create a link element
        const link = document.createElement('a');
        link.href = dataUri;
        link.download = 'example.pdf';

        // Append the link to the body
        document.body.appendChild(link);

        // Trigger a click event on the link
        link.click();

        // Remove the link from the body
        document.body.removeChild(link);
    };


    const getUserByEmail = async (email, last_name, first_name, id, role) => {
        return new Promise(resolve => {
            apiNodeService.filterTable({ table: "employe_odoo", filter: { email: email }, db: "FREELANCE_MAD" }).then(userRes => {
                console.log(userRes)
                if (userRes && userRes !== "false" && Array.isArray(userRes.data)) {
                    if (!userRes.data[0]) {
                        let data = { email: email, last_name: last_name, first_name: first_name, uid: id, role: role }
                        apiNodeService.insertTableData({ db: "FREELANCE_MAD", table: "employe_odoo", data: data }).then(resAdd => {
                            console.log(resAdd)
                            if (resAdd && resAdd.status === 200) {
                                let data = {}
                                data.id = resAdd.data.id
                                setEmploye(data)
                            } else {
                                console.log('false')
                            }
                        })
                    } else {
                        setEmploye(userRes.data[0])
                        setNiveauExperience(userRes.data[0].niveau ? userRes.data[0].niveau : '')
                        setMontant(userRes.data[0].montant ? userRes.data[0].montant : '')
                        setSelectedValues(userRes.data[0].competence ? userRes.data[0].competence : [])
                        setSelectedValues1(userRes.data[0].logiciel ? userRes.data[0].logiciel : [])
                        setDisponibility(userRes.data[0].disponibility ? userRes.data[0].disponibility : '')
                        setChecked(userRes.data[0].permis ? userRes.data[0].permis : false)
                        setMobility(userRes.data[0].mobility ? userRes.data[0].mobility : '')
                        setPhoto(userRes.data[0].photo ? userRes.data[0].photo : null)
                        setFacebook(userRes.data[0].facebook ? userRes.data[0].facebook : '')
                        setLinkedin(userRes.data[0].linkedin ? userRes.data[0].linkedin : '')
                        setWhatsapp(userRes.data[0].whatsapp ? userRes.data[0].whatsapp : '')
                        setTwitter(userRes.data[0].twitter ? userRes.data[0].twitter : '')
                        setGmail(userRes.data[0].gmail ? userRes.data[0].gmail : userRes.data[0].email)
                        setDenomination(userRes.data[0].denomination ? userRes.data[0].denomination : '')
                        setFormJuridique(userRes.data[0].form_juridique ? userRes.data[0].form_juridique : '')
                        setSiret(userRes.data[0].siret ? userRes.data[0].siret : '')
                        setNumTva(userRes.data[0].num_tva ? userRes.data[0].num_tva : '')
                        setDateCreationJuridique(userRes.data[0].date_creation_juridique ? dayjs(userRes.data[0].date_creation_juridique) : '')
                        setPaysJuridique(userRes.data[0].pays_juridique ? userRes.data[0].pays_juridique : '')
                        setPaysJuridiqueP(userRes.data[0].pays_juridique ? userRes.data[0].pays_juridique : '')
                        setAdressJuridique(userRes.data[0].adress_juridique ? userRes.data[0].adress_juridique : '')
                        setComplAdressJuridique(userRes.data[0].compl_adress_juridique ? userRes.data[0].compl_adress_juridique : '')
                        setCodepJuridique(userRes.data[0].codep_juridique ? userRes.data[0].codep_juridique : '')
                        setVilleJuridique(userRes.data[0].ville_juridique ? userRes.data[0].ville_juridique : '')
                        if (userRes.data[0].postes) {
                            const sortedData = userRes.data[0].postes.sort(sortByDate);
                            setPostes(sortedData)
                        }
                        if (userRes.data[0].formations) {
                            const sortedData = userRes.data[0].formations.sort(sortByDate);
                            setFormations(sortedData)
                        }
                        if (userRes.data[0].projets) {
                            const sortedData = userRes.data[0].projets.sort(sortByDate);
                            setProjets(sortedData)
                        }
                        setPortfolio(userRes.data[0].portfolio ? userRes.data[0].portfolio : [])
                        setComments(userRes.data[0].comment ? userRes.data[0].comment : [])
                        getEtoileRemp(userRes.data[0].comment)
                        setPosteActuel(userRes.data[0].poste_actuel ? userRes.data[0].poste_actuel : '')
                        setLangues(userRes.data[0].langues ? userRes.data[0].langues : [])
                        if (localStorage.getItem('role') === "freelance") {
                            verifEmptyValueEmploye(userRes.data[0].denomination, userRes.data[0].form_juridique, userRes.data[0].adress_juridique, userRes.data[0].codep_juridique, userRes.data[0].ville_juridique, userRes.data[0].poste_actuel, userRes.data[0].niveau, userRes.data[0].pays_juridique, userRes.data[0].logiciel)
                        }
                        
                        if (localStorage.getItem('role') === "porteur") {
                            verifEmptyValueEmployePorteur(userRes.data[0].denomination, userRes.data[0].form_juridique, userRes.data[0].adress_juridique, userRes.data[0].codep_juridique, userRes.data[0].ville_juridique, userRes.data[0].pays_juridique)
                        }
                    }
                } else {
                    resolve("false")
                }
            }).catch(err => resolve("false"))
        })
    }

    const sortByDate = (a, b) => {
        // First, check if dateFin is equal or empty
        if (a.date_fin === b.date_fin || !a.date_fin || !b.date_fin) {
            // If so, sort by dateDeb
            return new Date(b.date_debut) - new Date(a.date_debut);
        } else {
            // Otherwise, sort by dateFin
            return new Date(b.date_fin) - new Date(a.date_fin);
        }
    }

    const getMissionsNumberByEmail = async (email) => {
        return new Promise(resolve => {
            apiNodeService.filterTable({ table: "mission", filter: { email: email }, db: "FREELANCE_MAD" }).then(userRes => {
                console.log(userRes)
                if (userRes && userRes !== "false" && Array.isArray(userRes.data)) {
                    setMissionsNumber(userRes.data.length)
                } else {
                    resolve("false")
                }
            }).catch(err => resolve("false"))
        })
    }

    const getMission = (email) => {
        rethink.getItemByLabel("FREELANCE_MAD", "mission", { freelancer: email }).then((res) => {
            console.log(res)
            if (res) {
                let filteredData = res.filter(item => item.state === "finished")
                setMissions(filteredData)

            }
        })
    }

    const getEtoileRemp = (items) => {
        if (items && items.length > 0) {
            let i = 0;
            items.forEach((item) => {
                i += (item.satisfaction + item.communication) / 2
            })
            console.log(i)
            setEtoileRemp(i)
        }
    }

    const updateProfile = (element, value, table) => {
        let data = {}
        data[element] = value
        apiNodeService.updateTableData({ db: "FREELANCE_MAD", table: table, id: user.id, data: data }).then(resAdd => {
            console.log(resAdd)
            if (resAdd && resAdd.status === 200) {
                setIsDisabled(false)
                setLoading(false)
            } else {
                setAlertError(true)
                setLoading(false)
                setIsDisabled(false)
                setTimeout(() => {
                    setAlertError(false)
                }, 5000)
            }
        })
    }

    const updateProfile1 = (element, value, table) => {
        let data = {}
        data[element] = value
        apiNodeService.updateTableData({ db: "FREELANCE_MAD", table: table, id: employe.id, data: data }).then(resAdd => {
            console.log(resAdd)
            if (resAdd && resAdd.status === 200) {
                setLoading(false)
                setIsDisabled(false)
            } else {
                setAlertError(true)
                setIsDisabled(false)
                setLoading(false)
                setTimeout(() => {
                    setAlertError(false)
                }, 5000)
            }
        })
    }

    const handleDeleteChip = (valueToDelete) => {
        const updatedValues = selectedValues1.filter((value) => value !== valueToDelete);
        setSelectedValues1(updatedValues);
        updateProfile1('logiciel', updatedValues, "employe_odoo")

    };

    const handleDeletePortfolio = (valueToDelete) => {
        const updatedValues = portfolio.filter((value) => value !== valueToDelete);
        setPortfolio(updatedValues);
        updateProfile1('portfolio', updatedValues, "employe_odoo")

    };

    const handleDeleteLangue = (valueToDelete) => {
        const updatedValues = langues.filter((value) => value !== valueToDelete);
        setLangues(updatedValues);
        updateProfile1('langues', updatedValues, "employe_odoo")

    };

    const addToCompetences = (array2) => {
        const newArray1 = [...selectedValues1];
        array2.forEach(item => {
            if (!newArray1.includes(item)) {
                newArray1.push(item);
            }
        });
        setSelectedValues1(newArray1)
        updateProfile1('logiciel', newArray1, "employe_odoo")
        setOpenCompetenceModal(false)
    };

    const handleUpdateUid = () => {
        console.log('hello prent child')
    }

    const handleUpdateUser = () => {
        getUserById(localStorage.getItem('id'))
    }

    const addPortfolio = (data) => {
        setOpenPortfolioModal(false)
        updateProfile1("portfolio", data, "employe_odoo")
        setPortfolioLink('')
        setPortfolioName('')
    }

    const addLangue = (data) => {
        setOpenLangueDialog(false)
        updateProfile1("langues", data, "employe_odoo")
        setLangue('')
        setLangueLevel('')
    }

    const deletePoste = () => {
        setLoading(true)
        setOpenDeletePoste(false)
        const data = postes.filter(item => item.uid !== posteUid);
        if (data) {
            updateProfile1("postes", data, "employe_odoo")
            setPostes(data)
        }
        setLoading(false)
    }

    const deleteFormation = () => {
        setLoading(true)
        setOpenDeleteFormation(false)
        const data = formations.filter(item => item.uid !== formationUid);
        if (data) {
            updateProfile1("formations", data, "employe_odoo")
            setFormations(data)
        }
        setLoading(false)
    }

    const deleteProjet = () => {
        setLoading(true)
        setOpenDeleteProjet(false)
        const data = projets.filter(item => item.uid !== projetUid);
        if (data) {
            updateProfile1("projets", data, "employe_odoo")
            setProjets(data)
        }
        setLoading(false)
    }

    const createCv = () => {
        setOpenCvDialog(true)
    }

    return (
        <>
            <MuiBackdrop open={loading} text={"Chargement..."} />
            {alertSuccess &&
                <Alert style={{ marginTop: 35 }} variant="filled" severity="success">
                    {alertText}
                </Alert>}
            {alertError &&
                <Alert style={{ marginTop: 35 }} variant="filled" severity="error">
                    Erreur, Veuillez réessayer plus tard !
                </Alert>}
            <div className="complete-profil-mada p-2">
                {user &&
                    <>
                        <div className='header-profile' style={{ marginTop: 40 }}>
                            <div className='d-flex justify-content-between align-items-center'>
                                <span>Mon profil</span>
                            </div>

                        </div>
                        <div className="body-complete">
                            <div className="row">
                                <div className="col-md-4 details-section">
                                    <div className='d-flex flex-column mt-3 justify-content-center'>
                                        <div className="d-flex flex-column align-items-center">
                                            <Badge
                                                className="mt-4"
                                                overlap="circular"
                                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                badgeContent={
                                                    <div className="update-avatar">
                                                        <SmallAvatar onClick={handleButtonClick} style={{ backgroundColor: "#fff", border: "1px solid grey", cursor: 'pointer' }} alt="Remy Sharp" src="/updateprofile.svg" />
                                                    </div>
                                                }
                                            >
                                                {photo ?
                                                    <Avatar
                                                        alt="Remy Sharp"
                                                        className="avatar-profil"
                                                        src={photo.prefix + photo.data}
                                                    />
                                                    : <Avatar className="avatar-profil">
                                                        {localStorage.getItem('first_name')[0]}
                                                    </Avatar>
                                                }
                                            </Badge>
                                            <span className='span-avatar mt-3'>{prenom} {nom}</span>
                                            {/* <span style={{ fontFamily: 'Montserrat', color: "grey", fontWeight: 700 }} className='mt-1'>{user.job_title ? user.job_title : ''}</span> */}
                                            {localStorage.getItem('role') === 'porteur' &&
                                            <>
                                            {(userTypePorteur !== "Une personne morale") ?
                                             <span style={{ fontFamily: 'Montserrat', color: "grey", fontWeight: 700 }} className='mt-1'>{posteActuelPorteur ? posteActuelPorteur : ''}</span>
                                             :
                                                <div className='d-flex align-items-center mt-3' style={{gap:5}}>
                                                <Button onClick={(e) => { 
                                                    window.open('https://www.pappers.fr/entreprise/'+ denomination + siret.slice(0, 9), '_blank');
                                                }} className='btn-suivre'
                                                disabled={siret === ""}
                                                variant="outlined" endIcon={<OpenInNewIcon />}>
                                                    En savoir plus
                                                </Button>
                                                <LightTooltip style={{ backgroundColor: '#fff' }} title="Si ce bouton ne fonctionne pas, c’est qu’il y a peut être une erreur dans la dénomination sociale ou le SIRET de votre société. Si tout vous semble correct, n’hésitez pas à contacter le support.">
                                                    <InfoOutlinedIcon className="mt-1" style={{ cursor: 'pointer' }} color="primary" />
                                                </LightTooltip>
                                            </div>
                                            }
                                            </>
                                            }
                                            {localStorage.getItem('role') === 'freelance' && <span style={{ fontFamily: 'Montserrat', color: "grey", fontWeight: 700 }} className='mt-1'>{posteActuel ? posteActuel : ''}</span>}

                                            {/* <div className='d-flex align-items-center mt-2' style={{ gap: 5 }}>
                                                <img alt="star" src='/star.png' />
                                                <span style={{ fontFamily: 'Montserrat' }}>4.7/5 (12 avis)</span>
                                            </div> */}
                                            {/* <Button className='btn-suivre mt-4' variant="contained" startIcon={<AddIcon />}>
                                            Suivre
                                        </Button> */}

                                        </div>
                                        <input
                                            type="file"
                                            ref={fileInputRef}
                                            style={{ display: 'none' }}
                                            onChange={handleFileChangeUpdated}
                                        />
                                    </div>
                                    <div className='mt-4 mb-'>
                                        {editing ? (
                                            <div className='d-flex flex-column'>
                                                <span className='span-avatar mb-2' style={{ textAlign: 'left' }}>À propos</span>

                                                <TextField
                                                    multiline
                                                    size='small'
                                                    value={description}
                                                    onChange={handleTextChange}
                                                    onBlur={handleBlur}
                                                    autoFocus
                                                />
                                            </div>
                                        ) : (
                                            <div className='d-flex flex-column'>
                                                <div className='d-flex justify-content-between align-items-center'>
                                                    <span className='span-avatar'>À propos</span>
                                                    <IconButton onClick={handleToggleEdit}>
                                                        <EditIcon fontSize='small' color='primary' />
                                                    </IconButton>
                                                </div>

                                                <p style={{ fontFamily: 'Montserrat' }}>{description === "" ? "Entrez une brève description de vous même et de votre façon de travailler" : description}</p>

                                            </div>
                                        )}
                                    </div>
                                    {localStorage.getItem('role') === "freelance" &&
                                        <>
                                            <div className='d-flex justify-content-between align-items-center mt-4 mb-3'>
                                                <span className='span-avatar'>Portfolio</span>
                                                <AddIcon onClick={(e) => { setOpenPortfolioModal(true) }} style={{ color: "#1565C0", cursor: "pointer" }} />
                                            </div>
                                            <div className='d-flex flex-wrap mb-3' style={{ gap: 5 }}>
                                                {portfolio.length === 0 && <span style={{ fontFamily: 'Montserrat' }}>Ajoutez un portfolio en utilisant le bouton (+)</span>}
                                                {portfolio.map((item, key) => (
                                                    <div className='d-flex justify-content-between align-items-center card-portfolio p-3' style={{ width: "90%" }} key={key}>
                                                        <div className='d-flex align-items-center' style={{ gap: 20 }}>
                                                            <CloseIcon onClick={(e) => { handleDeletePortfolio(item) }} style={{ cursor: 'pointer' }} fontSize='small' color='error' />
                                                            <span style={{ maxWidth: 200, overflow: 'hidden', textOverflow: 'ellipsis' }} className='span-portfolio'>{item.title}</span>
                                                        </div>
                                                        <KeyboardArrowRightIcon onClick={(e) => {
                                                            const newTab = window.open(item.link, '_blank');
                                                            newTab.focus();
                                                        }}
                                                            style={{ color: "#1976D2", cursor: 'pointer' }} size="small" />
                                                    </div>
                                                ))}

                                            </div>
                                        </>
                                    }

                                    {localStorage.getItem('role') === "freelance" &&
                                        <>
                                            <div className='d-flex justify-content-between align-items-center mt-4 mb-3'>
                                                <span className='span-avatar'>Compétences</span>
                                                <AddIcon onClick={(e) => { setOpenCompetenceModal(true) }} style={{ color: "#1565C0", cursor: "pointer" }} />
                                            </div>
                                            <div className='d-flex flex-wrap mb-4' style={{ gap: 5 }}>
                                                {selectedValues1.length === 0 && <span style={{ fontFamily: 'Montserrat' }}>Ajoutez des compétences en utilisant le bouton (+)</span>}
                                                {selectedValues1.map((item, key) => (
                                                    <Tooltip title={item} key={key} placement="top" arrow>
                                                        <Chip
                                                            style={{ color: '#1565C0', borderColor: '#1565C0', maxWidth: 200, overflow: 'hidden', textOverflow: 'ellipsis' }}
                                                            onDelete={() => handleDeleteChip(item)}
                                                            deleteIcon={<CancelOutlinedIcon style={{ color: 'red', width: 18 }} />}
                                                            variant="outlined"
                                                            size='small'
                                                            label={item}
                                                        />
                                                    </Tooltip>
                                                ))}

                                            </div>
                                        </>
                                    }

                                </div>

                                <div className="col-md-8" style={{ backgroundColor: "#F8F8F8" }}>
                                    <div className="d-flex flex-column">
                                        {(isIncomplet === true && localStorage.getItem('role') === 'freelance') &&
                                            <Alert
                                                iconMapping={{
                                                    info: <InfoOutlinedIcon style={{ color: "#18328F" }} />,
                                                }}
                                                style={{ border: "2px solid #18328F", backgroundColor: "#E9F4FF", borderRadius: 10, alignItems: 'center', marginLeft: -12 }} severity="info">Vous devez compléter votre profil avant de pouvoir postuler à une mission
                                            </Alert>
                                        }
                                        {((isIncomplet === true || (posteActuelPorteur === "" && userTypePorteur !== "Une personne morale")) && localStorage.getItem('role') === 'porteur') &&
                                            <Alert
                                                iconMapping={{
                                                    info: <InfoOutlinedIcon style={{ color: "#18328F" }} />,
                                                }}
                                                style={{ border: "2px solid #18328F", backgroundColor: "#E9F4FF", borderRadius: 10, alignItems: 'center', marginLeft: -12 }} severity="info">Vous devez compléter votre profil avant de pouvoir déposer une mission
                                            </Alert>
                                        }
                                        <span className='span-info-perso uppercase-text'>ACTIVITÉ</span>
                                        <div className='row'>
                                            {localStorage.getItem("role") === "freelance" &&
                                                <div className='col-md-6 p-3'>
                                                    <div className='card-detail-profil p-2'>
                                                        <div className='d-flex flex-column'>
                                                            <span className='span-detail-profil' style={{ fontSize: 24 }}>{missions.length}</span>
                                                            <span style={{ fontSize: 20 }}>Missions réalisées</span>
                                                            <div className='d-flex' style={{ gap: 10 }}>
                                                                <img width={12} src='/flechgreen.svg' />
                                                                <span style={{ fontSize: 14 }}>dont {missions.length} le dernier mois</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {localStorage.getItem("role") === "porteur" &&
                                                <div className='col-md-6 p-3'>
                                                    <div className='card-detail-profil p-2'>
                                                        <div className='d-flex flex-column'>
                                                            <span className='span-detail-profil' style={{ fontSize: 24 }}>{missionsNumber}</span>
                                                            <span style={{ fontSize: 20 }}>Missions portées</span>
                                                            <div className='d-flex' style={{ gap: 10 }}>
                                                                <img width={12} src='/flechgreen.svg' />
                                                                <span style={{ fontSize: 14 }}>dont {missionsNumber} le dernier mois</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>}
                                            <div className='col-md-6 p-3'>
                                                <div className='card-detail-profil p-2'>
                                                    <div className='d-flex flex-column'>
                                                        <span className='span-detail-profil' style={{ fontSize: 24 }}>{etoileRemp}</span>
                                                        <span style={{ fontSize: 20 }}>Étoiles remportées</span>
                                                        <div className='d-flex' style={{ gap: 10 }}>
                                                            <img width={12} src='/flechgreen.svg' />
                                                            <span style={{ fontSize: 14 }}>dont {etoileRemp} le dernier mois</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <span className='span-info-perso uppercase-text mt-3'>{userTypePorteur !== "Une personne morale" ? "Mes informations personnelles" : "Mes informations PROFESSIONNELLES"}</span>
                                        <div className='p-2' style={{ backgroundColor: '#fff' }}>
                                            
                                            <div className='d-flex align-items-center' style={{gap:15}}>
                                            <span className='mt-1' style={{ color: 'grey', fontWeight: 700 }}>* = Champs obligatoires</span>
                                            {/* { localStorage.getItem('role') === 'porteur' &&
                                            <LightTooltip style={{ backgroundColor: '#fff' }} title="Si vous souhaitez vous enregistrer en tant que société, veuillez choisir 'Une personne morale', sinon, si vous souhaitez vous inscrire en tant que personne sans société, veuillez choisir 'Une personne physique'">
                                                    <InfoOutlinedIcon className="mt-1" style={{ cursor: 'pointer' }} color="primary" />
                                            </LightTooltip>
                                            } */}
                                            </div>
                                            
                                            {localStorage.getItem('role') === 'porteur' &&
                                                <div className='row mt-3'>
                                                    <div className='col-md-12'>
                                                        <span className="span-title">je suis*</span>
                                                        <div className='d-flex align-items-center' style={{gap:10}}>
                                                        <Select
                                                            className='mt-1'
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={userTypePorteur}
                                                            style={{ height: 40 }}
                                                            size="small"
                                                            onChange={(e) => {
                                                                setUserTypePorteur(e.target.value)
                                                                updateProfile1('user_type_porteur', e.target.value, "employe_odoo")
                                                                updateProfile("user_type_porteur", e.target.value, "user_build")
                                                            }}
                                                            fullWidth
                                                        >
                                                            <MenuItem value={"Une personne physique"}>Une personne physique</MenuItem>
                                                            <MenuItem value={"Une personne morale"}>Une personne morale</MenuItem>
                                                        </Select>
                                                        <LightTooltip style={{ backgroundColor: '#fff' }} title="Une personne morale est une entité juridique distincte de ses membres, telle qu'une entreprise ou une association, avec des droits et des responsabilités propres. En revanche, une personne physique est un individu humain, avec une identité légale et des droits personnels.">
                                                                <InfoOutlinedIcon className="mt-1" style={{ cursor: 'pointer' }} color="primary" />
                                                        </LightTooltip>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            { userTypePorteur !== "Une personne morale" &&
                                            <>
                                                <div className='row mt-3'>
                                                    <div className='col-md-6'>
                                                        <span className="span-title mb-1">Nom de famille*</span>
                                                        <TextField fullWidth size="small" id="outlined-basic" variant="outlined"
                                                            value={nom}
                                                            error={nom === "" ? true : false}
                                                            onChange={(e) => {
                                                                setNom(e.target.value)
                                                                updateProfile("last_name", e.target.value, "user_build")
                                                                updateProfile1("last_name", e.target.value, "employe_odoo")
                                                            }} />
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <span className="span-title mb-1">Prénom*</span>
                                                        <TextField fullWidth size="small" id="outlined-basic1" variant="outlined"
                                                            value={prenom}
                                                            error={prenom === "" ? true : false}
                                                            onChange={(e) => {
                                                                setPrenom(e.target.value)
                                                                updateProfile("first_name", e.target.value, "user_build")
                                                                updateProfile1("first_name", e.target.value, "employe_odoo")

                                                            }} />
                                                    </div>
                                                </div>

                                                {localStorage.getItem('role') === 'freelance' &&
                                                    <div className='row mt-2'>
                                                        <div className='col-md-12'>
                                                            <span className="span-title mb-1">Titre du job*</span>
                                                            <TextField fullWidth size="small" id="outlined-basic13" variant="outlined"
                                                                value={jobTitle}
                                                                error={jobTitle === "" ? true : false}
                                                                onChange={(e) => {
                                                                    setJobTitle(e.target.value)
                                                                    updateProfile("job_title", e.target.value, "user_build")
                                                                    updateProfile1("job_title", e.target.value, "employe_odoo")
                                                                }} />
                                                        </div>
                                                    </div>
                                                }

                                                <div className='row mt-2'>
                                                    <div className='col-md-6'>
                                                        <span className="span-title">Téléphone</span>
                                                        <TextField fullWidth className='mt-2' size="small" id="outlined-basic3" variant="outlined"
                                                            value={mobile}
                                                            placeholder='+33 6 XX XX XX XX'
                                                            onChange={(e) => {
                                                                setMobile(e.target.value)
                                                                updateProfile("mobile", e.target.value, "user_build")
                                                            }} />
                                                    </div>
                                                    <div className='col-md-6 date-section1'>
                                                        <span className="span-title">Date de naissance*</span>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <DemoContainer components={['DatePicker']}>
                                                                <DatePicker format="DD/MM/YYYY" value={dayjs(naissance)} onChange={(e) => {
                                                                    if (e) {
                                                                        setNaissance(moment(e.$d).format('YYYY-MM-DD'))
                                                                        updateProfile("naissance", moment(e.$d).format('YYYY-MM-DD'), "user_build")
                                                                    }

                                                                }} />
                                                            </DemoContainer>
                                                        </LocalizationProvider>
                                                    </div>
                                                </div>

                                                <div className='row mb-2'>
                                                    <div className='col-md-6'>
                                                        <span className="span-title mb-1">Adresse*</span>
                                                        <TextField fullWidth size="small" id="outlined-basic10" variant="outlined"
                                                            value={adress}
                                                            error={adress === "" ? true : false}
                                                            onChange={(e) => {
                                                                setAdress(e.target.value)
                                                                updateProfile("adress", e.target.value, "user_build")
                                                            }} />
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <span className="span-title mb-1">Complément d’adresse</span>
                                                        <TextField fullWidth size="small" id="outlined-basic10" variant="outlined"
                                                            value={adressCompl}
                                                            onChange={(e) => {
                                                                setAdressCompl(e.target.value)
                                                                updateProfile("adress_complet", e.target.value, "user_build")
                                                            }} />
                                                    </div>

                                                </div>

                                                <div className='row'>

                                                    <div className='col-md-6'>
                                                        <span className="span-title mb-1">Code postal*</span>
                                                        <TextField fullWidth size="small" id="outlined-basic10" variant="outlined"
                                                            value={codep}
                                                            error={codep === "" ? true : false}
                                                            onChange={(e) => {
                                                                setCodep(e.target.value)
                                                                updateProfile("codep", e.target.value, "user_build")
                                                            }} />
                                                    </div>

                                                    <div className='col-md-6'>
                                                        <span className="span-title mb-1">Ville*</span>
                                                        <TextField fullWidth size="small" id="outlined-basic11" variant="outlined"
                                                            value={ville}
                                                            error={ville === "" ? true : false}
                                                            onChange={(e) => {
                                                                setVille(e.target.value)
                                                                updateProfile("ville", e.target.value, "user_build")
                                                            }} />
                                                    </div>
                                                </div>

                                                <div className='row mt-2'>
                                                    <div className='col-md-6'>
                                                        <span className="span-title mb-1">Pays*</span>
                                                        <Autocomplete
                                                            id="country-select-demo"
                                                            options={countries.countries}
                                                            className="countries-autocomplete"
                                                            defaultValue={pays ? pays : null}
                                                            onChange={(e, selected) => {
                                                                if (selected) {
                                                                    setPays(selected.label)
                                                                    updateProfile("pays", selected, "user_build")
                                                                }
                                                            }}
                                                            autoHighlight
                                                            getOptionLabel={(option) => option.label}
                                                            renderOption={(props, option) => (
                                                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                                    <img
                                                                        loading="lazy"
                                                                        width="20"
                                                                        srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                                                        src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                                                        alt=""
                                                                    />
                                                                    {option.label}
                                                                </Box>
                                                            )}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    size="small"
                                                                    inputProps={{
                                                                        ...params.inputProps,
                                                                        autoComplete: 'new-password', // disable autocomplete and autofill
                                                                    }}
                                                                />
                                                            )}
                                                        />

                                                    </div>
                                                    {localStorage.getItem("role") === "porteur" &&
                                                        <div className='col-md-6'>
                                                            <span className="span-title mb-1">Poste actuel*</span>
                                                            <TextField fullWidth size="small" id="outlined-basic11" variant="outlined"
                                                                value={posteActuelPorteur}
                                                                error={(posteActuelPorteur === "" && isValueFilled === true) ? true : false}
                                                                placeholder='Développeur web'
                                                                onChange={(e) => {
                                                                    setPosteActuelPorteur(e.target.value)
                                                                    updateProfile("poste_actuel_porteur", e.target.value, "user_build")
                                                                }} />
                                                        </div>
                                                    }
                                                    {localStorage.getItem("role") === "freelance" &&
                                                        <div className='col-md-6'>
                                                            <span className="span-title">je suis*</span>
                                                            <div className='d-flex align-items-center' style={{gap:10}}>
                                                            <Select
                                                                className='mt-1'
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                value={userType}
                                                                style={{ height: 40 }}
                                                                size="small"
                                                                onChange={(e) => {
                                                                    setUserType(e.target.value)
                                                                    updateProfile1('user_type', e.target.value, "employe_odoo")
                                                                    updateProfile("user_type", e.target.value, "user_build")
                                                                }}
                                                                fullWidth
                                                            >
                                                                <MenuItem value={"Une personne physique"}>Une personne physique</MenuItem>
                                                                <MenuItem value={"Une personne morale"}>Une personne morale</MenuItem>
                                                            </Select>
                                                            <LightTooltip style={{ backgroundColor: '#fff' }} title="Une personne morale est une entité juridique distincte de ses membres, telle qu'une entreprise ou une association, avec des droits et des responsabilités propres. En revanche, une personne physique est un individu humain, avec une identité légale et des droits personnels.">
                                                                <InfoOutlinedIcon className="mt-1" style={{ cursor: 'pointer' }} color="primary" />
                                                        </LightTooltip>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </>
                                            }
                                        {(localStorage.getItem('role') === "porteur" && userTypePorteur === "Une personne morale") &&
                                            <>
                                                    <div className='row mt-3'>
                                                        <div className='col-md-6'>
                                                            <span className="span-title mb-1">Dénomination sociale*</span>
                                                            <TextField fullWidth size="small" id="outlined-basic" variant="outlined"
                                                                value={denomination}
                                                                error={(denomination === "" && isValueFilled === true) ? true : false}
                                                                onChange={(e) => {
                                                                    setDenomination(e.target.value)
                                                                    updateProfile1("denomination", e.target.value, "employe_odoo")
                                                                }} />
                                                        </div>
                                                        <div className='col-md-6'>
                                                            <span className="span-title mb-1">Forme juridique*</span>
                                                            <TextField fullWidth size="small" id="outlined-basic1" variant="outlined"
                                                                value={formJuridique}
                                                                error={(formJuridique === "" && isValueFilled === true) ? true : false}
                                                                onChange={(e) => {
                                                                    setFormJuridique(e.target.value)
                                                                    updateProfile1("form_juridique", e.target.value, "employe_odoo")

                                                                }} />
                                                        </div>
                                                    </div>
                                                    <div className='row mt-2'>
                                                    <div className='col-md-6'>
                                                        <span className="span-title mb-1">Nom du dirigeant*</span>
                                                        <TextField fullWidth size="small" id="outlined-basic" variant="outlined"
                                                            value={nom}
                                                            error={nom === "" ? true : false}
                                                            onChange={(e) => {
                                                                setNom(e.target.value)
                                                                updateProfile("last_name", e.target.value, "user_build")
                                                                updateProfile1("last_name", e.target.value, "employe_odoo")
                                                            }} />
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <span className="span-title mb-1">Prénom du dirigeant*</span>
                                                        <TextField fullWidth size="small" id="outlined-basic1" variant="outlined"
                                                            value={prenom}
                                                            error={prenom === "" ? true : false}
                                                            onChange={(e) => {
                                                                setPrenom(e.target.value)
                                                                updateProfile("first_name", e.target.value, "user_build")
                                                                updateProfile1("first_name", e.target.value, "employe_odoo")

                                                            }} />
                                                    </div>
                                                </div>

                                                    <div className='row mt-2'>
                                                        <div className='col-md-6'>
                                                            <span className="span-title">SIRET*</span>
                                                            <TextField fullWidth className='mt-1' size="small" id="outlined-basic3" variant="outlined"
                                                                value={siret}
                                                                error={(siret === "" && isValueFilled === true) ? true : false}
                                                                onChange={(e) => {
                                                                    setSiret(e.target.value)
                                                                    updateProfile1("siret", e.target.value, "employe_odoo")
                                                                }} />
                                                        </div>
                                                        <div className='col-md-6'>
                                                            <span className="span-title mb-1">Numéro de TVA</span>
                                                            <TextField className='mt-1' fullWidth size="small" id="outlined-basic10" variant="outlined"
                                                                value={numTva}
                                                                onChange={(e) => {
                                                                    setNumTva(e.target.value)
                                                                    updateProfile1("num_tva", e.target.value, "employe_odoo")
                                                                }} />
                                                        </div>
                                                    </div>

                                                    <div className='row mb-1'>

                                                        <div className='col-md-6 date-section1'>
                                                            <span className="span-title">Date de création*</span>
                                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                <DemoContainer components={['DatePicker']}>
                                                                    <DatePicker format="DD/MM/YYYY" value={dayjs(dateCreationJuridique)} onChange={(e) => {
                                                                        console.log(e)
                                                                        if (e) {
                                                                            setDateCreationJuridique(moment(e.$d).format('YYYY-MM-DD'))
                                                                            updateProfile1("date_creation_juridique", moment(e.$d).format('YYYY-MM-DD'), "employe_odoo")
                                                                        }

                                                                    }} />
                                                                </DemoContainer>
                                                            </LocalizationProvider>
                                                        </div>
                                                        <div className='col-md-6'>
                                                            <span className="span-title mb-1">Pays*</span>
                                                            <Autocomplete
                                                                id="country-select-demo1"
                                                                options={countries.countries}
                                                                className="countries-autocomplete mt-2"
                                                                value={paysJuridiqueP ? paysJuridiqueP : null}
                                                                onChange={(e, selected) => {
                                                                    if (selected) {
                                                                        setPaysJuridiqueP(selected)
                                                                        updateProfile1("pays_juridique", selected, "employe_odoo")
                                                                    }

                                                                }}
                                                                autoHighlight
                                                                getOptionLabel={(option) => option.label}
                                                                renderOption={(props, option) => (
                                                                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                                        <img
                                                                            loading="lazy"
                                                                            width="20"
                                                                            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                                                            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                                                            alt=""
                                                                        />
                                                                        {option.label}
                                                                    </Box>
                                                                )}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        size="small"
                                                                        inputProps={{
                                                                            ...params.inputProps,
                                                                            autoComplete: 'new-password', // disable autocomplete and autofill
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                        </div>

                                                    </div>

                                                    <div className='row'>

                                                        <div className='col-md-6'>
                                                            <span className="span-title mb-1">Adresse*</span>
                                                            <TextField fullWidth size="small" id="outlined-basic10" variant="outlined"
                                                                value={adressJuridique}
                                                                error={(adressJuridique === "" && isValueFilled === true) ? true : false}
                                                                onChange={(e) => {
                                                                    setAdressJuridique(e.target.value)
                                                                    updateProfile1("adress_juridique", e.target.value, "employe_odoo")
                                                                }} />
                                                        </div>

                                                        <div className='col-md-6'>
                                                            <span className="span-title mb-1">Complément d’adresse</span>
                                                            <TextField fullWidth size="small" id="outlined-basic11" variant="outlined"
                                                                value={complAdressJuridique}
                                                                onChange={(e) => {
                                                                    setComplAdressJuridique(e.target.value)
                                                                    updateProfile1("compl_adress_juridique", e.target.value, "employe_odoo")
                                                                }} />
                                                        </div>
                                                    </div>

                                                    <div className='row mt-2'>
                                                        <div className='col-md-6'>
                                                            <span className="span-title mb-1">Code postal*</span>
                                                            <TextField fullWidth size="small" id="outlined-basic11" variant="outlined"
                                                                value={codepJuridique}
                                                                error={(codepJuridique === "" && isValueFilled === true) ? true : false}
                                                                onChange={(e) => {
                                                                    setCodepJuridique(e.target.value)
                                                                    updateProfile1("codep_juridique", e.target.value, "employe_odoo")
                                                                }} />
                                                        </div>

                                                        <div className='col-md-6'>
                                                            <span className="span-title mb-1">Ville*</span>
                                                            <TextField fullWidth size="small" id="outlined-basic11" variant="outlined"
                                                                value={villeJuridique}
                                                                error={(villeJuridique === "" && isValueFilled === true) ? true : false}
                                                                onChange={(e) => {
                                                                    setVilleJuridique(e.target.value)
                                                                    updateProfile1("ville_juridique", e.target.value, "employe_odoo")
                                                                }} />
                                                        </div>

                                                        <div className='col-md-6'>
                                                        <span className="span-title">Téléphone</span>
                                                        <TextField fullWidth className='mt-2' size="small" id="outlined-basic3" variant="outlined"
                                                            value={mobile}
                                                            placeholder='+33 6 XX XX XX XX'
                                                            onChange={(e) => {
                                                                setMobile(e.target.value)
                                                                updateProfile("mobile", e.target.value, "user_build")
                                                            }} />
                                                    </div>
                                                    </div>
                                            </>
                                        }
                                        </div>

                                        {localStorage.getItem('role') === "freelance" &&
                                            <>

                                                <span className='span-info-perso mt-4 uppercase-text'>Mes informations professionnelles</span>
                                                <div className='p-2' style={{ backgroundColor: '#fff' }}>
                                                    <div className='row'>
                                                        <div className='col-md-6'>
                                                            <span className="span-title">Poste actuel*</span>
                                                            <TextField className='mt-1' fullWidth size="small" id="outlined-basic11" variant="outlined"
                                                                value={posteActuel}
                                                                error={(posteActuel === "" && isValueFilled === true) ? true : false}
                                                                onChange={(e) => {
                                                                    setPosteActuel(e.target.value)
                                                                    updateProfile("poste_actuel", e.target.value, "user_build")
                                                                    updateProfile1('poste_actuel', e.target.value, "employe_odoo")
                                                                }} />
                                                        </div>
                                                        <div className='col-md-6'>
                                                            <span className="span-title">Niveau d’expérience sur ce poste*</span>
                                                            <Select
                                                                className='mt-1'
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                value={niveauExperience}
                                                                error={(niveauExperience === "" && isValueFilled === true) ? true : false}
                                                                style={{ height: 40 }}
                                                                size="small"
                                                                onChange={(e) => {
                                                                    setNiveauExperience(e.target.value)
                                                                    updateProfile1('niveau', e.target.value, "employe_odoo")
                                                                }}
                                                                fullWidth
                                                            >
                                                                <MenuItem value={"Junior (0-2ans)"}>Junior (0-2ans)</MenuItem>
                                                                <MenuItem value={"Confirmé (3-7ans)"}>Confirmé (3-7ans)</MenuItem>
                                                                <MenuItem value={"Senior (8-12ans)"}>Senior (8-12ans)</MenuItem>
                                                                <MenuItem value={"Expert (13ans et +)"}>Expert (13ans et +)</MenuItem>
                                                            </Select>
                                                        </div>

                                                    </div>

                                                    <div className='row mt-2'>
                                                        <div className='col-md-6'>
                                                            <span className="span-title">Prix de la prestation par jour (HT)</span>
                                                            <FormControl fullWidth className='mt-1' variant="outlined">
                                                                <OutlinedInput
                                                                    onChange={(e) => {
                                                                        setMontant(e.target.value)
                                                                        updateProfile1('montant', e.target.value, "employe_odoo")
                                                                        console.log(e.target.value)
                                                                    }}
                                                                    value={montant}
                                                                    size='small'
                                                                    id="outlined-adornment-weight"
                                                                    endAdornment={<InputAdornment position="end">{parseInt(montant) > 0 ? "( " + (parseInt(montant) / 4870).toFixed(2) + " € ) MGA" : "MGA"}</InputAdornment>}
                                                                    aria-describedby="outlined-weight-helper-text"
                                                                    inputProps={{
                                                                        'aria-label': 'weight',
                                                                    }}
                                                                />
                                                            </FormControl>
                                                        </div>
                                                        <div className='col-md-6'>
                                                            <span className="span-title">Disponibilité</span>
                                                            <Select
                                                                className='mt-1'
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                value={disponibility}
                                                                style={{ height: 40 }}
                                                                size="small"
                                                                onChange={(e) => {
                                                                    setDisponibility(e.target.value)
                                                                    updateProfile1('disponibility', e.target.value, "employe_odoo")
                                                                }}
                                                                fullWidth
                                                            >
                                                                <MenuItem value={"1 jour"}>1 jour par semaine</MenuItem>
                                                                <MenuItem value={"2 jours"}>2 jours par semaine</MenuItem>
                                                                <MenuItem value={"3 jours"}>3 jours par semaine</MenuItem>
                                                                <MenuItem value={"4 jours"}>4 jours par semaine</MenuItem>
                                                                <MenuItem value={"Temps plein"}>Temps plein</MenuItem>
                                                            </Select>
                                                        </div>
                                                    </div>
                                                    <div className='row mt-2'>
                                                        <div className='col-md-5'>
                                                            <span className="span-title">Permis de conduire</span>
                                                            <div className='d-flex justify-content-between align-items-center px-2 mt-1 section-permis'>
                                                                <span>Permis B</span>
                                                                <Checkbox
                                                                    checked={checked}
                                                                    onChange={handleChange2}
                                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='col-7'>
                                                            <span className="span-title">Moyen de transport</span>
                                                            <Select
                                                                className='mt-1'
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                value={mobility}
                                                                style={{ height: 40 }}
                                                                size="small"
                                                                onChange={(e) => {
                                                                    setMobility(e.target.value)
                                                                    updateProfile1('mobility', e.target.value, "employe_odoo")
                                                                }}
                                                                fullWidth
                                                            >
                                                                <MenuItem value={"Véhicule personnel"}>Véhicule personnel</MenuItem>
                                                                <MenuItem value={"Bus"}>Bus</MenuItem>
                                                                <MenuItem value={"Covoiturage"}>Covoiturage</MenuItem>
                                                                <MenuItem value={"Train"}>Train</MenuItem>
                                                                <MenuItem value={"Autre"}>Autre</MenuItem>
                                                            </Select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='mt-3 pb-2' style={{ backgroundColor: postes.length === 0 ? "#fff" : "" }}>
                                                    <div className='d-flex justify-content-between p-1'>
                                                        <span style={{ fontWeight: 700, fontSize: 20, color: "grey", fontFamily: "montserrat" }}>Expériences</span>
                                                        <AddIcon onClick={(e) => {
                                                            setIsEditPoste(false)
                                                            setPosteUid('')
                                                            setOpenPosteDialog(true)
                                                        }}
                                                            fontSize='large' style={{ color: "#1565C0", cursor: "pointer" }} />
                                                    </div>
                                                    <Poste_Profil handleUpdateUser={handleUpdateUser} handleUpdateUid={handleUpdateUid} posteUid={posteUid} isEditPoste={isEditPoste} openPosteDialog={openPosteDialog} setLoading={(e) => { setLoading(e) }}
                                                        onCloseDialogPoste={(e) => {
                                                            setOpenPosteDialog(false)
                                                        }}
                                                    />

                                                    {postes.length > 0 ?
                                                        <div className='p-4' style={{ border: "1px solid #dcdcdc", backgroundColor: "#fff" }}>
                                                            {postes.slice(0, posteNumber).map((item, key) => (
                                                                <div className='d-flex mb-3' key={key}>
                                                                    <div className='d-flex flex-column px-3' style={{ width: "30%" }}>
                                                                        <span style={{ color: "#1565C0", fontSize: 22, fontFamily: "Montserrat", fontWeight: 700, textAlign: 'center' }}>{item.type_poste}</span>
                                                                        <span style={{ fontWeight: 600, textAlign: 'center', color: '#616161' }}>{calculateDuration(item.date_debut, item.date_fin, item.checked_poste)}</span>
                                                                    </div>
                                                                    <Divider style={{ backgroundColor: '#1565C0' }} orientation="vertical" flexItem />
                                                                    <div className='d-flex flex-column px-3' style={{ width: "67%" }}>
                                                                        <span style={{ color: "#1565C0", fontSize: 20, fontFamily: "Montserrat", fontWeight: 700 }}>{item.title}</span>
                                                                        <span style={{ fontWeight: 600, color: '#000' }}>{item.nom_soc + ", " + item.lieu_poste}</span>
                                                                        <span style={{ fontSize: 14 }}>{getDateRange(item.date_debut, item.date_fin, item.checked_poste)}</span>
                                                                        {/* <span className={`text ${showAll ? 'expanded' : ''}`} style={{ fontSize: 14 }}>{item.description}</span>
                                                                    {item.description.split(/\s+/).length > 15 && (
                                                                        <span className='toggle-button' onClick={toggleShow}>
                                                                            {showAll ? '...Voir moins' : '...Voir plus'}
                                                                        </span>
                                                                    )} */}
                                                                        <TextComponent key={item.uid} text={item.description} />
                                                                    </div>
                                                                    <div className='d-flex' style={{ width: "6%", gap: 2 }}>

                                                                        <EditOutlinedIcon onClick={(e) => {
                                                                            setIsEditPoste(true)
                                                                            setPosteUid(item.uid)
                                                                            setOpenPosteDialog(true)
                                                                        }}
                                                                            style={{ cursor: 'pointer' }} fontSize='small' color='primary' />
                                                                        <DeleteForeverOutlinedIcon
                                                                            onClick={(e) => {
                                                                                setOpenDeletePoste(true)
                                                                                setPosteUid(item.uid)
                                                                            }}
                                                                            fontSize='small'
                                                                            color='error' style={{ cursor: 'pointer' }} />
                                                                    </div>
                                                                </div>
                                                            ))}
                                                            {postes.length > 2 &&
                                                                <div className='d-flex justify-content-center align-items-center mt-2'
                                                                    onClick={(e) => {
                                                                        if (posteNumber === 2) {
                                                                            setPosteNumber(postes.length)
                                                                        } else {
                                                                            setPosteNumber(2)
                                                                        }
                                                                    }}>
                                                                    <span style={{ color: "#1565C0", fontSize: 16, fontFamily: "Montserrat", fontWeight: 700, cursor: 'pointer' }}>{posteNumber === 2 ? "Voir toutes les expériences" : "Voir moins"}</span>
                                                                    {posteNumber === 2 ?
                                                                        <KeyboardArrowDownOutlinedIcon style={{ cursor: 'pointer' }} fontSize='small' color='primary' /> :
                                                                        <KeyboardArrowUpOutlinedIcon style={{ cursor: 'pointer' }} fontSize='small' color='primary' />
                                                                    }
                                                                </div>
                                                            }
                                                        </div>
                                                        :
                                                        <div className='px-2'>
                                                            <span style={{ fontWeight: 600, textAlign: 'center', color: '#616161' }}>Ajoutez des expériences en utilisant le bouton (+)</span>
                                                        </div>
                                                    }
                                                </div>
                                                <div className='mt-3 pb-2' style={{ backgroundColor: formations.length === 0 ? "#fff" : "" }}>

                                                    <div className='d-flex justify-content-between p-1'>
                                                        <span style={{ fontWeight: 700, fontSize: 20, color: "grey", fontFamily: "montserrat" }}>Formations</span>
                                                        <AddIcon onClick={(e) => {
                                                            setIsEditFormation(false)
                                                            setFormationUid('')
                                                            setOpenFormationDialog(true)
                                                        }}
                                                            fontSize='large' style={{ color: "#1565C0", cursor: "pointer" }} />
                                                    </div>
                                                    <Formation_Profil handleUpdateUser={handleUpdateUser} handleUpdateUid={handleUpdateUid} formationUid={formationUid} isEditFormation={isEditFormation} openFormationDialog={openFormationDialog} setLoading={(e) => { setLoading(e) }}
                                                        onCloseDialogFormation={(e) => {
                                                            setOpenFormationDialog(false)
                                                        }}
                                                    />
                           
                                                    {formations.length > 0 ?
                                                        <div className='p-4' style={{ border: "1px solid #dcdcdc", backgroundColor: "#fff" }}>
                                                            {formations.slice(0, formationNumber).map((item, key) => (
                                                                <div className='d-flex mb-3' key={key}>
                                                                    <div className='d-flex flex-column px-3' style={{ width: "30%" }}>
                                                                        <span style={{ color: "#1565C0", fontSize: 22, fontFamily: "Montserrat", fontWeight: 700, textAlign: 'center' }}>{item.title}</span>
                                                                        <span style={{ fontWeight: 600, textAlign: 'center', color: '#616161' }}>{item.docUid ? 'Certifié' : 'Non certifié'}</span>
                                                                    </div>
                                                                    <Divider style={{ backgroundColor: '#1565C0' }} orientation="vertical" flexItem />
                                                                    <div className='d-flex flex-column px-3' style={{ width: "67%" }}>
                                                                        <span style={{ color: "#1565C0", fontSize: 20, fontFamily: "Montserrat", fontWeight: 700 }}>{item.domaine}</span>
                                                                        <span style={{ fontWeight: 600, color: '#000' }}>{item.ecole + ", " + item.lieu_formation}</span>
                                                                        <span style={{ fontSize: 14 }}>{getDateRangeFormation(item.date_debut, item.date_fin, item.checked_poste)}</span>
                                                                        {/* <span className={`text ${showAll ? 'expanded' : ''}`} style={{ fontSize: 14 }}>{item.description}</span>
                                                                    {item.description.split(/\s+/).length > 15 && (
                                                                        <span className='toggle-button' onClick={toggleShow}>
                                                                            {showAll ? '...Voir moins' : '...Voir plus'}
                                                                        </span>
                                                                    )} */}
                                                                        <TextComponent key={item.uid} text={item.description} />
                                                                    </div>
                                                                    <div className='d-flex' style={{ width: "6%", gap: 2 }}>
                                                                        <EditOutlinedIcon onClick={(e) => {
                                                                            setIsEditFormation(true)
                                                                            setFormationUid(item.uid)
                                                                            setOpenFormationDialog(true)
                                                                        }}
                                                                            style={{ cursor: 'pointer' }} fontSize='small' color='primary' />
                                                                        <DeleteForeverOutlinedIcon
                                                                            onClick={(e) => {
                                                                                setOpenDeleteFormation(true)
                                                                                setFormationUid(item.uid)
                                                                            }}
                                                                            fontSize='small'
                                                                            color='error' style={{ cursor: 'pointer' }} />
                                                                    </div>
                                                                </div>
                                                            ))}
                                                            {formations.length > 2 &&
                                                                <div className='d-flex justify-content-center align-items-center mt-2'
                                                                    onClick={(e) => {
                                                                        if (formationNumber === 2) {
                                                                            setFormationNumber(formations.length)
                                                                        } else {
                                                                            setFormationNumber(2)
                                                                        }
                                                                    }}>
                                                                    <span style={{ color: "#1565C0", fontSize: 16, fontFamily: "Montserrat", fontWeight: 700, cursor: 'pointer' }}>{formationNumber === 2 ? "Voir toutes les formations" : "Voir moins"}</span>
                                                                    {formationNumber === 2 ?
                                                                        <KeyboardArrowDownOutlinedIcon style={{ cursor: 'pointer' }} fontSize='small' color='primary' /> :
                                                                        <KeyboardArrowUpOutlinedIcon style={{ cursor: 'pointer' }} fontSize='small' color='primary' />
                                                                    }
                                                                </div>
                                                            }
                                                        </div>
                                                        :
                                                        <div className='px-2'>
                                                            <span style={{ fontWeight: 600, textAlign: 'center', color: '#616161' }}>Ajoutez des formations en utilisant le bouton (+)</span>
                                                        </div>
                                                    }
                                                </div>

                                                <div className='mt-3 pb-2' style={{ backgroundColor: projets.length === 0 ? "#fff" : "" }}>
                                                    <div className='d-flex justify-content-between p-1'>
                                                        <span style={{ fontWeight: 700, fontSize: 20, color: "grey", fontFamily: "montserrat" }}>Projets</span>
                                                        <AddIcon onClick={(e) => {
                                                            setIsEditProjet(false)
                                                            setProjetUid('')
                                                            setOpenProjetDialog(true)
                                                        }}
                                                            fontSize='large' style={{ color: "#1565C0", cursor: "pointer" }} />
                                                    </div>
                                                    <Projet_Profil handleUpdateUser={handleUpdateUser} handleUpdateUid={handleUpdateUid} projetUid={projetUid} isEditProjet={isEditProjet} openProjetDialog={openProjetDialog} setLoading={(e) => { setLoading(e) }}
                                                        onCloseDialogProjet={(e) => {
                                                            setOpenProjetDialog(false)
                                                        }}
                                                    />

                                                    {projets.length > 0 ?
                                                        <div className='p-4' style={{ border: "1px solid #dcdcdc", backgroundColor: "#fff" }}>
                                                            {projets.slice(0, projetNumber).map((item, key) => (
                                                                <div className='d-flex mb-3' key={key}>
                                                                    <div className='d-flex flex-column px-3' style={{ width: "30%" }}>
                                                                        <span style={{ color: "#1565C0", fontSize: 22, fontFamily: "Montserrat", fontWeight: 700, textAlign: 'center' }}>Projet {key + 1}</span>
                                                                        <span style={{ fontWeight: 600, textAlign: 'center', color: '#616161' }}>{calculateDuration(item.date_debut, item.date_fin, item.checked_projet)}</span>
                                                                    </div>
                                                                    <Divider style={{ backgroundColor: '#1565C0' }} orientation="vertical" flexItem />
                                                                    <div className='d-flex flex-column px-3' style={{ width: "67%" }}>
                                                                        <span style={{ color: "#1565C0", fontSize: 20, fontFamily: "Montserrat", fontWeight: 700 }}>{item.title}</span>
                                                                        <span style={{ fontWeight: 600, color: '#000' }}>{item.lieu_projet}</span>
                                                                        <span style={{ fontSize: 14 }}>{getDateRange(item.date_debut, item.date_fin, item.checked_projet)}</span>
                                                                        {/* <span className={`text ${showAll ? 'expanded' : ''}`} style={{ fontSize: 14 }}>{item.description}</span>
                                                                    {item.description.split(/\s+/).length > 15 && (
                                                                        <span className='toggle-button' onClick={toggleShow}>
                                                                            {showAll ? '...Voir moins' : '...Voir plus'}
                                                                        </span>
                                                                    )}                                   */}
                                                                        <TextComponent key={item.uid} text={item.description} />
                                                                    </div>
                                                                    <div className='d-flex' style={{ width: "6%", gap: 2 }}>
                                                                        <EditOutlinedIcon onClick={(e) => {
                                                                            setIsEditProjet(true)
                                                                            setProjetUid(item.uid)
                                                                            setOpenProjetDialog(true)
                                                                        }}
                                                                            style={{ cursor: 'pointer' }} fontSize='small' color='primary' />
                                                                        <DeleteForeverOutlinedIcon
                                                                            onClick={(e) => {
                                                                                setOpenDeleteProjet(true)
                                                                                setProjetUid(item.uid)
                                                                            }}
                                                                            fontSize='small'
                                                                            color='error' style={{ cursor: 'pointer' }} />
                                                                    </div>
                                                                </div>
                                                            ))}
                                                            {projets.length > 2 &&
                                                                <div className='d-flex justify-content-center align-items-center mt-2'
                                                                    onClick={(e) => {
                                                                        if (projetNumber === 2) {
                                                                            setProjetNumber(projets.length)
                                                                        } else {
                                                                            setProjetNumber(2)
                                                                        }
                                                                    }}>
                                                                    <span style={{ color: "#1565C0", fontSize: 16, fontFamily: "Montserrat", fontWeight: 700, cursor: 'pointer' }}>{projetNumber === 2 ? "Voir toutes les projets" : "Voir moins"}</span>
                                                                    {projetNumber === 2 ?
                                                                        <KeyboardArrowDownOutlinedIcon style={{ cursor: 'pointer' }} fontSize='small' color='primary' /> :
                                                                        <KeyboardArrowUpOutlinedIcon style={{ cursor: 'pointer' }} fontSize='small' color='primary' />
                                                                    }
                                                                </div>
                                                            }
                                                        </div>
                                                        :
                                                        <div className='px-2'>
                                                            <span style={{ fontWeight: 600, textAlign: 'center', color: '#616161' }}>Ajoutez des projets en utilisant le bouton (+)</span>
                                                        </div>
                                                    }
                                                </div>

                                            </>
                                        }
                                        {localStorage.getItem('role') === "freelance" &&
                                            <div className='mt-3' style={{ backgroundColor: langues.length === 0 ? "#fff" : '' }}>
                                                <div className='d-flex justify-content-between p-1'>
                                                    <span style={{ fontWeight: 700, fontSize: 20, color: "grey", fontFamily: "montserrat" }}>Langues</span>
                                                    <AddIcon onClick={(e) => {
                                                        setOpenLangueDialog(true)
                                                    }}
                                                        fontSize='large' style={{ color: "#1565C0", cursor: "pointer" }} />
                                                </div>

                                                <div className='row px-2 mb-3'>
                                                    {langues.length === 0 && <span style={{ fontWeight: 600, color: '#616161' }}>Ajoutez une langue en utilisant le bouton (+)</span>}
                                                    {langues.map((item, key) => (
                                                        <div className='col-md-4 p-2' key={key}>
                                                            <div className='card-portfolio p-3' >
                                                                <div className='d-flex align-items-center' style={{ gap: 10 }}>
                                                                    <CancelOutlinedIcon onClick={(e) => { handleDeleteLangue(item) }} style={{ cursor: 'pointer', height: 22, width: 22 }} color='error' />
                                                                    <img
                                                                        loading="lazy"
                                                                        width="40"
                                                                        style={{ height: 25 }}
                                                                        srcSet={`https://flagcdn.com/w40/${item.langue === 'Anglais' ? 'gb' :
                                                                            item.langue === 'Français' ? 'fr' :
                                                                                item.langue === 'Espagnol' ? 'es' :
                                                                                    item.langue === 'Chinois' ? 'cn' :
                                                                                        item.langue === 'Arabe' ? 'sa' :
                                                                                            item.langue === 'Allemand' ? 'de' :
                                                                                                item.langue === 'Italien' ? 'it' :
                                                                                                    item.langue === 'Japonais' ? 'jp' :
                                                                                                        item.langue === 'Russe' ? 'ru' :
                                                                                                            item.langue === 'Portugais' ? 'pt' :
                                                                                                                item.langue === 'Hindi' ? 'in' :
                                                                                                                    item.langue === 'Coréen' ? 'kr' :
                                                                                                                        item.langue === 'Turc' ? 'tr' :
                                                                                                                            item.langue === 'Néerlandais' ? 'nl' :
                                                                                                                                item.langue === 'Suédois' ? 'se' :
                                                                                                                                    item.langue === 'Polonais' ? 'pl' :
                                                                                                                                        item.langue === 'Grec' ? 'gr' :
                                                                                                                                            ''
                                                                            }.png`}
                                                                        src={`https://flagcdn.com/w20/${item.langue === 'Anglais' ? 'gb' :
                                                                            item.langue === 'Français' ? 'fr' :
                                                                                item.langue === 'Espagnol' ? 'es' :
                                                                                    item.langue === 'Chinois' ? 'cn' :
                                                                                        item.langue === 'Arabe' ? 'sa' :
                                                                                            item.langue === 'Allemand' ? 'de' :
                                                                                                item.langue === 'Italien' ? 'it' :
                                                                                                    item.langue === 'Japonais' ? 'jp' :
                                                                                                        item.langue === 'Russe' ? 'ru' :
                                                                                                            item.langue === 'Portugais' ? 'pt' :
                                                                                                                item.langue === 'Hindi' ? 'in' :
                                                                                                                    item.langue === 'Coréen' ? 'kr' :
                                                                                                                        item.langue === 'Turc' ? 'tr' :
                                                                                                                            item.langue === 'Néerlandais' ? 'nl' :
                                                                                                                                item.langue === 'Suédois' ? 'se' :
                                                                                                                                    item.langue === 'Polonais' ? 'pl' :
                                                                                                                                        item.langue === 'Grec' ? 'gr' :
                                                                                                                                            ''
                                                                            }.png`}
                                                                        alt=""
                                                                    />
                                                                    <div className='d-flex flex-column'>
                                                                        <span style={{ maxWidth: 200, overflow: 'hidden', textOverflow: 'ellipsis', color: "#1976D2", fontWeight: 600 }} className='span-langue'>{item.langue}</span>
                                                                        <span style={{ maxWidth: 200, overflow: 'hidden', textOverflow: 'ellipsis', color: '#000' }} className='span-langue'>Niveau: <b>{item.level}</b> </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}

                                                </div>

                                            </div>
                                        }


                                        {(localStorage.getItem('role') === "freelance" && userType === "Une personne morale") &&
                                            <>
                                                <span className='span-info-perso uppercase-text mt-4'>MES INFORMATIONS JURIDIQUES</span>
                                                <div className='p-2' style={{ backgroundColor: '#fff' }}>
                                                    <span className='mt-1' style={{ color: 'grey', fontWeight: 700 }}>* = Champs obligatoires</span>
                                                    <div className='row mt-3'>
                                                        <div className='col-md-6'>
                                                            <span className="span-title mb-1">Dénomination sociale*</span>
                                                            <TextField fullWidth size="small" id="outlined-basic" variant="outlined"
                                                                value={denomination}
                                                                error={(denomination === "" && isValueFilled === true) ? true : false}
                                                                onChange={(e) => {
                                                                    setDenomination(e.target.value)
                                                                    updateProfile1("denomination", e.target.value, "employe_odoo")
                                                                }} />
                                                        </div>
                                                        <div className='col-md-6'>
                                                            <span className="span-title mb-1">Forme juridique*</span>
                                                            <TextField fullWidth size="small" id="outlined-basic1" variant="outlined"
                                                                value={formJuridique}
                                                                error={(formJuridique === "" && isValueFilled === true) ? true : false}
                                                                onChange={(e) => {
                                                                    setFormJuridique(e.target.value)
                                                                    updateProfile1("form_juridique", e.target.value, "employe_odoo")

                                                                }} />
                                                        </div>
                                                    </div>

                                                    <div className='row mt-2'>
                                                        <div className='col-md-6'>
                                                            <span className="span-title">SIRET*</span>
                                                            <TextField fullWidth className='mt-1' size="small" id="outlined-basic3" variant="outlined"
                                                                value={siret}
                                                                error={(siret === "" && isValueFilled === true) ? true : false}
                                                                onChange={(e) => {
                                                                    setSiret(e.target.value)
                                                                    updateProfile1("siret", e.target.value, "employe_odoo")
                                                                }} />
                                                        </div>
                                                        <div className='col-md-6'>
                                                            <span className="span-title mb-1">Numéro de TVA</span>
                                                            <TextField className='mt-1' fullWidth size="small" id="outlined-basic10" variant="outlined"
                                                                value={numTva}
                                                                onChange={(e) => {
                                                                    setNumTva(e.target.value)
                                                                    updateProfile1("num_tva", e.target.value, "employe_odoo")
                                                                }} />
                                                        </div>
                                                    </div>

                                                    <div className='row mb-1'>

                                                        <div className='col-md-6 date-section1'>
                                                            <span className="span-title">Date de création*</span>
                                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                <DemoContainer components={['DatePicker']}>
                                                                    <DatePicker format="DD/MM/YYYY" value={dayjs(dateCreationJuridique)} onChange={(e) => {
                                                                        console.log(e)
                                                                        if (e) {
                                                                            setDateCreationJuridique(moment(e.$d).format('YYYY-MM-DD'))
                                                                            updateProfile1("date_creation_juridique", moment(e.$d).format('YYYY-MM-DD'), "employe_odoo")
                                                                        }

                                                                    }} />
                                                                </DemoContainer>
                                                            </LocalizationProvider>
                                                        </div>
                                                        <div className='col-md-6'>
                                                            <span className="span-title mb-1">Pays*</span>
                                                            <Autocomplete
                                                                id="country-select-demo1"
                                                                options={countries.countries}
                                                                className="countries-autocomplete mt-2"
                                                                value={paysJuridique ? paysJuridique : null}
                                                                onChange={(e, selected) => {
                                                                    if (selected) {
                                                                        setPaysJuridique(selected)
                                                                        updateProfile1("pays_juridique", selected, "employe_odoo")
                                                                    }

                                                                }}
                                                                autoHighlight
                                                                getOptionLabel={(option) => option.label}
                                                                renderOption={(props, option) => (
                                                                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                                        <img
                                                                            loading="lazy"
                                                                            width="20"
                                                                            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                                                            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                                                            alt=""
                                                                        />
                                                                        {option.label}
                                                                    </Box>
                                                                )}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        size="small"
                                                                        inputProps={{
                                                                            ...params.inputProps,
                                                                            autoComplete: 'new-password', // disable autocomplete and autofill
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                        </div>

                                                    </div>

                                                    <div className='row'>

                                                        <div className='col-md-6'>
                                                            <span className="span-title mb-1">Adresse*</span>
                                                            <TextField fullWidth size="small" id="outlined-basic10" variant="outlined"
                                                                value={adressJuridique}
                                                                error={(adressJuridique === "" && isValueFilled === true) ? true : false}
                                                                onChange={(e) => {
                                                                    setAdressJuridique(e.target.value)
                                                                    updateProfile1("adress_juridique", e.target.value, "employe_odoo")
                                                                }} />
                                                        </div>

                                                        <div className='col-md-6'>
                                                            <span className="span-title mb-1">Complément d’adresse</span>
                                                            <TextField fullWidth size="small" id="outlined-basic11" variant="outlined"
                                                                value={complAdressJuridique}
                                                                onChange={(e) => {
                                                                    setComplAdressJuridique(e.target.value)
                                                                    updateProfile1("compl_adress_juridique", e.target.value, "employe_odoo")
                                                                }} />
                                                        </div>
                                                    </div>

                                                    <div className='row mt-2'>
                                                        <div className='col-md-6'>
                                                            <span className="span-title mb-1">Code postal*</span>
                                                            <TextField fullWidth size="small" id="outlined-basic11" variant="outlined"
                                                                value={codepJuridique}
                                                                error={(codepJuridique === "" && isValueFilled === true) ? true : false}
                                                                onChange={(e) => {
                                                                    setCodepJuridique(e.target.value)
                                                                    updateProfile1("codep_juridique", e.target.value, "employe_odoo")
                                                                }} />
                                                        </div>

                                                        <div className='col-md-6'>
                                                            <span className="span-title mb-1">Ville*</span>
                                                            <TextField fullWidth size="small" id="outlined-basic11" variant="outlined"
                                                                value={villeJuridique}
                                                                error={(villeJuridique === "" && isValueFilled === true) ? true : false}
                                                                onChange={(e) => {
                                                                    setVilleJuridique(e.target.value)
                                                                    updateProfile1("ville_juridique", e.target.value, "employe_odoo")
                                                                }} />
                                                        </div>


                                                    </div>
                                                </div>
                                            </>
                                        }

                                        <span className='span-info-perso mt-4 uppercase-text'>Mes moyens de contact</span>
                                        <div className='p-2' style={{ backgroundColor: '#fff' }}>
                                            <div className='row'>
                                                <div className='col-md-12'>
                                                    <span className="span-title">Linkedin</span>
                                                    <FormControl fullWidth variant="outlined">
                                                        <OutlinedInput
                                                            id="outlined-adornment-password"
                                                            size="small"
                                                            variant="filled"
                                                            value={linkedin}
                                                            onChange={(e) => {
                                                                setLinkedin(e.target.value)
                                                                updateProfile1('linkedin', e.target.value, "employe_odoo")
                                                            }}
                                                            startAdornment={
                                                                <InputAdornment position="start">
                                                                    <IconButton
                                                                        aria-label="toggle password visibility"
                                                                        edge="start"
                                                                    >
                                                                        <img src="/linkedin1.png" />
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            }
                                                        />
                                                    </FormControl>
                                                </div>

                                            </div>

                                            <div className='row mt-2'>
                                                <div className='col-md-12'>
                                                    <span className="span-title">Facebook</span>
                                                    <FormControl fullWidth variant="outlined">
                                                        <OutlinedInput
                                                            id="outlined-adornment-password"
                                                            size="small"
                                                            variant="filled"
                                                            value={facebook}
                                                            onChange={(e) => {
                                                                setFacebook(e.target.value)
                                                                updateProfile1('facebook', e.target.value, "employe_odoo")
                                                            }}
                                                            startAdornment={
                                                                <InputAdornment position="start">
                                                                    <IconButton
                                                                        aria-label="toggle password visibility"
                                                                        edge="start"
                                                                    >
                                                                        <img src="/facb.png" />
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            }
                                                        />
                                                    </FormControl>
                                                </div>

                                            </div>

                                            <div className='row mt-2'>
                                                <div className='col-md-12'>
                                                    <span className="span-title">Twitter</span>
                                                    <FormControl fullWidth variant="outlined">
                                                        <OutlinedInput
                                                            id="outlined-adornment-password"
                                                            size="small"
                                                            variant="filled"
                                                            value={twitter}
                                                            onChange={(e) => {
                                                                setTwitter(e.target.value)
                                                                updateProfile1('twitter', e.target.value, "employe_odoo")
                                                            }}
                                                            startAdornment={
                                                                <InputAdornment position="start">
                                                                    <IconButton
                                                                        aria-label="toggle password visibility"
                                                                        edge="start"
                                                                    >
                                                                        <img src="/twitter.png" />
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            }
                                                        />
                                                    </FormControl>
                                                </div>

                                            </div>

                                            <div className='row mt-2'>
                                                <div className='col-md-12'>
                                                    <span className="span-title">Whatsapp</span>
                                                    <FormControl fullWidth variant="outlined">
                                                        <OutlinedInput
                                                            id="outlined-adornment-password"
                                                            size="small"
                                                            variant="filled"
                                                            value={whatsapp}
                                                            onChange={(e) => {
                                                                setWhatsapp(e.target.value)
                                                                updateProfile1('whatsapp', e.target.value, "employe_odoo")
                                                            }}
                                                            startAdornment={
                                                                <InputAdornment position="start">
                                                                    <IconButton
                                                                        aria-label="toggle password visibility"
                                                                        edge="start"
                                                                    >
                                                                        <img src="/whatsapp.png" />
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            }
                                                        />
                                                    </FormControl>
                                                </div>

                                            </div>

                                            <div className='row mt-2'>
                                                <div className='col-md-12'>
                                                    <span className="span-title">Gmail</span>
                                                    <FormControl fullWidth variant="outlined">
                                                        <OutlinedInput
                                                            id="outlined-adornment-password"
                                                            size="small"
                                                            variant="filled"
                                                            value={gmail}
                                                            onChange={(e) => {
                                                                setGmail(e.target.value)
                                                                updateProfile1('gmail', e.target.value, "employe_odoo")
                                                            }}
                                                            startAdornment={
                                                                <InputAdornment position="start">
                                                                    <IconButton
                                                                        aria-label="toggle password visibility"
                                                                        edge="start"
                                                                    >
                                                                        <img src="/gmail.png" />
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            }
                                                        />
                                                    </FormControl>
                                                </div>
                                            </div>
                                        </div>
                                        <span className='span-info-perso mt-4'>RETOURS</span>
                                        {comments.length > 0 ?
                                            <div className='row'>
                                                {comments.map((item, key) => (
                                                    <div key={key} className='col-md-6 p-3'>
                                                        <div className='card-detail-profil p-2'>
                                                            <div className='d-flex' style={{ gap: 10 }}>
                                                                <Avatar className="avatar-comment">
                                                                    {item.user_first_name[0] + item.user_last_name[0]}
                                                                </Avatar>
                                                                <div className='d-flex flex-column'>
                                                                    <span className='span-user-comment'>{item.user_first_name + " " + item.user_last_name}</span>
                                                                    <div className='d-flex align-items-center' style={{ gap: 5 }}>
                                                                        <img alt="star" width={17} height={17} src='/star.png' />
                                                                        <span style={{ fontFamily: 'Montserrat', fontSize: 12 }}>{(parseInt(item.communication) + parseInt(item.satisfaction)) / 2} / 5</span>
                                                                    </div>
                                                                    <p className='m-0' style={{ fontFamily: 'Montserrat', fontSize: 12 }}>{item.description}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                                }
                                            </div>
                                            :
                                            <p className='m-0' style={{ fontFamily: 'Montserrat', fontSize: 14, fontWeight: 700 }}>{localStorage.getItem('role') === 'porteur' ? 'Publiez une mission et faites appel à un.e de nos freelance pour avoir votre premier retour' : "Postulez à une mission et faites appel à un.e de nos porteur pour avoir votre premier retour"}</p>
                                        }
                                        {openAlert === true &&
                                            <div className='row mt-3 mb-4'>
                                                <div className='col-md-12'>
                                                    <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
                                                        Informations sauvegardées avec succés
                                                    </Alert>
                                                </div>
                                            </div>
                                        }
                                        {isValueFilled === true &&
                                            <Alert
                                                iconMapping={{
                                                    info: <InfoOutlinedIcon style={{ color: "red" }} />,
                                                }}
                                                style={{ border: "2px solid #red", backgroundColor: "#FFE2E2", borderRadius: 10, alignItems: 'center', marginLeft: -12 }} severity="error">Veuillez remplir toutes les données obligatoires (mentionnées par le symbole “*”) avant de sauvegarder.
                                                Si vous rencontrez un problème ou une donnée que vous ne pouvez remplir, n’hésitez pas à nous contacter à FREELANCE_MADA_MAIL
                                            </Alert>
                                        }

                                        <div className='row mt-2 mb-4'>
                                            <div className='col-md-12'>
                                                <Button disabled={isDisabled} className="btn-profile" style={{ width: "100%", fontSize: 16 }} variant="contained" onClick={(e) => {
                                                    if (localStorage.getItem('role') === "freelance") {
                                                        verifEmptyValue()
                                                    } else {
                                                        if (userTypePorteur === "Une personne morale") {
                                                            verifEmptyValuePorteurMoral()
                                                        } else {
                                                            verifEmptyValuePorteur()
                                                        }
                                                    }
                                                    setTimeout(() => {
                                                        setOpenAlert(false)
                                                    }, 5000)
                                                }}>
                                                    Sauvegarder les informations
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </>
                }
            </div>

            <Drawer anchor="right" open={openBottomSignModal}
                onClose={() => setOpenBottomSignModal(false)}
                style={{ borderRadius: 20 }}
            >
                <div style={{ padding: 15 }}>
                    <h5 style={{ color: "green", marginTop: 5 }}>Dessinez votre signature</h5>
                    <div style={{ marginTop: 20, textAlign: "center" }}>
                        <div style={{ height: 300, border: '3px solid #f0f0f0' }}>
                            <SignatureCanvas ref={(ref) => {
                                setSigCanvas(ref)
                            }} penColor={"#000"} canvasProps={{
                                width: 520,
                                height: 300,
                                className: 'sigCanvas'
                            }}
                            />
                        </div>
                    </div>
                    <div align="right" style={{ marginTop: 15 }}>
                        <Button
                            onClick={() => {
                                setTimeout(() => {
                                    let signature = sigCanvas.getTrimmedCanvas().toDataURL('image/png');
                                    const cleanBase64 = signature.replace(/^data:image\/png;base64,/, '');
                                    let data = {
                                        date: moment().format("DD/MM/YYYY"),
                                        b64: cleanBase64,
                                        fileName: "Freelance",
                                        w: 170,
                                        h: 80,
                                        page: 1
                                    }
                                    console.log(data)
                                    let data1 = {
                                        file64: "data:application/pdf;base64," + doc64,
                                        signature: data
                                    }
                                    apiNodeService.signDoc(data1).then(res => {
                                        console.log(res)
                                        downloadPdf(res.pdf)
                                        insertDoc(res.pdf)
                                    })
                                    // sendAnnotatedImage(data.signature)
                                    setOpenBottomSignModal(false)
                                }, 250)
                            }}
                            color="primary"
                            variant='outlined'
                        >
                            Valider
                        </Button>
                    </div>
                    <div align="right" style={{ marginTop: 15 }}>
                        <Button
                            onClick={() => {
                                sigCanvas.clear()
                            }}
                            variant='outlined'
                            color="error"
                        >
                            Annuler
                        </Button>
                    </div>
                </div>
            </Drawer>

            <Dialog
                open={openCompetenceModal}
                aria-labelledby="form-dialog-title"
                fullWidth={true}
                maxWidth="sm"
                onClose={(e) => {
                    setOpenCompetenceModal(false)
                }}
            >
                <DialogTitle disableTypography id="form-dialog-title" style={{ paddingBottom: 1 }}>
                    <div className='d-flex' style={{ gap: 10 }}>
                        <AddIcon style={{ color: "#1565C0" }} />
                        <Typography style={{ color: '#1565C0', fontWeight: 600 }}>{"Ajouter des compétences"} </Typography>
                    </div>
                    <IconButton
                        aria-label="close"
                        style={{
                            position: 'absolute',
                            right: 5,
                            top: 5,
                            color: '#c0c0c0'
                        }}
                        onClick={() => {
                            setOpenCompetenceModal(false)
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <hr style={{ color: "#1565C0" }} />
                </DialogTitle>
                <DialogContent style={{ overflowY: "inherit" }}>
                    <>
                        <div className='d-flex flex-column p-4'>
                            <span style={{ color: "#616161", fontWeight: 600 }}>Compétences informatiques requises</span>
                            <Autocomplete
                                multiple
                                className='mb-4 mt-2'
                                id="tags-filled"
                                options={options}
                                defaultValue={[]}
                                onChange={(event, newValue) => {
                                    console.log(newValue);
                                    setSelectedLogiciel(newValue)
                                }}
                                renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                        <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                                    ))
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        size='small'
                                        label="Ajouter des compétences informatiques"
                                        placeholder="Logiciels"
                                        onChange={handleInputChange}
                                    />
                                )}
                            />
                            <span style={{ color: "#616161", fontWeight: 600 }}>Compétences métier requises</span>
                            <Autocomplete
                                className='mt-2'
                                multiple
                                id="tags-filled1"
                                options={options1}
                                defaultValue={[]}
                                onChange={(event, newValue) => {
                                    console.log(newValue);
                                    setSelectedMetiers(newValue)
                                }}
                                renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                        <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                                    ))
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        size='small'
                                        label="Ajouter des compétences métier"
                                        placeholder="Métiers"
                                        onChange={handleInputChange1}
                                    />
                                )}
                            />
                        </div>

                    </>
                </DialogContent>
                <DialogActions style={{ padding: "10px 55px 20px 35px", justifyContent: 'end' }}>
                    <Button
                        variant="outlined"
                        color='primary'
                        onClick={() => {
                            setOpenCompetenceModal(false)
                        }}
                        style={{ textTransform: 'none', fontWeight: 600 }}
                    >
                        Annuler
                    </Button>
                    <Button
                        variant="contained"
                        disabled={(selectedValuesLogiciel.length === 0 && selectedValuesMetiers.length === 0) ? true : false}
                        onClick={(e) => {
                            const newCompetences = selectedValuesLogiciel.concat(selectedValuesMetiers)
                            console.log(newCompetences)
                            addToCompetences(newCompetences)
                        }}
                        style={{ textTransform: 'none', color: "#fff", backgroundColor: (selectedValuesLogiciel.length === 0 && selectedValuesMetiers.length === 0) ? "grey" : "#1565C0", minWidth: 180, fontWeight: 600 }}
                    >
                        Ajouter les compétences
                    </Button>

                </DialogActions>
            </Dialog>

            <Dialog
                open={openPortfolioModal}
                aria-labelledby="form-dialog-title"
                fullWidth={true}
                maxWidth="sm"
                onClose={(e) => {
                    setOpenPortfolioModal(false)
                }}

            >
                <DialogTitle id="form-dialog-title" style={{ paddingBottom: 1 }}>
                    <div className='d-flex' style={{ gap: 10 }}>
                        <AddIcon style={{ color: "#1565C0" }} />
                        <Typography style={{ color: '#1565C0', fontWeight: 600 }}>{"Ajouter un lien portfolio"} </Typography>
                    </div>
                    <IconButton
                        aria-label="close"
                        style={{
                            position: 'absolute',
                            right: 5,
                            top: 5,
                            color: '#c0c0c0'
                        }}
                        onClick={() => {
                            setOpenPortfolioModal(false)
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <hr style={{ color: "#1565C0" }} />
                </DialogTitle>
                <DialogContent style={{ overflowY: "inherit" }}>
                    <>
                        <span style={{ color: 'grey', marginLeft: 26, fontWeight: 700 }}>* = Champs obligatoires</span>
                        <div className='d-flex flex-column p-4'>
                            <span className="span-title mb-1">Nom du portfolio*</span>
                            <TextField fullWidth size="small" id="outlined-basic11" variant="outlined"
                                value={portfolioName}
                                onChange={(e) => {
                                    setPortfolioName(e.target.value)
                                }} />
                            <span className="span-title mb-1 mt-3">Lien du portfolio*</span>
                            <TextField fullWidth size="small" id="outlined-basic11" variant="outlined"
                                value={portfolioLink}
                                onChange={(e) => {
                                    setPortfolioLink(e.target.value)
                                }} />
                        </div>

                    </>
                </DialogContent>
                <DialogActions style={{ padding: "10px 55px 20px 35px", justifyContent: 'end' }}>
                    <Button
                        variant="outlined"
                        color='primary'
                        onClick={() => {
                            setOpenPortfolioModal(false)
                        }}
                        style={{ textTransform: 'none', fontWeight: 600 }}
                    >
                        Annuler
                    </Button>
                    <Button
                        variant="contained"
                        disabled={(portfolioLink === '' || portfolioName === '') ? true : false}
                        onClick={(e) => {
                            let data = portfolio;
                            let newData = {
                                title: portfolioName,
                                link: portfolioLink
                            }
                            data.push(newData)
                            setPortfolio(data)
                            addPortfolio(data)
                        }}
                        style={{ textTransform: 'none', color: "#fff", backgroundColor: (portfolioLink === '' || portfolioName === '') ? "grey" : "#1565C0", minWidth: 180, fontWeight: 600 }}
                    >
                        Ajouter le portfolio
                    </Button>
                </DialogActions>
            </Dialog>


            <Dialog
                open={openLangueDialog}
                aria-labelledby="form-dialog-title"
                fullWidth={true}
                maxWidth="sm"
                onClose={(e) => {
                    setOpenLangueDialog(false)
                }}

            >
                <DialogTitle id="form-dialog-title" style={{ paddingBottom: 1 }}>
                    <div className='d-flex' style={{ gap: 10 }}>
                        <AddIcon style={{ color: "#1565C0" }} />
                        <Typography style={{ color: '#1565C0', fontWeight: 600 }}>{"Ajouter une langue"} </Typography>
                    </div>
                    <IconButton
                        aria-label="close"
                        style={{
                            position: 'absolute',
                            right: 5,
                            top: 5,
                            color: '#c0c0c0'
                        }}
                        onClick={() => {
                            setOpenLangueDialog(false)
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <hr style={{ color: "#1565C0" }} />
                </DialogTitle>
                <DialogContent style={{ overflowY: "inherit" }}>
                    <>
                        <span style={{ color: 'grey', marginLeft: 26, fontWeight: 700 }}>* = Champs obligatoires</span>
                        <div className='d-flex flex-column p-4'>
                            <span className="span-title mb-1">Langue maîtrisée*</span>
                            <Select
                                className='mt-1'
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={langue}
                                style={{ height: 40 }}
                                size="small"
                                displayEmpty
                                onChange={(e) => {
                                    setLangue(e.target.value)
                                }}
                                fullWidth
                            >
                                <MenuItem value={"Français"}>Français</MenuItem>
                                <MenuItem value={"Anglais"}>Anglais</MenuItem>
                                <MenuItem value={"Espagnol"}>Espagnol</MenuItem>
                                <MenuItem value={"Arabe"}>Arabe</MenuItem>
                                <MenuItem value={"Chinois"}>Chinois</MenuItem>
                                <MenuItem value={"Allemand"}>Allemand</MenuItem>
                                <MenuItem value={"Italien"}>Italien</MenuItem>
                                <MenuItem value={"Japonais"}>Japonais</MenuItem>
                                <MenuItem value={"Russe"}>Russe</MenuItem>
                                <MenuItem value={"Portugais"}>Portugais</MenuItem>
                                <MenuItem value={"Hindi"}>Hindi</MenuItem>
                                <MenuItem value={"Coréen"}>Coréen</MenuItem>
                                <MenuItem value={"Turc"}>Turc</MenuItem>
                                <MenuItem value={"Néerlandais"}>Néerlandais</MenuItem>
                                <MenuItem value={"Suédois"}>Suédois</MenuItem>
                                <MenuItem value={"Polonais"}>Polonais</MenuItem>
                                <MenuItem value={"Grec"}>Grec</MenuItem>

                            </Select>
                            <span className="span-title mb-1 mt-3">Niveau de maîtrise*</span>
                            <Select
                                className='mt-1'
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={langueLevel}
                                style={{ height: 40 }}
                                size="small"
                                onChange={(e) => {
                                    setLangueLevel(e.target.value)
                                }}
                                fullWidth
                            >
                                <MenuItem value={"Langue maternelle"}>Langue maternelle</MenuItem>
                                <MenuItem value={"A1"}>A1</MenuItem>
                                <MenuItem value={"A2"}>A2</MenuItem>
                                <MenuItem value={"B1"}>B1</MenuItem>
                                <MenuItem value={"B2"}>B2</MenuItem>
                                <MenuItem value={"C1"}>C1</MenuItem>
                                <MenuItem value={"C2"}>C2</MenuItem>
                            </Select>
                        </div>

                    </>
                </DialogContent>
                <DialogActions style={{ padding: "10px 55px 20px 35px", justifyContent: 'end' }}>
                    <Button
                        variant="outlined"
                        color='primary'
                        onClick={() => {
                            setOpenLangueDialog(false)
                        }}
                        style={{ textTransform: 'none', fontWeight: 600 }}
                    >
                        Annuler
                    </Button>
                    <Button
                        variant="contained"
                        disabled={(langue === '' || langueLevel === '') ? true : false}
                        onClick={(e) => {
                            let data = langues;
                            let newData = {
                                langue: langue,
                                level: langueLevel
                            }
                            data.push(newData)
                            setLangues(data)
                            addLangue(data)
                        }}
                        style={{ textTransform: 'none', color: "#fff", backgroundColor: (langue === '' || langueLevel === '') ? "grey" : "#1565C0", minWidth: 180, fontWeight: 600 }}
                    >
                        Ajouter la langue
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openDeletePoste}
                aria-labelledby="form-dialog-title"
                fullWidth={true}
                maxWidth="sm"
                onClose={(e) => {
                    setOpenDeletePoste(false)
                }}

            >
                <DialogTitle id="form-dialog-title" style={{ paddingBottom: 1 }}>
                    <div className='d-flex' style={{ gap: 10 }}>
                        <DeleteForeverOutlinedIcon color='error' />
                        <Typography style={{ color: '#1565C0', fontWeight: 600 }}>{"Êtes vous sur de vouloir supprimer cette expérience ?"} </Typography>
                    </div>
                    <IconButton
                        aria-label="close"
                        style={{
                            position: 'absolute',
                            right: 5,
                            top: 5,
                            color: '#c0c0c0'
                        }}
                        onClick={() => {
                            setOpenDeletePoste(false)
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <hr style={{ color: "#1565C0" }} />
                </DialogTitle>
                <DialogActions style={{ justifyContent: 'end' }}>
                    <Button
                        variant="outlined"
                        color='primary'
                        onClick={() => {
                            setOpenDeletePoste(false)
                        }}
                        style={{ textTransform: 'none', fontWeight: 600 }}
                    >
                        Non, je garde cette expérience
                    </Button>
                    <Button
                        variant="outlined"
                        color="error"
                        onClick={(e) => {
                            deletePoste()
                        }}
                        style={{ textTransform: 'none', minWidth: 180, fontWeight: 600 }}
                    >
                        Oui, je veux supprimer cette expérience
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openDeleteFormation}
                aria-labelledby="form-dialog-title"
                fullWidth={true}
                maxWidth="sm"
                onClose={(e) => {
                    setOpenDeleteFormation(false)
                }}

            >
                <DialogTitle id="form-dialog-title" style={{ paddingBottom: 1 }}>
                    <div className='d-flex' style={{ gap: 10 }}>
                        <DeleteForeverOutlinedIcon color='error' />
                        <Typography style={{ color: '#1565C0', fontWeight: 600 }}>{"Êtes vous sur de vouloir supprimer cette formation ?"} </Typography>
                    </div>
                    <IconButton
                        aria-label="close"
                        style={{
                            position: 'absolute',
                            right: 5,
                            top: 5,
                            color: '#c0c0c0'
                        }}
                        onClick={() => {
                            setOpenDeleteFormation(false)
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <hr style={{ color: "#1565C0" }} />
                </DialogTitle>
                <DialogActions style={{ justifyContent: 'end' }}>
                    <Button
                        variant="outlined"
                        color='primary'
                        onClick={() => {
                            setOpenDeleteFormation(false)
                        }}
                        style={{ textTransform: 'none', fontWeight: 600 }}
                    >
                        Non, je garde cette formation
                    </Button>
                    <Button
                        variant="outlined"
                        color="error"
                        onClick={(e) => {
                            deleteFormation()
                        }}
                        style={{ textTransform: 'none', minWidth: 180, fontWeight: 600 }}
                    >
                        Oui, je veux supprimer cette formation
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openDeleteProjet}
                aria-labelledby="form-dialog-title"
                fullWidth={true}
                maxWidth="sm"
                onClose={(e) => {
                    setOpenDeleteProjet(false)
                }}

            >
                <DialogTitle id="form-dialog-title" style={{ paddingBottom: 1 }}>
                    <div className='d-flex' style={{ gap: 10 }}>
                        <DeleteForeverOutlinedIcon color='error' />
                        <Typography style={{ color: '#1565C0', fontWeight: 600 }}>{"Êtes vous sur de vouloir supprimer ce projet ?"} </Typography>
                    </div>
                    <IconButton
                        aria-label="close"
                        style={{
                            position: 'absolute',
                            right: 5,
                            top: 5,
                            color: '#c0c0c0'
                        }}
                        onClick={() => {
                            setOpenDeleteProjet(false)
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <hr style={{ color: "#1565C0" }} />
                </DialogTitle>
                <DialogActions style={{ justifyContent: 'end' }}>
                    <Button
                        variant="outlined"
                        color='primary'
                        onClick={() => {
                            setOpenDeleteProjet(false)
                        }}
                        style={{ textTransform: 'none', fontWeight: 600 }}
                    >
                        Non, je garde ce projet
                    </Button>
                    <Button
                        variant="outlined"
                        color="error"
                        onClick={(e) => {
                            deleteProjet()
                        }}
                        style={{ textTransform: 'none', minWidth: 180, fontWeight: 600 }}
                    >
                        Oui, je veux supprimer ce projet
                    </Button>
                </DialogActions>
            </Dialog>


        </>
    )
}
export default Complete_Profil;