import React from 'react'
import useWindowSize from "../../components/WindowSize/useWindowSize";
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";
import MuiBackdrop from "../../components/Loading/MuiBackdrop";
import {toast} from 'react-toastify';

import "./style.css"
import nodes from "../../data/nodeAnnuaire"
import CheckboxTree from 'react-checkbox-tree';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import dataAnnuaire from '../../data/dataAnnuaire'
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import {Checkbox, FormControlLabel, FormGroup, MenuItem, Select} from "@mui/material";
import OutlinedInput from '@mui/material/OutlinedInput';
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import {blue} from "@mui/material/colors";
import Avatar from "@mui/material/Avatar";
import IconButton from "@material-ui/core/IconButton";
import rethink from "../../controller/rethink";
import { Theme, useTheme } from '@mui/material/styles';
import InputAdornment from '@mui/material/InputAdornment';
import colors from '../../data/colors'
import {Button as MuiButton,Chip } from '@material-ui/core'
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import {CheckBox} from "@mui/icons-material";
import { apiNodeService } from '../../provider/apiNodeService';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const db_name=process.env.REACT_APP_RETHINK_DB_NAME
function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

export default function Annuaire_List(props){
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const[checked,setChecked]=React.useState([])
    const[expanded,setExpanded]=React.useState([])
    const[filterText,setFilterText]=React.useState("");
    const [showSearchNodes,setShowSearchNodes]=React.useState(false)


    const handleClose = () => {
        setAnchorEl(null);
    };
    const screenSize = useWindowSize()
    const theme = useTheme();

    const navigate = useNavigate()
    const [listChips,setListChips]=React.useState([])
    const [openMetierMenu,setOpenMetierMenu]=React.useState(false)
    const [selectedCat,setSelectedCat]=React.useState([])
    const [openNewMembreModal,setOpenMembreModal]=React.useState(false)
    const [selectedMetier,setSelectedMetier]=React.useState("Designer de mode :")

    const [Filtred_nodes,setFiltred_nodes]=React.useState([])

    const [loading, setLoading] = React.useState(true);
    const [annuaires,setAnnuaires]=React.useState([]);
    const [newMembre,setNewMembre]=React.useState({
        nom:"",
        prenom:"",
        email:"",
        phone:"",
        typeMetier:"",
        metiers:[],
        secondSkills:[],
        instagramUrl:"",
        linkedinUrl:"",
        facebookUrl:"",
        created_at:"",
        updatedAt:"",
    })
    const [ sousMetiers,setSousMetiers]=React.useState([])
    const [metiers,setMetiers]=React.useState([])
    const [afficheData,setAfficheData]=React.useState({})


    const handleChangeCat=(item)=>{
        setLoading(true)
        console.log(item)

        let findIndex=selectedCat.findIndex(element=>element.toString().trim()===item.toString().trim())
        console.log(findIndex)
        if (findIndex===-1){
            setSelectedCat(oldArray => [...oldArray, item.trim()]);

        }else {
            console.log(selectedCat.filter(element=>element.trim()!==item.trim()))
            setSelectedCat(selectedCat.filter(element=>element.trim()!==item.trim()))

        }

        console.log(selectedCat)
        prepareData(true)
        setLoading(false)


    }
    const getSousMetier = (array,name)=>{

        return Object.keys(array[name])
    }



    const prepareData =(role,annuaireData)=>{
        console.log(role)
        if (role===false){
            let distinctMetier =Object.keys(dataAnnuaire)
            let sousMetier = getSousMetier(dataAnnuaire,distinctMetier[0])
            let afficheD=afficheData
            console.log(annuaireData)
            console.log(afficheD)
            sousMetier.map((item,key)=>{
                afficheD[item]=annuaireData.filter(contact=>contact.metiers.findIndex(met=>met.toString().trim()===item.toString().trim())!==-1)
            })

            setMetiers(distinctMetier)
            setSousMetiers(sousMetier)
            setAfficheData(afficheD)

        }else {

            let afficheD=afficheData
            sousMetiers.map((item,key)=>{
                afficheD[item]=annuaires.filter(contact=>contact.metiers.findIndex(met=>met.toString().trim()===item.toString().trim())!==-1)
            })
            console.log(afficheD)
            setAfficheData(afficheD)
        }




    }
    useEffect(() => {
        getAnnuaire()
    }, [])

    const getAnnuaire=()=>{
        setLoading(true)
        apiNodeService.getTableData({db:"FREELANCE_MAD",table: "annuaire"}).then((res)=>{
            console.log(res)
            if (res&&res.status === 200){
                setAnnuaires([...res.data])
                console.log(res)
                prepareData(false,res.data)
            }
            setLoading(false)
        })
    }

    const handleChangeMetier = (name) => {
        setSelectedMetier(name)
        setSelectedCat([])
        setSousMetiers(getSousMetier(dataAnnuaire,name))

    }
    const handleChangeNewMembre=(e,name)=>{
        if (name!=="metier"){
            setNewMembre(params => ({
                ...params,             // Reuse the previous properties
                [name]: e.target.value
            }));
        }else if (name==="metier"){
            let metiers = newMembre.metiers
            metiers.push(e.target.value)
            setNewMembre(params => ({
                ...params,             // Reuse the previous properties
                [name]: metiers
            }));
        }

    }


    const addnewContact =()=>{
        setLoading(true)
        let newContact= newMembre
        //setAnnuaires((oldarray)=>[...oldarray,newContact])
        apiNodeService.insertTableData({ db: "FREELANCE_MAD", table: "annuaire", data: newContact }).then(resAdd => {
            if (resAdd&&resAdd===true){
                getAnnuaire()
                toast.success(newContact.nom+" "+newContact.prenom+" a ete ajouter avec succès")
                setLoading(false)
                setNewMembre({
                    nom:"",
                    prenom:"",
                    email:"",
                    phone:"",
                    typeMetier:"",
                    metiers:[],
                    secondSkills:[],
                    instagramUrl:"",
                    linkedinUrl:"",
                    facebookUrl:"",
                    created_at:"",
                    updatedAt:"",
                })
                setOpenMembreModal(false)
            }else{
                toast.error("Erreur ajout contact, réessayer ultérieurement")

                setLoading(false)
            }
        })



    }
    const getSearchedValues = (nodes, keyword) => {

        const values = [];
        for (let n of nodes) {
            if (n.label.toLowerCase().includes(keyword.toLowerCase()) && n.showCheckbox !== false) values.push(n.value);
            if (n.children) {
                values.push(...getSearchedValues(n.children, keyword));
            }
        }
        return values;
    }

    const onSearchInputChange_v2 = (event, nodes) => {
        setFilterText(event.target.value)
        setShowSearchNodes(true)
        let filtred_nodes = getSearchedValues(nodes, event.target.value)
        setFiltred_nodes(filtred_nodes)
    };

    const renderInternal = (contact)=>{
        return<Checkbox size="small" onChange={(e)=>{
            handleChangeCheckboxInternal(contact,e)
        }} checked={contact.internal=="true"?true:false}  />

    }

    const renderName =(contact)=> {
        return <Typography variant={"subtitle1"} >
            {contact.nom + " "+contact.prenom}
        </Typography>
    }

    const renderFirstSkills =(contact)=> {
        return <div style={{display:"flex"}}>
            {contact.metiers?contact.metiers.map((item)=>(
                <Chip size="small" label={item} className="mr-1" />

            )):<text>
                No first skills
            </text>
            }
        </div>
    }

    const renderSecondSkills =(contact)=> {
        return <div style={{display:"flex"}}>
            {contact.secondSkills?contact.secondSkills.map((item)=>(
                <Chip size="small" label={item} className="mr-1" />

            )):<text>
                No second skills
            </text>
            }
        </div>
    }

    const handleChangeCheckboxInternal=(contact,e)=>{
        setLoading(true)
        let ann = annuaires
        let newContact=annuaires.find(element=>element.id===contact.id)
        let indexNewContact=annuaires.findIndex(element=>element.id===contact.id)

        newContact.internal=e.target.checked.toString()
        console.log(newContact)
        rethink.update("test",'table("annuaire").get('+JSON.stringify(newContact.id)+').update('+ JSON.stringify(newContact) + ')',db_name,false).then( updateRes => {
            console.log(updateRes)
            if (updateRes && updateRes === true) {
                prepareData(true)
                setLoading(false)
            } else {
                setLoading(false)
            }
        }).catch(err => {
            console.log(err)
        })


    }

    return(
        <div>
            <MuiBackdrop open={loading} text={"Chargement..."}/>
            <div className="container container-lg"
                 style={{marginTop: 60, height: screenSize.height, overflowY: "auto"}}
            >
                <div className="row" style={{marginTop: 20}}>
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <div style={{display:"flex",justifyContent:"space-between"}}>
                                    <h4 className="mb-0">
                                        Annuaires
                                    </h4>
                                    <Avatar onClick={()=>{setOpenMembreModal(true)}} sx={{ bgcolor: blue[400] ,opacity:0.7, width: 24, height: 24 }} variant="rounded"
                                            style={{alignSelf:"center",marginRight:15,cursor:"pointer"}} >
                                        <AddIcon fontSize="small" />
                                    </Avatar>
                                </div>



                                <div className="row justify-content-center">
                                    <div className="col-md-2">
                                        <MuiButton onClick={()=>handleChangeMetier("Artisan d'art : ")} size="small" variant="contained" style={{backgroundColor:"#CDF5F6",borderRadius:100}}>
                                            Artisan d'art
                                        </MuiButton>
                                    </div>
                                    <div className="col-md-2">
                                        <MuiButton size="small" variant="contained" style={{backgroundColor:"#e6e6e6",borderRadius:100}}>
                                            Matière travaillee
                                        </MuiButton>
                                    </div>
                                    <div className="col-md-2">
                                        <MuiButton
                                            style={{borderRadius:100}}
                                            size="small" variant="contained"
                                            id="demo-positioned-button"
                                            aria-controls={open ? 'demo-positioned-menu' : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={open ? 'true' : undefined}
                                            onClick={()=>setOpenMetierMenu(true)}
                                        >
                                            Autre metier
                                        </MuiButton>

                                    </div>


                                </div>

                                <div className="mt-3 text-center" style={{display:'flex',justifyContent:"center"}}>
                                    <IconButton size="small" color="primary" aria-label="upload picture"onClick={()=>{
                                        let element = document.getElementById('chipScroll')
                                        element.scrollBy(-30, 0);
                                    }} component="label">
                                        <ChevronLeftIcon fontSize="small"/>

                                    </IconButton>
                                    <div id='chipScroll'>
                                        {sousMetiers.map((item,key)=>(
                                            <Chip size="small" color="default" style={{marginLeft:10,backgroundColor:selectedCat.findIndex(element=>element.trim()===item.trim())!==-1?colors[key].hex:null}} label={item} variant={selectedCat.findIndex(element=>element.trim()===item.trim())!==-1?"contained":"outlined"} onClick={()=>{handleChangeCat(item)}} />

                                        ))}
                                    </div>

                                    <IconButton size="small" color="primary" aria-label="upload picture"onClick={()=>{
                                        let element = document.getElementById('chipScroll')
                                        element.scrollBy(30, 0);
                                    }} component="label">
                                        <ChevronRightIcon fontSize="small"/>

                                    </IconButton>

                                </div>



                                {selectedCat.length===0?sousMetiers.map((item,key)=>(
                                    <div>
                                        <div className="mt-3" style={{display:'flex'}}>
                                            <div className="p-1  text-center" style={{minWidth:150,backgroundColor:colors[key].hex}}>
                                                <h5 style={{color:"white"}}>{item}</h5>
                                            </div>

                                        </div>
                                        {afficheData[item]&&afficheData[item].length>0?
                                            <DataTable value={afficheData[item]} tableStyle={{ minWidth: '50rem' }}>
                                                <Column body={renderName} header="Name"></Column>
                                                <Column field="email" header="Email"></Column>
                                                <Column  header="Internal" body={renderInternal}></Column>
                                                <Column  header="First Skills" body={renderFirstSkills}></Column>
                                                <Column  header="Second Skills" body={renderSecondSkills}></Column>

                                            </DataTable>:
                                            <div className="text-left mt-2">
                                                <h5>
                                                    Aucun contact trouvé
                                                </h5>
                                            </div>

                                        }
                                    </div>
                                )):selectedCat.map((item,key)=>(
                                    <div>
                                        <div className="mt-3" style={{display:'flex'}}>
                                            <div className="p-1 text-center  " style={{minWidth:160,backgroundColor:colors[key].hex}}>
                                                <h5 style={{color:"white"}}>{item}</h5>
                                            </div>
                                            <hr style={{width:"90%"}} className="ml-auto"/>

                                        </div>
                                        {afficheData[item]&&afficheData[item].length>0?
                                            <DataTable value={afficheData[item]} tableStyle={{ minWidth: '50rem' }}>
                                                <Column field="image" header="image"></Column>

                                                <Column body={renderName} header="Name"></Column>
                                                <Column field="phone" header="telephone"></Column>

                                                <Column field="email" header="Email"></Column>
                                                <Column  header="Internal" body={renderInternal}></Column>

                                                <Column  header="First Skills" body={renderFirstSkills}></Column>
                                                <Column  header="Second Skills" body={renderSecondSkills}></Column>


                                            </DataTable>:
                                            <div className="text-left mt-2">
                                                <h5>
                                                    Aucun contact trouvé
                                                </h5>
                                            </div>

                                        }
                                    </div>
                                ))}


                                {/*
                                    !annuaires ?
                                        <ShimmerTable row={2} /> :

                                        annuaires && annuaires.length === 0 ?
                                            <div style={{marginTop: 30}}>
                                                <h5 style={{color: "grey"}}>Aucun modele encore crée par vous</h5>
                                            </div> :

                                            <div id="cardCollpase4" className="collapse pt-3 show">
                                                <div className="table-responsive">
                                                    <table style={{padding:"0.65rem"}}
                                                           className="table table-centered table-borderless mb-0">
                                                        <thead style={{
                                                            backgroundColor: grayColor,
                                                            color: "gray",
                                                            fontWeight: "normal"
                                                        }}>
                                                        <tr>
                                                            <th>Image</th>
                                                            <th>Nom</th>
                                                            <th className="text-center">Description</th>
                                                            <th>Créer par</th>
                                                            <th className="text-center">Crée le</th>
                                                            <th className="text-center">Options</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {
                                                            annuaires && annuaires.map((item, key) => (
                                                                <tr key={key} style={{cursor: "pointer"}}
                                                                    onClick={() => {
                                                                        navigate("/home/plans/list/" + item.id,{state:{plan:item}})
                                                                    }}
                                                                >
                                                                    <td style={{cursor: "pointer"}}>
                                                                        {
                                                                            item.thumbnail &&
                                                                            <img alt="" src={item.thumbnail.prefix + item.thumbnail.data} style={{width:50,height:50,objectFit:"cover"}}/>
                                                                        }

                                                                    </td>
                                                                    <td style={{cursor: "pointer",minWidth:125}}>
                                                                        <h5>{item.name}</h5>
                                                                    </td>
                                                                    <td style={{cursor: "pointer",minWidth:100,textAlign:"center"}}>
                                                                        <p>{item.desc}</p>
                                                                    </td>
                                                                    <td style={{cursor: "pointer",minWidth:125,textAlign:"center"}}>
                                                                        <RenderUserAvatar email={item.created_by === localStorage.getItem("email") ? "Vous" : item.created_by} />
                                                                    </td>
                                                                    <td style={{minWidth:100,textAlign:"center"}}>
                                                                        <p>{moment(item.created_at).format("DD-MM-YYYY HH:mm")}</p>
                                                                    </td>

                                                                    <td className="text-center">
                                                                        <Popup
                                                                            size={"mini"}
                                                                            content={
                                                                                <div style={{borderRadius:10}}>
                                                                                    <MenuGroup
                                                                                        maxWidth={150}
                                                                                        minWidth={150}
                                                                                        onClick={(e) => e.stopPropagation()}
                                                                                    >
                                                                                        <Section>
                                                                                            <ButtonItem
                                                                                                iconBefore={<EditIcon primaryColor="gray" label=""/>}
                                                                                            >
                                                                                                Modifier
                                                                                            </ButtonItem>
                                                                                            <ButtonItem
                                                                                                onClick={() => {
                                                                                                }}
                                                                                                iconBefore={<TrashIcon primaryColor="red" label=""/>}
                                                                                            >
                                                                                                Supprimer
                                                                                            </ButtonItem>
                                                                                        </Section>
                                                                                    </MenuGroup>
                                                                                </div>
                                                                            }
                                                                            on='click'
                                                                            position='bottom left'
                                                                            popper={{id: 'popper-container', style: {zIndex: 2000}}}
                                                                            trigger={
                                                                                <div style={{cursor: "pointer"}}>
                                                                                    <IconButton>
                                                                                        <MoreHorizIcon/>
                                                                                    </IconButton>
                                                                                </div>
                                                                            }
                                                                        />

                                                                    </td>

                                                                </tr>
                                                            ))
                                                        }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                               */ }



                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Dialog
                open={openNewMembreModal}
                aria-labelledby="form-dialog-title"
                fullWidth={true}
                maxWidth="sm"

            >
                <DialogTitle disableTypography id="form-dialog-title">
                    <Typography variant="h6">Créer un nouveau contact </Typography>
                    <IconButton
                        aria-label="close"
                        style={{
                            position: 'absolute',
                            right: 5,
                            top: 5,
                            color: '#c0c0c0'
                        }}
                        onClick={() => {
                            setOpenMembreModal(false)
                        }}
                    >
                        <CloseIcon/>
                    </IconButton>
                </DialogTitle>
                <DialogContent style={{overflowY: "inherit"}}>

                    <div className="row mt-1 align-items-center justify-content-around">
                        <div className="col-md-6">
                            <TextField  fullWidth value={newMembre.nom} onChange={(e)=>{handleChangeNewMembre(e,"nom")}} size="small" id="outlined-basic" label="Nom" variant="outlined" />

                        </div>
                        <div className="col-md-6">
                            <TextField fullWidth value={newMembre.prenom} onChange={(e)=>{handleChangeNewMembre(e,"prenom")}} size="small" id="outlined-basic" label="Prénom" variant="outlined" />

                        </div>

                    </div>
                    <div className="row mt-1 align-items-center justify-content-around">
                        <div className="col-md-6">
                            <TextField fullWidth value={newMembre.email} onChange={(e)=>{handleChangeNewMembre(e,"email")}}  size="small" id="outlined-basic" label="Email" variant="outlined" />

                        </div>
                        <div className="col-md-6">
                            <TextField fullWidth value={newMembre.phone} onChange={(e)=>{handleChangeNewMembre(e,"phone")}} type="number"  size="small" id="outlined-basic" label="Telephone" variant="outlined" />

                        </div>

                    </div>
                    <div className="row mt-2 justify-content-around align-items-start">

                        <div className="col-md-12">
                            <FormControl sx={{ width:"100%" }} size={"small"}>
                                <InputLabel id="demo-multiple-chip-label">Metier</InputLabel>
                                <Select
                                    labelId="demo-multiple-chip-label"
                                    id="demo-multiple-chip"


                                    multiple
                                    value={newMembre.metiers}
                                    onChange={(e)=>{
                                        handleChangeNewMembre(e,'metier')
                                    }}
                                    input={<OutlinedInput id="select-multiple-chip" label="Metier" />}
                                    renderValue={(selected) => (
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5,maxHeight:100,overflow:"auto" }}>
                                            {newMembre.metiers.map((value) => (
                                                <Chip size="small" key={value} label={value} />
                                            ))}
                                        </Box>
                                    )}
                                    MenuProps={MenuProps}
                                >
                                    <CheckboxTree
                                        nodes={nodes}
                                        checked={newMembre.metiers}
                                        expanded={expanded}
                                        onCheck={checked => {
                                            let newMembr=newMembre
                                            newMembr.metiers=checked
                                            console.log(checked)
                                            console.log(newMembr)
                                            setNewMembre(newMembr)
                                            setChecked(checked)
                                        }}
                                        onExpand={expanded => setExpanded(expanded)}
                                        noCascade={false}
                                        iconsClass="fa5"
                                        showNodeIcon={false}
                                        icons={{
                                            check: <CheckBoxIcon color="primary" style={{marginTop: -5}}/>,
                                            uncheck: <CheckBoxOutlineBlankIcon style={{marginTop: -5}}/>
                                        }}
                                    />
                                </Select>
                            </FormControl>
                        </div>

                        <div className="col-md-12 mt-2">
                            <FormControl sx={{ width:"100%" }} size={"small"}>
                                <InputLabel id="demo-multiple-chip-label">Second Skills</InputLabel>
                                <Select
                                    labelId="demo-multiple-chip-label"
                                    id="demo-multiple-chip"
                                    label="Second Skills"
                                    multiple
                                    value={newMembre.secondSkills}
                                    onChange={(e)=>{
                                        handleChangeNewMembre(e,'secondSkills')
                                    }}
                                    input={<OutlinedInput id="select-multiple-chip" label="Second Skills" />}
                                    renderValue={(selected) => (
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5,maxHeight:100,overflow:"auto" }}>
                                            {newMembre.secondSkills.map((value) => (
                                                <Chip size="small" key={value} label={value} />
                                            ))}
                                        </Box>
                                    )}
                                    MenuProps={MenuProps}
                                >
                                    <CheckboxTree
                                        nodes={nodes}
                                        checked={newMembre.secondSkills}
                                        expanded={expanded}
                                        onCheck={checked => {
                                            let newMembr=newMembre
                                            newMembr.secondSkills=checked
                                            setNewMembre(newMembr)
                                            setChecked(checked)}}
                                        onExpand={expanded => setExpanded(expanded)}
                                        noCascade={false}
                                        iconsClass="fa5"
                                        showNodeIcon={false}
                                        icons={{
                                            check: <CheckBoxIcon color="primary" style={{marginTop: -5}}/>,
                                            uncheck: <CheckBoxOutlineBlankIcon style={{marginTop: -5}}/>
                                        }}
                                    />
                                </Select>
                            </FormControl>
                        </div>





                    </div>
                    <div className="row mt-2 justify-content-around align-items-center">
                        <div className="col-md-6">
                            <TextField
                                id="input-with-icon-textfield"
                                label="Linkedin"
                                value={newMembre.linkedinUrl}
                                size="small"
                                fullWidth
                                onChange={(e)=>{handleChangeNewMembre(e,"linkedinUrl")}}

                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <LinkedInIcon/>
                                        </InputAdornment>
                                    ),
                                }}
                                variant="outlined"
                            />
                        </div>
                        <div className="col-md-6">
                            <TextField
                                id="input-with-icon-textfield"
                                label="Instagram"
                                fullWidth
                                value={newMembre.instagramUrl}
                                size="small"
                                onChange={(e)=>{handleChangeNewMembre(e,"instagramUrl")}}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <InstagramIcon/>
                                        </InputAdornment>
                                    ),
                                }}
                                variant="outlined"
                            />

                        </div>
                    </div>
                </DialogContent>
                <DialogActions style={{padding: 20}}>
                    <MuiButton

                        color="primary"
                        style={{textTransform: 'capitalize'}}
                    >
                        Annuler
                    </MuiButton>
                    <MuiButton
                        onClick={()=>{addnewContact()}}


                        color="primary"
                        variant="contained"
                        style={{textTransform: 'capitalize', color: "#fff"}}
                    >
                        Créer
                    </MuiButton>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openMetierMenu}
                onClose={() => {
                    this.setState({openNewRoomModal: !this.state.openNewRoomModal});
                }}
                aria-labelledby="form-dialog-title"
                fullWidth={true}
                maxWidth="sm"
                PaperProps={{
                    sx: {


                        overflowY:"auto",
                        height:"70vh"
                    }
                }}

            >
                <DialogTitle disableTypography id="form-dialog-title">
                    <Typography variant="h5" >Select metier</Typography>
                    <IconButton
                        aria-label="close"
                        style={{
                            position: 'absolute',
                            right: 5,
                            top: 5,
                            color: '#c0c0c0'
                        }}
                        onClick={() => {
                            setOpenMetierMenu(false)
                        }}
                    >
                        <CloseIcon/>
                    </IconButton>
                </DialogTitle>
                <DialogContent style={{overflowY: "auto"}}>

                    <div className="col-lg-12 mb-4">
                        <input className="form-control"
                               placeholder={"Chercher..."}
                               style={{
                                   height: 37,
                                   border: "0.0625rem solid rgb(217, 220, 226)",
                                   boxShadow: "0 4px 10px 0 rgba(0,0,0,.15)",
                                   borderRadius: 7.5
                               }}
                               value={filterText || ""}
                               onChange={(event) => {
                                   //this.onSearchInputChange(event, searchedNodes);
                                   onSearchInputChange_v2(event, nodes);
                               }}
                        />
                    </div>

                    {
                        showSearchNodes && filterText.trim() !== "" ?
                            Filtred_nodes.map((item, key) => (
                                <FormGroup style={{marginLeft: 15}}>
                                    <FormControlLabel
                                        control={<Checkbox
                                            checked={ selectedCat.findIndex(x => x.trim() === item.trim()) > -1 ?true: false}
                                            onChange={(e) => {
                                                if (selectedCat.findIndex(x => x.trim() === item.trim()) === -1){
                                                    setSelectedCat(oldArray => [...oldArray, item.trim()]);

                                                }
                                                else {
                                                    let checks=[...selectedCat]
                                                    let find_index = checks.findIndex(x => x.trim() === item.trim())
                                                    console.log(checks)

                                                    setSelectedCat(olds =>{
                                                        return olds.filter(old=>old.trim()!==item.trim())
                                                    })


                                                }
                                            }}
                                            name={"checked-item-" + key}/>}
                                        label={item }
                                        style={{color: "#6C757D", fontSize: 14, marginBottom: -7}}
                                    />
                                </FormGroup>
                            )):
                            <CheckboxTree
                                nodes={nodes}
                                checked={selectedCat.map((item)=>item+" ")}
                                expanded={expanded}
                                onCheck={(checked,checkNode) =>{
                                    handleChangeCat(checkNode.label.trim())
                                }}
                                onExpand={expanded => setExpanded(expanded)}
                                noCascade={false}
                                iconsClass="fa5"
                                showNodeIcon={false}
                                icons={{
                                    check: <CheckBoxIcon color="primary" style={{marginTop: -5}}/>,
                                    uncheck: <CheckBoxOutlineBlankIcon style={{marginTop: -5}}/>
                                }}
                            />
                    }



                </DialogContent>
                <DialogActions style={{padding: 20}}>
                    <MuiButton

                        color="primary"
                        style={{textTransform: 'capitalize'}}
                    >
                        Annuler
                    </MuiButton>
                    <MuiButton

                        onClick={()=>{setOpenMetierMenu(false)}}
                        color="primary"
                        variant="contained"
                        style={{textTransform: 'capitalize', color: "#fff"}}
                    >
                        Confirmer
                    </MuiButton>
                </DialogActions>
            </Dialog>
        </div>
    )
}
