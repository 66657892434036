import React from "react";
import './menu.css'
import { Link } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import { textTitleColor, version } from "../../constants/defaultValues";
import Alert from '@mui/material/Alert';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

export default class Menu1 extends React.Component {


    state = {
        active_item: this.props.active_item,
        isComplet: false
    }

    componentDidMount() {
        let arrow = document.querySelectorAll(".arrow");
        for (var i = 0; i < arrow.length; i++) {
            arrow[i].addEventListener("click", (e) => {
                let arrowParent = e.target.parentElement.parentElement;
                arrowParent.classList.toggle("showMenu");
            });
        }
    }


    render() {

        if (this.props.openDrawerMenu === true) {
            let arrow = document.querySelectorAll(".arrow");
            for (var i = 0; i < arrow.length; i++) {
                arrow[i].addEventListener("click", (e) => {
                    let arrowParent = e.target.parentElement.parentElement;
                    arrowParent.classList.toggle("showMenu");
                });
            }
        }

        return (
            <>
                <div className="sidebar close"
                    style={{
                        backgroundColor: this.props.background_color, borderRight: "1px solid #E8E9EB",
                        visibility: this.props.isMobile === false ? "unset" : "hidden"
                    }}
                >
                    <div className="logo-details mt-2">
                        {this.props.openDetailMenu ?
                            <div>
                                <span style={{ fontSize: 36, color: '#000', fontWeight: 700 }}>Build.</span>
                                <span style={{ fontSize: 36, color: '#214dcb', fontWeight: 700 }}>Mada</span>
                            </div> :
                            <div>
                                <span style={{ fontSize: 30, color: '#000', fontWeight: 700 }}>B.</span>
                                <span style={{ fontSize: 30, color: '#214dcb', fontWeight: 700 }}>M</span>
                            </div>
                        }
                    </div>
                    <ul className="nav-links" style={{ fontFamily: 'Montserrat,sans-serif' }}>
                        {
                            this.props.items.map((item, key) => (
                                <li key={key} style={{ backgroundColor: this.props.active_item === key + 1 ? "#F5FBFF" : "inherit", marginLeft: this.props.size === "big" ? 1 : -7, borderLeft: this.props.active_item === key + 1 ? "2px solid #8B3130" : "" }}
                                    className={(this.props.active_item === key + 1 && this.props.size === "big") ? "s_menu_item" : ""}
                                    onClick={() => {
                                        console.log(this.props.isLocked)
                                        if (this.props.isLocked === false) {
                                            this.props.setActiveMenuItem(key + 1)
                                            this.props.setIsProfilePath(false)
                                            this.setState({ isComplet: false })
                                        } else {
                                            this.setState({ isComplet: true })
                                        }

                                    }}
                                >
                                    {
                                        item.childrens && item.childrens.length > 0 ?
                                            <div className="iocn-link">
                                                <Link style={{ cursor: "pointer" }} to={item.route}>
                                                    <i className={item.icon} style={{ color: this.props.active_item === key + 1 ? textTitleColor : "#fff" }} />
                                                    <span className="link_name" style={{ color: this.props.active_item === key + 1 ? textTitleColor : "#fff" }}>{item.title}</span>
                                                </Link>
                                                <i className='bx bxs-chevron-down arrow' style={{ color: this.props.active_item === key + 1 ? textTitleColor : "#fff" }} />
                                            </div> :
                                            <>
                                                {item.role.includes(localStorage.getItem("role")) &&
                                                    <Link style={{ cursor: "pointer" }} to={this.props.isLocked === false ? item.route : "rh-employe?user=" + localStorage.getItem('id')} onClick={() => { this.props.setOpenDetailMenu(item) }}>
                                                        <i className={item.icon} style={{ color: this.props.active_item === key + 1 ? "#004671" : "#3F434A" }} />
                                                        <span className="link_name"
                                                            style={{ color: this.props.active_item === key + 1 ? "#004671" : "#3F434A", fontWeight: this.props.active_item === key + 1 ? 700 : 400 }}
                                                        >{item.title}</span>
                                                    </Link>
                                                }
                                            </>

                                    }

                                    <ul className={item.childrens && item.childrens.length > 0 ? "sub-menu" : "sub-menu blank"}>
                                        <li>
                                            <Link className="link_name" style={{ color: "#004671", cursor: "pointer", fontSize: "1.05rem" }}
                                                to={item.route}>{item.title}</Link>
                                        </li>
                                        {
                                            (item.childrens || []).map((child, k) => (
                                                <li style={{ cursor: "pointer", margin: 5 }} key={k}>
                                                    <Link style={{ fontSize: "1.05rem", color: textTitleColor }} to={child.route}>{child.title}</Link>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </li>
                            ))
                        }
                        {(this.props.isLocked === true && this.state.isComplet === true) &&
                            <Alert
                                iconMapping={{
                                    info: <InfoOutlinedIcon style={{ color: "red" }} />,
                                }}
                                style={{ border: "2px solid #red", backgroundColor: "#FFE2E2", borderRadius: 10, alignItems: 'center', marginLeft: -12 }} severity="error">Veuillez remplir toutes les données pour accéder.

                            </Alert>
                        }
                        {/* { this.props.isProfilePath === false &&
                        <div style={{ position: "absolute", bottom: 10, width: "100%" }}>
                            {
                                this.props.items1.map((item, key) => (
                                    <li key={key} style={{ marginLeft: this.props.size === "big" ? 1 : -7 }}
                                        className={(this.state.active_item === key + 1 && this.props.size === "big") ? "s_menu_item" : ""}
                                    >
                                        {
                                            item.childrens && item.childrens.length > 0 ?
                                                <div className="iocn-link">
                                                    <Link style={{ cursor: "pointer" }} to={item.route}>
                                                        <i className={item.icon} style={{ color: this.state.active_item === key + 1 ? textTitleColor : "#fff" }} />
                                                        <span className="link_name" style={{ color: this.state.active_item === key + 1 ? textTitleColor : "#fff" }}>{item.title}</span>
                                                    </Link>
                                                    <i className='bx bxs-chevron-down arrow' style={{ color: this.state.active_item === key + 1 ? textTitleColor : "#fff" }} />
                                                </div> :
                                                <Link style={{ cursor: "pointer" }} to={item.route} onClick={() => { this.props.setOpenDetailMenu(item) }}>
                                                    <i className={item.icon} style={{ color: "#004671" }} />
                                                    <span className="link_name"
                                                        style={{ color: "#004671", fontWeight: 700 }}
                                                    >{item.title}</span>
                                                </Link>
                                        }

                                        <ul className={item.childrens && item.childrens.length > 0 ? "sub-menu" : "sub-menu blank"}>
                                            <li>
                                                <Link className="link_name" style={{ color: "#004671", cursor: "pointer", fontSize: "1.05rem" }}
                                                    to={item.route}>{item.title}</Link>
                                            </li>
                                            {
                                                (item.childrens || []).map((child, k) => (
                                                    <li style={{ cursor: "pointer", margin: 5 }} key={k}>
                                                        <Link style={{ fontSize: "1.05rem", color: textTitleColor }} to={child.route}>{child.title}</Link>
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                    </li>
                                ))

                            }
                        </div>
                        } */}

                        {/* <li>
                            <p className="version-text">{version}</p>
                        </li> */}

                    </ul>
                </div>

                <Drawer anchor={"left"} open={this.props.openDrawerMenu} onClose={() => {
                    this.props.setOpenDrawerMenu()
                }}
                >
                    <div className="sidebar"
                        style={{ backgroundColor: this.props.background_color }}
                    >
                        <div className="logo-details">
                            {
                                this.props.show_logo === true &&
                                <div>
                                    <span style={{ fontSize: 36, color: '#000', fontWeight: 700 }}>Build.</span>
                                    <span style={{ fontSize: 36, color: '#214dcb', fontWeight: 700 }}>Mada</span>
                                </div>
                            }
                        </div>
                        <ul className="nav-links">
                            {
                                this.props.items.map((item, key) => (
                                    <li key={key} style={{ backgroundColor: this.state.active_item === key + 1 ? "#fff" : "inherit" }}
                                        onClick={() => { this.setState({ active_item: key + 1 }) }}
                                    >
                                        {
                                            item.childrens && item.childrens.length > 0 ?
                                                <div className="iocn-link">
                                                    <Link style={{ cursor: "pointer" }} to={item.route}>
                                                        <i className={item.icon} style={{ color: this.state.active_item === key + 1 ? textTitleColor : "#fff" }} />
                                                        <span className="link_name" style={{ color: this.state.active_item === key + 1 ? textTitleColor : "#fff" }}>{item.title}</span>
                                                    </Link>
                                                    <i className='bx bxs-chevron-down arrow' style={{ color: this.state.active_item === key + 1 ? textTitleColor : "#fff" }} />
                                                </div> :
                                                <Link style={{ cursor: "pointer" }} to={item.route} onClick={() => { this.props.setOpenDetailMenu(item) }}>
                                                    <i className={item.icon} style={{ color: this.state.active_item === key + 1 ? "#004671" : "#3F434A" }} />
                                                    <span className="link_name"
                                                        style={{ color: this.state.active_item === key + 1 ? "#004671" : "#3F434A", fontWeight: this.state.active_item === key + 1 ? 700 : 400 }}
                                                    >{item.title}</span>
                                                </Link>
                                        }

                                        <ul className={item.childrens && item.childrens.length > 0 ? "sub-menu" : "sub-menu blank"}>
                                            <li>
                                                <Link className="link_name" style={{ color: "#000", cursor: "pointer", fontSize: "1.05rem" }}
                                                    to={item.route}>{item.title}</Link>
                                            </li>
                                            {
                                                (item.childrens || []).map((child, k) => (
                                                    <li style={{ cursor: "pointer", margin: 5 }} key={k}>
                                                        <Link style={{ fontSize: "1.05rem" }} to={child.route}>{child.title}</Link>
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                    </li>
                                ))
                            }

                        </ul>
                    </div>

                </Drawer>

            </>
        )

    }





}
