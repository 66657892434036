import "./Freelance_List.css"
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import React, { useEffect, useRef, useState } from "react";
import { apiNodeService } from "../../provider/apiNodeService";
import VisibilityIcon from '@mui/icons-material/Visibility';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import InputAdornment from '@mui/material/InputAdornment';
import { Button as MuiButton, Chip } from '@material-ui/core'
import IconButton from "@material-ui/core/IconButton";
import MuiBackdrop from "../../components/Loading/MuiBackdrop";
import updateImg from "../../assets/images/updateimg.png"
import utilFunctions from "../../tools/functions";
import formations from "../../data/formation"
import ecoles from "../../data/ecole"
import niveauEtude from "../../data/niveauEtude"
import countries from "../../data/countries"
import etudiants from "../../data/etudiants"
import TreeList from "./TreeList";
import Competence from "./Competence";
import Alert from '@mui/material/Alert';
import Avatar from '@mui/material/Avatar';
import moment from 'moment';
import Autocomplete from '@mui/material/Autocomplete';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import * as XLSX from 'xlsx';
import Menu from '@mui/material/Menu';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const langues = [
    "Français Maternelle",
    "Français courant",
    "Français moyen"
];

const langues2 = [
    "Anglais courant",
    "Anglais moyen"
];

function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

const Freelance_List = () => {

    const theme = useTheme();
    const [openNewMembreModal, setOpenMembreModal] = React.useState(false)
    const [openUpdateMembreModal, setOpenUpdateMembreModal] = React.useState(false)

    const [selectedProducts, setSelectedProducts] = React.useState(null);
    const [loading, setLoading] = React.useState(true);
    const [loadingProfil, setLoadingProfil] = React.useState(true);
    const [openProfilModal, setOpenProfilModal] = React.useState(false)
    const [annuaires, setAnnuaires] = React.useState([]);
    const [step, setStep] = React.useState(1);
    const [listFormation, setListFormation] = React.useState([])
    const [listMetier, setListMetier] = React.useState([])
    const [profilDetail, setProfilDetail] = React.useState({})
    const [dataEc, setDataEc] = React.useState(ecoles.ecole)
    const [dataNiveauEtude, setDataNiveauEtude] = React.useState(niveauEtude.niveauEtude)

    const [newMembre, setNewMembre] = React.useState({
        nom: "",
        prenom: "",
        email: "",
        phone: "",
        niveau: "",
        niveauActuelle: "",
        tauxH: "",
        ecole: "",
        langue: [],
        codep: "",
        adress: "",
        ville: "",
        pays: "",
        created_at: "",
        photo: "",
        metiers: [],
        formations: [],
        nbExperience: 0,
        linkedinUrl: ""
    })
    const [selectedFile, setSelectedFile] = useState("");
    const fileInputRef = useRef(null);
    const [alertSuccess, setAlertSuccess] = React.useState(false)
    const [alertError, setAlertError] = React.useState(false)
    const [langue, setLangue] = React.useState([])
    const [langue2, setLangue2] = React.useState([])
    const [selectedValues, setSelectedValues] = useState([]);
    const [requiredValues, setRequiredValues] = React.useState(false)
    const [selectedNodes, setSelectedNodes] = useState({});
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openMenu = Boolean(anchorEl);
    const [updatedMembre, setUpdatedMembre] = useState({});
    const [alertText, setAlertText] = useState("");

    const handleClickMenu = (event, row) => {
        console.log(row)
        setUpdatedMembre(row)
        setSelectedFile(row.photo)
        setLangue([row.langue[0]])
        setLangue2([row.langue[1]])
        setAnchorEl(event.currentTarget);
        setSelectedValues(row.metiers)
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };



    const handleChange = (event) => {
        console.log(event.target.value)
        const updatedObject = event.target.value.reduce((acc, currentValue) => {
            acc[currentValue] = true;
            return acc;
        }, {});
        console.log(updatedObject)
        setSelectedNodes(updatedObject)
        const arr1 = selectedValues.filter(element => element !== undefined);
        setSelectedValues(arr1)
        console.log(selectedValues)
    };

    const options = dataEc.map((option) => {
        return {
            section: option.section,
            ...option,
        };
    });

    const options1 = dataNiveauEtude.map((option) => {
        return {
            section: option.section,
            ...option,
        };
    });


    useEffect(() => {
        setDataEc(ecoles.ecole)
        setDataNiveauEtude(niveauEtude.niveauEtude)
        console.log(formations)
        getOdooEmployee()
        getAnnuaire()
        // console.log(etudiants.etudiants)
        // etudiants.etudiants.map((item)=>{
        //     addnewContact300(item.nom,item.prenom)
        // })
    }, [])

    const getOdooEmployee = () => {
        apiNodeService.getEmployeeOdoo().then(res=>{
            console.log(res)
        })
    }

    const handleChangeLangues = (event) => {
        const {
            target: { value },
        } = event;
        setLangue(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleChangeLangues2 = (event) => {
        const {
            target: { value },
        } = event;
        setLangue2(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            let thumbnail = await utilFunctions.compressImage(file, 0.4, 0.4, 300, 300, true)
            setNewMembre(params => ({
                ...params,
                ["photo"]: thumbnail
            }));
            setSelectedFile(thumbnail);
            console.log("Selected file:", thumbnail);
        } else {
            console.log("No file selected!");
        }
    };

    const handleFileChangeUpdated = async (event) => {
        const file = event.target.files[0];
        if (file) {
            let thumbnail = await utilFunctions.compressImage(file, 0.4, 0.4, 300, 300, true)
            setUpdatedMembre(params => ({
                ...params,
                ["photo"]: thumbnail
            }));
            setSelectedFile(thumbnail);
            console.log("Selected file:", thumbnail);
        } else {
            console.log("No file selected!");
        }
    };

    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    const getAnnuaire = () => {
        setLoading(true)
        apiNodeService.getTableData({ db: "FREELANCE_MAD", table: "annuaire" }).then((res) => {
            console.log(res)
            if (res && res.status === 200) {
                setAnnuaires([...res.data])
                console.log(res)
            }
            setLoading(false)
        })
    }

    const iconsTemplate = (row) => {
        return (
            <div className="d-flex" style={{ gap: 10 }}>
                <VisibilityIcon onClick={(e => {
                    setProfilDetail(row)
                    setOpenProfilModal(true)
                })}
                    style={{ color: "#1565C0", cursor: 'pointer' }} fontSize="small" />

                <MoreVertIcon fontSize="small"
                    style={{ cursor: 'pointer' }}
                    aria-controls={openMenu ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={openMenu ? 'true' : undefined}
                    onClick={(e) => {
                        handleClickMenu(e, row)
                    }} />
                <Menu
                    key={row.id}
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={openMenu}
                    onClose={handleCloseMenu}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                    PaperProps={{
                        elevation: 0,
                        sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.02))',
                            mt: 1.5,
                            '& .MuiAvatar-root': {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                            },
                            '&:before': {
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                top: 0,
                                right: 10,
                                width: 10,
                                height: 10,
                                bgcolor: 'background.paper',
                                transform: 'translateY(-50%) rotate(45deg)',
                                zIndex: 0,
                            },
                        },
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                    <MenuItem style={{ paddingLeft: 0, gap: 10 }} onClick={handleCloseMenu}>
                        <DeleteOutlineIcon style={{ color: "#EB5757" }} /> <span style={{ color: "#EB5757", fontSize: 12, fontWeight: 500 }}>Supprimer</span> </MenuItem>
                    <hr className="m-0" />
                    <MenuItem style={{ paddingLeft: 0, gap: 10 }} onClick={(e) => {
                        handleCloseMenu()
                        setOpenUpdateMembreModal(true)
                    }}><EditIcon style={{ color: "#1565C0" }} /> <span style={{ color: "#1565C0", fontSize: 12, fontWeight: 500 }}>Modifier</span> </MenuItem>

                </Menu>

            </div>
        )
    }

    const handleChangeNewMembre = (e, name, selected) => {
        console.log(selected)
        if (name !== "metier" && name !== "formation" && name !== "ecole" && name !== "pays" && name !== "niveauActuelle") {
            setNewMembre(params => ({
                ...params,
                [name]: e.target.value
            }));
        } else if (name === "pays") {
            setNewMembre(params => ({
                ...params,
                [name]: selected
            }));
        } else if (name === "ecole") {
            setNewMembre(params => ({
                ...params,
                [name]: e.target.textContent
            }));

            let data = newMembre;
            if (selected) {
                selected.section.includes("bac +2")
                    ? data.niveau = "Bac +2"
                    : selected.section.includes("Bac +3")
                        ? data.niveau = "Bac +3"
                        : selected.section.includes("Bac +5")
                            ? data.niveau = "Bac +5"
                            : selected.section.includes("Lycée")
                                ? data.niveau = "Lycée"
                                : selected.section.includes("doctorat")
                                    ? data.niveau = "Doctorat"
                                    : data.niveau = ""
                data.ecole = e.target.textContent
                setNewMembre(data)
            }
        } else if (name === "niveauActuelle") {
            setNewMembre(params => ({
                ...params,
                [name]: e.target.textContent
            }));
        } else if (name === "metier") {
            let metiers = newMembre.metiers
            metiers.push(e.target.value)
            setNewMembre(params => ({
                ...params,
                [name]: metiers
            }));
        }
    }

    const handleChangeUpdatedMembre = (e, name, selected) => {
        console.log(selected)
        if (name !== "metier" && name !== "formation" && name !== "ecole" && name !== "pays" && name !== "niveauActuelle") {
            setUpdatedMembre(params => ({
                ...params,
                [name]: e.target.value
            }));
        } else if (name === "pays") {
            setUpdatedMembre(params => ({
                ...params,
                [name]: selected
            }));
        } else if (name === "ecole") {
            setUpdatedMembre(params => ({
                ...params,
                [name]: e.target.textContent
            }));

            let data = updatedMembre;
            if (selected) {
                selected.section.includes("bac +2")
                    ? data.niveau = "Bac +2"
                    : selected.section.includes("Bac +3")
                        ? data.niveau = "Bac +3"
                        : selected.section.includes("Bac +5")
                            ? data.niveau = "Bac +5"
                            : selected.section.includes("Lycée")
                                ? data.niveau = "Lycée"
                                : selected.section.includes("doctorat")
                                    ? data.niveau = "Doctorat"
                                    : data.niveau = ""
                data.ecole = e.target.textContent
                setUpdatedMembre(data)
            }
        } else if (name === "niveauActuelle") {
            setUpdatedMembre(params => ({
                ...params,
                [name]: e.target.textContent
            }));
        } else if (name === "metier") {
            let metiers = updatedMembre.metiers
            metiers.push(e.target.value)
            setUpdatedMembre(params => ({
                ...params,
                [name]: metiers
            }));
        }
    }

    const nextStep = () => {
        if (step === 1) {
            if (newMembre.nom === "" || newMembre.prenom === "" || newMembre.codep === "" || newMembre.adress === "" || newMembre.pays === "" || newMembre.email === "" || newMembre.ville === "") {
                setRequiredValues(true)
            } else {
                setRequiredValues(false)
                setStep(step + 1)
            }
        }
        if (step === 2) {
            if (selectedValues.length === 0 || newMembre.ecole === "" || newMembre.niveauActuelle === "" || newMembre.tauxH === "" || newMembre.nbExperience === "" || langue === "") {
                setRequiredValues(true)
            } else {
                setRequiredValues(false)
                addnewContact()
            }
        }
        console.log(newMembre)
    }

    const nextStepUpdate = () => {
        if (step === 1) {
            if (updatedMembre.nom === "" || updatedMembre.prenom === "" || updatedMembre.codep === "" || updatedMembre.adress === "" || updatedMembre.pays === "" || updatedMembre.email === "" || updatedMembre.ville === "") {
                setRequiredValues(true)
            } else {
                setRequiredValues(false)
                setStep(step + 1)
            }
        }
        if (step === 2) {
            if (selectedValues.length === 0 || updatedMembre.ecole === "" || updatedMembre.niveauActuelle === "" || updatedMembre.tauxH === "" || updatedMembre.nbExperience === "" || langue === "") {
                setRequiredValues(true)
            } else {
                setRequiredValues(false)
                updateContact()
            }
        }
        console.log(updatedMembre)
    }

    const resetMembre = () => {
        setSelectedFile("")
        setSelectedValues([])
        setRequiredValues(false)
        setTimeout(() => {
            setStep(1)
        }, 1000)
        setLangue([])
        setLangue2([])
        setUpdatedMembre({})
        setNewMembre({
            nom: "",
            prenom: "",
            email: "",
            photo: "",
            phone: "",
            langue: [],
            codep: "",
            ecole: "",
            created_at: "",
            adress: "",
            ville: "",
            pays: "",
            tauxH: "",
            niveau: "",
            niveauActuelle: "",
            metiers: [],
            formations: [],
            nbExperience: 0,
            linkedinUrl: ""
        })
    }

    const addnewContact = () => {
        setLoading(true)
        let newContact = newMembre
        newContact.created_at = moment().format('YYYY-MM-DD')
        let langueData = []
        langueData.push(langue[0])
        langueData.push(langue2[0])
        newContact.langue = langueData
        newContact.metiers = selectedValues
        newContact.tauxH = newMembre.tauxH + " Ar/H"
        newContact.nbExperience = parseInt(newMembre.nbExperience) > 1 ? newMembre.nbExperience + " ans" : newMembre.nbExperience + " an"
        apiNodeService.insertTableData({ db: "FREELANCE_MAD", table: "annuaire", data: newContact }).then(resAdd => {
            console.log(resAdd)
            if (resAdd && resAdd.status === 200) {
                setAlertText("Nouveau membre à été ajouté à la liste")
                setAlertSuccess(true)
                getAnnuaire()
                setLoading(false)
                setSelectedFile("")
                setSelectedValues([])
                setLangue([])
                setLangue2([])
                setNewMembre({
                    nom: "",
                    prenom: "",
                    email: "",
                    photo: "",
                    phone: "",
                    langue: [],
                    adress: "",
                    ville: "",
                    pays: "",
                    codep: "",
                    ecole: "",
                    tauxH: "",
                    created_at: "",
                    niveauActuelle: "",
                    niveau: "",
                    metiers: [],
                    formations: [],
                    nbExperience: 0,
                    linkedinUrl: ""
                })
                setOpenMembreModal(false)
                setSelectedValues([])
                setTimeout(() => {
                    setStep(1)
                }, 1000)
                setTimeout(() => {
                    setAlertSuccess(false)
                }, 5000)
            } else {
                setAlertError(true)
                setLoading(false)
                setSelectedFile("")
                setTimeout(() => {
                    setAlertError(false)
                }, 5000)
            }
        })
    }

    const updateContact = () => {
        setLoading(true)
        let newContact = updatedMembre
        newContact.updated_at = moment().format('YYYY-MM-DD')
        let langueData = []
        langueData.push(langue[0])
        langueData.push(langue2[0])
        newContact.langue = langueData
        newContact.metiers = selectedValues
        newContact.tauxH = parseInt(updatedMembre.tauxH) + " Ar/H"
        newContact.nbExperience = parseInt(updatedMembre.nbExperience) > 1 ? parseInt(updatedMembre.nbExperience) + " ans" : parseInt(updatedMembre.nbExperience) + " an"
        apiNodeService.updateTableData({ db: "FREELANCE_MAD", table: "annuaire",id:newContact.id ,data: newContact }).then(resAdd => {
            console.log(resAdd)
            if (resAdd && resAdd.status === 200) {
                setAlertText("Membre à été modifier avec succés")
                setAlertSuccess(true)
                getAnnuaire()
                setLoading(false)
                setSelectedFile("")
                setLangue([])
                setLangue2([])
                setUpdatedMembre({})
                setOpenUpdateMembreModal(false)
                setSelectedValues([])
                setTimeout(() => {
                    setStep(1)
                }, 1000)
                setTimeout(() => {
                    setAlertSuccess(false)
                }, 5000)
            } else {
                setAlertError(true)
                setLoading(false)
                setSelectedFile("")
                setTimeout(() => {
                    setAlertError(false)
                }, 5000)
            }
        })
    }



    return (
        <>
            <MuiBackdrop open={loading} text={"Chargement..."} />
            {alertSuccess &&
                <Alert style={{ marginTop: 35 }} variant="filled" severity="success">
                    {alertText}
                </Alert>}
            {alertError &&
                <Alert variant="filled" severity="error">
                    Erreur, Veuillez réessayer plus tard !
                </Alert>}
            <div style={{ marginTop: 30, overflowY: 'auto', maxHeight: '85vh' }} className="p-3">
                <div className="equipe-list p-2">
                    <div className="title-equipe d-flex justify-content-between mb-4">
                        <span>
                            Equipes DFMA
                        </span>
                        <button className="btn-add-member" onClick={(e) => { setOpenMembreModal(true) }}>
                            <strong>+ </strong>Ajouter membre
                        </button>
                    </div>
                    <hr style={{ color: "#7293b3" }} className="mb-4" />
                    <DataTable value={annuaires} paginator rows={10} selectionMode={'checkbox'} selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)} dataKey="id" tableStyle={{ minWidth: '50rem' }}>
                        <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                        <Column field="nom" header="Nom"></Column>
                        <Column field="prenom" header="Prenom"></Column>
                        <Column field="email" header="Email"></Column>
                        <Column field="niveau" header="Niveau d’étude"></Column>
                        <Column field="ecole" header="Nom de l’école"></Column>
                        <Column field="nbExperience" header="Expérience"></Column>
                        <Column field="tauxH" header="Taux horaire"></Column>
                        <Column body={iconsTemplate} headerStyle={{ width: '4rem' }}></Column>
                    </DataTable>
                </div>
            </div>
            <Dialog
                open={openNewMembreModal}
                aria-labelledby="form-dialog-title"
                fullWidth={true}
                maxWidth="sm"

            >
                <DialogTitle disableTypography id="form-dialog-title" style={{ paddingBottom: 1 }}>
                    <Typography style={{ color: '#1565C0', fontWeight: 600 }}>{step === 1 ? "Informations générales du nouveau membre" : step === 2 ? "Informations professionelles du nouveau membre" : "Informations professionelles du nouveau membre"} </Typography>
                    <IconButton
                        aria-label="close"
                        style={{
                            position: 'absolute',
                            right: 5,
                            top: 5,
                            color: '#c0c0c0'
                        }}
                        onClick={() => {
                            setOpenMembreModal(false)
                            resetMembre()
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <hr style={{ color: "#1565C0" }} />
                </DialogTitle>
                <DialogContent style={{ overflowY: "inherit" }}>
                    {step === 1 &&
                        <>
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="d-flex" style={{ marginRight: "20px", gap: 20 }}>
                                    {selectedFile === "" ?
                                        <img height={80} width={82} onClick={handleButtonClick} style={{ cursor: 'pointer' }} src={updateImg} />
                                        :
                                        <img height={80} width={82} onClick={handleButtonClick} style={{ borderRadius: 40 }} src={selectedFile.prefix + selectedFile.data} />
                                    }
                                    <input
                                        type="file"
                                        ref={fileInputRef}
                                        style={{ display: 'none' }}
                                        onChange={handleFileChange}
                                    />
                                    {/* Button to trigger file selection */}
                                    <div className="d-flex flex-column" style={{ gap: 5 }}>
                                        <span style={{ fontSize: 14, fontWeight: 500 }}>Photo de profil</span>
                                        <button className="btn-upload" onClick={handleButtonClick}>Choisir une image...</button>
                                        <p style={{ fontSize: 11 }}>La taille de fichier maximale autorisée est de 200 Ko.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="p-2" style={{ border: "1px solid #B7D8FD", borderRadius: 4 }}>
                                <div className="row auto-icon mt-2 align-items-center justify-content-around">
                                    <Autocomplete
                                        id="country-select-demo"
                                        sx={{ width: 300 }}
                                        options={countries.countries}
                                        className="countries-autocomplete"
                                        onChange={(e, selected) => {
                                            handleChangeNewMembre(e, "pays", selected)
                                        }}
                                        autoHighlight
                                        getOptionLabel={(option) => option.label}
                                        renderOption={(props, option) => (
                                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                <img
                                                    loading="lazy"
                                                    width="20"
                                                    srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                                    src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                                    alt=""
                                                />
                                                {option.label}
                                            </Box>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Pays / région *"
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: 'new-password', // disable autocomplete and autofill
                                                }}
                                            />
                                        )}
                                    />
                                </div>

                                <div className="row mt-4 align-items-center justify-content-around">
                                    <div className="col-md-6">
                                        <TextField fullWidth value={newMembre.prenom} onChange={(e) => { handleChangeNewMembre(e, "prenom") }} size="small" id="outlined-basic" label="Prénom *" variant="outlined" />
                                    </div>
                                    <div className="col-md-6">
                                        <TextField fullWidth value={newMembre.nom} onChange={(e) => { handleChangeNewMembre(e, "nom") }} size="small" id="outlined-basic" label="Nom *" variant="outlined" />

                                    </div>
                                </div>


                                <div className="row mt-4 align-items-center justify-content-around">
                                    <div className="col-md-12">
                                        <TextField fullWidth value={newMembre.email} onChange={(e) => { handleChangeNewMembre(e, "email") }} size="small" id="outlined-basic" label="Email *" variant="outlined" />

                                    </div>

                                </div>

                                <div className="row mt-4 align-items-center justify-content-around">
                                    <div className="col-md-12">
                                        <TextField fullWidth value={newMembre.phone} onChange={(e) => { handleChangeNewMembre(e, "phone") }} size="small" id="outlined-basic" label="Telephone" variant="outlined" />

                                    </div>

                                </div>

                                <div className="row mt-4 align-items-center justify-content-around">
                                    <div className="col-md-12">
                                        <TextField fullWidth value={newMembre.adress} onChange={(e) => { handleChangeNewMembre(e, "adress") }} size="small" id="outlined-basic" label="Adresse *" variant="outlined" />

                                    </div>

                                </div>
                                <div className="row mt-4 align-items-center justify-content-around">
                                    <div className="col-md-4">
                                        <TextField fullWidth value={newMembre.codep} onChange={(e) => { handleChangeNewMembre(e, "codep") }} size="small" id="outlined-basic" label="Code postal *" variant="outlined" />

                                    </div>
                                    <div className="col-md-8">
                                        <TextField fullWidth value={newMembre.ville} onChange={(e) => { handleChangeNewMembre(e, "ville") }} size="small" id="outlined-basic" label="Ville *" variant="outlined" />

                                    </div>

                                </div>
                            </div>

                        </>
                    }
                    {step === 2 &&
                        <>
                            <div className="d-flex" style={{ gap: 70 }}>

                                <div className="d-flex flex-column">
                                    <span style={{ fontSize: 14, fontWeight: 500 }}>Année(s) d’expérience </span>
                                    <div className="d-flex align-items-end" style={{ gap: 5 }}>
                                        <input value={newMembre.nbExperience} onChange={(e) => { handleChangeNewMembre(e, "nbExperience") }} className="input-experience align-self-center mt-2" type="number" />
                                        <span style={{ fontSize: 14, fontWeight: 500, color: "#1565C0" }}>ans </span>
                                    </div>
                                    <span className="mt-2" style={{ fontSize: 12 }}>Après formation initale</span>
                                </div>

                                <div className="d-flex flex-column">
                                    <span style={{ fontSize: 14, fontWeight: 500 }}>Taux horaire </span>
                                    <div className="d-flex align-items-end" style={{ gap: 5 }}>
                                        <input value={newMembre.tauxH} onChange={(e) => { handleChangeNewMembre(e, "tauxH") }} className="input-tauxh align-self-center mt-2" type="number" />
                                        <span style={{ fontSize: 14, fontWeight: 500, color: "#1565C0" }}>Ar/heure </span>
                                    </div>
                                    <span className="mt-2" style={{ fontSize: 12 }}>Prix TTC</span>
                                </div>
                            </div>
                            <div className="p-2" style={{ border: "1px solid #B7D8FD", borderRadius: 4 }}>
                                <div className="row mt-4 align-items-center justify-content-around">
                                    <div className="col-md-12 auto-icon">
                                        <Autocomplete

                                            id="grouped-demo"
                                            options={options.sort((a, b) => a.section - b.section)}
                                            onChange={(e, selected) => {
                                                handleChangeNewMembre(e, "ecole", selected)
                                            }}
                                            groupBy={(option) => option.section}
                                            getOptionLabel={(option) => option.name}
                                            renderInput={(params) => <TextField {...params} label="Nom établissement formation  initilale" />}
                                        />
                                    </div>
                                </div>
                                <div className="row mt-4 align-items-center justify-content-around">
                                    <div className="col-md-12 auto-icon">
                                        <Autocomplete

                                            id="grouped-demo"
                                            options={options1.sort((a, b) => a.section - b.section)}
                                            onChange={(e, selected) => {
                                                handleChangeNewMembre(e, "niveauActuelle", selected)
                                            }}
                                            groupBy={(option) => option.section}
                                            getOptionLabel={(option) => option.name}
                                            renderInput={(params) => <TextField {...params} label="Niveau d’étude actuel *" />}
                                        />
                                    </div>
                                </div>

                                <div className="row mt-3 justify-content-around align-items-center">
                                    <div className="mb-2">
                                        <span style={{ fontWeight: 500, color: '#6a6a6a' }}>Compétences</span>
                                    </div>
                                    <FormControl fullWidth>
                                        <Select
                                            multiple
                                            value={selectedValues}
                                            onChange={handleChange}
                                            renderValue={(selected) => (
                                                <Box display="flex" style={{ gap: 5 }} flexWrap="wrap">
                                                    {selected.map((value, key) => (
                                                        value ?
                                                            <Chip key={key} label={value} /> : ''
                                                    ))}
                                                </Box>
                                            )}
                                        >
                                            <MenuItem>
                                                <Competence selectedNodes={selectedNodes} onCheckboxChange={(selectedValue) => {
                                                    if (selectedValue) {
                                                        if (selectedValues.includes(selectedValue)) {
                                                            const newArray = selectedValues.filter(item => item !== selectedValue);
                                                            setSelectedValues(newArray);
                                                        } else {
                                                            setSelectedValues([...selectedValues, selectedValue]);
                                                        }
                                                    }
                                                }} />
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="row mt-4 justify-content-around align-items-center">

                                    <div className="col-md-12">
                                        <TextField
                                            id="input-with-icon-textfield"
                                            label="Linkedin"
                                            value={newMembre.linkedinUrl}
                                            size="small"
                                            fullWidth
                                            onChange={(e) => { handleChangeNewMembre(e, "linkedinUrl") }}

                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <LinkedInIcon />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            variant="outlined"
                                        />
                                    </div>
                                </div>
                                <div className="row mt-4 justify-content-around align-items-center">
                                    <div className="col-md-6">
                                        <FormControl style={{ width: '100%' }}>
                                            <InputLabel id="demo-multiple-chip-label" style={{ marginTop: -6 }}>Langue(s) maîtrisée(s) *</InputLabel>
                                            <Select
                                                labelId="demo-multiple-chip-label"
                                                id="demo-multiple-chip"
                                                multiple
                                                style={{ height: 40 }}
                                                value={langue}
                                                onChange={handleChangeLangues}
                                                input={<OutlinedInput id="select-multiple-chip" label="Langue(s) maîtrisée(s) *" />}
                                                renderValue={(selected) => (
                                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                        {selected.map((value) => (
                                                            <Chip key={value} label={value} />
                                                        ))}
                                                    </Box>
                                                )}
                                                MenuProps={MenuProps}
                                            >
                                                {langues.map((name) => (
                                                    <MenuItem
                                                        className="select-langue"
                                                        key={name}
                                                        value={name}
                                                        style={getStyles(name, langue, theme)}
                                                    >
                                                        {name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="col-md-6">
                                        <FormControl style={{ width: '100%' }}>
                                            <InputLabel id="demo-multiple-chip-label" style={{ marginTop: -6 }}>Langues 2</InputLabel>
                                            <Select
                                                labelId="demo-multiple-chip-label"
                                                id="demo-multiple-chip"
                                                multiple
                                                value={langue2}
                                                style={{ height: 40 }}
                                                onChange={handleChangeLangues2}
                                                input={<OutlinedInput id="select-multiple-chip" label="Langues 2" />}
                                                renderValue={(selected) => (
                                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                        {selected.map((value) => (
                                                            <Chip key={value} label={value} />
                                                        ))}
                                                    </Box>
                                                )}
                                                MenuProps={MenuProps}
                                            >
                                                {langues2.map((name) => (
                                                    <MenuItem
                                                        className="select-langue"
                                                        key={name}
                                                        value={name}
                                                        style={getStyles(name, langue2, theme)}
                                                    >
                                                        {name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                            </div>

                        </>
                    }
                </DialogContent>
                <DialogActions style={{ padding: 20, justifyContent: 'start' }}>
                    <MuiButton

                        color="primary"
                        variant="outlined"
                        style={{ textTransform: 'capitalize' }}
                        onClick={() => {
                            setOpenMembreModal(false)
                            resetMembre()
                        }}
                    >
                        Cancel
                    </MuiButton>
                    <MuiButton
                        onClick={() => { nextStep() }}
                        variant="contained"
                        style={{ textTransform: 'capitalize', color: "#fff", backgroundColor: "#1565C0" }}
                    >
                        Suite
                    </MuiButton>
                    {requiredValues === true && <span style={{ color: 'red' }}>Veuillez remplir les champs obligatoire *</span>}
                </DialogActions>
            </Dialog>



            {profilDetail.email &&
                <Dialog
                    open={openProfilModal}
                    aria-labelledby="form-dialog-title"
                    fullWidth={true}
                    maxWidth="sm"
                    className="profil-detail"

                >
                    <DialogTitle disableTypography id="form-dialog-title">
                        <IconButton
                            aria-label="close"
                            style={{
                                position: 'absolute',
                                right: 5,
                                top: 5,
                                color: '#c0c0c0'
                            }}
                            onClick={() => {
                                setOpenProfilModal(false)
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent style={{ overflowY: "inherit" }}>

                        <div className="d-flex align-items-center" style={{ gap: 30 }}>
                            {profilDetail.photo.data ?
                                <Avatar
                                    alt="Remy Sharp"
                                    src={profilDetail.photo.prefix + profilDetail.photo.data}
                                    sx={{ width: 100, height: 100 }}
                                />
                                : <Avatar sx={{ width: 100, height: 100, fontSize: 35 }}>
                                    {profilDetail.nom[0]}{profilDetail.prenom[0]}
                                </Avatar>
                            }

                            <div className="d-flex flex-column">
                                <span style={{ fontSize: 24, fontWeight: 700, color: '#1565C0', fontFamily: "Montserrat" }}>{profilDetail.nom} {profilDetail.prenom}</span>
                                <span style={{ fontFamily: "Montserrat" }}>{profilDetail.email}</span>
                            </div>
                        </div>

                        <div className="mt-3">
                            <MuiButton
                                variant="outlined"
                                style={{ textTransform: 'capitalize', color: "#1565C0", border: "1px solid #1565C0" }}
                            >
                                Me contacter
                            </MuiButton>
                        </div>

                        <hr />

                        <div className="row mt-3 px-5">
                            <div className="col-md-6">
                                <div>
                                    <span style={{ fontSize: 14, color: "#616161", fontWeight: 600, fontFamily: "Montserrat" }}>Habite à</span>
                                </div>
                                <div>
                                    <span style={{ fontSize: 14, color: "#1565C0", fontWeight: 500, fontFamily: "Montserrat" }}>{profilDetail.adress}</span>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div>
                                    <span style={{ fontSize: 14, color: "#616161", fontWeight: 600, fontFamily: "Montserrat" }}>Membre depuis le</span>
                                </div>
                                <div>
                                    <span style={{ fontSize: 14, color: "#1565C0", fontWeight: 500, fontFamily: "Montserrat" }}>{moment(profilDetail.created_at).format('MMMM, YYYY')}</span>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-2 px-5">
                            <div className="col-md-6">
                                <div>
                                    <span style={{ fontSize: 14, color: "#616161", fontWeight: 600, fontFamily: "Montserrat" }}>Langues parlées</span>
                                </div>
                                <div>
                                    <span style={{ fontSize: 14, color: "#1565C0", fontWeight: 500, fontFamily: "Montserrat" }}>{profilDetail.langue[0]} , {profilDetail.langue[1]}</span>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div>
                                    <span style={{ fontSize: 14, color: "#616161", fontWeight: 600, fontFamily: "Montserrat" }}>Taux horaire</span>
                                </div>
                                <div>
                                    <span style={{ fontSize: 14, color: "#1565C0", fontWeight: 500, fontFamily: "Montserrat" }}>{profilDetail.tauxH}</span>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-2 px-5">
                            <div className="col-md-6">
                                <div>
                                    <span style={{ fontSize: 14, color: "#616161", fontWeight: 600, fontFamily: "Montserrat" }}>Niveau d’étude</span>
                                </div>
                                <div>
                                    <span style={{ fontSize: 14, color: "#1565C0", fontWeight: 500, fontFamily: "Montserrat" }}>{profilDetail.niveau}</span>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div>
                                    <span style={{ fontSize: 14, color: "#616161", fontWeight: 600, fontFamily: "Montserrat" }}>Expérience</span>
                                </div>
                                <div>
                                    <span style={{ fontSize: 14, color: "#1565C0", fontWeight: 500, fontFamily: "Montserrat" }}>{profilDetail.nbExperience}</span>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-2 px-5">
                            <div className="col-md-12">
                                <div>
                                    <span style={{ fontSize: 14, color: "#616161", fontWeight: 600, fontFamily: "Montserrat" }}>Ecole</span>
                                </div>
                                <div>
                                    <span style={{ fontSize: 14, color: "#1565C0", fontWeight: 500, fontFamily: "Montserrat" }}>{profilDetail.ecole}</span>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-2 px-5">
                            <div className="col-md-12">
                                <div>
                                    <span style={{ fontSize: 14, color: "#616161", fontWeight: 600, fontFamily: "Montserrat" }}>Expérience</span>
                                </div>
                                <div>
                                    {profilDetail.metiers.map((item, key) => (
                                        <Chip key={key} className="mt-2" variant="outlined" label={item} style={{ color: "#1565C0", border: "1px solid #1565C0", marginLeft: 6 }} />
                                    ))}
                                </div>
                            </div>
                        </div>

                    </DialogContent>

                    {(profilDetail.nom && profilDetail.prenom) &&
                        <DialogActions style={{ padding: 20 }}>
                            <MuiButton
                                variant="contained"
                                style={{ textTransform: 'capitalize', color: "#fff", backgroundColor: "#1565C0" }}
                            >
                                Choisir {profilDetail.nom} {profilDetail.prenom}
                            </MuiButton>
                        </DialogActions>
                    }
                </Dialog>
            }

            <Dialog
                open={openUpdateMembreModal}
                aria-labelledby="form-dialog-title"
                fullWidth={true}
                maxWidth="sm"

            >
                <DialogTitle disableTypography id="form-dialog-title" style={{ paddingBottom: 1 }}>
                    <Typography style={{ color: '#1565C0', fontWeight: 600 }}>{step === 1 ? "Modifier les informations générales du membre" : step === 2 ? "Informations professionelles du nouveau membre" : "Modifier les informations professionelles du membre"} </Typography>
                    <IconButton
                        aria-label="close"
                        style={{
                            position: 'absolute',
                            right: 5,
                            top: 5,
                            color: '#c0c0c0'
                        }}
                        onClick={() => {
                            setOpenUpdateMembreModal(false)
                            resetMembre()
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <hr style={{ color: "#1565C0" }} />
                </DialogTitle>
                <DialogContent style={{ overflowY: "inherit" }}>
                    {step === 1 &&
                        <>
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="d-flex" style={{ marginRight: "20px", gap: 20 }}>
                                    {selectedFile === "" ?
                                        <img height={80} width={82} onClick={handleButtonClick} style={{ cursor: 'pointer' }} src={updateImg} />
                                        :
                                        <img height={80} width={82} onClick={handleButtonClick} style={{ borderRadius: 40 }} src={selectedFile.prefix + selectedFile.data} />
                                    }
                                    <input
                                        type="file"
                                        ref={fileInputRef}
                                        style={{ display: 'none' }}
                                        onChange={handleFileChangeUpdated}
                                    />
                                    {/* Button to trigger file selection */}
                                    <div className="d-flex flex-column" style={{ gap: 5 }}>
                                        <span style={{ fontSize: 14, fontWeight: 500 }}>Photo de profil</span>
                                        <button className="btn-upload" onClick={handleButtonClick}>Choisir une image...</button>
                                        <p style={{ fontSize: 11 }}>La taille de fichier maximale autorisée est de 200 Ko.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="p-2" style={{ border: "1px solid #B7D8FD", borderRadius: 4 }}>
                                <div className="row auto-icon mt-2 align-items-center justify-content-around">
                                    <Autocomplete
                                        id="country-select-demo"
                                        sx={{ width: 300 }}
                                        options={countries.countries}
                                        defaultValue={updatedMembre.pays ? updatedMembre.pays : null}
                                        className="countries-autocomplete"
                                        onChange={(e, selected) => {
                                            handleChangeUpdatedMembre(e, "pays", selected)
                                        }}
                                        autoHighlight
                                        getOptionLabel={(option) => option.label}
                                        renderOption={(props, option) => (
                                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                <img
                                                    loading="lazy"
                                                    width="20"
                                                    srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                                    src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                                    alt=""
                                                />
                                                {option.label}
                                            </Box>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Pays / région *"
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: 'new-password', // disable autocomplete and autofill
                                                }}
                                            />
                                        )}
                                    />
                                </div>

                                <div className="row mt-4 align-items-center justify-content-around">
                                    <div className="col-md-6">
                                        <TextField fullWidth value={updatedMembre.prenom} onChange={(e) => { handleChangeUpdatedMembre(e, "prenom") }} size="small" id="outlined-basic" label="Prénom *" variant="outlined" />
                                    </div>
                                    <div className="col-md-6">
                                        <TextField fullWidth value={updatedMembre.nom} onChange={(e) => { handleChangeUpdatedMembre(e, "nom") }} size="small" id="outlined-basic" label="Nom *" variant="outlined" />

                                    </div>
                                </div>


                                <div className="row mt-4 align-items-center justify-content-around">
                                    <div className="col-md-12">
                                        <TextField fullWidth value={updatedMembre.email} onChange={(e) => { handleChangeUpdatedMembre(e, "email") }} size="small" id="outlined-basic" label="Email *" variant="outlined" />

                                    </div>

                                </div>

                                <div className="row mt-4 align-items-center justify-content-around">
                                    <div className="col-md-12">
                                        <TextField fullWidth value={updatedMembre.phone} onChange={(e) => { handleChangeUpdatedMembre(e, "phone") }} size="small" id="outlined-basic" label="Telephone" variant="outlined" />

                                    </div>

                                </div>

                                <div className="row mt-4 align-items-center justify-content-around">
                                    <div className="col-md-12">
                                        <TextField fullWidth value={updatedMembre.adress} onChange={(e) => { handleChangeUpdatedMembre(e, "adress") }} size="small" id="outlined-basic" label="Adresse *" variant="outlined" />

                                    </div>

                                </div>
                                <div className="row mt-4 align-items-center justify-content-around">
                                    <div className="col-md-4">
                                        <TextField fullWidth value={updatedMembre.codep} onChange={(e) => { handleChangeUpdatedMembre(e, "codep") }} size="small" id="outlined-basic" label="Code postal *" variant="outlined" />

                                    </div>
                                    <div className="col-md-8">
                                        <TextField fullWidth value={updatedMembre.ville} onChange={(e) => { handleChangeUpdatedMembre(e, "ville") }} size="small" id="outlined-basic" label="Ville *" variant="outlined" />

                                    </div>

                                </div>
                            </div>

                        </>
                    }
                    {step === 2 &&
                        <>
                            <div className="d-flex" style={{ gap: 70 }}>

                                <div className="d-flex flex-column">
                                    <span style={{ fontSize: 14, fontWeight: 500 }}>Année(s) d’expérience </span>
                                    <div className="d-flex align-items-end" style={{ gap: 5 }}>
                                        <input value={parseInt(updatedMembre.nbExperience)} onChange={(e) => { handleChangeUpdatedMembre(e, "nbExperience") }} className="input-experience align-self-center mt-2" type="number" />
                                        <span style={{ fontSize: 14, fontWeight: 500, color: "#1565C0" }}>ans </span>
                                    </div>
                                    <span className="mt-2" style={{ fontSize: 12 }}>Après formation initale</span>
                                </div>

                                <div className="d-flex flex-column">
                                    <span style={{ fontSize: 14, fontWeight: 500 }}>Taux horaire </span>
                                    <div className="d-flex align-items-end" style={{ gap: 5 }}>
                                        <input value={parseInt(updatedMembre.tauxH)} onChange={(e) => { handleChangeUpdatedMembre(e, "tauxH") }} className="input-tauxh align-self-center mt-2" type="number" />
                                        <span style={{ fontSize: 14, fontWeight: 500, color: "#1565C0" }}>Ar/heure </span>
                                    </div>
                                    <span className="mt-2" style={{ fontSize: 12 }}>Prix TTC</span>
                                </div>
                            </div>
                            <div className="p-2" style={{ border: "1px solid #B7D8FD", borderRadius: 4 }}>
                                <div className="row mt-4 align-items-center justify-content-around">
                                    <div className="col-md-12 auto-icon">
                                        <Autocomplete

                                            id="grouped-demo"
                                            value={options.find(item => item.name === updatedMembre.ecole) || null}
                                            options={options.sort((a, b) => a.section - b.section)}
                                            onChange={(e, selected) => {
                                                handleChangeUpdatedMembre(e, "ecole", selected)
                                            }}
                                            groupBy={(option) => option.section}
                                            getOptionLabel={(option) => option.name}
                                            renderInput={(params) => <TextField {...params} label="Nom établissement formation  initilale" />}
                                        />
                                    </div>
                                </div>
                                <div className="row mt-4 align-items-center justify-content-around">
                                    <div className="col-md-12 auto-icon">
                                        <Autocomplete

                                            id="grouped-demo"
                                            value={options1.find(item => item.name === updatedMembre.niveauActuelle) || null}
                                            options={options1.sort((a, b) => a.section - b.section)}
                                            onChange={(e, selected) => {
                                                handleChangeUpdatedMembre(e, "niveauActuelle", selected)
                                            }}
                                            groupBy={(option) => option.section}
                                            getOptionLabel={(option) => option.name}
                                            renderInput={(params) => <TextField {...params} label="Niveau d’étude actuel *" />}
                                        />
                                    </div>
                                </div>

                                <div className="row mt-3 justify-content-around align-items-center">
                                    <div className="mb-2">
                                        <span style={{ fontWeight: 500, color: '#6a6a6a' }}>Compétences</span>
                                    </div>
                                    <FormControl fullWidth>
                                        <Select
                                            multiple
                                            value={selectedValues}
                                            onChange={handleChange}
                                            renderValue={(selected) => (
                                                <Box display="flex" style={{ gap: 5 }} flexWrap="wrap">
                                                    {selected.map((value, key) => (
                                                        value ?
                                                            <Chip key={key} label={value} /> : ''
                                                    ))}
                                                </Box>
                                            )}
                                        >
                                            <MenuItem>
                                                <Competence selectedNodes={selectedNodes} onCheckboxChange={(selectedValue) => {
                                                    if (selectedValue) {
                                                        if (selectedValues.includes(selectedValue)) {
                                                            const newArray = selectedValues.filter(item => item !== selectedValue);
                                                            setSelectedValues(newArray);
                                                        } else {
                                                            setSelectedValues([...selectedValues, selectedValue]);
                                                        }
                                                    }
                                                }} />
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="row mt-4 justify-content-around align-items-center">

                                    <div className="col-md-12">
                                        <TextField
                                            id="input-with-icon-textfield"
                                            label="Linkedin"
                                            value={updatedMembre.linkedinUrl}
                                            size="small"
                                            fullWidth
                                            onChange={(e) => { handleChangeUpdatedMembre(e, "linkedinUrl") }}

                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <LinkedInIcon />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            variant="outlined"
                                        />
                                    </div>
                                </div>
                                <div className="row mt-4 justify-content-around align-items-center">
                                    <div className="col-md-6">
                                        <FormControl style={{ width: '100%' }}>
                                            <InputLabel id="demo-multiple-chip-label" style={{ marginTop: -6 }}>Langue(s) maîtrisée(s) *</InputLabel>
                                            <Select
                                                labelId="demo-multiple-chip-label"
                                                id="demo-multiple-chip"
                                                multiple
                                                style={{ height: 40 }}
                                                value={langue}
                                                onChange={handleChangeLangues}
                                                input={<OutlinedInput id="select-multiple-chip" label="Langue(s) maîtrisée(s) *" />}
                                                renderValue={(selected) => (
                                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                        {selected.map((value) => (
                                                            <Chip key={value} label={value} />
                                                        ))}
                                                    </Box>
                                                )}
                                                MenuProps={MenuProps}
                                            >
                                                {langues.map((name) => (
                                                    <MenuItem
                                                        className="select-langue"
                                                        key={name}
                                                        value={name}
                                                        style={getStyles(name, langue, theme)}
                                                    >
                                                        {name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="col-md-6">
                                        <FormControl style={{ width: '100%' }}>
                                            <InputLabel id="demo-multiple-chip-label" style={{ marginTop: -6 }}>Langues 2</InputLabel>
                                            <Select
                                                labelId="demo-multiple-chip-label"
                                                id="demo-multiple-chip"
                                                multiple
                                                value={langue2}
                                                style={{ height: 40 }}
                                                onChange={handleChangeLangues2}
                                                input={<OutlinedInput id="select-multiple-chip" label="Langues 2" />}
                                                renderValue={(selected) => (
                                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                        {selected.map((value) => (
                                                            <Chip key={value} label={value} />
                                                        ))}
                                                    </Box>
                                                )}
                                                MenuProps={MenuProps}
                                            >
                                                {langues2.map((name) => (
                                                    <MenuItem
                                                        className="select-langue"
                                                        key={name}
                                                        value={name}
                                                        style={getStyles(name, langue2, theme)}
                                                    >
                                                        {name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                            </div>

                        </>
                    }
                </DialogContent>
                <DialogActions style={{ padding: 20, justifyContent: 'start' }}>
                    <MuiButton

                        color="primary"
                        variant="outlined"
                        style={{ textTransform: 'capitalize' }}
                        onClick={() => {
                            setOpenUpdateMembreModal(false)
                            resetMembre()
                        }}
                    >
                        Cancel
                    </MuiButton>
                    <MuiButton
                        onClick={() => { nextStepUpdate() }}
                        variant="contained"
                        style={{ textTransform: 'capitalize', color: "#fff", backgroundColor: "#1565C0" }}
                    >
                        Suite
                    </MuiButton>
                    {requiredValues === true && <span style={{ color: 'red' }}>Veuillez remplir les champs obligatoire *</span>}
                </DialogActions>
            </Dialog>
        </>
    )
}
export default Freelance_List;