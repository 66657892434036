import { useEffect, useRef } from "react";
import skills from "../../data/skills"
import React, { useState } from 'react';
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import Checkbox from '@mui/material/Checkbox';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import "./Competence.css"
import Header from "../../components/header/Header";
const TreeNode = ({ node, onCheck, checkedNodes, setCheckedNodes, filterText }) => {
  const isChecked = checkedNodes[node.name] || false;

  const handleToggle = () => {
    setCheckedNodes((prevCheckedNodes) => ({
      ...prevCheckedNodes,
      [node.name]: !isChecked,
    }));
    onCheck(node.name, !isChecked);
    console.log(checkedNodes)
  };

  const isNodeMatchingFilter = node.name.toLowerCase().includes(filterText.toLowerCase());

  const anyChildMatchesFilter = (children) => {
    if (!children) {
      return false;
    }
    return children.some((child) => {
      return child.name.toLowerCase().includes(filterText.toLowerCase()) || anyChildMatchesFilter(child.children);
    });
  };

  if (!isNodeMatchingFilter && !anyChildMatchesFilter(node.children)) {
    // If the node and its children do not match the filterText, exclude it from the rendered tree
    return null;
  }

  return (
    <TreeItem
      style={{ marginTop: node.selectable ? 0 : 20, color:"#000" }}
      key={node.name}
      nodeId={node.name}
      label={
        <div className="level-fin" style={{ display: 'flex', alignItems: 'center', gap: 1, fontFamily: "Montserrat", fontSize: 14, color:"#000" }}>
          {node.selectable && <Checkbox checked={isChecked} onChange={handleToggle} />}
          {node.name}
        </div>
      }
    >
      {Array.isArray(node.children) && node.children.map((child) => (
        <TreeNode
          key={child.name}
          node={child}
          onCheck={onCheck}
          checkedNodes={checkedNodes}
          setCheckedNodes={setCheckedNodes}
          filterText={filterText}
        />
      ))}
    </TreeItem>
  );
};


const Competence = ({ onCheckboxChange, selectedNodes}) => {
  const [checkedNodes, setCheckedNodes] = useState(selectedNodes);
  const [filterText, setFilterText] = useState("");
  const [selectedSkills, setSelectedSkills] = useState(0);

  const handleCheck = (nodeName, isChecked) => {
    console.log(nodeName)
    console.log(selectedNodes)
    setCheckedNodes((prevCheckedNodes) => ({
      ...prevCheckedNodes,
      [nodeName]: isChecked,
    }));
    onCheckboxChange(nodeName)
  };

  return (
    <>
      <div className="p-4">
        <div className="d-flex justify-content-between">
          <button className={selectedSkills === 0 ? "btn-skills-selected":"btn-skills"} onClick={(e=>{setSelectedSkills(0)})} ><span className="item2_text">Data IT & Tech</span> </button>
          <button className={selectedSkills === 4 ? "btn-skills-selected":"btn-skills"} onClick={(e=>{setSelectedSkills(4)})} ><span className="item2_text">Marketing & communi</span> </button>
          <button className={selectedSkills === 3 ? "btn-skills-selected":"btn-skills"} onClick={(e=>{setSelectedSkills(3)})} ><span className="item2_text">Stratégie & Business</span> </button>
        </div>
        <div className="d-flex justify-content-between mt-2 mb-3">
          <button className={selectedSkills === 2 ? "btn-skills-selected":"btn-skills"} onClick={(e=>{setSelectedSkills(2)})} ><span className="item2_text">Design, son et image </span> </button>
          <button className={selectedSkills === 5 ? "btn-skills-selected":"btn-skills"} onClick={(e=>{setSelectedSkills(5)})} ><span className="item2_text">Métier d’arts</span> </button>
          <button className={selectedSkills === 1 ? "btn-skills-selected":"btn-skills"} onClick={(e=>{setSelectedSkills(1)})} ><span className="item2_text">Génie civil & Archi</span> </button>
        </div>
        <input
          type="text"
          className="form-control"
          placeholder="Chercher ..."
          value={filterText}
          onChange={(e) => setFilterText(e.target.value)}
          style={{ marginBottom: "10px" }}
        />
        <TreeView style={{ fontFamily: "Plus Jakarta Sans" }} defaultCollapseIcon={<ExpandMoreIcon fontSize="large" style={{ color: "grey" }} />} defaultExpandIcon={<ChevronRightIcon fontSize="large" style={{ color: "grey" }} />}>
          {skills.skills[selectedSkills].categorie.map((node) => (
            <TreeNode
              className="mt-3"
              key={node.name}
              node={node}
              onCheck={handleCheck}
              checkedNodes={checkedNodes}
              setCheckedNodes={setCheckedNodes}
              filterText={filterText}
            />
          ))}
        </TreeView>
      </div>
    </>
  );
};

export default Competence;