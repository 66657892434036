exports.niveauEtude = [
    {
        name: "Seconde",
        section: "Lycée"
    },
    {
        name: "Première",
        section: "Lycée"
    },
    {
        name: "Terminale",
        section: "Lycée"
    },
    {
        name: "L1 - Première année de Licence / Bachelor",
        section: "Enseignement Supérieur"
    },
    {
        name: "L2 - Deuxième année de Licence / Bachelor",
        section: "Enseignement Supérieur"
    },
    {
        name: "L3 - Troisième année de Licence / Bachelor",
        section: "Enseignement Supérieur"
    },
    {
        name: "M1 - Première année de Master",
        section: "Enseignement Supérieur"
    },
    {
        name: "M2 - Deuxième année de Master",
        section: "Enseignement Supérieur"
    },
    {
        name: "Doctorat - Recherche",
        section: "Enseignement Supérieur"
    },
    {
        name: "DESS - Diplôme d’Études Supérieures Spécialisées",
        section: "Études Supérieures Supplémentaires"
    },
    {
        name: "DEA - Diplôme d’Études Approfondies",
        section: "Études Supérieures Supplémentaires"
    },
    {
        name: "MS - Mastère Spécialisé",
        section: "Études Supérieures Supplémentaires"
    },
    {
        name: "BTS - Diplôme de Technicien Supérieur",
        section: "Écoles Supérieures"
    },
    {
        name: "DUT - Diplômes Universitaires de Technologie",
        section: "Écoles Supérieures"
    },
    {
        name: "IUT - Instituts Universitaires de Technologie",
        section: "Écoles Supérieures"
    },
    {
        name: "CAP / BEP - Formations courtes certifiées",
        section: "Écoles Supérieures"
    },
    {
        name: "Formation continue",
        section: "Écoles Supérieures"
    },
    {
        name: "Formation continue universitaire",
        section: "Écoles Supérieures"
    },
    {
        name: "Cours et certifications professionnelles",
        section: "Écoles Supérieures"
    }
]