import FileUpload from "../components/Documents/FileUpload";
import { workspaces } from "../data/workspaces";
import { apiNodeService } from "../provider/apiNodeService";
import { Card, CardContent, Typography, Radio, RadioGroup, FormControlLabel } from '@mui/material';
import React, { useState } from 'react';


const UploadDocToOdoo = () => {

    const handleFileUpload = async (file) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('folder_id', selectedCard);
        apiNodeService.uploadDocOdoo(formData).then(res => {
            console.log(res)
        })
    };

    const [selectedCard, setSelectedCard] = useState('1');

    const handleCardChange = (event) => {
        setSelectedCard(event.target.value);
    };

    const getSelectedCard = () => {
        return `Espace sélectionnée : ${selectedCard}`;
    };

    return (
        <div className="p-3">
            <div className="container mt-4 mb-4">
                <Typography variant="h4" className="mb-4">Sélectionnez un espace de travail</Typography>
                <RadioGroup
                    aria-label="card-selection"
                    name="card-selection"
                    value={selectedCard}
                    onChange={handleCardChange}
                >
                    <div className="row">
                        {workspaces.map((card) => (
                            <div key={card.id} className="col-md-4 mb-3">
                                <Card className="h-100">
                                    <CardContent>
                                        <Typography variant="h5" component="div">
                                             {card.name}
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            Espace de travail {card.name}
                                        </Typography>
                                        <FormControlLabel
                                            value={card.id.toString()}
                                            control={<Radio />}
                                            label={`Sélectionner l'espace ${card.id}`}
                                            className="mt-2"
                                        />
                                    </CardContent>
                                </Card>
                            </div>
                        ))}
                    </div>
                </RadioGroup>
                <Typography variant="h6" className="mt-4 mb-4">
                    {getSelectedCard()}
                </Typography>
                <FileUpload handleFileUpload={handleFileUpload} />
            </div>
        </div>
    );
}

export default UploadDocToOdoo;