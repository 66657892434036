exports.categoriesMetier = [
    {
        "label": "Architecte chef de projet"
    },
    {
        "label": "Chef de projet bâtiment"
    },
    {
        "label": "Chef de projet rénovation"
    },
    {
        "label": "Chef de projet développement durable"
    },
    {
        "label": "Architecte conseiller"
    },
    {
        "label": "Conseiller en patrimoine"
    },
    {
        "label": "Conseiller en efficacité énergétique"
    },
    {
        "label": "Conseiller en choix de matériaux"
    },
    {
        "label": "Architecte HMONP "
    },
    {
        "label": "Architecte indépendant"
    },
    {
        "label": "Architecte associé"
    },
    {
        "label": "Architecte en entreprise"
    },
    {
        "label": "Architecte plasticien "
    },
    {
        "label": "Architecte-artiste"
    },
    {
        "label": "Designer d'espace"
    },
    {
        "label": "Architecte urbaniste designer "
    },
    {
        "label": "Designer urbain"
    },
    {
        "label": "Planificateur régional"
    },
    {
        "label": "Dessinateur-projeteur"
    },
    {
        "label": "Assistant de conception"
    },
    {
        "label": "Coordinateur de projet"
    },
    {
        "label": "Architecte paysagiste"
    },
    {
        "label": "Paysagiste"
    },
    {
        "label": "Designer d'extérieur"
    },
    {
        "label": "Designer de mobilier d'extérieur"
    },
    {
        "label": "Designer d'éclairage d'extérieur"
    },
    {
        "label": "Designer de jardin thématique"
    },
    {
        "label": "Assistant chef de projet en agence d'architecture "
    },
    {
        "label": "Assistant à la coordination"
    },
    {
        "label": "Assistant à la conception"
    },
    {
        "label": "Assistant de communication"
    },
    {
        "label": "Technicien CAO/DAO"
    },
    {
        "label": "Technicien en mécanique du bâtiment"
    },
    {
        "label": "Technicien en structure"
    },
    {
        "label": "Technicien études techniques"
    },
    {
        "label": "Technicien en électricité du bâtiment"
    },
    {
        "label": "Technicien en plomberie et sanitaire"
    },
    {
        "label": "Technicien en efficacité énergétique"
    },
    {
        "label": "Gestion de Projets BIM"
    },
    {
        "label": "Coordinateur de projet BIM"
    },
    {
        "label": "Planificateur de projet BIM"
    },
    {
        "label": "Développement et Maintenance des Normes BIM"
    },
    {
        "label": "Spécialiste des normes et protocoles BIM"
    },
    {
        "label": "Développeur de templates et familles BIM"
    },
    {
        "label": "Formation et Support BIM"
    },
    {
        "label": "Support technique BIM"
    },
    {
        "label": "Coordination et Vérification de la Modélisation"
    },
    {
        "label": "Analyste de données BIM"
    },
    {
        "label": "Gestionnaire de base de données BIM"
    },
    {
        "label": "Intégration des Systèmes et Interopérabilité"
    },
    {
        "label": "Spécialiste en intégration BIM"
    },
    {
        "label": "Spécialiste en interopérabilité BIM"
    },
    {
        "label": "Technologie et Innovation BIM"
    },
    {
        "label": "Développement de Logiciels BIM"
    },
    {
        "label": "Spécialiste en automatisation BIM"
    },
    {
        "label": "Spécialiste en simulation BIM"
    },
    {
        "label": "Analyste de performance BIM"
    },
    {
        "label": "Gestion de la Documentation BIM"
    },
    {
        "label": "Gestion des Actifs et Maintenance BIM"
    },
    {
        "label": "Gestionnaire d'actifs BIM"
    },
    {
        "label": "Qualité et Conformité BIM"
    },
    {
        "label": "Spécialiste en conformité BIM"
    },
    {
        "label": "Auditeur de qualité BIM"
    },
    {
        "label": "Chef de projet BIM "
    },
    {
        "label": "Chef de Projet BIM pour le MEP"
    },
    {
        "label": "Coordinateur BIM"
    },
    {
        "label": "Communicateur de projet BIM"
    },
    {
        "label": "Contrôle de Qualité et Vérification"
    },
    {
        "label": "Vérificateur de modèle BIM"
    },
    {
        "label": "Dessinateur BIM"
    },
    {
        "label": "Perspectiviste 3D"
    },
    {
        "label": "Technicien modeleur BIM"
    },
    {
        "label": "Technicien modeleur DfMA"
    },
    {
        "label": "Gestionnaire de ressources BIM"
    },
    {
        "label": "Projeteur CFA (Courants Faibles Associés)"
    },
    {
        "label": "Projeteur en systèmes de sécurité"
    },
    {
        "label": "Projeteur en télécommunications"
    },
    {
        "label": "Projeteur en contrôle d'accès"
    },
    {
        "label": "Projeteur en sonorisation et audiovisuel"
    },
    {
        "label": "Projeteur VRD "
    },
    {
        "label": "Projeteur en réseaux divers"
    },
    {
        "label": "Responsable Planification"
    },
    {
        "label": "Responsable  Coordination sur le terrain"
    },
    {
        "label": "Inspections régulières"
    },
    {
        "label": "Rédaction du DIUO (Dossier d'Interventions Ultérieures sur l'Ouvrage)"
    },
    {
        "label": "Constitution et mise à jour du dossier de coordination"
    },
    {
        "label": "Responsable  Sensibilisation et formation"
    },
    {
        "label": "Formation à la sécurité"
    }
    ,
    {
        "label": "Dessinateur bureau d'études bâtiment"
    },
    {
        "label": "Dessinateur en structures"
    },
    {
        "label": "Dessinateur en CVC (Chauffage, Ventilation, Climatisation)"
    },
    {
        "label": "Dessinateur en électricité"
    },
    {
        "label": "Dessinateur en plomberie et sanitaire"
    },
    {
        "label": "Dessinateur en aménagements intérieurs"
    },
    {
        "label": "Dessinateur en VRD (Voirie et Réseaux Divers)"
    },
    {
        "label": "DAO/CAO"
    },
    {
        "label": "Modélisation 3D et BIM (Building Information Modeling)"
    },
    {
        "label": "Dessinateur de métallerie "
    },
    {
        "label": "Dessinateur de charpente métallique"
    },
    {
        "label": "Dessinateur de structures métalliques légères"
    },
    {
        "label": "Dessinateur de structures métalliques lourdes"
    },
    {
        "label": "Dessinateur de tuyauterie industrielle"
    },
    {
        "label": "Dessinateur de structures métalliques architecturales"
    },
    {
        "label": "Dessinateur de serrurerie métallique"
    },
    {
        "label": "Dessinateur de menuiserie métallique"
    },
    {
        "label": "Dessinateur en bâtiment "
    },
    {
        "label": "Dessinateur en bâtiment architectural"
    },
    {
        "label": "Dessinateur en bâtiment structurel"
    },
    {
        "label": "Dessinateur en bâtiment en génie électrique"
    },
    {
        "label": "Dessinateur en bâtiment en génie mécanique"
    },
    {
        "label": "Dessinateur en bâtiment en génie civil"
    },
    {
        "label": "Conception et Modélisation  CAO  (Sketup, AutoCAD, SolidWorks ou Revit."
    },
    {
        "label": "Aptitude à élaborer des plans, des schémas et des dessins techniques."
    },
    {
        "label": "Conception de systèmes d'énergie renouvelable (solaire, éolien, hydroélectrique, etc.)."
    },
    {
        "label": "Analyse Technique"
    },
    {
        "label": "Calculs et Simulations"
    },
    {
        "label": "Gestionnaire de Projets Coordination et Communication"
    },
    {
        "label": "Gestionnaire de Projets Planification et Suivi"
    },
    
    {
        "label": "Scénographe"
    },
    {
        "label": "Métreurs"
    },
    {
        "label": "Spécialiste en SIG (Systèmes d'Information Géographique)"
    },
    {
        "label": "Analyste SIG"
    },
    {
        "label": "Cartographe"
    },
    {
        "label": "Géomaticien"
    },
    {
        "label": "Gestionnaire de données géographiques"
    },
    {
        "label": "Développeur SIG"
    },
    {
        "label": "Spécialiste en télédétection"
    },
    {
        "label": "Spécialiste en SIG environnemental"
    },
    {
        "label": "Spécialiste en SIG urbain"
    },
    {
        "label": "Technicien en CAO (Conception Assistée par Ordinateur)"
    },
    {
        "label": "Technicien en CAO mécanique"
    },
    {
        "label": "Technicien en CAO architectural"
    },
    {
        "label": "Technicien en CAO électronique"
    },
    {
        "label": "Technicien en CAO électrique"
    },
    {
        "label": "Technicien en CAO civil"
    },
    {
        "label": "Technicien en CAO aérospatiale"
    },
    {
        "label": "Technicien en CAO dans l'industrie automobile"
    },
    {
        "label": "Technicien en CAO dans le secteur de l'énergie"
    },
    {
        "label": "Spécialiste en DAO (Dessin Assisté par Ordinateur)"
    },
    {
        "label": "Spécialiste en DAO mécanique"
    },
    {
        "label": "Spécialiste en DAO architectural"
    },
    {
        "label": "Spécialiste en DAO civil"
    },
    {
        "label": "Spécialiste en DAO dans le design industriel"
    },
    {
        "label": " Concepteur designer scénographe •"
    },
    {
        "label": "Conseiller thermique"
    },
    {
        "label": "Économiste de la construction"
    },
    {
        "label": "Ingénieur étude de prix "
    },
    {
        "label": "Inspecteur en bâtiment"
    },
    {
        "label": "Ingénieur en structure"
    },
    {
        "label": "Ingénieur en génie civil"
    },
    {
        "label": "Ingénieur en génie électrique"
    },
    {
        "label": "Ingénieur en génie mécanique"
    },
    {
        "label": "Ingénieur en génie environnemental"
    },
    {
        "label": "Ingénieur géotechnique"
    },
    {
        "label": "Gestionnaire de construction"
    },
    {
        "label": "Gestionnaire de projet en construction"
    },
    {
        "label": "Planificateur de projet"
    },
   
    {
        "label": "Chargé de mission SCoT "
    },
    {
        "label": "Chargé de mission urbanisme "
    },
    {
        "label": "Chargé d'études environnement planification •"
    },
    {
        "label": "Chargés d'études mobilités "
    },
    {
        "label": "Chargé d'opérations réhabilitation •"
    },
    {
        "label": "Consultants en durabilité"
    },
    {
        "label": "Spécialiste en efficacité énergétique"
    },
    {
        "label": "Consultant en énergie renouvelable"
    },
    {
        "label": "Consultant en gestion des déchets et recyclage"
    },
    {
        "label": "Spécialiste en qualité de l'air intérieur"
    },
    
    {
        "label": "Évaluateur d'impact environnemental"
    },
    {
        "label": "Auditeur environnemental"
    },
    {
        "label": "Gestion des ressources"
    },
    {
        "label": "Gestionnaire des ressources en eau"
    },
    {
        "label": "Gestionnaire des ressources naturelles"
    },
    
    {
        "label": "Gestionnaire de site contaminé"
    },
    {
        "label": "Analyse et recherche environnementale"
    },
    {
        "label": "Analyste environnemental"
    },
    {
        "label": "Chercheur en sciences environnementales"
    },
    {
        "label": "Éducation et sensibilisation environnementale"
    },
    {
        "label": "Éducateur environnemental"
    },
    {
        "label": "Coordinateur de programmes de sensibilisation environnementale"
    },
    {
        "label": "Technologie environnementale"
    },
    {
        "label": "Technicien en systèmes environnementaux"
    },
    {
        "label": "Spécialiste en modélisation environnementale"
    },
    {
        "label": "Conservation et préservation"
    },
    {
        "label": "Biologiste de la conservation"
    },
    {
        "label": "Gestion des risques environnementaux"
    },
    {
        "label": "Spécialiste en gestion des risques environnementaux"
    },
    {
        "label": "Analyste des risques environnementaux"
    },
    {
        "label": "Applicateur de béton décoratif "
    },
    {
        "label": "Boiseur"
    },
    {
        "label": "Charpentier bois"
    },
    {
        "label": "Charpentier métallique "
    },
    {
        "label": "Menuisier "
    },
    {
        "label": "Poseur menuiserie "
    },
    {
        "label": "Coffreur bancheur"
    },
    {
        "label": "Coffreur boiseur "
    },
    {
        "label": "Chef de carrière"
    },
    {
        "label": "Mécanicien d'engins de carrière •"
    },
   
    {
        "label": "Cordiste "
    },
    {
        "label": "Couvreur "
    },
    {
        "label": "Deviseur "
    },
    {
        "label": "Échafaudeur"
    },
    {
        "label": "Étancheur -"
    },
    {
        "label": "Nacelliste Élingueur"
    },
    {
        "label": "Opérateur désamiantage Toit "
    },
    {
        "label": "Poseur applicateur toiture "
    },
    {
        "label": "Chauffeur toupie "
    },
    {
        "label": "Conducteur d'engins en carrière •"
    },
    {
        "label": "Levageur"
    },
    {
        "label": "Conducteur de travaux en construction métallique"
    },
    {
        "label": "Métreur vérandas "
    },
    {
        "label": "Miroitier "
    },
    
    {
        "label": "Technicien froid et climatisation "
    },
    {
        "label": "Technicien VMC"
    },
    {
        "label": "Technico-commercial CVC "
    },
    {
        "label": "Thermicien "
    },
    {
        "label": "Tôlier en ventilation"
    },
    {
        "label": "Chargé de projet éco-construction "
    },
    {
        "label": "Conseiller énergie "
    },
    {
        "label": "Ingénieur efficacité énergétique"
    },
    {
        "label": "Pilote énergie"
    },
    {
        "label": "Ingénieur en génie thermique"
    },
    {
        "label": "Ingénieur fluides"
    },
    {
        "label": "ingénieur thermicien"
    },
    {
        "label": "Monteur en installations sanitaires et thermiques "
    },
    {
        "label": "Technicien hygiène sécurité environnement "
    },
    {
        "label": "Ingénieur Urbaniste"
    },
    {
        "label": "Aide-sondeur "
    },
    {
        "label": "Technicien de terrain "
    },
    {
        "label": " Ingénieur géomètre foncier "
    },
    {
        "label": "Métreur"
    },
    {
        "label": "Métreur économiste de la construction "
    },
    {
        "label": "Opérateur-géomètre"
    },
    {
        "label": "Sondeur géotechnique"
    },
    {
        "label": "Téléopérateur de drone"
    },
    {
        "label": "Chargé d'étude cartographie"
    },
    {
        "label": "Assistant technicien géomètre "
    },
    {
        "label": "Dessinateur cartographe "
    },
    {
        "label": "Dessinateur géomètre REVIT"
    },
    {
        "label": "Géomètre topographe "
    },
    {
        "label": "Géomètre-expert"
    },
    {
        "label": "Géotechnicien"
    },
    {
        "label": " Géotechnicien expert "
    },
    {
        "label": " Ingénieur d'études géotechniques "
    },
    {
        "label": "Technicien cartographie réseau "
    },
    {
        "label": "Assistant d'études géomatique"
    },
    {
        "label": "Chef de projet SIG "
    },
    {
        "label": "Ingénieur SIG "
    },
    {
        "label": "Technicien cartographe SIG "
    },
    {
        "label": "Technicien de l'information géographique"
    },
    {
        "label": "Technicien SIG "
    },
    {
        "label": "Dessinateur projeteur topographe"
    },
    {
        "label": "Photogrammètre"
    },
    {
        "label": "Technicien géodétection et géoréférencement"
    },
    {
        "label": "Technicien projeteur synthèse réseaux "
    },
    
    {
        "label": "Dessinateur 2D"
    },
    {
        "label": "Architectes en chef"
    },
    {
        "label": "Concepteurs en environnement"
    },
    {
        "label": "Ingénieurs en éclairage"
    },
    {
        "label": "Ingénieurs aérospatiaux"
    },
    {
        "label": "Ingénieurs CI/CD"
    },
    {
        "label": "Ingénieurs en protection incendie"
    },
    {
        "label": "Ingénieurs mécaniques"
    },
    {
        "label": "Architectes"
    },
    {
        "label": "Ingénieurs civils"
    },
    {
        "label": "Concepteurs de maisons"
    },
    {
        "label": "Architectes navals"
    },
    {
        "label": "Modélisateurs architecturaux"
    },
    {
        "label": "Ingénieurs concepteurs"
    },
    {
        "label": "Ingénieurs hydrauliques"
    },
    {
        "label": "Spécialistes de la conception"
    },
    {
        "label": "Ingénieurs industriels"
    },
    {
        "label": "Ingénieurs professionnels"
    },
    {
        "label": "Experts AutoCAD"
    },
    {
        "label": "Ingénieurs électriciens"
    },
    {
        "label": "Ingénieurs en infrastructure"
    },
    {
        "label": "Ingénieurs en robotique"
    },
    {
        "label": "Programmeurs AutoCAD"
    },
    {
        "label": "Consultants en ingénierie"
    },
    {
        "label": "Architectes d'intérieur"
    },
    {
        "label": "Ingénieurs structurels"
    },
    {
        "label": "Concepteurs CAO"
    },
    {
        "label": "Concepteurs en ingénierie"
    },
    {
        "label": "Architectes paysagistes"
    },
    {
        "label": "Concepteurs urbains"
    },
    {
        "label": "Développeur Front-end"
    },
    {
        "label": "Développeur Back-end"
    },
    {
        "label": "Développeur Full-stack"
    },
    {
        "label": "Développeur JavaScript"
    },
    {
        "label": "Développeur PHP"
    },
    {
        "label": "Développeur Python"
    },
    {
        "label": "Développeur Ruby"
    },
    {
        "label": "Développeur Java"
    },
    {
        "label": "Développeur .NET"
    },
    {
        "label": "Développeur mobile"
    },
    {
        "label": "Développeur d'applications web réactives"
    },
    {
        "label": "Développeur de commerce électronique"
    },
    {
        "label": "Développeur de jeux en ligne"
    },
    {
        "label": "Développeur web freelance"
    },
    {
        "label": "Développeur web en agence"
    },
    {
        "label": "Développeur web en entreprise"
    },
    {
        "label": "Développeur web en start-up"
    },
    {
        "label": "Développeur web spécialisé en sécurité"
    },
    {
        "label": "Développeur web spécialisé en optimisation des performances"
    },
    {
        "label": "Développeur web spécialisé en accessibilité."
    },
    {
        "label": "Design d'interfaces utilisateur (UI)"
    },
    {
        "label": "Design d'expérience utilisateur (UX)"
    },
    {
        "label": "Design responsive"
    },
    {
        "label": "Design de site e-commerce"
    },
    {
        "label": "Design de site institutionnel"
    },
    {
        "label": "Design d'applications web"
    },
    {
        "label": "Design de blogs et de contenu"
    },
    {
        "label": "Design de portfolio en ligne"
    },
    {
        "label": "Design d'animation web"
    },
    {
        "label": "Design pour la vitesse et la performance"
    },
    {
        "label": "Design pour l'accessibilité."
    },
    {
        "label": "Conception d'expérience utilisateur (UX)"
    },
    {
        "label": "Personas"
    },
    {
        "label": "Conception d'interfaces mobiles"
    },
    {
        "label": "Conception d'interfaces web"
    },
    {
        "label": "Wireframing et prototypage"
    },
    {
        "label": "Maquettes haute fidélité"
    },
    {
        "label": "Prototypes interactifs"
    },
    {
        "label": "Design système"
    },
    {
        "label": "Conception d'écrans mobiles"
    },
    {
        "label": "Conception d'écrans desktop"
    },
    {
        "label": "Conception d'applications logicielles"
    },
    {
        "label": "Conception d'applications web"
    },
    {
        "label": "Conception pour la conversion"
    },
    {
        "label": "Angular"
    },
    {
        "label": "Développement d'applications mobiles avec Angular et Ionic"
    },
    {
        "label": "Programmation orientée composants avec Angular"
    },
    {
        "label": "Gestion de l'état de l'application avec NgRx (pour les applications Redux)"
    },
    {
        "label": "Intégration avec des API REST ou GraphQL"
    },
    {
        "label": "Création de composants réutilisables en Angular"
    },
    {
        "label": "Routage et navigation dans les applications Angular"
    },
    {
        "label": "Tests unitaires et d'intégration avec Jasmine et Karma"
    },
    {
        "label": "Optimisation des performances des applications Angular"
    },
    {
        "label": "Développeur Front-end JavaScript"
    },
    {
        "label": "Développeur Back-end JavaScript"
    },
    {
        "label": "Développeur Full-stack JavaScript"
    },
    {
        "label": "Développeur JavaScript senior"
    },
    {
        "label": "Développeur JavaScript freelance"
    },
    {
        "label": "Développeur JavaScript en agence"
    },
    {
        "label": "Développeur JavaScript en entreprise"
    },
    {
        "label": "Développeur JavaScript pour l'optimisation des performances"
    },
    {
        "label": "Développeur JavaScript pour les jeux en ligne"
    },
    {
        "label": "Développeur JavaScript pour les chatbots et l'automatisation"
    },
    {
        "label": "Développeur JavaScript pour l'IoT."
    },
    {
        "label": "Développeur Front-end CSS"
    },
    {
        "label": "Spécialiste en animations CSS"
    },
    {
        "label": "Développeur CSS responsive"
    },
    {
        "label": "Développeur PHP Full-stack"
    },
    {
        "label": "Développeur PHP Front-end"
    },
    {
        "label": "Développeur PHP Back-end"
    },
    
    {
        "label": "Développeur en web scraping"
    },
    {
        "label": "Développeur Front-end Backbone.js"
    },
    {
        "label": "Développeur Back-end Backbone.js"
    },
    {
        "label": "Développeur Full-stack Backbone.js"
    },
    {
        "label": "Développeur Backbone.js senior"
    },
    {
        "label": "Développeur Backbone.js freelance"
    },
    
    {
        "label": "Développeur Vue.js"
    },
    {
        "label": "Développeur Vue.js avec Vuex"
    },
    {
        "label": "Développeur Full-stack Vue.js"
    },
    
    {
        "label": "Développeur RxJS"
    },
    
    {
        "label": "Développeur Front-end Meteor"
    },
    {
        "label": "Développeur Back-end Meteor"
    },
    {
        "label": "Développeur Full-stack Meteor"
    },
    
    {
        "label": "Développeur Back-end Laravel"
    },
    {
        "label": "Développeur Full-stack Laravel"
    },
    
    {
        "label": "Développeur Laravel pour les CMS"
    },
    {
        "label": "Développeur Back-end CakePHP"
    },
    {
        "label": "Développeur Full-stack CakePHP"
    },
    
    {
        "label": "Développeur Back-end CodeIgniter"
    },
    {
        "label": "Développeur Full-stack CodeIgniter"
    },
    
    {
        "label": "Développeur Front-end Angular"
    },
    {
        "label": "Développeur Back-end Node.js"
    },
    {
        "label": "Développeur Full-stack MEAN Stack"
    },
    {
        "label": "Développeur MEAN Stack senior"
    },
    {
        "label": "Développeur MEAN Stack freelance"
    },
    
    {
        "label": "Développeur Front-end React"
    },
    {
        "label": "Développeur React Native"
    },
    {
        "label": "React js"
    },
    {
        "label": "Développeur Full-stack Node.js"
    },
    {
        "label": "Développeur Node.js senior"
    },
    
    {
        "label": "Développeur Ruby on Rails Full-stack"
    },
    {
        "label": "Développeur Ruby on Rails Front-end"
    },
    {
        "label": "Développeur Ruby on Rails Back-end"
    },
    {
        "label": "Développeur Ruby on Rails Mobile"
    },
    
    {
        "label": "Développeur TypeScript"
    },
    
    {
        "label": "Développeur Back-end Django"
    },
    {
        "label": "Développeur Full-stack Django"
    },
    
    {
        "label": "Développeur API RESTful"
    },
    {
        "label": "Développeur API GraphQL"
    },
    {
        "label": "Développeur API SOAP"
    },
    {
        "label": "Développeur API WebSocket"
    },
    {
        "label": "Développeur API JSON-RPC"
    },
    {
        "label": "Développeur API de paiement"
    },
    {
        "label": "Développeur API de médias sociaux"
    },
    {
        "label": "Développeur API de géolocalisation"
    },
    {
        "label": "Développeur API Cloud"
    },
    {
        "label": "Développeur API de gestion des utilisateurs"
    },
    {
        "label": "Développeur API de gestion des données"
    },
    {
        "label": "Développeur API de l'Internet des objets (IoT)."
    },
    {
        "label": "Développeur Back-end Yii"
    },
    {
        "label": "Développeur Full-stack Yii"
    },
    {
        "label": "Développeur Yii senior"
    },
   
    {
        "label": "Développeur ASP.NET Web Forms"
    },
    {
        "label": "Développeur ASP.NET MVC"
    },
    {
        "label": "Développeur ASP.NET Core"
    },
    {
        "label": "Développeur ASP.NET Blazor"
    },
    {
        "label": "Développeur ASP.NET Razor Pages"
    },
    {
        "label": "Développeur ASP.NET API"
    },
    {
        "label": "Développeur ASP.NET SignalR"
    },
    {
        "label": "Développeur ASP.NET pour la sécurité"
    },
    {
        "label": "Développeur ASP.NET pour les CMS"
    },
    
    {
        "label": "Ingénieur DevOps Cloud"
    },
    {
        "label": "Ingénieur DevOps Kubernetes"
    },
    {
        "label": "Ingénieur DevOps automatisation"
    },
    {
        "label": "Ingénieur DevOps sécurité"
    },
    {
        "label": "Ingénieur DevOps réseau"
    },
    {
        "label": "Ingénieur DevOps base de données"
    },
    {
        "label": "Ingénieur DevOps outils"
    },
    {
        "label": "Ingénieur DevOps CI/CD (Intégration continue / Livraison continue)"
    },
    {
        "label": "Ingénieur DevOps monitoring"
    },
    {
        "label": "Ingénieur DevOps support technique"
    },
    {
        "label": "Ingénieur DevOps gestion des incidents"
    },
    {
        "label": "Ingénieur DevOps en entreprise"
    },
    {
        "label": "Ingénieur de données Big Data"
    },
    {
        "label": "Ingénieur de données SQL"
    },
    {
        "label": "Ingénieur de données NoSQL"
    },
    {
        "label": "Ingénieur de données ETL (Extract, Transform, Load)"
    },
    {
        "label": "Ingénieur de données en streaming"
    },
    {
        "label": "Ingénieur de données cloud"
    },
    {
        "label": "Ingénieur de données en entreprise"
    },
    {
        "label": "Ingénieur de données en marketing"
    },
    {
        "label": "Ingénieur de données en santé"
    },
    {
        "label": "Ingénieur de données géospatiales"
    },
    {
        "label": "Ingénieur de données financières"
    },
    {
        "label": "Ingénieur de données pour l'analytique avancée."
    },
    {
        "label": "Développeur de bases de données SQL"
    },
    {
        "label": "Développeur de bases de données NoSQL"
    },
    {
        "label": "Développeur de bases de données Big Data"
    },
    {
        "label": "Développeur de bases de données cloud"
    },
    {
        "label": "Développeur de bases de données géospatiales"
    },
    {
        "label": "Développeur de bases de données temps réel"
    },
    {
        "label": "Développeur de bases de données d'entreprise"
    },
    {
        "label": "Développeur de bases de données pour l'analytique"
    },
    {
        "label": "Développeur de bases de données marketing"
    },
    {
        "label": "Développeur de bases de données financières"
    },
    {
        "label": "Développeur de bases de données pour les applications mobiles"
    },
    {
        "label": "Développeur de bases de données en entreprise."
    },
    {
        "label": "Ingénieur des systèmes distribués cloud"
    },
    {
        "label": "Ingénieur des systèmes distribués Big Data"
    },
    {
        "label": "Ingénieur des systèmes distribués en conteneurs"
    },
    {
        "label": "Ingénieur des systèmes distribués IoT (Internet des objets)"
    },
    {
        "label": "Ingénieur des systèmes distribués en temps réel"
    },
    {
        "label": "Ingénieur des systèmes distribués en entreprise"
    },
    {
        "label": "Ingénieur des systèmes distribués en finance"
    },
    {
        "label": "Ingénieur des systèmes distribués en santé"
    },
    {
        "label": "Ingénieur des systèmes distribués en e-commerce"
    },
    {
        "label": "Ingénieur des systèmes distribués en jeux vidéo"
    },
    {
        "label": "Ingénieur des systèmes distribués en logistique"
    },
    {
        "label": "Ingénieur des systèmes distribués en aviation."
    },
    {
        "label": "Développeur AWS cloud natif"
    },
    {
        "label": "Développeur AWS Serverless"
    },
    {
        "label": "Développeur AWS DevOps"
    },
    
    {
        "label": "Développeur AWS IoT (Internet des objets)"
    },
    {
        "label": "Développeur AWS Machine Learning"
    },
    {
        "label": "Développeur AWS sécurité"
    },
    {
        "label": "Développeur AWS en entreprise"
    },
    {
        "label": "Développeur AWS en startup"
    },
    {
        "label": "Développeur AWS en gouvernement."
    },
    {
        "label": "Développeur d'Applications Web Azure"
    },
    {
        "label": "Développeur de Fonctions Azure"
    },
    {
        "label": "Développeur de Services de Conteneurs Azure"
    },
    {
        "label": "Développeur de Bases de Données Azure"
    },
    {
        "label": "Développeur Azure DevOps"
    },
    {
        "label": "Développeur de Solutions IoT Azure"
    },
    {
        "label": "Développeur de Flux de Données Azure"
    },
    {
        "label": "Développeur de Machine Learning Azure"
    },
    {
        "label": "Développeur de Chatbots Azure"
    },
    {
        "label": "Développeur de Fonctions d'Intelligence Artificielle (IA) Azure"
    },
    {
        "label": "Développeur Azure Logic Apps"
    },
    {
        "label": "Développeur Azure Synapse Analytics"
    },
    {
        "label": "Développeur Azure Active Directory"
    },
    {
        "label": "Développeur Azure Functions"
    },
    {
        "label": "Développeur de Solutions Serverless Azure"
    },
    {
        "label": "Développeur Azure en Gestion des Ressources"
    },
    {
        "label": "Développeur Azure pour l'Éducation"
    },
    {
        "label": "Développeur Azure pour la Sécurité"
    },
    {
        "label": "Développeur d'Applications Kubernetes AKS"
    },
    {
        "label": "Administrateur de Cluster AKS"
    },
    {
        "label": "Développeur Helm AKS"
    },
    {
        "label": "Développeur de Services Kubernetes AKS"
    },
    {
        "label": "Développeur de Manifestes Kubernetes AKS"
    },
    {
        "label": "Développeur de Flux de Travail CI/CD pour AKS"
    },
    {
        "label": "Développeur Istio AKS"
    },
    {
        "label": "Développeur de Contrôleurs Kubernetes AKS"
    },
    {
        "label": "Développeur de Politiques de Sécurité AKS"
    },
    {
        "label": "Développeur de Solutions DevOps AKS"
    },
    {
        "label": "Développeur de Tableaux de Bord AKS"
    },
    {
        "label": "Développeur de Plugins Kubernetes AKS"
    },
    {
        "label": "Architecture de l'information"
    },
    {
        "label": "Design d'interaction"
    },
    {
        "label": "Design d'interface"
    },
    {
        "label": "Prototypage et wireframing"
    },
    {
        "label": "Tests utilisateurs"
    },
    {
        "label": "Stratégie UX"
    },
    {
        "label": "Design de contenu"
    },
    {
        "label": "Design émotionnel"
    },
    {
        "label": "Design d'icônes"
    },
    {
        "label": "Typographie"
    },
    {
        "label": "Design de systèmes (Design Systems)"
    },
    {
        "label": "Palettes de couleurs"
    },
    {
        "label": "Maquettes et mockups"
    },
    {
        "label": "Animation et transitions"
    },
    {
        "label": "Design pour mobile (iOS, Android)"
    },
    {
        "label": "Design web"
    },
    {
        "label": "Design d'applications logicielles"
    },
    {
        "label": "Design pour iOS"
    },
    {
        "label": "Design pour Android"
    },
    {
        "label": "Design d'icônes mobiles"
    },
    {
        "label": "Design d'interfaces pour smartwatches"
    },
    {
        "label": "Design responsive pour tablettes"
    },
    {
        "label": "Design d'interfaces pour d'autres dispositifs mobiles (ex. lunettes AR)"
    },
    {
        "label": "Animation et transitions pour mobile"
    },
    {
        "label": "Adaptation de l'UI pour différents écrans et résolutions"
    },
    {
        "label": "Design de systèmes pour mobile (Design Systems)"
    },
    {
        "label": "Conception d'interfaces pour applications hybrides."
    },
    {
        "label": "Design de sites e-commerce"
    },
    {
        "label": "Design de sites institutionnels"
    },
    {
        "label": "Design de blogs et magazines en ligne"
    },
    {
        "label": "Design d'interfaces web app"
    },
    {
        "label": "Design responsive et adaptatif"
    },
    
    {
        "label": "Web animation et interactions."
    },
    {
        "label": "Illustration numérique"
    },
    {
        "label": "Illustration traditionnelle (ex. aquarelle, crayon)"
    },
    {
        "label": "Illustration éditoriale (pour magazines, journaux)"
    },
    {
        "label": "Illustration de livres (couvertures, intérieurs)"
    },
    {
        "label": "Bande dessinée et roman graphique"
    },
    {
        "label": "Caricature et dessin humoristique"
    },
    {
        "label": "Illustration de mode"
    },
    {
        "label": "Illustration scientifique ou médicale"
    },
    {
        "label": "Illustration pour jeux (jeux vidéo, jeux de société)"
    },
    {
        "label": "Illustration pour enfants."
    },
    {
        "label": "Design de mobilier"
    },
    {
        "label": "Design industriel"
    },
    {
        "label": "Design d'éclairage"
    },
    {
        "label": "Design de jouets"
    },
    {
        "label": "Design d'emballage et packaging"
    },
    {
        "label": "Design d'accessoires (ex. bijoux, sacs)"
    },
    {
        "label": "Design d'électronique grand public"
    },
    {
        "label": "Design de produits durables et éco-conçus"
    },
    {
        "label": "Design pour la santé et dispositifs médicaux"
    },
    {
        "label": "Design automobile et transport."
    },
   
    {
        "label": "Direction créative en design graphique"
    },
    {
        "label": "Direction créative en digital/numérique"
    },
    {
        "label": "Direction créative en mode"
    },
    {
        "label": "Direction créative en cinéma et télévision"
    },
    {
        "label": "Direction créative en musique"
    },
    {
        "label": "Direction créative en jeux vidéo"
    },
    {
        "label": "Direction créative en édition et publications"
    },
    {
        "label": "Direction créative en événementiel."
    },
    {
        "label": "Designer graphique freelance"
    },
    
    {
        "label": "Utilisation avancée de symboles et de bibliothèques partagées"
    },
    {
        "label": "Intégration de Sketch avec d'autres outils (ex. Zeplin, InVision)"
    },
    {
        "label": "Design responsive et adaptatif avec Sketch"
    },
   
    {
        "label": "Recherche quantitative"
    },
    {
        "label": "Tests d'utilisabilité"
    },
    {
        "label": "Recherche ethnographique"
    },
    {
        "label": "Tri de cartes (Card Sorting)"
    },
    {
        "label": "Tests A/B"
    },
    {
        "label": "Enquêtes et sondages"
    },
    {
        "label": "Journaux et diaries"
    },
    {
        "label": "Développement web avec Django"
    },
    {
        "label": "Développement web avec Flask"
    },
    {
        "label": "Science des données avec Pandas et NumPy"
    },
    {
        "label": "Apprentissage automatique avec TensorFlow et Scikit-learn"
    },
    {
        "label": "Développement de scripts et d'automatisations"
    },
    {
        "label": "Développement d'applications de bureau avec PyQt ou Tkinter"
    },
    {
        "label": "Développement de jeux avec Pygame"
    },
    {
        "label": "Administration système et automatisation avec Python"
    },
    {
        "label": "Traitement du texte et des expressions régulières"
    },
    {
        "label": "Développement pour le web scraping avec Beautiful Soup ou Scrapy."
    },
    
    {
        "label": "Développement de gems Ruby personnalisés"
    },
    {
        "label": "Test et spécification avec RSpec"
    },
    {
        "label": "Déploiement et intégration continue pour applications Ruby"
    },
    {
        "label": "Développement d'APIs en Ruby"
    },
    {
        "label": "Programmation orientée objet en Ruby"
    },
    {
        "label": "Optimisation et performance en Ruby"
    },
    {
        "label": "Développement avec le framework Hanami."
    },
    {
        "label": "Développement avec le framework .NET Core"
    },
    {
        "label": "Développement d'applications de bureau avec Windows Forms ou WPF"
    },
    {
        "label": "Développement de jeux avec Unity"
    },
    {
        "label": "Développement d'applications web avec ASP.NET MVC"
    },
    {
        "label": "Programmation pour Azure (services cloud de Microsoft)"
    },
    {
        "label": "Développement pour Xamarin (applications mobiles)"
    },
    {
        "label": "Programmation orientée objet en C#"
    },
    {
        "label": "Développement d'applications d'entreprise avec Entity Framework"
    },
    {
        "label": "Test et spécification avec NUnit ou MSTest"
    },
    {
        "label": "Développement d'applications Windows UWP."
    },
    {
        "label": "Développement d'applications web avec Spring Boot"
    },
    {
        "label": "Développement d'applications d'entreprise avec Java EE"
    },
    {
        "label": "Développement d'applications mobiles avec Android"
    },
    {
        "label": "Programmation avec le framework Hibernate"
    },
    {
        "label": "Développement d'applications de bureau avec JavaFX ou Swing"
    },
    {
        "label": "Développement de microservices avec Spring Cloud"
    },
    {
        "label": "Intégration et développement d'APIs avec JAX-RS ou Spring REST"
    },
    {
        "label": "Programmation orientée objet en Java"
    },
    {
        "label": "Test et spécification avec JUnit ou TestNG"
    },
    {
        "label": "Développement pour des systèmes embarqués en Java."
    },
    {
        "label": "Développement de systèmes"
    },
    {
        "label": "Programmation WebAssembly avec Rust"
    },
    {
        "label": "Développement de jeux avec Amethyst"
    },
    {
        "label": "Développement d'applications web avec le framework Rocket"
    },
    {
        "label": "\"Programmation concurrente et utilisation des \"\"futures\"\" en Rust\""
    },
    {
        "label": "Optimisation de performance et programmation bas-niveau"
    },
    {
        "label": "Intégration et développement d'APIs avec Rust"
    },
    {
        "label": "Développement de bibliothèques et crates personnalisés"
    },
    {
        "label": "Programmation orientée objet et fonctionnelle en Rust"
    },
    {
        "label": "Développement pour l'embarqué avec Rust."
    },
    {
        "label": "Développement avec le framework OTP (Open Telecom Platform)"
    },
    {
        "label": "Programmation concurrente et distribution de systèmes"
    },
    {
        "label": "Développement d'applications web avec Cowboy ou Yaws"
    },
    {
        "label": "Gestion des bases de données NoSQL avec Mnesia"
    },
    {
        "label": "Optimisation et débogage de systèmes en temps réel"
    },
    {
        "label": "Intégration et développement d'APIs avec Erlang"
    },
    {
        "label": "Programmation fonctionnelle en Erlang"
    },
    {
        "label": "Développement pour les télécommunications avec Erlang"
    },
    {
        "label": "Tests et spécification avec EUnit ou Common Test"
    },
    {
        "label": "Développement de systèmes distribués résilients avec Erlang."
    },
    {
        "label": "Développement d'applications web avec le framework Ring et Compojure"
    },
    {
        "label": "Développement d'interfaces utilisateur avec Reagent ou re-frame"
    },
    {
        "label": "Programmation fonctionnelle en Clojure"
    },
    {
        "label": "Gestion de données avec la bibliothèque Datomic"
    },
    {
        "label": "Développement d'applications concurrentes avec core.async"
    },
    {
        "label": "Scripting et automatisation avec Clojure"
    },
    {
        "label": "Intégration et développement d'APIs avec Clojure"
    },
    {
        "label": "Développement de microservices en Clojure"
    },
    {
        "label": "Tests et spécification avec Clojure.test ou Spec"
    },
    {
        "label": "Développement avec ClojureScript pour des applications front-end."
    },
    {
        "label": "Développement d'applications mobiles avec Flutter"
    },
    {
        "label": "Programmation fonctionnelle en Dart"
    },
    {
        "label": "Développement d'applications web avec AngularDart"
    },
    {
        "label": "Développement d'applications de bureau avec Flutter"
    },
    {
        "label": "Gestion d'état avec des solutions comme Riverpod ou Provider"
    },
    {
        "label": "Intégration et développement d'APIs avec Dart"
    },
    {
        "label": "Tests et spécification avec le package test de Dart"
    },
    {
        "label": "Développement orienté objet en Dart"
    },
    {
        "label": "Optimisation et performance avec Llama 2"
    },
    {
        "label": "Tests et validation de l'interaction utilisateur avec Llama 2"
    },
    {
        "label": "Gestion de la scalabilité avec l'API Llama 2"
    },
    {
        "label": "Intégration de Llama 2 dans des systèmes existants"
    },
    {
        "label": "Évaluation des performances de Llama 2 par rapport à d'autres modèles"
    },
    {
        "label": "Sécurité et confidentialité avec Llama 2"
    },
    {
        "label": "Développement d'outils et d'utilitaires basés sur Llama 2."
    },
    {
        "label": "Intégration de LLMs avec LangChain"
    },
    {
        "label": "Développement d'applications de chatbot avec LangChain"
    },
    {
        "label": "Conception d'interfaces pour des applications LangChain"
    },
    {
        "label": "Gestion de données et intégration avec d'autres outils"
    },
    {
        "label": "Développement d'applications web avec LangChain"
    },
    {
        "label": "Intégration de LangChain avec Python ou JavaScript"
    },
    {
        "label": "Optimisation et personnalisation des modèles LLM avec LangChain"
    },
    {
        "label": "Tests et validation de modèles avec LangChain"
    },
    {
        "label": "Gestion de la scalabilité des applications LangChain"
    },
    {
        "label": "Sécurité et confidentialité dans le développement avec LangChain."
    },
    {
        "label": "Développeurs de logiciels"
    },
    
    {
        "label": "Testeur QA automatisé"
    },
    {
        "label": "Testeur QA de performance"
    },
    {
        "label": "Testeur QA de sécurité"
    },
    {
        "label": "Testeur QA de compatibilité"
    },
    {
        "label": "Testeur QA de localisation"
    },
    {
        "label": "Testeur QA de jeux vidéo"
    },
    {
        "label": "Testeur QA de logiciels mobiles"
    },
    {
        "label": "Testeur QA de logiciels en nuage (Cloud)"
    },
    {
        "label": "Testeur QA de logiciels embarqués"
    },
    {
        "label": "Testeur QA de logiciels ERP"
    },
    {
        "label": "Testeur QA de logiciels CRM."
    },
    {
        "label": "esteur manuel fonctionnel"
    },
    {
        "label": "Testeur manuel d'interface utilisateur (UI)"
    },
    {
        "label": "Testeur manuel de convivialité"
    },
    {
        "label": "Testeur manuel de compatibilité"
    },
    {
        "label": "Testeur manuel de performance"
    },
    
    {
        "label": "Chef QA de projet"
    },
    {
        "label": "Chef QA manuel"
    },
    {
        "label": "Chef QA automatisé"
    },
    
    {
        "label": "Ingénieur en automatisation des tests de logiciels"
    },
    
    {
        "label": "Développeur Selenium Grid"
    },
    {
        "label": "Sécurité et Monétisation"
    },
    {
        "label": "Programmation AR"
    },
    {
        "label": "Conception AR"
    },
    {
        "label": "Traitement d'Image/Vidéo"
    },
    {
        "label": "Interaction et Interface Utilisateur"
    },
    {
        "label": "Détection de la Profondeur"
    },
    {
        "label": "Systèmes d'Exploitation/Plateformes"
    },
    {
        "label": "Intelligence Artificielle et Apprentissage Machine"
    },
    {
        "label": "Test et Débogage AR"
    },
    {
        "label": "Conception d'Interfaces Utilisateur (UI) AR"
    },
    {
        "label": "Conception d'Expériences AR"
    },
    {
        "label": "Conception d'Objets Virtuels AR"
    },
    {
        "label": "Design Spatial AR"
    },
    {
        "label": "Design Sonore AR"
    },
    {
        "label": "Utilisabilité et Ergonomie"
    },
    {
        "label": "Design de Marque AR"
    },
    {
        "label": "Optimisation des Performances AR"
    },
    {
        "label": "Tests et Itérations AR"
    },
    {
        "label": "Programmation VR"
    },
    {
        "label": "Conception d'Expériences VR"
    },
    {
        "label": "Intégration de Contrôleurs VR"
    },
    {
        "label": "Modélisation 3D VR"
    },
    {
        "label": "Optimisation des Performances VR"
    },
    {
        "label": "Interaction VR"
    },
    {
        "label": "Audio Spatial VR"
    },
    {
        "label": "Systèmes d'Exploitation et Plateformes VR"
    },
    {
        "label": "Tests et Débogage VR"
    },
    {
        "label": "Réseaux et Multijoueur VR"
    },
    {
        "label": "Animation de Personnages 3D"
    },
    {
        "label": "Animation d'Objets 3D"
    },
    {
        "label": "Animation de Créatures Fantastiques"
    },
    {
        "label": "Animation Faciale"
    },
    {
        "label": "Animation de Caméra"
    },
    {
        "label": "Animation de Jeux Vidéo"
    },
    {
        "label": "Effets Spéciaux (VFX)"
    },
    {
        "label": "Modélisation 3D"
    },
    {
        "label": "Texturage"
    },
    {
        "label": "Éclairage et Rendu"
    },
    {
        "label": "Logiciels 3D"
    },
    {
        "label": "Motion Capture (MoCap)"
    },
    {
        "label": "Art et Créativité"
    },
    {
        "label": "Conception de Mécanismes de Jeu"
    },
    {
        "label": "Conception de Niveaux"
    },
    {
        "label": "Conception Visuelle"
    },
    {
        "label": "Conception Sonore"
    },
    {
        "label": "Équilibrage du Jeu"
    },
    {
        "label": "Test et Analyse"
    },
    {
        "label": "Narration et Scénarisation"
    },
    {
        "label": "Adaptation aux Plateformes"
    },
    {
        "label": "Marketing et Promotion"
    },
    {
        "label": "Suivi des Tendances"
    },
    {
        "label": "Analyse du Public Cible"
    },
    {
        "label": "Sensibilité au Mobile"
    },
    {
        "label": "Programmation"
    },
    {
        "label": "Traitement d'Images"
    },
    {
        "label": "Détection d'Objets"
    },
    {
        "label": "Suivi d'Objets"
    },
    {
        "label": "Reconnaissance de Formes"
    },
    {
        "label": "Segmentation d'Images"
    },
    {
        "label": "Apprentissage Profond (Deep Learning)"
    },
    {
        "label": "Localisation et Cartographie (SLAM)"
    },
    {
        "label": "Analyse Vidéo"
    },
    {
        "label": "Calibrage de Caméra"
    },
    {
        "label": "Intégration Matérielle"
    },
    {
        "label": "Analyse de Données 3D"
    },
    {
        "label": "Optimisation et Débogage"
    },
    {
        "label": "Applications Spécialisées"
    },
    {
        "label": "Éthique et Sécurité"
    },
    
    {
        "label": "Animation de Jeux Vidéo 2D"
    },
    {
        "label": "Animation de Publicités et de Clips Musicaux"
    },
    {
        "label": "Effets Spéciaux 2D (VFX)"
    },
    {
        "label": "Conception de Personnages 2D"
    },
    {
        "label": "Texturage et Colorisation"
    },
    {
        "label": "Logiciels d'Animation 2D"
    },
    {
        "label": "Timing et Rythme"
    },
    {
        "label": "Connaissance des Principes de l'Animation"
    },
    {
        "label": "Collaboration"
    },
    {
        "label": "Dessin Numérique"
    },
    {
        "label": "Peinture Numérique"
    },
    {
        "label": "Texturage et Mapping UV"
    },
    {
        "label": "Rigging"
    },
    {
        "label": "Animation 3D"
    },
    {
        "label": "Effets Visuels (VFX)"
    },
    {
        "label": "Sculpture Numérique"
    },
    {
        "label": "Storyboarding"
    },
    {
        "label": "Conception d'Interface Utilisateur (UI)"
    },
    {
        "label": "Conception de Logo et de Marque"
    },
    {
        "label": "Illustration Vectorielle"
    },
    {
        "label": "Photomanipulation"
    },
    
    {
        "label": "Déploiement sur Différentes Plateformes"
    },
    {
        "label": "Gestion de Projet"
    },
    {
        "label": "Maîtrise de JavaScript"
    },
    {
        "label": "Utilisation de Three.js"
    },
    {
        "label": "Scène et Caméra"
    },
    {
        "label": "Lumières et Matériaux"
    },
    {
        "label": "Physique 3D"
    },
    {
        "label": "Réseaux et Intégration"
    },
    {
        "label": "Déploiement Web"
    },
    {
        "label": "Gestion des Ressources"
    },
    {
        "label": "Débogage et Tests"
    },
    {
        "label": "Mathématiques 3D"
    },
    
    {
        "label": "Développement de Jeux Vidéo"
    },
    {
        "label": "Développement Embarqué"
    },
    {
        "label": "Programmation Réseau"
    },
    {
        "label": "Débogage"
    },
    {
        "label": "Connaissance des Frameworks C++"
    },
    {
        "label": "Intégration de Langages et de Bibliothèques Externes"
    },
    {
        "label": "Développeur iOS (Apple App Store)"
    },
    {
        "label": "Développeur Android (Google Play Store)"
    },
    {
        "label": "Développeur d'applications natives"
    },
    {
        "label": "Développeur d'applications hybrides"
    },
    {
        "label": "Développeur de jeux mobiles"
    },
    {
        "label": "Développeur d'applications e-commerce"
    },
    {
        "label": "Développeur d'applications de médias sociaux"
    },
    {
        "label": "Développeur d'applications de productivité"
    },
    {
        "label": "Développeur d'applications de santé et de bien-être"
    },
    {
        "label": "Développeur d'applications éducatives."
    },
    {
        "label": "Développeur d'applications Android natives"
    },
    {
        "label": "Développeur d'applications Android hybrides"
    },
    {
        "label": "Développeur de jeux Android"
    },
    {
        "label": "Développeur d'applications Android pour l'IoT (Internet des objets)"
    },
    {
        "label": "Développeur d'applications Android pour l'e-commerce"
    },
    {
        "label": "Développeur d'applications Android de streaming vidéo"
    },
    
    {
        "label": "Maîtrise de Java/Kotlin"
    },
    {
        "label": "Utilisation d'Android Studio"
    },
    {
        "label": "Gestion des Composants Android"
    },
    {
        "label": "Connaissance des API Android"
    },
    {
        "label": "Gestion des Données"
    },
    {
        "label": "Navigation et Flux d'Écrans"
    },
    {
        "label": "Connaissance des Bibliothèques Tierces"
    },
    {
        "label": "Marketing et Lancement"
    },
    {
        "label": "Collecte et Analyse de Données"
    },
    {
        "label": "Formation et Support Utilisateur"
    },
    {
        "label": "Gestion des Retours Utilisateurs"
    },
    {
        "label": "Évolution et Mises à Jour"
    },
    {
        "label": "Documentation et Rapports"
    },
    {
        "label": "Recherche Utilisateur"
    },
    {
        "label": "Analyse de l'Utilisateur"
    },
    {
        "label": "Conception de l'Architecture de l'Information"
    },
    {
        "label": "Wireframing"
    },
    {
        "label": "Prototypage"
    },
    {
        "label": "Design Responsif"
    },
    {
        "label": "Animation et Transitions"
    },
    {
        "label": "Tests Utilisateur"
    },
    {
        "label": "Optimisation de la Performance"
    },
    {
        "label": "Accessibilité"
    },
    {
        "label": "Sélection d'Images et de Médias"
    },
    {
        "label": "Collaboration avec les Développeurs"
    },
    {
        "label": "Conformité aux Normes de Design"
    },
    {
        "label": "Connaissance des Tendances et des Technologies"
    },
    {
        "label": "Documentation de Conception"
    },
    {
        "label": "Sens de l'Esthétique"
    },
    {
        "label": "Feedback et Itérations"
    },
    {
        "label": "Conception d'Interfaces Utilisateur (UI)"
    },
    {
        "label": "Conception de l'Expérience Utilisateur (UX)"
    },
   
    {
        "label": "Connaissance des API iOS"
    },
    {
        "label": "Gestion de Données"
    },
    {
        "label": "Réseaux et Services Web"
    },
    {
        "label": "Optimisation des Performances"
    },
    {
        "label": "Notifications"
    },
    {
        "label": "Conformité aux Directives d'App Store"
    },
    {
        "label": "Adaptabilité aux Différents Appareils"
    },
    {
        "label": "Internationalisation et Localisation"
    },
    {
        "label": "Documentation"
    },
    {
        "label": "Conception de Jeu"
    },
    {
        "label": "Conception d'Interfaces Utilisateur (UI) de Jeu"
    },
    {
        "label": "Conception d'Environnements"
    },
    {
        "label": "Game Design Document (GDD)"
    },
    {
        "label": "Conception de Personnages"
    },
    {
        "label": "Conception sonore"
    },
    {
        "label": "Tests et Équilibrage"
    },
    {
        "label": "Monétisation"
    },
    {
        "label": "Analyse de Données"
    },
    {
        "label": "Connaissance des Plateformes"
    },
    {
        "label": "Conformité aux Règles de la Plateforme"
    },
    {
        "label": "Mises à Jour et Maintenance"
    },
    {
        "label": "Créativité et Innovation"
    },
    {
        "label": "Maîtrise de Dart"
    },
    {
        "label": "Utilisation de Flutter"
    },
    {
        "label": "Navigation et Gestion de l'État"
    },
    {
        "label": "Intégration de Conception (UI/UX)"
    },
    
    {
        "label": "Animation"
    },
    {
        "label": "Plugin et Package Flutter"
    },
    {
        "label": "Déploiement Multiplateforme"
    },
    {
        "label": "Gestion de Versions"
    },
    {
        "label": "Connaissance des Widgets Personnalisés"
    },
    
    {
        "label": "Utilisation de Xcode"
    },
    {
        "label": "Développement d'Interfaces Utilisateur (UI)"
    },
    {
        "label": "Gestion des Composants d'Interface"
    },
    {
        "label": "Gestion des Composants du Système"
    },
    {
        "label": "Tests et Débogage"
    },
    {
        "label": "Adaptabilité Multiplateforme"
    },
    {
        "label": "Notifications et Interactions Utilisateur"
    },
    {
        "label": "Gestion de Versions et de Déploiement"
    },
    {
        "label": "Scientifiques des données"
    },
    {
        "label": "Ingénieurs en apprentissage automatique"
    },
    {
        "label": "Ingénieurs de données"
    },
    {
        "label": "Analystes de données"
    },
    {
        "label": "Développeurs en vision par ordinateur"
    },
    {
        "label": "Développeurs d'algorithmes"
    },
    {
        "label": "Développeurs Microsoft Access"
    },
    {
        "label": "Ingénieurs MATLAB"
    },
    {
        "label": "Développeurs MATLAB pour la Recherche"
    },
    {
        "label": "Développeurs MATLAB pour l'Analyse de Données"
    },
    {
        "label": "Développeurs MATLAB pour la Simulation"
    },
    {
        "label": "Développeurs MATLAB pour le Traitement d'Images"
    },
    {
        "label": "Développeurs MATLAB pour la Robotique"
    },
    {
        "label": "Développeurs MATLAB pour le Traitement du Signal"
    },
    {
        "label": "Développeurs MATLAB pour la Conception de Contrôleurs"
    },
    {
        "label": "Développeurs MATLAB pour l'Apprentissage Automatique et l'Intelligence Artificielle"
    },
    {
        "label": "Développeurs MATLAB pour la Simulation de Systèmes Électriques"
    },
    {
        "label": "Développeurs MATLAB pour la Biologie et les Sciences de la Vie"
    },
    {
        "label": "Développeurs MATLAB pour l'Automatisation Industrielle"
    },
    {
        "label": "Développeurs MATLAB pour les Finances"
    },
    {
        "label": "Développeurs MATLAB pour la Géophysique et la Géologie"
    },
    {
        "label": "Développeurs MATLAB pour l'Acoustique et l'Audio"
    },
    {
        "label": "Développeurs MATLAB pour la Simulations de Circuits Électroniques"
    },
    {
        "label": "Développeurs MATLAB pour l'Éducation"
    },
    {
        "label": "Développeur de Rapports Power BI"
    },
    {
        "label": "Développeur de Tableaux de Bord Power BI"
    },
    {
        "label": "Développeur de Modèles de Données Power BI"
    },
    {
        "label": "Développeur Power Query"
    },
    
    {
        "label": "Modélisation de Données"
    },
    {
        "label": "Prétraitement de Données"
    },
    {
        "label": "Infrastructures Cloud (AWS, Azure, Google Cloud, etc.)"
    },
    {
        "label": "Utilisation d'API OpenAI (comme GPT-3)"
    },
    {
        "label": "Optimisation de Modèles"
    },
    {
        "label": "Sécurité et Éthique de l'IA"
    },
    {
        "label": "Communication des Résultats"
    },
    {
        "label": "Formation Continue"
    },
    {
        "label": "Adaptabilité aux Nouvelles Technologies"
    },
    {
        "label": "Développeur de Rapports Crystal Reports"
    },
    {
        "label": "Développeur de Connexions de Données Crystal Reports"
    },
    {
        "label": "Développeur de Formules Crystal Reports"
    },
    {
        "label": "Développeur de Mises en Page Crystal Reports"
    },
    {
        "label": "Développeur de Graphiques Crystal Reports"
    },
    {
        "label": "Développeur de Planning et de Distribution de Rapports Crystal Reports"
    },
    {
        "label": "Développeur de Personnalisation de Rapports Crystal Reports"
    },
    {
        "label": "Développeur de Rendu de Rapports Crystal Reports"
    },
    {
        "label": "Développeur de Connexion à des Sources de Données Externes"
    },
    {
        "label": "Développeur de Sécurité des Rapports Crystal Reports"
    },
    {
        "label": "Développeur de Génération de Rapports Automatisée"
    },
    {
        "label": "Développeur de Dépannage et de Maintenance des Rapports Crystal Reports"
    },
    {
        "label": "Développeur d'Applications GPT-3"
    },
    {
        "label": "Développeur de Chatbots GPT-3"
    },
    {
        "label": "Développeur de Traitement du Langage Naturel (NLP) avec GPT-3"
    },
    {
        "label": "Développeur de Génération de Contenu GPT-3"
    },
    
    {
        "label": "Développeur Python Full Stack"
    },
    {
        "label": "Développeur de Jeux Python"
    },
    {
        "label": "Développeur de Data Science Python"
    },
    {
        "label": "Développeur de Machine Learning Python"
    },
    {
        "label": "Développeur de Traitement du Langage Naturel (NLP) Python"
    },
    {
        "label": "Développeur DevOps Python"
    },
    {
        "label": "Développeur Python en Cloud Computing"
    },
    {
        "label": "Développeur Python IoT (Internet des Objets)"
    },
   
    {
        "label": "Recherche Textuelle"
    },
    {
        "label": "Analyse de Texte"
    },
    {
        "label": "Recherche Géospatiale"
    },
    {
        "label": "Agrégations et Groupements"
    },
    {
        "label": "Gestion des Index"
    },
    {
        "label": "Optimisation des Requêtes Elasticsearch"
    },
    {
        "label": "Sécurité Elasticsearch"
    },
    {
        "label": "Gestion des Erreurs et du Dépannage"
    },
    {
        "label": "Intégration avec d'autres Outils (Logstash, Kibana, etc.)"
    },
    {
        "label": "Visualisation des Données avec Kibana"
    },
    {
        "label": "Scalabilité d'Elasticsearch"
    },
    {
        "label": "Planification de la Migration"
    },
    {
        "label": "Surveillance et Maintenance"
    },
    {
        "label": "Conformité aux Règles de l'Entreprise"
    },
    {
        "label": "Formation et Support"
    },
    {
        "label": "Maîtrise d'Excel"
    },
    {
        "label": "Automatisation de Tâches avec VBA"
    },
    {
        "label": "Création de Tableaux de Bord"
    },
    {
        "label": "Formules Avancées"
    },
    {
        "label": "Consolidation de Données"
    },
    {
        "label": "Importation/Exportation de Données"
    },
    {
        "label": "Sécurité des Données"
    },
    {
        "label": "Visual Basic for Applications (VBA)"
    },
    {
        "label": "Reporting et Présentation"
    },
    {
        "label": "Utilisation Avancée des Tableaux Croisés Dynamiques"
    },
    {
        "label": "Créativité et Résolution de Problèmes"
    },
    {
        "label": "Formation des Utilisateurs"
    },
    {
        "label": "Analyse de Scénarios"
    },
    {
        "label": "Communication"
    },
    {
        "label": "Développeur Rasa"
    },
    {
        "label": "Maîtrise de Rasa"
    },
    {
        "label": "Développement de Chatbots Conversationnels"
    },
    {
        "label": "Langage de Programmation (principalement Python)"
    },
    {
        "label": "Compréhension du Langage Naturel (NLU - Natural Language Understanding)"
    },
    {
        "label": "Conception de Flux de Dialogue"
    },
    {
        "label": "Intégrations avec Divers Canaux de Communication"
    },
    {
        "label": "Formation de Modèles de Compréhension du Langage Naturel (NLU)"
    },
    {
        "label": "Déploiement de Chatbots Rasa"
    },
    {
        "label": "Personnalisation des Chatbots"
    },
    {
        "label": "Optimisation des Performances des Chatbots"
    },
    {
        "label": "Sécurité des Données pour les Chatbots"
    },
    {
        "label": "Débogage et Dépannage des Chatbots"
    },
    {
        "label": "Conformité aux Règles de l'Entreprise pour la Gestion des Données"
    },
    {
        "label": "Collaboration en Équipe"
    },
    {
        "label": "Formation des Utilisateurs Finaux à l'Utilisation des Chatbots"
    },
    {
        "label": "Maintenance et Mises à Jour Régulières des Chatbots"
    },
    {
        "label": "Développeurs BotPress "
    },
    {
        "label": "Intégrateur de Chatbots BotPress"
    },
    {
        "label": "Développeur de Thèmes et d'Interfaces Utilisateur (UI/UX)"
    },
    {
        "label": "Développeur de Scripts et de Logique Métier BotPress"
    },
    {
        "label": "Développeur de NLU (Natural Language Understanding) pour BotPress"
    },
    {
        "label": "Architecte de Chatbots BotPress"
    },
    {
        "label": "Développeur Back-End BotPress"
    },
    {
        "label": "Développeur Full-Stack BotPress"
    },
    {
        "label": "Développeur de Chatbots d'Entreprise BotPress"
    },
    {
        "label": "Spécialiste de la Sécurité des Chatbots BotPress"
    },
    {
        "label": "Développeurs Dialogue flow"
    },
    {
        "label": "Développeur de Chatbots Conversationnels Dialogflow"
    },
    {
        "label": "Intégrateur Dialogflow"
    },
   
    {
        "label": "Spécialiste de la Sécurité des Chatbots BotUI"
    },
    {
        "label": "Architectes Big Data"
    },
    {
        "label": "Développeurs Oracle SQL"
    },
    {
        "label": "Développeurs de bases de données"
    },
    {
        "label": "Développeurs MongoDB"
    },
    {
        "label": "Développeurs PostgreSQL"
    },
    {
        "label": "Développeurs Apache Airflow"
    },
    {
        "label": "Développeurs CMS (Système de Gestion de Contenu)"
    },
    {
        "label": "Développeurs de thèmes WordPress"
    },
    {
        "label": "Développeurs WordPress"
    },
    {
        "label": "Designers WordPress"
    },
    {
        "label": "Développeurs Joomla"
    },
    {
        "label": "Développeurs Drupal"
    },
    {
        "label": "Développeurs SharePoint"
    },
    {
        "label": "Designers Webflow"
    },
    {
        "label": "Développeurs Elementor"
    },
    {
        "label": "Développeurs E-commerce"
    },
    {
        "label": "Développeurs WooCommerce"
    },
    {
        "label": "Développeurs Magento"
    },
    {
        "label": "Développeurs Shopify"
    },
    {
        "label": "Développeurs d'API Shopify"
    },
    {
        "label": "Développeurs OpenCart"
    },
    {
        "label": "Développeurs Salesforce Commerce Cloud"
    },
    {
        "label": "Développeurs Salesforce"
    },
    {
        "label": "Développeurs Stripe"
    },
    {
        "label": "Développeurs Odoo"
    },
    {
        "label": "Développeurs BigCommerce"
    },
    {
        "label": "Développeurs Google AdWords"
    },
    {
        "label": "Développeurs Hubspot"
    },
    {
        "label": "Designers de marque"
    },
    {
        "label": "Graphistes"
    },
    {
        "label": "Designers visuels"
    },
    {
        "label": "Designers de logo"
    },
    {
        "label": "Designers de présentation"
    },
    {
        "label": "Experts Adobe Illustrator"
    },
    {
        "label": "Experts Photoshop"
    },
    {
        "label": "Consultants en gestion de produit"
    },
    {
        "label": "Chefs de produit contractuels"
    },
    {
        "label": "Chefs de produit à distance"
    },
    {
        "label": "Chefs de produit techniques"
    },
    {
        "label": "Chefs de produit numériques"
    },
    {
        "label": "Chefs de projet freelance"
    },
    {
        "label": "Analystes d'affaires techniques"
    },
    {
        "label": "Chefs de produit pour applications"
    },
    {
        "label": "Chefs de projet web"
    },
    {
        "label": "Consultants en flux de trésorerie"
    },
    {
        "label": "Consultants en modélisation financière"
    },
    {
        "label": "Consultants en financement de startups"
    },
    {
        "label": "Consultants en fusions et acquisitions (M&A)"
    },
    {
        "label": "Consultants en capital-risque"
    },
    {
        "label": "Consultants en plan d'affaires"
    },
    {
        "label": "Consultants en levée de fonds"
    },
    {
        "label": "Analystes financiers"
    },
    {
        "label": "Analystes quantitatifs"
    },
    {
        "label": "Consultants en affaires"
    },
    {
        "label": "Analystes d'affaires"
    },
    {
        "label": "Consultants en tarification"
    },
    {
        "label": "Spécialistes en évaluation"
    },
    {
        "label": "DAF (Directeurs Administratifs et Financiers) fractionnés"
    },
    {
        "label": "Consultants pour petites entreprises"
    },
    {
        "label": "Consultants en pitch deck"
    },
    {
        "label": "Experts en prévision financière"
    },
    {
        "label": "Développeurs ChatGPT"
    },
    {
        "label": "Développeurs OpenAI"
    },
    {
        "label": "Développeurs Web React"
    },
    {
        "label": "Développeurs Azure"
    },
    {
        "label": "DSI (Directeurs des Systèmes d'Information)"
    },
    {
        "label": "Experts Kubernetes"
    },
    {
        "label": "Développeurs IoT (Internet des Objets)"
    },
    {
        "label": "Développeurs WhisperAI"
    },
    {
        "label": "Développeurs Hugging Face"
    },
    {
        "label": "Développeurs PlayFab"
    },
    {
        "label": "Développeurs Thymeleaf"
    },
    {
        "label": "Développeurs OpenWRT"
    },
    {
        "label": "Développeurs Agavi"
    },
    {
        "label": "Ingénieurs IA (Intelligence Artificielle)"
    },
    {
        "label": "Géomaticien en analyse spatiale"
    },
    {
        "label": "Géomaticien en cartographie"
    },
    {
        "label": "Géomaticien en gestion de données géospatiales"
    },
    {
        "label": "Développeur  QGIS"
    },
    {
        "label": " Développeur  GRASS GIS"
    },
    {
        "label": "Technicien en acquisition de données géospatiales"
    },
    {
        "label": "Technicien en traitement de données géospatiales"
    },
    {
        "label": "Technicien en support SIG"
    },
    {
        "label": "Développeur AutoCAD Map 3D"
    },
    {
        "label": "Développeur ArcGIS"
    },
    {
        "label": "Ingénieur en développement SIG"
    },
    {
        "label": "Ingénieur en télédétection"
    },
    {
        "label": "Ingénieur en gestion de projets géomatiques"
    },
    {
        "label": "Développeur ENVI"
    },
    {
        "label": "Développeur ERDAS Imagine"
    },
    {
        "label": "Cartographe thématique"
    },
    {
        "label": "Cartographe topographique"
    },
    {
        "label": "Cartographe numérique"
    },
    {
        "label": "Adobe Illustrator (avec l'extension MAPublisher)"
    },
    {
        "label": "Topographe géodésien"
    },
    {
        "label": "Topographe foncier"
    },
    {
        "label": "Topographe en travaux publics"
    },
    {
        "label": "Spécialiste en analyse spatiale SIG"
    },
    {
        "label": "Spécialiste en gestion de bases de données SIG"
    },
    {
        "label": "Spécialiste en développement d'applications SIG"
    },
    {
        "label": "ArcGIS"
    },
    {
        "label": "QGIS"
    },
    {
        "label": "MapInfo"
    },
    {
        "label": "Chargé d'études en analyse spatiale"
    },
    {
        "label": "Chargé d'études en cartographie"
    },
    {
        "label": "Chargé d'études en télédétection"
    },
    {
        "label": "ENVI"
    },
    {
        "label": "Analyste en imagerie satellitaire"
    },
    {
        "label": "Analyste en photogrammétrie"
    },
    {
        "label": "Analyste en radar à synthèse d'ouverture (SAR)"
    },
    {
        "label": "ERDAS Imagine"
    },
    {
        "label": "Orfeo Toolbox"
    },
    {
        "label": "Développeur en applications SIG"
    },
    {
        "label": "Développeur en bases de données géospatiales"
    },
    {
        "label": "Développeur en interfaces SIG"
    },
    {
        "label": "ArcGIS API for JavaScript"
    },
    {
        "label": "OpenLayers"
    },
    {
        "label": "Leaflet"
    },
    {
        "label": "PostgreSQL/PostGIS"
    },
    {
        "label": "Microsoft SQL Server Spatial"
    },
    {
        "label": "Oracle Spatial and Graph"
    },
    {
        "label": "Développeur Commercial B2B"
    },
    {
        "label": "Développeur Commercial B2C"
    },
    {
        "label": "Développeur Commercial International"
    },
    {
        "label": "Développeur Commercial Technologique"
    },
    {
        "label": "Développeur Commercial dans le Secteur Financier"
    },
    {
        "label": "Développeur Commercial dans l'Immobilier"
    },
    {
        "label": "Développeur Commercial dans l'Industrie"
    },
    {
        "label": "Développeur Commercial en Ligne (e-commerce)"
    },
    {
        "label": "Développeur Commercial dans les Services Professionnels"
    },
    {
        "label": "Développeur Commercial en Start-up"
    },
    {
        "label": "Développeur Commercial dans l'Industrie de la Santé"
    },
    {
        "label": "Développeur Commercial en Énergie et Environnement"
    },
    {
        "label": "Business Analyst en Analyse des Données"
    },
    {
        "label": "Business Analyst en Analyse Financière"
    },
    {
        "label": "Business Analyst en Analyse des Processus"
    },
    {
        "label": "Business Analyst en Analyse des Exigences"
    },
    {
        "label": "Business Analyst en Systèmes d'Information"
    },
    {
        "label": "Business Analyst en Analyse de Marché"
    },
    {
        "label": "Business Analyst en Gestion de Projet"
    },
    {
        "label": "Business Analyst en Analyse de la Chaîne d'Approvisionnement"
    },
    {
        "label": "Business Analyst en Analyse des Risques"
    },
    {
        "label": "Business Analyst en Analyse Marketing"
    },
    {
        "label": "Consultant en Gestion de Projet"
    },
    {
        "label": "Consultant en Gestion du Changement"
    },
    {
        "label": "Consultant en Gestion des Ressources Humaines"
    },
    {
        "label": "Consultant en Optimisation des Processus"
    },
    {
        "label": "Consultant en Gestion de la Chaîne d'Approvisionnement"
    },
    {
        "label": "Consultant en Gestion de la Qualité"
    },
    {
        "label": "Consultant en Gestion de la Technologie de l'Information (TI)"
    },
    {
        "label": "Consultant en Gestion de Crise"
    },
    {
        "label": "Consultant en Gestion de la Performance"
    },
    {
        "label": "Consultant en Stratégie de Marketing"
    },
    {
        "label": "Consultant en Développement Durable"
    },
    {
        "label": "Consultant en Finances d'Entreprise"
    },
    {
        "label": "Expert en Analyse Financière"
    },
    {
        "label": "Expert en Contrôle de Gestion"
    },
    {
        "label": "Expert en Planification Financière"
    },
    {
        "label": "Expert en Trésorerie et Gestion de la Liquidité"
    },
    {
        "label": "Expert en Fiscalité"
    },
    {
        "label": "Expert en Gestion des Risques Financiers"
    },
    {
        "label": "Expert en Comptabilité"
    },
    {
        "label": "Expert en Investissements"
    },
    {
        "label": "Expert en Finance d'Entreprise"
    },
    {
        "label": "Expert en Analyse des Coûts et Rentabilité"
    },
    {
        "label": "Expert en Finance Internationale"
    },
    {
        "label": "Expert en Gestion de Patrimoine"
    },
    {
        "label": "Expert en Fintech"
    },
    {
        "label": "Consultant en Stratégie d'Entreprise"
    },
    {
        "label": "Consultant en Stratégie de Croissance"
    },
    {
        "label": "Consultant en Stratégie Numérique"
    },
    {
        "label": "Consultant en Stratégie Marketing"
    },
    {
        "label": "Consultant en Stratégie de Gestion de la Chaîne d'Approvisionnement"
    },
    {
        "label": "Consultant en Stratégie de Gestion de la Performance"
    },
    {
        "label": "Consultant en Stratégie de Durabilité"
    },
    {
        "label": "Consultant en Stratégie de Ressources Humaines"
    },
    {
        "label": "Consultant en Stratégie de Fusion et Acquisition (M&A)"
    },
    {
        "label": "Consultant en Stratégie d'Innovation"
    },
    {
        "label": "Consultant en Stratégie Internationale"
    },
    {
        "label": "Consultant en Stratégie de Crise"
    },
    {
        "label": "Spécialiste en adaptation culturelle"
    },
    {
        "label": "Directeur Artistique en Publicité"
    },
    {
        "label": "Directeur Artistique en Cinéma et Télévision"
    },
    {
        "label": "Expert en adaptation culturelle pour les sites web multilingues"
    },
    {
        "label": "Directeur Artistique en Mode"
    },
    {
        "label": "Expert en publicités internationales sur Google Ads"
    },
    {
        "label": "Directeur Artistique en Conception Graphique"
    },
    {
        "label": "Expert en publicités sur Facebook payantes"
    },
    {
        "label": "Directeur Artistique en Jeux Vidéo"
    },
    {
        "label": "Expert en publicités sur Instagram payantes"
    },
    {
        "label": "Directeur Artistique en Design d'Intérieur"
    },
    {
        "label": "Directeur Artistique en Photographie"
    },
    {
        "label": "Directeur Artistique en Animation"
    },
    {
        "label": "Directeur Artistique en Musique et Spectacles"
    },
    {
        "label": "Directeur Artistique en Événementiel"
    },
    {
        "label": "Directeur Artistique en Illustration"
    },
    {
        "label": "Directeur Artistique en Architecture"
    },
    {
        "label": "Directeur Artistique en Arts Visuels"
    },
    {
        "label": "Graphiste en Conception Graphique"
    },
    {
        "label": "Graphiste Web"
    },
    {
        "label": "Illustrateur"
    },
    {
        "label": "Designer d'Expérience Utilisateur (UX)"
    },
    {
        "label": "Designer d'Interface Utilisateur (UI)"
    },
    {
        "label": "Designer d'Infographie"
    },
    {
        "label": "Designer de Jeux Vidéo"
    },
    {
        "label": "Designer de Packaging"
    },
    {
        "label": "Designer de Typographie"
    },
    {
        "label": "Designer de Produits"
    },
    {
        "label": "Designer de Communication Visuelle"
    },
    {
        "label": "Designer en Design d'Intérieur"
    },
    {
        "label": "Illustration pour Enfants"
    },
    {
        "label": "Illustration de Mode"
    },
    {
        "label": "Illustration Scientifique"
    },
    {
        "label": "Illustration de Fantasy et de Science-Fiction"
    },
    {
        "label": "Illustration de Personnages"
    },
    {
        "label": "Illustration de Storyboard"
    },
    {
        "label": "Illustration de Produits de Consommation"
    },
    {
        "label": "Illustration de Cartes et de Cartographie"
    },
    {
        "label": "Illustration de Nature et d'Animaux"
    },
    {
        "label": "Illustration Médicale"
    },
    {
        "label": "Illustration de Presse"
    },
    {
        "label": "Illustration Numérique"
    },
    {
        "label": "Illustration de Jeux de Plateau et de Jeux de Cartes"
    },
    {
        "label": "Motion Design en Publicité"
    },
    {
        "label": "Motion Design pour le Cinéma"
    },
    {
        "label": "Motion Design pour la Télévision"
    },
    {
        "label": "Motion Design en Conception de Site Web"
    },
    {
        "label": "Motion Design en Animation de Personnages"
    },
    {
        "label": "Motion Design pour les Médias Sociaux"
    },
    {
        "label": "Motion Design pour l'Éducation"
    },
    {
        "label": "Motion Design en Conception de Marque"
    },
    {
        "label": "Motion Design en Promotion de Produits"
    },
    {
        "label": "Motion Design pour les Arts Visuels"
    },
    {
        "label": "Motion Design en Exposition et Événementiel"
    },
    {
        "label": "Motion Design en Santé et Médecine"
    },
    {
        "label": "Motion Design en Conception de Produits"
    },
    {
        "label": "Motion Design en Divertissement"
    },
    {
        "label": "Vidéaste de Mariage"
    },
    {
        "label": "Vidéaste de Cinéma"
    },
    {
        "label": "Vidéaste de Télévision"
    },
    {
        "label": "Vidéaste de Vidéos Musicales"
    },
    {
        "label": "Vidéaste de Médias Sociaux"
    },
    {
        "label": "Vidéaste de Voyage"
    },
    {
        "label": "Vidéaste d'Entreprise"
    },
    {
        "label": "Vidéaste Documentariste"
    },
    {
        "label": "Vidéaste de Sport"
    },
    {
        "label": "Vidéaste de Journalisme"
    },
    {
        "label": "Vidéaste de Mode"
    },
    {
        "label": "Vidéaste de Drone"
    },
    {
        "label": "Vidéaste de Science"
    },
    {
        "label": "Vidéaste Culinaire"
    },
    {
        "label": "Vidéaste de Vlog"
    },
    
    {
        "label": "Analyste en marketing"
    },
    {
        "label": "Spécialiste en analyse de marché"
    },
    {
        "label": "Expert en analyse de marché B2B"
    },
    {
        "label": "Expert en analyse de marché pour les technologies de l'information"
    },
    {
        "label": "Expert en analyse de marché pour le secteur de la santé"
    },
    {
        "label": "Expert en analyse de marché B2C"
    },
    {
        "label": "Expert en analyse de marché pour les produits de consommation"
    },
    {
        "label": "Expert en analyse de marché pour l'industrie automobile"
    },
    {
        "label": "Expert en segmentation de marché"
    },
    {
        "label": "Spécialiste en segmentation démographique"
    },
    {
        "label": "Expert en segmentation démographique des jeunes consommateurs"
    },
    {
        "label": "Expert en segmentation démographique des seniors"
    },
    {
        "label": "Spécialiste en segmentation comportementale"
    },
    {
        "label": "Expert en segmentation comportementale des acheteurs en ligne"
    },
    {
        "label": "Expert en segmentation comportementale des acheteurs en magasin"
    },
    {
        "label": "Stratège marketing"
    },
    {
        "label": "Spécialiste en planification stratégique"
    },
    {
        "label": "Expert en planification stratégique pour les startups"
    },
    {
        "label": "Expert en planification stratégique pour les entreprises établies"
    },
    {
        "label": "Expert en recherche de public cible"
    },
    {
        "label": "Spécialiste en recherche de public cible pour les produits de luxe"
    },
    {
        "label": "Spécialiste en recherche de public cible pour les produits écologiques"
    },
    {
        "label": "Consultant en marketing"
    },
    {
        "label": "Spécialiste en conseil en marketing numérique"
    },
    {
        "label": "Expert en conseil en marketing pour les petites entreprises"
    },
    {
        "label": "Expert en conseil en marketing pour les entreprises internationales"
    },
    {
        "label": "Expert en stratégie de marque"
    },
    {
        "label": "Spécialiste en stratégie de marque personnelle"
    },
    {
        "label": "Expert en développement de la marque personnelle pour les entrepreneurs"
    },
    {
        "label": "Expert en développement de la marque personnelle pour les influenceurs"
    },
    {
        "label": "Spécialiste en stratégie de marque d'entreprise"
    },
    {
        "label": "Expert en stratégie de marque pour les entreprises technologiques"
    },
    {
        "label": "Expert en stratégie de marque pour les entreprises alimentaires"
    },
    {
        "label": "Responsable de la planification marketing"
    },
    {
        "label": "Spécialiste en gestion de budgets marketing"
    },
    {
        "label": "Expert en budgétisation publicitaire en ligne"
    },
    {
        "label": "Expert en budgétisation publicitaire sur les réseaux sociaux"
    },
    {
        "label": "Expert en budgétisation publicitaire sur les moteurs de recherche"
    },
    {
        "label": "Expert en planification de campagnes marketing"
    },
    {
        "label": "Spécialiste en planification de campagnes de lancement de produits"
    },
    {
        "label": "Expert en planification de campagnes de lancement de produits technologiques"
    },
    {
        "label": "Expert en planification de campagnes de lancement de produits de beauté"
    },
    {
        "label": "Chef de projet marketing"
    },
    {
        "label": "Spécialiste en gestion de projet numérique"
    },
    {
        "label": "Expert en gestion de projets de refonte de site web"
    },
    {
        "label": "Expert en gestion de projets de développement d'applications mobiles"
    },
    {
        "label": "Expert en gestion d'équipe marketing"
    },
    {
        "label": "Spécialiste en gestion d'équipes marketing à distance"
    },
    {
        "label": "Spécialiste en gestion d'équipes marketing multiculturelles"
    },
    {
        "label": "Gestionnaire de projet en communication"
    },
    {
        "label": "Spécialiste en gestion de projets de communication externe"
    },
    {
        "label": "Expert en gestion de projets de relations publiques d'entreprise"
    },
    {
        "label": "Expert en gestion de projets de relations publiques pour les entreprises technologiques"
    },
    {
        "label": "Expert en gestion de projets de relations publiques pour les entreprises de santé"
    },
    {
        "label": "Expert en gestion de projets de médias sociaux"
    },
    {
        "label": "Expert en gestion de projets de médias sociaux pour les organisations à but non lucratif"
    },
    {
        "label": "Expert en gestion de projets de médias sociaux pour les institutions financières"
    },
    {
        "label": "Expert en gestion de projets événementiels"
    },
    {
        "label": "Spécialiste en gestion de projets d'événements d'entreprise"
    },
    {
        "label": "Expert en gestion de projets d'événements d'entreprise pour les lancements de produits"
    },
    
    {
        "label": "Spécialiste en marketing d'influence"
    },
    {
        "label": "Responsable de campagnes d'influence"
    },
    {
        "label": "Spécialiste en campagnes d'influence sur les médias sociaux"
    },
    {
        "label": "Spécialiste en campagnes d'influence sur Instagram"
    },
   
    {
        "label": "Designer automobile"
    },
    {
        "label": "Designer de motos"
    },
    {
        "label": "Designer de systèmes de mobilités urbaines "
    },
    {
        "label": "Designer de camions"
    },
    {
        "label": "Designer de bateaux"
    },
    {
        "label": "Designer d'avions"
    },
    {
        "label": "Designer d'intérieurs de transports publics"
    },
    {
        "label": "Designer d'interfaces utilisateur pour les systèmes de transport"
    },
    {
        "label": "Designer d'infrastructures de transport L"
    },
    {
        "label": "Designer de systèmes de transport intelligents"
    },
    {
        "label": "Designer de meubles industriels"
    },
    {
        "label": "Designer d'emballages écologique"
    },
    {
        "label": "Designer d'emballage de produits de consommation"
    },
   
    {
        "label": "Designer de systèmes d'éclairage"
    },
    {
        "label": "Designer d'emballage"
    },
    {
        "label": "Designer de mobilier"
    },
    {
        "label": "Designer de meubles d'intérieur"
    },
    {
        "label": "Designer de meubles d'extérieur"
    },
    {
        "label": "Designer de meubles de cuisine"
    },
    {
        "label": "Designer de meubles de salle de bain"
    },
    {
        "label": "Designer de meubles pour enfants"
    },
    {
        "label": "Designer de meubles sur mesure"
    },
    {
        "label": "Designer de jouets"
    },
    {
        "label": "Designer de bijoux"
    },
    {
        "label": "Designer de produits durables "
    },
    {
        "label": "Directeur artistique en mode "
    },
    {
        "label": "Directeur artistique en joaillerie "
    },
    {
        "label": "Directeur artistique en parfumerie"
    },
    {
        "label": "Directeur artistique en hôtellerie "
    },
    {
        "label": "Styliste de mode"
    },
    {
        "label": "Styliste d'accessoires "
    },
    {
        "label": "Styliste de coiffure et maquillage "
    },
    {
        "label": "Styliste d'intérieur"
    },
    {
        "label": "Designer horloger "
    },
    {
        "label": "Ingénieur horloger"
    },
    {
        "label": "Horloger assembleur"
    },
    {
        "label": "Horloger réparateur"
    },
    {
        "label": "Responsable marketing horlogerie"
    },
    {
        "label": "Responsable des ventes horlogerie"
    },
    {
        "label": "Expert horloger "
    },
    {
        "label": "Joaillier haute joaillerie "
    },
    {
        "label": "Gemmologue"
    },
    {
        "label": "Responsable marketing joaillerie"
    },
    {
        "label": "Responsable des ventes joaillerie"
    },
    {
        "label": "Expert joaillier "
    },
    {
        "label": "Broderie"
    },
    {
        "label": "Broderie haute couture"
    },
    {
        "label": "La broderie perlée"
    },
    {
        "label": "La broderie de paillettes"
    },
    {
        "label": "La broderie de sequins"
    },
    {
        "label": "La broderie de ruban"
    },
    {
        "label": "La broderie de perles et de pierres précieuses"
    },
    {
        "label": "La broderie en relief"
    },
    {
        "label": "La broderie à points comptés"
    },
    {
        "label": "Le point de chaînette"
    },
    {
        "label": "Le point de Rhodes"
    },
    {
        "label": "Le point de feuille"
    },
    {
        "label": "Le point de roue"
    },
    {
        "label": "La broderie de dentelle"
    },
    {
        "label": "Broderie d'accessoires de mode"
    },
    {
        "label": "Broderie d'ameublement"
    },
    {
        "label": "Broderie de bijoux"
    },
    {
        "label": "Broderie de drapeaux et d'écussons"
    },
    {
        "label": "Broderie d'art contemporain "
    },
    {
        "label": "Cadre ( fabricant de cadre ) / Frame Maker )"
    },
    {
        "label": "Fabricant de cadres en métal "
    },
    {
        "label": "Fabricant de cadres en bois "
    },
    {
        "label": "Fabricant de cadres en cuir "
    },
    {
        "label": "Fabricant de cadres en cristal "
    },
    {
        "label": "Fabricant de cadres personnalisés "
    },
    {
        "label": "Fabricant de cadres d'œuvres d'art "
    },
    {
        "label": "Calligraphiste "
    },
    {
        "label": "Calligraphe traditionnel "
    },
    {
        "label": "Calligraphe contemporain"
    },
    {
        "label": "Enlumineur"
    },
    {
        "label": "Graphiste calligraphe "
    },
    {
        "label": "Scribe "
    },
    {
        "label": "Lettering artist "
    },
    {
        "label": "Graveur "
    },
    {
        "label": "Céramiste "
    },
    {
        "label": "Céramiste d'art "
    },
    {
        "label": "Céramiste utilitaire "
    },
    {
        "label": "Céramiste de production "
    },
    {
        "label": "Céramiste traditionnel "
    },
    {
        "label": "Céramiste contemporain "
    },
    {
        "label": "chaudronnier"
    },
    {
        "label": "La chaudronnerie d'art"
    },
    {
        "label": "La chaudronnerie de précision"
    },
    {
        "label": "La chaudronnerie industrielle"
    },
    {
        "label": "La chaudronnerie pour l'automobile"
    },
    {
        "label": "La chaudronnerie pour les yachts et les bateaux"
    },
    {
        "label": "Ebéniste  / Marqueterie "
    },
    {
        "label": "Ébéniste de meubles d'art "
    },
    {
        "label": "Ébénisterie de haute décoration"
    },
    {
        "label": "Restauration d'antiquités"
    },
    {
        "label": "Ébénisterie de luxe sur mesure"
    },
    {
        "label": "Ébénisterie de Marine "
    },
    {
        "label": "Ébénisterie de yachts et de bateaux de plaisance "
    },
    {
        "label": "Ébénisterie de bateaux de pêche"
    },
    {
        "label": "Ébénisterie de bateaux de guerre"
    },
    {
        "label": "Ébénisterie de bateaux de croisière"
    },
    {
        "label": "Ébénisterie de bateaux traditionnels"
    },
    {
        "label": "Ébéniste de meubles en série "
    },
    {
        "label": "Ébéniste de restauration de meubles "
    },
    {
        "label": "Ébéniste de mobilier contemporain "
    },
    {
        "label": "Ébénisterie d'art contemporain"
    },
    {
        "label": "Ébéniste de cuisine"
    },
    {
        "label": "Ébéniste d'escaliers"
    },
    {
        "label": "Ébéniste de décoration intérieure "
    },
    {
        "label": "Ébénisterie de production"
    },
    {
        "label": "Emailleurs"
    },
    {
        "label": "Émailleur sur métaux précieux "
    },
    {
        "label": "Émailleur sur porcelaine ."
    },
    {
        "label": "Émailleur sur verre "
    },
    {
        "label": "Émailleur sur montres "
    },
    {
        "label": "Émailleur sur métaux communs "
    },
    {
        "label": "Émailleur artistique "
    },
    {
        "label": "Maroquinier "
    },
    {
        "label": "Maroquinier sacs à main "
    },
    {
        "label": "Maroquinier designer "
    },
    {
        "label": "Maroquinier prototypiste "
    },
    {
        "label": "Maroquinier industriel "
    },
    {
        "label": "Maroquinier chaussures "
    },
    {
        "label": "Maroquinier chaussures sur mesure "
    },
    {
        "label": "Maroquinier ceintures "
    },
    {
        "label": "Maroquinier valises "
    },
    {
        "label": "Maroquinier sacs de voyage "
    },
    {
        "label": "Maroquinier porte-documents "
    },
    {
        "label": "Maroquinier accessoires de voyage "
    },
    {
        "label": "Maroquinier accessoires "
    },
    {
        "label": "Bijoutiers "
    },
    {
        "label": "Joailliers "
    },
    {
        "label": "Joailliers créateurs "
    },
    {
        "label": "Joailliers artisans "
    },
    {
        "label": "Joailliers gemmologues"
    },
    {
        "label": "Joailliers bijoutiers "
    },
    {
        "label": "Horlogers "
    },
    {
        "label": "Gravure sur bijoux "
    },
    {
        "label": "Fabricants de bijoux fantaisie "
    },
    {
        "label": "Tapis ( Fabricant ) de "
    },
    {
        "label": "Tapissier "
    },
    {
        "label": "Tapissier d'ameublement "
    },
    {
        "label": "Tapissier décorateur "
    },
    {
        "label": "Tapissier garnisseur "
    },
    {
        "label": "Tapissier sellier "
    },
    {
        "label": "Modiste "
    },
    {
        "label": "Modiste haute couture "
    },
    {
        "label": "Modiste prêt-à-porter"
    },
    {
        "label": "Modiste événementiel"
    },
    {
        "label": "Modiste chapelier "
    },
    {
        "label": "Verrier "
    },
    {
        "label": "Verrier d'art "
    },
    {
        "label": "Verrier utilitaire "
    },
    {
        "label": "Verrier scientifique "
    },
    {
        "label": "Verrier industriel"
    },
    {
        "label": "Responsable de boutique "
    },
    {
        "label": "Responsable régional de vente "
    },
    {
        "label": "Responsable des ventes en ligne "
    },
    {
        "label": "Responsable des ventes internationales "
    },
    {
        "label": "Designer maroquinerie "
    },
    {
        "label": "Maroquinier"
    },
    {
        "label": "Responsable de production "
    },
    {
        "label": "Responsable marketing "
    },
    {
        "label": "Responsable des ventes"
    },
    {
        "label": "Expert en cuir"
    },
    {
        "label": "Parfumeur-créateur"
    },
    {
        "label": "Responsable de la création"
    },
    {
        "label": "Responsable des ventes "
    },
    {
        "label": "Responsable de la qualité "
    },
    {
        "label": "Chef de produit"
    },
    {
        "label": "Ingénieur produit "
    },
    {
        "label": "Responsable de la stratégie produit "
    },
    {
        "label": "Responsable du développement de produit durable "
    },
    {
        "label": "Responsable du développement de produit numérique "
    },
    {
        "label": "Analyste de marché des produits de luxe "
    },
    {
        "label": "Analyste de marché de la clientèle de luxe "
    },
    {
        "label": "Analyste de marché de la concurrence dans le luxe "
    },
    {
        "label": "Analyste de marché de la distribution de luxe "
    },
    {
        "label": "Analyste de marché des événements de luxe "
    },
    {
        "label": "Responsable logistique "
    },
    {
        "label": "Responsable des achats "
    },
    {
        "label": "Responsable de la planification de la production"
    },
    {
        "label": "Responsable de la qualité"
    },
    {
        "label": "Responsable de la gestion de stock "
    },
    {label:"Directeur(trice) Logistique"},
    {label:"Responsable de la Stratégie Logistique"},
    {label:"Chef de Projet Logistique"},
    {label:"Coordinateur(trice) Logistique"},
    {label:"Coordinateur(trice) de Transport International"},
    {label:"Coordinateur(trice) des Opérations de Douane"},
    {label:"Responsable des Transports"},
    {label:"Gestionnaire de Flotte"},
    {label:"Responsable des Affrètements"},
    {label:"Opérateur de Transport"},
    {label:"Conducteur(trice) Routier(ère) International(e)"},
    {label:"Agent de Transit"},
    {label:"Responsable de la Planification des Transports"},
    {label:"Responsable d'Entrepôt"},
    {label:"Chef de Quai"},
    {label:"Gestionnaire de Stock"},
    {label:"Magasinier(ère)"},
    {label:"Opérateur(trice) Logistique"},
    {label:"Préparateur(trice) de Commandes"},
    {label:"Cariste"},
    {label:"Responsable des Affaires Douanières"},
    {label:"Déclarant(e) en Douane"},
    {label:"Responsable de la Conformité"},
    {label:"Agent de Douane"},
    {label:"Inspecteur(trice) des Douanes"},
    {label:"Conseiller(ère) en Commerce International"},
    {label:"Responsable des Systèmes d'Information Logistiques"},
    {label:"Analyste Logistique"},
    {label:"Chef de Projet Systèmes d'Information"},
    {label:"Technicien(ne) en Logistique Informatique"},
    {label:"Gestionnaire de Bases de Données Logistiques"},
    {label:"Responsable du Service Client"},
    {label:"Responsable des Relations Clientèle"},
    {label:"Responsable de la Satisfaction Client"},
    {label:"Agent de Support Logistique"},
    {label:"Agent de Service Client"},
    {label:"Support Technique Logistique"}
]