exports.skills = [
    {
        categorie: [
            {
                "name": "Web Development",
                "selectable": false,
                "children": [
                    {
                        "name": "Développeurs Web",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Développeur Front-end",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Back-end",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Full-stack",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur JavaScript",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur PHP",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Python",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Ruby",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Java",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur .NET",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur mobile",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur d'applications web réactives",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur de commerce électronique",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur de jeux en ligne",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur web freelance",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur web en agence",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur web en entreprise",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur web en start-up",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur web spécialisé en sécurité",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur web spécialisé en optimisation des performances",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur web spécialisé en accessibilité.",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Designers Web",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Design d'interfaces utilisateur (UI)",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Design d'expérience utilisateur (UX)",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Design responsive",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Design d'interaction",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Design de site e-commerce",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Design de site institutionnel",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Design d'applications web",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Design de blogs et de contenu",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Design de portfolio en ligne",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Design d'animation web",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Design pour la vitesse et la performance",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Design pour l'accessibilité.",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Designers UI UX",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Conception d'interfaces utilisateur (UI)",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Conception d'expérience utilisateur (UX)",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Recherche utilisateur",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Tests utilisateurs",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Personas",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Conception d'interfaces mobiles",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Conception d'interfaces web",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Wireframing et prototypage",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Maquettes haute fidélité",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Prototypes interactifs",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Design système",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Conception d'écrans mobiles",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Conception d'écrans desktop",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Conception d'applications logicielles",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Conception d'applications web",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Design pour l'accessibilité",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Design pour la vitesse et la performance",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Conception pour la conversion",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Design d'application mobile réactif",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Design pour les médias sociaux.",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Développeurs Angular",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Développement d'applications web avec Angular",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développement d'applications mobiles avec Angular et Ionic",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Programmation orientée composants avec Angular",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Gestion de l'état de l'application avec NgRx (pour les applications Redux)",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Intégration avec des API REST ou GraphQL",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Création de composants réutilisables en Angular",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Routage et navigation dans les applications Angular",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Tests unitaires et d'intégration avec Jasmine et Karma",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Optimisation des performances des applications Angular",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développement d'applications de commerce électronique avec Angular et des solutions telles que Angular Universal.",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Développeurs JavaScript",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Développeur Front-end JavaScript",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Back-end JavaScript",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Full-stack JavaScript",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur JavaScript senior",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur JavaScript freelance",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur JavaScript en agence",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur JavaScript en entreprise",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur JavaScript pour les applications mobiles",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur JavaScript pour les applications web réactives",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur JavaScript pour l'optimisation des performances",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur JavaScript pour les jeux en ligne",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur JavaScript pour les chatbots et l'automatisation",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur JavaScript pour l'IoT.",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Développeurs CSS",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Développeur Front-end CSS",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Spécialiste en animations CSS",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur CSS responsive",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur CSS pour l'accessibilité",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur CSS pour la performance",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur CSS pour les animations web",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur CSS pour les effets parallaxe",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur CSS pour les frameworks",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur CSS pour les thèmes WordPress",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur CSS pour les courriels HTML",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur CSS pour les jeux en ligne",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur CSS pour les applications web réactives",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur CSS freelance",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur CSS en agence",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur CSS en entreprise",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur CSS en start-up.",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Développeurs PHP",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Développeur PHP Full-stack",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur PHP Front-end",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur PHP Back-end",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur PHP en e-commerce",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur PHP en CMS (Système de gestion de contenu)",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur PHP en frameworks",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur PHP en bases de données",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur PHP en sécurité",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur PHP en API",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur PHP en entreprise",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur PHP en freelance",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur PHP en gouvernement.",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Développeurs en Web Scraping",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Développeur en web scraping général",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur en web scraping e-commerce",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur en web scraping de médias sociaux",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur en web scraping de sites d'actualités",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur en web scraping de données financières",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur en web scraping de métadonnées de produits",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur en web scraping de données immobilières",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur en web scraping de données scientifiques",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur en web scraping de données géospatiales",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur en web scraping de données gouvernementales",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur en web scraping de données de voyages",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur en web scraping de données sportives.",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Développeurs Backbone.js",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Développeur Front-end Backbone.js",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Back-end Backbone.js",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Full-stack Backbone.js",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Backbone.js senior",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Backbone.js freelance",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Backbone.js en agence",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Backbone.js en entreprise",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Backbone.js pour les applications mobiles",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Backbone.js pour les applications web réactives",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Backbone.js pour l'optimisation des performances.",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Développeurs Vue.js",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Développeur Front-end Vue.js",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Vue.js avec Vuex",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Vue Router",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Full-stack Vue.js",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Vue.js senior",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Vue.js freelance",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Vue.js en agence",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Vue.js en entreprise",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Vue.js pour les applications mobiles",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Vue.js pour les applications web réactives",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Vue.js pour l'optimisation des performances.",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Développeurs RxJS",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Développeur RxJS Front-end",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur RxJS Back-end",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Full-stack RxJS",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur RxJS senior",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur RxJS freelance",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur RxJS en agence",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur RxJS en entreprise",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur RxJS pour les applications mobiles",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur RxJS pour les applications web réactives",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur RxJS pour l'optimisation des performances.",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Développeurs Meteor",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Développeur Front-end Meteor",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Back-end Meteor",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Full-stack Meteor",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Meteor senior",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Meteor freelance",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Meteor en agence",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Meteor en entreprise",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Meteor pour les applications mobiles",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Meteor pour les applications web réactives",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Meteor pour l'optimisation des performances.",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Développeurs Laravel",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Développeur Back-end Laravel",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Full-stack Laravel",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Laravel senior",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Laravel freelance",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Laravel en agence",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Laravel en entreprise",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Laravel pour les applications mobiles",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Laravel pour les applications web réactives",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Laravel pour l'optimisation des performances",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Laravel pour la sécurité",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Laravel pour les CMS",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Laravel pour les applications e-commerce.",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Développeurs CakePHP",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Développeur Back-end CakePHP",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Full-stack CakePHP",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur CakePHP senior",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur CakePHP freelance",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur CakePHP en agence",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur CakePHP en entreprise",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur CakePHP pour les applications mobiles",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur CakePHP pour les applications web réactives",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur CakePHP pour l'optimisation des performances",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur CakePHP pour la sécurité",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur CakePHP pour les CMS",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur CakePHP pour les applications e-commerce.",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Développeurs CodeIgniter",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Développeur Back-end CodeIgniter",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Full-stack CodeIgniter",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur CodeIgniter senior",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur CodeIgniter freelance",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur CodeIgniter en agence",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur CodeIgniter en entreprise",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur CodeIgniter pour les applications mobiles",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur CodeIgniter pour les applications web réactives",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur CodeIgniter pour l'optimisation des performances",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur CodeIgniter pour la sécurité",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur CodeIgniter pour les CMS",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur CodeIgniter pour les applications e-commerce.",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Développeurs MEAN Stack",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Développeur Front-end Angular",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Back-end Node.js",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Full-stack MEAN Stack",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur MEAN Stack senior",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur MEAN Stack freelance",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur MEAN Stack en agence",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur MEAN Stack en entreprise",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur MEAN Stack pour les applications mobiles",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur MEAN Stack pour les applications web réactives",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur MEAN Stack pour l'optimisation des performances",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur MEAN Stack pour la sécurité",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur MEAN Stack pour les CMS.",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Développeurs Web React",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Développeur Front-end React",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur React Native",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Full-stack React",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur React senior",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur React freelance",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur React en agence",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur React en entreprise",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur React pour les applications web réactives",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur React pour l'optimisation des performances",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur React pour la sécurité",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur React pour les CMS",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur React pour les applications e-commerce.",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Développeurs Node.js",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Développeur Back-end Node.js",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Full-stack Node.js",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Node.js senior",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Node.js freelance",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Node.js en agence",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Node.js en entreprise",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Node.js pour les applications mobiles",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Node.js pour les applications web réactives",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Node.js pour l'optimisation des performances",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Node.js pour la sécurité",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Node.js pour les applications IoT",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Node.js pour les chatbots.",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Développeurs Ruby on Rails",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Développeur Ruby on Rails Full-stack",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Ruby on Rails Front-end",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Ruby on Rails Back-end",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Ruby on Rails Mobile",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Ruby on Rails e-commerce",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Ruby on Rails en entreprise",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Ruby on Rails en startup",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Ruby on Rails en gouvernement",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Ruby on Rails en santé",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Ruby on Rails en finance",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Ruby on Rails en jeux vidéo",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Ruby on Rails en médias sociaux.",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Développeurs TypeScript",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Développeur TypeScript Front-end",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur TypeScript Back-end",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Full-stack TypeScript",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur TypeScript senior",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur TypeScript freelance",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur TypeScript en agence",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur TypeScript en entreprise",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur TypeScript pour les applications mobiles",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur TypeScript pour les applications web réactives",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur TypeScript pour l'optimisation des performances",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur TypeScript pour la sécurité",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur TypeScript pour les CMS.",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Développeurs Django",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Développeur Back-end Django",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Full-stack Django",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Django senior",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Django freelance",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Django en agence",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Django en entreprise",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Django pour les applications mobiles",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Django pour les applications web réactives",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Django pour l'optimisation des performances",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Django pour la sécurité",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Django pour les CMS",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Django pour les applications e-commerce.",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Développeurs API",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Développeur API RESTful",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur API GraphQL",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur API SOAP",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur API WebSocket",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur API JSON-RPC",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur API de paiement",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur API de médias sociaux",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur API de géolocalisation",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur API Cloud",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur API de gestion des utilisateurs",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur API de gestion des données",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur API de l'Internet des objets (IoT).",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Développeurs Yii",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Développeur Back-end Yii",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Full-stack Yii",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Yii senior",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Yii freelance",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Yii en agence",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Yii en entreprise",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Yii pour les applications mobiles",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Yii pour les applications web réactives",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Yii pour l'optimisation des performances",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Yii pour la sécurité",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Yii pour les CMS",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Yii pour les applications e-commerce.",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Développeurs ASP.NET",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Développeur ASP.NET Web Forms",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur ASP.NET MVC",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur ASP.NET Core",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur ASP.NET Blazor",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur ASP.NET Razor Pages",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur ASP.NET API",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur ASP.NET SignalR",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur ASP.NET pour l'optimisation des performances",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur ASP.NET pour la sécurité",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur ASP.NET pour les CMS",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur ASP.NET pour les applications e-commerce",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur ASP.NET en entreprise.",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    }
                ]
            },
            {
                "name": "DevOps & Cloud Computing",
                "selectable": false,
                "children": [
                    {
                        "name": "Ingénieurs DevOps",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Ingénieur DevOps Cloud",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Ingénieur DevOps Kubernetes",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Ingénieur DevOps automatisation",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Ingénieur DevOps sécurité",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Ingénieur DevOps réseau",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Ingénieur DevOps base de données",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Ingénieur DevOps outils",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Ingénieur DevOps CI/CD (Intégration continue / Livraison continue)",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Ingénieur DevOps monitoring",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Ingénieur DevOps support technique",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Ingénieur DevOps gestion des incidents",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Ingénieur DevOps en entreprise.",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Ingénieurs de données",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Ingénieur de données Big Data",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Ingénieur de données SQL",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Ingénieur de données NoSQL",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Ingénieur de données ETL (Extract, Transform, Load)",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Ingénieur de données en streaming",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Ingénieur de données cloud",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Ingénieur de données en entreprise",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Ingénieur de données en marketing",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Ingénieur de données en santé",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Ingénieur de données géospatiales",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Ingénieur de données financières",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Ingénieur de données pour l'analytique avancée.",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Développeurs de bases de données",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Développeur de bases de données SQL",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur de bases de données NoSQL",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur de bases de données Big Data",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur de bases de données cloud",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur de bases de données géospatiales",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur de bases de données temps réel",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur de bases de données d'entreprise",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur de bases de données pour l'analytique",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur de bases de données marketing",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur de bases de données financières",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur de bases de données pour les applications mobiles",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur de bases de données en entreprise.",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Ingénieurs des systèmes distribués",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Ingénieur des systèmes distribués cloud",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Ingénieur des systèmes distribués Big Data",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Ingénieur des systèmes distribués en conteneurs",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Ingénieur des systèmes distribués IoT (Internet des objets)",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Ingénieur des systèmes distribués en temps réel",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Ingénieur des systèmes distribués en entreprise",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Ingénieur des systèmes distribués en finance",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Ingénieur des systèmes distribués en santé",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Ingénieur des systèmes distribués en e-commerce",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Ingénieur des systèmes distribués en jeux vidéo",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Ingénieur des systèmes distribués en logistique",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Ingénieur des systèmes distribués en aviation.",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Développeurs AWS",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Développeur AWS cloud natif",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur AWS Serverless",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur AWS DevOps",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur AWS Front-end",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur AWS Full-stack",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur AWS Big Data",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur AWS IoT (Internet des objets)",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur AWS Machine Learning",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur AWS sécurité",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur AWS en entreprise",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur AWS en startup",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur AWS en gouvernement.",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Développeurs Azure",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Développeur d'Applications Web Azure",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur de Fonctions Azure",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur de Services de Conteneurs Azure",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur de Bases de Données Azure",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Azure DevOps",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur de Solutions IoT Azure",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur de Flux de Données Azure",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur de Machine Learning Azure",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur de Chatbots Azure",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur de Fonctions d'Intelligence Artificielle (IA) Azure",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Azure Logic Apps",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Azure Synapse Analytics",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Azure Active Directory",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Azure Functions",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur de Solutions Serverless Azure",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Azure en Gestion des Ressources",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Azure pour l'Éducation",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Azure pour la Sécurité",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Développeurs Azure Kubernetes Service (AKS)",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Développeur d'Applications Kubernetes AKS",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Administrateur de Cluster AKS",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Helm AKS",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur de Services Kubernetes AKS",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur de Manifestes Kubernetes AKS",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur de Flux de Travail CI/CD pour AKS",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Istio AKS",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur de Contrôleurs Kubernetes AKS",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur de Politiques de Sécurité AKS",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur de Solutions DevOps AKS",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur de Tableaux de Bord AKS",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur de Plugins Kubernetes AKS",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur de Politiques de Gestion des Coûts AKS",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur de Solutions d'Intelligence Artificielle (IA) pour AKS",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur d'Applications Serverless pour AKS",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Experts Kubernetes",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Développeur d'Applications Kubernetes",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Administrateur de Cluster Kubernetes",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Helm",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur de Services Kubernetes",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur de Manifestes Kubernetes",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Istio",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Operator Kubernetes",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur de Politiques de Sécurité Kubernetes",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur de Plugins Kubectl",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur de Contrôleurs Kubernetes",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur de Test et de Validation Kubernetes",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur de Stratégies de Mise à l'Échelle Kubernetes",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur de Tableaux de Bord Kubernetes",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur de Plugins d'Authentification et d'Autres Extensions",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Développeurs Docker",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Développeur d'Images Docker",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur de Conteneurs Docker",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur de Compose Docker",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur de Réseaux Docker",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur de Volumes Docker",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Docker Swarm",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Kubernetes avec Docker",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur de Plugins Docker",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Docker pour Windows ou Docker pour Mac",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Docker pour les Opérations (DevOps)",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Docker pour la Sécurité",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Docker pour les Environnements Cloud",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Docker pour les Conteneurs Windows",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Docker pour les Conteneurs ARM",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Docker pour les Conteneurs Linux",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Développeurs  GitHub Actions ",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Développeur CI/CD GitHub Actions",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur de Tests Automatisés GitHub Actions",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur de Publication de Packages GitHub Actions",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur de Gestion des Versions GitHub Actions",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur d'Alertes et Notifications GitHub Actions",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur de Déploiement d'Environnements de Développement GitHub Actions",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur de Sécurité et d'Analyse du Code GitHub Actions",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur de Documentation Automatique GitHub Actions",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur de Gestion de Projet GitHub Actions",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur de Déploiement sur Différentes Plates-Formes GitHub Actions",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur de Tests sur Différents Navigateurs et Systèmes GitHub Actions",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur d'Analyse de Performances GitHub Actions",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur de Synchronisation avec des Outils Externes GitHub Actions",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "développeurs sur GitHub Container Registry",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Développeur de Gestion des Images Docker",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur de Flux de Travail de Construction d'Images Docker",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur de Publication de Packages Docker",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur de Sécurité des Conteneurs Docker",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur de Politiques de Rétention d'Images Docker",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur de Gestion des Collaborateurs du Registre",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur de Synchronisation avec des Outils Externes",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur de Surveillance et de Reporting du Registre",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur de Flux de Travail de Déploiement d'Images Docker",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur de Collaboration au Niveau du Registre",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur de Politiques d'Accès au Registre",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur de Documentation du Registre",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    }
                ]
            },
            {
                "name": "UX/UI Designers",
                "selectable": false,
                "children": [
                    {
                        "name": "Designers UX (Expérience Utilisateur)",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Recherche utilisateur",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Architecture de l'information",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Design d'interaction",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Design d'interface",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Prototypage et wireframing",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Tests utilisateurs",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Stratégie UX",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Design de contenu",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Accessibilité",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Design émotionnel",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Designers UI (Interface Utilisateur)",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Design d'icônes",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Typographie",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Design de systèmes (Design Systems)",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Palettes de couleurs",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Maquettes et mockups",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Design responsive et adaptatif",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Animation et transitions",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Design pour mobile (iOS, Android)",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Design web",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Design d'applications logicielles",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Designers UI Mobile",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Design pour iOS",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Design pour Android",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Design d'icônes mobiles",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Design d'interfaces pour smartwatches",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Design responsive pour tablettes",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Design d'interfaces pour d'autres dispositifs mobiles (ex. lunettes AR)",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Animation et transitions pour mobile",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Adaptation de l'UI pour différents écrans et résolutions",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Design de systèmes pour mobile (Design Systems)",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Conception d'interfaces pour applications hybrides.",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Designers Web",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Design de sites e-commerce",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Design de sites institutionnels",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Design de blogs et magazines en ligne",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Design d'interfaces web app",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Design responsive et adaptatif",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Design de landing pages",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Design de portfolios",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Design d'interfaces pour CMS (ex. WordPress, Drupal)",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Design de systèmes pour web (Design Systems)",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Web animation et interactions.",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Illustrateurs",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Illustration numérique",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Illustration traditionnelle (ex. aquarelle, crayon)",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Illustration éditoriale (pour magazines, journaux)",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Illustration de livres (couvertures, intérieurs)",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Bande dessinée et roman graphique",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Caricature et dessin humoristique",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Illustration de mode",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Illustration scientifique ou médicale",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Illustration pour jeux (jeux vidéo, jeux de société)",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Illustration pour enfants.",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Designers de Produits",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Design de mobilier",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Design industriel",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Design d'éclairage",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Design de jouets",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Design d'emballage et packaging",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Design d'accessoires (ex. bijoux, sacs)",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Design d'électronique grand public",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Design de produits durables et éco-conçus",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Design pour la santé et dispositifs médicaux",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Design automobile et transport.",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Designers de Produits Numériques",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Design d'interfaces (UI/UX)",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Design d'applications mobiles",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Design d'applications web",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Design de systèmes (Design Systems)",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Prototypage et wireframing",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Design d'interaction",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Design d'expérience utilisateur (UX Research)",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Design de jeux vidéo",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Design d'interfaces pour réalité virtuelle et augmentée",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Design de services numériques.",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Directeurs Créatifs",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Direction créative en publicité",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Direction artistique",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Direction créative en design graphique",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Direction créative en digital/numérique",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Direction créative en mode",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Direction créative en cinéma et télévision",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Direction créative en musique",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Direction créative en jeux vidéo",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Direction créative en édition et publications",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Direction créative en événementiel.",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Designers Freelance",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Designer graphique freelance",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Designer d'interface (UI) freelance",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Designer d'expérience utilisateur (UX) freelance",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Illustrateur freelance",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Designer de produits freelance",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Designer de mode freelance",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Designer d'animation freelance",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Designer web freelance",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Designer de branding et identité visuelle freelance",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Designer d'emballage et packaging freelance.",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Designers en Réalité Augmentée",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Design d'interfaces pour AR",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Design d'expériences immersives en AR",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Storyboarding et scénarisation pour AR",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Modélisation et animation 3D pour AR",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Design de jeux en réalité augmentée",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Design pour la formation et l'éducation en AR",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Design d'AR pour le commerce de détail et la publicité",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Design de systèmes de navigation et cartographie en AR",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Design d'AR pour la santé et la médecine",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Design de réalité augmentée pour l'événementiel.",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Experts Sketch",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Design d'interfaces avec Sketch",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Prototypage avec Sketch",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Création de systèmes de design avec Sketch",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Animation avec Sketch et des plugins tiers",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Design d'icônes et d'illustrations vectorielles avec Sketch",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Utilisation avancée de symboles et de bibliothèques partagées",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Intégration de Sketch avec d'autres outils (ex. Zeplin, InVision)",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Design responsive et adaptatif avec Sketch",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Gestion des styles et des thèmes avec Sketch",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Extension de Sketch avec des plugins et des scripts personnalisés.",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Designers Fintech",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Design d'interfaces pour applications bancaires",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "UX pour la gestion de portefeuilles et investissements",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Design pour les plateformes de trading",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Design de systèmes de paiement et transferts",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Design pour les applications d'assurance",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Design d'interfaces pour la blockchain et les cryptomonnaies",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Design de dashboards financiers",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Design pour applications de prêts et de crédit",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "UX pour la conformité et la sécurité financière",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Design de solutions pour la finance inclusive et les services non bancaires.",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Chercheurs UX (Expérience Utilisateur)",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Recherche formative",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Recherche sommative",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Recherche qualitative",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Recherche quantitative",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Tests d'utilisabilité",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Recherche ethnographique",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Tri de cartes (Card Sorting)",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Tests A/B",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Enquêtes et sondages",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Journaux et diaries",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    }
                ]
            },
            {
                "name": "Programming Languages",
                "selectable": false,
                "children": [
                    {
                        "name": "Développeurs Python",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Développement web avec Django",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développement web avec Flask",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Science des données avec Pandas et NumPy",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Apprentissage automatique avec TensorFlow et Scikit-learn",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développement de scripts et d'automatisations",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développement d'applications de bureau avec PyQt ou Tkinter",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développement de jeux avec Pygame",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Administration système et automatisation avec Python",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Traitement du texte et des expressions régulières",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développement pour le web scraping avec Beautiful Soup ou Scrapy.",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Développeurs JavaScript",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Développement frontend avec Vue.js",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développement frontend avec Angular",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développement backend avec Node.js",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développement de jeux avec Phaser.js",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développement d'applications mobiles avec React Native",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développement d'applications desktop avec Electron",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Programmation fonctionnelle avec JavaScript",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développement avec TypeScript",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développement d'applications en temps réel avec Socket.io.",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Développeurs C++",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Développement de logiciels système",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développement de jeux avec Unreal Engine",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Programmation pour l'embarqué et les systèmes temps réel",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développement d'applications de bureau (ex. avec Qt)",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Optimisation de performance et programmation bas-niveau",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développement de bibliothèques et frameworks en C++",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Programmation concurrente et multithreading en C++",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": " ",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Développement pour la réalité virtuelle et augmentée",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Programmation de simulations et modélisation",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développement de drivers et logiciels matériels.",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Développeurs PHP",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Développement avec le framework Laravel",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développement avec le framework Symfony",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développement de plugins et thèmes pour WordPress",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développement e-commerce avec Magento",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développement de sites avec Drupal",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développement avec le framework Phalcon",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Optimisation et sécurité en PHP",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développement de systèmes de gestion de contenu (CMS) personnalisés",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Programmation orientée objet en PHP",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Intégration et développement d'APIs en PHP.",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Développeurs Ruby",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Développement avec le framework Ruby on Rails",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développement avec le framework Sinatra",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Scripting et automatisation avec Ruby",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développement de gems Ruby personnalisés",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Test et spécification avec RSpec",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Déploiement et intégration continue pour applications Ruby",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développement d'APIs en Ruby",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Programmation orientée objet en Ruby",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Optimisation et performance en Ruby",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développement avec le framework Hanami.",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Développeurs C#",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Développement avec le framework .NET Core",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développement d'applications de bureau avec Windows Forms ou WPF",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développement de jeux avec Unity",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développement d'applications web avec ASP.NET MVC",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Programmation pour Azure (services cloud de Microsoft)",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développement pour Xamarin (applications mobiles)",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Programmation orientée objet en C#",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développement d'applications d'entreprise avec Entity Framework",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Test et spécification avec NUnit ou MSTest",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développement d'applications Windows UWP.",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Développeurs Java",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Développement d'applications web avec Spring Boot",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développement d'applications d'entreprise avec Java EE",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développement d'applications mobiles avec Android",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Programmation avec le framework Hibernate",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développement d'applications de bureau avec JavaFX ou Swing",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développement de microservices avec Spring Cloud",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Intégration et développement d'APIs avec JAX-RS ou Spring REST",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Programmation orientée objet en Java",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Test et spécification avec JUnit ou TestNG",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développement pour des systèmes embarqués en Java.",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Développeurs Rust",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Développement de systèmes",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Programmation WebAssembly avec Rust",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développement de jeux avec Amethyst",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développement d'applications web avec le framework Rocket",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Programmation concurrente et utilisation des \"futures\" en Rust",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Optimisation de performance et programmation bas-niveau",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Intégration et développement d'APIs avec Rust",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développement de bibliothèques et crates personnalisés",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Programmation orientée objet et fonctionnelle en Rust",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développement pour l'embarqué avec Rust.",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Développeurs Erlang",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Développement avec le framework OTP (Open Telecom Platform)",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Programmation concurrente et distribution de systèmes",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développement d'applications web avec Cowboy ou Yaws",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Gestion des bases de données NoSQL avec Mnesia",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Optimisation et débogage de systèmes en temps réel",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Intégration et développement d'APIs avec Erlang",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Programmation fonctionnelle en Erlang",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développement pour les télécommunications avec Erlang",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Tests et spécification avec EUnit ou Common Test",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développement de systèmes distribués résilients avec Erlang.",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Développeurs Clojure",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Développement d'applications web avec le framework Ring et Compojure",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développement d'interfaces utilisateur avec Reagent ou re-frame",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Programmation fonctionnelle en Clojure",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Gestion de données avec la bibliothèque Datomic",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développement d'applications concurrentes avec core.async",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Scripting et automatisation avec Clojure",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Intégration et développement d'APIs avec Clojure",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développement de microservices en Clojure",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Tests et spécification avec Clojure.test ou Spec",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développement avec ClojureScript pour des applications front-end.",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Développeurs Dart",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Développement d'applications mobiles avec Flutter",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Programmation fonctionnelle en Dart",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développement d'applications web avec AngularDart",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développement d'applications de bureau avec Flutter",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Gestion d'état avec des solutions comme Riverpod ou Provider",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Intégration et développement d'APIs avec Dart",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Tests et spécification avec le package test de Dart",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développement orienté objet en Dart",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Optimisation de performance et débogage en Dart",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développement d'applications multiplateformes avec Flutter.",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Développeurs Haskell",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Programmation fonctionnelle pure en Haskell",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développement d'applications web avec Yesod ou Servant",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Manipulation de données avec Haskell et bibliothèques comme Lens",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Conception et utilisation de types avancés",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développement d'applications concurrentes avec Haskell",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Tests et spécification avec QuickCheck",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Optimisation de performance et profilage en Haskell",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développement de DSLs (Domain Specific Languages) en Haskell",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Intégration et développement d'APIs avec Haskell",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Utilisation de GHC (Glasgow Haskell Compiler) et ses extensions.",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Développeurs Swift",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Développement iOS",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développement macOS",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développement watchOS",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développement tvOS",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Swift pour le développement serveur (par exemple, avec Vapor)",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développement d'interfaces avec SwiftUI",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Gestion des données avec Core Data en Swift",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Programmation orientée objet et fonctionnelle en Swift",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Tests unitaires et UI avec XCTest",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Intégration avec des API et services tiers en Swift.",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Développeurs Golang",
                        "selectable": false,
                        "children": [
                            {
                                "name": "éveloppement d'APIs RESTful avec Go",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développement de microservices en Go",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Programmation concurrente avec goroutines et channels",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développement d'applications web avec frameworks comme Gin ou Echo",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Gestion de données avec des bases de données comme BoltDB ou CockroachDB en Go",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Optimisation de performance et profilage en Go",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développement d'outils et d'utilitaires CLI en Go",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Intégration de systèmes et middleware en Go",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Tests unitaires et benchmarking avec le package testing de Go",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développement d'applications réseau et systèmes distribués en Go.",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Développeurs VB.NET",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Développement d'applications Windows Forms",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développement d'applications web avec ASP.NET",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Programmation orientée objet en VB.NET",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Gestion de données avec ADO.NET",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développement d'applications de bureau avec WPF",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Utilisation de LINQ pour les requêtes de données",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développement d'applications mobiles avec Xamarin en VB.NET",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Tests unitaires avec Microsoft's Unit Testing Framework",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Intégration avec des bases de données comme SQL Server",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développement d'add-ins et de plugins pour les applications Microsoft Office en VB.NET.",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Développeurs Visual Basic",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Développement d'applications Windows avec Visual Basic 6",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Création de composants ActiveX",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Gestion de données avec DAO (Data Access Objects) ou RDO (Remote Data Objects)",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Programmation orientée objet en Visual Basic",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Automatisation et création de macros pour les applications Microsoft Office",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Intégration avec des bases de données comme Access ou SQL Server",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développement d'applications client-serveur",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Utilisation de l'API Windows pour étendre les capacités de Visual Basic",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Tests et débogage d'applications Visual Basic",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Migration ou mise à jour de projets Visual Basic vers VB.NET.",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Développeurs Elixir",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Développement de systèmes embarqués avec Nerves",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Programmation fonctionnelle en Elixir",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Tests et spécification avec ExUnit",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développement d'applications d'entreprise et de systèmes distribués",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Intégration avec des bases de données et services tiers",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Optimisation de performance et débogage en Elixir.",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Développeurs  ChatGPI API\n",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Intégration d'assistants virtuels et chatbots",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développement d'applications d'assistance clientèle automatisée",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Création de systèmes de recommandation basés sur le langage naturel",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Traitement du langage naturel et analyse de texte avec ChatGPT",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Adaptation et ajustement des modèles pour des domaines spécifiques",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Conception d'interfaces utilisateur pour une interaction optimale avec les modèles",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Gestion de la scalabilité et des performances avec l'API de ChatGPT",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Implémentation de mesures de sécurité et de confidentialité",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Tests, validation et amélioration continue de l'interaction utilisateur",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Intégration avec d'autres services et API pour des applications multimodales.",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Développeurs en LLAMA 2 \n",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Intégration de Llama 2 pour des applications de chatbot",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développement avec les modèles Llama pré-entraînés",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Personnalisation et affinement des modèles Llama pour des domaines spécifiques",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Optimisation et performance avec Llama 2",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Tests et validation de l'interaction utilisateur avec Llama 2",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Gestion de la scalabilité avec l'API Llama 2",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Intégration de Llama 2 dans des systèmes existants",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Évaluation des performances de Llama 2 par rapport à d'autres modèles",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Sécurité et confidentialité avec Llama 2",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développement d'outils et d'utilitaires basés sur Llama 2.",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Développeurs en Langchain \n",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Intégration de LLMs avec LangChain",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développement d'applications de chatbot avec LangChain",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Conception d'interfaces pour des applications LangChain",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Gestion de données et intégration avec d'autres outils",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développement d'applications web avec LangChain",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Intégration de LangChain avec Python ou JavaScript",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Optimisation et personnalisation des modèles LLM avec LangChain",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Tests et validation de modèles avec LangChain",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Gestion de la scalabilité des applications LangChain",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Sécurité et confidentialité dans le développement avec LangChain.",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    }
                ]
            },
            {
                "name": "Software Development Roles & Collaboration Models",
                "selectable": false,
                "children": [
                    {
                        "name": "Développeurs de logiciels",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Développeurs Full-Stack",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Développeurs Back-end",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Développeurs Front-end",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Développeurs mobiles",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Rédacteurs techniques",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Consultants produits",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Architectes logiciels",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Développeurs à distance",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Codeurs",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Développeurs de prototypes",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Développeurs de startups",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Développeurs externalisés",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Développeurs offshore",
                        "selectable": true,
                        "children": []
                    }
                ]
            },
            {
                "name": "Quality Assurance & Testing",
                "selectable": false,
                "children": [
                    {
                        "name": "Testeurs QA (Assurance Qualité)",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Testeur QA manuel",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Testeur QA automatisé",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Testeur QA de performance",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Testeur QA de sécurité",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Testeur QA de compatibilité",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Testeur QA de localisation",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Testeur QA de jeux vidéo",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Testeur QA de logiciels mobiles",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Testeur QA de logiciels en nuage (Cloud)",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Testeur QA de logiciels embarqués",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Testeur QA de logiciels ERP",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Testeur QA de logiciels CRM.",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Testeurs manuels",
                        "selectable": false,
                        "children": [
                            {
                                "name": "esteur manuel fonctionnel",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Testeur manuel d'interface utilisateur (UI)",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Testeur manuel de convivialité",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Testeur manuel de compatibilité",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Testeur manuel de performance",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Testeur manuel de sécurité",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Testeur manuel de localisation",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Testeur manuel de jeux vidéo",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Testeur manuel de logiciels mobiles",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Testeur manuel de logiciels embarqués",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Testeur manuel de logiciels CRM (Gestion de la Relation Client)",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Testeur manuel de logiciels ERP (Planification des Ressources d'Entreprise).",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Chefs QA (Assurance Qualité)",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Chef QA de projet",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Chef QA manuel",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Chef QA automatisé",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Chef QA de performance",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Chef QA de sécurité",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Chef QA de compatibilité",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Chef QA de localisation",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Chef QA de jeux vidéo",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Chef QA de logiciels mobiles",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Chef QA de logiciels embarqués",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Chef QA de logiciels ERP (Planification des Ressources d'Entreprise)",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Chef QA de logiciels CRM (Gestion de la Relation Client).",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Ingénieurs en automatisation des tests",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Ingénieur en automatisation des tests de logiciels",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Ingénieur en automatisation des tests mobiles",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Ingénieur en automatisation des tests Web",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Ingénieur en automatisation des tests de jeux vidéo",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Ingénieur en automatisation des tests de performance",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Ingénieur en automatisation des tests de sécurité",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Ingénieur en automatisation des tests de compatibilité",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Ingénieur en automatisation des tests de localisation",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Ingénieur en automatisation des tests de systèmes embarqués",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Ingénieur en automatisation des tests de logiciels ERP",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Ingénieur en automatisation des tests de logiciels CRM",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Ingénieur en automatisation des tests de logiciels en nuage (Cloud).",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Développeurs Selenium",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Développeur Selenium WebDriver",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Selenium Grid",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Selenium avec Java",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Selenium avec Python",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Selenium avec C#",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Selenium pour mobile",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Selenium pour la sécurité",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Selenium pour l'automatisation de tâches web",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Selenium pour la compatibilité multi-navigateurs",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Selenium pour les tests de performance.",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Développeurs Jenkins",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Développeur Jenkins Pipeline",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Jenkinsfile",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Jenkins Plugins",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Jenkins Job DSL",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Jenkins pour le déploiement continu (CD)",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Jenkins pour l'intégration avec d'autres outils",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Jenkins pour la sécurité",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Jenkins pour la surveillance et la gestion des performances",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Jenkins pour la haute disponibilité",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Jenkins pour l'intégration de tests automatisés.",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Développeurs Appium",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Développeur Appium pour Android",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Appium pour iOS",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Appium pour cross-platform",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Appium pour les tests de jeu mobile",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Appium pour les tests de sécurité mobile",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Appium pour les tests de performance mobile",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Appium avec Java",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Appium avec Python",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Appium avec C#",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Appium pour le déploiement continu (CD).",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Développeurs Cucumber",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Développeur Cucumber avec Gherkin",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Cucumber pour les tests fonctionnels",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Cucumber pour les tests d'acceptation",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Cucumber pour les tests de non-régression",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Cucumber pour les tests de bout en bout",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Cucumber pour les tests d'intégration",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Cucumber avec Java",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Cucumber avec Ruby",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Cucumber pour les tests de l'interface utilisateur (UI)",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Cucumber pour l'intégration continue (CI).",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    }
                ]
            },
            {
                "name": "API Development & Integration",
                "selectable": false,
                "children": [
                    {
                        "name": "Spécialistes en développement d'API",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Développeur d'API RESTful",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur d'API GraphQL",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur d'API SOAP",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur d'API JSON-RPC",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur d'API gRPC",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur d'API OpenAPI (Swagger)",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur d'API de sécurité",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur d'API pour les microservices",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur d'API de données",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur d'API pour l'internet des objets (IoT)",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur d'API pour l'intégration de systèmes",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur d'API pour la gestion d'identité.",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Développeurs d'API LinkedIn",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Développeur d'API LinkedIn pour l'authentification",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur d'API LinkedIn pour la collecte de données",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur d'API LinkedIn pour les notifications",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur d'API LinkedIn pour les publications",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur d'API LinkedIn pour les recrutements",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur d'API LinkedIn pour la publicité",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur d'API LinkedIn pour l'analyse",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur d'API LinkedIn pour la messagerie",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur d'API LinkedIn pour le marketing",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur d'API LinkedIn pour la gestion de contenu.",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Développeurs d'API Facebook",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Développeur d'API Facebook pour l'authentification",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur d'API Facebook pour la collecte de données",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur d'API Facebook pour les publications",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur d'API Facebook pour les commentaires et les réactions",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur d'API Facebook pour les notifications",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur d'API Facebook pour les publicités",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur d'API Facebook pour les jeux",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur d'API Facebook pour les événements",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur d'API Facebook pour le commerce électronique",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur d'API Facebook pour l'analyse.",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Développeurs d'API WhatsApp",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Développeur d'API WhatsApp pour l'envoi de messages",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur d'API WhatsApp pour les chatbots",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur d'API WhatsApp pour les notifications",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur d'API WhatsApp pour le support client",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur d'API WhatsApp pour le commerce électronique",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur d'API WhatsApp pour les campagnes marketing",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur d'API WhatsApp pour l'authentification à deux facteurs (2FA)",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur d'API WhatsApp pour les enquêtes et les sondages",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur d'API WhatsApp pour les intégrations d'entreprise",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur d'API WhatsApp pour la gestion de groupe.",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Développeurs d'API Tik Tok ",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Développeur d'API TikTok pour la création de contenu",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur d'API TikTok pour les outils de gestion de compte",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur d'API TikTok pour les statistiques et les analyses",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur d'API TikTok pour les campagnes marketing",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur d'API TikTok pour les tendances et les recommandations",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur d'API TikTok pour les filtres et les effets spéciaux",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur d'API TikTok pour les défis et les jeux",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur d'API TikTok pour les événements en direct",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur d'API TikTok pour les intégrations e-commerce",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur d'API TikTok pour les interactions sociales.",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Développeurs Stripe",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Développeur Stripe pour les paiements en ligne",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Stripe pour les paiements récurrents",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Stripe pour les paiements mobiles",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Stripe pour les plateformes de commerce électronique",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Stripe pour les solutions de point de vente (POS)",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Stripe pour les entreprises B2B",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Stripe pour les dons en ligne",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Stripe pour les services financiers",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Stripe pour la gestion des abonnements",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Stripe pour l'analyse des paiements.",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Développeurs d'API Shopify",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Développeur d'API Shopify pour les applications de boutique en ligne",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur d'API Shopify pour le développement de thèmes",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur d'API Shopify pour l'intégration de paiements",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur d'API Shopify pour la gestion des commandes et de l'inventaire",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur d'API Shopify pour le marketing et les ventes",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur d'API Shopify pour l'automatisation des tâches",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur d'API Shopify pour les rapports et les analyses",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur d'API Shopify pour le commerce omnicanal",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur d'API Shopify pour la personnalisation de produits",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur d'API Shopify pour l'expérience client.",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    }
                ]
            },
            {
                "name": "Blockchain Development",
                "selectable": false,
                "children": [
                    {
                        "name": "Développeurs Blockchain",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Développeurs de Smart Contracts",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Développeurs Ethereum",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Développeurs de Smart Contracts Ethereum",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Développeurs de Cryptomonnaie",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Développeurs Hyperledger",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Développeurs Solidity",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Ingénieurs des systèmes distribués",
                        "selectable": true,
                        "children": []
                    }
                ]
            },
            {
                "name": "Desktop Development",
                "selectable": false,
                "children": [
                    {
                        "name": "Développeurs PyQt",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Développeurs DevExpress",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Développeurs Qt",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Développeurs .NET",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Développeurs .NET Core",
                        "selectable": true,
                        "children": []
                    }
                ]
            },
            {
                "name": "AR/VR & Game Development",
                "selectable": false,
                "children": [
                    {
                        "name": "Développeurs de jeux",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Programmation",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Conception de jeu",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Graphismes et Animation",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Son et Musique",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Test et Débogage",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Narration et Scénarisation",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Gestion de Projet",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Marketing et Promotion",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Connaissance des Plateformes",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Sécurité et Monétisation",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Développeurs en réalité augmentée",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Programmation AR",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Conception AR",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Modélisation 3D",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Traitement d'Image/Vidéo",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Optimisation de la Performance",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Interaction et Interface Utilisateur",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Détection de la Profondeur",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Systèmes d'Exploitation/Plateformes",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Intelligence Artificielle et Apprentissage Machine",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Test et Débogage AR",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Designers en réalité augmentée",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Conception d'Interfaces Utilisateur (UI) AR",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Conception d'Expériences AR",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Conception d'Objets Virtuels AR",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Design Spatial AR",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Design Sonore AR",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Utilisabilité et Ergonomie",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Narration et Scénarisation AR",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Design de Marque AR",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Optimisation des Performances AR",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Tests et Itérations AR",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Développeurs en réalité virtuelle",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Programmation VR",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Conception d'Expériences VR",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Intégration de Contrôleurs VR",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Modélisation 3D VR",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Optimisation des Performances VR",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Interaction VR",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Audio Spatial VR",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Systèmes d'Exploitation et Plateformes VR",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Tests et Débogage VR",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Réseaux et Multijoueur VR",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Animateurs 3D",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Animation de Personnages 3D",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Animation d'Objets 3D",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Animation de Créatures Fantastiques",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Animation Faciale",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Animation de Caméra",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Rigging",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Animation de Jeux Vidéo",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Effets Spéciaux (VFX)",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Modélisation 3D",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Texturage",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Éclairage et Rendu",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Logiciels 3D",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Motion Capture (MoCap)",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Art et Créativité",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Collaboration",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Designers de jeux mobiles",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Conception de Mécanismes de Jeu",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Conception de Niveaux",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Conception d'Interfaces Utilisateur (UI)",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Conception Visuelle",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Conception Sonore",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Équilibrage du Jeu",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Monétisation",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Test et Analyse",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Optimisation des Performances",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Narration et Scénarisation",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Adaptation aux Plateformes",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Marketing et Promotion",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Suivi des Tendances",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Analyse du Public Cible",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Sensibilité au Mobile",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Développeurs en vision par ordinateur",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Programmation",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Traitement d'Images",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Détection d'Objets",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Suivi d'Objets",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Reconnaissance de Formes",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Segmentation d'Images",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Apprentissage Profond (Deep Learning)",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Localisation et Cartographie (SLAM)",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Analyse Vidéo",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Calibrage de Caméra",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Intégration Matérielle",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Analyse de Données 3D",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Optimisation et Débogage",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Applications Spécialisées",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Éthique et Sécurité",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Développeurs iOS",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Langages de Programmation (Swift, Objective-C)",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développement d'Applications iOS",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Utilisation d'Xcode (IDE)",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Conception d'Interfaces Utilisateur (UI)",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Connaissance des Frameworks iOS (UIKit, SwiftUI, Core Data, Core Animation, etc.)",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développement d'Applications Multitouch",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Intégration de Fonctionnalités Apple (Face ID, Touch ID, Apple Pay, etc.)",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Optimisation des Performances",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développement d'Applications Multilingues",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développement d'Applications pour iPad",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Tests et Débogage",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Intégration de Services Cloud",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Déploiement sur l'App Store",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Gestion des Mises à Jour",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Sécurité et Confidentialité",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Connaissance des Dernières Versions d'iOS",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Documentation et Communication",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Développeurs d'applications Android",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Maîtrise de Java/Kotlin",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Utilisation d'Android Studio",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développement d'Interfaces Utilisateur (UI)",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Gestion des Composants Android",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Connaissance des API Android",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Gestion des Données",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Navigation et Flux d'Écrans",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Réseaux et Services Web",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Gestion de Versions et de Déploiement",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Tests et Débogage",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Optimisation des Performances",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Sécurité",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Notifications",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Connaissance des Bibliothèques Tierces",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Adaptabilité aux Différents Appareils",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Conception de l'Expérience Utilisateur (UX)",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Internationalisation et Localisation",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Documentation",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Collaboration",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Mises à Jour et Maintenance",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Animateurs 2D",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Animation de Personnages 2D",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Animation d'Objets 2D",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Animation de Personnages de Dessins Animés",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Animation de Bandes Dessinées",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Animation de Jeux Vidéo 2D",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Animation de Publicités et de Clips Musicaux",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Effets Spéciaux 2D (VFX)",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Storyboarding",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Conception de Personnages 2D",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Texturage et Colorisation",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Logiciels d'Animation 2D",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Timing et Rythme",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Connaissance des Principes de l'Animation",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Collaboration",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Narration et Scénarisation",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Artistes numériques",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Dessin Numérique",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Peinture Numérique",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Modélisation 3D",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Texturage et Mapping UV",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Rigging",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Animation 3D",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Effets Visuels (VFX)",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Sculpture Numérique",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Storyboarding",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Conception de Personnages",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Conception d'Environnements",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Conception d'Interface Utilisateur (UI)",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Conception de Logo et de Marque",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Illustration Vectorielle",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Photomanipulation",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Animation 2D",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Effets Sonores et Musique",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Connaissance des Logiciels Artistiques",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Collaboration",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Créativité et Inspiration",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Développeurs Unity ou Unity3D",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Programmation en C#",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Utilisation de l'Éditeur Unity",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développement de Jeux 2D et 3D",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Conception de Niveaux",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Physique et Simulation",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Animation",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Gestion des Actifs",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Intelligence Artificielle (IA)",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Réseaux Multijoueurs",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Optimisation des Performances",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Effets Visuels (VFX)",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Son et Musique",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Déploiement sur Différentes Plateformes",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Tests et Débogage",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Sécurité",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Intégration de Publicités et de Monétisation",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Analyse de Données",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Documentation",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Collaboration",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Gestion de Projet",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Développeurs Unreal Engine",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Maîtrise du Moteur Unreal Engine",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Programmation en C++ et Blueprints",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Conception de Niveaux",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Graphismes et Rendu",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Modélisation 3D",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Animation",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Physique",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Intelligence Artificielle (IA)",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Effets Sonores et Musique",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Réseaux Multijoueurs",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Optimisation des Performances",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Déploiement sur Différentes Plateformes",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Tests et Débogage",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Gestion de Projet",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Sécurité",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Analyse de Données",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Documentation",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Collaboration",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Développeurs Three.js",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Maîtrise de JavaScript",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Utilisation de Three.js",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Modélisation 3D",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Scène et Caméra",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Lumières et Matériaux",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Animation",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Physique 3D",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Réseaux et Intégration",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Optimisation des Performances",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Déploiement Web",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Gestion des Ressources",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Débogage et Tests",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Mathématiques 3D",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Sécurité",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Documentation",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Collaboration",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Développeurs C++",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Maîtrise de C++",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Programmation Orientée Objet (POO)",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Utilisation des Bibliothèques C++",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Conception Logicielle",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développement d'Applications Multiplateformes",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Programmation Système",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Optimisation de la Performance",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développement de Jeux Vidéo",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développement Embarqué",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Programmation Réseau",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Sécurité",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Débogage",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Gestion de Projet",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Connaissance des Frameworks C++",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Intégration de Langages et de Bibliothèques Externes",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Documentation",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    }
                ]
            },
            {
                "name": "Mobile Development",
                "selectable": false,
                "children": [
                    {
                        "name": "Développeurs pour l'App Store",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Développeur iOS (Apple App Store)",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Android (Google Play Store)",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur d'applications natives",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur d'applications hybrides",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur de jeux mobiles",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur d'applications e-commerce",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur d'applications de médias sociaux",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur d'applications de productivité",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur d'applications de santé et de bien-être",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur d'applications éducatives.",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Développeurs Android",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Développeur d'applications Android natives",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur d'applications Android hybrides",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur de jeux Android",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur d'applications Android pour l'IoT (Internet des objets)",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur d'applications Android pour l'e-commerce",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur d'applications Android de streaming vidéo",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur d'applications Android pour la santé et le bien-être",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur d'applications Android de médias sociaux",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur d'applications Android de productivité",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur d'applications Android éducatives.",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Développeurs d'applications Android",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Maîtrise de Java/Kotlin",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Utilisation d'Android Studio",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développement d'Interfaces Utilisateur (UI)",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Gestion des Composants Android",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Connaissance des API Android",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Gestion des Données",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Navigation et Flux d'Écrans",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Réseaux et Services Web",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Gestion de Versions et de Déploiement",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Tests et Débogage",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Optimisation des Performances",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Sécurité",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Notifications",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Connaissance des Bibliothèques Tierces",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Adaptabilité aux Différents Appareils",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Conception de l'Expérience Utilisateur (UX)",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Internationalisation et Localisation",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Documentation",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Collaboration",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Mises à Jour et Maintenance",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Développeurs iOS",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Développeur d'applications iOS natives",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur d'applications iOS hybrides",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur de jeux iOS",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur d'applications iOS pour l'IoT (Internet des objets)",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur d'applications iOS pour l'e-commerce",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur d'applications iOS de streaming vidéo",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur d'applications iOS pour la santé et le bien-être",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur d'applications iOS de médias sociaux",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur d'applications iOS de productivité",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur d'applications iOS éducatives.",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Gestionnaires de produits d'applications",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Gestionnaire de produits mobiles",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Gestionnaire de produits web",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Gestionnaire de produits logiciels",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Gestionnaire de produits SaaS (Software as a Service)",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Gestionnaire de produits d'applications mobiles pour la santé",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Gestionnaire de produits d'applications éducatives",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Gestionnaire de produits d'applications de médias sociaux",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Gestionnaire de produits d'applications e-commerce",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Gestionnaire de produits d'applications de divertissement",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Gestionnaire de produits d'applications de productivité.",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Gestionnaires de projets mobiles",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Gestion de Projet",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Compréhension des Plateformes Mobiles",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Analyse des Besoins",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Définition des Objectifs",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Planification Stratégique",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Gestion des Ressources",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Collaboration en Équipe",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Communication",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Gestion des Risques",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Suivi et Évaluation",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Conformité Légale et Réglementaire",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Optimisation des Performances",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Sécurité",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Test et Assurance Qualité",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Marketing et Lancement",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Collecte et Analyse de Données",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Formation et Support Utilisateur",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Gestion des Retours Utilisateurs",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Évolution et Mises à Jour",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Documentation et Rapports",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Designers UX mobiles",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Recherche Utilisateur",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Analyse de l'Utilisateur",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Conception de l'Architecture de l'Information",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Wireframing",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Prototypage",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Conception d'Interface Utilisateur (UI)",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Conception de l'Expérience Utilisateur (UX)",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Design Responsif",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Animation et Transitions",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Tests Utilisateur",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Optimisation de la Performance",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Accessibilité",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Sélection d'Images et de Médias",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Collaboration avec les Développeurs",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Conformité aux Normes de Design",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Connaissance des Tendances et des Technologies",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Documentation de Conception",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Sens de l'Esthétique",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Feedback et Itérations",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Gestion de Projet",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Designers UI mobiles",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Conception d'Interfaces Utilisateur (UI)",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Conception de l'Expérience Utilisateur (UX)",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Prototypage",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Wireframing",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Choix de Couleurs et de Typographie",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Sélection d'Iconographie",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Conception d'Éléments d'Interface",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Responsive Design",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Animation et Transitions",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Tests Utilisateur",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Accessibilité",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Conformité aux Normes de Design",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Connaissance des Plateformes",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Collaboration avec les Développeurs",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Connaissance des Tendances et des Technologies",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Documentation de Conception",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Sens de l'Esthétique",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Feedback et Itérations",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Gestion de Projet",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Designers d'applications mobiles",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Conception d'Interfaces Utilisateur (UI)",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Conception de l'Expérience Utilisateur (UX)",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Prototypage",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Wireframing",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Choix de Couleurs et de Typographie",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Sélection d'Iconographie",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Conception d'Éléments d'Interface",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Responsive Design",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Animation et Transitions",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Tests Utilisateur",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Accessibilité",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Connaissance des Plateformes",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Conception d'Écrans Multilingues",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Documentation de Conception",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Visualisation de Données",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Conformité aux Normes de Design",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Collaboration avec les Développeurs",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Connaissance des Tendances et des Technologies",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Sens de l'Esthétique",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Feedback et Itérations",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Gestion de Projet",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Mises à Jour et Maintenance",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Développeurs d'applications iPhone",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Maîtrise de Swift",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Utilisation de Xcode",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développement d'Interfaces Utilisateur (UI)",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Gestion des Composants d'Interface",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Conception de l'Expérience Utilisateur (UX)",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Connaissance des API iOS",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Gestion de Données",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Navigation et Flux d'Écrans",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Réseaux et Services Web",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Tests et Débogage",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Optimisation des Performances",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Sécurité",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Notifications",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Conformité aux Directives d'App Store",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Gestion de Versions et de Déploiement",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Connaissance des Bibliothèques Tierces",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Adaptabilité aux Différents Appareils",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Internationalisation et Localisation",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Documentation",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Collaboration",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Mises à Jour et Maintenance",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Designers de jeux mobiles",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Conception de Jeu",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Conception d'Interfaces Utilisateur (UI) de Jeu",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Conception de Niveaux",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Storyboarding",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Conception d'Environnements",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Animation",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Game Design Document (GDD)",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Conception de Personnages",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Conception sonore",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Tests et Équilibrage",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Monétisation",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Analyse de Données",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Connaissance des Plateformes",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Optimisation des Performances",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Conformité aux Règles de la Plateforme",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Mises à Jour et Maintenance",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Gestion de Projet",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Créativité et Innovation",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Développeurs Flutter",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Maîtrise de Dart",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Utilisation de Flutter",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développement d'Interfaces Utilisateur (UI)",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Navigation et Gestion de l'État",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Intégration de Conception (UI/UX)",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Gestion de Données",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Animation",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Tests et Débogage",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Optimisation des Performances",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Adaptabilité Multiplateforme",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Plugin et Package Flutter",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Sécurité",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Déploiement Multiplateforme",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Gestion de Versions",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Connaissance des Widgets Personnalisés",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Documentation",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Collaboration",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Développeurs Xamarin",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Maîtrise de C#",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Utilisation de Xamarin",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développement d'Interfaces Utilisateur (UI)",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Navigation et Gestion de l'État",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Intégration de Conception (UI/UX)",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Gestion de Données",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Tests et Débogage",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Optimisation des Performances",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Adaptabilité Multiplateforme",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Plugin Xamarin",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Sécurité",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Déploiement Multiplateforme",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Gestion de Versions",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Connaissance des Contrôles Personnalisés",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Documentation",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Collaboration",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Développeurs React Native",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Maîtrise de JavaScript/TypeScript",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Utilisation de React Native",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développement d'Interfaces Utilisateur (UI)",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Navigation et Gestion de l'État",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Intégration de Conception (UI/UX)",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Gestion de Données",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Tests et Débogage",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Optimisation des Performances",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Adaptabilité Multiplateforme",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Modules Natifs",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Sécurité",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Déploiement Multiplateforme",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Gestion de Versions",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Connaissance des Bibliothèques Tierces",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Documentation",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Collaboration",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Développeurs Kotlin",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Maîtrise de Kotlin",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Utilisation d'Android Studio",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développement d'Interfaces Utilisateur (UI)",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Gestion des Composants Android",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Connaissance des API Android",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Gestion de Données",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Navigation et Flux d'Écrans",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Réseaux et Services Web",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Gestion de Versions et de Déploiement",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Tests et Débogage",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Optimisation des Performances",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Sécurité",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Notifications",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Connaissance des Bibliothèques Tierces",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Adaptabilité aux Différents Appareils",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Conception de l'Expérience Utilisateur (UX)",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Internationalisation et Localisation",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Documentation",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Collaboration",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Mises à Jour et Maintenance",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Développeurs Swift",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Maîtrise de Swift",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Utilisation de Xcode",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développement d'Interfaces Utilisateur (UI)",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Gestion des Composants d'Interface",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Conception de l'Expérience Utilisateur (UX)",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Gestion des Composants du Système",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Gestion de Données",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Navigation et Flux d'Écrans",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Tests et Débogage",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Optimisation des Performances",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Adaptabilité Multiplateforme",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Sécurité",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Notifications et Interactions Utilisateur",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Gestion de Versions et de Déploiement",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Connaissance des Bibliothèques Tierces",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Internationalisation et Localisation",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Documentation",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Collaboration",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Mises à Jour et Maintenance",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    }
                ]
            },
            {
                "name": "Data Science & AI",
                "selectable": false,
                "children": [
                    {
                        "name": "Scientifiques des données",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Ingénieurs en apprentissage automatique",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Ingénieurs de données",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Analystes de données",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Développeurs en vision par ordinateur",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Développeurs d'algorithmes",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Développeurs Microsoft Access",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Développeurs MATLAB",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Ingénieurs MATLAB",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeurs MATLAB pour la Recherche",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeurs MATLAB pour l'Analyse de Données",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeurs MATLAB pour la Simulation",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeurs MATLAB pour le Traitement d'Images",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeurs MATLAB pour la Robotique",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeurs MATLAB pour le Traitement du Signal",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeurs MATLAB pour la Conception de Contrôleurs",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeurs MATLAB pour l'Apprentissage Automatique et l'Intelligence Artificielle",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeurs MATLAB pour la Simulation de Systèmes Électriques",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeurs MATLAB pour la Biologie et les Sciences de la Vie",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeurs MATLAB pour l'Automatisation Industrielle",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeurs MATLAB pour les Finances",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeurs MATLAB pour la Géophysique et la Géologie",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeurs MATLAB pour l'Acoustique et l'Audio",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeurs MATLAB pour la Simulations de Circuits Électroniques",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeurs MATLAB pour l'Éducation",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Développeurs Power BI",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Développeur de Rapports Power BI",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur de Tableaux de Bord Power BI",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur de Modèles de Données Power BI",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Power Query",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur DAX (Data Analysis Expressions) Power BI",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Power BI Embedded",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur de Flux de Données Power BI",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur de Connecteurs Personnalisés Power BI",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur de Solutions Power BI en Entreprise",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Spécialiste de la Sécurité Power BI",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Power BI Mobile",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Power BI en Libre-Service",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Développeurs Microsoft Dataverse",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Développeur de Modèles de Données Microsoft Dataverse",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur de Flux de Données Microsoft Dataverse",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur de Connecteurs Personnalisés Microsoft Dataverse",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur d'Applications Microsoft Dataverse",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur de Processus Microsoft Dataverse",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur de Règles et de Logique Métier Microsoft Dataverse",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Spécialiste de la Sécurité Microsoft Dataverse",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Power Platform en Libre-Service",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur de Tableaux de Bord et de Rapports Microsoft Dataverse",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Power Virtual Agents Microsoft Dataverse",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur de Solutions Microsoft Dataverse en Entreprise",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Développeurs OpenAI",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Apprentissage Machine et Apprentissage Profond",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Programmation (en particulier Python)",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Traitement du Langage Naturel (NLP)",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Modélisation de Données",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Prétraitement de Données",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Infrastructures Cloud (AWS, Azure, Google Cloud, etc.)",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Utilisation d'API OpenAI (comme GPT-3)",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Optimisation de Modèles",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Sécurité et Éthique de l'IA",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Analyse de Données",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Collaboration en Équipe",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Communication des Résultats",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Formation Continue",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Adaptabilité aux Nouvelles Technologies",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Gestion de Projet",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Développeurs Crystal Reports",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Développeur de Rapports Crystal Reports",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur de Connexions de Données Crystal Reports",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur de Formules Crystal Reports",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur de Mises en Page Crystal Reports",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur de Graphiques Crystal Reports",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur de Planning et de Distribution de Rapports Crystal Reports",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur de Personnalisation de Rapports Crystal Reports",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur de Rendu de Rapports Crystal Reports",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur de Connexion à des Sources de Données Externes",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur de Sécurité des Rapports Crystal Reports",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur de Génération de Rapports Automatisée",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur de Dépannage et de Maintenance des Rapports Crystal Reports",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Développeurs GPT-3",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Développeur d'Applications GPT-3",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur de Chatbots GPT-3",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur de Traitement du Langage Naturel (NLP) avec GPT-3",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur de Génération de Contenu GPT-3",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur d'Intégration GPT-3",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur de Personnalisation de GPT-3",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur de Formation de Modèles GPT-3",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur de Sécurité GPT-3",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur de Dépannage GPT-3",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur de Recherche en IA avec GPT-3",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Développeurs Python",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Développeur Web Python",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Python Full Stack",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur de Jeux Python",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur de Data Science Python",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur de Machine Learning Python",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur de Traitement du Langage Naturel (NLP) Python",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur DevOps Python",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Python en Cloud Computing",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Python IoT (Internet des Objets)",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Python Backend",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Python Frontend",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Python pour la Sécurité",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Python en Automatisation",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Python en Analyse de Données",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Python en Traitement d'Images",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur Python en Robotique",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Développeurs Hadoop",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Maîtrise de Hadoop",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Langages de Programmation (Java, Python, etc.)",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Écosystème Hadoop (Hive, Pig, Spark, HBase, Kafka, etc.)",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Cluster Hadoop",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Stockage Distribué (HDFS)",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Traitement Distribué",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Programmation MapReduce",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Optimisation des Performances",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Sécurité Hadoop",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Gestion des Données",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Bases de Données NoSQL (HBase, etc.)",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Planification et Ordonnancement",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Analyse de Données",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Conception d'Applications Hadoop",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Migration de Données",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Maintenance et Dépannage",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Conformité et Gouvernance",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Formation et Support",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Collaboration",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Communication",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Formation Continue",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Développeurs Tableau",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Maîtrise de Tableau Desktop",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Tableau Server et Tableau Online",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Langage de Calcul Tableau",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Connexions de Données",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Modélisation de Données",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Création de Visualisations",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Tableaux de Bord (Dashboards)",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Interactions et Actions",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Utilisation de Fonctions Avancées (LOD, Window Functions, etc.)",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Paramètres et Filtres",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Planification et Automatisation",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Analyse de Données",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Intégration avec d'autres Outils",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Optimisation des Performances",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Sécurité et Gestion des Accès",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Conformité aux Règles de l'Entreprise",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Formation des Utilisateurs",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Maintenance et Mises à Jour",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Créativité et Innovation",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Communication",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Formation Continue",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Développeurs Elasticsearch",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Maîtrise d'Elasticsearch",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Langage de Requête Elasticsearch",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Installation et Configuration d'Elasticsearch",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Indexation des Données",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Recherche Textuelle",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Analyse de Texte",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Recherche Géospatiale",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Agrégations et Groupements",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Gestion des Index",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Optimisation des Requêtes Elasticsearch",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Sécurité Elasticsearch",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Gestion des Erreurs et du Dépannage",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Intégration avec d'autres Outils (Logstash, Kibana, etc.)",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Visualisation des Données avec Kibana",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Scalabilité d'Elasticsearch",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Planification de la Migration",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Surveillance et Maintenance",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Conformité aux Règles de l'Entreprise",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Formation et Support",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Experts Excel",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Maîtrise d'Excel",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Automatisation de Tâches avec VBA",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Analyse de Données",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Création de Tableaux de Bord",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Gestion de Données",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Formules Avancées",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Consolidation de Données",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Importation/Exportation de Données",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Sécurité des Données",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Collaboration en Équipe",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Visual Basic for Applications (VBA)",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Reporting et Présentation",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Utilisation Avancée des Tableaux Croisés Dynamiques",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Créativité et Résolution de Problèmes",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Formation des Utilisateurs",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Conformité aux Règles de l'Entreprise",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Analyse de Scénarios",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Communication",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Formation Continue",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Développeurs de Chatbots",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Développeur Rasa",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeurs BotPress ",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeurs Dialogue flow",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeurs Dialogue flow",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    }
                ]
            },
            {
                "name": "Database & Big Data Technologies",
                "selectable": false,
                "children": [
                    {
                        "name": "Architectes Big Data",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Développeurs Oracle SQL",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Développeurs de bases de données",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Développeurs MongoDB",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Développeurs PostgreSQL",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Développeurs Apache Airflow",
                        "selectable": true,
                        "children": []
                    }
                ]
            },
            {
                "name": "CMS Platforms",
                "selectable": false,
                "children": [
                    {
                        "name": "Développeurs CMS (Système de Gestion de Contenu)",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Développeurs de thèmes WordPress",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Développeurs WordPress",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Designers WordPress",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Développeurs Joomla",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Développeurs Drupal",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Développeurs SharePoint",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Designers Webflow",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Développeurs Elementor",
                        "selectable": true,
                        "children": []
                    }
                ]
            },
            {
                "name": "E-commerce & CRM Platforms",
                "selectable": false,
                "children": [
                    {
                        "name": "Développeurs E-commerce",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Développeurs WooCommerce",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Développeurs Magento",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Développeurs Shopify",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Développeurs d'API Shopify",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Développeurs OpenCart",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Développeurs Salesforce Commerce Cloud",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Développeurs Salesforce",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Développeurs Stripe",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Développeurs Odoo",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Développeurs BigCommerce",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Développeurs Google AdWords",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Développeurs Hubspot",
                        "selectable": true,
                        "children": []
                    }
                ]
            },
            {
                "name": "Visual & Brand Design",
                "selectable": false,
                "children": [
                    {
                        "name": "Designers de marque",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Graphistes",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Designers visuels",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Designers de logo",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Designers de présentation",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Experts Adobe Illustrator",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Experts Photoshop",
                        "selectable": true,
                        "children": []
                    }
                ]
            },
            {
                "name": "Product & Project Management",
                "selectable": false,
                "children": [
                    {
                        "name": "Consultants en gestion de produit",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Chefs de produit contractuels",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Chefs de produit à distance",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Chefs de produit techniques",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Chefs de produit numériques",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Chefs de projet freelance",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Analystes d'affaires techniques",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Chefs de produit pour applications",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Chefs de projet web",
                        "selectable": true,
                        "children": []
                    }
                ]
            },
            {
                "name": "Finance & Business Consulting",
                "selectable": false,
                "children": [
                    {
                        "name": "Consultants en flux de trésorerie",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Consultants en modélisation financière",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Consultants en financement de startups",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Consultants en fusions et acquisitions (M&A)",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Consultants en capital-risque",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Consultants en plan d'affaires",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Consultants en levée de fonds",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Analystes financiers",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Analystes quantitatifs",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Consultants en affaires",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Analystes d'affaires",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Consultants en tarification",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Spécialistes en évaluation",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "DAF (Directeurs Administratifs et Financiers) fractionnés",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Consultants pour petites entreprises",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Consultants en pitch deck",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Experts en prévision financière",
                        "selectable": true,
                        "children": []
                    }
                ]
            },
            {
                "name": "Trending Skills & Roles",
                "selectable": false,
                "children": [
                    {
                        "name": "Développeurs ChatGPT",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Développeurs OpenAI",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Développeurs Web React",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Développeurs Azure",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "DSI (Directeurs des Systèmes d'Information)",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Experts Kubernetes",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Développeurs IoT (Internet des Objets)",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Développeurs WhisperAI",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Développeurs Hugging Face",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Développeurs PlayFab",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Développeurs Thymeleaf",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Développeurs OpenWRT",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Développeurs Agavi",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Ingénieurs IA (Intelligence Artificielle)",
                        "selectable": true,
                        "children": []
                    }
                ]
            },
            {
                "name": "Géomatique ",
                "selectable": false,
                "children": [
                    {
                        "name": "Géomaticien",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Géomaticien en analyse spatiale",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Géomaticien en cartographie",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Géomaticien en gestion de données géospatiales",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur ArcGIS",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur  QGIS",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": " Développeur  GRASS GIS",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Technicien Géomatique",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Technicien en acquisition de données géospatiales",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Technicien en traitement de données géospatiales",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Technicien en support SIG",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur  QGIS",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur AutoCAD Map 3D",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur ArcGIS",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Ingénieur Géomatique",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Ingénieur en développement SIG",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Ingénieur en télédétection",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Ingénieur en gestion de projets géomatiques",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur ENVI",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur ArcGIS",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur ERDAS Imagine",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Cartographe",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Cartographe thématique",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Cartographe topographique",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Cartographe numérique",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Adobe Illustrator (avec l'extension MAPublisher)",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "ArcMap",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "QGIS",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Topographe",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Topographe géodésien",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Topographe foncier",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Topographe en travaux publics",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "AutoCAD Civil 3D",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Leica Geo Office",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Trimble Business Center",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Spécialiste SIG (Systèmes d'Information Géographique)",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Spécialiste en analyse spatiale SIG",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Spécialiste en gestion de bases de données SIG",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Spécialiste en développement d'applications SIG",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "ArcGIS",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "QGIS",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "MapInfo",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Chargé d'études en géomatique",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Chargé d'études en analyse spatiale",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Chargé d'études en cartographie",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Chargé d'études en télédétection",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "ArcGIS",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "QGIS",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "ENVI",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Analyste en télédétection",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Analyste en imagerie satellitaire",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Analyste en photogrammétrie",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Analyste en radar à synthèse d'ouverture (SAR)",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "ERDAS Imagine",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "ENVI",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Orfeo Toolbox",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Développeur SIG",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Développeur en applications SIG",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur en bases de données géospatiales",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur en interfaces SIG",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "ArcGIS API for JavaScript",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "OpenLayers",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Leaflet",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Gestionnaire de bases de données géospatiales",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Gestionnaire de données vectorielles",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Gestionnaire de données raster",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Gestionnaire d'intégration de données géospatiales",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "PostgreSQL/PostGIS",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Microsoft SQL Server Spatial",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Oracle Spatial and Graph",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    }
                ]
            }
        ],
        "name_section": "DATA IT & TECH"
    },
    {
        categorie: [
            {
                "name": "Architecte ",
                "selectable": false,
                "children": [
                    {
                        "name": "Architecte chef de projet •",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Architecte conseiller",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Architecte HMONP ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Architecte plasticien ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Architecte urbaniste designer ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Collaborateur d'architecte •",
                        "selectable": true,
                        "children": []
                    }
                ]
            },
            {
                "name": "Aménagement paysager :",
                "selectable": false,
                "children": [
                    {
                        "name": "Architecte paysagiste",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Designer d'extérieur",
                        "selectable": true,
                        "children": []
                    }
                ]
            },
            {
                "name": "Assistant chef de projet ",
                "selectable": false,
                "children": [
                    {
                        "name": "Assistant chef de projet en agence d'architecture ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Assistant polyvalent en agence d'architecture ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Technicien de bureau d'études ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Technicien études techniques •",
                        "selectable": true,
                        "children": []
                    }
                ]
            },
            {
                "name": "BIM ",
                "selectable": false,
                "children": [
                    {
                        "name": "Gestion de Projets BIM :",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Coordinateur de projet BIM",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Planificateur de projet BIM",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Développement et Maintenance des Normes BIM :",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Spécialiste des normes et protocoles BIM",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur de templates et familles BIM",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Formation et Support BIM :",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Formateur BIM",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Support technique BIM",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Coordination et Vérification de la Modélisation :",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Coordinateur de la modélisation BIM",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Vérificateur de modèle BIM",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Gestion des Données et Analyse BIM :",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Analyste de données BIM",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Gestionnaire de base de données BIM",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Intégration des Systèmes et Interopérabilité :",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Spécialiste en intégration BIM",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Spécialiste en interopérabilité BIM",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Technologie et Innovation BIM :",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Spécialiste en technologie BIM",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Chercheur en innovation BIM",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Développement de Logiciels BIM :",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Développeur de logiciels BIM",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Spécialiste en automatisation BIM",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Simulation et Analyse :",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Spécialiste en simulation BIM",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Analyste de performance BIM",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Gestion de la Documentation BIM :",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Gestionnaire de documentation BIM",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Coordinateur de documentation BIM",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Gestion des Actifs et Maintenance BIM :",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Gestionnaire d'actifs BIM",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Spécialiste en maintenance BIM",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Qualité et Conformité BIM :",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Spécialiste en conformité BIM",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Vérificateur de modèle BIM",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Auditeur de qualité BIM ",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Chef de projet BIM ",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Chef de Projet BIM pour les infrastructures",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Chef de Projet BIM pour les bâtiments résidentiels",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Chef de Projet BIM pour les installations industrielles",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Chef de Projet BIM pour le MEP",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Spécialiste des normes et protocoles BIM",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur de templates et familles BIM",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Coordinateur BIM",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Coordinateur de projet BIM",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Communicateur de projet BIM",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Spécialiste en intégration BIM",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Spécialiste en interopérabilité BIM",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Contrôle de Qualité et Vérification :",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Vérificateur de modèle BIM",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Auditeur de qualité BIM",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Dessinateur BIM",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Perspectiviste 3D",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Projeteur BIM ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Technicien modeleur BIM",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Technicien modeleur DfMA",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Planification et Suivi du Projet :",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Planificateur de projet BIM",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Suivi et contrôle de projet BIM",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Gestion des Données et de la Documentation :",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Gestionnaire de données BIM",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Gestionnaire de documentation BIM",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Gestion des Ressources :",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Gestionnaire de ressources BIM",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Coordinateur d'équipe BIM",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Innovation et Recherche BIM :",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Chercheur en innovation BIM",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur de solutions BIM",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    }
                ]
            },
            {
                "name": "Projeteur ",
                "selectable": false,
                "children": [
                    {
                        "name": "Projeteur CFA",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Projeteur VRD ",
                        "selectable": true,
                        "children": []
                    }
                ]
            },
            {
                "name": "Coordonnateur SPS",
                "selectable": false,
                "children": [
                    {
                        "name": "Coordonnateur SPS ",
                        "selectable": true,
                        "children": []
                    }
                ]
            },
            {
                "name": "Dessinateur et technologies associées",
                "selectable": false,
                "children": [
                    {
                        "name": "Dessinateur bureau d'études bâtiment",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Dessinateur de métallerie ",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Dessinateur de charpente métallique",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Dessinateur de structures métalliques légères",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Dessinateur de structures métalliques lourdes",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Dessinateur de tuyauterie industrielle",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Dessinateur de structures métalliques architecturales",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Dessinateur de serrurerie métallique",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Dessinateur de menuiserie métallique",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Dessinateur de structures métalliques pour l'industrie pétrolière et gazière",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Dessinateur de structures métalliques pour les télécommunications",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Dessinateur de structures métalliques pour l'industrie de l'énergie",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Dessinateur en bâtiment ",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Dessinateur en bâtiment architectural",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Dessinateur en bâtiment structurel",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Dessinateur en bâtiment en génie électrique",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Dessinateur en bâtiment en génie mécanique",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Dessinateur en bâtiment en génie civil",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Dessinateur en bâtiment en aménagement paysager",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Dessinateur en bâtiment en rénovation",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Dessinateur en bâtiment en décoration intérieure",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Dessinateur en bâtiment en planification urbaine",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Dessinateur en bâtiment en construction durable",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Dessinateur métreur • ",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Dessinateur métreur en bâtiment",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Dessinateur métreur en génie civil",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Dessinateur métreur en travaux publics",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Dessinateur métreur en construction résidentielle",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Dessinateur métreur en construction commerciale",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Dessinateur métreur en construction industrielle",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Dessinateur métreur en rénovation",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Dessinateur métreur en génie électrique",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Dessinateur métreur en génie mécanique",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Dessinateur métreur en aménagement paysager",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Dessinateur projeteur en architecture ",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Dessinateur projeteur en conception architecturale",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Dessinateur projeteur en plans d'aménagement intérieur",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Dessinateur projeteur en plans techniques",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Dessinateur projeteur en modélisation 3D",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Dessinateur projeteur en restauration architecturale",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Dessinateur projeteur en conception paysagère",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Dessinateur projeteur en urbanisme",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Technicien dessinateur méthode ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Dessinateur projeteur en électricité",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Dessinateur projeteur en électricité industrielle",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Dessinateur projeteur en électricité du bâtiment",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Dessinateur projeteur en éclairage",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Dessinateur projeteur en automatisation et contrôle",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Dessinateur projeteur en énergie renouvelable",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Dessinateur projeteur en télécommunications",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Dessinateur projeteur en systèmes de sécurité",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Dessinateur projeteur en génie électrique",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Dessinateur projeteur en réseaux domotique",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Dessinateur projeteur en éclairage domotique",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Dessinateur projeteur en chauffage, ventilation et climatisation (CVC) domotique",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Dessinateur projeteur en sécurité domotique",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Dessinateur projeteur en audiovisuel domotique",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Dessinateur projeteur en gestion de l'énergie domotique",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Dessinateur projeteur en intégration domotique",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Dessinateur projeteur en serrurerie métallerie ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Dessinateur projeteur structure",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Dessinateur projeteur en génie civil",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Dessinateur projeteur en bâtiment",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Dessinateur projeteur en structures métalliques",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Dessinateur projeteur en structures en béton armé",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Dessinateur projeteur en structures en bois",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Dessinateur projeteur en structures préfabriquées",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Dessinateur projeteur en génie parasismique",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Dessinateur projeteur en ponts et ouvrages d'art",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Dessinateur projeteur structure",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Dessinateur VRD ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Scénographe",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Métreurs",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Spécialiste en SIG (Systèmes d'Information Géographique)",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Analyste SIG",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Cartographe",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Géomaticien",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Gestionnaire de données géographiques",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Développeur SIG",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Spécialiste en télédétection",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Spécialiste en SIG environnemental",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Spécialiste en SIG urbain",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Technicien en CAO (Conception Assistée par Ordinateur)",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Technicien en CAO mécanique",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Technicien en CAO architectural",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Technicien en CAO électronique",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Technicien en CAO électrique",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Technicien en CAO civil",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Technicien en CAO aérospatiale",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Technicien en CAO dans l'industrie automobile",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Technicien en CAO dans le secteur de l'énergie",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Spécialiste en DAO (Dessin Assisté par Ordinateur)",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Spécialiste en DAO mécanique",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Spécialiste en DAO architectural",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Spécialiste en DAO civil",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Spécialiste en DAO dans le design industriel",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    }
                ]
            },
            {
                "name": "Designer ",
                "selectable": false,
                "children": [
                    {
                        "name": " Concepteur designer scénographe •",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Conseiller thermique      ",
                        "selectable": true,
                        "children": []
                    }
                ]
            },
            {
                "name": "Économiste",
                "selectable": false,
                "children": [
                    {
                        "name": "Économiste de la construction",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Ingénieur étude de prix ",
                        "selectable": true,
                        "children": []
                    }
                ]
            },
            {
                "name": "Évaluation et inspection :",
                "selectable": false,
                "children": [
                    {
                        "name": "Évaluateur en bâtiment",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Inspecteur en bâtiment",
                        "selectable": true,
                        "children": []
                    }
                ]
            },
            {
                "name": "Ingénierie :",
                "selectable": false,
                "children": [
                    {
                        "name": "Ingénieur en structure",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Ingénieur en génie civil",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Ingénieur en génie électrique",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Ingénieur en génie mécanique",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Ingénieur en génie environnemental",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Ingénieur géotechnique",
                        "selectable": true,
                        "children": []
                    }
                ]
            },
            {
                "name": "Gestion de projet :",
                "selectable": false,
                "children": [
                    {
                        "name": "Gestionnaire de construction",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Gestionnaire de projet en construction",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Planificateur de projet",
                        "selectable": true,
                        "children": []
                    }
                ]
            },
            {
                "name": "Urbaniste",
                "selectable": false,
                "children": [
                    {
                        "name": "* urbaniste",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "* urbaniste prefab ",
                        "selectable": true,
                        "children": []
                    }
                ]
            },
            {
                "name": "Chargé de mission ",
                "selectable": false,
                "children": [
                    {
                        "name": "Chargé de mission SCoT ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Chargé de mission urbanisme • ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Chargé d'études environnement planification •",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": " Chargés d'études mobilités ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Chargé d'opérations réhabilitation •",
                        "selectable": true,
                        "children": []
                    }
                ]
            },
            {
                "name": "Spécialistes environnementaux :",
                "selectable": false,
                "children": [
                    {
                        "name": "Consultants en durabilité :",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Spécialiste en efficacité énergétique",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Consultant en énergie renouvelable",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Consultant en gestion des déchets et recyclage",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Spécialiste en qualité de l'air intérieur",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Évaluateurs environnementaux :",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Évaluateur d'impact environnemental",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Auditeur environnemental",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Gestion des ressources :",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Gestionnaire des ressources en eau",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Gestionnaire des ressources naturelles",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Spécialistes en conformité et réglementation environnementale :",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Spécialiste en conformité environnementale",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Spécialiste en droit de l'environnement",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Gestion des sites et réhabilitation :",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Spécialiste en remédiation des sites",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Gestionnaire de site contaminé",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Analyse et recherche environnementale :",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Analyste environnemental",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Chercheur en sciences environnementales",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Éducation et sensibilisation environnementale :",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Éducateur environnemental",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Coordinateur de programmes de sensibilisation environnementale",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Technologie environnementale :",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Technicien en systèmes environnementaux",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Spécialiste en modélisation environnementale",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Conservation et préservation :",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Spécialiste en conservation des espaces naturels",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Biologiste de la conservation",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Gestion des risques environnementaux :",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Spécialiste en gestion des risques environnementaux",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Analyste des risques environnementaux",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Certification et labellisation environnementale :",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Spécialiste en certification LEED (Leadership in Energy and Environmental Design)",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Spécialiste en certification BREEAM (Building Research Establishment Environmental Assessment Method)",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    }
                ]
            },
            {
                "name": "Beton ",
                "selectable": false,
                "children": [
                    {
                        "name": "Applicateur de béton décoratif ",
                        "selectable": true,
                        "children": []
                    }
                ]
            },
            {
                "name": "Bois",
                "selectable": false,
                "children": [
                    {
                        "name": " Boiseur",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": " Charpentier bois",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": " Charpentier métallique ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Menuisier ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Poseur menuiserie ",
                        "selectable": true,
                        "children": []
                    }
                ]
            },
            {
                "name": "Coffreur",
                "selectable": false,
                "children": [
                    {
                        "name": "Coffreur bancheur",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Coffreur boiseur ",
                        "selectable": true,
                        "children": []
                    }
                ]
            },
            {
                "name": "Carrière",
                "selectable": false,
                "children": [
                    {
                        "name": "Chef de carrière",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Mécanicien d'engins de carrière •",
                        "selectable": true,
                        "children": []
                    }
                ]
            },
            {
                "name": "Facade",
                "selectable": false,
                "children": [
                    {
                        "name": "Bardeur ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Façadier ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Opérateur désamiantage Facade",
                        "selectable": true,
                        "children": []
                    }
                ]
            },
            {
                "name": "Electricité",
                "selectable": false,
                "children": [
                    {
                        "name": "Électricien de chantier",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Électricien du bâtiment ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Installateur Domotique ",
                        "selectable": true,
                        "children": []
                    }
                ]
            },
            {
                "name": "Maconnerie",
                "selectable": false,
                "children": [
                    {
                        "name": "Maçon ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Maçon finisseur ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Ouvrier polyvalent du bâtiment ",
                        "selectable": true,
                        "children": []
                    }
                ]
            },
            {
                "name": "Métal",
                "selectable": false,
                "children": [
                    {
                        "name": "Menuisier Alu ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Ferrailleur ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Monteur en structures métalliques ",
                        "selectable": true,
                        "children": []
                    }
                ]
            },
            {
                "name": "Murs",
                "selectable": false,
                "children": [
                    {
                        "name": " Plaquiste",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Plâtrier ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Tailleur de pierre",
                        "selectable": true,
                        "children": []
                    }
                ]
            },
            {
                "name": "Peinture",
                "selectable": false,
                "children": [
                    {
                        "name": "Peintre ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Peintre en décoration ",
                        "selectable": true,
                        "children": []
                    }
                ]
            },
            {
                "name": "Serrure",
                "selectable": false,
                "children": [
                    {
                        "name": "Serrurier métallier ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Serrurier Installation Domotique",
                        "selectable": true,
                        "children": []
                    }
                ]
            },
            {
                "name": "Sol ",
                "selectable": false,
                "children": [
                    {
                        "name": "Chapiste",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Carreleur mosaïste ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Géologue",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Scieur carotteur de béton ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": " Solier moquettiste",
                        "selectable": true,
                        "children": []
                    }
                ]
            },
            {
                "name": "Thermie",
                "selectable": false,
                "children": [
                    {
                        "name": "Monteur en installations sanitaires et thermiques ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Monteur en installations  thermiques en commande controle domotique",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Plombier",
                        "selectable": true,
                        "children": []
                    }
                ]
            },
            {
                "name": "Toit",
                "selectable": false,
                "children": [
                    {
                        "name": "Cordiste ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Couvreur ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Deviseur ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Échafaudeur",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Étancheur -",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Nacelliste Élingueur",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Opérateur désamiantage Toit ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Poseur applicateur toiture ",
                        "selectable": true,
                        "children": []
                    }
                ]
            },
            {
                "name": "Transport",
                "selectable": false,
                "children": [
                    {
                        "name": "Chauffeur toupie ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Conducteur d'engins en carrière •",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Levageur",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Conducteur de travaux en construction métallique",
                        "selectable": true,
                        "children": []
                    }
                ]
            },
            {
                "name": "Véranda",
                "selectable": false,
                "children": [
                    {
                        "name": "Métreur vérandas ",
                        "selectable": true,
                        "children": []
                    }
                ]
            },
            {
                "name": "Verre",
                "selectable": false,
                "children": [
                    {
                        "name": "Miroitier ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Installateur Verre polarisant ( domotique )",
                        "selectable": true,
                        "children": []
                    }
                ]
            },
            {
                "name": "Divers",
                "selectable": false,
                "children": [
                    {
                        "name": "Staffeur ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Secrétaire assistante travaux ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Coordinateur SPS ",
                        "selectable": true,
                        "children": []
                    }
                ]
            },
            {
                "name": "Audit énergetique ",
                "selectable": false,
                "children": [
                    {
                        "name": "Auditeur énergétique ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Chargé d'études énergétiques ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Chef de projet transition énergétique ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Chiffreur",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Projeteur BIM ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Technicien évaluateur en performance énergétique ",
                        "selectable": true,
                        "children": []
                    }
                ]
            },
            {
                "name": "CVC ( chauffage, ventilation, climatisation )",
                "selectable": false,
                "children": [
                    {
                        "name": "Chargé d'affaires CVC ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Chauffagiste itinérant ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Chef de chantier en génie thermique et climatique ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Chef de projet CVC ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Dépanneur frigoriste ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Diagnostiqueur ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Économe de flux ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Frigoriste ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Fumiste ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Ingénieur CVC ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Monteur-calorifugeur ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Plombier chauffagiste •",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": " Poseur monteur salles blanches •",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Projeteur CVC",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Technicien de maintenance climatisation froid ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Technicien de maintenance CVC",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Technicien de maintenance chaudières gaz fioul ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Technicien de maintenance frigoriste",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Technicien en ramonage et d'entretien des appareils de chauffage domestiques ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Technicien froid et climatisation ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Technicien VMC",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Technico-commercial CVC ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Thermicien ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Tôlier en ventilation",
                        "selectable": true,
                        "children": []
                    }
                ]
            },
            {
                "name": "Eco-construction ",
                "selectable": false,
                "children": [
                    {
                        "name": "Chargé de projet éco-construction ",
                        "selectable": true,
                        "children": []
                    }
                ]
            },
            {
                "name": "Efficacité énergétique ",
                "selectable": false,
                "children": [
                    {
                        "name": "Chargé de mission énergie ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Conseiller énergie ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Ingénieur efficacité énergétique",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Pilote énergie",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Project manager énergie ",
                        "selectable": true,
                        "children": []
                    }
                ]
            },
            {
                "name": "Genie climatique",
                "selectable": false,
                "children": [
                    {
                        "name": "Agent de maintenance équipements thermiques ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Calorifugeur en isolation ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": " • Climaticien ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Consultant en transition énergétique ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Façadier isolation thermique par l'extérieur ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Ingénieur d'études en génie climatique ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Ingénieur d'études énergie-thermie fluides ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Ingénieur d'études thermiques ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Installateur génie climatique",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Monteur en installations de génie climatique",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Technicien en génie climatique ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Technicien supérieur en génie climatique ",
                        "selectable": true,
                        "children": []
                    }
                ]
            },
            {
                "name": "Production énergétique ",
                "selectable": false,
                "children": [
                    {
                        "name": "Technicien instatlation Batteries",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Technicien instatlation Capteurs IoT",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Technicien instatlation controleur EMS ( Energy Management System",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Technicien instatlation Onduleurs ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Technicien instatlation panneaux solaires",
                        "selectable": true,
                        "children": []
                    }
                ]
            },
            {
                "name": "Renovation thermique",
                "selectable": false,
                "children": [
                    {
                        "name": "Chargé d'affaires en rénovation ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Conducteur de travaux en rénovation énergétique ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Conseiller en rénovation énergétique",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Façadier itéiste ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Ingénieur en génie thermique",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Ingénieur fluides",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "ingénieur thermicien",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Monteur en installations sanitaires et thermiques ",
                        "selectable": true,
                        "children": []
                    }
                ]
            },
            {
                "name": "Sécurité ",
                "selectable": false,
                "children": [
                    {
                        "name": "Technicien hygiène sécurité environnement ",
                        "selectable": true,
                        "children": []
                    }
                ]
            },
            {
                "name": "Aménagement du Territoire ",
                "selectable": false,
                "children": [
                    {
                        "name": "Ingénieur Urbaniste",
                        "selectable": true,
                        "children": []
                    }
                ]
            },
            {
                "name": "Foncier",
                "selectable": false,
                "children": [
                    {
                        "name": "Aide-sondeur ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Technicien de terrain ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Ingénieur géomètre foncier ",
                        "selectable": true,
                        "children": []
                    }
                ]
            },
            {
                "name": "Geotechnique ( Métreur )",
                "selectable": false,
                "children": [
                    {
                        "name": "Métreur",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Métreur économiste de la construction ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Opérateur-géomètre",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Sondeur géotechnique",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Téléopérateur de drone",
                        "selectable": true,
                        "children": []
                    }
                ]
            },
            {
                "name": "Géométrique Cartographique ",
                "selectable": false,
                "children": [
                    {
                        "name": "Chargé d'étude cartographie",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Assistant technicien géomètre ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Dessinateur cartographe ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Dessinateur géomètre REVIT",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": " Géomaticien •",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Géomètre topographe ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Géomètre-expert",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Géotechnicien",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": " Géotechnicien expert ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": " Ingénieur d'études géotechniques ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Technicien cartographie réseau ",
                        "selectable": true,
                        "children": []
                    }
                ]
            },
            {
                "name": "Information géographique ",
                "selectable": false,
                "children": [
                    {
                        "name": "Assistant d'études géomatique",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Chef de projet SIG ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": " Ingénieur SIG ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Technicien cartographe SIG ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Technicien de l'information géographique",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Technicien SIG ",
                        "selectable": true,
                        "children": []
                    }
                ]
            },
            {
                "name": "Topographie",
                "selectable": false,
                "children": [
                    {
                        "name": "Dessinateur projeteur topographe",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Photogrammètre",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Technicien géodétection et géoréférencement",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Technicien projeteur synthèse réseaux ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Technicien topographe cartographe ",
                        "selectable": true,
                        "children": []
                    }
                ]
            },
            {
                "name": "Génie civil",
                "selectable": false,
                "children": [
                    {
                        "name": "Acheteur lots techniques ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Acheteur travaux ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Animateur QSE chantier ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Assistant responsable d'affaires ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Chargé d'affairescontinue ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Responsable de programmes",
                        "selectable": true,
                        "children": []
                    }
                ]
            },
            {
                "name": "Techniciens et ingénieurs Génie ",
                "selectable": false,
                "children": [
                    {
                        "name": "Building Information Modeling manager (BIM manager) ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Responsable études ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Technicien d'études et de chantier",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Technicien étude de prix • ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Technicien pilote d'opérations infrastructures voirie ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Technicien pilote d'opérations infrastructures voirie ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Technicien projeteur synthèse technique",
                        "selectable": true,
                        "children": []
                    }
                ]
            },
            {
                "name": "Travaux Publics (air )",
                "selectable": false,
                "children": [
                    {
                        "name": "Aide conducteur de travaux",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Canalisateur",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Chef de chantier ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Coffreur boiseur ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Conducteur de travaux ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Conducteur d'engins ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Conducteur d'engins de levage",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Fontainier ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Grutier ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Ingénieur TP",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Maçon VRD ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Mécanicien d'engins de chantier ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Monteur de lignes caténaires GSM ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Constructeur en béton armé ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Contremaître ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Régleur finisseur ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Responsable étude de prix •",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": " Technicien cordiste projets spéciaux",
                        "selectable": true,
                        "children": []
                    }
                ]
            },
            {
                "name": "Terrassement (travaux sur sols )",
                "selectable": false,
                "children": [
                    {
                        "name": "Asphalteur ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Chauffeur de skid  (  châssis, (matériel, équipement, tuyauterie, etc… )",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Chef d'équipe enrobés •",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Chef d'équipe terrassement ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Conducteur de bitumineuse goudronneuse ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Conducteur de chargeur ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Conducteur de compacteur ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Conducteur de drague ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Conducteur de finisseur ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Conducteur de pelle hydraulique",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Dalleur",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Opérateur tunnelier ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Ouvrier régleur d'enrobés ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Paveur",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Pontier",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Scaphandrier travaux publics ",
                        "selectable": true,
                        "children": []
                    }
                ]
            },
            {
                "name": "VRD ( Voiries Réseaux Divers )",
                "selectable": false,
                "children": [
                    {
                        "name": "Chef d'équipe monteur réseaux aéro-souterrains",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Constructeur de routes et VRD",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Monteur de réseaux électriques",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Poseur de canalisations ",
                        "selectable": true,
                        "children": []
                    }
                ]
            }
        ],
        "name_section": "GENIE CIVIL & ARCHI"
    },
    {
        "categorie": [
            {
                "name": "Directeur artistique",
                "selectable": false,
                "children": [
                    {
                        "name": "Directeur Artistique en Publicité",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Directeur Artistique en Cinéma et Télévision",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Directeur Artistique en Mode",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Directeur Artistique en Conception Graphique",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Directeur Artistique en Jeux Vidéo",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Directeur Artistique en Design d'Intérieur",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Directeur Artistique en Photographie",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Directeur Artistique en Animation",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Directeur Artistique en Musique et Spectacles",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Directeur Artistique en Événementiel",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Directeur Artistique en Illustration",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Directeur Artistique en Architecture",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Directeur Artistique en Arts Visuels",
                        "selectable": true,
                        "children": []
                    }
                ]
            },
            {
                "name": "graphistes",
                "selectable": false,
                "children": [
                    {
                        "name": "Graphiste en Conception Graphique",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Graphiste Web",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Illustrateur",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Designer d'Expérience Utilisateur (UX)",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Designer d'Interface Utilisateur (UI)",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Designer d'Infographie",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Designer de Jeux Vidéo",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Designer de Packaging",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Designer de Motion Graphics",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Designer d'Identité Visuelle",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Designer de Typographie",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Designer de Produits",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Designer de Communication Visuelle",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Designer en Design d'Intérieur",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Designer en Publicité et Marketing",
                        "selectable": true,
                        "children": []
                    }
                ]
            },
            {
                "name": "Illustrateurs ",
                "selectable": false,
                "children": [
                    {
                        "name": "Illustration Éditoriale",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Illustration de Bandes Dessinées",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Illustration pour Enfants",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Illustration de Mode",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Illustration Scientifique",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Illustration de Fantasy et de Science-Fiction",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Illustration de Personnages",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Illustration de Storyboard",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Illustration de Produits de Consommation",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Illustration de Cartes et de Cartographie",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Illustration de Nature et d'Animaux",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Illustration Médicale",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Illustration de Presse",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Illustration Numérique",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Illustration de Jeux de Plateau et de Jeux de Cartes",
                        "selectable": true,
                        "children": []
                    }
                ]
            },
            {
                "name": "Motion designer",
                "selectable": false,
                "children": [
                    {
                        "name": "Motion Design en Publicité",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Motion Design pour le Cinéma",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Motion Design pour la Télévision",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Motion Design en Conception de Site Web",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Motion Design en Animation de Personnages",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Motion Design pour les Médias Sociaux",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Motion Design pour l'Éducation",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Motion Design en Conception de Marque",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Motion Design en Promotion de Produits",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Motion Design pour les Arts Visuels",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Motion Design en Exposition et Événementiel",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Motion Design en Santé et Médecine",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Motion Design en Conception de Produits",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Motion Design en Divertissement",
                        "selectable": true,
                        "children": []
                    }
                ]
            },
            {
                "name": "Vidéastes",
                "selectable": false,
                "children": [
                    {
                        "name": "Vidéaste de Mariage",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Vidéaste de Cinéma",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Vidéaste de Télévision",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Vidéaste de Vidéos Musicales",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Vidéaste de Médias Sociaux",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Vidéaste de Voyage",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Vidéaste d'Entreprise",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Vidéaste Documentariste",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Vidéaste de Sport",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Vidéaste de Journalisme",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Vidéaste de Mode",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Vidéaste de Drone",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Vidéaste de Science",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Vidéaste Culinaire",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Vidéaste de Vlog",
                        "selectable": true,
                        "children": []
                    }
                ]
            },
            {
                "name": "Web designers ",
                "selectable": false,
                "children": [
                    {
                        "name": "Design Web Front-End",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Design Web Responsive",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Design d'Interfaces Utilisateur (UI)",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Design de Sites Web de Commerce Électronique",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Design Web pour Applications Mobiles",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Design Web pour les Médias Sociaux",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Design Web pour le Marketing en Ligne",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Design de Blogs",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Design Web Artistique",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Design Web de Portfolios",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Design Web pour Sites Gouvernementaux",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Design Web pour Sites Éducatifs",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Design Web pour Sites de Voyage et de Tourisme",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Design Web pour Sites de Jeux Vidéo",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Design Web pour Sites de Santé et de Bien-être",
                        "selectable": true,
                        "children": []
                    }
                ]
            }
        ],
        "name_section": "DESIGN, SON et IMAGE"
    },
    {
        categorie: [
            {
                "name": "Business développers ",
                "selectable": false,
                "children": [
                    {
                        "name": "Développeur Commercial B2B",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Développeur Commercial B2C",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Développeur Commercial International",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Développeur Commercial Technologique",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Développeur Commercial dans le Secteur Financier",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Développeur Commercial dans l'Immobilier",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Développeur Commercial dans l'Industrie",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Développeur Commercial en Ligne (e-commerce)",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Développeur Commercial dans les Services Professionnels",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Développeur Commercial en Start-up",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Développeur Commercial dans l'Industrie de la Santé",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Développeur Commercial en Énergie et Environnement",
                        "selectable": true,
                        "children": []
                    }
                ]
            },
            {
                "name": "Business analyst",
                "selectable": false,
                "children": [
                    {
                        "name": "Business Analyst en Analyse des Données",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Business Analyst en Analyse Financière",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Business Analyst en Analyse des Processus",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Business Analyst en Analyse des Exigences",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Business Analyst en Systèmes d'Information",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Business Analyst en Analyse de Marché",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Business Analyst en Gestion de Projet",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Business Analyst en Analyse de la Chaîne d'Approvisionnement",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Business Analyst en Analyse des Risques",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Business Analyst en Analyse Marketing",
                        "selectable": true,
                        "children": []
                    }
                ]
            },
            {
                "name": "Consultant management",
                "selectable": false,
                "children": [
                    {
                        "name": "Consultant en Stratégie d'Entreprise",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Consultant en Gestion de Projet",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Consultant en Gestion du Changement",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Consultant en Gestion des Ressources Humaines",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Consultant en Optimisation des Processus",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Consultant en Gestion de la Chaîne d'Approvisionnement",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Consultant en Gestion de la Qualité",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Consultant en Gestion de la Technologie de l'Information (TI)",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Consultant en Gestion de Crise",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Consultant en Gestion de la Performance",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Consultant en Stratégie de Marketing",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Consultant en Développement Durable",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Consultant en Finances d'Entreprise",
                        "selectable": true,
                        "children": []
                    }
                ]
            },
            {
                "name": "Expert finance et controle de gestion",
                "selectable": false,
                "children": [
                    {
                        "name": "Expert en Analyse Financière",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Expert en Contrôle de Gestion",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Expert en Planification Financière",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Expert en Trésorerie et Gestion de la Liquidité",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Expert en Fiscalité",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Expert en Gestion des Risques Financiers",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Expert en Comptabilité",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Expert en Investissements",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Expert en Finance d'Entreprise",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Expert en Analyse des Coûts et Rentabilité",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Expert en Finance Internationale",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Expert en Gestion de Patrimoine",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Expert en Fintech",
                        "selectable": true,
                        "children": []
                    }
                ]
            },
            {
                "name": "Consultant en stratégie",
                "selectable": false,
                "children": [
                    {
                        "name": "Consultant en Stratégie d'Entreprise",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Consultant en Stratégie de Croissance",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Consultant en Stratégie Numérique",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Consultant en Stratégie Marketing",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Consultant en Stratégie de Gestion de la Chaîne d'Approvisionnement",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Consultant en Stratégie de Gestion de la Performance",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Consultant en Stratégie de Durabilité",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Consultant en Stratégie de Ressources Humaines",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Consultant en Stratégie de Fusion et Acquisition (M&A)",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Consultant en Stratégie de Redressement",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Consultant en Stratégie d'Innovation",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Consultant en Stratégie Internationale",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Consultant en Stratégie de Crise",
                        "selectable": true,
                        "children": []
                    }
                ]
            }
        ],
        "name_section": "STRATEGIE & BUSINESS"
    },
    {
        categorie: [
            {
                "name": "1. Stratégie de Marketing",
                "selectable": false,
                "children": [
                    {
                        "name": "Analyste en marketing",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Spécialiste en analyse de marché",
                                "selectable": false,
                                "children": [
                                    {
                                        "name": "Expert en analyse de marché B2B",
                                        "selectable": false,
                                        "children": [
                                            {
                                                "name": "Expert en analyse de marché pour les technologies de l'information",
                                                "selectable": true,
                                                "children": []
                                            },
                                            {
                                                "name": "Expert en analyse de marché pour le secteur de la santé",
                                                "selectable": true,
                                                "children": []
                                            }
                                        ]
                                    },
                                    {
                                        "name": "Expert en analyse de marché B2C",
                                        "selectable": false,
                                        "children": [
                                            {
                                                "name": "Expert en analyse de marché pour les produits de consommation",
                                                "selectable": true,
                                                "children": []
                                            },
                                            {
                                                "name": "Expert en analyse de marché pour l'industrie automobile",
                                                "selectable": true,
                                                "children": []
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "name": "Expert en segmentation de marché",
                                "selectable": false,
                                "children": [
                                    {
                                        "name": "Spécialiste en segmentation démographique",
                                        "selectable": false,
                                        "children": [
                                            {
                                                "name": "Expert en segmentation démographique des jeunes consommateurs",
                                                "selectable": true,
                                                "children": []
                                            },
                                            {
                                                "name": "Expert en segmentation démographique des seniors",
                                                "selectable": true,
                                                "children": []
                                            }
                                        ]
                                    },
                                    {
                                        "name": "Spécialiste en segmentation comportementale",
                                        "selectable": false,
                                        "children": [
                                            {
                                                "name": "Expert en segmentation comportementale des acheteurs en ligne",
                                                "selectable": true,
                                                "children": []
                                            },
                                            {
                                                "name": "Expert en segmentation comportementale des acheteurs en magasin",
                                                "selectable": true,
                                                "children": []
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "name": "Stratège marketing",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Spécialiste en planification stratégique",
                                "selectable": false,
                                "children": [
                                    {
                                        "name": "Expert en planification stratégique pour les startups",
                                        "selectable": true,
                                        "children": []
                                    },
                                    {
                                        "name": "Expert en planification stratégique pour les entreprises établies",
                                        "selectable": true,
                                        "children": []
                                    }
                                ]
                            },
                            {
                                "name": "Expert en recherche de public cible",
                                "selectable": false,
                                "children": [
                                    {
                                        "name": "Spécialiste en recherche de public cible pour les produits de luxe",
                                        "selectable": true,
                                        "children": []
                                    },
                                    {
                                        "name": "Spécialiste en recherche de public cible pour les produits écologiques",
                                        "selectable": true,
                                        "children": []
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "name": "Consultant en marketing",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Spécialiste en conseil en marketing numérique",
                                "selectable": false,
                                "children": [
                                    {
                                        "name": "Expert en conseil en marketing pour les petites entreprises",
                                        "selectable": true,
                                        "children": []
                                    },
                                    {
                                        "name": "Expert en conseil en marketing pour les entreprises internationales",
                                        "selectable": true,
                                        "children": []
                                    }
                                ]
                            },
                            {
                                "name": "Expert en stratégie de marque",
                                "selectable": false,
                                "children": [
                                    {
                                        "name": "Spécialiste en stratégie de marque personnelle",
                                        "selectable": true,
                                        "children": []
                                    },
                                    {
                                        "name": "Expert en développement de la marque personnelle pour les entrepreneurs",
                                        "selectable": true,
                                        "children": []
                                    },
                                    {
                                        "name": "Expert en développement de la marque personnelle pour les influenceurs",
                                        "selectable": true,
                                        "children": []
                                    }
                                ]
                            },
                            {
                                "name": "Spécialiste en stratégie de marque d'entreprise",
                                "selectable": false,
                                "children": [
                                    {
                                        "name": "Expert en stratégie de marque pour les entreprises technologiques",
                                        "selectable": true,
                                        "children": []
                                    },
                                    {
                                        "name": "Expert en stratégie de marque pour les entreprises alimentaires",
                                        "selectable": true,
                                        "children": []
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "name": "Responsable de la planification marketing",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Spécialiste en gestion de budgets marketing",
                                "selectable": false,
                                "children": [
                                    {
                                        "name": "Expert en budgétisation publicitaire en ligne",
                                        "selectable": false,
                                        "children": [
                                            {
                                                "name": "Expert en budgétisation publicitaire sur les réseaux sociaux",
                                                "selectable": true,
                                                "children": []
                                            },
                                            {
                                                "name": "Expert en budgétisation publicitaire sur les moteurs de recherche",
                                                "selectable": true,
                                                "children": []
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "name": "Expert en planification de campagnes marketing",
                                "selectable": false,
                                "children": [
                                    {
                                        "name": "Spécialiste en planification de campagnes de lancement de produits",
                                        "selectable": false,
                                        "children": [
                                            {
                                                "name": "Expert en planification de campagnes de lancement de produits technologiques",
                                                "selectable": true,
                                                "children": []
                                            },
                                            {
                                                "name": "Expert en planification de campagnes de lancement de produits de beauté",
                                                "selectable": true,
                                                "children": []
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                "name": "2. Gestion de Projet",
                "selectable": false,
                "children": [
                    {
                        "name": "Chef de projet marketing",
                        "selectable": false,
                        "children": []
                    },
                    {
                        "name": "Spécialiste en gestion de projet numérique",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Expert en gestion de projets de refonte de site web",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Expert en gestion de projets de développement d'applications mobiles",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Expert en gestion d'équipe marketing",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Spécialiste en gestion d'équipes marketing à distance",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Spécialiste en gestion d'équipes marketing multiculturelles",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Gestionnaire de projet en communication",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Spécialiste en gestion de projets de communication externe",
                                "selectable": false,
                                "children": [
                                    {
                                        "name": "Expert en gestion de projets de relations publiques d'entreprise",
                                        "selectable": false,
                                        "children": [
                                            {
                                                "name": "Expert en gestion de projets de relations publiques pour les entreprises technologiques",
                                                "selectable": true,
                                                "children": []
                                            },
                                            {
                                                "name": "Expert en gestion de projets de relations publiques pour les entreprises de santé",
                                                "selectable": true,
                                                "children": []
                                            }
                                        ]
                                    },
                                    {
                                        "name": "Expert en gestion de projets de médias sociaux",
                                        "selectable": false,
                                        "children": [
                                            {
                                                "name": "Expert en gestion de projets de médias sociaux pour les organisations à but non lucratif",
                                                "selectable": true,
                                                "children": []
                                            },
                                            {
                                                "name": "Expert en gestion de projets de médias sociaux pour les institutions financières",
                                                "selectable": true,
                                                "children": []
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "name": "Expert en gestion de projets événementiels",
                                "selectable": false,
                                "children": [
                                    {
                                        "name": "Spécialiste en gestion de projets d'événements d'entreprise",
                                        "selectable": false,
                                        "children": [
                                            {
                                                "name": "Expert en gestion de projets d'événements d'entreprise pour les lancements de produits",
                                                "selectable": true,
                                                "children": []
                                            },
                                            {
                                                "name": "Expert en gestion de projets d'événements d'entreprise pour les conférences",
                                                "selectable": true,
                                                "children": []
                                            }
                                        ]
                                    },
                                    {
                                        "name": "Spécialiste en gestion de projets d'événements culturels",
                                        "selectable": false,
                                        "children": [
                                            {
                                                "name": "Expert en gestion de projets d'événements culturels artistiques",
                                                "selectable": true,
                                                "children": []
                                            },
                                            {
                                                "name": "Expert en gestion de projets d'événements culturels sportifs",
                                                "selectable": true,
                                                "children": []
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "name": "Coordinateur de projet marketing",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Spécialiste en coordination de campagnes publicitaires",
                                "selectable": false,
                                "children": [
                                    {
                                        "name": "Expert en coordination de campagnes publicitaires sur les réseaux sociaux",
                                        "selectable": false,
                                        "children": [
                                            {
                                                "name": "Expert en coordination de campagnes publicitaires Facebook",
                                                "selectable": true,
                                                "children": []
                                            },
                                            {
                                                "name": "Expert en coordination de campagnes publicitaires Instagram",
                                                "selectable": true,
                                                "children": []
                                            }
                                        ]
                                    },
                                    {
                                        "name": "Expert en coordination de campagnes publicitaires par e-mail",
                                        "selectable": false,
                                        "children": [
                                            {
                                                "name": "Expert en coordination de campagnes publicitaires par e-mail automatisées",
                                                "selectable": true,
                                                "children": []
                                            },
                                            {
                                                "name": "Expert en coordination de campagnes publicitaires par e-mail personnalisées",
                                                "selectable": true,
                                                "children": []
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "name": "Expert en gestion de projets événementiels",
                                "selectable": false,
                                "children": [
                                    {
                                        "name": "Spécialiste en coordination de conférences internationales",
                                        "selectable": false,
                                        "children": [
                                            {
                                                "name": "Expert en coordination de conférences médicales internationales",
                                                "selectable": true,
                                                "children": []
                                            },
                                            {
                                                "name": "Expert en coordination de conférences technologiques internationales",
                                                "selectable": true,
                                                "children": []
                                            }
                                        ]
                                    },
                                    {
                                        "name": "Spécialiste en coordination d'événements sportifs",
                                        "selectable": false,
                                        "children": [
                                            {
                                                "name": "Expert en coordination d'événements sportifs nationaux",
                                                "selectable": true,
                                                "children": []
                                            },
                                            {
                                                "name": "Expert en coordination d'événements sportifs internationaux",
                                                "selectable": true,
                                                "children": []
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "name": "Directeur de projet marketing",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Spécialiste en gestion de projets marketing complexes",
                                "selectable": false,
                                "children": [
                                    {
                                        "name": "Expert en gestion de projets marketing multicanal",
                                        "selectable": false,
                                        "children": [
                                            {
                                                "name": "Expert en gestion de projets marketing multicanal pour les entreprises de vente au détail",
                                                "selectable": true,
                                                "children": []
                                            },
                                            {
                                                "name": "Expert en gestion de projets marketing multicanal pour les entreprises de voyages et de tourisme",
                                                "selectable": true,
                                                "children": []
                                            }
                                        ]
                                    },
                                    {
                                        "name": "Expert en gestion de projets marketing internationaux",
                                        "selectable": false,
                                        "children": [
                                            {
                                                "name": "Expert en gestion de projets marketing internationaux pour les marchés émergents",
                                                "selectable": true,
                                                "children": []
                                            },
                                            {
                                                "name": "Expert en gestion de projets marketing internationaux pour les marchés matures",
                                                "selectable": true,
                                                "children": []
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "name": "Expert en gestion de projets de grande envergure",
                                "selectable": false,
                                "children": [
                                    {
                                        "name": "Spécialiste en gestion de projets marketing à budget élevé",
                                        "selectable": false,
                                        "children": [
                                            {
                                                "name": "Spécialiste en gestion de projets marketing à budget élevé pour les ONG",
                                                "selectable": true,
                                                "children": []
                                            },
                                            {
                                                "name": "Spécialiste en gestion de projets marketing à budget élevé pour les sociétés de technologie",
                                                "selectable": true,
                                                "children": []
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                "name": "3. Marketing Digital",
                "selectable": false,
                "children": [
                    {
                        "name": "Spécialiste en marketing des médias sociaux",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Expert en gestion de campagnes sur les réseaux sociaux",
                                "selectable": false,
                                "children": [
                                    {
                                        "name": "Spécialiste en publicités sur Facebook et Instagram",
                                        "selectable": false,
                                        "children": [
                                            {
                                                "name": "Expert en publicités Facebook pour les entreprises de commerce électronique",
                                                "selectable": true,
                                                "children": []
                                            },
                                            {
                                                "name": "Expert en publicités Instagram pour les marques de mode",
                                                "selectable": true,
                                                "children": []
                                            }
                                        ]
                                    },
                                    {
                                        "name": "Spécialiste en publicités sur LinkedIn et Twitter",
                                        "selectable": false,
                                        "children": [
                                            {
                                                "name": "Expert en publicités LinkedIn pour les services professionnels",
                                                "selectable": true,
                                                "children": []
                                            },
                                            {
                                                "name": "Expert en publicités Twitter pour les événements sportifs",
                                                "selectable": true,
                                                "children": []
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "name": "Spécialiste en gestion de communautés en ligne",
                                "selectable": false,
                                "children": [
                                    {
                                        "name": "Expert en gestion de communautés de niche",
                                        "selectable": false,
                                        "children": [
                                            {
                                                "name": "Spécialiste en gestion de communautés pour les fans de jeux vidéo",
                                                "selectable": true,
                                                "children": []
                                            },
                                            {
                                                "name": "Spécialiste en gestion de communautés pour les amateurs de photographie",
                                                "selectable": true,
                                                "children": []
                                            }
                                        ]
                                    },
                                    {
                                        "name": "Expert en gestion de communautés de grandes marques",
                                        "selectable": false,
                                        "children": [
                                            {
                                                "name": "Spécialiste en gestion de communautés pour les entreprises de technologie",
                                                "selectable": true,
                                                "children": []
                                            },
                                            {
                                                "name": "Spécialiste en gestion de communautés pour les marques de produits de consommation",
                                                "selectable": true,
                                                "children": []
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "name": "Expert en SEO (Optimisation pour les moteurs de recherche)",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Spécialiste en SEO local",
                                "selectable": false,
                                "children": [
                                    {
                                        "name": "Expert en SEO local pour les restaurants",
                                        "selectable": true,
                                        "children": []
                                    },
                                    {
                                        "name": "Expert en SEO local pour les entreprises de services locaux",
                                        "selectable": true,
                                        "children": []
                                    }
                                ]
                            },
                            {
                                "name": "Spécialiste en SEO technique",
                                "selectable": false,
                                "children": [
                                    {
                                        "name": "Expert en audit SEO technique",
                                        "selectable": false,
                                        "children": [
                                            {
                                                "name": "Spécialiste en audit SEO technique pour les sites web de commerce électronique",
                                                "selectable": true,
                                                "children": []
                                            },
                                            {
                                                "name": "Spécialiste en audit SEO technique pour les sites web d'information",
                                                "selectable": true,
                                                "children": []
                                            }
                                        ]
                                    },
                                    {
                                        "name": "Spécialiste en optimisation de la vitesse de chargement",
                                        "selectable": false,
                                        "children": [
                                            {
                                                "name": "Expert en optimisation de la vitesse de chargement pour les sites web de voyage",
                                                "selectable": true,
                                                "children": []
                                            },
                                            {
                                                "name": "Expert en optimisation de la vitesse de chargement pour les sites web de santé",
                                                "selectable": true,
                                                "children": []
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "name": "Responsable de publicités en ligne",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Spécialiste en publicité Google Ads",
                                "selectable": false,
                                "children": [
                                    {
                                        "name": "Expert en publicité Google Ads pour les entreprises B2B",
                                        "selectable": true,
                                        "children": []
                                    },
                                    {
                                        "name": "Expert en publicité Google Ads pour les entreprises B2C",
                                        "selectable": true,
                                        "children": []
                                    }
                                ]
                            },
                            {
                                "name": "Spécialiste en publicités sur les réseaux de display",
                                "selectable": false,
                                "children": [
                                    {
                                        "name": "Expert en publicités programmatiques",
                                        "selectable": false,
                                        "children": [
                                            {
                                                "name": "Spécialiste en publicités programmatiques pour les sites web de voyage",
                                                "selectable": true,
                                                "children": []
                                            },
                                            {
                                                "name": "Spécialiste en publicités programmatiques pour les sites web d'actualités",
                                                "selectable": true,
                                                "children": []
                                            }
                                        ]
                                    },
                                    {
                                        "name": "Spécialiste en publicités natives",
                                        "selectable": false,
                                        "children": [
                                            {
                                                "name": "Expert en publicités natives pour les entreprises de technologie",
                                                "selectable": true,
                                                "children": []
                                            },
                                            {
                                                "name": "Expert en publicités natives pour les entreprises de produits de beauté",
                                                "selectable": true,
                                                "children": []
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "name": "Responsable de l'e-mail marketing",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Spécialiste en automatisation de l'e-mail marketing",
                                "selectable": false,
                                "children": [
                                    {
                                        "name": "Expert en automatisation de marketing par e-mail pour les sites de commerce électronique",
                                        "selectable": true,
                                        "children": []
                                    },
                                    {
                                        "name": "Expert en automatisation de marketing par e-mail pour les organisations à but non lucratif",
                                        "selectable": true,
                                        "children": []
                                    }
                                ]
                            },
                            {
                                "name": "Expert en gestion de listes d'e-mails",
                                "selectable": false,
                                "children": [
                                    {
                                        "name": "Spécialiste en croissance des listes d'abonnés par e-mail",
                                        "selectable": false,
                                        "children": [
                                            {
                                                "name": "Expert en croissance des listes d'abonnés par e-mail pour les entreprises de voyage",
                                                "selectable": true,
                                                "children": []
                                            },
                                            {
                                                "name": "Expert en croissance des listes d'abonnés par e-mail pour les entreprises de mode",
                                                "selectable": true,
                                                "children": []
                                            }
                                        ]
                                    },
                                    {
                                        "name": "Expert en nettoyage de listes d'abonnés par e-mail",
                                        "selectable": false,
                                        "children": [
                                            {
                                                "name": "Spécialiste en nettoyage de listes d'abonnés par e-mail pour les entreprises de technologie",
                                                "selectable": true,
                                                "children": []
                                            },
                                            {
                                                "name": "Spécialiste en nettoyage de listes d'abonnés par e-mail pour les entreprises de finance",
                                                "selectable": true,
                                                "children": []
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                "name": "4. Contenu",
                "selectable": false,
                "children": [
                    {
                        "name": "Rédacteur créatif",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Créateur de contenu visuel",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Spécialiste en conception graphique",
                                "selectable": false,
                                "children": [
                                    {
                                        "name": "Expert en conception de bannières publicitaires pour les sites web de voyage",
                                        "selectable": true,
                                        "children": []
                                    },
                                    {
                                        "name": "Expert en conception de visuels pour les médias sociaux pour les marques de produits de consommation",
                                        "selectable": true,
                                        "children": []
                                    }
                                ]
                            },
                            {
                                "name": "Expert en montage vidéo",
                                "selectable": false,
                                "children": [
                                    {
                                        "name": "Spécialiste en montage vidéo de vlogs de voyage",
                                        "selectable": false,
                                        "children": [
                                            {
                                                "name": "Expert en montage vidéo de vlogs de voyage d'aventure",
                                                "selectable": true,
                                                "children": []
                                            },
                                            {
                                                "name": "Expert en montage vidéo de vlogs de voyage culinaires",
                                                "selectable": true,
                                                "children": []
                                            }
                                        ]
                                    },
                                    {
                                        "name": "Expert en montage vidéo de tutoriels",
                                        "selectable": false,
                                        "children": [
                                            {
                                                "name": "Spécialiste en montage vidéo de tutoriels de beauté",
                                                "selectable": true,
                                                "children": []
                                            },
                                            {
                                                "name": "Spécialiste en montage vidéo de tutoriels de bricolage",
                                                "selectable": true,
                                                "children": []
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "name": "Responsable de blog",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Spécialiste en blogging de voyage",
                                "selectable": false,
                                "children": [
                                    {
                                        "name": "Expert en rédaction de blogs de voyage d'aventure",
                                        "selectable": false,
                                        "children": [
                                            {
                                                "name": "Spécialiste en photographie de voyage pour blogs d'aventure",
                                                "selectable": true,
                                                "children": []
                                            },
                                            {
                                                "name": "Spécialiste en rédaction de blogs de randonnée",
                                                "selectable": true,
                                                "children": []
                                            }
                                        ]
                                    },
                                    {
                                        "name": "Expert en rédaction de blogs de voyage culinaires",
                                        "selectable": false,
                                        "children": [
                                            {
                                                "name": "Spécialiste en photographie alimentaire pour blogs culinaires",
                                                "selectable": true,
                                                "children": []
                                            },
                                            {
                                                "name": "Spécialiste en rédaction de blogs de dégustation de vin",
                                                "selectable": true,
                                                "children": []
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "name": "Expert en blogging culinaire",
                                "selectable": false,
                                "children": [
                                    {
                                        "name": "Spécialiste en rédaction de blogs de recettes de desserts",
                                        "selectable": false,
                                        "children": [
                                            {
                                                "name": "Expert en photographie de desserts pour blogs culinaires",
                                                "selectable": true,
                                                "children": []
                                            },
                                            {
                                                "name": "Spécialiste en rédaction de blogs de pâtisserie",
                                                "selectable": true,
                                                "children": []
                                            }
                                        ]
                                    },
                                    {
                                        "name": "Expert en rédaction de blogs de cuisine saine",
                                        "selectable": false,
                                        "children": [
                                            {
                                                "name": "Spécialiste en rédaction de blogs de cuisine végétalienne",
                                                "selectable": true,
                                                "children": []
                                            },
                                            {
                                                "name": "Expert en photographie de plats végétaliens pour blogs culinaires",
                                                "selectable": true,
                                                "children": []
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "name": "Copywriter publicitaire",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Spécialiste en publicités imprimées",
                                "selectable": false,
                                "children": [
                                    {
                                        "name": "Expert en rédaction de publicités pour magazines de mode",
                                        "selectable": false,
                                        "children": [
                                            {
                                                "name": "Spécialiste en rédaction de publicités pour les marques de vêtements",
                                                "selectable": true,
                                                "children": []
                                            },
                                            {
                                                "name": "Spécialiste en rédaction de publicités pour les marques de chaussures",
                                                "selectable": true,
                                                "children": []
                                            }
                                        ]
                                    },
                                    {
                                        "name": "Spécialiste en rédaction de publicités extérieures",
                                        "selectable": false,
                                        "children": [
                                            {
                                                "name": "Expert en rédaction de publicités pour panneaux d'affichage numériques",
                                                "selectable": true,
                                                "children": []
                                            },
                                            {
                                                "name": "Expert en rédaction de publicités pour panneaux d'affichage traditionnels",
                                                "selectable": true,
                                                "children": []
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "name": "Expert en copywriting pour les médias sociaux",
                                "selectable": false,
                                "children": [
                                    {
                                        "name": "Spécialiste en rédaction de légendes Instagram pour les entreprises de voyage",
                                        "selectable": false,
                                        "children": [
                                            {
                                                "name": "Expert en rédaction de légendes Instagram pour les agences de voyage",
                                                "selectable": true,
                                                "children": []
                                            },
                                            {
                                                "name": "Spécialiste en rédaction de légendes Instagram pour les hôtels de luxe",
                                                "selectable": true,
                                                "children": []
                                            }
                                        ]
                                    },
                                    {
                                        "name": "Expert en rédaction de tweets promotionnels pour les entreprises technologiques",
                                        "selectable": false,
                                        "children": [
                                            {
                                                "name": "Spécialiste en rédaction de tweets promotionnels pour les startups",
                                                "selectable": true,
                                                "children": []
                                            },
                                            {
                                                "name": "Spécialiste en rédaction de tweets promotionnels pour les entreprises de logiciels",
                                                "selectable": true,
                                                "children": []
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                "name": "5. Relations Publiques",
                "selectable": false,
                "children": [
                    {
                        "name": "Responsable des relations publiques",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Spécialiste en relations publiques financières",
                                "selectable": false,
                                "children": [
                                    {
                                        "name": "Expert en communication financière pour les institutions financières",
                                        "selectable": false,
                                        "children": [
                                            {
                                                "name": "Spécialiste en relations publiques pour les banques",
                                                "selectable": true,
                                                "children": []
                                            },
                                            {
                                                "name": "Spécialiste en relations publiques pour les sociétés de gestion de patrimoine",
                                                "selectable": true,
                                                "children": []
                                            }
                                        ]
                                    },
                                    {
                                        "name": "Spécialiste en relations publiques pour les entreprises d'assurance",
                                        "selectable": false,
                                        "children": [
                                            {
                                                "name": "Expert en communication financière pour les compagnies d'assurance",
                                                "selectable": true,
                                                "children": []
                                            },
                                            {
                                                "name": "Expert en relations publiques pour les courtiers en assurance",
                                                "selectable": true,
                                                "children": []
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "name": "Expert en relations avec les médias",
                                "selectable": false,
                                "children": [
                                    {
                                        "name": "Spécialiste en relations avec les médias nationaux",
                                        "selectable": false,
                                        "children": [
                                            {
                                                "name": "Expert en relations avec les médias nationaux pour les entreprises de technologie",
                                                "selectable": true,
                                                "children": []
                                            },
                                            {
                                                "name": "Spécialiste en relations avec les médias nationaux pour les organisations environnementales",
                                                "selectable": true,
                                                "children": []
                                            }
                                        ]
                                    },
                                    {
                                        "name": "Spécialiste en relations avec les médias locaux",
                                        "selectable": false,
                                        "children": [
                                            {
                                                "name": "Expert en relations avec les médias locaux pour les entreprises de restauration",
                                                "selectable": true,
                                                "children": []
                                            },
                                            {
                                                "name": "Expert en relations avec les médias locaux pour les associations caritatives",
                                                "selectable": true,
                                                "children": []
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "name": "Attaché de presse",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Spécialiste en relations avec les médias locaux",
                                "selectable": false,
                                "children": [
                                    {
                                        "name": "Expert en relations avec les médias locaux pour les entreprises de commerce de détail",
                                        "selectable": false,
                                        "children": [
                                            {
                                                "name": "Expert en relations avec les médias locaux pour les boutiques de mode",
                                                "selectable": true,
                                                "children": []
                                            },
                                            {
                                                "name": "Expert en relations avec les médias locaux pour les magasins de produits électroniques",
                                                "selectable": true,
                                                "children": []
                                            }
                                        ]
                                    },
                                    {
                                        "name": "Spécialiste en relations avec les médias locaux pour les institutions éducatives",
                                        "selectable": false,
                                        "children": [
                                            {
                                                "name": "Spécialiste en relations avec les médias locaux pour les écoles primaires",
                                                "selectable": true,
                                                "children": []
                                            },
                                            {
                                                "name": "Spécialiste en relations avec les médias locaux pour les universités",
                                                "selectable": true,
                                                "children": []
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "name": "Expert en gestion de crise médiatique",
                                "selectable": false,
                                "children": [
                                    {
                                        "name": "Spécialiste en gestion de crise médiatique pour les entreprises pharmaceutiques",
                                        "selectable": false,
                                        "children": [
                                            {
                                                "name": "Expert en gestion de crise médiatique pour les entreprises de santé",
                                                "selectable": true,
                                                "children": []
                                            },
                                            {
                                                "name": "Spécialiste en gestion de crise médiatique pour les fabricants de médicaments",
                                                "selectable": true,
                                                "children": []
                                            }
                                        ]
                                    },
                                    {
                                        "name": "Expert en gestion de crise médiatique pour les institutions gouvernementales",
                                        "selectable": false,
                                        "children": [
                                            {
                                                "name": "Spécialiste en gestion de crise médiatique pour les municipalités",
                                                "selectable": true,
                                                "children": []
                                            },
                                            {
                                                "name": "Spécialiste en gestion de crise médiatique pour les organismes gouvernementaux régionaux",
                                                "selectable": true,
                                                "children": []
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "name": "Spécialiste en communication de crise",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Expert en communication de crise politique",
                                "selectable": false,
                                "children": [
                                    {
                                        "name": "Spécialiste en communication de crise politique pour les campagnes électorales",
                                        "selectable": true,
                                        "children": []
                                    },
                                    {
                                        "name": "Spécialiste en communication de crise politique pour les partis politiques",
                                        "selectable": true,
                                        "children": []
                                    }
                                ]
                            },
                            {
                                "name": "Spécialiste en communication de crise environnementale",
                                "selectable": false,
                                "children": [
                                    {
                                        "name": "Expert en communication de crise environnementale pour les catastrophes naturelles",
                                        "selectable": true,
                                        "children": []
                                    },
                                    {
                                        "name": "Expert en communication de crise environnementale pour les questions de durabilité",
                                        "selectable": true,
                                        "children": []
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "name": "Organisateur d'événements",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Spécialiste en événements sportifs",
                                "selectable": false,
                                "children": [
                                    {
                                        "name": "Expert en organisation d'événements sportifs de niveau international",
                                        "selectable": false,
                                        "children": [
                                            {
                                                "name": "Spécialiste en organisation de tournois de golf internationaux",
                                                "selectable": true,
                                                "children": []
                                            },
                                            {
                                                "name": "Spécialiste en organisation de compétitions de natation internationales",
                                                "selectable": true,
                                                "children": []
                                            }
                                        ]
                                    },
                                    {
                                        "name": "Expert en organisation d'événements sportifs locaux",
                                        "selectable": false,
                                        "children": [
                                            {
                                                "name": "Spécialiste en organisation de marathons locaux",
                                                "selectable": true,
                                                "children": []
                                            },
                                            {
                                                "name": "Spécialiste en organisation de tournois de tennis locaux",
                                                "selectable": true,
                                                "children": []
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "name": "Spécialiste en événements culturels",
                                "selectable": false,
                                "children": [
                                    {
                                        "name": "Expert en organisation de festivals de musique",
                                        "selectable": false,
                                        "children": [
                                            {
                                                "name": "Expert en organisation de festivals de musique rock",
                                                "selectable": true,
                                                "children": []
                                            },
                                            {
                                                "name": "Expert en organisation de festivals de musique électronique",
                                                "selectable": true,
                                                "children": []
                                            }
                                        ]
                                    },
                                    {
                                        "name": "Spécialiste en organisation d'expositions artistiques",
                                        "selectable": false,
                                        "children": [
                                            {
                                                "name": "Spécialiste en organisation d'expositions d'art contemporain",
                                                "selectable": true,
                                                "children": []
                                            },
                                            {
                                                "name": "Spécialiste en organisation d'expositions d'art classique",
                                                "selectable": true,
                                                "children": []
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                "name": "6. Analyse et Mesure",
                "selectable": false,
                "children": [
                    {
                        "name": "Analyste de données marketing",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Spécialiste en analyse des données des médias sociaux",
                                "selectable": false,
                                "children": [
                                    {
                                        "name": "Expert en analyse des données des médias sociaux pour les entreprises de divertissement",
                                        "selectable": true,
                                        "children": []
                                    },
                                    {
                                        "name": "Expert en analyse des données des médias sociaux pour les organisations à but non lucratif",
                                        "selectable": true,
                                        "children": []
                                    }
                                ]
                            },
                            {
                                "name": "Expert en rapports et statistiques",
                                "selectable": false,
                                "children": [
                                    {
                                        "name": "Spécialiste en analyse des taux de conversion",
                                        "selectable": false,
                                        "children": [
                                            {
                                                "name": "Expert en analyse des taux de conversion pour les sites web de commerce électronique",
                                                "selectable": true,
                                                "children": []
                                            },
                                            {
                                                "name": "Expert en analyse des taux de conversion pour les sites web d'abonnement",
                                                "selectable": true,
                                                "children": []
                                            }
                                        ]
                                    },
                                    {
                                        "name": "Spécialiste en analyse de l'entonnoir de conversion",
                                        "selectable": false,
                                        "children": [
                                            {
                                                "name": "Expert en analyse de l'entonnoir de conversion pour les entreprises SaaS",
                                                "selectable": true,
                                                "children": []
                                            },
                                            {
                                                "name": "Expert en analyse de l'entonnoir de conversion pour les sites web de voyages",
                                                "selectable": true,
                                                "children": []
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "name": "Spécialiste en suivi de KPI (Key Performance Indicators)",
                                "selectable": false,
                                "children": [
                                    {
                                        "name": "Expert en suivi des KPI pour les campagnes publicitaires",
                                        "selectable": false,
                                        "children": [
                                            {
                                                "name": "Expert en suivi des KPI pour les campagnes publicitaires sur les médias sociaux",
                                                "selectable": true,
                                                "children": []
                                            },
                                            {
                                                "name": "Expert en suivi des KPI pour les campagnes publicitaires par e-mail",
                                                "selectable": true,
                                                "children": []
                                            }
                                        ]
                                    },
                                    {
                                        "name": "Spécialiste en suivi des KPI pour le marketing de contenu",
                                        "selectable": false,
                                        "children": [
                                            {
                                                "name": "Spécialiste en suivi des KPI pour les blogs d'entreprise",
                                                "selectable": true,
                                                "children": []
                                            },
                                            {
                                                "name": "Spécialiste en suivi des KPI pour les vidéos en ligne",
                                                "selectable": true,
                                                "children": []
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                "name": "7. Marketing de Contenu",
                "selectable": false,
                "children": [
                    {
                        "name": "Stratège de contenu",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Spécialiste en stratégie de contenu pour les blogs",
                                "selectable": false,
                                "children": [
                                    {
                                        "name": "Expert en stratégie de contenu pour les blogs de voyage",
                                        "selectable": false,
                                        "children": [
                                            {
                                                "name": "Spécialiste en stratégie de contenu pour les blogs de voyage d'aventure",
                                                "selectable": true,
                                                "children": []
                                            },
                                            {
                                                "name": "Spécialiste en stratégie de contenu pour les blogs de voyage culinaires",
                                                "selectable": true,
                                                "children": []
                                            }
                                        ]
                                    },
                                    {
                                        "name": "Expert en stratégie de contenu pour les blogs de mode",
                                        "selectable": false,
                                        "children": [
                                            {
                                                "name": "Spécialiste en stratégie de contenu pour les blogs de mode éthique",
                                                "selectable": true,
                                                "children": []
                                            },
                                            {
                                                "name": "Expert en stratégie de contenu pour les blogs de mode haut de gamme",
                                                "selectable": true,
                                                "children": []
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "name": "Expert en stratégie de contenu vidéo",
                                "selectable": false,
                                "children": [
                                    {
                                        "name": "Spécialiste en stratégie de contenu vidéo pour les chaînes YouTube de divertissement",
                                        "selectable": false,
                                        "children": [
                                            {
                                                "name": "Expert en stratégie de contenu vidéo pour les chaînes de voyage sur YouTube",
                                                "selectable": true,
                                                "children": []
                                            },
                                            {
                                                "name": "Expert en stratégie de contenu vidéo pour les chaînes de cuisine sur YouTube",
                                                "selectable": true,
                                                "children": []
                                            }
                                        ]
                                    },
                                    {
                                        "name": "Spécialiste en stratégie de contenu vidéo pour les vidéos éducatives en ligne",
                                        "selectable": false,
                                        "children": [
                                            {
                                                "name": "Expert en stratégie de contenu vidéo pour les cours en ligne",
                                                "selectable": true,
                                                "children": []
                                            },
                                            {
                                                "name": "Spécialiste en stratégie de contenu vidéo pour les tutoriels de bricolage en ligne",
                                                "selectable": true,
                                                "children": []
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "name": "Concepteur de contenu interactif",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Spécialiste en création de quiz interactifs",
                                "selectable": false,
                                "children": [
                                    {
                                        "name": "Expert en création de quiz interactifs pour les sites web de fitness",
                                        "selectable": true,
                                        "children": []
                                    },
                                    {
                                        "name": "Expert en création de quiz interactifs pour les sites web éducatifs",
                                        "selectable": true,
                                        "children": []
                                    }
                                ]
                            },
                            {
                                "name": "Spécialiste en développement d'applications interactives",
                                "selectable": false,
                                "children": [
                                    {
                                        "name": "Expert en développement d'applications interactives pour les musées",
                                        "selectable": true,
                                        "children": []
                                    },
                                    {
                                        "name": "Expert en développement d'applications interactives pour les galeries d'art",
                                        "selectable": true,
                                        "children": []
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "name": "Rédacteur technique",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Spécialiste en rédaction de documentation technique",
                                "selectable": false,
                                "children": [
                                    {
                                        "name": "Expert en rédaction de manuels d'utilisation",
                                        "selectable": false,
                                        "children": [
                                            {
                                                "name": "Spécialiste en rédaction de manuels d'utilisation pour les produits électroniques",
                                                "selectable": true,
                                                "children": []
                                            },
                                            {
                                                "name": "Spécialiste en rédaction de manuels d'utilisation pour les logiciels",
                                                "selectable": true,
                                                "children": []
                                            }
                                        ]
                                    },
                                    {
                                        "name": "Expert en rédaction de guides de dépannage",
                                        "selectable": false,
                                        "children": [
                                            {
                                                "name": "Spécialiste en rédaction de guides de dépannage pour les produits électroménagers",
                                                "selectable": true,
                                                "children": []
                                            },
                                            {
                                                "name": "Spécialiste en rédaction de guides de dépannage pour les produits automobiles",
                                                "selectable": true,
                                                "children": []
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                "name": "8. Marketing International",
                "selectable": false,
                "children": [
                    {
                        "name": "Responsable marketing international",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Spécialiste en développement de marchés internationaux",
                                "selectable": false,
                                "children": [
                                    {
                                        "name": "Expert en expansion internationale pour les entreprises de commerce électronique",
                                        "selectable": true,
                                        "children": []
                                    },
                                    {
                                        "name": "Expert en expansion internationale pour les entreprises de biens de consommation",
                                        "selectable": true,
                                        "children": []
                                    }
                                ]
                            },
                            {
                                "name": "Spécialiste en adaptation culturelle",
                                "selectable": false,
                                "children": [
                                    {
                                        "name": "Expert en adaptation culturelle pour les campagnes publicitaires internationales",
                                        "selectable": true,
                                        "children": []
                                    },
                                    {
                                        "name": "Expert en adaptation culturelle pour les sites web multilingues",
                                        "selectable": true,
                                        "children": []
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "name": "Gestionnaire de campagnes publicitaires internationales",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Spécialiste en publicités internationales sur les moteurs de recherche",
                                "selectable": false,
                                "children": [
                                    {
                                        "name": "Expert en publicités internationales sur Google Ads",
                                        "selectable": true,
                                        "children": []
                                    },
                                    {
                                        "name": "Expert en publicités internationales sur Bing Ads",
                                        "selectable": true,
                                        "children": []
                                    }
                                ]
                            },
                            {
                                "name": "Spécialiste en publicités internationales sur les réseaux sociaux",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Expert en publicités internationales sur Facebook et Instagram",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Expert en publicités internationales sur LinkedIn et Twitter",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Responsable de la localisation de contenu",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Spécialiste en localisation de sites web",
                                "selectable": false,
                                "children": [
                                    {
                                        "name": "Expert en localisation de sites web pour les entreprises de technologie",
                                        "selectable": true,
                                        "children": []
                                    },
                                    {
                                        "name": "Expert en localisation de sites web pour les entreprises de jeux vidéo",
                                        "selectable": true,
                                        "children": []
                                    }
                                ]
                            },
                            {
                                "name": "Spécialiste en localisation de matériel promotionnel",
                                "selectable": false,
                                "children": [
                                    {
                                        "name": "Spécialiste en localisation de vidéos promotionnelles",
                                        "selectable": false,
                                        "children": [
                                            {
                                                "name": "Expert en localisation de vidéos promotionnelles pour les entreprises de divertissement",
                                                "selectable": true,
                                                "children": []
                                            },
                                            {
                                                "name": "Expert en localisation de vidéos promotionnelles pour les entreprises de mode",
                                                "selectable": true,
                                                "children": []
                                            }
                                        ]
                                    },
                                    {
                                        "name": "Spécialiste en localisation de brochures marketing",
                                        "selectable": false,
                                        "children": [
                                            {
                                                "name": "Expert en localisation de brochures marketing pour les entreprises pharmaceutiques",
                                                "selectable": true,
                                                "children": []
                                            },
                                            {
                                                "name": "Expert en localisation de brochures marketing pour les entreprises automobiles",
                                                "selectable": true,
                                                "children": []
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                "name": "9. Publicités et Médias Payants",
                "selectable": false,
                "children": [
                    {
                        "name": "Gestionnaire de publicités en ligne",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Spécialiste en publicités sur les médias sociaux payants",
                                "selectable": false,
                                "children": [
                                    {
                                        "name": "Expert en publicités sur Facebook payantes",
                                        "selectable": false,
                                        "children": [
                                            {
                                                "name": "Expert en publicités sur Facebook payantes pour les entreprises locales",
                                                "selectable": true,
                                                "children": []
                                            },
                                            {
                                                "name": "Expert en publicités sur Facebook payantes pour les entreprises de technologie",
                                                "selectable": true,
                                                "children": []
                                            }
                                        ]
                                    },
                                    {
                                        "name": "Expert en publicités sur Instagram payantes",
                                        "selectable": false,
                                        "children": [
                                            {
                                                "name": "Expert en publicités sur Instagram payantes pour les entreprises de mode",
                                                "selectable": true,
                                                "children": []
                                            },
                                            {
                                                "name": "Expert en publicités sur Instagram payantes pour les entreprises de fitness",
                                                "selectable": true,
                                                "children": []
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "name": "Spécialiste en publicités Google Ads payantes",
                                "selectable": false,
                                "children": [
                                    {
                                        "name": "Spécialiste en publicités Google Ads payantes pour les produits électroniques",
                                        "selectable": true,
                                        "children": []
                                    },
                                    {
                                        "name": "Spécialiste en publicités Google Ads payantes pour les services professionnels",
                                        "selectable": true,
                                        "children": []
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "name": "Responsable de publicités vidéo en ligne",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Spécialiste en publicités vidéo Facebook",
                                "selectable": false,
                                "children": [
                                    {
                                        "name": "Spécialiste en publicités vidéo Facebook pour les entreprises locales",
                                        "selectable": true,
                                        "children": []
                                    },
                                    {
                                        "name": "Spécialiste en publicités vidéo Facebook pour les entreprises de technologie",
                                        "selectable": true,
                                        "children": []
                                    }
                                ]
                            },
                            {
                                "name": "Expert en publicités vidéo YouTube",
                                "selectable": false,
                                "children": [
                                    {
                                        "name": "Expert en publicités vidéo YouTube pour les chaînes de beauté",
                                        "selectable": true,
                                        "children": []
                                    },
                                    {
                                        "name": "Expert en publicités vidéo YouTube pour les chaînes de jeu",
                                        "selectable": true,
                                        "children": []
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "name": "Gestionnaire de publicités programmatiques",
                        "selectable": false,
                        "children": []
                    }
                ]
            },
            {
                "name": "10. Marketing de l'Influence",
                "selectable": false,
                "children": [
                    {
                        "name": "Spécialiste en marketing d'influence",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Spécialiste en marketing d'influence B2B",
                                "selectable": false,
                                "children": [
                                    {
                                        "name": "Spécialiste en marketing d'influence B2B pour les entreprises technologiques",
                                        "selectable": true,
                                        "children": []
                                    },
                                    {
                                        "name": "Spécialiste en marketing d'influence B2B pour les entreprises de services professionnels",
                                        "selectable": true,
                                        "children": []
                                    }
                                ]
                            },
                            {
                                "name": "Spécialiste en marketing d'influence B2C",
                                "selectable": false,
                                "children": [
                                    {
                                        "name": "Spécialiste en marketing d'influence B2C pour les entreprises de mode",
                                        "selectable": true,
                                        "children": []
                                    },
                                    {
                                        "name": "Spécialiste en marketing d'influence B2C pour les entreprises de beauté",
                                        "selectable": true,
                                        "children": []
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "name": "Responsable de campagnes d'influence",
                        "selectable": true,
                        "children": []
                    }
                ]
            }
        ],
        "name_section": "Marketing"
    },
    {
        categorie: [
            {
                "name": "Designer industriel",
                "selectable": false,
                "children": [
                    {
                        "name": "Designers d'équipements industriels ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Designer automobile :",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Designer de motos",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Designer de camions",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Designer de bateaux",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Designer d'avions",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Designer d'intérieurs de transports publics",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Designer d'interfaces utilisateur pour les systèmes de transport :",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Designer d'infrastructures de transport : L",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Designer de meubles industriels :",
                        "selectable": true,
                        "children": []
                    }
                    
                ]
            },
            {
                "name": "Designer d'emballage",
                "selectable": false,
                "children": [
                    {
                        "name": "Les designers d'emballage  pour Aliments et boissons",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Les designers d'emballage  pour  Produits de beauté et de soins personnels :",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Les designers d'emballage  pour  Produits ménagers :",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Les designers d'emballage de jouets et de jeux t",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Les designers d'emballage  pour  Produits électroniques grand public :",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Les designers d'emballage  pour  Produits de bureau et de papeterie :",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Designer de systèmes d'éclairage",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Designer d'emballage de luxe :",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Designer d'emballage industriel :",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Designer d'emballage écologique :",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Designer d'emballage de produits électroniques :",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Designer d'emballage de produits médicaux :",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Designer d'emballage :",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Designer de meubles",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Designer de meubles de cuisine :",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Designer de meubles de salle de bain",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Designer de meubles pour enfants :",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Designer de meubles sur mesure",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Designer de jouets :",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Designer de bijoux :",
                        "selectable": true,
                        "children": []
                    }
                ]
            },
            {
                "name": "Directeur artistique : ",
                "selectable": false,
                "children": [
                    {
                        "name": "Directeur artistique en mode : ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Directeur artistique en joaillerie : ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Directeur artistique en parfumerie :",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Directeur artistique en hôtellerie : ",
                        "selectable": true,
                        "children": []
                    }
                ]
            },
            {
                "name": "Styliste : ",
                "selectable": false,
                "children": [
                    {
                        "name": "Styliste de mode :",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Styliste d'accessoires : ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Styliste de coiffure et maquillage : ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Styliste d'intérieur :",
                        "selectable": true,
                        "children": []
                    }
                ]
            },
            {
                "name": "Horloger : ",
                "selectable": false,
                "children": [
                    {
                        "name": "Designer horloger : ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Ingénieur horloger :",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Horloger assembleur :",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Horloger réparateur :",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Responsable marketing horlogerie :",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Responsable des ventes horlogerie :",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Broderie haute couture :",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Expert horloger : ",
                        "selectable": true,
                        "children": []
                    }
                ]
            },
            {
                "name": "Joaillier : ",
                "selectable": false,
                "children": [
                    {
                        "name": "Joaillier haute joaillerie : ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Joaillier bijoutier : ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Joaillier artisan : ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Joaillier sertisseur : ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Joaillier lapidaire :",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Gemmologue :",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Responsable marketing joaillerie :",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Responsable des ventes joaillerie :",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Expert joaillier : ",
                        "selectable": true,
                        "children": []
                    }
                ]
            },
            {
                "name": "Artisan d'art : ",
                "selectable": false,
                "children": [
                    {
                        "name": "Broderie",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Broderie haute couture :",
                                "selectable": false,
                                "children": [
                                    {
                                        "name": "La broderie perlée",
                                        "selectable": true,
                                        "children": []
                                    },
                                    {
                                        "name": "La broderie de paillettes",
                                        "selectable": true,
                                        "children": []
                                    },
                                    {
                                        "name": "La broderie de sequins :",
                                        "selectable": true,
                                        "children": []
                                    },
                                    {
                                        "name": "La broderie de ruban",
                                        "selectable": true,
                                        "children": []
                                    },
                                    {
                                        "name": "La broderie de perles et de pierres précieuses :",
                                        "selectable": true,
                                        "children": []
                                    },
                                    {
                                        "name": "La broderie en relief :",
                                        "selectable": true,
                                        "children": []
                                    },
                                    {
                                        "name": "La broderie à points comptés",
                                        "selectable": false,
                                        "children": [
                                            {
                                                "name": "Le point de croix :",
                                                "selectable": true,
                                                "children": []
                                            },
                                            {
                                                "name": "Le point de tige",
                                                "selectable": true,
                                                "children": []
                                            },
                                            {
                                                "name": "Le point de nœud",
                                                "selectable": true,
                                                "children": []
                                            },
                                            {
                                                "name": "Le point lancé",
                                                "selectable": true,
                                                "children": []
                                            },
                                            {
                                                "name": "Le point de chaînette",
                                                "selectable": true,
                                                "children": []
                                            },
                                            {
                                                "name": "Le point de Rhodes :",
                                                "selectable": true,
                                                "children": []
                                            },
                                            {
                                                "name": "Le point de feuille",
                                                "selectable": true,
                                                "children": []
                                            },
                                            {
                                                "name": "Le point de roue :",
                                                "selectable": true,
                                                "children": []
                                            }
                                        ]
                                    },
                                    {
                                        "name": "La broderie de dentelle :",
                                        "selectable": true,
                                        "children": []
                                    }
                                ]
                            },
                            {
                                "name": "Broderie d'accessoires de mode",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Broderie d'ameublement",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Broderie de bijoux :",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Broderie de drapeaux et d'écussons :",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Broderie d'art contemporain ",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Cadre ( fabricant de cadre ) / Frame Maker )",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Fabricant de cadres en métal : ",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Fabricant de cadres en bois : ",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Fabricant de cadres en cuir : ",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Fabricant de cadres en cristal : ",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Fabricant de cadres personnalisés : ",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Fabricant de cadres d'œuvres d'art : ",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Calligraphiste ",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Calligraphe traditionnel : ",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Calligraphe contemporain :",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Enlumineur :",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Graphiste calligraphe : ",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Scribe : ",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Lettering artist : ",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Graveur : ",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Céramiste : ",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Céramiste d'art : ",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Céramiste utilitaire : ",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Céramiste de production : ",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Céramiste traditionnel : ",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Céramiste contemporain : ",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "chaudronnier",
                        "selectable": false,
                        "children": [
                            {
                                "name": "La chaudronnerie d'art :",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "La chaudronnerie de précision",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "La chaudronnerie industrielle :",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "La chaudronnerie pour l'automobile :",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "La chaudronnerie pour les yachts et les bateaux",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Ebéniste :  / Marqueterie ",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Ébéniste de meubles d'art : ",
                                "selectable": false,
                                "children": [
                                    {
                                        "name": "Ébénisterie de haute décoration :",
                                        "selectable": true,
                                        "children": []
                                    },
                                    {
                                        "name": "Restauration d'antiquités",
                                        "selectable": true,
                                        "children": []
                                    },
                                    {
                                        "name": "Ébénisterie de luxe sur mesure",
                                        "selectable": true,
                                        "children": []
                                    },
                                    {
                                        "name": "La marqueterie de placage",
                                        "selectable": true,
                                        "children": []
                                    }
                                ]
                            },
                            {
                                "name": "Ébénisterie de Marine ",
                                "selectable": false,
                                "children": [
                                    {
                                        "name": "Ébénisterie de yachts et de bateaux de plaisance ",
                                        "selectable": true,
                                        "children": []
                                    },
                                    {
                                        "name": "Ébénisterie de bateaux de pêche",
                                        "selectable": true,
                                        "children": []
                                    },
                                    {
                                        "name": "Ébénisterie de bateaux de guerre :",
                                        "selectable": true,
                                        "children": []
                                    },
                                    {
                                        "name": "Ébénisterie de bateaux de croisière :",
                                        "selectable": true,
                                        "children": []
                                    },
                                    {
                                        "name": "Ébénisterie de bateaux traditionnels",
                                        "selectable": true,
                                        "children": []
                                    }
                                ]
                            },
                            {
                                "name": "Ébéniste de meubles en série : ",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Ébéniste de restauration de meubles : ",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Ébéniste de mobilier contemporain : ",
                                "selectable": false,
                                "children": [
                                    {
                                        "name": "Ébénisterie d'art contemporain",
                                        "selectable": true,
                                        "children": []
                                    }
                                ]
                            },
                            {
                                "name": "Ébéniste de cuisine :",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Ébéniste d'escaliers :",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Ébéniste de décoration intérieure : ",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Ébénisterie de production",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Emailleurs",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Émailleur sur métaux précieux : ",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Émailleur sur porcelaine : .",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Émailleur sur verre : ",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Émailleur sur montres : ",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Émailleur sur métaux communs : ",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Émailleur artistique : ",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Maroquinier : ",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Maroquinier sacs à main : ",
                                "selectable": false,
                                "children": [
                                    {
                                        "name": "Maroquinier designer : ",
                                        "selectable": true,
                                        "children": []
                                    },
                                    {
                                        "name": "Maroquinier prototypiste : ",
                                        "selectable": true,
                                        "children": []
                                    },
                                    {
                                        "name": "Maroquinier industriel : ",
                                        "selectable": true,
                                        "children": []
                                    },
                                    {
                                        "name": "Maroquinier artisanal :",
                                        "selectable": true,
                                        "children": []
                                    }
                                ]
                            },
                            {
                                "name": "Maroquinier chaussures : ",
                                "selectable": false,
                                "children": [
                                    {
                                        "name": "Maroquinier chaussures de luxe :",
                                        "selectable": true,
                                        "children": []
                                    },
                                    {
                                        "name": "Maroquinier chaussures pour hommes : ",
                                        "selectable": true,
                                        "children": []
                                    },
                                    {
                                        "name": "Maroquinier chaussures pour femmes : ",
                                        "selectable": true,
                                        "children": []
                                    },
                                    {
                                        "name": "Maroquinier chaussures sur mesure : ",
                                        "selectable": true,
                                        "children": []
                                    }
                                ]
                            },
                            {
                                "name": "Maroquinier ceintures : ",
                                "selectable": false,
                                "children": [
                                    {
                                        "name": "Maroquinier ceintures de luxe : ",
                                        "selectable": true,
                                        "children": []
                                    },
                                    {
                                        "name": "Maroquinier ceintures pour hommes :",
                                        "selectable": true,
                                        "children": []
                                    },
                                    {
                                        "name": "Maroquinier ceintures pour femmes : ",
                                        "selectable": true,
                                        "children": []
                                    },
                                    {
                                        "name": "Maroquinier ceintures sur mesure : ",
                                        "selectable": true,
                                        "children": []
                                    }
                                ]
                            },
                            {
                                "name": "Maroquinier articles de voyage : ",
                                "selectable": false,
                                "children": [
                                    {
                                        "name": "Maroquinier valises : ",
                                        "selectable": true,
                                        "children": []
                                    },
                                    {
                                        "name": "Maroquinier sacs de voyage : ",
                                        "selectable": true,
                                        "children": []
                                    },
                                    {
                                        "name": "Maroquinier porte-documents : ",
                                        "selectable": true,
                                        "children": []
                                    },
                                    {
                                        "name": "Maroquinier accessoires de voyage : ",
                                        "selectable": true,
                                        "children": []
                                    }
                                ]
                            },
                            {
                                "name": "Maroquinier accessoires : ",
                                "selectable": false,
                                "children": [
                                    {
                                        "name": "Maroquinier ceintures :",
                                        "selectable": true,
                                        "children": []
                                    },
                                    {
                                        "name": "Maroquinier porte-monnaie : ",
                                        "selectable": true,
                                        "children": []
                                    },
                                    {
                                        "name": "Maroquinier étuis à cigarettes : ",
                                        "selectable": true,
                                        "children": []
                                    },
                                    {
                                        "name": "Maroquinier porte-clés : ",
                                        "selectable": true,
                                        "children": []
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "name": "Marqueterie",
                        "selectable": false,
                        "children": [
                            {
                                "name": "La marqueterie de placage",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "La marqueterie de pierre dure",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "La marqueterie de nacre :",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "La marqueterie de paille :",
                                "selectable": false,
                                "children": [
                                    {
                                        "name": "La marqueterie de paille traditionnelle :",
                                        "selectable": true,
                                        "children": []
                                    },
                                    {
                                        "name": "La marqueterie de paille dorée :",
                                        "selectable": true,
                                        "children": []
                                    },
                                    {
                                        "name": "La marqueterie de paille colorée :",
                                        "selectable": true,
                                        "children": []
                                    },
                                    {
                                        "name": "La marqueterie de paille en relief :",
                                        "selectable": true,
                                        "children": []
                                    },
                                    {
                                        "name": "La marqueterie de paille enroulée :",
                                        "selectable": true,
                                        "children": []
                                    }
                                ]
                            },
                            {
                                "name": "La marqueterie de cuir",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "La marqueterie de métal :",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "La marqueterie de corne :",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Bijoutier-joaillier :",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Bijoutiers : ",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Joailliers : ",
                                "selectable": false,
                                "children": [
                                    {
                                        "name": "Joailliers créateurs : ",
                                        "selectable": true,
                                        "children": []
                                    },
                                    {
                                        "name": "Joailliers artisans : ",
                                        "selectable": true,
                                        "children": []
                                    },
                                    {
                                        "name": "Joailliers gemmologues :",
                                        "selectable": true,
                                        "children": []
                                    },
                                    {
                                        "name": "Joailliers bijoutiers : ",
                                        "selectable": true,
                                        "children": []
                                    }
                                ]
                            },
                            {
                                "name": "Horlogers : ",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Gravure sur bijoux : ",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Fabricants de bijoux fantaisie : ",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Tapis ( Fabricant ) de ",
                        "selectable": false,
                        "children": []
                    },
                    {
                        "name": "Tapissier ",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Tapissier d'ameublement : ",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Tapissier décorateur : ",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Tapissier garnisseur ",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Tapissier sellier : ",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Modiste : ",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Modiste haute couture : ",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Modiste prêt-à-porter :",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Modiste événementiel :",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Modiste chapelier : ",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    },
                    {
                        "name": "Verrier : ",
                        "selectable": false,
                        "children": [
                            {
                                "name": "Verrier d'art : ",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Verrier utilitaire : ",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Verrier scientifique : ",
                                "selectable": true,
                                "children": []
                            },
                            {
                                "name": "Verrier industriel :",
                                "selectable": true,
                                "children": []
                            }
                        ]
                    }
                ]
            },
            {
                "name": "Responsable des ventes : ",
                "selectable": false,
                "children": [
                    {
                        "name": "Responsable de boutique ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Responsable régional de vente ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Responsable des ventes en ligne ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Responsable des ventes internationales ",
                        "selectable": true,
                        "children": []
                    }
                ]
            },
            {
                "name": "Expert en maroquinerie : ",
                "selectable": false,
                "children": [
                    {
                        "name": "Designer maroquinerie : ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Maroquinier :",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Responsable de production : ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Responsable marketing : ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Responsable des ventes :",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Expert en cuir :",
                        "selectable": true,
                        "children": []
                    }
                ]
            },
            {
                "name": "Parfumeur : ",
                "selectable": false,
                "children": [
                    {
                        "name": "Parfumeur-créateur :",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Nez : ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Responsable de la création :",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Responsable marketing : ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Responsable des ventes : ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Responsable de la qualité : ",
                        "selectable": true,
                        "children": []
                    }
                ]
            },
            {
                "name": "Responsable du développement de produit : ",
                "selectable": false,
                "children": [
                    {
                        "name": "Chef de produit :",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Ingénieur produit : ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Responsable de la stratégie produit : ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Responsable du développement de produit durable : ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Responsable du développement de produit numérique : ",
                        "selectable": true,
                        "children": []
                    }
                ]
            },
            {
                "name": "Analyste de marché : ",
                "selectable": false,
                "children": [
                    {
                        "name": "Analyste de marché des produits de luxe : ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Analyste de marché de la clientèle de luxe : ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Analyste de marché de la concurrence dans le luxe : ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Analyste de marché de la distribution de luxe : ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Analyste de marché des événements de luxe : ",
                        "selectable": true,
                        "children": []
                    }
                ]
            },
            {
                "name": "Gestionnaire de la chaîne d'approvisionnement : ",
                "selectable": false,
                "children": [
                    {
                        "name": "Responsable logistique : ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Responsable des achats : ",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Responsable de la planification de la production :",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Responsable de la qualité :",
                        "selectable": true,
                        "children": []
                    },
                    {
                        "name": "Responsable de la gestion de stock : ",
                        "selectable": true,
                        "children": []
                    }
                ]
            }
        ],
        "name_section": "art"
    }
]