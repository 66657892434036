import "./Product.css"
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import AddIcon from '@mui/icons-material/Add';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
import { Button as MuiButton, Chip } from '@material-ui/core'
import IconButton from "@material-ui/core/IconButton";
import MuiBackdrop from "../../components/Loading/MuiBackdrop";
import Typography from "@mui/material/Typography";
import Checkbox from '@mui/material/Checkbox';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import VisibilityIcon from '@mui/icons-material/Visibility';

const useStyles = makeStyles((theme) => ({
    button: {
        textTransform: 'none',
    },
}));

const useStyles1 = makeStyles((theme) => ({
    button: {
        textTransform: 'none',
        borderRadius: 16,
    },
}));

const Product = () => {

    const classes = useStyles();
    const classes1 = useStyles1();

    const [age, setAge] = React.useState('');
    const [openModal, setOpenModal] = React.useState(false)
    const [openModal1, setOpenModal1] = React.useState(false)

    const [checkedRevit, setCheckedRevit] = React.useState(false);
    const [checkedMicro, setCheckedMicro] = React.useState(false);
    const [checkedAuto, setCheckedAuto] = React.useState(false);
    const [checkedBlender, setCheckedBlender] = React.useState(false);
    const [checkedSketch, setCheckedSketch] = React.useState(false);
    const [checkedRinho, setCheckedRinho] = React.useState(false);
    const [checked3ds, setChecked3ds] = React.useState(false);
    const [checkedPdf, setCheckedPdf] = React.useState(false);

    const handleChangeRevit = (event) => {
        setCheckedRevit(event.target.checked);
    };

    const handleChangePdf = (event) => {
        setCheckedPdf(event.target.checked);
    };

    const handleChangeMicro = (event) => {
        setCheckedMicro(event.target.checked);
    };

    const handleChangeAuto = (event) => {
        setCheckedAuto(event.target.checked);
    };

    const handleChangeBlender = (event) => {
        setCheckedBlender(event.target.checked);
    };

    const handleChangeSketch = (event) => {
        setCheckedSketch(event.target.checked);
    };

    const handleChangeRinho = (event) => {
        setCheckedRinho(event.target.checked);
    };

    const handleChange3ds = (event) => {
        setChecked3ds(event.target.checked);
    };

    const handleChange = (event) => {
        setAge(event.target.value);
    };

    return (
        <div style={{ overflowY: 'auto', maxHeight: '90vh', fontFamily: 'Montserrat' }}>
            <div className="d-flex " >
                <div className="image-product">
                    <img alt="product" src="/product.png" />
                </div>
                <div className="d-flex flex-column" style={{ marginTop: 100 }}>
                    <div className="description-product d-flex flex-column">
                        <span style={{ fontSize: 18, color: '#616161', fontWeight: 500 }}>KIT SOLAIRE</span>
                        <span style={{ fontSize: 24, color: '#1565C0', fontWeight: 500 }}>KIT SOLAIRE 850 W 230 V AUTOCONSOMMATION</span>
                        <div className="d-flex align-items-center" style={{ gap: 10 }}>
                            <img height={20} alt="star" src="/star.png" />
                            <span>4.1 (21 reviews)</span>
                        </div>
                        <p className="mt-5">Ce kit solaire en autoconsommation de 850 W à été conçu pour une installation facile.
                            Profitez d'une source d'énergie propre et renouvelable sans tracas, tout en économisant sur vos factures tout en contribuant à un avenir plus durable.</p>

                        <div className="d-flex" style={{ gap: 10 }}>
                            <Button className={classes.button} style={{ width: '60%' }} onClick={(e) => { setOpenModal(true) }} variant="contained">Télécharger</Button>
                            <Button className={classes.button} style={{ width: '36%' }} onClick={(e) => { setOpenModal1(true) }} variant="outlined">Apprendre à installer</Button>
                        </div>

                    </div>
                    <div className="d-flex align-items-center mt-4" style={{ gap: 150 }}>
                        <span>Créer par</span>
                        <Button className={classes1.button} startIcon={<AddIcon />} variant="contained">Suivre</Button>
                    </div>
                    <div className="d-flex align-items-center created-by mb-4" style={{ gap: 50, width: '50%' }}>
                        <img alt="créer par" src="/createdby.png" />
                        <div className="d-flex flex-column">
                            <span style={{ fontSize: 14, color: '#616161', fontWeight: 500 }}>HOYMILES</span>
                            <span style={{ fontSize: 14, color: '#616161' }}>Open Energy For All</span>
                        </div>
                    </div>

                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                        <InputLabel id="demo-select-small-label">Spécifications techniques</InputLabel>
                        <Select
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            value={age}
                            label="Spécifications techniques"
                            onChange={handleChange}
                        >
                            {/* <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            <MenuItem value={10}>Ten</MenuItem>
                            <MenuItem value={20}>Twenty</MenuItem>
                            <MenuItem value={30}>Thirty</MenuItem> */}
                        </Select>
                    </FormControl>
                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                        <InputLabel id="demo-select-small-label">Liens</InputLabel>
                        <Select
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            value={age}
                            label="Liens"
                            onChange={handleChange}
                        >
                        </Select>
                    </FormControl>
                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                        <InputLabel id="demo-select-small-label">Dans le même thème</InputLabel>
                        <Select
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            value={age}
                            label="Dans le même thème"
                            onChange={handleChange}
                        >
                        </Select>
                    </FormControl>
                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                        <InputLabel id="demo-select-small-label">Classification</InputLabel>
                        <Select
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            value={age}
                            label="Classification"
                            onChange={handleChange}
                        >
                        </Select>
                    </FormControl>

                </div>

            </div>

            <Dialog
                open={openModal}
                aria-labelledby="form-dialog-title"
                fullWidth={true}
                maxWidth="sm"
                className="profil-detail"

            >
                <DialogTitle disableTypography id="form-dialog-title" style={{ paddingBottom: 1 }}>
                    <Typography style={{ color: '#616161', fontWeight: 600, fontSize: 14 }}>KIT SOLAIRE 850 W 230 V AUTOCONSOMMATION</Typography>
                    <Typography style={{ color: '#1565C0', fontWeight: 600, fontSize: 20 }}>Sélectionnez les formats et fichiers à télécharger</Typography>
                    <IconButton
                        aria-label="close"
                        style={{
                            position: 'absolute',
                            right: 5,
                            top: 5,
                            color: '#c0c0c0'
                        }}
                        onClick={() => {
                            setOpenModal(false)
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <hr style={{ color: "#1565C0" }} />
                </DialogTitle>
                <DialogContent style={{ overflowY: "inherit" }}>
                    <div className="d-flex flex-wrap p-2" style={{ gap: 10 }}>
                        <div className="d-flex align-items-center created-by mb-4 p-2" style={{ gap: 15, width: '31%', backgroundColor: checkedRevit === true ? "#E9F4FF" : "" }}>
                            <img alt="revit" src="/revit.png" />
                            <div className="d-flex flex-column">
                                <span style={{ fontSize: 14, color: '#616161', fontWeight: 500 }}>Revit</span>
                                <span style={{ fontSize: 14, color: '#616161' }}>2 fichiers</span>
                            </div>
                            <Checkbox
                                checked={checkedRevit}
                                onChange={handleChangeRevit}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </div>
                        <div className="d-flex align-items-center created-by mb-4 p-2" style={{ gap: 15, width: '35%', backgroundColor: checkedMicro === true ? "#E9F4FF" : "" }}>
                            <img alt="revit" src="/micro.png" />
                            <div className="d-flex flex-column">
                                <span style={{ fontSize: 14, color: '#616161', fontWeight: 500 }}>MicroStation</span>
                                <span style={{ fontSize: 14, color: '#616161' }}>0 fichiers</span>
                            </div>
                            <Checkbox
                                checked={checkedMicro}
                                onChange={handleChangeMicro}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </div>
                        <div className="d-flex align-items-center created-by mb-4 p-2" style={{ gap: 15, width: '30%', backgroundColor: checkedAuto === true ? "#E9F4FF" : "" }}>
                            <img alt="revit" src="/autocad.png" />
                            <div className="d-flex flex-column">
                                <span style={{ fontSize: 14, color: '#616161', fontWeight: 500 }}>AutoCAD</span>
                                <span style={{ fontSize: 14, color: '#616161' }}>1 fichiers</span>
                            </div>
                            <Checkbox
                                checked={checkedAuto}
                                onChange={handleChangeAuto}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </div>
                        <div className="d-flex align-items-center created-by mb-4 p-2" style={{ gap: 15, width: '31%', backgroundColor: checked3ds === true ? "#E9F4FF" : "" }}>
                            <img alt="revit" src="/edsmax.png" />
                            <div className="d-flex flex-column">
                                <span style={{ fontSize: 14, color: '#616161', fontWeight: 500 }}>3ds Max</span>
                                <span style={{ fontSize: 14, color: '#616161' }}>0 fichiers</span>
                            </div>
                            <Checkbox
                                checked={checked3ds}
                                onChange={handleChange3ds}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </div>
                        <div className="d-flex align-items-center created-by mb-4 p-2" style={{ gap: 15, width: '32%', backgroundColor: checkedSketch === true ? "#E9F4FF" : "" }}>
                            <img alt="revit" src="/sketch.png" />
                            <div className="d-flex flex-column">
                                <span style={{ fontSize: 14, color: '#616161', fontWeight: 500 }}>Sketchup</span>
                                <span style={{ fontSize: 14, color: '#616161' }}>1 fichiers</span>
                            </div>
                            <Checkbox
                                checked={checkedSketch}
                                onChange={handleChangeSketch}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </div>
                        <div className="d-flex align-items-center created-by mb-4 p-2" style={{ gap: 15, width: '33%', backgroundColor: checkedRinho === true ? "#E9F4FF" : "" }}>
                            <img alt="revit" src="/rinho.png" />
                            <div className="d-flex flex-column">
                                <span style={{ fontSize: 14, color: '#616161', fontWeight: 500 }}>Rhino</span>
                                <span style={{ fontSize: 14, color: '#616161' }}>0 fichiers</span>
                            </div>
                            <Checkbox
                                checked={checkedRinho}
                                onChange={handleChangeRinho}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </div>
                        <div className="d-flex align-items-center created-by mb-4 p-2" style={{ gap: 15, width: '31%', backgroundColor: checkedBlender === true ? "#E9F4FF" : "" }}>
                            <img alt="revit" src="/blender.png" />
                            <div className="d-flex flex-column">
                                <span style={{ fontSize: 14, color: '#616161', fontWeight: 500 }}>Blender</span>
                                <span style={{ fontSize: 14, color: '#616161' }}>3 fichiers</span>
                            </div>
                            <Checkbox
                                checked={checkedBlender}
                                onChange={handleChangeBlender}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </div>

                    </div>

                    <div className="file-download">
                        <span className="file-dispo">Fichiers disponibles</span>
                        <div className="card-file-download  mt-2">
                            <div className="d-flex py-2 px-3" style={{ gap: 20, backgroundColor: checkedRevit === true ? "#E9F4FF" : "" }}>
                                <Checkbox
                                    checked={checkedRevit}
                                    onChange={handleChangeRevit}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                                <div className="d-flex flex-column">
                                    <span className="span-file-download">REVIT</span>
                                    <span className="span-description-download">Solar kit 850W.rfa</span>
                                    <div className="px-2 mt-1 span-extension" style={{ borderRadius: 10, width: 60, backgroundColor: checkedRevit === true ? "#C3E1FF" : "#E9F4FF" }}>.rfa</div>
                                </div>
                            </div>
                        </div>
                        <div className="card-file-download  mt-2">
                            <div className="d-flex py-2 px-3" style={{ gap: 20, backgroundColor: checkedPdf === true ? "#E9F4FF" : "" }}>
                                <Checkbox
                                    checked={checkedPdf}
                                    onChange={handleChangePdf}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                                <div className="d-flex flex-column">
                                    <span className="span-file-download">PDF</span>
                                    <span className="span-description-download">Notice utilisation kit solaire.pdf</span>
                                    <div className="px-2 mt-1 span-extension" style={{ borderRadius: 10, width: 60, backgroundColor: checkedPdf === true ? "#C3E1FF" : "#E9F4FF" }}>.pdf</div>
                                </div>
                            </div>
                        </div>
                        <div className="card-file-download mt-2">
                            <div className="d-flex py-2 px-3" style={{ gap: 20, backgroundColor: checkedBlender === true ? "#E9F4FF" : "" }}>
                                <Checkbox
                                    checked={checkedBlender}
                                    onChange={handleChangeBlender}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                                <div className="d-flex flex-column">
                                    <span className="span-file-download">BLENDER</span>
                                    <span className="span-description-download">Solar kit low poly form.blend</span>
                                    <div className="px-2 mt-1 span-extension" style={{ borderRadius: 10, width: 60, backgroundColor: checkedBlender === true ? "#C3E1FF" : "#E9F4FF" }}>.blend</div>
                                </div>
                            </div>
                        </div>
                    </div>

                </DialogContent>

                <DialogActions style={{ padding: 20 }}>
                    <MuiButton
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                            setOpenModal(false)
                        }}
                        style={{ textTransform: 'capitalize', color: "#1565C0", width: "22%" }}
                    >
                        Fermer
                    </MuiButton>
                    <MuiButton
                        variant="contained"
                        style={{ textTransform: 'capitalize', color: "#fff", backgroundColor: "#1565C0", width: "72%", textTransform: "none" }}
                    >
                        Tout télécharger
                    </MuiButton>
                </DialogActions>

            </Dialog>


            <Dialog
                open={openModal1}
                aria-labelledby="form-dialog-title"
                fullWidth={true}
                maxWidth="sm"
                className="profil-detail"

            >
                <DialogTitle disableTypography id="form-dialog-title" style={{ paddingBottom: 1 }}>
                    <Typography style={{ color: '#616161', fontWeight: 600, fontSize: 14 }}>KIT SOLAIRE 850 W 230 V AUTOCONSOMMATION</Typography>
                    <Typography style={{ color: '#1565C0', fontWeight: 600, fontSize: 20 }}>Sélectionnez les formations souhaitées</Typography>
                    <IconButton
                        aria-label="close"
                        style={{
                            position: 'absolute',
                            right: 5,
                            top: 5,
                            color: '#c0c0c0'
                        }}
                        onClick={() => {
                            setOpenModal1(false)
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <hr style={{ color: "#1565C0" }} />
                </DialogTitle>
                <DialogContent style={{ overflowY: "inherit" }}>
                    <div className="file-apprendre">
                        <span className="file-dispo">Formations disponibles</span>
                        <div className="card-file-download  mt-2">
                            <div className="d-flex py-2 px-3" style={{ gap: 20, backgroundColor: checkedRevit === true ? "#E9F4FF" : "" }}>

                                <div className="d-flex flex-column">
                                    <span className="span-file-download">INSTALLATION SIMPLE EN AUTOCONSOMMATION</span>
                                    <span className="span-description-download">Apprenez à installer par vous même votre kit d’autoconsommation à la maison avec Bruno</span>
                                    <div className="d-flex" style={{ gap: 10 }}>
                                        <div className="px-2 py-1 mt-1 d-flex align-items-center span-extension" style={{ gap: 5, borderRadius: 10, width: 65, backgroundColor: checkedRevit === true ? "#C3E1FF" : "#E9F4FF" }}><AccessTimeIcon fontSize="small" />1h45</div>
                                        <div className="px-2 py-1 mt-1 d-flex align-items-center span-extension" onClick={(e)=>{window.open("https://www.youtube.com/watch?v=Mi9KPxU2t3E", "_blank")}} style={{ cursor:'pointer',gap: 5, borderRadius: 10, width: 85, backgroundColor: checkedRevit === true ? "#C3E1FF" : "#E9F4FF" }}><VisibilityIcon fontSize="small" />Youtube</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-file-download  mt-2">
                            <div className="d-flex py-2 px-3" style={{ gap: 20, backgroundColor: checkedPdf === true ? "#E9F4FF" : "" }}>

                                <div className="d-flex flex-column">
                                    <span className="span-file-download">CALCULER SON ROI EN 2 MINUTES</span>
                                    <span className="span-description-download">Olivier vous apprends à calculer votre retour sur investissement en quelques étapes</span>
                                    <div className="d-flex" style={{ gap: 10 }}>
                                        <div className="px-2 py-1 mt-1 d-flex align-items-center span-extension" style={{ gap: 5, borderRadius: 10, width: 75, backgroundColor: checkedRevit === true ? "#C3E1FF" : "#E9F4FF" }}><AccessTimeIcon fontSize="small" />5 mins</div>
                                        <div className="px-2 py-1 mt-1 d-flex align-items-center span-extension" onClick={(e)=>{window.open("https://www.youtube.com/watch?v=kdrZFMf9KYs", "_blank")}} style={{ cursor:'pointer', gap: 5, borderRadius: 10, width: 85, backgroundColor: checkedRevit === true ? "#C3E1FF" : "#E9F4FF" }}><VisibilityIcon fontSize="small" />Linkedin</div>
                                    </div>                              
                                      </div>
                            </div>
                        </div>
                        <div className="card-file-download mt-2">
                            <div className="d-flex py-2 px-3" style={{ gap: 20, backgroundColor: checkedBlender === true ? "#E9F4FF" : "" }}>

                                <div className="d-flex flex-column">
                                    <span className="span-file-download">PROTEGER SON ÉQUIPEMENT DE L’ORAGE</span>
                                    <span className="span-description-download">Sécurisez vos équipements solaires en écoutant les conseils d’un électricien certifié</span>
                                    <div className="d-flex" style={{ gap: 10 }}>
                                        <div className="px-2 py-1 mt-1 d-flex align-items-center span-extension" style={{ gap: 5, borderRadius: 10, width: 85, backgroundColor: checkedRevit === true ? "#C3E1FF" : "#E9F4FF" }}><AccessTimeIcon fontSize="small" />25 mins</div>
                                        <div className="px-2 py-1 mt-1 d-flex align-items-center span-extension" style={{ gap: 5, borderRadius: 10, width: 85, backgroundColor: checkedRevit === true ? "#C3E1FF" : "#E9F4FF" }}><VisibilityIcon fontSize="small" />Spotify</div>
                                    </div>                         
                                           </div>
                            </div>
                        </div>
                    </div>

                </DialogContent>

                <DialogActions style={{ padding: 20, justifyContent: 'end' }}>
                    <MuiButton
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                            setOpenModal1(false)
                        }}
                        style={{ textTransform: 'capitalize', color: "#1565C0", width: "22%" }}
                    >
                        Fermer
                    </MuiButton>
                </DialogActions>

            </Dialog>
        </div>
    )
}
export default Product;