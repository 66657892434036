exports.categoriesLogiciel = [
    {
        "label": "SketchUp"
    }, 
    {
        "label": "AutoCAD"
    },
    {
        "label": "Moodle"
    },
    {
        "label": "Revit"
    },
    {
        "label": "Rhino 3D"
    },
    {
        "label": "ArchiCAD"
    },
    {
        "label": "MicroStation"
    },
    {
        "label": "Vectorworks Architect"
    },
    {
        "label": "CATIA"
    },
    {
        "label": "BricsCAD"
    },
    {
        "label": "Chief Architect"
    },
    {
        "label": "Fusion 360 autodesk "
    },
    {
        "label": "3ds Max"
    },
    {
        "label": "Lumion"
    },
    {
        "label": "V-Ray"
    },
    {
        "label": "Cinema 4D"
    },
    {
        "label": "Blender"
    },
    {
        "label": "Twinmotion,  Epic Games,"
    },
    {
        "label": "Homestyler ( online )"
    },
    {
        "label": "Adobe Illustrator"
    },
    {
        "label": "CorelDRAW"
    },
    {
        "label": "Photoshop"
    },
    {
        "label": "Microsoft Project"
    },
    {
        "label": "Microsoft Teams "
    },
    {
        "label": "Slack"
    },
    {
        "label": "Asana "
    },
    {
        "label": "Trello "
    },
    {
        "label": "Primavera P6 "
    },
    {
        "label": "Smartsheet "
    },
    {
        "label": "Adobe InDesign "
    },
    {
        "label": "Sketch "
    },
    {
        "label": "Tableau "
    },
    {
        "label": "Power BI "
    },
    {
        "label": "Bluebeam Revu "
    },
    {
        "label": "Gestion de Projets BIM"
    },
    {
        "label": "Autodesk BIM 360"
    },
    {
        "label": "Procore"
    },
    {
        "label": "ONFLY de BIM&Co"
    },
    {
        "label": "Trimble Connect "
    },
    {
        "label": "Bentley Systems ProjectWise"
    },
    {
        "label": "Graphisoft BIMcloud"
    },
    {
        "label": "Newforma Project Center"
    },
    {
        "label": "Développement et Maintenance des Normes BIM"
    },
    {
        "label": "Solibri Model Checker"
    },
    {
        "label": "Qualité et Conformité BIM"
    },
    {
        "label": "Model Checker"
    },
    {
        "label": "BIMcollab"
    },
    {
        "label": "Coordination et Vérification de la Modélisation"
    },
    {
        "label": "Navisworks"
    },
    {
        "label": "Tekla BIMsight"
    },
    {
        "label": "Gestion des Données et Analyse BIM"
    },
    {
        "label": "Assemble Systems"
    },
    {
        "label": "dRofus"
    },
    {
        "label": "Intégration des Systèmes et Interopérabilité"
    },
    {
        "label": "FME (Feature Manipulation Engine)"
    },
    {
        "label": "Open BIM ToolBox"
    },
    {
        "label": "Technologie et Innovation BIM"
    },
    {
        "label": "Dynamo"
    },
    {
        "label": "Unity Reflec"
    },
    {
        "label": "Simulation et Analyse"
    },
    {
        "label": "IES VE"
    },
    {
        "label": "Autodesk CFD"
    },
    {
        "label": "Gestion de la Documentation BIM"
    },
    {
        "label": "Bluebeam Revu: G"
    },
    {
        "label": "Acone"
    },
    {
        "label": "Gestion des Actifs et Maintenance BIM"
    },
    {
        "label": "ARCHIBUS"
    },
    {
        "label": "IBM Maximo"
    },
    {
        "label": "Rôles Professionnels"
    },
    {
        "label": "Chef de projet BIM"
    },
    {
        "label": "Coordinateur BIM"
    },
    {
        "label": "Contrôle de Qualité et Vérification"
    },
    {
        "label": "Dessinateur BIM"
    },
    {
        "label": "Perspectiviste 3"
    },
    {
        "label": "Projeteur BIM"
    },
    {
        "label": "Formation et Support BIM"
    },
    {
        "label": "Lynda.com (LinkedIn Learning)"
    },
    {
        "label": "Pluralsight"
    },
    {
        "label": "Grasshopper (plugin pour Rhino): pour la conception paramétrique et algorithmique."
    },
    {
        "label": "Ladybug et Honeybee "
    },
    {
        "label": "ANSYS"
    },
    {
        "label": "Ecotect Analysis "
    },
    {
        "label": "SimScale"
    },
    {
        "label": "Autodesk CFD "
    },
    {
        "label": "EnergyPlus"
    },
    {
        "label": "DesignBuilder"
    },
    {
        "label": "IES VE (Integrated Environmental Solutions Virtual Environment) "
    },
    {
        "label": "eQUEST "
    },
    {
        "label": "OpenStudio "
    },
    {
        "label": "SimScale "
    },
    {
        "label": "TRNSYS (Transient System Simulation Tool) "
    },
    {
        "label": "AP (Hourly Analysis Program) par Carrier : U"
    },
    {
        "label": "Autodesk Green Building Studio "
    },
    {
        "label": "Dialux "
    },
    {
        "label": "Relux "
    },
    {
        "label": "AGi32 "
    },
    {
        "label": "LightTools "
    },
    {
        "label": "LumenRT "
    },
    {
        "label": "Luxicon "
    },
    {
        "label": "IES Virtual Environment (IESVE) "
    },
    {
        "label": "Photopia "
    },
    {
        "label": "OxyTech LiTGHT "
    },
    {
        "label": "Visual "
    },
    {
        "label": "Elecalc Trace Software"
    },
    {
        "label": "AutoCAD Electrical "
    },
    {
        "label": "ETAP (Electrical Transient Analyzer Program)"
    },
    {
        "label": "EPLAN Electric P8 "
    },
    {
        "label": "DIALux "
    },
    {
        "label": "Elecdes Design Suite"
    },
    {
        "label": "SEE Electrical "
    },
    {
        "label": "SolidWorks Electrical "
    },
    {
        "label": "SKM Power Tools"
    },
    {
        "label": "Revit MEP (Mechanical, Electrical, and Plumbing)"
    },
    {
        "label": "Cinema 4D "
    },
    {
        "label": "Rhinoceros 3D (Rhino) "
    },
    {
        "label": "ZBrush"
    },
    {
        "label": "AutoCAD Civil 3D "
    },
    {
        "label": "Lumion "
    },
    {
        "label": "GIS (Geographic Information System) Software : QGIS"
    },
    {
        "label": "GIS ArcGIS"
    },
    {
        "label": "AutoCAD Map 3D "
    },
    {
        "label": "CityEngine "
    },
    {
        "label": "InfraWorks "
    },
    {
        "label": "SketchUp ((avec des extensions comme PlaceMaker) "
    },
    {
        "label": "Grasshopper (pour Rhino) "
    },
    {
        "label": "ENVI-met "
    },
    {
        "label": "Adobe Creative Suite"
    },
    {
        "label": "PowerPoint: pour les présentations classiques."
    },
    {
        "label": "Canva"
    }
]