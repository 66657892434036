import React, { useEffect } from "react";
import MuiBackdrop from "../../../components/Loading/MuiBackdrop";
import { apiNodeService } from "../../../provider/apiNodeService";
import moment from "moment";
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { maillingService } from "../../../provider/maillingService";
import Avatar from '@mui/material/Avatar';
import { Chip } from '@material-ui/core'
import TextField from '@mui/material/TextField';
import { useNavigate } from "react-router-dom";

const Offre_Payment = () => {

    const navigate = useNavigate();

    const [loading, setLoading] = React.useState(false);
    const [offre, setOffre] = React.useState(null);
    const [email, setEmail] = React.useState("");
    const [step, setStep] = React.useState(1);
    const [user, setUser] = React.useState(null);
    const [userEmploye, setUserEmploye] = React.useState(null);
    const [montant, setMontant] = React.useState(0);

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const id = searchParams.get('id');
        const mail = searchParams.get('email');
        setEmail(mail)
        getOffreById(id)
        getUserByEmail(mail)
        getUserByEmail1(mail)
    }, []);

    const getOffreById = async (id) => {
        setLoading(true)
        return new Promise(resolve => {
            apiNodeService.filterTable({ table: "mission", filter: { id: id }, db: "FREELANCE_MAD" }).then(userRes => {
                console.log(userRes)
                if (userRes && userRes !== "false" && Array.isArray(userRes.data)) {
                    console.log(userRes.data[0])
                    setOffre(userRes.data[0])
                    setLoading(false)
                    resolve(userRes.data[0])
                } else {
                    setLoading(false)
                    resolve("false")
                }
            }).catch(err => resolve("false"))
        })
    }

    const updateOffre = () => {
        setLoading(true)
        let data = {}
        data.freelancer_choosen = email
        console.log(data)
        apiNodeService.updateTableData({ db: "FREELANCE_MAD", table: "offre", id: offre.id, data: data }).then(resAdd => {
            console.log(resAdd)
            if (resAdd && resAdd.status === 200) {
                localStorage.setItem("email",offre.email)
                setStep(2)
                setLoading(false)
            } else {
                setLoading(false)
            }
        })
    }

    const sendEmail = () => {
        const mailData = {
            emailFrom: "dev.rocketbonds@gmail.com",
            nameFrom: "FreelanceMada",
            to: [
                {
                    Email: offre.email,
                    Name: ""
                }
            ],
            subject: "Bon de Commande et Facture d'Acompte pour Votre Mission",
            htmlText: 'Bonjour ' + offre.name + ', <br/> <br/> ' +
                "Nous avons le plaisir de vous informer qu'un freelanceur a accepté votre mission." +
                '<br/>' +
                "Nous vous avons également joint une facture d'acompte correspondant à 30% du coût total de la mission. Vous pouvez effectuer le paiement en cliquant sur le lien suivant : " +
                '<br/>' +
                "<a href=" + "https://www.freelancemada.ovh/offre-payment?id=" + offre.id + "&email=" + email + ">Lien de Paiement.</b></a>" +
                '<br/>' +
                "Votre prompt paiement nous permettra de commencer le travail sur votre projet dans les meilleurs délais." +
                '<br/><br/>' +
                "Cordialement." +
                '<br/><br/>' +
                '<br/><br/>FreelanceMada'
        };

        maillingService.sendMailByHtmlText(mailData).then(res => {
            console.log(res)
        }).catch(err => {
            console.log(err)
        })
    }

    const getUserByEmail = async (email) => {
        return new Promise(resolve => {
            apiNodeService.filterTable({ table: "user_build", filter: { email: email }, db: "FREELANCE_MAD" }).then(userRes => {
                console.log(userRes)
                if (userRes && userRes !== "false" && Array.isArray(userRes.data)) {
                    setUser(userRes.data[0])
                } else {
                    resolve("false")
                }
            }).catch(err => resolve("false"))
        })
    }

    const getUserByEmail1 = async (email) => {
        return new Promise(resolve => {
            apiNodeService.filterTable({ table: "employe_odoo", filter: { email: email }, db: "FREELANCE_MAD" }).then(userRes => {
                console.log(userRes)
                if (userRes && userRes !== "false" && Array.isArray(userRes.data)) {
                    setUserEmploye(userRes.data[0])
                } else {
                    resolve("false")
                }
            }).catch(err => resolve("false"))
        })
    }

    return (
        <div>
            <MuiBackdrop open={loading} text={"Chargement..."} />
            {/* <div className="header-client">
                <div className="d-flex justify-content-between align-items-center" style={{ height: "100%" }}>
                    <div className="d-flex px-2">
                        <span style={{ fontSize: 34, fontWeight: 700 }}>Build.</span><span style={{ fontSize: 34, color: "#214dcb", fontWeight: 700 }}>Mada</span>
                    </div>
                </div>
            </div> */}

            <div className="body-offre p-4" style={{ marginTop: 90 }}>
                <div className="row">
                    {step === 1 ?
                        <div className="col-md-6 px-3">
                            {offre &&
                                <div className="d-flex flex-column" style={{ gap: 10 }}>
                                    <span style={{ fontSize: 22, fontWeight: 700, fontFamily: "Montserrat", textDecoration: "underline" }}>Mission :</span>
                                    <div className="d-flex align-items-end" style={{ gap: 5 }}>
                                        <span style={{ fontSize: 18, fontWeight: 700, fontFamily: "Montserrat" }}>Titre: </span>
                                        <span style={{ fontSize: 16, fontWeight: 500, fontFamily: "Montserrat" }}> {offre.title} </span>
                                    </div>
                                    <div className="d-flex align-items-end" style={{ gap: 5 }}>
                                        <span style={{ fontSize: 18, fontWeight: 700, fontFamily: "Montserrat" }}>Date de création: </span>
                                        <span style={{ fontSize: 16, fontWeight: 500, fontFamily: "Montserrat" }}> {moment(offre.created_at).format("YYYY/MM/DD")} </span>
                                    </div>
                                    {/* <div className="d-flex align-items-end" style={{ gap: 5 }}>
                                        <span style={{ fontSize: 18, fontWeight: 700, fontFamily: "Montserrat" }}>Tarif: </span>
                                        <span style={{ fontSize: 16, fontWeight: 500, fontFamily: "Montserrat" }}> {offre.montant} Ar</span>
                                    </div> */}
                                    <div className="d-flex align-items-start" style={{ gap: 5 }}>
                                        <span style={{ fontSize: 18, fontWeight: 700, fontFamily: "Montserrat" }}>Description: </span>
                                        <span style={{ fontSize: 16, fontWeight: 500, fontFamily: "Montserrat" }}> {offre.description} </span>
                                    </div>
                                </div>
                            }
                        </div>
                        :
                        <div className="col-md-6 px-3">
                            <div className="d-flex flex-column">
                                <div className="d-flex align-items-center mb-3" style={{ gap: 10 }}>
                                    <TaskAltIcon color="success" fontSize="large" />
                                    <span className="span-title-freemada">Entrez le montant à payer</span>
                                </div>
                                <TextField
                                    style={{ width: 150 }}
                                    onChange={(e) => { setMontant(e.target.value) }}
                                    value={montant}
                                    size="small"
                                    id="outlined-basic99"
                                    label="Montant"
                                    variant="outlined"
                                />
                            </div>

                        </div>
                    }
                    {user &&
                        <div className="col-md-6 px-3">
                            <div className="d-flex flex-column" style={{ gap: 10 }}>
                                <span style={{ fontSize: 22, fontWeight: 700, fontFamily: "Montserrat", textDecoration: "underline" }}>Freelanceur :</span>

                                <div className="d-flex align-items-center" style={{ gap: 10 }}>
                                    {user.photo ?
                                        <Avatar
                                            alt="Remy Sharp"
                                            className="avatar-profil"
                                            src={user.photo.prefix + user.photo.data}
                                        />
                                        : <Avatar className="avatar-profil">
                                            {user.first_name[0]}
                                        </Avatar>
                                    }
                                    <div className="d-flex flex-column" >
                                        <span style={{ fontSize: 16, fontWeight: 700, fontFamily: "Montserrat" }}> {user.first_name}  {user.last_name}</span>
                                        <span style={{ fontSize: 16, fontWeight: 500, fontFamily: "Montserrat" }}> {user.email} </span>
                                    </div>
                                </div>
                                {user.description && <span style={{ fontSize: 16, fontWeight: 500, fontFamily: "Montserrat" }}>{user.description} </span>}
                                {user.pays && <span style={{ fontSize: 16, fontWeight: 500, fontFamily: "Montserrat" }}> Pays: {user.pays.label} </span>}
                                {userEmploye &&
                                    <div>
                                        {userEmploye.logiciel &&
                                            <div className="d-flex flex-wrap mt-2" style={{ gap: 4 }}>
                                                {(userEmploye.logiciel || []).map((competence, key1) => (
                                                    <Chip size="small" style={{ color: "#1565C0", borderColor: "#1565C0" }} variant="outlined" key={key1} label={competence} />
                                                ))
                                                }
                                            </div>
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                    }
                </div>

                {user &&
                    <div className="d-flex justify-content-center mt-4 mb-3" style={{ width: "100%" }}>
                        <button className="create-client-button" onClick={(e) => {
                            step === 1 ? updateOffre() : navigate("/stripe/payment/" + montant +'?id='+offre.id);
                        }}>
                            Confirmer et payer
                        </button>
                    </div>
                }

            </div>
        </div>
    )
}
export default Offre_Payment;