
export const menu_items2 = [
    {
        id:11,
        uid:"support",
        title: "Support",
        icon: "bx bx-message-detail",
        menu:[]
    },
    {
        id:12,
        uid:"parametres",
        title: "Paramètres",
        icon: "bx bx-cog",
        menu:[]
    }
]




