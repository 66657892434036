import React, { useEffect, useState } from "react";
import MuiBackdrop from "../../components/Loading/MuiBackdrop";
import { apiNodeService } from "../../provider/apiNodeService";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { Button as MuiButton, Chip } from '@material-ui/core'
import IconButton from "@material-ui/core/IconButton";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import Competence_Logiciel from "./Competence_Logiciel";
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import DoneIcon from '@mui/icons-material/Done';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import moment from "moment";
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import "./Profile_List.css"
import rethink from "../../controller/rethink";
import utilFunctions from "../../tools/functions";
import { useNavigate } from "react-router-dom";
import { maillingService } from "../../provider/maillingService";
import categoriesMetier from "../../data/categoriesMetier";
import categoriesLogiciel from "../../data/categoriesLogiciel";
import Autocomplete from '@mui/material/Autocomplete';
import SearchIcon from '@mui/icons-material/Search';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import Skeleton from '@mui/material/Skeleton';

const Mission_List = () => {

    const navigate = useNavigate();

    const [missions, setMissions] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [openMissionModal, setOpenMissionModal] = React.useState(false);
    const [requiredValues, setRequiredValues] = React.useState(false)
    const [title, setTitle] = React.useState("")
    const [description, setDescription] = React.useState("")
    const [localisation, setLocalisation] = React.useState("")
    const [selectedValues1, setSelectedValues1] = React.useState([]);
    const [selectedNodes1, setSelectedNodes1] = React.useState({});
    const [niveauExperience, setNiveauExperience] = React.useState('');
    const [montant, setMontant] = React.useState("")
    const [montantEuro, setMontantEuro] = React.useState("")
    const [alertSuccess, setAlertSuccess] = React.useState(false)
    const [alertError, setAlertError] = React.useState(false)
    const [alertText, setAlertText] = React.useState("");
    const [selectedValuesMetiers, setSelectedMetiers] = React.useState([]);
    const [selectedValuesLogiciel, setSelectedLogiciel] = React.useState([]);
    const [filteredData, setFilteredData] = useState(null);
    const [logicielFilter, setLogicielFilter] = useState('');
    const [filteredDataMontant, setFilteredDataMontant] = useState(null);
    const [checkedPrice, setCheckedPrice] = React.useState('0,100000000');
    const [filteredDataNiveau, setFilteredDataNiveau] = useState(null);
    const [checkedNiveau, setCheckedNiveau] = React.useState(0);
    const pageTopRef = React.useRef(null);
    const [checkedTri, setCheckedTri] = React.useState("Plus récent");
    const [options, setOptions] = useState(categoriesLogiciel.categoriesLogiciel.map((option) => option.label));
    const [options1, setOptions1] = useState(categoriesMetier.categoriesMetier.map((option) => option.label));

    const resetValues = () => {
        setTitle("")
        setLocalisation("")
        setDescription("")
        setSelectedValues1([])
        setNiveauExperience("")
        setMontant("")
        setMontantEuro("")
        setRequiredValues(false)
    }

    const handleInputChange = (event) => {
        const newValue = event.target.value;
        if (newValue.trim() !== '' && !options.includes(newValue)) {
            // Si la nouvelle valeur n'existe pas dans la liste, l'ajouter aux options
            setOptions([...options, newValue]);
        }
    };

    const handleInputChange1 = (event) => {
        const newValue = event.target.value;
        if (newValue.trim() !== '' && !options1.includes(newValue)) {
            // Si la nouvelle valeur n'existe pas dans la liste, l'ajouter aux options
            setOptions1([...options1, newValue]);
        }
    };

    const addMission = () => {
        if (description === "" || title === "" || montant === "" || montantEuro === "" || localisation === "" || niveauExperience === "") {
            setRequiredValues(true)
            return
        }
        const newCompetences = selectedValuesLogiciel.concat(selectedValuesMetiers)
        let data = {
            title: title,
            description: description,
            localisation: localisation,
            competence: newCompetences,
            niveau: niveauExperience,
            montant: montant,
            email: localStorage.getItem('email'),
            created_at: moment(),
            accepted: false,
            freelancer: ""
        }
        console.log(data)
        apiNodeService.insertTableData({ db: "FREELANCE_MAD", table: "mission", data: data }).then(res => {
            console.log(res)
            if (res && res.status === 200) {
                setLoading(false)
                getFreelancers(res.data.id)
                getMissions()
                setAlertText("La mission a bien été enregistré et sera publiée après vérification par notre équipe")
                setOpenMissionModal(false)
                setAlertSuccess(true)
                setRequiredValues(false)
                resetValues()
                setTimeout(() => {
                    setAlertSuccess(false)
                }, 5000)
            } else {
                console.log("erreur")
                setAlertText("La mission n'a pas pu être enregistré, Veuillez ressayer ou contacter le support technique")
                setOpenMissionModal(false)
                setLoading(false)
                setAlertError(true)
                setRequiredValues(false)
                setTimeout(() => {
                    setAlertError(false)
                }, 5000)
            }
        })
    }
    useEffect(() => {
        moment.updateLocale('fr', {
            relativeTime: {
                future: 'dans %s',
                past: 'il y a %s',
                s: 'quelques secondes',
                m: 'une minute',
                mm: '%d minutes',
                h: 'une heure',
                hh: '%d heures',
                d: 'un jour',
                dd: '%d jours',
                M: 'un mois',
                MM: '%d mois',
                y: 'un an',
                yy: '%d ans',
            },
        })
        getMissions()

    }, []);

    const getMissions = () => {
        setLoading(true)
        apiNodeService.getTableData({ db: "FREELANCE_MAD", table: "mission" }).then((res) => {
            console.log(res)
            if (res && res.status === 200) {
                let missionsValid = res.data.filter(item => item.accepted === false && !item.signed)
                const sortedData = missionsValid.sort((a, b) => {
                    return moment(b.created_at).diff(moment(a.created_at));
                });
                setMissions([...sortedData])
                setFilteredData([...sortedData])
                setFilteredDataMontant([...sortedData])
                setFilteredDataNiveau([...sortedData])
                console.log(sortedData)
            }
            setLoading(false)
        })
    }

    const handleFilterChange = (event) => {
        const { value } = event.target;
        setLogicielFilter(value);
        filterByLogiciel(value);
    };

    const filterByLogiciel = (filterValue) => {
        console.log(filterValue)
        const filteredArray = missions.filter(item =>
            !filterValue || // Added check for empty filter value
            (item.competence &&
                item.competence.some(competence =>
                    (competence.toLowerCase().replace(/é/g, 'e')).includes(filterValue.toLowerCase().replace(/é/g, 'e'))
                ))
            || (item.title.toLowerCase().replace(/é/g, 'e')).includes(filterValue.toLowerCase().replace(/é/g, 'e'))
            || (item.description.toLowerCase().replace(/é/g, 'e')).includes(filterValue.toLowerCase().replace(/é/g, 'e')))
        console.log(filteredArray)
        setFilteredData(filteredArray);
        setFilteredDataMontant(filteredArray)
        filterByMontant(checkedPrice, filteredArray)
    };

    const filterByMontant = (value, data) => {
        console.log(filteredData);
        let defaultValue = '0,100000000'
        let filterValue = value ? value.split(',').map(Number) : defaultValue.split(',').map(Number);
        console.log(filterValue)
        const filteredArray = data.filter(item => {
            if (!filterValue) {
                return true; // Retourne true si aucun filtre n'est défini
            }

            if (filterValue[0] === 0) {
                // Retourne true si le filtre est défini à 0 (inclut les objets sans montant)
                return !item.montant || (item.montant >= filterValue[0] && item.montant <= filterValue[1]);
            }

            // Retourne true si le montant de l'élément est dans la plage spécifiée
            return item.montant && (item.montant >= filterValue[0] && item.montant <= filterValue[1]);
        });

        console.log(filteredArray);
        setFilteredDataMontant(filteredArray);
        filterByNiveau(checkedNiveau, filteredArray)
    };

    const filterByNiveau = (filterValue, data) => {
        console.log(filterValue);

        const filteredArray = data.filter(item => {
            if (!filterValue) {
                return true;
            }

            if (filterValue === '' || 0) {
                // Retourne true si le filtre est défini à 0 (inclut les objets sans montant)
                return !item.niveau || item.niveau;
            }

            // Retourne true si le montant de l'élément est dans la plage spécifiée
            return item.niveau && (item.niveau === filterValue);
        });

        console.log(filteredArray);
        setFilteredDataNiveau(filteredArray);
        handleChangeTriDefault(checkedTri, filteredArray)
    };

    const handleChangeMontant = (event) => {
        console.log(event.target.value)
        setCheckedPrice(event.target.value ? event.target.value : '0,100000000');
        filterByMontant(event.target.value, filteredData)
    };

    const handleChangeTri = (event) => {
        let tri = event.target ? event.target.value : event
        setCheckedTri(tri);
        if (tri === "Plus récent") {
            const sortedData = filteredDataNiveau.sort((a, b) => {
                return moment(b.created_at).diff(moment(a.created_at));
            });
            setFilteredDataNiveau(sortedData)
        } else if (tri === "Plus ancien") {
            const sortedData = filteredDataNiveau.sort((a, b) => {
                return moment(a.created_at).diff(moment(b.created_at));
            });
            setFilteredDataNiveau(sortedData)
        } else if (tri === "Moins cher") {
            const sortedData = filteredDataNiveau.sort(function (a, b) {
                return a.montant - b.montant;
            });
            setFilteredDataNiveau(sortedData)
        } else if (tri === "Plus cher") {
            const sortedData = filteredDataNiveau.sort(function (a, b) {
                return b.montant - a.montant;
            });
            setFilteredDataNiveau(sortedData)
        }

    };

    const handleChangeTriDefault = (value, array) => {
        let tri = value
        setCheckedTri(tri);
        if (tri === "Plus récent") {
            const sortedData = array.sort((a, b) => {
                return moment(b.created_at).diff(moment(a.created_at));
            });
            setFilteredDataNiveau(sortedData)
        } else if (tri === "Plus ancien") {
            const sortedData = array.sort((a, b) => {
                return moment(a.created_at).diff(moment(b.created_at));
            });
            setFilteredDataNiveau(sortedData)
        } else if (tri === "Moins cher") {
            const sortedData = array.sort(function (a, b) {
                return a.montant - b.montant;
            });
            setFilteredDataNiveau(sortedData)
        } else if (tri === "Plus cher") {
            const sortedData = array.sort(function (a, b) {
                return b.montant - a.montant;
            });
            setFilteredDataNiveau(sortedData)
        }

    };

    const scrollToTop = () => {
        console.log('hi to scroll')
        pageTopRef.current.scrollTo(0, 0)
    };

    const createRoom = async (email, id, nameM) => {
        console.log(email)
        let porteur = await getUserByEmail1(email)
        let freelancer = await getUserByEmail1(localStorage.getItem("email"))
        setLoading(true)
        let newRoom = {
            uid: utilFunctions.getUID(),
            title: nameM,
            desc: "",
            color: "",
            porteur_first_name: porteur.first_name,
            porteur_last_name: porteur.last_name,
            freelancer_first_name: freelancer.first_name,
            freelancer_last_name: freelancer.last_name,
            mission_id: id,
            created_by: localStorage.getItem('email'),
            created_at: moment().toDate(),
            members: [email, localStorage.getItem("email")],
            offre_state: "en cours"
        }
        rethink.insert("FREELANCE_MAD", 'rooms', newRoom).then(resAdd => {
            console.log(resAdd)
            if (resAdd && resAdd === true) {
                console.log(resAdd)
                navigate('/discuss/groups?room=' + newRoom.uid)
                setLoading(false)
            } else {
                console.log('erreur')
                setLoading(false)
            }
        })
    }

    const getFreelancers = (id) => {
        apiNodeService.getTableData({ db: "FREELANCE_MAD", table: "employe_odoo" }).then((res) => {
            console.log(res)
            if (res && res.status === 200) {
                res.data.map((item, key) => {
                    sendEmail(item.email, id, item.first_name)
                })
                console.log(res)
            }
            setLoading(false)
        })
    }

    const sendEmail = (mail, id, name) => {
        console.log(id)
        const mailData = {
            emailFrom: "dev.rocketbonds@gmail.com",
            nameFrom: "FreelanceMada",
            to: [
                {
                    Email: mail,
                    Name: ""
                }
            ],
            subject: "Nouvelle Mission Disponible : " + title,
            htmlText: 'Bonjour ' + name + ', <br/> <br/> ' +
                "Une nouvelle mission qui pourrait correspondre à vos compétences est disponible : " + title + ". Le client propose un budget de " + montant + " ariary /h." +
                '<br/>' +
                "Veuillez cliquer sur le lien ci-dessous pour consulter la mission." +
                '<br/>' +
                "<a href=" + "https://www.freelancemada.ovh" + ">lien.</b></a>" +
                '<br/><br/>' +
                '<br/><br/>FreelanceMada'
        };

        maillingService.sendMailByHtmlText(mailData).then(res => {
            console.log(res)
            resetValues()
        }).catch(err => {
            console.log(err)
        })
    }

    const getUserByEmail1 = async (email) => {
        return new Promise(resolve => {
            apiNodeService.filterTable({ table: "user_build", filter: { email: email }, db: "FREELANCE_MAD" }).then(userRes => {
                console.log(userRes)
                if (userRes && userRes !== "false" && Array.isArray(userRes.data)) {
                    console.log(userRes.data[0])
                    resolve(userRes.data[0])
                } else {
                    resolve("false")
                }
            }).catch(err => resolve("false"))
        })
    }

    const resetFilter = () => {
        setCheckedPrice('0,100000000')
        setCheckedNiveau(0)
        setCheckedTri("Plus récent")
        setFilteredDataNiveau(missions)
    }

    return (
        <>
            <MuiBackdrop open={loading} text={"Chargement..."} />
            {alertSuccess &&
                <Alert style={{ marginTop: 35 }} severity="success">
                    {alertText}
                </Alert>}
            {alertError &&
                <Alert severity="error">
                    Erreur, Veuillez réessayer plus tard !
                </Alert>}
            <div ref={pageTopRef} className="mission-page p-3" style={{ marginTop: 40, overflowY: 'auto', maxHeight: '85vh', overflowX: 'hidden' }}>


                <div>
                    <div className="title-equipe d-flex justify-content-between mb-4">
                        <span>
                            {localStorage.getItem("role") !== "porteur" ? "Postuler à une mission" : "Missions"}
                        </span>
                        {localStorage.getItem("role") === "porteur" &&
                            <button className="btn-add-mission" onClick={(e) => { setOpenMissionModal(true) }}>
                                <strong>+ </strong>Ajouter une mission
                            </button>
                        }
                    </div>
                    <hr style={{ color: "#7293b3" }} className="mb-4" />
                </div>

                <div className="d-flex justify-content-start mb-2">
                    <div className="d-flex flex-column" style={{ gap: 5 }}>
                        <TextField
                            value={logicielFilter}
                            onChange={handleFilterChange}
                            id="input-with-icon-textfield"
                            placeholder="Rechercher par compétences, logiciels ou métiers"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon style={{ color: "#1565C0" }} />
                                    </InputAdornment>
                                ),
                            }}
                            style={{ width: 500 }}
                            variant="outlined"
                            size="small"
                        />
                        {/* <span className="span-title mb-2" style={{ fontSize: 12, color: "#b1b1b1" }}>Chercher par compétences</span> */}
                    </div>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center mb-4" style={{ gap: 10 }}>
                        <FormControl sx={{ minWidth: 120 }} size="small">

                            <Select
                                autoWidth
                                labelId="demo-select-small-label"
                                id="demo-select-small"
                                value={checkedPrice}
                                onChange={handleChangeMontant}
                            >
                                <MenuItem disabled value={'0,100000000'}>
                                    Prix
                                </MenuItem>
                                <MenuItem value={'0,1000'}>0 - 1000 MGA/h</MenuItem>
                                <MenuItem value={'1000,10000'}>1000 - 10000 MGA/h</MenuItem>
                                <MenuItem value={'10000,100000000'}>10000+ MGA/h</MenuItem>
                                <MenuItem onClick={(e) => { setCheckedPrice('0,100000000') }}>
                                    Tous
                                </MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl sx={{ minWidth: 120 }} size="small">
                            <Select
                                labelId="demo-select-small-label"
                                id="demo-select-small"
                                value={checkedNiveau}
                                onChange={(e) => {
                                    let selected = e.target.value ? e.target.value : 0
                                    setCheckedNiveau(selected)
                                    console.log(e.target.value)
                                    filterByNiveau(selected, filteredData)
                                }}
                            >
                                <MenuItem disabled value={0}>Catégories</MenuItem>
                                <MenuItem value={"Junior"}>Junior</MenuItem>
                                <MenuItem value={"Confirmer"}>Confirmer</MenuItem>
                                <MenuItem value={"Senior"}>Senior</MenuItem>
                                <MenuItem value={"Expert"}>Expert</MenuItem>
                                <MenuItem onClick={(e) => { setCheckedNiveau(0) }}>Tous</MenuItem>
                            </Select>
                        </FormControl>

                        {(checkedPrice !== "0,100000000" || checkedNiveau !== 0) &&
                            <div className="d-flex align-items-center" style={{ gap: 5, cursor: 'pointer', marginLeft: 10 }} onClick={resetFilter}>
                                <RestartAltIcon style={{ color: "#1565C0", width: 24, height: 24 }} />
                                <span style={{ color: "#1565C0", fontSize: 15, fontWeight: 500 }}>Réinitialiser les filtres</span>
                            </div>}
                    </div>
                    <div className="d-flex align-items-center mb-4" style={{ gap: 10 }}>
                        <span>Trier par</span>
                        <FormControl sx={{ minWidth: 120 }} size="small">

                            <Select
                                autoWidth
                                labelId="demo-select-small-label"
                                id="demo-select-small"
                                value={checkedTri}
                                onChange={handleChangeTri}
                            >
                                <MenuItem value={'Plus récent'}>Plus récent</MenuItem>
                                <MenuItem value={'Plus ancien'}>Plus ancien</MenuItem>
                                <MenuItem value={'Moins cher'}>Moins cher</MenuItem>
                                <MenuItem value={'Plus cher'}>Plus cher</MenuItem>

                            </Select>
                        </FormControl>
                    </div>
                </div>

                {(loading === true && !filteredDataNiveau) &&
                            <div className="row px-2">
                                <div className="col-md-12">
                                    <Skeleton className="mt-3" variant="rectangular" width={"100%"} height={160} />
                                </div>
                                <div className="col-md-12">
                                    <Skeleton className="mt-3" variant="rectangular" width={"100%"} height={160} />
                                </div>
                            </div>
                        }

                {
                    filteredDataNiveau &&
                    <div className="d-flex flex-column" style={{ gap: 20 }}>
                        {filteredDataNiveau.map((item, key) => (
                            <div key={key}>
                                <div className="p-3 card-profil">
                                    <div className="d-flex justify-content-between">
                                        <span className="span-avatar" style={{cursor:'pointer'}} >{item.title}</span>
                                        <div className="d-flex flex-column justify-content-end">
                                            <span className="span-avatar">{item.montant} MGA</span>
                                            {parseInt(item.montant) > 0 && <span className="span-avatar" style={{ fontSize: 16, textAlign: "right" }}>{(parseInt(item.montant) / 4870).toFixed(2)} EUROS</span>}
                                        </div>
                                    </div>
                                    <div className="mt-2">
                                        <span style={{ fontFamily: 'Montserrat', fontSize: 14, fontWeight: 700 }}>{item.description}</span>
                                    </div>
                                    {item.competence &&
                                        <div className="d-flex flex-wrap mt-2" style={{ gap: 4 }}>
                                            {(item.competence || []).map((logiciel, key1) => (
                                                <div key={key1}
                                                    onClick={(e) => {
                                                        setLogicielFilter(logiciel)
                                                        filterByLogiciel(logiciel)
                                                        scrollToTop()
                                                    }}>
                                                    <Chip size="small" style={{ color: "#1565C0", borderColor: "#1565C0", cursor: "pointer" }}

                                                        variant="outlined" label={logiciel} />
                                                </div>
                                            ))
                                            }
                                        </div>
                                    }
                                    <div className="d-flex justify-content-between mt-2">
                                        <div>
                                            <span style={{ fontFamily: 'Montserrat', fontSize: 13, fontWeight: 700 }}>Niveau d'expérience: </span>
                                            <span style={{ fontSize: 13, fontWeight: 700 }}>{item.niveau}</span>
                                        </div>
                                        {localStorage.getItem("role") === "freelance" &&
                                            <div className="d-flex align-items-center" style={{ gap: 8 }}>
                                                {/* <Button style={{ color: "grey", borderColor: "grey" }} variant="outlined" className="btn-profile" startIcon={<LocationOnOutlinedIcon />}>
                                                    {item.localisation}
                                                </Button> */}
                                                <Button onClick={(e) => { createRoom(item.email, item.id, item.title) }} className="btn-profile" variant="contained" startIcon={<SendOutlinedIcon />}>
                                                    Contacter le porteur du projet
                                                </Button>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div style={{ fontSize: 12, color: 'grey' }}>
                                    {moment(item.created_at).fromNow()}
                                </div>
                            </div>
                        ))
                        }
                    </div>
                }

            </div>
            <Dialog
                open={openMissionModal}
                aria-labelledby="form-dialog-title"
                fullWidth={true}
                maxWidth="sm"
                onClose={(e) => {
                    setOpenMissionModal(false)
                }}
            >
                <DialogTitle id="form-dialog-title" style={{ paddingBottom: 1 }}>
                    <Typography style={{ color: '#1565C0', fontWeight: 600 }}>+ Informations générales de la mission</Typography>
                    <IconButton
                        aria-label="close"
                        style={{
                            position: 'absolute',
                            right: 5,
                            top: 5,
                            color: '#c0c0c0'
                        }}
                        onClick={() => {
                            setOpenMissionModal(false)
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <hr style={{ color: "#1565C0" }} />
                </DialogTitle>
                <DialogContent style={{ overflowY: "inherit" }}>
                    <div className="d-flex flex-column mission-add">
                        <span style={{ fontSize: 14, color: "#b1b1b1", fontWeight: 700 }} className="span-title mt-1">* = Champs obligatoires</span>

                        <span className="span-title mb-2 mt-3">Titre de la mission*</span>
                        <TextField fullWidth size="small" id="outlined-basic" variant="outlined"
                            value={title}
                            onChange={(e) => {
                                setTitle(e.target.value)
                            }} />
                        <div className="d-flex justify-content-end">
                            <span style={{ fontSize: 12, color: title.length > 100 ? 'red' : "#b1b1b1" }} className="span-title mt-1">{title.length} / 100 caractères</span>
                        </div>
                        <div className="d-flex justify-content-start">
                            <span className="span-title mb-2" style={{ fontSize: 12, color: "#b1b1b1" }}>Un bon titre c'est quelques mots pour decrire précisément l'objectif de votre projet</span>
                        </div>
                        <span className="span-title mb-2 mt-2">Courte description de la mission*</span>
                        <TextField fullWidth size="small" id="outlined-basic" variant="outlined"
                            value={description}
                            onChange={(e) => {
                                setDescription(e.target.value)
                            }} />
                        <div className="d-flex justify-content-end">
                            <span style={{ fontSize: 12, color: description.length > 500 ? 'red' : "#b1b1b1" }} className="span-title mt-1">{description.length} / 500 caractères</span>
                        </div>
                        <div className="d-flex justify-content-start">
                            <span className="span-title mb-2" style={{ fontSize: 12, color: "#b1b1b1" }}>Cette description sera visible sur la liste des missions.</span>
                        </div>
                        <span className="span-title mb-2 mt-2">Localisation de la mission*</span>
                        <TextField fullWidth size="small" id="outlined-basic" variant="outlined"
                            value={localisation}
                            onChange={(e) => {
                                setLocalisation(e.target.value)
                            }} />
                        <div className="d-flex justify-content-start mt-1">
                            <span className="span-title mb-2" style={{ fontSize: 12, color: "#b1b1b1" }}>Si la mission est en télétravail, mettre le lieu de votre société ou de chez vous.</span>
                        </div>
                        <span className="span-title mb-1 mt-2">Compétences informatiques requises</span>
                        <Autocomplete
                            multiple
                            fontSize="small"
                            className='mb-2'
                            id="tags-filled"
                            options={options}
                            defaultValue={[]}
                            onChange={(event, newValue) => {
                                console.log(newValue);
                                setSelectedLogiciel(newValue)
                            }}
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                    <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                                ))
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    size="small"
                                    label="Ajouter des compétences informatiques"
                                    placeholder="Logiciels"
                                    onChange={handleInputChange}
                                />
                            )}
                        />
                        <span className="span-title mb-1 mt-2">Compétences métier requises</span>
                        <Autocomplete
                            className='mt-2'
                            multiple
                            id="tags-filled1"
                            options={options1}
                            defaultValue={[]}
                            onChange={(event, newValue) => {
                                console.log(newValue);
                                setSelectedMetiers(newValue)
                            }}
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                    <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                                ))
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    size="small"
                                    variant="outlined"
                                    label="Ajouter des compétences métier"
                                    placeholder="Métiers"
                                    onChange={handleInputChange1}
                                />
                            )}
                        />

                        <div className="d-flex justify-content-start mt-1">
                            <span className="span-title mb-2" style={{ fontSize: 12, color: "#b1b1b1" }}>Les mots clés permettent de rechercher des missiions par compétence logiciel, outils,etc...</span>
                        </div>

                        <div className="row mt-2">
                            <div className='col-md-6 ml-auto'>
                                <span className="span-title">Prix de la prestation par jour envisagé (HT)*</span>
                                <FormControl fullWidth className='mt-1' variant="outlined">
                                    <OutlinedInput
                                        onChange={(e) => {
                                            setMontantEuro(e.target.value)
                                            if (parseInt(e.target.value) > 0) {
                                                setMontant((parseInt(e.target.value) * 4870).toFixed(2))
                                            } else {
                                                setMontant("")
                                            }
                                        }}
                                        value={montantEuro}
                                        size='small'
                                        id="outlined-adornment-weight"
                                        endAdornment={<InputAdornment position="end">EUROS</InputAdornment>}
                                        aria-describedby="outlined-weight-helper-text"
                                        inputProps={{
                                            'aria-label': 'weight',
                                        }}
                                    />
                                </FormControl>
                            </div>
                            <div className='col-md-6 ml-auto'>
                                <span className="span-title">Prix de la prestation par jour envisagé (HT)*</span>
                                <FormControl fullWidth className='mt-1' variant="outlined">
                                    <OutlinedInput
                                        onChange={(e) => {
                                            setMontant(e.target.value)
                                            if (parseInt(e.target.value) > 0) {
                                                setMontantEuro((parseInt(e.target.value) / 4870).toFixed(2))
                                            } else {
                                                setMontantEuro("")
                                            }
                                        }}
                                        value={montant}
                                        size='small'
                                        id="outlined-adornment-weight"
                                        endAdornment={<InputAdornment position="end">MGA</InputAdornment>}
                                        aria-describedby="outlined-weight-helper-text"
                                        inputProps={{
                                            'aria-label': 'weight',
                                        }}
                                    />
                                </FormControl>
                            </div>
                        </div>
                        <div className="d-flex justify-content-start mt-1">
                            <span className="span-title mb-2" style={{ fontSize: 12, color: "#b1b1b1" }}>Ce montant représente le prix maximal que vous souhaitez mettre pour la réalisation de cette mission par un.e freelance.</span>
                        </div>
                        <div className="row mt-2">
                            <div className="col-md-6 select-mission-niv">
                                <span className="span-title">Niveau d’expérience requis*</span>
                                <Select
                                    className='mt-1'
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={niveauExperience}
                                    style={{ height: 40 }}
                                    size="small"
                                    onChange={(e) => {
                                        setNiveauExperience(e.target.value)
                                    }}
                                    fullWidth
                                >
                                    <MenuItem value={"Je ne sais pas"}>Je ne sais pas</MenuItem>
                                    <MenuItem value={"Junior"}>Junior</MenuItem>
                                    <MenuItem value={"Confirmer"}>Confirmer</MenuItem>
                                    <MenuItem value={"Senior"}>Senior</MenuItem>
                                    <MenuItem value={"Expert"}>Expert</MenuItem>
                                </Select>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions style={{ padding: 20, justifyContent: 'end' }}>
                    <MuiButton

                        color="primary"
                        variant="outlined"
                        style={{ textTransform: 'none' }}
                        onClick={() => {
                            setOpenMissionModal(false)
                            resetValues()
                        }}
                    >
                        Annuler
                    </MuiButton>
                    <MuiButton
                        onClick={() => {
                            addMission()
                        }}
                        variant="contained"
                        disabled={description.length > 500 ? true : false}
                        style={{ textTransform: 'none', color: "#fff", backgroundColor:description.length > 500 ? "grey" : "#1565C0" }}
                    >
                        Publier la mission
                    </MuiButton>
                    {requiredValues === true && <span style={{ color: 'red' }}>Veuillez remplir les champs obligatoire *</span>}
                </DialogActions>
            </Dialog>
        </>
    )
}
export default Mission_List;