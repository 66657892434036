import React, { useEffect } from "react";
import { apiNodeService } from "../../../provider/apiNodeService";
import { useToast } from "@chakra-ui/react";
import "./login_build.css"
import TextField from '@mui/material/TextField';
import { Button as MuiButton } from "@mui/material";
import { useParams } from "react-router-dom";
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import { useNavigate } from "react-router-dom";

const Update_Password = () => {

    const navigate = useNavigate();

    const toast = useToast();
    const { table, token } = useParams();
    const [loading, setLoading] = React.useState(false);
    const [password,setPassword]= React.useState('')
    const [passwordConfirm,setPasswordConfirm]= React.useState('')
    const [showPassword, setShowPassword] = React.useState(false);
    const [showPassword1, setShowPassword1] = React.useState(false);
    const [passwordVerif, setPasswordVerif] = React.useState('')

    useEffect(() => {
    }, [])

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleClickShowPassword1 = () => setShowPassword1((show) => !show);

    const handleMouseDownPassword1 = (event) => {
        event.preventDefault();
    };

    const validatePassword = (password) => {
        // Define regular expressions for each requirement
        const minLengthRegex = /.{8,}/;
        const uppercaseRegex = /[A-Z]/;
        const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;
        const numberRegex = /[0-9]/;

        // Check if all requirements are met
        const isMinLengthValid = minLengthRegex.test(password);
        const isUppercaseValid = uppercaseRegex.test(password);
        const isSpecialCharValid = specialCharRegex.test(password);
        const isNumberValid = numberRegex.test(password);

        // Set valid state based on all requirements being met

        return isMinLengthValid &&
            isUppercaseValid &&
            isSpecialCharValid &&
            isNumberValid

    };

    const updatePassword = async () => {
        setLoading(true)
        let isValidPassword = validatePassword(password)
        if (isValidPassword === false) {
            toast({
                title: 'Erreur !',
                description: "Mot de passe doit comporter au moins 8 caractères, 1 majuscule, 1 chiffre et 1 caractère spécial",
                status: 'error',
                duration: 5000,
                isClosable: true,
              });
        } else {
            if (password !== passwordVerif) {
                console.log(password)
                console.log(passwordVerif)
          toast({
            title: 'Erreur !',
            description: "Veuillez vérifier les mots de passe",
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
          setLoading(false)
        } else {
          let data = {resetToken:token,table:table,password:password}
          console.log(data)
          apiNodeService.updatePasswordFreelance(data).then(res=>{
            console.log(res)
            if (res && res.status === 200) {
              navigate('/build-mada')
              setLoading(false)
            } else if(res && res.status === 402){
              toast({
                title: 'Erreur !',
                description: "Token expiré, Veuillez réessayer ultérieurement! ",
                status: 'error',
                duration: 5000,
                isClosable: true,
              });
              setLoading(false)
            } else {
              toast({
                title: 'Erreur !',
                description: "Veuillez réessayer ultérieurement! ",
                status: 'error',
                duration: 5000,
                isClosable: true,
              });
              setLoading(false)
            }
          })
        }
    }
      }

    return (
        <div>
            <div className="header-reset d-flex">
                <span style={{ fontSize: 36, color: '#000', fontWeight: 700 }}>Build.</span>
                <span style={{ fontSize: 36, color: '#214dcb', fontWeight: 700 }}>Mada</span>
            </div>
            <div className="d-flex flex-column align-items-center" style={{ marginTop: 70, width: "100%", gap: 20 }}>
                <span style={{ fontSize: 22, color: '#000', fontWeight: 600 }}>Entrez nouveau mot de passe</span>
                <div className="d-flex align-items-center justify-content-center mt-2">
                                    <div className="d-flex flex-column">
                                        <span className="span-title mb-1">Mot de passe*</span>
                                        <FormControl style={{ width: '22rem' }} variant="outlined">
                                            <OutlinedInput
                                                id="outlined-adornment-password"
                                                type={showPassword ? 'text' : 'password'}
                                                size="small"
                                                variant="filled"
                                                value={password}
                                                onChange={(e) => {
                                                    setPassword(e.target.value)
                                                }}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                            edge="end"
                                                        >
                                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center justify-content-center mt-2">
                                    <div className="d-flex flex-column">
                                        <span className="span-title mb-1">Confirmer le mot de passe*</span>
                                        <FormControl style={{ width: '22rem' }} variant="outlined">
                                            <OutlinedInput
                                                id="outlined-adornment-password1"
                                                type={showPassword1 ? 'text' : 'password'}
                                                size="small"
                                                variant="filled"
                                                value={passwordVerif}
                                                onChange={(e) => {
                                                    setPasswordVerif(e.target.value)
                                                }}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword1}
                                                            onMouseDown={handleMouseDownPassword1}
                                                            edge="end"
                                                        >
                                                            {showPassword1 ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                    </div>
                                </div>
                <MuiButton variant="contained" color="primary" size="large"
                    style={{ textTransform: "none", fontWeight: 600, height: 46, fontSize: "1.1rem", width: '22rem' }}
                    onClick={() => {
                        updatePassword()
                    }}
                    fullWidth={true}
                >
                    Valider
                </MuiButton>
            </div>
        </div>
    )
}
export default Update_Password;