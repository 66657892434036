import React, { useState } from 'react';
import './Experience.css';

const TextComponent = ({ text }) => {
  const [showAll, setShowAll] = useState(false);

  const toggleShow = () => {
    setShowAll(!showAll);
  };

  return (
    <>
      <span className={`text ${showAll ? 'expanded' : ''}`}>{text}</span>
      {text.split(' ').length > 15 && (
        <span className="toggle-button" onClick={toggleShow}>
          {showAll ? '...Voir moins' : '...Voir plus'}
        </span>
      )}
    </>
  );
};
export default TextComponent;