import React, { useEffect } from "react";
import Menu from "../Menu/Menu1";
import {
    Outlet, useLocation, useNavigate
} from 'react-router-dom';
import { useMediaQuery } from 'react-responsive'
import { menu_items } from "../Menu/DataMenu1";
import { menu_items2 } from "../Menu/DataMenu2";
import defaultAvatar from "../../assets/images/default_avatar.png"
import { Popup } from 'semantic-ui-react'
import { ButtonItem, MenuGroup, Section } from '@atlaskit/menu';
import SignOutIcon from '@atlaskit/icon/glyph/sign-out'
import PersonIcon from '@atlaskit/icon/glyph/person'
import SettingsIcon from '@atlaskit/icon/glyph/settings'
import logo_2 from "../../assets/logo/logo2.png"
import logo_3 from "../../assets/logo/logo3.png"
import Avatar from '@mui/material/Avatar';
import Typography from "@mui/material/Typography";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';

export default function Main2(props) {

    const location = useLocation()
    const navigate = useNavigate()
    const [photo, setPhoto] = React.useState(localStorage.getItem('photo'))
    const [openDrawerMenu, setOpenDrawerMenu] = React.useState(false);
    const [openDetailMenu, setOpenDetailMenu] = React.useState(true);
   

    useEffect(() => {
        console.log("HOME")
        console.log(photo)
        if (!localStorage.getItem("email") || !localStorage.getItem("first_name")) {
            navigate("/login")
        }
    }, [])

    return (
        <>

            <section className="home-section"
                style={{
                    backgroundColor: "#fff",
                    width: "100%"
                }}
            >
                <div className="home-content">
                    <div className="px-4">
                        <span style={{fontSize:34,fontWeight:700}}>Build.</span><span style={{fontSize:34,color:"#F45800",fontWeight:700}}>Mada</span>
                    </div>
                    <div style={{ position: "fixed", right: 20 }}>

                        <Popup
                            style={{ backgroundColor: "#fff", borderRadius: 4, border: "1px solid #c6d7f4", boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
                            content={
                                <>
                                    <MenuGroup
                                        maxWidth={180}
                                        minWidth={180}
                                        onClick={(e) => e.stopPropagation()}
                                    >
                                        <Section hasSeparator={false}>
                                            <ButtonItem
                                                onClick={(e) => {
                                                    navigate("/profil-build?user=" + localStorage.getItem('id'))
                                                }}
                                                iconBefore={<AccountCircleOutlinedIcon color="primary" label="" />}
                                            >
                                                Profile
                                            </ButtonItem>
                                            <ButtonItem
                                                onClick={() => {
                                                    localStorage.clear()
                                                    navigate("/build-mada", { replace: true })
                                                }}
                                                iconBefore={<SignOutIcon primaryColor="red" label="" />}
                                            >
                                                Déconnexion
                                            </ButtonItem>
                                        </Section>
                                    </MenuGroup>
                                </>
                            }
                            on='click'
                            position='bottom right'
                            popper={{ id: 'popper-container', style: { zIndex: 2000 } }}
                            trigger={
                                <div style={{ cursor: "pointer" }}>
                                    <div style={{ display: "flex" }}>
                                        <div style={{ alignSelf: "center" }}>
                                          <Avatar
                                                sx={{ width: 34, height: 34 }}
                                                alt={localStorage.getItem("email")}
                                                src={photo ? "data:image/png;base64," +photo :''}
                                            />
                                        </div>
                                        <div style={{ alignSelf: "center", marginLeft: 5 }}>
                                            <Typography variant="subtitle1">{localStorage.getItem("username")}</Typography>
                                        </div>
                                        <div style={{ alignSelf: "center" }}>
                                            <ArrowDropDownIcon style={{ color: "black", marginLeft: 3 }} />
                                        </div>
                                    </div>

                                </div>
                            }
                        />

                    </div>
                </div>

                <div style={{
                    paddingTop: 30
                }}>
                    <Outlet />
                </div>
            </section>

        </>
    )


}
